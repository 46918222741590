// @ts-nocheck
import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';

import SideMenuLayout from './SideMenuLayout';
import TopMenuLayout from './TopMenuLayout';

const Layout = (props: any) => {
    /* @ts-ignore */
    const { auth } = useAuth();
    let layout = auth.layout;

    if (window.innerWidth <= 600) {
        //mobile view
        layout = 'top';
    }

    return <SideMenuLayout/>;
};

export default Layout;
