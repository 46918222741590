import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {Multiselect} from "multiselect-react-dropdown";
import {
    convertFuncPerm,
    getDefaultAccessMat,
    getNavIdByPath,
    handlePopup,
    useLocalStorage, convertErMsg, specialCharCheck
} from "../../../general/general-tools";
import { usePopupManager } from "react-popup-manager";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";
import {getExchangeList} from "../../../../apis/models/Exchange";


const Index = () => {
    const {setLoading} = useLoading();
    const popupManager = usePopupManager();

    const [tableData, setTableData] = useLocalStorage("exp_so_stock_tableData",[]);
    const [totalRecords, setTotalRecords] = useLocalStorage("exp_so_stock_totalRecords",0);
    const [currentPage, setCurrentPage] = useLocalStorage("exp_so_stock_currentPage",1);
    const [searchInput, setSearchInput] = useLocalStorage("exp_so_stock_searchInput","");
    const [searchSymbol, setSearchSymbol] = useLocalStorage("exp_so_stock_searchSymbol","");
    const [currentPerPage, setCurrentPerPage] = useState(10);
    const [brSymbol, setBrSymbol] =useLocalStorage("expLSC_symbol",[]);
    const [brSymbolList, setBrSymbolList] = useState([]);

    const [currentCompanyName, setCurrentCompanyName] = useState("");
    const [exchangeList, setExchangeList] = useState([]);

    const isInitialMount = useRef(true);

    const location = useLocation();
    let navigate = useNavigate();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat():getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);
    const multiselectRef = useRef();

    useLayoutEffect(() => {
        handleCompanyDetail();


        window.scrollTo({ top: 0, behavior: 'smooth' });

    },[])



    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleInitList(searchInput[0] == null ? "" : searchInput[0]['code'],searchSymbol);
        }

    }, [currentPage, currentPerPage]);
    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {
        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }
    useEnterKeyPress(handleEnterKeyPress);
    const handleCompanyDetail = async () => {
        setLoading(true);
        api.getCompanyDetail().then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            let companyEle = result.data.list[0];
            setCurrentCompanyName(companyEle.b_name ?? "N/A");
            if (tableData.length >0) {
                handleInitList(searchInput[0] == null ? "" : searchInput[0]['code'],searchSymbol)
            }else{
                handleResetBtn();
            }
            handleExchangeList();
            setLoading(false);

        });
    };

    const onExchangeHandle = (selectedList, selectedItem) => {
        if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur();
        setSearchInput([selectedItem]);
        // handleTickerList(selectedList,"");
        if(selectedList.length == 0) setSearchInput([]);
    }

    const onSymbolHandle = (selectedList, selectedItem) => {
        setBrSymbol(selectedList);
    }

    const onHandleSymbolSearch= (searchItem) =>{
        if(searchItem.length > 2) handleTickerList(searchInput,searchItem);
        else if (searchItem.length == 0)handleTickerList(searchInput,"");
    }

    const handleTickerList = async (searchTerm,searchSymbols) => {
        let searchTermStr = "";
        for (let i = 0; i < searchTerm.length; i++) {
            searchTermStr += searchTerm[i]['id']+","
        }
        setBrSymbolList([]);
        setLoading(true);

        searchTermStr= searchTermStr.substring(0,searchTermStr.length-1)
        api.getBroadcastTicker(100, 1,null, searchSymbols == ""? null : searchSymbols,searchTermStr).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

            if (result.status == 0 && result.data) {
                if(result.data.list.length > 0){
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdownTicker(dataList);
                    setBrSymbolList(TreeData);
                }

            }
            setLoading(false);

        });
    };

    const convertRawIntoDropdownTicker = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                exchange: data[i]['em_code'],
                symbol: data[i]['t_code'],
                stockname: data[i]['t_name'],
                id: data[i]['t_id']
            });
        }
        return returnDat;
    }

    const handleInitList = async (searchTerm,searchSymbols,newSearch = false) => {

        setLoading(true);
        let curPage = currentPage;
           if (newSearch) {
            setCurrentPage(1)
            curPage= 1;
        }

        // let symbolStr = '';
           // if(searchSymbols && searchSymbols.length > 0){
           //     for (let i = 0; i < searchSymbols.length; i++) {
           //         symbolStr += searchSymbols[i]['id']+","
           //     }
           // }

           let actualSym = brSymbol == "" ? null : brSymbol;
        // let actualSym = symbolStr.substring(0,symbolStr.length-1);

        api.getTickerList(currentPerPage, curPage,searchTerm, actualSym).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);}

            if (result.status == 0 && result.data) {
                setLoading(false);

                if(result.data.list.length > 0){
                    let dataList = result.data.list;
                    const timeout = setTimeout(() => {
                        setTableData(dataList);
                        let totalPage = parseInt(result.data.totalrow)
                        setTotalRecords(totalPage);
                    }, 10);
                    return () => clearTimeout(timeout);
                }else {
                    handlePopup(true,"Error","No results found",popupManager)
                    setTableData([]);
                    setTotalRecords(10);
                }

            } else {
                handlePopup(true,"Error","No results found",popupManager)
                setTableData([]);
                setTotalRecords(10);
            }
            setLoading(false);

        });
    };

    const handleOnclick = async (cur_code) => {
        await setLoading(true);
        cur_code['company_name'] = currentCompanyName;
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "exp_stock_opr", {state: {row: cur_code,perm: actualPerm, mode: 'Edit'}});
        setLoading(false);
    };

    const resetSelectField = () => {
        multiselectRef.current.resetSelectedValues();
    };

    const handleResetBtn = async () => {
        await setLoading(true);
        setExchangeList([]);
        setBrSymbolList([]);
        setSearchInput("");
        setSearchSymbol("");
        setBrSymbol("");
        resetSelectField();
        handleExchangeList();

        setLoading(false);
    };

    const columns = useMemo(
        () => [

            {
                name: 'Stock Name',
                selector: row => row.t_name,
                sortable: true,
                cell: (row: { t_code: any }) => (
                    <>
                        <a className="tableItemsHlink" onClick={() => handleOnclick(row)}>{row.t_name}</a>
                    </>
                )
            },
            {
                id: row => row.t_code,
                name: 'Symbol',
                selector: row => row.t_code,
                sortable: true,
            },{
                name: 'Exchange',
                selector: row => row.em_code,
                sortable: true,
            }


        ],
        []
    );


    const handleExchangeList = async () => {

        setLoading(true);
        api.getExchangeList(999, 1, 1, null).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdown(dataList);
                    setExchangeList(TreeData);
                }
            }

            setLoading(false);

        });
    };


    const convertRawIntoDropdown = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                code:  data[i]['em_code'],
                name:  data[i]['em_code'] + " | " +  data[i]['em_name'],
                id:  data[i]['em_id']
            });
        }
        return returnDat;
    }
    const onSelect = (selectedList, selectedItem) => {
        setSearchInput(selectedList);
    }
    const onRemove = (selectedList, selectedItem) => {
        setSearchInput("");
    }
    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Risk Management &gt; Exposure Limit
                                - Stock - Company</h5>
                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Company</div>
                                    <div className="label col-md-10">
                                        <input
                                            autoComplete='off'

                                        className="underline-input"
                                        type="text"
                                        placeholder=""
                                        readOnly={true}
                                        aria-label=""
                                        value={currentCompanyName}
                                        onChange={(e) => setCurrentCompanyName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentCompanyName,popupManager)}

                                        />
                                    </div>



                                </div>
                                    <div className="input-container col-md-12 fullWidthSearch">


                                    <div className="label col-md-2">Exchange</div>
                                    <div className=" col-md-10">
                                        <Multiselect
                                            options={exchangeList}
                                            // singleSelect={true}
                                            onSelect={onExchangeHandle}
                                            onRemove={onExchangeHandle}
                                            selectedValues={searchInput}
                                            displayValue="name"
                                            placeholder="Search by Exchange Code / Exchange Name"
                                            showCheckbox={false}
                                            ref={multiselectRef}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>

                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-2 ">Symbol / StockName</div>
                                    <div className="label col-md-10">
                                        <input
                                            autoComplete='off'
                                            className="form-control"
                                            type="text"
                                            placeholder="Search by Symbol"
                                            readOnly={false}
                                            aria-label=""
                                            value={brSymbol}
                                            onChange={(e) => setBrSymbol(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setBrSymbol,popupManager)}
                                        />

                                    </div>

                                </div>
                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary "  id="btn-search-main"  onClick={() => handleInitList(searchInput[0] == null ? "" : searchInput[0]['code'],brSymbol,true)}>Search
                                        </button>
                                        <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    columns={columns}
                                    perPageQty={setCurrentPerPage}
                                    data={tableData}
                                    pagination={{
                                        currentPage: currentPage,
                                        currentPerPage: currentPerPage,
                                        totalRecords: totalRecords
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
