import {handleRequestGet, handleRequestPost} from '../axios';

export const getAuthLimitList = async (param) => {
    let url = '/usertype/limit';

    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getAuthLimitListAdmin = async (param) => {
    let url = '/adminuser/limit';

    let result = await handleRequestGet(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};


export const getAuthLimitListUserType = async (param) => {
    let url = '/usertype';

    let result = await handleRequestGet(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getAuthLimitListOwn = async () => {
    let url = '/adminuser/session/limit';

    let result = await handleRequestGet(url, {});
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateAuthLimitUserType = async (param) => {
    let url = '/usertype/limit/update';
    let result = await handleRequestPost(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const removeAuthLimitAdmin = async (param) => {
    let url = '/adminuser/limit/delete';
    let result = await handleRequestPost(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateAuthLimitAdmin = async (param) => {
    let url = '/adminuser/limit/update';
    let result = await handleRequestPost(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};


