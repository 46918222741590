import {handleRequestGet, handleRequestPost} from '../axios';

export const validatePw = async (param) => {
    let url = '/password/validate';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

