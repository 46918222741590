import {handleRequestPost, handleRequestGet} from '../axios';

export const getBroadcastList = async (size, page, searchParams) => {
    let url = '/broadcast';

    let param = {
        size: size,
        page: page
    };
    if (searchParams['term'] != "") param['term'] = searchParams['term'];
    if (searchParams['tterm'] != "") param['tterm'] = searchParams['tterm']; //Broadcast Type
    if (searchParams['docid'] != "") param['docid'] = searchParams['docid']; //Doc ID
    if (searchParams['exchange'] != "") param['exchange'] = searchParams['exchange']; //1,2,3
    if (searchParams['ticker'] != "") param['ticker'] = searchParams['ticker'];  // Symbol
    if (searchParams['usertype'] != "") param['usertype'] = searchParams['usertype'];

    if (searchParams['status'] != "") param['status'] = searchParams['status'];
    // Optional, to filter the list 'b_status' * 0 - pending approval , 1 = approved , 2 = reject , 3 = published , 4 = Expired
    if (searchParams['publicdatefrom'] != "") param['publicdatefrom'] = searchParams['publicdatefrom']; //2023-01-01
    if (searchParams['createdate'] != "") param['createdate'] = searchParams['createdate']; //2023-01-01
    if (searchParams['publicdateto'] != "") param['publicdateto'] = searchParams['publicdateto']; //2023-01-01
    if (searchParams['approvaldate'] != "") param['approvaldate'] = searchParams['approvaldate']; //2023-01-01
    if (searchParams['approvalby'] != "") param['approvalby'] = searchParams['approvalby']; //Approve By


    let result = await handleRequestGet(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getBroadcastDetail = async (searchParams) => {
    let url = '/broadcast/detail';

    //id
    //broadcast_type
    let result = await handleRequestGet(url, searchParams);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};


export const createBroadcast = async (param) => {
    let url = '/broadcast/create';
    let result = await handleRequestPost(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateBroadcast = async (param) => {
    let url = '/broadcast/update';
    let result = await handleRequestPost(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const deleteBroadcast = async (param) => {
    let url = '/broadcast/delete';
    let result = await handleRequestPost(url, param);

    /*
    {
    "id" : "1"
    }
     */
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const broadcastApproval = async (param) => {
    let url = '/broadcast/approval/update';
    let result = await handleRequestPost(url, param);

    /*
    {
    "id": 1,
    "approval": 1
    }
    */
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getBroadcastTicker = async (size, page, eterm,sterm,emid) => {
    let url = '/ticker';
    let param ={
        size:size,
        page:page,
        sterm:sterm,
        eterm:eterm
    }

    if(emid) param['emid'] = emid;

    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getResearchCat = async () => {
    let url = '/researchcategory';
    let param ={
        size:999,
        page:1
    }

    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
