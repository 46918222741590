import {handleRequestGet, handleRequestPost} from '../axios';

export const getUserAccessList = async (size, page, status, term, nterm, utterm) => {
    let url = '/useraccess';

    let param = {
        size: parseInt(size),
        page: page
    };
    param['status'] = status;
    param['term'] = term;
    param['nterm'] = nterm;
    param['utterm'] = utterm;

    let result = await handleRequestGet(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateUserAccess = async (data) => {
    let url = '/useraccess/update';
    let result = await handleRequestPost(url, {
        "user": data
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};


