// MyModal.jsx
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalPopup from "./ModalPopup";
import Button from "react-bootstrap/Button";

export class ModalPop extends React.Component {

    close() {
        // `onClose` will close the modal and will call the callback defined in main.jsx
        this.props.onClose('');
    }

    render() {
        // `isOpen` is managed only by 'PopupManager'

        const { isOpen } = this.props;
        return (
        <Modal show={isOpen} onHide={() => this.close()}>
            <Modal.Header closeButton>
                <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                {this.props.content}
            </div>
                <div>
                    {this.props.content2 ?? ""}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.close()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        );
    }
}
export default ModalPop;
