import React, {useState, useEffect, useMemo} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import {
    handleSelectChange,
    timeElement15,
    holidayTypeOptions,
    handleCheckboxChange,
    handlePositiveNumberChange,
    onMinMaxCheck,
    handlePopup,
    getDefaultAccessMat,
    convertFuncPerm,
    getNavIdByPath, disableAllEleInDiv, convertErMsg, checkZeroMinMaxULimit
} from "../../../general/general-tools";
import {confirmAlert} from "react-confirm-alert";
import Toggle from 'react-toggle'
import ReactTooltip from 'react-tooltip-rc';
import {usePopupManager} from "react-popup-manager/dist/src";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";

const Index = () => {
    const {setLoading} = useLoading();
    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();

    //Password
    const [compositionAlphabet, setCompositionAlphabet] = useState("");
    const [compositionAlphabetUpper, setCompositionAlphabetUpper] = useState("");
    const [compositionNum, setCompositionNum] = useState("");
    const [compositionSymbol, setCompositionSymbol] = useState("");
    const [allowedSymbols, setAllowedSymbols] = useState('! @ # $ % ^ & * ( ) * ');

    const [beginWith, setBeginWith] = useState("0");


    const [prevMinPwChar, setPrevMinPwChar] = useState("");
    const [prevMaxPwChar, setPrevMaxPwChar] = useState("");
    const [minChar, setMinChar] = useState("");
    const [maxChar, setMaxChar] = useState("");
    const [expiry, setExpiry] = useState("");
    const [expiryNoti, setExpiryNoti] = useState("");
    const [failedAttempt, setFailedAttempt] = useState("");
    const [generationQty, setGenerationQty] = useState("");
    const [generationRules, setGenerationRules] = useState(false);

    //UserId
    const [prevUserIdMinChar, setPrevUserIdMinChar] = useState("");
    const [prevUserIdMaxChar, setPrevUserIdMaxChar] = useState("");

    const [userIdcompositionAlphabet, setUserIdCompositionAlphabet] = useState("");
    const [userIdcompositionNum, setUserIdCompositionNum] = useState("");
    const [userIdBeginWith, setUserIdBeginWith] = useState("");
    const [userIdMinChar, setUserIdMinChar] = useState("");
    const [userIdMaxChar, setUserIdMaxChar] = useState("");

    //LoginAccessControl
    const [timeoutDuration, setTimeoutDuration] = useState("");
    const [singleSignOn, setSgineSignOnMode] = useState("");
    const [singleSignOn2, setSgineSignOnMode2] = useState("");
    const [dormancyDay, setDormancyDay] = useState("");

    //Order Control
    const [clientAmendment, setClientAmendment] = useState("");
    const [DBTOrderCutoff, setDBTOrderCutoff] = useState("");
    const [IDSSOrderCutoff, setIDSSrderCutoff] = useState("");
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat() : getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);



    //Load initial data and mode
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        if (!actualPerm['edit']) {
            setTimeout(function () {
                disableAllEleInDiv("mainDiv");
            }, 50);
        }
        handleInitList();
    }, []);


    const handleInitList = async () => {

        setLoading(true);
        api.getParameterList().then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }
            if (result.data) {
                let dataEle = result.data.list[0];
                setCompositionAlphabet(dataEle['pwd_complexity']['is_alpha_small'])
                setCompositionAlphabetUpper(dataEle['pwd_complexity']['is_alpha_capital'])
                setCompositionNum(dataEle['pwd_complexity']['is_numeric'])
                setCompositionSymbol(dataEle['pwd_complexity']['is_symbol'])
                setAllowedSymbols(dataEle['pwd_complexity']['symbol_pattern'] == '' ? '! @ # $ % ^ & * ( ) * ' : dataEle['pwd_complexity']['symbol_pattern'])

                setBeginWith(dataEle['pwd_begin']['is_alpha'] ? 'begin_with_alphabet' :
                    dataEle['pwd_begin']['is_numeric'] ? 'begin_with_numeric' :
                        dataEle['pwd_begin']['is_none'] ? 'begin_with_none' : '')

                setMinChar(dataEle['pwd_length_min'])
                setPrevMinPwChar(dataEle['pwd_length_min'])
                setMaxChar(dataEle['pwd_length_max'])
                setPrevMaxPwChar(dataEle['pwd_length_max'])
                setExpiry(dataEle['pwd_expired_days'])
                setGenerationQty(dataEle['pwd_generation'])
                setGenerationRules(dataEle['pwd_generation_status'])
                setExpiryNoti(dataEle['pwd_expired_days_notification'])
                setFailedAttempt(dataEle['max_fail_attempt'])

                setUserIdCompositionAlphabet(dataEle['userid_complexity']['is_alpha'])
                setUserIdCompositionNum(dataEle['userid_complexity']['is_numeric'])
                setUserIdBeginWith(dataEle['userid_begin']['is_alpha'] ? 'begin_with_alphabet' :
                    dataEle['userid_begin']['is_numeric'] ? 'begin_with_numeric' :
                        dataEle['userid_begin']['is_none'] ? 'begin_with_none' : '')
                setUserIdMinChar(dataEle['userid_length_min'])
                setPrevUserIdMinChar(dataEle['userid_length_min'])
                setUserIdMaxChar(dataEle['userid_length_max'])
                setPrevUserIdMaxChar(dataEle['userid_length_max'])

                setTimeoutDuration(dataEle['timeout_duration'])
                setDormancyDay(dataEle['dormancy'])

                setClientAmendment(dataEle['amend_cut_off'])
                setDBTOrderCutoff(dataEle['dbt_cut_off'])
                setIDSSrderCutoff(dataEle['idss_cut_off'])


                setSgineSignOnMode(dataEle['single_signon']['web_and_mobile'] ? 'web_and_mobile' :
                    dataEle['single_signon']['web_or_mobile'] ? 'web_or_mobile' : '')

                setSgineSignOnMode2(dataEle['single_signon']['lifo'] ? 'web_lifo_mobile' :
                    dataEle['single_signon']['block'] ? 'web_block_mobile' : '')

            }
        });
        setLoading(false);
    };


    const handleResetBtn = () => {
        confirmAlert({
            title: 'Changes not saved',
            message: <div id="area_confirm">By reset, you will lost any unsaved information.</div>,
            buttons: [{
                label: 'Yes', onClick: () => {
                    handleInitList();
                }
            }, {
                label: 'Cancel',
                id: 'btn_cancel',
                onClick: () => {
                    return false;
                }
            }],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    }


    const handleSaveBtn = async () => {
        await setLoading(true);
        if (timeoutDuration < 3) {
            handlePopup(true, "Error", "Mandatory Field - Timeout duration must be larger than 3 minutes.", popupManager);
            setLoading(false);
            return;
        }
        if (!minChar || minChar.length == 0 || !maxChar || maxChar.length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Password length limit must be configured.", popupManager);
            setLoading(false);
            return;
        }
        if (!userIdMinChar || userIdMinChar.length == 0 || !userIdMaxChar || userIdMaxChar.length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Username length limit must be configured.", popupManager);
            setLoading(false);
            return;
        }
        if (!expiry || expiry.length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Password expiry period must be configured.", popupManager);
            setLoading(false);
            return;
        }
        if (!expiryNoti || expiryNoti.length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Password expiry notification period must be configured.", popupManager);
            setLoading(false);
            return;
        }
        if (!generationQty || generationQty.length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Password generation must be configured.", popupManager);
            setLoading(false);
            return;
        }
        if (!failedAttempt || failedAttempt.length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Max failed attempts must be configured.", popupManager);
            setLoading(false);
            return;
        }
        if (!timeoutDuration || timeoutDuration.length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Timeout duration must be configured.", popupManager);
            setLoading(false);
            return;
        }
        if (!dormancyDay || dormancyDay.length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Dormancy setting must be configured.", popupManager);
            setLoading(false);
            return;
        }
        if (!clientAmendment || clientAmendment.length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Client Amendment Cut-off Time  must be configured.", popupManager);
            setLoading(false);
            return;
        }
        if (!DBTOrderCutoff || DBTOrderCutoff.length == 0) {
            handlePopup(true, "Error", "Mandatory Field - DBT Order Cut-off Time must be configured.", popupManager);
            setLoading(false);
            return;
        }
        if (!IDSSOrderCutoff || IDSSOrderCutoff.length == 0) {
            handlePopup(true, "Error", "Mandatory Field - IDSS Order Cut-off Time  must be configured.", popupManager);
            setLoading(false);
            return;
        }

        let data = {
            "max_fail_attempt": failedAttempt,
            "pwd_length_min": minChar,
            "pwd_length_max": maxChar,
            "pwd_complexity_small": compositionAlphabet ? 1 : 0,
            "pwd_complexity_capital": compositionAlphabetUpper ? 1 : 0,
            "pwd_complexity_numeric": compositionNum ? 1 : 0,
            "pwd_complexity_symbol": compositionSymbol ? 1 : 0,
            "pwd_complexity_pattern": "!@#$%^&*()",
            "pwd_expired_days": expiry,
            "pwd_generation": generationQty,
            "pwd_generation_status": generationRules ? 1 : 0,
            "dormancy": dormancyDay,
            "signon_web_and_mobile": singleSignOn == 'web_and_mobile' ? 1 : 0,
            "signon_web_or_mobile": singleSignOn == 'web_or_mobile' ? 1 : 0,
            "signon_lifo": singleSignOn2 == 'web_lifo_mobile' ? 1 : 0,
            "signon_block": singleSignOn2 == 'web_block_mobile' ? 1 : 0,
            "timeout_duration": timeoutDuration,
            "userid_length_min": userIdMinChar,
            "userid_length_max": userIdMaxChar,
            "userid_complexity_alpha": userIdcompositionAlphabet ? 1 : 0,
            "userid_complexity_numeric": userIdcompositionNum ? 1 : 0,
            "userid_begin_none": userIdBeginWith == 'begin_with_none' ? 1 : 0,
            "userid_begin_alpha": userIdBeginWith == 'begin_with_alphabet' ? 1 : 0,
            "userid_begin_numeric": userIdBeginWith == 'begin_with_numeric' ? 1 : 0,
            "pwd_begin_none": beginWith == 'begin_with_none' ? 1 : 0,
            "pwd_begin_alpha": beginWith == 'begin_with_alphabet' ? 1 : 0,
            "pwd_begin_numeric": beginWith == 'begin_with_numeric' ? 1 : 0,
            "pwd_expired_days_notification": expiryNoti,
            "amend_cut_off": clientAmendment,
            "dbt_cut_off": DBTOrderCutoff,
            "idss_cut_off": IDSSOrderCutoff
        }

        api.updateParameters(data).then((result: any) => {
            if (result != false && result.status == 0) {
                handlePopup(true, "Success", "Parameter control updated successfully.", popupManager);
                setLoading(false);
            } else {
                setLoading(false);
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
            }
        });


    };


    const handleToggle = (event) => {
        setGenerationRules(event.target.checked);
    };

    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Parameter
                                Maintenance </h5>
                            <div className="dropdown no-arrow">

                            </div>
                        </div>


                    </div>

                    {/*Password sector*/}
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Password </h5>

                        </div>

                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3">Composition</div>
                                    <div className="col-md-8">
                                        <label htmlFor="composition_alphabet_lower">
                                            <input className="" name="composition" type="checkbox"
                                                   id="composition_alphabet_lower" value="1"
                                                   checked={compositionAlphabet == "1"}
                                                   onChange={e => handleSelectChange(e.target.checked, setCompositionAlphabet, false)}
                                            />
                                            Lower-case Alphabet

                                        </label>
                                        <label htmlFor="composition_alphabet_upper">
                                            <input className="checkboxMarginLeft" name="composition" type="checkbox"
                                                   id="composition_alphabet_upper"
                                                   value="1"
                                                   checked={compositionAlphabetUpper == "1"}
                                                   onChange={e => handleSelectChange(e.target.checked, setCompositionAlphabetUpper, false)}
                                            />
                                            Upper-case Alphabet
                                        </label>
                                        <label htmlFor="composition_numeric">
                                            <input className=" checkboxMarginLeft" name="composition" type="checkbox"
                                                   id="composition_numeric"
                                                   value="1"
                                                   checked={compositionNum == "1"}
                                                   onChange={e => handleSelectChange(e.target.checked, setCompositionNum, false)}

                                            />
                                            Numeric
                                        </label>
                                        <label data-tip={allowedSymbols} htmlFor="composition_symbol">
                                            <input className=" checkboxMarginLeft" name="composition" type="checkbox"
                                                   id="composition_symbol"
                                                   value="1"
                                                   checked={compositionSymbol == "1"}
                                                   onChange={e => handleSelectChange(e.target.checked, setCompositionSymbol, false)}

                                            />
                                            Symbol ⓘ
                                        </label>


                                    </div>

                                    <ReactTooltip/>


                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-1"></div>
                                    {/*<div className=" col-md-5 small-characters"><i>* Only symbol can be found on a regular keyboard is allowed.</i></div>*/}


                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3">Begin With</div>
                                    <div className=" col-md-8">
                                        <label htmlFor="begin_with_alphabet">
                                            <input className=" " name="composition_alphabet" type="radio"
                                                   id="begin_with_alphabet"
                                                   value="begin_with_alphabet"
                                                   checked={beginWith == "begin_with_alphabet"}
                                                   onChange={e => handleSelectChange(e.target.value, setBeginWith, false)}
                                            />
                                            Alphabet
                                        </label>
                                        <label className="checkboxMarginLeft" htmlFor="begin_with_numeric">
                                            <input className=" checkboxMarginLeft" name="composition_alphabet"
                                                   type="radio" id="begin_with_numeric"
                                                   value="begin_with_numeric"
                                                   checked={beginWith == "begin_with_numeric"}
                                                   onChange={e => handleSelectChange(e.target.value, setBeginWith, false)}

                                            />
                                            Numeric
                                        </label>
                                        <label className="checkboxMarginLeft" htmlFor="begin_with_none">
                                            <input className=" checkboxMarginLeft" name="composition_alphabet"
                                                   type="radio" id="begin_with_none"
                                                   value="begin_with_none"
                                                   checked={beginWith == "begin_with_none"}
                                                   onChange={e => handleSelectChange(e.target.value, setBeginWith, false)}

                                            />
                                            None
                                        </label>
                                    </div>

                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3">Length</div>
                                    <div className="col-md-3">
                                        <div className=""
                                             style={{float: "left", "margin-top": "3%", "margin-right": ".3em"}}>
                                            Min Characters
                                        </div>
                                        <div className="col-md-5" style={{overflow: "hidden"}}>
                                            <input
                                                autoComplete='off'
                                                // require-input-alert
                                                id="min_char_password"
                                                className="form-control"
                                                type="number"
                                                value={minChar}
                                                step={1}
                                                onBlur={(e) => onMinMaxCheck(e.target.value, setMinChar, minChar, maxChar, setMinChar, setMaxChar, 'min', prevMinPwChar, setPrevMinPwChar, prevMaxPwChar, setPrevMaxPwChar)}
                                                onChange={(e) => handlePositiveNumberChange(e.target.value, setMinChar, true)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className=""
                                             style={{float: "left", "margin-top": "3%", "margin-right": ".3em"}}>
                                            Max Characters
                                        </div>
                                        <div className="col-md-5" style={{overflow: "hidden"}}>
                                            <input
                                                autoComplete='off'

                                                className="form-control "
                                                type="number"
                                                value={maxChar}
                                                onBlur={(e) => onMinMaxCheck(e.target.value, setMinChar, minChar, maxChar, setMinChar, setMaxChar, 'max', prevMinPwChar, setPrevMinPwChar, prevMaxPwChar, setPrevMaxPwChar)}
                                                onChange={(e) => handlePositiveNumberChange(e.target.value, setMaxChar, true)}
                                            />
                                        </div>
                                    </div>


                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3">Expiry</div>
                                    <div className="label col-md-2">
                                        <input
                                            autoComplete='off'

                                            className="form-control "
                                            type="text"
                                            value={expiry}
                                            onChange={(e) => handlePositiveNumberChange(e.target.value, setExpiry, true)}
                                        />

                                    </div>
                                    <div className=" col-md-1">Days</div>

                                </div>

                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3">Expiry Notification</div>
                                    <div className="label col-md-2">
                                        <input
                                            autoComplete='off'

                                            className="form-control "
                                            type="text"
                                            value={expiryNoti}
                                            onChange={(e) => handlePositiveNumberChange(e.target.value, setExpiryNoti, true)}
                                        />

                                    </div>
                                    <div className=" col-md-2">Day before</div>

                                </div>

                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-3">Generation</div>
                                    <div className=" col-md-2">
                                        <input
                                            autoComplete='off'

                                            className="form-control "
                                            type="text"
                                            value={generationQty}
                                            onChange={(e) => handlePositiveNumberChange(e.target.value, setGenerationQty, true)}

                                        />
                                    </div>

                                    <div className=" col-md-2">


                                        Off
                                        <Toggle
                                            defaultChecked={generationRules}
                                            checked={generationRules}
                                            onClick={handleToggle}/>
                                        On
                                    </div>

                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3">Max fail attempts</div>
                                    <div className="label col-md-2">
                                        <input
                                            autoComplete='off'

                                            className="form-control "
                                            type="text"
                                            value={failedAttempt}
                                            onChange={(e) => handlePositiveNumberChange(e.target.value, setFailedAttempt, true)}

                                        />

                                    </div>
                                    <div className=" col-md-2">attempt(s)</div>

                                </div>


                            </div>
                        </div>
                    </div>

                    {/*UserID Section*/}
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">User ID </h5>

                        </div>

                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3">Composition</div>
                                    <div className=" col-md-8">
                                        <label htmlFor="composition_alphabet_userId">
                                            <input className="checkboxMarginRight" name="composition_userId"
                                                   type="checkbox" id="composition_alphabet_userId"
                                                   value="1"
                                                   checked={userIdcompositionAlphabet == "1"}
                                                   onChange={e => handleSelectChange(e.target.checked, setUserIdCompositionAlphabet, false)}
                                            />
                                            Alphabet
                                        </label>
                                        <label htmlFor="composition_numeric_userId">
                                            <input className=" checkboxMarginLeft" name="composition_userId"
                                                   type="checkbox" id="composition_numeric_userId"
                                                   value="1"
                                                   checked={userIdcompositionNum == "1"}
                                                   onChange={e => handleSelectChange(e.target.checked, setUserIdCompositionNum, false)}
                                            />
                                            Numeric
                                        </label>

                                    </div>

                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3">Begin With</div>
                                    <div className=" col-md-8">
                                        <label htmlFor="begin_with_alphabet_userId">
                                            <input className=" checkboxMarginRight" name="begin_with_userId"
                                                   type="radio" id="begin_with_alphabet_userId"
                                                   checked={userIdBeginWith == "begin_with_alphabet"}
                                                   onChange={e => handleCheckboxChange(e.target.checked, 'begin_with_alphabet', setUserIdBeginWith, false)}
                                            />
                                            Alphabet
                                        </label>
                                        <label className="checkboxMarginLeft" htmlFor="begin_with_numeric_userId">
                                            <input className=" checkboxMarginLeft" name="begin_with_userId" type="radio"
                                                   id="begin_with_numeric_userId"
                                                   checked={userIdBeginWith == "begin_with_numeric"}
                                                   onChange={e => handleCheckboxChange(e.target.checked, 'begin_with_numeric', setUserIdBeginWith, false)}

                                            />
                                            Numeric
                                        </label>
                                        <label className="checkboxMarginLeft" htmlFor="begin_with_none_userId">
                                            <input className=" checkboxMarginLeft" name="begin_with_userId" type="radio"
                                                   id="begin_with_none_userId"
                                                   checked={userIdBeginWith == "begin_with_none"}
                                                   onChange={e => handleCheckboxChange(e.target.checked, 'begin_with_none', setUserIdBeginWith, false)}

                                            />
                                            None
                                        </label>
                                    </div>

                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3">Length</div>

                                    <div className="col-md-3">
                                        <div className=""
                                             style={{float: "left", "margin-top": "3%", "margin-right": ".3em"}}>
                                            Min Characters
                                        </div>
                                        <div className="col-md-5" style={{overflow: "hidden"}}>
                                            <input
                                                className="form-control "
                                                type="text"
                                                value={userIdMinChar}
                                                onBlur={(e) => onMinMaxCheck(e.target.value, setUserIdMinChar, userIdMinChar, userIdMaxChar, setUserIdMinChar, setUserIdMaxChar, 'min',prevUserIdMinChar, setPrevUserIdMinChar, prevUserIdMaxChar, setPrevUserIdMaxChar)}
                                                max={999999999}
                                                onChange={(e) => handlePositiveNumberChange(e.target.value, setUserIdMinChar, true)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className=""
                                             style={{float: "left", "margin-top": "3%", "margin-right": ".3em"}}>
                                            Max Characters
                                        </div>
                                        <div className="col-md-5" style={{overflow: "hidden"}}>
                                            <input
                                                className="form-control "
                                                type="text"
                                                value={userIdMaxChar}
                                                onBlur={(e) => onMinMaxCheck(e.target.value, setUserIdMinChar, userIdMinChar, userIdMaxChar, setUserIdMinChar, setUserIdMaxChar, 'max',prevUserIdMinChar, setPrevUserIdMinChar, prevUserIdMaxChar, setPrevUserIdMaxChar)}
                                                max={999999999}
                                                onChange={(e) => handlePositiveNumberChange(e.target.value, setUserIdMaxChar, true)}

                                            />
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Login Access Control Section*/}
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Login Access Control </h5>

                        </div>

                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3">Time Out Duration</div>
                                    <div className=" col-md-2">
                                        <input
                                            className="form-control "
                                            type="number"
                                            value={timeoutDuration}
                                            onChange={(e) => handlePositiveNumberChange(e.target.value, setTimeoutDuration, true)}
                                        />

                                    </div>
                                    Minutes (Idle session expired, min 3 minutes)

                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3">Dormancy Setting</div>
                                    <div className="label col-md-2">
                                        <input
                                            className="form-control "
                                            type="number"
                                            value={dormancyDay}
                                            onChange={(e) => handlePositiveNumberChange(e.target.value, setDormancyDay, true)}
                                        />

                                    </div>
                                    Day(s)

                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3">Single Sign-On</div>
                                    <div className=" col-md-5">
                                        <label htmlFor="composition_wam_login">
                                            <input className=" checkboxMarginRight" name="composition_login"
                                                   type="radio" id="composition_wam_login"
                                                   checked={singleSignOn == "web_and_mobile"}
                                                   onChange={e => handleCheckboxChange(e.target.checked, 'web_and_mobile', setSgineSignOnMode, false)}
                                            />
                                            Web AND Mobile
                                        </label>
                                        <label className="checkboxMarginLeft" htmlFor="composition_wom_login">
                                            <input className=" checkboxMarginLeft" name="composition_login" type="radio"
                                                   id="composition_wom_login"
                                                   checked={singleSignOn == "web_or_mobile"}
                                                   onChange={e => handleCheckboxChange(e.target.checked, 'web_or_mobile', setSgineSignOnMode, false)}

                                            />
                                            Web OR Mobile
                                        </label>
                                    </div>
                                </div>

                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3"></div>
                                    <div className=" col-md-5">
                                        <label htmlFor="composition_lifo_login">
                                            <input className=" checkboxMarginRight" name="composition_login2"
                                                   type="radio" id="composition_lifo_login"
                                                   checked={singleSignOn2 == "web_lifo_mobile"}
                                                   onChange={e => handleCheckboxChange(e.target.checked, 'web_lifo_mobile', setSgineSignOnMode2, false)}
                                            />
                                            LIFO
                                        </label>
                                        <label className="checkboxMarginLeft" htmlFor="composition_block_login">
                                            <input className=" checkboxMarginLeft" name="composition_login2"
                                                   type="radio" id="composition_block_login"
                                                   checked={singleSignOn2 == "web_block_mobile"}
                                                   onChange={e => handleCheckboxChange(e.target.checked, 'web_block_mobile', setSgineSignOnMode2, false)}

                                            />
                                            Block
                                        </label>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    {/*Order Control Section*/}
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Order Control </h5>

                        </div>

                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3">Client Amendment Cut-off Time</div>
                                    <div className=" col-md-3">
                                        <select
                                            className="form-control"
                                            aria-label=""
                                            value={clientAmendment}
                                            onChange={e => handleSelectChange(e.target.value, setClientAmendment, false)}
                                        >
                                            {timeElement15()}
                                        </select>
                                    </div>

                                </div>

                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3">DBT Order Cut-off Time</div>
                                    <div className=" col-md-3">
                                        <select
                                            className="form-control"
                                            aria-label=""
                                            value={DBTOrderCutoff}
                                            onChange={e => handleSelectChange(e.target.value, setDBTOrderCutoff, false)}
                                        >
                                            {timeElement15()}
                                        </select>
                                    </div>

                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-3">IDSS Order Cut-off Time</div>
                                    <div className=" col-md-3">
                                        <select
                                            className="form-control"
                                            aria-label=""
                                            value={IDSSOrderCutoff}
                                            onChange={e => handleSelectChange(e.target.value, setIDSSrderCutoff, false)}
                                        >
                                            {timeElement15()}
                                        </select>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <div className=" col-md-12 fullWidthSearch">

                        <div className="label">
                            <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset to default</button>

                        </div>
                        {actualPerm['edit'] ?
                            <button className="btn btn-primary " onClick={() => handleSaveBtn()}>Save</button> : ""}


                    </div>
                </div>


            </div>
        </div>
    );
};

export default Index;
