import React, {useState, useEffect, useMemo} from 'react';

import api from '../../../../apis';
import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';

import DropdownSelect from '../../../general/form-control/dropdown-select';
import ReactTable from '../../../general/form-control/react-table';
import statusText from '../../../../constants/statusText';
import TreeSelect from '../../../general/form-control/tree-select';
import {parseAPIResutlToData} from '../../../general/form-control/tree-select/helper';
import DataTable from 'react-data-table-component';
import {useLocation, useNavigate} from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
    convertErMsg,
    disableAllEleInDiv,
    handleCountryChange,
    handlePopup, specialCharCheck,
    stateField
} from "../../../general/general-tools";
import {confirmAlert} from "react-confirm-alert";
import { usePopupManager } from "react-popup-manager";
import ModalConfirm from "../../public/ModalConfirm";


const Index = () => {
    const {setLoading} = useLoading();
    const [defaultPhoneCountry] = useState(process.env.REACT_APP_PHONE_COUNTRY);


    const [currentMode, setCurrentMode] = useState("");
    const [currentBranchId, setCurrentBranchId] = useState("");
    const [currentBranchCode, setCurrentBranchCode] = useState("");
    const [currentBranchName, setCurrentBranchName] = useState("");
    const [currentBranchAddress1, setCurentBranchAddress1] = useState("");
    const [currentBranchAddress2, setCurentBranchAddress2] = useState("");
    const [currentBranchAddress3, setCurentBranchAddress3] = useState("");
    const [currentBranchPostCode, setCurrentBranchPostCode] = useState("");
    const [currentBranchState, setCurrentBranchState] = useState("");
    const [currentBranchCountryText, setCurrentBranchCountryText] = useState("");
    const [currentBranchCountry, setCurrentBranchCountry] = useState("");
    const [currentBranchPhoneCode, setCurrentBranchPhoneCode] = useState("");
    const [currentBranchCompanyCode, setCurrentBranchCompanyCode] = useState("");

    const [countryList, setCountryList] = useState([]);
    const [companyId, setCompanyId] = useState([]);

    const [accessItem, setAccessItem] = useState({});

    const [readOnlyBranchCode, setReadOnlyBranchCode] = useState(false);

    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();

    //Load initial data and mode
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        handleCountryList();
        handleCompanyDetail();
        // setCurrentBranchCountryText(
        if(location.state){
            if (location.state.row){
                setAccessItem(location.state.perm);
                if(!location.state.perm['edit']){
                    setTimeout(function() {
                        disableAllEleInDiv("mainDiv");
                    }, 50);
                }
                setCurrentBranchId(location.state.row.bb_id);
                setCurrentBranchCode(location.state.row.bb_code ?? "");
                setCurrentBranchName(location.state.row.bb_name ?? "");
                setCurentBranchAddress1(location.state.row.bb_address_line1 ?? "");
                setCurentBranchAddress2(location.state.row.bb_address_line2);
                setCurentBranchAddress3(location.state.row.bb_address_line3);
                setCurrentBranchPostCode(location.state.row.bb_postcode);
                setCurrentBranchState(location.state.row.bb_state);
                setCurrentBranchCountryText(location.state.row.co_name);
                setCurrentBranchCountry(location.state.row.bb_cid);
                if(location.state.row.bb_phone)setCurrentBranchPhoneCode(location.state.row.bb_phone);
                setCurrentBranchCompanyCode(location.state.row.bb_cid);
            }
            setCurrentMode(location.state.mode)
        }
    }, [location.state]);

    const handleCountryList = async () => {
        setLoading(true);
        api.getCountryList(999, 1, 1, null).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if(result.data){
                if (result.data.list.length > 0) {
                    setCountryList(result.data.list);
                }
            }
        });
        setLoading(false);

    };

    const handleCompanyDetail = async () => {
        setLoading(true);
        api.getCompanyDetail().then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            setCompanyId(result.data.list[0]['b_id']);
        });
        setLoading(false);
    };

    // Edit
    const handleBackBtn = async () => {
        await setLoading(true);
        navigate("/"+process.env.REACT_APP_URL_PREFIX+"branch",{state: {mode: 'back'}});
        setLoading(false);
    };


    const handleRemoveBtn = async (country_code) => {
        await setLoading(true);
        popupManager.open(ModalConfirm, {
            title: 'Delete',
            content: 'Are you sure you want to delete?',
            onClose: (...params) => handleRemoveItem(params[0])
        });

        setLoading(false);
    };
    const handleRemoveItem = async (selBoolean) => {
        if(!selBoolean) return;
        api.deleteBranch(currentBranchId).then((result: any) => {
            if (result != false && result.status == 0) {
                setLoading(false);
                handlePopup(true, "Success", "Branch deleted successfully.", popupManager,true,"branch",navigate)
            }else{
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
            }
        });
    };

    const handleSaveBtn = async () => {
        await setLoading(true);

        if ( currentBranchCode.trim().length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Branch Code not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if ( currentBranchName.trim().length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Branch Name not entered.", popupManager)
            setLoading(false);
            return false;
        }
        if ( currentBranchAddress1.trim().length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Address not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if ( currentBranchPhoneCode.trim().length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Phone Number not entered.", popupManager)
            setLoading(false);
            return false;
        }



        if (
            currentBranchCode.trim().length > 1 && currentBranchCode.trim().length < 10 &&
            currentBranchName.trim().length > 2 &&
            currentBranchAddress1.trim().length > 2 &&
            currentBranchPhoneCode.trim().length > 2) {
            let data = {
                "id" : currentBranchId,
                "code" : currentBranchCode,
                "name" : currentBranchName == '' ? null : currentBranchName,
                "phone": currentBranchPhoneCode == '' ? null : currentBranchPhoneCode,
                "address1" : currentBranchAddress1 == '' ? null :currentBranchAddress1,
                "address2" : currentBranchAddress2 == '' ? null : currentBranchAddress2,
                "address3" : currentBranchAddress3 == '' ? null : currentBranchAddress3,
                "postcode" : currentBranchPostCode == '' ? null : currentBranchPostCode,
                "state" : currentBranchState == '' ? null : currentBranchState,
                "country" : currentBranchCountry == '' ? null : currentBranchCountry,
                "company" : companyId == '' ? null : companyId
            }

            api.updateBranch(data).then((result: any) => {
                if (result != false && result.status == 0){
                    setLoading(false)
                    handlePopup(true, "Success", "Branch information updated successfully.", popupManager,true,"branch",navigate)
                }
                else   handlePopup(true,"Error",convertErMsg(result.message),popupManager)
            });



        }
        setLoading(false);
    };


    const handleResetBtn = async (branch_code) => {
        await setLoading(true);
        setCurrentBranchCode("");
        setCurrentBranchName("");
        setCurentBranchAddress1("");
        setCurentBranchAddress2("");
        setCurentBranchAddress3("");
        setCurrentBranchPostCode("");
        setCurrentBranchState("");
        setCurrentBranchCountry("");
        setCurrentBranchPhoneCode("");
        setLoading(false);
    };

    const handleAddBtn = async () => {
        await setLoading(true);
        if ( currentBranchCode.trim().length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Branch Code not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if ( currentBranchName.trim().length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Branch Name not entered.", popupManager)
            setLoading(false);
            return false;
        }
        if ( currentBranchAddress1.trim().length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Address not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if ( currentBranchPhoneCode.trim().length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Phone Number not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if (
            currentBranchCode.trim().length > 1 && currentBranchCode.trim().length < 10 &&
            currentBranchName.trim().length > 2 &&
            currentBranchAddress1.trim().length > 2 &&
            currentBranchPhoneCode.trim().length > 2
        ) {
            let data = {
                "code" : currentBranchCode,
                "name" : currentBranchName == '' ? null : currentBranchName,
                "phone": currentBranchPhoneCode == '' ? null : currentBranchPhoneCode,
                "address1" : currentBranchAddress1 == '' ? null :currentBranchAddress1,
                "address2" : currentBranchAddress2 == '' ? null : currentBranchAddress2,
                "address3" : currentBranchAddress3 == '' ? null : currentBranchAddress3,
                "postcode" : currentBranchPostCode == '' ? null : currentBranchPostCode,
                "state" : currentBranchState == '' ? null : currentBranchState,
                "country" : currentBranchCountry == '' ? null : currentBranchCountry,
                "company" : companyId == '' ? null : companyId
            }

            api.addBranch(data).then((result: any) => {
                if (result != false && result.status == 0){
                    setLoading(false);
                    handlePopup(true, "Success", "Branch created successfully.", popupManager,true,"branch",navigate)
                }
                else handlePopup(true,"Error",convertErMsg(result.message),popupManager)
            });


        }

        setLoading(false);
    };




    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Master File
                                Maintenance &gt; Branch - {currentMode}</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Branch Code</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            autoComplete='off'
                                            id="branch_code"
                                            type="text"
                                            placeholder="e.g. 001"
                                            aria-label=""
                                            value={currentBranchCode}
                                            readOnly={readOnlyBranchCode}
                                            onChange={(e) => setCurrentBranchCode(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentBranchCode,popupManager)}

                                        />
                                    </div><b className='mandatory-red'>*</b>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Branch Name</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            autoComplete='off'
                                            id="branch_name"
                                            type="text"
                                            placeholder="e.g. Selangor"
                                            aria-label=""
                                            value={currentBranchName}
                                            onChange={(e) => setCurrentBranchName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentBranchName,popupManager)}

                                        />

                                    </div>
                                <b className='mandatory-red'>*</b>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Address</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            autoComplete='off'
                                            id="branch_add1"
                                            type="text"
                                            placeholder="e.g. No.1 Jalan Pertama"
                                            aria-label=""
                                            value={currentBranchAddress1}
                                            onChange={(e) => setCurentBranchAddress1(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurentBranchAddress1,popupManager)}

                                        />

                                    </div><b className='mandatory-red'>*</b>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2"></div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            autoComplete='off'
                                            id="branch_add2"
                                            type="text"
                                            placeholder="e.g. Taman Sentosa "
                                            aria-label=""
                                            value={currentBranchAddress2}
                                            onChange={(e) => setCurentBranchAddress2(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurentBranchAddress2,popupManager)}

                                        />

                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2"></div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            autoComplete='off'
                                            id="branch_add3"
                                            type="text"
                                            placeholder="e.g. Seri Kembangan"
                                            aria-label=""
                                            value={currentBranchAddress3}
                                            onChange={(e) => setCurentBranchAddress3(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurentBranchAddress3,popupManager)}

                                        />

                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Post Code</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            autoComplete='off'
                                            id="branch_postcode"
                                            type="text"
                                            placeholder="e.g. 40010"
                                            aria-label=""
                                            maxLength={5}
                                            value={currentBranchPostCode}
                                            onChange={(e) => setCurrentBranchPostCode(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentBranchPostCode,popupManager)}

                                        />

                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">State</div>
                                    <div className="label col-md-10">

                                        {stateField(currentBranchState,setCurrentBranchState,(currentBranchCountryText == 'Malaysia' ? true : false),false,popupManager)}
                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Country</div>
                                    <div className="label col-md-10">

                                        <select
                                            className="form-control"
                                            id="branch_state"
                                            aria-label=""
                                            value={currentBranchCountry}
                                            onChange={(e) => handleCountryChange(e.target.value,e.target.options[e.target.selectedIndex].text,setCurrentBranchCountry,setCurrentBranchCountryText)}
                                        >
                                            <option value=''>Please select Country</option>
                                            {countryList.map(el => <option
                                                value={el.c_id}>{el.c_name}</option>)}
                                        </select>
                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Phone Number</div>
                                    <div className="label col-md-10">

                                        <PhoneInput
                                            containerClass='react-tel-input-full'
                                            inputClass='react-tel-input-full'
                                            dropdownClass='react-tel-input-full' country={defaultPhoneCountry}
                                            preferredCountries={['my','sg']}
                                            enableAreaCodeStretch
                                            masks={{defaultPhoneCountry: '...........'}}
                                            countryCodeEditable={false}
                                            value={currentBranchPhoneCode}
                                            onChange={phone => setCurrentBranchPhoneCode(phone)}
                                        />
                                    </div>
                                <b className='mandatory-red'>*</b>

                                </div>


                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2"></div>

                                    {currentMode.toLowerCase() == 'edit' ?
                                        <div className="label col-md-10 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>
                                            {accessItem['delete'] ? <button className="btn btn-primary " onClick={() => handleRemoveBtn(currentBranchCode)}>Delete</button> :""}
                                            {accessItem['edit'] ? <button className="btn btn-primary " onClick={() => handleSaveBtn()}>Save</button> :""}

                                        </div> :

                                        <div className="label col-md-10 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>
                                            <button className="btn btn-primary " onClick={() => handleResetBtn(currentBranchCode)}>Reset</button>
                                            <button className="btn btn-primary " onClick={() => handleAddBtn()}>Save</button>
                                        </div>
                                    }


                                </div>


                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
