import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react';
import '../../../../asset/styles/customer-profile-style.css';
import api from '../../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    convertPositiveNumberHundred, dateTimeFormat, getNavItemById,
    handlePositiveNumberChange,
    handlePositiveNumberHundredChange,
    handleSelectChange
} from "../../../../general/general-tools";
import ReactTable from "../../../../general/form-control/react-table";
import dayjs from "dayjs";



const UserAccess = React.memo(props => {

    let setCurrentPage = props['setCurrentPage']== null ? '' : props['setCurrentPage'];
    let setCurrentPerPage = props['setCurrentPerPage']== null ? '' : props['setCurrentPerPage'];
    let currentPage = props['tableData']== null ? '' : props['currentPage'];
    let currentPerPage = props['tableData']== null ? '' : props['currentPerPage'];
    let totalRecords = props['tableData']== null ? '' : props['totalRecords'];
    // let setCurrentUserId = props['setCurrentUserId']== null ? '' : props['setCurrentUserId'];

    let tableData = props['tableData']== null ? '' : props['tableData'];
    let popupManager = props['popupManager']== null ? '' : props['popupManager'];


    const handleOnclick = async (row) => {
        await setLoading(true);

        // if(setCurrentUserId)setCurrentUserId(row.ut_id ?? 0);

        // navigate("/" + process.env.REACT_APP_URL_PREFIX + "br_entry_opr", {
        //     state: {
        //         row: row,
        //         perm: actualPerm,
        //         mode: 'Edit'
        //     }
        // });
        setLoading(false);
    };


    const columns = useMemo(
        () => [
            {
                id: row => row.p_fullname,
                name: 'Username',
                selector: row => row.p_fullname,
                sortable: true,
            },
            {
                name: 'User ID',
                selector: row => row.username,
                sortable: true
            }, {
                name: 'Device',
                selector: row => row.device ?? "-",
                sortable: true
            },
            {
                name: 'Last Login Date',
                selector: row => (dayjs(row.last_login).format(dateTimeFormat()) == 'Invalid Date' ? "-" :  dayjs(row.last_login).format(dateTimeFormat())),
                sortable: true
            },
            {
                name: 'Access Status',
                selector: row => row.active_status,
                sortable: true
            }
        ],
        []
    );


    return  <>

        <ReactTable
            rowClick={setCurrentPage}
            perPageQty={setCurrentPerPage}
            // handleSelect={setCurrentUsrTypeId}
            columns={columns}
            data={tableData}
            pagination={{
                currentPage: currentPage,
                totalRecords: totalRecords,
                currentPerPage: currentPerPage,
                // selectableHandle: {setCurrentUsrTypeId},
            }}
        />

    </>
});
export default UserAccess;
