import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import Multiselect from "multiselect-react-dropdown";
import {
    useLocalStorage,
    handlePopup,
    convertFuncPerm,
    getNavIdByPath,
    getDefaultAccessMat, convertErMsg, specialCharCheck
} from "../../../general/general-tools";
import { usePopupManager } from "react-popup-manager";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";


const Index = () => {
    const {setLoading} = useLoading();

    const [tableData, setTableData] = useLocalStorage("exp_dealer_tableData",[]);
    const [totalRecords, setTotalRecords] = useLocalStorage("exp_dealer_totalRecords",0);
    const [currentPage, setCurrentPage] = useLocalStorage("exp_dealer_currentPage",1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage("exp_dealer_currentPerPage",10);
    const [searchInput, setSearchInput] = useLocalStorage("exp_dealer_searchInput","");
    const [searchDealerCode, setSearchDealerCode] = useLocalStorage("exp_dealer_searchDealerCode","");
    const [searchBranchCode, setSearchBranchCode] = useLocalStorage("exp_dealer_searchBranchCode","");
    const [searchStatus, setSearchStatus] = useState("");
    const [branchList, setBranchList] = useState([]);
    const [branchListMultiSel, setBranchListMultiSel] = useState([]);
    const isInitialMount = useRef(true);

    const location = useLocation();
    let navigate = useNavigate();
    const popupManager = usePopupManager();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat():getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);

    useLayoutEffect(() => {
        if (tableData.length > 0) {
            handleInitList(searchDealerCode)
        }else{
            handleResetBtn();
        }
        // handleBranchList()
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[])



    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleInitList(searchDealerCode);
        }

    }, [currentPage, currentPerPage]);

    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {
        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }
    useEnterKeyPress(handleEnterKeyPress);

    const handleSearch = async (newSearch = true) => {
        if(searchInput.trim().length > 0 && searchInput.length < 3){
             handlePopup(true,"Error","Search term require at least 2 characters.",popupManager)
            return false;
        }

        if(searchDealerCode.trim().length > 0 && searchDealerCode.length < 3){
            handlePopup(true,"Error","Dealer code require at least 2 characters.",popupManager)
            return false;
        }
        handleInitList(searchDealerCode,newSearch)

    }



    const handleInitList = async (searchTerm,newSearch = false) => {

        setLoading(true);
        let curPage = currentPage;
           if (newSearch) {
            setCurrentPage(1)
            curPage= 1;
        }
        // export const getDealerList = async (size, page, term,bterm,dterm,status) => {
            api.getDealerList(currentPerPage, curPage, searchTerm).then((result: any) => {
                if(result.status == 1){
                    handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                    setLoading(false);
                }

            if (result.status == 0 && result.data) {
                if(result.data.list.length > 0){
                    let dataList = result.data.list;
                    const timeout = setTimeout(() => {
                        setTableData(dataList);
                        let totalPage = parseInt(result.data.totalrow)
                        setTotalRecords(totalPage);
                        setLoading(false);
                    }, 500);
                    return () => clearTimeout(timeout);
                }else {
                    handlePopup(true,"Error","No results found",popupManager)
                    setTableData([]);
                    setTotalRecords(10);
                    setLoading(false);
                }

            } else {
                handlePopup(true,"Error","No results found",popupManager)
                setTableData([]);
                setTotalRecords(10);
                setLoading(false);
            }
        });
    };





    const handleOnclick = async (row) => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "exp_dealer_opr", {state: {row: row, perm: actualPerm,mode: 'Edit'}});
        setLoading(false);
    };
    const handleAddBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "exp_dealer_opr", {state: {row: "", perm: actualPerm,mode: 'Add'}});
        setLoading(false);
    };
    const handleResetBtn = async () => {
        await setLoading(true);
        setSearchInput("");
        setSearchDealerCode("");
        setSearchBranchCode("");
        setSearchStatus("");
        setLoading(false);
    };

    const columns = useMemo(
        () => [
            {
                id: row => row.d_code,
                name: "Dealer Code",
                selector: row => row.d_code,
                sortable: true,
                // center: true,
                cell: (row: { d_code: any }) => (
                    <>
                        <a className="tableItemsHlink" onClick={() => handleOnclick(row)}>{row.d_code}</a>
                    </>
                )
            },
            {
                name: 'Dealer Name',
                selector: row => row.p_fullname ?? "N/A",
                sortable: true
            },
            {
                name: 'Branch Code',
                selector: row => row.bb_code ?? "N/A",
                sortable: true,
            }
        ],
        []
    );
    const onSelect = (selectedList, selectedItem) => {
        setSearchBranchCode(selectedList);
    }
    const onRemove = (selectedList, selectedItem) => {
        setSearchBranchCode("");
    }

    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Risk Management &gt; Exposure Limit
                                - Dealer</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">

                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-2">Dealer Code / Branch Code / Branch Name </div>
                                    <div className="label col-md-10">
                                        <input
                                            autoComplete='off'

                                            className="form-control "

                                            type="text"
                                            placeholder="Search by Dealer Code / Branch Code / Branch Name"
                                            aria-label="Search Input"
                                            value={searchDealerCode}
                                            onChange={(e) => setSearchDealerCode(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setSearchDealerCode,popupManager)}

                                        />
                                    </div>



                                </div>
                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary "   id="btn-search-main" onClick={() => handleSearch(true)}>Search
                                        </button>
                                        <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                        </button>
                                        {/*<button className="btn btn-primary " onClick={() => handleAddBtn()}>Add</button>*/}
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    perPageQty={setCurrentPerPage}
                                    columns={columns}
                                    data={tableData}
                                    pagination={{
                                        currentPage: currentPage,
                                        currentPerPage: currentPerPage,
                                        totalRecords: totalRecords,
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
