import {handleRequestGet, handleRequestPost} from '../axios';

export const getExchangeList = async (size, page, status, term) => {
    let url = '/exchange';
    let param = {
        size: size,
        page: page
    };
    if(term != "") param['term'] = term;

    let result = await handleRequestGet(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getExchangeMainList = async (size, page, status, term) => {
    let url = '/exchangemain';

    let param = {
        size: size,
        page: page
    };
    if(term != "") param['term'] = term;

    let result = await handleRequestGet(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const addExchange = async (data) => {
    let url = '/exchangemain/create';
    let result = await handleRequestPost(url,data);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateExchange = async (data) => {
    let url = '/exchangemain/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const deleteExchange = async (id) => {
    let url = '/exchangemain/delete';
    let result = await handleRequestPost(url, {
        id: id
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
