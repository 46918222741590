import React, {useState, useEffect, useMemo} from 'react';

import api from '../../../../apis';
import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';

import DropdownSelect from '../../../general/form-control/dropdown-select';
import ReactTable from '../../../general/form-control/react-table';
import statusText from '../../../../constants/statusText';
import TreeSelect from '../../../general/form-control/tree-select';
import {parseAPIResutlToData} from '../../../general/form-control/tree-select/helper';
import DataTable from 'react-data-table-component';
import {useLocation, useNavigate} from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
    convertErMsg,
    convertFuncPerm, disableAllEleInDiv,
    getDefaultAccessMat,
    getNavIdByPath,
    handleCountryChange,
    handlePopup, specialCharCheck,
    stateField
} from "../../../general/general-tools";
import { usePopupManager } from "react-popup-manager";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";


const Index = () => {
    const {setLoading} = useLoading();
    const [defaultPhoneCountry] = useState(process.env.REACT_APP_PHONE_COUNTRY);


    const [currentCompanyCode, setCurrentCompanyCode] = useState("");
    const [currentBranchCode, setCurrentBranchCode] = useState("");
    const [currentCompanyName, setCurrentCompanyName] = useState("");

    const [currentCompanyAddress1, setCurentBranchAddress1] = useState("");
    const [currentCompanyAddress2, setCurentBranchAddress2] = useState("");
    const [currentCompanyAddress3, setCurentBranchAddress3] = useState("");
    const [currentCompanyPostCode, setCurrentCompanyPostCode] = useState("");
    const [currentCompanyState, setCurrentCompanyState] = useState("");
    const [currentCompanyCountry, setCurrentCompanyCountry] = useState("");
    const [currentCompanyCountryText, setCurrentCompanyCountryText] = useState("");
    const [currentCompanyPhoneNum, setCurrentCompanyPhoneNum] = useState("");
    const [currentCompanyWebsite, setCurrentCompanyWebsite] = useState("");

    const [readOnlyBranchCode, setReadOnlyBranchCode] = useState(false);
    const [countryList, setCountryList] = useState([]);

    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat():getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);

    //Load initial data and mode
    useEffect(() => {
        if(!actualPerm['edit']){
            setTimeout(function() {
                disableAllEleInDiv("mainDiv");
            }, 50);
        }
        handleCompanyDetail();
        handleCountryList();

    }, []);


    const handleCompanyDetail = async () => {
        setLoading(true);
        api.getCompanyDetail().then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

            let companyEle = result.data.list[0];
            setCurrentCompanyCountryText(companyEle.co_name);
            setCurrentCompanyCode(companyEle.b_id)
            setCurrentBranchCode(companyEle.b_code);
            setCurrentCompanyName(companyEle.b_name);
            setCurentBranchAddress1(companyEle.b_address_line1);
            setCurentBranchAddress2(companyEle.b_address_line2);
            setCurentBranchAddress3(companyEle.b_address_line3);
            setCurrentCompanyPostCode(companyEle.b_postcode);
            setCurrentCompanyState(companyEle.b_state);
            setCurrentCompanyCountry(companyEle.b_cid);
            if(companyEle.b_phone)setCurrentCompanyPhoneNum(companyEle.b_phone);
            setCurrentCompanyWebsite(companyEle.b_website);

            setLoading(false);

        });
    };

    const handleCountryList = async () => {
        api.getCountryList(999, 1, 1, null).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

            if(result.data){
                if (result.data.list.length > 0) {
                    setCountryList(result.data.list);
                }
            }

        });
    };


    const handleSaveBtn = async () => {
        await setLoading(true);
        if (currentBranchCode == '') {
            handlePopup(true, "Error", "Mandatory Field - Company Code not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if (currentCompanyAddress1 == '') {
            handlePopup(true, "Error", "Mandatory Field - Address not entered.", popupManager)
            setLoading(false);
            return false;
        }
        if (
            currentBranchCode.trim().length > 2 && currentBranchCode.trim().length < 10
        ){
            let data = {
                "id" : currentCompanyCode,
                "code" : currentBranchCode,
                "name" : currentCompanyName ? currentCompanyName : null ,
                "phone": currentCompanyPhoneNum ? currentCompanyPhoneNum :null,
                "website" : currentCompanyWebsite? currentCompanyWebsite :null,
                "address1" : currentCompanyAddress1,
                "address2" : currentCompanyAddress2? currentCompanyAddress2 :null,
                "address3" : currentCompanyAddress3? currentCompanyAddress3 :null,
                "postcode" : currentCompanyPostCode? currentCompanyPostCode :null,
                "state" : currentCompanyState? currentCompanyState :null,
                "country" : currentCompanyCountry? currentCompanyCountry :null
            };
            api.updateCompany(data).then((result: any) => {
                if (result != false && result.status == 0)
                    handlePopup(true,"Success","Company information updated successfully.",popupManager);
                else{
                    handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                    setLoading(false);
                }
            });


        } else handlePopup(true,"Error", "Please insert all required fields.",popupManager)
        setLoading(false);
    };


    const handleResetBtn = async () => {
        await setLoading(true);
        setCurrentBranchCode("");
        setCurrentCompanyName("");
        setCurentBranchAddress1("");
        setCurentBranchAddress2("");
        setCurentBranchAddress3("");
        setCurrentCompanyPostCode("");
        setCurrentCompanyState("");
        setCurrentCompanyCountry("");
        setCurrentCompanyPhoneNum("+60");
        setCurrentCompanyWebsite("");

        setLoading(false);
    };



    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Master File
                                Maintenance &gt; Company</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Company Code</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            id="branch_code"
                                            type="text"
                                            placeholder="e.g. 001"
                                            aria-label=""
                                            value={currentBranchCode}
                                            readOnly={readOnlyBranchCode}
                                            onChange={(e) => setCurrentBranchCode(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentBranchCode,popupManager)}

                                        />
                                    </div> <b className='mandatory-red'>*</b>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Company Name</div>
                                    <div className="label col-md-10">
                                        <input
                                            autoComplete='off'
                                            className="form-control"
                                            id="branch_code"
                                            type="text"
                                            placeholder="e.g. Brokerage Corps"
                                            aria-label=""
                                            value={currentCompanyName}
                                            onChange={(e) => setCurrentCompanyName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentCompanyName,popupManager)}

                                        />

                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Address</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            id="branch_add1"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={currentCompanyAddress1}
                                            onChange={(e) => setCurentBranchAddress1(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurentBranchAddress1,popupManager)}

                                        />

                                    </div>
                                    <b className='mandatory-red'>*</b>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2"></div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            id="branch_add2"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={currentCompanyAddress2}
                                            onChange={(e) => setCurentBranchAddress2(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurentBranchAddress2,popupManager)}

                                        />

                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2"></div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            id="branch_add3"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={currentCompanyAddress3}
                                            onChange={(e) => setCurentBranchAddress3(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurentBranchAddress3,popupManager)}

                                        />

                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Post Code</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            id="branch_postcode"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={currentCompanyPostCode}
                                            onChange={(e) => setCurrentCompanyPostCode(e.target.value)}                                            onBlur={(e) => specialCharCheck(e.target.value,setCurentBranchAddress3,popupManager)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentCompanyPostCode,popupManager)}

                                        />

                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">State</div>
                                    <div className="label col-md-10">
                                        {stateField(currentCompanyState,setCurrentCompanyState,(currentCompanyCountryText == 'Malaysia' ? true : false),false,popupManager)}
                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Country</div>
                                    <div className="label col-md-10">

                                        <select
                                            className="form-control"
                                            id="branch_state"
                                            aria-label=""
                                            value={currentCompanyCountry}
                                            onChange={(e) => handleCountryChange(e.target.value,e.target.options[e.target.selectedIndex].text,setCurrentCompanyCountry,setCurrentCompanyCountryText)}
                                        >
                                            <option>Please select Country</option>
                                            {countryList.map(el => <option
                                                value={el.c_id}>{el.c_name}</option>)}
                                        </select>
                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Phone Number</div>
                                    <div className="label col-md-10">

                                        <PhoneInput
                                            containerClass='react-tel-input-full'
                                            inputClass='react-tel-input-full'
                                            dropdownClass='react-tel-input-full' country={defaultPhoneCountry}
                                            preferredCountries={['my','sg']}
                                            enableAreaCodeStretch
                                            masks={{defaultPhoneCountry: '...........'}}
                                            countryCodeEditable={false}
                                            value={currentCompanyPhoneNum}
                                            onChange={phone => setCurrentCompanyPhoneNum(phone)}
                                        />
                                    </div>

                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Website</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            id="company_website"
                                            type="text"
                                            placeholder="e.g. www.brokeragecoprs.com"
                                            aria-label=""
                                            value={currentCompanyWebsite}
                                            onChange={(e) => setCurrentCompanyWebsite(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentCompanyWebsite,popupManager)}

                                        />

                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2"></div>

                                    <div className="label col-md-10 btn_search_padding">
                                        {actualPerm['edit'] ?

                                            <>
                                                <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset</button>
                                                <button className="btn btn-primary " onClick={() => handleSaveBtn()}>Save</button>
                                            </>

                                             :""}

                                    </div>


                                </div>


                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
