import React, {useState, useEffect, useMemo, useLayoutEffect} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';
// import 'react-quill/dist/quill.snow.css';
import {convertToHTML, convertFromHTML} from 'draft-convert';
import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import {
    handleSelectChange,
    timeElement,
    holidayTypeOptions,
    userTypeCategory,
    researchCategory,
    handlePopup,
    getMimeTypes,
    disableAllEleInDiv,
    dateFormats,
    datePlaceHolder,
    performAntdTimeCheck,
    convertErMsg, momentTimeFormat, specialCharCheck
} from "../../../general/general-tools";
import {confirmAlert} from "react-confirm-alert";
import {Editor} from 'react-draft-wysiwyg';
import {EditorState, ContentState, convertFromRaw} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ModalConfirm from "../../public/ModalConfirm";
import {usePopupManager} from "react-popup-manager";
import {stateToHTML} from 'draft-js-export-html';
import {createBroadcast} from "../../../../apis/models/Broadcast";
import {stateFromHTML} from "draft-js-import-html";
import dayjs from "dayjs";
import ReactDatepick from '../../../general/form-control/react-datepick';
import {DatePicker} from "antd";


const Index = () => {
    const {setLoading} = useLoading();

    const [currentMode, setCurrentMode] = useState("");
    const [exchangeList, setExchangeList] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const [brId, setBrId] = useState("");
    const [brTitle, setBrTitle] = useState("");
    const [brSource, setBrSource] = useState("");
    const [brCreateDate, setBrCreateDate] = useState("");
    const [brCreator, setBrCreator] = useState("");
    const [brLstModifyDate, setBrLstModifyDate] = useState("");
    const [brEditor, setBrEditor] = useState("");
    const [brType, setBrType] = useState(1);
    const [brPublishStart, setBrPublishStart] = useState("");
    const [brPublishDate, setBrPublishDate] = useState("");
    const [brDocumentId, setBrDocumentId] = useState("");
    const [brPublishEnd, setBrPublishEnd] = useState("");

    const [brExchange, setBrExchange] = useState([]);
    const [brApprovalDate, setBrApprovalDate] = useState("");
    const [brSymbol, setBrSymbol] = useState([]);
    const [brSymbolList, setBrSymbolList] = useState([]);
    const [brApprover, setBrApprover] = useState("");
    const [brStatus, setBrStatus] = useState("");
    const [brUserType, setBrUserType] = useState([]);
    const [brUserTypeList, setBrUserTypeList] = useState([]);

    const [brVersion, setBrVersion] = useState("");
    const [brDesc, setBrDesc] = useState("");

    const [researchCat, setResearchCat] = useState("");
    const [researchCatList, setResearchCatList] = useState([]);
    const [anncType, setAnncType] = useState(2);
    const [fileContentPath, setFileContentPath] = useState("");
    const [fileThumbPath, setFileThumbPath] = useState("");
    const [fileContentName, setFileContentName] = useState("");
    const [fileThumbName, setFileThumbName] = useState("");

    const [fileUploadType, setFileUploadType] = useState("1");
    const [prevContentName, setPrevContentName] = useState("");
    const [prevThumbName, setPrevThumbName] = useState("");
    const [isReadOnly, setIsReadOnly] = useState(false);

    const [userTypeSelAllLbl, setUserTypeSelAllLbl] = useState("User Type");
    const [hideUTItems, setHideUTItems] = useState(false);
    const [exchangeSelAllLbl, setExchangeSelAllLbl] = useState("Exchange");
    const [hideExcItems, setHideExcItems] = useState(false);

    const [accessItem, setAccessItem] = useState({});

    const [toggleReset, setToggleReset] = useState({});
    const [isDateValid, setIsDateValid] = useState(false);


    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();
    let fileSizeLbl = process.env.REACT_APP_FILE_LIMIT_LBL;
    let fileSize = process.env.REACT_APP_FILE_LIMIT_SIZE;


    useLayoutEffect(() => {
        setLoading(true);

        window.scrollTo({top: 0, behavior: 'smooth'});
        handleExchangeList();
        handleResearchCategory();

        if (location.state) {
            if (location.state.row) {
                handleInitData(location.state.row.b_id, location.state.row.b_type, location.state.mode);
            } else {
                handleUserTypeList(null);
            }
            setAccessItem(location.state.perm);
            if (!location.state.perm['edit']) {
                setTimeout(function () {
                    setIsReadOnly(true)
                    disableAllEleInDiv("mainDiv");
                }, 50);
            }

            setCurrentMode(location.state.mode)
            if (location.state.mode.toLowerCase() == 'edit') {
                let cls_edit = document.getElementsByClassName("cls_edit");
                for (var i = 0; i < cls_edit.length; i++) {
                    cls_edit[i].classList.remove('divHide');
                }

            }
        }

    }, []);

    useEffect(() => {
        if (brType == 1) {
            performAntdTimeCheck(brPublishDate, brPublishEnd, setBrPublishEnd, popupManager, setIsDateValid);
        } else if (brType == 2 || brType == 3) {
            performAntdTimeCheck(brPublishStart, brPublishEnd, setBrPublishEnd, popupManager, setIsDateValid);
        }

    }, [brPublishDate, brPublishEnd, brPublishStart]);

    const handleInitData = async (id, broadcast_type, curMode) => {

        let param = {
            id: id,
            broadcast_type: broadcast_type
        }
        api.getBroadcastDetail(param).then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }

            setLoading(false);

            if (result.data) {
                if (result.data.list.length > 0) {
                    let dataList = result.data.list[0];
                    let navType = '';
                    if (dataList['b_type'] == 1) navType = 'btn_research'
                    if (dataList['b_type'] == 2 || dataList['b_type'] == 3) navType = 'btn_announcement'
                    if (dataList['b_type'] == 4) navType = 'btn_tnc'
                    let nav_btn = document.getElementById(navType);
                    nav_btn.classList.remove('divHide');
                    nav_btn.classList.add('inFormTopNavBtnSelected');
                    nav_btn.click();

                    setBrId(dataList['b_id']);
                    setBrTitle(dataList['title']);
                    setBrSource(dataList['br_source']);
                    setBrCreateDate(dataList['b_create_date'] ?? "");
                    setBrCreator(dataList['b_create_by'] ?? "");
                    setBrEditor(dataList['b_modify_by'] ?? "");
                    setBrLstModifyDate(dataList['b_modify_date'] == "Invalid date" ? "-" : dataList['b_modify_date']);
                    setBrPublishStart(dataList['b_publish_date_start'] == "Invalid date" ? "" : dataList['b_publish_date_start']);
                    setBrPublishDate(dataList['b_publish_date_start'] == "Invalid date" ? "" : dataList['b_publish_date_start']);
                    setBrDocumentId(dataList['b_doc_id']);
                    setBrPublishEnd(dataList['b_publish_date_end'] == "Invalid date" ? "" : dataList['b_publish_date_end']);
                    setBrApprovalDate(dataList['b_approved_date'] == "Invalid date" ? "-" : dataList['b_approved_date']);
                    setBrApprover(dataList['b_approved_by']);
                    setBrStatus(dataList['b_status'] == 0 ? "Pending Approval" : dataList['b_status'] == 1 ? "Approved" : dataList['b_status'] == 2 ? "Rejected" : dataList['b_status'] == 3 ? "Published" : dataList['b_status'] == 4 ? "Rejected" : "-");
                    setResearchCat(dataList['br_research_category']);
                    setBrType(dataList['b_type']);
                    setAnncType(dataList['b_type']);
                    setBrVersion(dataList['bt_version']);
                    setBrDesc(dataList['tnc_desc']);

                    if (dataList['b_status'] == 1) {
                        setIsReadOnly(true);
                    }

                    if (curMode.toLowerCase() == 'edit' && dataList['file_content_path']) {
                        let file = dataList['file_content_path'];
                        let types = getMimeTypes();
                        let mimeType = types[file.split('.').pop()] || null;

                        if (mimeType == 'text/html') {
                            api.getFile(file || '').then((result: any) => {

                                let linkSource =
                                    'data:' +
                                    mimeType +
                                    ';base64,' +
                                    btoa(
                                        new Uint8Array(result).reduce(
                                            (data, byte) => data + String.fromCharCode(byte),
                                            ''
                                        )
                                    );

                                var rawFile = new XMLHttpRequest();
                                rawFile.open("GET", linkSource, false);
                                rawFile.onreadystatechange = function () {
                                    if (rawFile.readyState === 4) {
                                        if (rawFile.status === 200 || rawFile.status == 0) {
                                            var allText = rawFile.responseText;
                                            setEditorState(EditorState.createWithContent(stateFromHTML(allText)));
                                        }
                                    }
                                }
                                rawFile.send(null);
                            });
                        } else {
                            setFileUploadType("2")
                        }
                    }

                    if (dataList['file_content_path']) {
                        let fileItem = dataList['file_content_path'];
                        setFileContentPath("/file/" + fileItem);
                    }

                    if (dataList['thumbnail_path']) {
                        let fileItem = dataList['thumbnail_path'];
                        setFileThumbPath("/file/" + fileItem);
                    }


                    if (dataList['file_content_name']) {
                        let fileItem = dataList['file_content_name'];
                        setFileContentName(fileItem)
                        setPrevContentName(fileItem)

                    } else {
                        let fileItem = dataList['file_content_path'];
                        let name = fileItem.split("/");
                        setFileContentName(name[name.length - 1])
                        setPrevContentName(name[name.length - 1])

                    }

                    if (dataList['thumbnail_name']) {
                        let fileItem = dataList['thumbnail_name'];
                        setFileThumbName(fileItem)
                        setPrevThumbName(fileItem)

                    } else {
                        let fileItem = dataList['thumbnail_path'];
                        let name = fileItem.split("/");
                        setFileThumbName(name[name.length - 1])
                        setPrevThumbName(name[name.length - 1])

                    }

                    if (dataList['exchanges']) {
                        let exchangeList = JSON.parse(dataList['exchanges']);
                        handleExchangeList(exchangeList)
                        // let exchange = [];
                        //
                        // for (let j = 0; j < exchangeList.length; j++) {
                        //     let currentEx = {};
                        //     currentEx['id'] = exchangeList[j]['em_id'];
                        //     currentEx['name'] = exchangeList[j]['em_code'];
                        //     exchange.push(currentEx);
                        // }
                        // setBrExchange(exchange);
                    }

                    if (dataList['tickers']) {
                        let tickerList = JSON.parse(dataList['tickers']);
                        let ticker = [];

                        for (let j = 0; j < tickerList.length; j++) {
                            let currentTic = {};
                            currentTic['id'] = tickerList[j]['t_id'];
                            currentTic['exchange'] = tickerList[j]['em_code'];
                            currentTic['symbol'] = tickerList[j]['t_code'];
                            currentTic['stockname'] = tickerList[j]['t_name'];
                            ticker.push(currentTic);
                        }
                        setBrSymbol(ticker);
                    }

                    if (dataList['usertypes']) {
                        let userTypeList = JSON.parse(dataList['usertypes']);
                        handleUserTypeList(userTypeList);

                    }
                }
            }


        });
    };

    const handleResearchCategory = async () => {
        //setLoading(true);
        api.getResearchCat().then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }

            if (result.data) {
                if (result.data.list.length > 0) {
                    setResearchCatList(result.data.list)
                }
            }

        });
        //setLoading(false);
    };

    const handleExchangeList = async (currentList = null) => {

        //setLoading(true);
        api.getExchangeMainList(999, 1, 1, null).then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }

            if (result.data) {
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdown(dataList);
                    setExchangeList(TreeData);

                    if (exchangeList != null) {
                        let exchanges = [];

                        if (currentList.length == TreeData.length - 1) {
                            exchanges.push({
                                name: "Select All",
                                id: 0
                            });
                            setExchangeSelAllLbl("[All Exchange]")
                            setHideExcItems(true);
                        }
                        for (let j = 0; j < currentList.length; j++) {
                            let currentUt = {};
                            currentUt['name'] = currentList[j]['em_code'];
                            currentUt['id'] = currentList[j]['em_id'];
                            exchanges.push(currentUt);
                        }

                        setBrExchange(exchanges);
                    }

                }
            }
        });
        //setLoading(false);
    };

    const handleTickerList = async (searchTerm, searchSymbols) => {
        let searchTermStr = "";
        for (let i = 0; i < searchTerm.length; i++) {
            searchTermStr += searchTerm[i]['id'] + ","
        }
        searchTermStr = searchTermStr.substring(0, searchTermStr.length - 1)
        //setLoading(true);
        api.getBroadcastTicker(999, 1, null, searchSymbols == "" ? null : searchSymbols, searchTermStr).then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }
            if (result.status == 0 && result.data) {
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdownTicker(dataList);
                    setBrSymbolList(TreeData);
                }


            }
        });
        //setLoading(false);
    };

    const handleUserTypeList = async (currentList = null) => {
        //setLoading(true);
        let param = {
            size: 999,
            page: 1,
            is_opr: false
        };
        api.getAuthLimitListUserType(param).then((result: any) => {
            setLoading(false);
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }
            if (result.data) {
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdownUt(dataList);
                    setBrUserTypeList(TreeData);

                    if (currentList != null) {
                        let userType = [];
                        if (currentList.length == TreeData.length - 1) {
                            userType.push({
                                name: "Select All",
                                id: 0
                            });
                            setUserTypeSelAllLbl("[All User Types]")
                            setHideUTItems(true);
                        }
                        for (let j = 0; j < currentList.length; j++) {
                            let currentUt = {};
                            currentUt['name'] = currentList[j]['ut_name'];
                            currentUt['id'] = currentList[j]['ut_id'];
                            userType.push(currentUt);
                        }
                        setBrUserType(userType);
                    }


                }
            }

        });
    };

    const onExchangeHandle = (selectedList, selectedItem) => {
        setBrExchange(selectedList);
        const checkUsername = obj => obj.id == 0;
        if (selectedItem.id == 0) {
            handleTickerList(exchangeList, "");
            setBrExchange(exchangeList);
            if (!selectedList.some(checkUsername)) {
                setBrExchange([]);
                setExchangeSelAllLbl("Exchange")
                setHideExcItems(false);
            } else setExchangeSelAllLbl("[All Exchange]")
            setHideExcItems(true);
        } else {
            handleTickerList(selectedList, "");

            setExchangeSelAllLbl("Exchange")
            setHideExcItems(false);

            setBrExchange([]);
            let tempArr = [];
            for (let i = 0; i < selectedList.length; i++) {
                if (selectedList[i]['id'] == 0) continue;
                tempArr.push({name: selectedList[i]['name'], id: selectedList[i]['id']});
            }
            setBrExchange(tempArr);
        }


    }

    const onSymbolHandle = (selectedList, selectedItem) => {
        setBrSymbol(selectedList);
    }

    const onUserTypeHandle = (selectedList, selectedItem) => {

        const checkUsername = obj => obj.id == 0;
        if (selectedItem.id == 0) {
            setBrUserType(brUserTypeList);
            if (!selectedList.some(checkUsername)) {
                setBrUserType([]);
                setUserTypeSelAllLbl("User Type")
                setHideUTItems(false);
            } else setUserTypeSelAllLbl("[All User Types]")
            setHideUTItems(true);
        } else {
            setUserTypeSelAllLbl("User Type")
            setHideUTItems(false);

            setBrUserType([]);
            let tempArr = [];
            for (let i = 0; i < selectedList.length; i++) {
                if (selectedList[i]['id'] == 0) continue;
                tempArr.push({name: selectedList[i]['name'], id: selectedList[i]['id']});
            }
            setBrUserType(tempArr);
        }
    }

    const onEditorChangeHandle = async (editorState) => {
        setEditorState(editorState)
    }

    const uploadCallback = async (file) => {
        return new Promise(
            (resolve, reject) => {
                if (file) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        resolve({data: {link: e.target.result}})
                    };
                    reader.readAsDataURL(file);
                }
            }
        );
    }


    const convertRawIntoDropdown = (data) => {
        var returnDat = [];
        if (data.length > 0) {
            returnDat.push({
                name: "Select All",
                id: 0
            });
        }

        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                name: data[i]['em_code'],
                id: data[i]['em_id']
            });
        }
        return returnDat;
    }

    const convertRawIntoDropdownUt = (data) => {
        var returnDat = [];
        if (data.length > 0) {
            returnDat.push({
                name: "Select All",
                id: 0
            });
        }
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                name: data[i]['ut_name'],
                id: data[i]['ut_id']
            });
        }
        return returnDat;
    }


    const convertRawIntoDropdownTicker = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                id: data[i]['t_id'],
                exchange: data[i]['em_code'],
                symbol: data[i]['t_code'],
                stockname: data[i]['t_name']
            });
        }
        return returnDat;
    }

    const handleDateChange = (fromDate, toDate, fromSetter, toSetter) => {
        fromSetter(fromDate);
        toSetter(toDate);
        performAntdTimeCheck(fromDate, toDate, toSetter, popupManager, setIsDateValid)

    }

    // Edit
    const handleBackBtn = async () => {
        // await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "br_entry", {state: {mode: 'back'}});
        //setLoading(false);
    };


    const handleResetBtn = async () => {
        // await setLoading(true);

        setBrTitle("");
        setBrSource("");
        setBrCreateDate("");
        setBrCreator("");
        setBrLstModifyDate("");
        setBrEditor("");
        setBrPublishStart("");
        setBrPublishDate("");
        setBrDocumentId("");
        setBrPublishEnd("");
        setBrApprovalDate("");
        setBrApprover("");
        setBrStatus("");
        setBrStatus("");
        setBrVersion("");
        setBrDesc("");
        setResearchCat("");
        setFileContentPath("");
        setFileThumbPath("");
        setFileContentName("");
        setFileThumbName("");
        setFileThumbName("");
        setPrevContentName("");
        setPrevThumbName("");
        setUserTypeSelAllLbl("User Type");
        setHideUTItems(false);
        setHideExcItems(false);
        setBrExchange([]);
        setBrSymbol([]);
        setBrSymbolList([]);
        setBrUserType([]);
        setToggleReset(!toggleReset)
        document.getElementById('research-content-file').value = "";
        document.getElementById('research-thumb-file').value = "";
        document.getElementById('announcement-content-file').value = "";
        document.getElementById('announcement-thumb-file').value = "";
        document.getElementById('tnc-content-file').value = "";


        //setLoading(false);
    };

    const handleResetFileContent = (id) => {
        //setLoading(true);

        if (id) {
            let category = id.split("-")[0];
            document.getElementById(category + '_uploadContent').classList.remove('divHide');
            document.getElementById(category + '_removeContent').classList.add('divHide');
        }

        setFileContentName("");
        setFileContentPath("");
        document.getElementById(id).value = "";
        //setLoading(false);
    };

    const handleResetThumbContent = (id) => {

        if (id) {
            let category = id.split("-")[0];
            document.getElementById(category + '_uploadThumb').classList.remove('divHide');
            document.getElementById(category + '_removeThumb').classList.add('divHide');
        }

        //setLoading(true);
        setFileThumbName("");
        setFileThumbPath("");
        document.getElementById(id).value = "";
        //setLoading(false);
    };


    const handleProcessUpdates = async (type) => {
        // await setLoading(true);

        if (brType == 1) {
            var researchFile = document.getElementById("research-content-file");

            //add
            if (fileContentPath == "") {
                handlePopup(true, "Error", "Content file is required.", popupManager)
                // await setLoading(false);
                return false;
            }
            handleSaveOpr(type, fileContentPath, fileThumbPath);


        } else if (brType == 2 || brType == 3) {
            var announceContent = document.getElementById("announcement-content-file");
            let promiseUpload;

            if (announceContent.files.length == 0 && fileUploadType == "2") {
                handlePopup(true, "Error", "Content file is required.", popupManager)
                // await setLoading(false);
                return false;
            }

            if (fileUploadType == "1") {
                promiseUpload = new Promise((resolve, reject) => {
                    let convertToHtml = stateToHTML(editorState.getCurrentContent());
                    const fileBlob = new Blob([convertToHtml], {type: 'text/plain'});
                    const formData = new FormData();
                    formData.append("file", fileBlob, "file.html");
                    api.uploadFile(formData).then((result: any) => {
                        if (result) {
                            if (result.status == 1) {
                                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                                setLoading(false);
                                return false
                            }

                            if (result.data.file) {
                                resolve(result)
                            }
                        } else {
                            reject("Error Occured")
                            handlePopup(true, "Error", "Exceeded maximum file size allowed or format not supported.", popupManager)
                            //setLoading(false);

                        }
                    });
                });

                promiseUpload.then(result => {
                    console.log("promise")
                    while (!result.data.file) {
                        const myTimeout = setTimeout(myGreeting, 100);

                        function myStopFunction() {
                            clearTimeout(myTimeout);
                        }
                    }
                    if (result.data.file) {
                        setFileContentPath("/file/" + result.data.file);
                        setFileContentName(result.data.file)
                        handleSaveOpr(type, "/file/" + result.data.file, fileThumbPath);
                    }

                }, function (error) {
                    console.log(error)
                });
            } else {
                handleSaveOpr(type, fileContentPath, fileThumbPath);

            }


        } else {

            if (fileContentPath == "") {
                handlePopup(true, "Error", "Content file is required.", popupManager)
                // await setLoading(false);
                return false;
            }
            handleSaveOpr(type, fileContentPath, fileThumbPath);

        }


    }


    const handleSaveOpr = async (type, contentFile, thumbFile) => {

        let param = {};
        let saveTermExg = "";
        for (let i = 0; i < brExchange.length; i++) {
            if (brExchange[i]['id'] == 0) continue;
            saveTermExg += brExchange[i]['id'] + ","
        }
        saveTermExg = saveTermExg.substring(0, saveTermExg.length - 1)

        let saveTermStr = "";
        for (let i = 0; i < brSymbol.length; i++) {
            saveTermStr += brSymbol[i]['id'] + ","
        }
        saveTermStr = saveTermStr.substring(0, saveTermStr.length - 1)

        let saveTermUt = "";
        for (let i = 0; i < brUserType.length; i++) {
            if (brUserType[i]['id'] == 0) continue;
            saveTermUt += brUserType[i]['id'] + ","
        }
        saveTermUt = saveTermUt.substring(0, saveTermUt.length - 1)


        if (brTitle.trim() == "") {
            handlePopup(true, "Error", "Title is required.", popupManager)
            // await setLoading(false);
            return false;
        }

        if (brUserType.length == 0) {
            handlePopup(true, "Error", "User type is required.", popupManager)
            // await setLoading(false);
            return false;
        }

        if (brType == 1) {//Research

            if (brSource.trim() == "") {
                handlePopup(true, "Error", "Source is required.", popupManager)
                // await setLoading(false);
                return false;
            }

            if (dayjs(brPublishDate).format(momentTimeFormat()) == "Invalid Date") {
                handlePopup(true, "Error", "Publish Date is required.", popupManager)
                // await setLoading(false);
                return false;
            }


            if (!isDateValid) {
                handlePopup(true, "Error", "Please ensure that the ending date is not earlier than the starting date.", popupManager);
                await setLoading(false);
                return false;
            }


            param = {
                broadcast_type: brType,
                title: brTitle,
                publish_date_start: brPublishDate,
                publish_date_end: brPublishEnd == "" ? null : brPublishEnd == "-" ? null : brPublishEnd,
                research_source: brSource,
                research_category: "1",
                exchange: saveTermExg == "" ? null : saveTermExg,
                ticker: saveTermStr == "" ? null : saveTermStr,
                user_type: saveTermUt == "" ? null : saveTermUt,
                content_file_path: contentFile.replace("/file/", ""),
                thumbnail_path: thumbFile.replace("/file/", ""),
                content_file_name: fileContentName ?? "",
                thumbnail_name: fileThumbName ?? ""
            };

            console.log(param)


        } else if (brType == 2 || brType == 3) { //Announcement OR Event

            if (dayjs(brPublishStart).format(momentTimeFormat()) == "Invalid Date" && dayjs(brPublishEnd).format(momentTimeFormat()) == "Invalid Date") {

                handlePopup(true, "Error", "Publish Start and End Dates are required.", popupManager)
                // await setLoading(false);
                return false;
            }
            if (dayjs(brPublishStart).format(momentTimeFormat()) == "Invalid Date") {
                handlePopup(true, "Error", "Publish Start Date is required.", popupManager)
                // await setLoading(false);
                return false;
            }

            if (dayjs(brPublishEnd).format(momentTimeFormat()) == "Invalid Date") {
                handlePopup(true, "Error", "Publish End Date is required.", popupManager)
                // await setLoading(false);
                return false;
            }


            const date1 = moment(brPublishStart, 'YYYY-MM-DD').valueOf()
            const date2 = moment(brPublishEnd, 'YYYY-MM-DD').valueOf()

            if (!isDateValid) {
                handlePopup(true, "Error", "Please ensure that the ending date is not earlier than the starting date.", popupManager);
                await setLoading(false);
                return false;
            }

            param = {
                broadcast_type: anncType,
                title: brTitle,
                publish_date_start: brPublishStart,
                publish_date_end: brPublishEnd,
                exchange: saveTermExg == "" ? null : saveTermExg,
                ticker: saveTermStr == "" ? null : saveTermStr,
                user_type: saveTermUt == "" ? null : saveTermUt,
                content_file_path: contentFile.replace("/file/", ""),
                thumbnail_path: thumbFile.replace("/file/", ""),
                content_file_name: fileContentName == "" ? "broadcast.html" : fileContentName,
                thumbnail_name: fileThumbName ?? ""
            }

        } else { //TnC

            if (dayjs(brPublishDate).format(momentTimeFormat()) == "Invalid Date") {
                handlePopup(true, "Error", "Publish Date is required.", popupManager)
                // await setLoading(false);
                return false;
            }

            param = {
                broadcast_type: brType,
                title: brTitle,
                tnc_desc: brDesc,
                version: brVersion,
                publish_date_start: brPublishDate,
                exchange: saveTermExg == "" ? null : saveTermExg,
                user_type: saveTermUt == "" ? null : saveTermUt,
                content_file_path: contentFile.replace("/file/", ""),
                thumbnail_path: thumbFile.replace("/file/", ""),
                content_file_name: fileContentName ?? "",
                thumbnail_name: fileThumbName ?? ""
            }
        }


        if (type == 'save') {
            param['id'] = brId;
            api.updateBroadcast(param).then((result: any) => {
                if (result.status == 1) {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    setLoading(false);
                    return false;
                }
                if (result != false && result.status == 0) {
                    handlePopup(true, "Success", "Broadcast information updated successfully.", popupManager, true, "br_entry", navigate)
                }

                //setLoading(false);

            });

        } else {
            api.createBroadcast(param).then((result: any) => {
                if (result.status == 1) {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    setLoading(false);
                    return false;
                }

                if (result != false && result.status == 0) {
                    setLoading(false);
                    handlePopup(true, "Success", "Broadcast created successfully.", popupManager, true, "br_entry", navigate)
                }

                //setLoading(false);

            });
        }


    };


    const handleTopBtnClicked = (portion) => {

        let nav_btn = document.getElementsByClassName("nav_btn");
        for (var i = 0; i < nav_btn.length; i++) {
            nav_btn[i].classList.remove('inFormTopNavBtnSelected');
            nav_btn[i].classList.add('inFormTopNavBtn');
        }
        switch (portion) {
            case "research" :
                setBrType(1)
                break;
            case "announcement" :
                setBrType(2)
                break;
            case "tnc" :
                setBrType(4)
                break;
        }

        document.getElementById("research_uploadContent").classList.remove('divHide');
        document.getElementById("research_removeContent").classList.add('divHide');
        document.getElementById("research_uploadThumb").classList.remove('divHide');
        document.getElementById("research_removeThumb").classList.add('divHide');
        document.getElementById("announcement_uploadContent").classList.remove('divHide');
        document.getElementById("announcement_removeContent").classList.add('divHide');
        document.getElementById("announcement_uploadThumb").classList.remove('divHide');
        document.getElementById("announcement_removeThumb").classList.add('divHide');
        document.getElementById("tnc_uploadContent").classList.remove('divHide');
        document.getElementById("tnc_removeContent").classList.add('divHide');
        document.getElementById('research-content-file').value = "";
        document.getElementById('research-thumb-file').value = "";
        document.getElementById('announcement-content-file').value = "";
        document.getElementById('announcement-thumb-file').value = "";
        document.getElementById('tnc-content-file').value = "";
        setFileContentPath("");
        setFileThumbPath("");
        setFileContentName("");
        setFileThumbName("");
        setPrevContentName("");
        setPrevThumbName("");

        document.getElementById("btn_" + portion).classList.remove('inFormTopNavBtn');
        document.getElementById("btn_" + portion).classList.add('inFormTopNavBtnSelected');
        document.getElementById("div_research").classList.add('divHide');
        document.getElementById("div_announcement").classList.add('divHide');
        document.getElementById("div_tnc").classList.add('divHide');
        document.getElementById("div_" + portion).classList.remove('divHide');

    }

    const renderButtonByMode = (mode) => {
        if (mode.toLowerCase() == 'edit') {
            return (
                <div className="label col-md-11 btn_search_padding">
                    <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>
                    {accessItem['delete'] ?
                        <button className="btn btn-danger " onClick={() => handleRemove()}>Delete</button> : ""}
                    {accessItem['edit'] ? <button className="btn btn-primary " id="btn_add"
                                                  disabled={brStatus == "Approved" ? true : false}
                                                  onClick={() => handleProcessUpdates("save")}
                    >Save
                    </button> : ""}


                </div>

            )
        } else {
            let nav_btn = document.getElementsByClassName("nav_btn");
            for (var i = 0; i < nav_btn.length; i++) {
                nav_btn[i].classList.remove('divHide');
            }
            return (
                <div className="label col-md-11 btn_search_padding">
                    <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>
                    <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset</button>
                    <button className="btn btn-primary "
                            onClick={() => handleProcessUpdates("add")}
                    >Add Broadcast
                    </button>
                </div>)
        }
    }


    const handleRemove = async () => {

        confirmAlert({
            title: "Delete",
            message: <div id="area_remark">
                <hr/>
                <div>
                    Are you sure you want to delete selected broadcast?
                </div>

            </div>,
            buttons: [{label: 'Yes', onClick: () => handleRemoveItem(true)}, {
                label: 'Close',
            }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });

        setLoading(false);
    };

    const handleRemoveItem = async (selBoolean) => {
        if (!selBoolean) return;
        // await setLoading(true);

        let param = {id: brId.toString()};
        api.deleteBroadcast(param).then((result: any) => {
            if (result != false && result.status == 0) {
                // await setLoading(true);
                setLoading(false);
                handlePopup(true, "Success", "Broadcast deleted successfully.", popupManager, true, "br_entry", navigate)
            } else {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
            }
        });

    };

    const onHandleSymbolSearch = (searchItem) => {
        if (searchItem.length > 2) handleTickerList(brExchange, searchItem);
        else if (searchItem.length == 0) handleTickerList(brExchange, "");
    }

    const onHandleFileUpload = async (element, setter, nameSetter, size = fileSize) => {
        //setLoading(true);


        let elements = document.getElementById(element);


        if (elements.files.length > 0) {


            if ((elements.files[0].size / 1024 / 1024) > size) {
                //setLoading(false);
                elements.value = "";
                handlePopup(true, "Error", "Mandatory Field - File size exceed maximum size ir format not supported.", popupManager)
                //setLoading(false);

                return false;

            } else {
                const formData = new FormData();
                formData.append("file", elements.files[0], elements.files[0]['name'] ?? "-");


                if(elements.files[0]['name'].length){
                    if(elements.files[0]['name'].length > 150){
                        handlePopup(true, "Error", "Mandatory Field - File name exceed maximum length.", popupManager)
                        elements.value = "";
                        //setLoading(false);
                        return false;
                    }
                }
                await api.uploadFile(formData).then((result: any) => {
                    if (result.status == 1) {
                        handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                        setLoading(false);
                        return false;
                    }

                    if (result) {
                        if (result.data.file) {
                            setter("/file/" + result.data.file);
                            nameSetter(elements.files[0]['name']);
                            // nameSetter(elements.files[0]['name'].slice(-30).replace(/-/g, ''));
                            let category = element.split("-")[0];
                            let itemType = element.split("-")[1];
                            if (itemType == 'content') {
                                document.getElementById(category + '_uploadContent').classList.add('divHide');
                                document.getElementById(category + '_removeContent').classList.remove('divHide');
                            } else {
                                document.getElementById(category + '_uploadThumb').classList.add('divHide');
                                document.getElementById(category + '_removeThumb').classList.remove('divHide');
                            }
                        }
                    } else {
                        handlePopup(true, "Error", "Mandatory Field - File size exceed maximum size ir format not supported.", popupManager)
                        elements.value = "";
                        //setLoading(false);

                        return false;

                    }
                    //setLoading(false);
                });
            }
        } else {
            handlePopup(true, "Error", "No file selected.", popupManager)

            //setLoading(false);

        }


    }

    //Editor file upload
    function onHandleHtmlFileUpload(state, setter, nameSetter) {
        //setLoading(true);
        let convertToHtml = stateToHTML(state.getCurrentContent());
        const fileBlob = new Blob([convertToHtml], {type: 'text/plain'});
        const formData = new FormData();
        formData.append("file", fileBlob, "file.html");
        api.uploadFile(formData).then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }
            if (result) {
                if (result.data.file) {
                    setter("/file/" + result.data.file);
                    nameSetter(result.data.file);
                    return result.data.file;
                }
            } else {
                elements.value = "";
                handlePopup(true, "Error", "Exceeded maximum file size allowed or format not supported or format not supported.", popupManager)
            }
        });

        //setLoading(false);

    }

    return (
        <div className="pt-3 text-left " id='mainDiv'>
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div
                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration  &gt; Broadcast
                                Maintenance &gt; Entry - {currentMode}</h5>
                            <div className="dropdown no-arrow">

                            </div>

                        </div>


                    </div>

                </div>

                <div className="col-xl-12 col-lg-12">
                    <div className="">
                        <button id='btn_research' className="nav_btn inFormTopNavBtnSelected divHide"
                                onClick={() => handleTopBtnClicked('research')}>Research
                        </button>
                        <button id='btn_announcement' className="nav_btn  inFormTopNavBtn divHide"
                                onClick={() => handleTopBtnClicked('announcement')}>Announcement / Event
                        </button>
                        <button id='btn_tnc' className="nav_btn  inFormTopNavBtn divHide"
                                onClick={() => handleTopBtnClicked('tnc')}>T&C
                        </button>
                    </div>
                    <div className="card shadow mb-4">


                        <div className="card-body">

                            {/*ResearchView*/}
                            <div id="div_research" className="col-12 search-container  ">
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Title</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="e.g. On The Spot Event"
                                            aria-label=""
                                            value={brTitle}
                                            readOnly={isReadOnly}
                                            maxLength={256}
                                            onChange={(e) => setBrTitle(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value, setBrTitle, popupManager)}

                                        />
                                    </div>
                                    <b className='mandatory-red'>*</b>

                                </div>
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Research Category</div>
                                    <div className="label col-md-5">
                                        <select
                                            className="form-control selectItem"
                                            aria-label=""
                                            value={researchCat}
                                            readOnly={isReadOnly}
                                            onChange={e => handleSelectChange(e.target.value, setResearchCat, false)}
                                        >
                                            <option>Please select research category</option>
                                            {researchCatList.map(el => <option
                                                value={el.rc_id}>{el.rc_name}</option>)}
                                        </select>

                                    </div>

                                    <div className="label col-md-1 cls_research">
                                        <b className='mandatory-red-inside'>*</b>
                                        Source
                                    </div>
                                    <div className="col-md-5 cls_research">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder=""
                                            readOnly={isReadOnly}
                                            aria-label=""
                                            value={brSource}
                                            maxLength={256}
                                            onChange={(e) => setBrSource(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value, setBrSource, popupManager)}

                                        />

                                    </div>

                                </div>
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Exchange</div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={exchangeList} // Options to display in the dropdown
                                            selectedValues={brExchange} // Preselected value to persist in dropdown
                                            onSelect={onExchangeHandle} // Function will trigger on select event
                                            onRemove={onExchangeHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            disable={isReadOnly}
                                            placeholder={exchangeSelAllLbl}
                                            hideSelectedList={hideExcItems}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                    <div id='' className="label col-md-1 ">
                                        <b className='mandatory-red-inside'>*</b>
                                        Publish Date
                                    </div>
                                    <div id='' className="label col-md-5 ">
                                        <ReactDatepick
                                            disabled={isReadOnly}
                                            value={brPublishDate}
                                            dateSetter={setBrPublishDate}
                                            resetToggle={toggleReset}
                                        />
                                    </div>

                                </div>
                                <div className="input-container fullWidthSearch ">
                                    <div className="label col-md-1  ">Symbol</div>
                                    <div className=" col-md-5 ">
                                        <Multiselect
                                            options={brSymbolList} // Options to display in the dropdown
                                            selectedValues={brSymbol} // Preselected value to persist in dropdown
                                            onSelect={onSymbolHandle} // Function will trigger on select event
                                            onRemove={onSymbolHandle} // Function will trigger on remove event
                                            displayValue="stockname" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            disable={isReadOnly}
                                            onSearch={onHandleSymbolSearch}
                                            placeholder="Symbol"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="label col-md-1  ">
                                        Publish End
                                    </div>
                                    <div className="label col-md-5  ">

                                        <ReactDatepick
                                            disabled={isReadOnly}
                                            value={brPublishEnd}
                                            dateSetter={setBrPublishEnd}
                                            resetToggle={toggleReset}
                                        />


                                    </div>
                                </div>
                                <div className="input-container fullWidthSearch ">
                                    <div className="label col-md-1">
                                        <b className='mandatory-red-inside'>*</b>
                                        User Type
                                    </div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={brUserTypeList} // Options to display in the dropdown
                                            selectedValues={brUserType} // Preselected value to persist in dropdown
                                            onSelect={onUserTypeHandle} // Function will trigger on select event
                                            onRemove={onUserTypeHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            disable={isReadOnly}
                                            placeholder={userTypeSelAllLbl}
                                            hideSelectedList={hideUTItems}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="input-container cls_edit divHide fullWidthSearch ">
                                    <div className="label col-md-1 ">Created By</div>
                                    <div className="label col-md-5 ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brCreator}
                                            onChange={(e) => setBrCreator(e.target.value)}
                                        />
                                    </div>

                                    <div className="label col-md-1   ">Created Date</div>
                                    <div className="label col-md-5   ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brCreateDate}
                                            onChange={(e) => setBrCreateDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="input-container cls_edit divHide fullWidthSearch ">
                                    <div className="label col-md-1 ">Last Modified</div>
                                    <div className="label col-md-5 ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brEditor}
                                            onChange={(e) => setBrEditor(e.target.value)}
                                        />
                                    </div>

                                    <div className="label col-md-1   ">Last Modified Date</div>
                                    <div className="label col-md-5   ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brLstModifyDate}
                                            onChange={(e) => setBrLstModifyDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="input-container cls_edit divHide  fullWidthSearch ">
                                    <div className="label col-md-1 ">Status</div>
                                    <div className="label col-md-5 ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brStatus}
                                            onChange={(e) => setBrStatus(e.target.value)}
                                        />
                                    </div>


                                </div>

                                <hr/>
                                {/*File upload section*/}
                                <div className="label col-md-3"><b>File Upload</b></div>
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-2">Content File</div>
                                    <div className="label col-md-4">
                                        <input className="form-control" readOnly={isReadOnly} id="research-content-file"
                                               accept="application/pdf, image/*"
                                               type="file"/>
                                    </div>
                                    <div className="label col-md-1" id="research_uploadContent">
                                        <span className='fa fa-upload linkCursor '
                                              onClick={() => onHandleFileUpload('research-content-file', setFileContentPath, setFileContentName)}></span>
                                    </div>
                                    <div className="label col-md-1 divHide" id="research_removeContent">
                                        <span className='fa fa-trash linkCursor '
                                              onClick={() => handleResetFileContent('research-content-file')}></span>
                                    </div>

                                    <div className="label col-md-2">(Maximum file size limit
                                        : {fileSizeLbl})
                                    </div>
                                    <b id='mandatory-red-content-file' className='mandatory-red '>*</b>
                                    <div className="label col-md-2 ">
                                        <a target="_blank"
                                           href={fileContentPath}>{fileContentName == "" ? "" : "[View] " + fileContentName}  </a>

                                    </div>


                                </div>

                                <div id="div_thumbnail"
                                     className="cls_announceEvent cls_research input-container fullWidthSearch">
                                    <div className="label col-md-2">Thumbnail Image</div>
                                    <div className="label col-md-4">
                                        <input className="form-control" readOnly={isReadOnly} id="research-thumb-file"
                                               accept="image/*"
                                               type="file"/>
                                    </div>
                                    <div className="label col-md-1" id="research_uploadThumb">
                                        <span className='fa fa-upload linkCursor '
                                              onClick={() => onHandleFileUpload('research-thumb-file', setFileThumbPath, setFileThumbName)}></span>
                                    </div>
                                    <div className="label col-md-1 divHide" id="research_removeThumb">
                                        <span className='fa fa-trash linkCursor'
                                              onClick={() => handleResetThumbContent('research-thumb-file')}></span>
                                    </div>

                                    <div className="label col-md-2">(Maximum file size limit
                                        : {fileSizeLbl})
                                    </div>
                                    <div className="label col-md-2  ">
                                        <a target="_blank"
                                           href={fileThumbPath}>{fileThumbName == "" ? "" : "[View] " + fileThumbName} </a>
                                    </div>

                                </div>
                                <hr/>
                            </div>

                            {/*Announcement and Event View*/}
                            <div id="div_announcement" className="col-12 search-container  divHide">
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Title</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="form-control"
                                            type="text"
                                            readOnly={isReadOnly}
                                            placeholder="e.g. On The Spot Event"
                                            aria-label=""
                                            maxLength={256}
                                            value={brTitle}
                                            onChange={(e) => setBrTitle(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value, setBrTitle, popupManager)}

                                        />
                                    </div>
                                    <b className='mandatory-red'>*</b>
                                </div>

                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Type</div>
                                    <div className=" col-md-5">
                                        <label htmlFor="announcement">

                                            <input name="announcement_event" readOnly={isReadOnly} type="radio"
                                                   id="announcement"
                                                   value="2"
                                                   checked={anncType == '2'}
                                                   onChange={e => handleSelectChange(e.target.value, setAnncType, false)}

                                            />
                                            Announcement
                                        </label>

                                        <label className="checkboxMarginLeft" htmlFor="event">
                                            <input name="announcement_event" type="radio" id="event"
                                                   value="3" readOnly={isReadOnly}
                                                   checked={anncType == '3'}
                                                   onChange={e => handleSelectChange(e.target.value, setAnncType, false)}
                                            />
                                            Event
                                        </label>
                                    </div>
                                    <b className='mandatory-red'>*</b>

                                    <div className="label col-md-1  ">
                                        <b className='mandatory-red-inside'>*</b>
                                        Publish Start
                                    </div>
                                    <div className="label col-md-5  ">

                                        <ReactDatepick
                                            disabled={isReadOnly}
                                            value={brPublishStart}
                                            dateSetter={setBrPublishStart}
                                            resetToggle={toggleReset}
                                        />
                                    </div>
                                </div>
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Exchange</div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={exchangeList} // Options to display in the dropdown
                                            selectedValues={brExchange} // Preselected value to persist in dropdown
                                            onSelect={onExchangeHandle} // Function will trigger on select event
                                            onRemove={onExchangeHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            placeholder={exchangeSelAllLbl}
                                            disable={isReadOnly}
                                            hideSelectedList={hideExcItems}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>


                                    <div className="label col-md-1">
                                        <b className='mandatory-red-inside'>*</b>
                                        Publish End
                                    </div>
                                    <div className="label col-md-5">
                                        <ReactDatepick
                                            disabled={isReadOnly}
                                            value={brPublishEnd}
                                            dateSetter={setBrPublishEnd}
                                            resetToggle={toggleReset}
                                        />
                                    </div>

                                </div>
                                <div className="input-container fullWidthSearch ">
                                    <div className="label col-md-1  div_symbol">Symbol</div>
                                    <div className=" col-md-5 div_symbol">
                                        <Multiselect
                                            options={brSymbolList} // Options to display in the dropdown
                                            selectedValues={brSymbol} // Preselected value to persist in dropdown
                                            onSelect={onSymbolHandle} // Function will trigger on select event
                                            onRemove={onSymbolHandle} // Function will trigger on remove event
                                            displayValue="stockname" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            disable={isReadOnly}
                                            onSearch={onHandleSymbolSearch}
                                            placeholder="Symbol"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>


                                    <div className="label col-md-1">
                                        <b className='mandatory-red-inside'>*</b>
                                        User Type
                                    </div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={brUserTypeList} // Options to display in the dropdown
                                            selectedValues={brUserType} // Preselected value to persist in dropdown
                                            // selectedValues={holidayExchange}
                                            onSelect={onUserTypeHandle} // Function will trigger on select event
                                            onRemove={onUserTypeHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            placeholder={userTypeSelAllLbl}
                                            hideSelectedList={hideUTItems}
                                            readOnly={isReadOnly}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className=" cls_announceEvent div_Editor ">
                                    <div className="input-container cls_edit divHide fullWidthSearch ">
                                        <div className="label col-md-1 ">Created By</div>
                                        <div className="label col-md-5 ">
                                            <input
                                                className="underline-input "
                                                type="text"
                                                readOnly={true}
                                                value={brCreator}
                                                onChange={(e) => setBrCreator(e.target.value)}
                                            />
                                        </div>

                                        <div className="label col-md-1   ">Created Date</div>
                                        <div className="label col-md-5   ">
                                            <input
                                                className="underline-input "
                                                type="text"
                                                readOnly={true}
                                                value={brCreateDate}
                                                onChange={(e) => setBrCreateDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-container cls_edit divHide fullWidthSearch ">
                                        <div className="label col-md-1 ">Last Modified</div>
                                        <div className="label col-md-5 ">
                                            <input
                                                className="underline-input "
                                                type="text"
                                                readOnly={true}
                                                value={brEditor}
                                                onChange={(e) => setBrEditor(e.target.value)}
                                            />
                                        </div>

                                        <div className="label col-md-1   ">Last Modified Date</div>
                                        <div className="label col-md-5   ">
                                            <input
                                                className="underline-input "
                                                type="text"
                                                readOnly={true}
                                                value={brLstModifyDate}
                                                onChange={(e) => setBrLstModifyDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-container cls_edit divHide  fullWidthSearch ">
                                        <div className="label col-md-1 ">Status</div>
                                        <div className="label col-md-5 ">
                                            <input
                                                className="underline-input "
                                                type="text"
                                                readOnly={true}
                                                value={brStatus}
                                                onChange={(e) => setBrStatus(e.target.value)}
                                            />
                                        </div>


                                    </div>

                                    <hr/>
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorChangeHandle}
                                        toolbar={{
                                            image: {
                                                uploadEnabled: true,
                                                uploadCallback: uploadCallback,
                                                previewImage: true,
                                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                alt: {present: false, mandatory: false},
                                                defaultSize: {
                                                    height: 'auto',
                                                    width: 'auto',
                                                },
                                            },
                                        }}
                                    />
                                </div>
                                <hr/>
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-3">Content Option</div>
                                    <div className=" col-md-5">
                                        <label htmlFor="textEditor">

                                            <input readOnly={isReadOnly} name="uploadType" type="radio" id="textEditor"
                                                   value="1"
                                                   checked={fileUploadType == '1'}
                                                   onChange={e => handleSelectChange("1", setFileUploadType, false)}
                                            />
                                            Text Editor
                                        </label>

                                        <label className="checkboxMarginLeft" htmlFor="uploadFile">
                                            <input readOnly={isReadOnly} name="uploadType" type="radio" id="uploadFile"
                                                   value="2"
                                                   checked={fileUploadType == '2'}
                                                   onChange={e => handleSelectChange("2", setFileUploadType, false)}
                                            />
                                            Upload Content File
                                        </label>
                                    </div>
                                </div>
                                * You are ONLY allowed to choose either (1) between Text Editor content and Upload
                                Content File

                                <hr/>


                                <div className="label col-md-3"><b>File Upload</b></div>
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-2">Content File</div>
                                    <div className="label col-md-4">
                                        <input readOnly={isReadOnly} className="form-control" type="file" accept="application/pdf, image/*" id="announcement-content-file"/>
                                    </div>
                                    <div className="label col-md-1" id="announcement_uploadContent">
                                        <span className='fa fa-upload linkCursor '
                                              onClick={() => onHandleFileUpload('announcement-content-file', setFileContentPath, setFileContentName)}></span>
                                    </div>
                                    <div className="label col-md-1 divHide" id="announcement_removeContent">
                                        <span className='fa fa-trash linkCursor'
                                              onClick={() => handleResetFileContent('announcement-content-file')}></span>
                                    </div>

                                    <div className="label col-md-2">(Maximum file size limit
                                        : {fileSizeLbl})
                                    </div>
                                    <b id='mandatory-red-content-file' className='mandatory-red '>*</b>
                                    <div className="label col-md-2 cls_edit">
                                        <a target="_blank"
                                           href={fileContentPath}>{fileContentName == "" ? "" : "[View] " + fileContentName} </a>
                                    </div>
                                </div>

                                {/*Announcement & Event Only, Thumbnail image*/}
                                <div id="div_thumbnail"
                                     className="cls_announceEvent cls_research input-container fullWidthSearch">
                                    <div className="label col-md-2">Thumbnail Image</div>
                                    <div className="label col-md-4">
                                        <input readOnly={isReadOnly} className="form-control"
                                               id="announcement-thumb-file" accept="image/*"
                                               type="file"/>
                                    </div>
                                    <div className="label col-md-1" id="announcement_uploadThumb">
                                        <span className='fa fa-upload linkCursor '
                                              onClick={() => onHandleFileUpload('announcement-thumb-file', setFileThumbPath, setFileThumbName)}></span>
                                    </div>
                                    <div className="label col-md-1 divHide" id="announcement_removeThumb">
                                        <span className='fa fa-trash linkCursor'
                                              onClick={() => handleResetThumbContent('announcement-thumb-file')}></span>
                                    </div>

                                    <div className="label col-md-2">(Maximum file size limit
                                        : {fileSizeLbl})
                                    </div>
                                    <div className="label col-md-2 cls_edit">
                                        <a target="_blank"
                                           href={fileThumbPath}>{fileThumbName == "" ? "" : "[View] " + fileThumbName} </a>

                                    </div>

                                </div>

                                <hr/>

                            </div>

                            {/*TnC View*/}
                            <div id="div_tnc" className="col-12 search-container  divHide">
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Title</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="form-control"
                                            type="text"
                                            readOnly={isReadOnly}
                                            maxLength={256}
                                            placeholder="e.g. On The Spot Event"
                                            aria-label=""
                                            value={brTitle}
                                            onChange={(e) => setBrTitle(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value, setBrTitle, popupManager)}

                                        />
                                    </div>
                                    <b className='mandatory-red'>*</b>

                                </div>
                                <div className="input-container fullWidthSearch  ">
                                    <div className="label col-md-1">Description</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="form-control"
                                            type="text"
                                            readOnly={isReadOnly}
                                            placeholder="e.g. This is a TNC Changes"
                                            aria-label=""
                                            value={brDesc}
                                            onChange={(e) => setBrDesc(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value, setBrDesc, popupManager)}

                                        />
                                    </div>

                                </div>
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Exchange</div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={exchangeList} // Options to display in the dropdown
                                            selectedValues={brExchange} // Preselected value to persist in dropdown
                                            onSelect={onExchangeHandle} // Function will trigger on select event
                                            onRemove={onExchangeHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            disable={isReadOnly}
                                            placeholder={exchangeSelAllLbl}
                                            hideSelectedList={hideExcItems}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                    <div id='div_publishDate'
                                         className="label col-md-1 cls_research cls_tnc">
                                        <b className='mandatory-red-inside'>*</b>
                                        Publish Date
                                    </div>
                                    <div id='div_publishDateIpt'
                                         className="label col-md-5 cls_research cls_tnc">
                                        <ReactDatepick
                                            disabled={isReadOnly}
                                            value={brPublishDate}
                                            dateSetter={setBrPublishDate}
                                            resetToggle={toggleReset}
                                        />

                                    </div>

                                    <div className="label col-md-1 cls_announceEvent divHide">
                                        <b className='mandatory-red-inside'>*</b>
                                        Publish End
                                    </div>
                                    <div className="label col-md-5 cls_announceEvent divHide">
                                        <ReactDatepick
                                            disabled={isReadOnly}
                                            value={brPublishEnd}
                                            dateSetter={setBrPublishEnd}
                                            resetToggle={toggleReset}
                                        />
                                    </div>

                                </div>
                                <div className="input-container fullWidthSearch ">


                                    <div className="label col-md-1   ">Version</div>
                                    <div className="label col-md-5   ">
                                        <input
                                            className="form-control "
                                            type="text"
                                            value={brVersion}
                                            readOnly={isReadOnly}
                                            onChange={(e) => setBrVersion(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value, setBrVersion, popupManager)}

                                        />
                                    </div>

                                    <div className="label col-md-1">
                                        <b className='mandatory-red-inside'>*</b>
                                        User Type
                                    </div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={brUserTypeList} // Options to display in the dropdown
                                            selectedValues={brUserType} // Preselected value to persist in dropdown
                                            // selectedValues={holidayExchange}
                                            onSelect={onUserTypeHandle} // Function will trigger on select event
                                            onRemove={onUserTypeHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            disable={isReadOnly}
                                            placeholder={userTypeSelAllLbl}
                                            hideSelectedList={hideUTItems}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="input-container cls_edit divHide fullWidthSearch ">
                                    <div className="label col-md-1 ">Created By</div>
                                    <div className="label col-md-5 ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brCreator}
                                            onChange={(e) => setBrCreator(e.target.value)}
                                        />
                                    </div>

                                    <div className="label col-md-1   ">Created Date</div>
                                    <div className="label col-md-5   ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brCreateDate}
                                            onChange={(e) => setBrCreateDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="input-container cls_edit divHide fullWidthSearch ">
                                    <div className="label col-md-1 ">Last Modified</div>
                                    <div className="label col-md-5 ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brEditor}
                                            onChange={(e) => setBrEditor(e.target.value)}
                                        />
                                    </div>

                                    <div className="label col-md-1   ">Last Modified Date</div>
                                    <div className="label col-md-5   ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brLstModifyDate}
                                            onChange={(e) => setBrLstModifyDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="input-container cls_edit divHide  fullWidthSearch ">
                                    <div className="label col-md-1 ">Status</div>
                                    <div className="label col-md-5 ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brStatus}
                                            onChange={(e) => setBrStatus(e.target.value)}
                                        />
                                    </div>


                                </div>

                                <hr/>
                                {/*File upload section*/}

                                <div className="label col-md-3"><b>File Upload</b></div>
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-2">Content File</div>
                                    <div className="label col-md-4">
                                        <input readOnly={isReadOnly} className="form-control" type="file" accept=" application/pdf, image/*" id="tnc-content-file"/>
                                    </div>
                                    <div className="label col-md-1" id="tnc_uploadContent">
                                        <span className='fa fa-upload linkCursor '
                                              onClick={() => onHandleFileUpload('tnc-content-file', setFileContentPath, setFileContentName)}></span>
                                    </div>

                                    <div className="label col-md-1 divHide" id="tnc_removeContent">
                                        <span className='fa fa-trash linkCursor'
                                              onClick={() => handleResetFileContent('tnc-content-file')}></span>
                                    </div>

                                    <div className="label col-md-2">(Maximum file size limit
                                        : {fileSizeLbl})
                                    </div>
                                    <b id='mandatory-red-content-file' className='mandatory-red '>*</b>
                                    <div className="label col-md-2 ">
                                        <a target="_blank"
                                           href={fileContentPath}>{fileContentName == "" ? "" : "[View] " + fileContentName} </a>
                                    </div>
                                </div>

                                <hr/>
                            </div>


                            {/*Button Area*/}
                            <div className="input-container col-md-12 fullWidthSearch">

                                <div className="label col-md-1"></div>

                                {renderButtonByMode(currentMode)}


                            </div>

                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
