import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react';
import '../../../../asset/styles/customer-profile-style.css';
import api from '../../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    convertPositiveNumberHundred, dateTimeFormat, getNavItemById,
    handlePositiveNumberChange,
    handlePositiveNumberHundredChange,
    handleSelectChange
} from "../../../../general/general-tools";
import ReactTable from "../../../../general/form-control/react-table";
import dayjs from "dayjs";



const UserProfile = React.memo(props => {

    let setCurrentPage = props['setCurrentPage']== null ? '' : props['setCurrentPage'];
    let setCurrentPerPage = props['setCurrentPerPage']== null ? '' : props['setCurrentPerPage'];
    let currentPage = props['tableData']== null ? '' : props['currentPage'];
    let currentPerPage = props['tableData']== null ? '' : props['currentPerPage'];
    let totalRecords = props['tableData']== null ? '' : props['totalRecords'];
    let setCurrentUserRow = props['setCurrentUserRow']== null ? '' : props['setCurrentUserRow'];
    let searchUserRow = props['searchUserRow']== null ? '' : props['searchUserRow'];

    let tableData = props['tableData']== null ? '' : props['tableData'];
    let popupManager = props['popupManager']== null ? '' : props['popupManager'];


    const handleOnclick =  (row) => {
        if(setCurrentUserRow)setCurrentUserRow(row);
    };


    const columns = useMemo(
        () => [
            {
                id: row => row.p_fullname,
                name: 'User Name',
                selector: row => row.p_fullname,
                sortable: true,
                // center: true,
                cell: (row: { p_fullname: any }) => (
                    <>
                        <a className="linkCursor" onClick={e => handleOnclick(row)}>{row.p_fullname}</a>
                    </>
                )
            },
            {
                name: 'User Id',
                selector: row => row.username,
                sortable: true
            },
            {
                name: 'User Type',
                selector: row => row.ut_name,
                sortable: true,
            },
            {
                name: 'Account No',
                selector: row => row.account_no ?? "-",
                sortable: true,
                left: true

            },
            {
                name: 'Account Type',
                selector: row => row.account_type == 0 ? "Cash" : row.account_type == 1 ? "Margin" : row.account_type == 2 ? "Cash Upfront" : row.account_type == 3 ? "Collateralized" : "-",
                sortable: true
            },
            {
                name: 'Status',
                selector: row => row.active_status == 0 ? "Inactive" : row.active_status == 1 ? "Active" : row.active_status == 2 ? "Suspended" : row.active_status == 3 ? "Expired" : row.active_status == 4 ? "Dormant" : "Closed",
                sortable: true
            },
            {
                name: 'Access Status',
                selector: row => row.active_status == 1 ? "Unblock" : row.active_status == 2 ? "Block" : "-",
                sortable: true
            },
            {
                name: 'Last Login',
                selector: row => dayjs(row.last_login).format(dateTimeFormat()) == 'Invalid Date' ? "-" : dayjs(row.last_login).format(dateTimeFormat()),
                sortable: true
            }
        ],
        []
    );


    return  <>

        <ReactTable
            rowClick={setCurrentPage}
            perPageQty={setCurrentPerPage}
            // handleSelect={setCurrentUserId}
            columns={columns}
            data={tableData}
            pagination={{
                currentPage: currentPage,
                totalRecords: totalRecords,
                currentPerPage: currentPerPage,
                // selectableHandle: {setCurrentUsrTypeId},
            }}
        />

    </>
});
export default UserProfile;
