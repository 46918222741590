import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react';
import '../../../../asset/styles/customer-profile-style.css';
import api from '../../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    cursorToFront,
    handleIntegerChangeWithCursor,
    handleSelectChange
} from "../../../../general/general-tools";
import ReactTooltip from "react-tooltip-rc";


const QuantityLimit = React.memo(props => {

    let perTradeBLB = props['perTradeBLB'] == null ? '' : props['perTradeBLB'];
    let setPerTradeBLB = props['setPerTradeBLB'] == null ? '' : props['setPerTradeBLB'];
    let perTradeBLS = props['perTradeBLS'] == null ? '' : props['perTradeBLS'];
    let setPerTradeBLS = props['setPerTradeBLS'] == null ? '' : props['setPerTradeBLS'];
    let perTradeBLBReadOnly = props['perTradeBLBReadOnly'] == null ? '' : props['perTradeBLBReadOnly'];
    let perTradeDBTB = props['perTradeDBTB'] == null ? '' : props['perTradeDBTB'];
    let setPerTradeDBTB = props['setPerTradeDBTB'] == null ? '' : props['setPerTradeDBTB'];
    let perTradeDBTS = props['perTradeDBTS'] == null ? '' : props['perTradeDBTS'];
    let setPerTradeDBTS = props['setPerTradeDBTS'] == null ? '' : props['setPerTradeDBTS'];
    let perTradeDBTReadOnly = props['perTradeDBTReadOnly'] == null ? '' : props['perTradeDBTReadOnly'];
    let perDayBLB = props['perDayBLB'] == null ? '' : props['perDayBLB'];
    let setPerDayBLB = props['perDayBLB'] == null ? '' : props['setPerDayBLB'];
    let perDayBLS = props['perDayBLS'] == null ? '' : props['perDayBLS'];
    let setPerDayBLS = props['setPerDayBLS'] == null ? '' : props['setPerDayBLS'];
    let perDayBLReadOnly = props['perDayBLReadOnly'] == null ? '' : props['perDayBLReadOnly'];
    let perTradeBLRadio = props['perTradeBLRadio'] == null ? '' : props['perTradeBLRadio'];
    let setPerTradeBLRadio = props['setPerTradeBLRadio'] == null ? '' : props['setPerTradeBLRadio'];

    let perDayOddLotReadonly = props['perDayOddLotReadonly'] == null ? true : props['perDayOddLotReadonly'];
    let perDayOLB = props['perDayOLB'] == null ? 0 : props['perDayOLB'];
    let perDayOLS = props['perDayOLS'] == null ? 0 : props['perDayOLS'];
    let setPerDayOLB = props['setPerDayOLB'] == null ? '' : props['setPerDayOLB'];
    let setPerDayOLS = props['setPerDayOLS'] == null ? '' : props['setPerDayOLS'];

    let perTradeOLB = props['perTradeOLB'] == null ? 0 : props['perTradeOLB'];
    let perTradeOLS = props['perTradeOLS'] == null ? 0 : props['perTradeOLS'];
    let setPerTradeOLS = props['setPerTradeOLS'] == null ? '' : props['setPerTradeOLS'];
    let setPerTradeOLB = props['setPerTradeOLB'] == null ? '' : props['setPerTradeOLB'];

    let perTradeOLRadio = props['perTradeOLRadio'] == null ? 'control_warning' : props['perTradeOLRadio'];
    let setPerTradeOLRadio = props['setPerTradeOLRadio'] == null ? '' : props['setPerTradeOLRadio'];
    let perTradeOLRadioReadonly = props['perTradeOLRadioReadonly'] == null ? true : props['perTradeOLRadioReadonly'];

    let perTradeOLReadOnly = props['perTradeOLReadOnly'] == null ? true : props['perTradeOLReadOnly'];

    let popupManager = props['popupManager'] == null ? '' : props['popupManager'];


    return <>

        <div className="input-container col-md-12 fullWidthSearch exp-input-parent-single">
            <b>Quantity Limit</b>
        </div>


        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-1"><u>Per Trade</u></div>
            <div className="label col-md-1">Board Lot</div>
            <div className="label col-md-2"><u></u></div>
            <div className="label col-md-1">Odd Lot</div>
            <div className="label col-md-2"></div>
            <div className="label col-md-1">DBT</div>
        </div>
        <div className="input-container col-md-12 fullWidthSearch">
            <hr/>
            <div className="label col-md-1">Buy</div>
            <div className=" col-md-2">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={perTradeBLBReadOnly}
                    max={999999999}
                    value={perTradeBLB}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager, e, setPerTradeBLB)}

                    // onChange={(e) => handlePositiveNumberHundredChange(e.target.value, setPerTradeBLB)}
                />
                {/*<div className='floating-right'> Shares</div>*/}
            </div>

            <div className=" col-md-1">Shares</div>
            <div className=" col-md-2">
                <input
                    autoComplete='off'
                    max={999999999}
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={perTradeOLReadOnly}
                    value={perTradeOLB}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager, e, setPerTradeOLB)}

                />

            </div>
            <div className=" col-md-1">Shares</div>

            <div className=" col-md-2">
                <input
                    autoComplete='off'
                    max={999999999}
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={perTradeDBTReadOnly}
                    value={perTradeDBTB}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager, e, setPerTradeDBTB)}

                />

            </div>
            <div className=" col-md-1">Shares</div>


        </div>
        <div className="input-container col-md-12 fullWidthSearch">
            <hr/>
            <div className="label col-md-1">Sell</div>
            <div className=" col-md-2">
                <input
                    autoComplete='off'

                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={perTradeBLBReadOnly}
                    value={perTradeBLS}
                    max={999999999}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager, e, setPerTradeBLS)}
                />

            </div>

            <div className=" col-md-1">Shares</div>
            <div className=" col-md-2">
                <input
                    autoComplete='off'

                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={perTradeOLReadOnly}
                    // value={0}
                    value={perTradeOLS}
                    max={999999999}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager, e, setPerTradeOLS)}
                />

            </div>

            <div className=" col-md-1">Shares</div>
            <div className=" col-md-2">
                <input
                    autoComplete='off'

                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={perTradeDBTReadOnly}
                    value={perTradeDBTS}
                    max={999999999}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager, e, setPerTradeDBTS)}
                />

            </div>
            <div className=" col-md-2">Shares</div>
        </div>


        <br/>


        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-1"><u>Per Day</u></div>
            <div className="label col-md-1"></div>
            <div className="label col-md-2"><u></u></div>
            <div className="label col-md-1"></div>
        </div>
        <div className="input-container col-md-12 fullWidthSearch">
            <hr/>
            <div className="label col-md-1">Buy</div>
            <div className=" col-md-2">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={perDayBLReadOnly}
                    value={perDayBLB}
                    max={999999999}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager, e, setPerDayBLB)}

                />

            </div>
            <div className=" col-md-1">Shares</div>
            <div className=" col-md-2">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={perDayOddLotReadonly}
                    value={perDayOLB}
                    max={999999999}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager, e, setPerDayOLB)}

                />
            </div>
            <div className=" col-md-1">Shares</div>


        </div>
        <div className="input-container col-md-12 fullWidthSearch">
            <hr/>
            <div className="label col-md-1">Sell</div>
            <div className=" col-md-2">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={perDayBLReadOnly}
                    value={perDayBLS}
                    max={999999999}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager, e, setPerDayBLS)}
                />

            </div>
            <div className=" col-md-1">Shares</div>
            <div className=" col-md-2">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={perDayOddLotReadonly}
                    value={perDayOLS}
                    max={999999999}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager, e, setPerDayOLS)}

                />
            </div>
            <div className=" col-md-1">Shares</div>
        </div>
        <div className='col-md-12'>
            *Please kindly left the value in 0 if no checking required.
        </div>
        <br></br>

        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-3">Board Lot / DBT Control <span className="toolTipSmall"
                                                                          data-tip="This control configuration applies to both 'Per Trade' and 'Per Day'">(?)</span>
            </div>
            {/*<div className="label col-md-1">!</div>*/}
            <ReactTooltip/>

            <div className="label col-md-3">

                <input className="checkboxMarginRight" name="quantity_limit_control" type="radio"
                    // defaultChecked={perTradeBLRadio == 'control_block' ? true : false}
                       checked={perTradeBLRadio == 'control_block' ? true : false}
                       id='quantity_limit_control_block'
                       disabled={perTradeBLBReadOnly && perTradeDBTReadOnly && perDayBLReadOnly}
                       onChange={e => handleSelectChange('control_block', setPerTradeBLRadio, perTradeBLBReadOnly && perTradeDBTReadOnly && perDayBLReadOnly)}
                />

                <label className='checkboxMarginRight' htmlFor="quantity_limit_control_block">
                    Block
                </label>

                <input className="checkboxMarginLeft" name="quantity_limit_control" type="radio"
                    // defaultChecked={perTradeBLRadio == 'control_warning' ? true : false}
                       checked={perTradeBLRadio == 'control_warning' ? true : false}
                       id='quantity_limit_control_warning'
                       disabled={perTradeBLBReadOnly && perTradeDBTReadOnly && perDayBLReadOnly}
                       onChange={e => handleSelectChange('control_warning', setPerTradeBLRadio, perTradeBLBReadOnly && perTradeDBTReadOnly && perDayBLReadOnly)}

                />
                <label className="" htmlFor="quantity_limit_control_warning">
                    Warning
                </label>


            </div>
            <div className="label col-md-2">

                <div className="label ">Odd Lot Control <span className="toolTipSmall"
                                                              data-tip="This control configuration applies to both 'Per Trade' and 'Per Day'.">(?)</span>
                </div>


            </div>

            <div className="label col-md-3">

                <input className="checkboxMarginRight" name="quantity_limit_odd_control" type="radio"
                       defaultChecked={perTradeOLRadio == 'control_block' ? true : false}
                       checked={perTradeOLRadio == 'control_block' ? true : false}
                       id='quantity_limit_control_odd_block'
                       disabled={perTradeOLRadioReadonly}

                    //    disabled={perTradeBLBReadOnly && perTradeDBTReadOnly && perDayBLReadOnly}
                       onChange={e => handleSelectChange('control_block', setPerTradeOLRadio, perTradeOLRadioReadonly)}
                />

                <label className='checkboxMarginRight' htmlFor="quantity_limit_control_odd_block">
                    Block
                </label>

                <input className="checkboxMarginLeft" name="quantity_limit_odd_control" type="radio"
                       defaultChecked={perTradeOLRadio == 'control_warning' ? true : false}
                       checked={perTradeOLRadio == 'control_warning' ? true : false}
                       id='quantity_limit_control_odd_warning'
                       disabled={perTradeOLRadioReadonly}
                       onChange={e => handleSelectChange('control_warning', setPerTradeOLRadio, perTradeOLRadioReadonly)}

                    //    disabled={perTradeBLBReadOnly && perTradeDBTReadOnly && perDayBLReadOnly}
                    //    onChange={e => handleSelectChange('control_warning', setPerTradeBLRadio, perTradeBLBReadOnly && perTradeDBTReadOnly && perDayBLReadOnly)}

                />
                <label className="" htmlFor="quantity_limit_control_odd_warning">
                    Warning
                </label>


            </div>

        </div>

    </>

});
export default QuantityLimit;
