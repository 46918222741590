import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    convertFuncPerm,
    getDefaultAccessMat, getNavIdByPath,
    handlePopup,
    handleSelectChange,
    useLocalStorage,
    userTypeCategory, convertErMsg, specialCharCheck
} from "../../../general/general-tools";
import {usePopupManager} from "react-popup-manager";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";


const Index = () => {
    const {setLoading} = useLoading();
    const popupManager = usePopupManager();

    const [tableData, setTableData] = useLocalStorage('uam_tableData', []);
    const [totalRecords, setTotalRecords] = useLocalStorage('uam_totalRecords', 0);
    const [currentPage, setCurrentPage] = useLocalStorage('uam_currentPage', 1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage('uam_currentPerPage', 10);
    const [searchInput, setSearchInput] = useLocalStorage('uam_searchTerm', "");
    const [searchUserType, setSearchUserType] = useLocalStorage('uam_userType', "");

    const isInitialMount = useRef(true);

    const location = useLocation();
    let navigate = useNavigate();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat() : getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);


    useLayoutEffect(() => {

        if (tableData.length > 0) {
            handleInitList()

        } else {
            handleResetBtn();

        }
        window.scrollTo({top: 0, behavior: 'smooth'});

    }, [])

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleInitList()
        }

    }, [currentPage, currentPerPage]);
    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {
        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }
    useEnterKeyPress(handleEnterKeyPress);

    const handleInitList = async (newSearch=false) => {
        setLoading(true);
        let curPage = currentPage;
           if (newSearch) {
            setCurrentPage(1)
            curPage= 1;
        }
        api.getAccessMatricList(currentPerPage, curPage, 1, searchInput, searchUserType).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

            if(result.data){
                if (result.data.list.length > 0) {
                    setLoading(false);

                    let dataList = result.data.list;
                    const timeout = setTimeout(() => {
                        setTableData(dataList);
                        let totalPage = parseInt(result.data.totalrow)
                        setTotalRecords(totalPage);
                    }, 10);
                    return () => clearTimeout(timeout);
                } else {
                    handlePopup(true, "Error", "No results found", popupManager)
                    setTableData([]);
                    setTotalRecords(10);
                    setLoading(false);

                }
                setLoading(false);

            }else {
                handlePopup(true, "Error", "No results found", popupManager)
                setTableData([]);
                setTotalRecords(10);
                setLoading(false);

            }
        });
    };

    const handleOnclick = async (cur_code) => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "user_mat_opr", {
            state: {
                row: cur_code,
                perm: actualPerm,
                mode: 'Edit'
            }
        });
        setLoading(false);
    };
    const handleAddBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "user_mat_opr", {
            state: {
                row: "",
                perm: actualPerm,
                mode: 'Add'
            }
        });
        setLoading(false);
    };
    const handleResetBtn = async () => {
        await setLoading(true);
        setSearchInput("");
        setSearchUserType("");
        setLoading(false);
    };


    const columns = useMemo(
        () => [
            {
                id: row => row.ut_name,
                name: 'User Type',
                selector: row => row.ut_name,
                sortable: true,
                // center: true,
                cell: (row: { ut_name: any }) => (
                    <>
                        <a className="tableItemsHlink" onClick={() => handleOnclick(row)}>{row.ut_name}</a>
                    </>
                )
            },
            {
                name: 'User Category',
                selector: row => row.uc_name,
                sortable: true,
            },

        ],
        []
    );


    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; User
                                Maintenance &gt;  Access Matrix - Search</h5>

                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-2 ">User Type</div>
                                    <input
                                        className="form-control "
                                        autoComplete='off'

                                        type="text"
                                        placeholder="Search by User Type  "
                                        aria-label="Search Input"
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                        onBlur={(e) => specialCharCheck(e.target.value,setSearchInput,popupManager)}

                                    />
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-2 ">User Category</div>
                                    <select
                                        className="form-control"
                                        aria-label=""
                                        value={searchUserType}
                                        onChange={e => handleSelectChange(e.target.value, setSearchUserType, false)}
                                    >
                                        {userTypeCategory()}
                                    </select>
                                </div>


                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary "  id="btn-search-main"  onClick={() => handleInitList(true)}>Search
                                        </button>
                                        <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    perPageQty={setCurrentPerPage}
                                    columns={columns}
                                    data={tableData}
                                    pagination={{
                                        currentPage: currentPage,
                                        currentPerPage: currentPerPage,
                                        totalRecords: totalRecords
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
