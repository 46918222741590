import React, {useState, useEffect, useMemo, useLayoutEffect, useRef, useCallback} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';
import {useLoading} from '../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {usePopupManager} from "react-popup-manager";
import ModalConfirm from "../../public/ModalConfirm";
import {
    changeBase,
    convertErMsg, dateFormats, dateTimeFormat,
    disableAllEleInDiv, getNavNumericIcon,
    handlePopup,
    handleSelectChange, momentTimeFormat,
    specialCharCheck, timeElement15, useLocalStorage
} from "../../../general/general-tools";
import Multiselect from "multiselect-react-dropdown";
import ReactDatepick from "../../../general/form-control/react-datepick";
import ReactTable from "../../../general/form-control/react-table";
import {tab} from "@testing-library/user-event/dist/tab";
import moment from "moment/moment";
import {TimePicker} from "antd";
import dayjs from "dayjs";
import {createTask, getSpecificTask} from "../../../../apis/models/TaskScheduler";
// import TimePicker from 'react-time-picker';
// import 'react-time-picker/dist/TimePicker.css';
// import 'react-clock/dist/Clock.css';


const Index = () => {
    const {setLoading} = useLoading();


    const [currentMode, setCurrentMode] = useState("");

    const [currentTaskId, setCurrentTaskId] = useState("");
    const [tradingType, setTradingType] = useState(0);
    const [tradingType2, setTradingType2] = useState(0);
    const [currentDesc, setCurrentDesc] = useState("");
    const [currentProcessName, setCurrentProcessName] = useState("");
    const [currentProcessType, setCurrentProcessType] = useState("");
    const [unsucessfulAttempt, setUnsucessfulAttempt] = useState("");
    const [doNotRepeats, setDoNotRepeats] = useState(0);
    const [repeatQty, setRepeatQty] = useState(1);
    const [repeatBasis, setRepeatBasis] = useState(0);
    const [repeatBasis2, setRepeatBasis2] = useState(1);

    const [repeatDayNum, setRepeatDayNum] = useState(1);
    const [repeatStartDate, setRepeatStartDate] = useState("");
    const [repeatEndDate, setRepeatEndDate] = useState(null);
    const [repeatStartTime, setRepeatStartTime] = useState("");

    const [toggleReset, setToggleReset] = useState(false);
    const [accessItem, setAccessItem] = useState({});

    const [tableData, setTableData] = useState([]);
    const [taskLists, setTaskLists] = useState([]);
    const [taskKeys, setTaskKeys] = useState([]);

    const [monChecked, setMonChecked] = useState(false);
    const [tueChecked, setTueChecked] = useState(false);
    const [wedChecked, setWedChecked] = useState(false);
    const [thuChecked, setThuChecked] = useState(false);
    const [friChecked, setFriChecked] = useState(false);
    const [satChecked, setSatChecked] = useState(false);
    const [sunChecked, setSunChecked] = useState(false);

    const [taskIdReadOnly, setTaskIdReadOnly] = useState(false);

    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();
    const [toggledClearRows, setToggleClearRows] = React.useState(false);


    let tempCallback = [];

    //Load initial data and mode
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        setLoading(true);
        if (location.state) {
            setAccessItem(location.state.perm);

            if (location.state.row) {

                if (location.state.perm['edit']) {
                    if (location.state.mode == "Edit") {
                        setTaskIdReadOnly(true);
                        getSpecificTask();
                    }
                } else {

                    setTimeout(function () {
                        disableAllEleInDiv("mainDiv");
                    }, 50);

                }
            } else {
                handleTaskLists();
            }
            setCurrentMode(location.state.mode)
        }
    }, [location.state]);

    const getSpecificTask = () => {
        setLoading(true);
        api.getSpecificTask(location.state.row.tss_id).then((result: any) => {
            setLoading(false);


            if (result.data) {
                let element = result.data.list[0];
                setCurrentTaskId(element['tss_id'])
                setTradingType(element['tss_active'])
                setTradingType2(element['tss_trade_day'])
                setCurrentDesc(element['tss_desc'])
                setCurrentProcessName(element['tss_name'])
                setCurrentProcessType(element['tss_type'])
                setUnsucessfulAttempt(element['tss_type'])
                setDoNotRepeats(element['tss_recurr_mode'])
                setRepeatStartDate(moment(element['tss_start']).format(dateTimeFormat()))
                if (element['tss_end']) setRepeatEndDate(moment(element['tss_end']).format(dateTimeFormat()))

                let recurEle = JSON.parse(element['tss_recurr_time']);
                // repeatBasis
                if (recurEle['type'] == 0) {
                    setRepeatBasis(recurEle['type']);
                    setRepeatQty(recurEle['repeat']);
                    setRepeatStartTime(moment(recurEle['time'], "HH:mm:ss"));
                } else if (recurEle['type'] == 1) {
                    setRepeatBasis(recurEle['type']);
                    setRepeatQty(recurEle['repeat']);
                    setRepeatStartTime(moment(recurEle['time'], "HH:mm:ss"));
                    setCheckbox(recurEle['days'])
                } else if (recurEle['type'] == 2) {
                    setRepeatBasis(recurEle['type']);
                    setRepeatQty(recurEle['repeat']);
                    setRepeatStartTime(moment(recurEle['time'], "HH:mm:ss"));
                    let tempRpt = 1;
                    if (recurEle['days'] == 0) tempRpt = 2
                    else if (recurEle['days'] == 99) tempRpt = 3
                    setRepeatBasis2(tempRpt)
                    setRepeatDayNum(recurEle['days']);
                }


                if (element['tss_depend']) {
                    let dependEle = JSON.parse(element['tss_depend']);
                    handleTaskLists(dependEle);
                } else {
                    handleTaskLists();
                }

            }
        });
    }

    const handleTaskLists = async (currentDepends = null) => {
        api.getTaskSchedulerList(999, 1, 1, "", "").then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }

            if (result.data.list.length > 0) {
                let dataList = result.data.list;
                let tblList = [];
                for (let i = 0; i < dataList.length; i++) {
                    let tempEle = dataList[i];
                    if (dataList[i]['tss_recurr_time']) {
                        let eleRecurTime = JSON.parse(dataList[i]['tss_recurr_time']);
                        tempEle['recurring'] = generateTypeDescription(eleRecurTime['type'], eleRecurTime['repeat'], eleRecurTime['time'], eleRecurTime['days'])
                    }
                    if (dataList[i]['tss_depend']) {
                        let tempEleDepends = JSON.parse(dataList[i]['tss_depend']);
                        let tempDepend = "";

                        for (let j = 0; j < tempEleDepends.length; j++) {
                            tempDepend += tempEleDepends[j] + "\n";
                        }
                        tempEle['depend'] = tempDepend;
                    }
                    tblList.push(tempEle);
                }

                let array3 = {};

                tblList.forEach(item => {
                    const {tss_id, ...rest} = item;
                    array3[tss_id] = rest;
                });
                setTaskLists(tblList);
                setTaskKeys(array3);

                if (currentDepends) {
                    handleDepends(currentDepends, array3);
                } else {
                    handleDepends("", array3);

                }
            }
            setLoading(false);

        });
    }

    const handleDepends = async (tss_depend, taskLists) => {
        await setLoading(true);
        if (tss_depend) {
            let dependEle = tss_depend;
            let tempTblDat = [];
            for (let i = 0; i < dependEle.length; i++) {
                let value = dependEle[i];

                let tempDat = {};
                if (taskLists[value]) {
                    tempDat['tss_id'] = dependEle[i];
                    tempDat['tss_desc'] = taskLists[value]['tss_desc'];
                    tempDat['tss_start'] = !moment(taskLists[value]['tss_start'] ?? "").isValid() ? "-" : dayjs(taskLists[value]['tss_start']).format(dateFormats());
                    tempDat['recurring'] = taskLists[value]['recurring'] ?? "-";
                    tempDat['depend'] = taskLists[value]['depend'] ?? "-";
                    tempTblDat.push(tempDat)
                }
            }
            setTableData(tempTblDat);
        } else { //Add or Empty Dependencies
            for (let i = 0; i < taskLists.length; i++) {
                let tempDat = {};
                tempDat['tss_id'] = dependEle[i];
                tempDat['tss_desc'] = taskLists[value]['tss_desc'];
                tempDat['tss_start'] = !moment(taskLists[value]['tss_start'] ?? "").isValid() ? "-" : dayjs(taskLists[value]['tss_start']).format(dateFormats());
                tempDat['recurring'] = taskLists[value]['recurring'] ?? "-";
                tempDat['depend'] = taskLists[value]['depend'] ?? "-";
                tempTblDat.push(tempDat)
            }

            let defaultItem = {
                "row_num": tableData.length,
                "tss_id": "",
                "tss_desc": "",
                "tss_start": "",
                "recur": "",
                "depend": ""
            };
            let item = tableData.concat(defaultItem);

            setToggleClearRows(!toggledClearRows);
            setTableData(item);
        }
        setLoading(false);
    };
    const handleResetBtn = async () => {
        await setLoading(true);

        if(currentMode == 'Edit'){
            getSpecificTask();
        }else {
            setCurrentTaskId("");
            setTradingType(0);
            setTradingType2(0);
            setCurrentDesc("");
            setCurrentProcessName("");
            setCurrentProcessType("");
            setRepeatBasis(0);
            setRepeatBasis2(0);
            setRepeatQty(1);
            setRepeatDayNum(1);
            setRepeatStartTime(null);
            setRepeatStartDate(null);
            setRepeatEndDate(null);
            setTableData([]);
            setLoading(false);

        }



    };

    function getBinaryRepresentation() {
        // Get the checkbox elements by name

        let checkBoxString = "";

        checkBoxString += sunChecked ? "1" : "0";
        checkBoxString += monChecked ? "1" : "0";
        checkBoxString += tueChecked ? "1" : "0";
        checkBoxString += wedChecked ? "1" : "0";
        checkBoxString += thuChecked ? "1" : "0";
        checkBoxString += friChecked ? "1" : "0";
        checkBoxString += satChecked ? "1" : "0";

        // Convert binary to decimal
        const decimalValue = parseInt(checkBoxString, 2);

        return decimalValue;
    }

    function setCheckbox(decimalValue) {
        // Get the checkbox elements by name
        const checkboxes = document.getElementsByName("access_day");
        let base2 = changeBase(decimalValue, 10, 2)

        for (let i = 0; i < checkboxes.length; i++) {
            // Check the checkbox based on the decimal representation
            const isChecked = base2[i] != 0;
            checkboxes[i].checked = isChecked;

            switch (i) {
                case 1:
                    setMonChecked(isChecked);
                    break;
                case 2:
                    setTueChecked(isChecked);
                    break;
                case 3:
                    setWedChecked(isChecked);
                    break;
                case 4:
                    setThuChecked(isChecked);
                    break;
                case 5:
                    setFriChecked(isChecked);
                    break;
                case 6:
                    setSatChecked(isChecked);
                    break;
                case 0:
                    setSunChecked(isChecked);
                    break;

            }
        }
    }

    const handleAddBtn = async () => {

        await setLoading(true);

        if (currentTaskId === "") {
            handlePopup(true, "Error", "Mandatory Field - Task Id not entered.", popupManager)
            setLoading(false);
            return false;
        }
        if (currentDesc === "") {
            handlePopup(true, "Error", "Mandatory Field - Descriptions not entered.", popupManager)
            setLoading(false);

            return false;
        }
        if (currentProcessName === "") {
            handlePopup(true, "Error", "Mandatory Field - Process name not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if (currentProcessType === "") {
            handlePopup(true, "Error", "Mandatory Field - Process Type not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if (unsucessfulAttempt === "") {
            handlePopup(true, "Error", "Mandatory Field - Unsuccessful approach not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if (!moment(repeatStartDate).isValid()) {
            handlePopup(true, "Error", "Mandatory Field - Start Date not selected.", popupManager)
            setLoading(false);
            return false;
        }

        if (!moment(repeatStartTime).isValid()) {
            handlePopup(true, "Error", "Mandatory Field - Start time not selected.", popupManager)
            setLoading(false);
            return false;
        }

        let dependTempStr = "";
        if (tableData.length > 0) {
            for (let i = 0; i < tableData.length; i++) {
                if (tableData[i]['tss_id']) {
                    dependTempStr += tableData[i]['tss_id'] + ",";
                }
            }
            dependTempStr = dependTempStr.substring(0, dependTempStr.length - 1)
        }
        let recurringStr = {}

        if (!doNotRepeats) {
            if (repeatBasis == 0) {
                recurringStr = {
                    "type": parseInt(repeatBasis),
                    "repeat": parseInt(repeatQty),
                    "time": repeatStartTime.format("HH:mm:ss")
                }
            } else if (repeatBasis == 1) {
                recurringStr = {
                    "type": parseInt(repeatBasis),
                    "repeat": parseInt(repeatQty),
                    "days": getBinaryRepresentation(),
                    "time": repeatStartTime.format("HH:mm:ss")
                }
            }
            if (repeatBasis == 2) {

                let dayTemp = 0;
                if (repeatBasis2 == 1) dayTemp = parseInt(repeatBasis2);
                if (repeatBasis2 == 2) dayTemp = 0;
                if (repeatBasis2 == 3) dayTemp = 99;
                recurringStr = {
                    "type": parseInt(repeatBasis),
                    "repeat": parseInt(repeatQty),
                    "days": dayTemp,
                    "time": repeatStartTime.format("HH:mm:ss")
                }
            }

        }

        let data = {
            "tss_id": currentTaskId,
            "tss_active": parseInt(tradingType ? 1 : 0),
            "tss_trade_day": parseInt(tradingType2 ? 1 : 0),
            "tss_name": currentProcessName,
            "tss_desc": currentDesc,
            "tss_fail_approach": unsucessfulAttempt,
            "tss_type": currentProcessType,
            "tss_start": dayjs(repeatStartDate).format(momentTimeFormat()),
            "tss_end": dayjs(repeatEndDate).format(momentTimeFormat()) == "Invalid Date" ? "" : dayjs(repeatEndDate).format(momentTimeFormat()),
            "tss_recurr_mode": parseInt(doNotRepeats ? 1 : 0),
            "tss_recurr_time": JSON.stringify(recurringStr),
            "dependencies": dependTempStr
        };
        
        if (currentMode == 'Add') {
            api.createTask(data).then((result: any) => {
                if (result.status == 0) {
                    setLoading(false);
                    handlePopup(true, "Success", "Task created successfully.", popupManager, true, "task_schedular", navigate)
                } else {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    setLoading(false);
                }
            });

        } else {
            api.updateTask(data).then((result: any) => {
                if (result.status == 0) {
                    setLoading(false);
                    handlePopup(true, "Success", "Task updated successfully.", popupManager, true, "task_schedular", navigate)
                } else {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    setLoading(false);
                }
            });
        }
    };
    const handleBackBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "task_schedular", {state: {mode: 'back'}});
        setLoading(false);
    };

    const handleTableOnChange = (index, value) => {
        setLoading(true)

        if (value == "" || !value) {
            setLoading(false);
            return false;
        }
        let tempDat = tableData;

        const existingIndex = tempDat.findIndex(item => item && item.tss_id === value);
        if (tempDat[index] && existingIndex == -1) {
            if (tableData[index]) {
                tempDat[index]['tss_id'] = value;
                tempDat[index]['tss_desc'] = taskKeys[value]['tss_desc'];
                tempDat[index]['tss_start'] = !moment(taskKeys[value]['tss_start']).isValid() ? "-" : dayjs(taskKeys[value]['tss_start']).format(dateFormats());
                tempDat[index]['recurring'] = taskKeys[value]['recurring'] ?? "-";
                tempDat[index]['depend'] = taskKeys[value]['depend'] ?? "-";
            }
            setToggleClearRows(!toggledClearRows)
            setTableData(tempDat);
        } else {
            handlePopup(true, "Error", "Selected task is already in list", popupManager)
            setLoading(false);
            return false;
        }
        setLoading(false)

    };

    function generateTypeDescription(recurrType, recurrRepeat, recurrTime, tssRecurrTimeNV) {
        let typeDescription = '';

        if (recurrType === 0) {
            // Repeat by day
            typeDescription = `Every ${recurrRepeat} day ${recurrTime}`;
        } else if (recurrType === 1) {
            // Repeat by week
            typeDescription = `Every ${recurrRepeat} week(s) on `;
            let recurrDays = tssRecurrTimeNV;
            let daysOfWeek = '';
            for (let dayPosition = 0; dayPosition < 7; dayPosition++) {
                // Check if the day is included
                if ((recurrDays & Math.pow(2, dayPosition)) > 0) {
                    // Add day to the list
                    daysOfWeek += (daysOfWeek.length > 0 ? ', ' : '') +
                        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayPosition] + ['\n'];
                }
            }

            if (recurrDays === 127) {
                daysOfWeek = 'everyday';
            }

            typeDescription += `${daysOfWeek} at ${recurrTime}`;
        } else if (recurrType === 2) {
            // Repeat by month
            typeDescription = `Every ${recurrRepeat} month(s) on `;

            switch (recurrRepeat) {
                case 1:
                    typeDescription += '1st day';
                    break;
                case 99:
                    typeDescription += 'last day';
                    break;
                default:
                    typeDescription += `${recurrRepeat}th day`;
            }
        }

        return typeDescription;
    }

    const handleTableAdd = async () => {
        let defaultItem = {
            "row_num": tableData.length,
            "tss_id": "",
            "tss_desc": "",
            "tss_start": "",
            "recur": "",
            "depend": ""
        };
        let item = tableData.concat(defaultItem);

        setToggleClearRows(!toggledClearRows);
        setTableData(item);
    }


    const handleCheckAll = async () => {
        let checkBoxMain = document.getElementById('removeIdMain').checked
        var checkBoxes = document.getElementsByName('removeSelect');

        for (var i = 0; i < checkBoxes.length; i++) {
            if (checkBoxMain) {
                checkBoxes[i].checked = true;
            } else {
                checkBoxes[i].checked = false;
            }

        }
    }
    const handleTableRemove = async () => {

        let indexesToDelete = [];

        document.getElementById('removeIdMain').checked = false;

        for (let i = 0; i < tableData.length; i++) {
            let chkBox = document.getElementById('removeId_' + i).checked
            if (chkBox) {
                document.getElementById('removeId_' + i).checked = false;
                indexesToDelete.push(tableData[i]['row_num'])
            }
        }

        indexesToDelete.sort((a, b) => b - a);
        let newArray2 = [];

        for (let i = 0; i < tableData.length; i++) {
            if (!indexesToDelete.includes(i)) {
                newArray2.push(tableData[i]);
            }
        }

        for (let i = 0; i < newArray2.length; i++) {
            newArray2[i]['row_num'] = i;
        }
        setTableData(newArray2);
    }


    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Task
                                Maintenance  &gt; Task Scheduler - {currentMode}</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">
                                        <b className='mandatory-red-inside'>*</b>Task ID
                                    </div>
                                    <div className="label col-md-4">
                                        <input
                                            className="form-control"
                                            autoComplete='off'
                                            type="text"
                                            maxLength={32}
                                            aria-label=""
                                            value={currentTaskId}
                                            readOnly={taskIdReadOnly}
                                            onChange={(e) => setCurrentTaskId(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value, setCurrentTaskId, popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-4">
                                        <label htmlFor="trading-day">

                                            <input className="checkboxMarginLeft" name="active_type_1" type="checkbox"
                                                   id="trading_day"
                                                   value={1}
                                                   checked={tradingType2 == 1}
                                                   onChange={e => handleSelectChange(e.target.checked ? 1 : 0, setTradingType2, false)}
                                            />
                                            Trading Day
                                        </label>

                                        <label htmlFor="trading-day">
                                            <input className="checkboxMarginLeft" name="active_type_2" type="checkbox"
                                                   id="active"
                                                   value={1}
                                                   checked={tradingType == 1}
                                                   onChange={e => handleSelectChange(e.target.checked ? 1 : 0, setTradingType, false)}
                                            />
                                            Active
                                        </label>
                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2"><b className='mandatory-red-inside'>*</b>Descriptions
                                    </div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            autoComplete='off'
                                            type="text"
                                            maxLength={128}
                                            aria-label=""
                                            value={currentDesc}
                                            onChange={(e) => setCurrentDesc(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value, setCurrentDesc, popupManager)}

                                        />

                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2"><b className='mandatory-red-inside'>*</b>Process
                                        Name
                                    </div>
                                    <div className="label col-md-5">

                                        <input
                                            className="form-control"
                                            autoComplete='off'
                                            type="text"
                                            maxLength={128}
                                            aria-label=""
                                            value={currentProcessName}
                                            onChange={(e) => setCurrentProcessName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value, setCurrentProcessName, popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-2"><b className='mandatory-red-inside'>*</b>Process
                                        Type
                                    </div>
                                    <div className="label col-md-3">
                                        <select
                                            className="form-control"
                                            aria-label=""
                                            value={currentProcessType}
                                            onChange={e => handleSelectChange(e.target.value, setCurrentProcessType, false)}
                                        >
                                            <option>Please select process type</option>
                                            <option value={0}>Program / Service</option>
                                            <option value={1}>StoreProcedure</option>
                                            <option value={2}>DLL</option>
                                        </select>


                                    </div>
                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2"><b className='mandatory-red-inside'>*</b>Unsuccessful
                                        Approach
                                    </div>
                                    <div className="label col-md-10">

                                        <select
                                            className="form-control"
                                            aria-label=""
                                            value={unsucessfulAttempt}
                                            onChange={e => handleSelectChange(e.target.value, setUnsucessfulAttempt, false)}
                                        >
                                            <option>Please select fail strategy</option>
                                            <option value={0}>Do nothing</option>
                                            <option value={1}>Roll-back</option>
                                        </select>
                                    </div>

                                </div>

                                <div className="card shadow mb-4">
                                    <div
                                        className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h5 className="m-0 font-weight-bold text-primary">{(repeatBasis == 1 ? "Weekly" : repeatBasis == 2 ? "Monthly" : "Recurrence")} </h5>

                                    </div>

                                    <div className="card-body">

                                        <div className="input-container col-md-12 fullWidthSearch">
                                            <div className="label col-md-2">
                                                Does Not Repeat
                                            </div>
                                            <div className="label col-md-4">
                                                <input className="checkboxMarginRight" name="doNotRpt"
                                                       type="checkbox"
                                                       value={1}
                                                       checked={doNotRepeats == 1}
                                                       onChange={e => handleSelectChange(e.target.checked ? 1 : 0, setDoNotRepeats, false)}
                                                />
                                            </div>
                                        </div>

                                        <div className="input-container col-md-12 fullWidthSearch">
                                            <div className="label col-md-2">
                                                {(doNotRepeats == 1 ? "" :
                                                    <b className={'mandatory-red-inside'}>*</b>)}

                                                Repeat every
                                            </div>
                                            <div className="label col-md-1">
                                                <select
                                                    className="form-control"
                                                    aria-label=""
                                                    value={repeatQty}
                                                    disabled={doNotRepeats == 1}
                                                    onChange={e => handleSelectChange(e.target.value, setRepeatQty, false)}
                                                >
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                    <option value={8}>8</option>
                                                    <option value={9}>9</option>
                                                    <option value={10}>10</option>
                                                    <option value={11}>11</option>
                                                    <option value={12}>12</option>
                                                    <option value={13}>13</option>
                                                    <option value={14}>14</option>
                                                    <option value={15}>15</option>
                                                    <option value={16}>16</option>
                                                    <option value={17}>17</option>
                                                    <option value={18}>18</option>
                                                    <option value={19}>19</option>
                                                    <option value={20}>20</option>
                                                    <option value={21}>21</option>
                                                    <option value={22}>22</option>
                                                    <option value={23}>23</option>
                                                    <option value={24}>24</option>
                                                    <option value={25}>25</option>
                                                    <option value={26}>26</option>
                                                    <option value={27}>27</option>
                                                    <option value={28}>28</option>
                                                    <option value={29}>29</option>
                                                    <option value={30}>30</option>
                                                    <option value={31}>31</option>
                                                </select>
                                            </div>
                                            <div className="label col-md-2">
                                                <select
                                                    className="form-control"
                                                    aria-label=""
                                                    value={repeatBasis}
                                                    disabled={doNotRepeats == "1"}
                                                    onChange={e => handleSelectChange(e.target.value, setRepeatBasis, false)}
                                                >
                                                    <option value={0}>Days</option>
                                                    <option value={1}>Weeks</option>
                                                    <option value={2}>Months</option>

                                                </select>
                                            </div>


                                            <div
                                                className={"label col-md-5" + (repeatBasis == 2 ? "" : " divHide")}>
                                                <div className="">
                                                    <div className="row">
                                                        <div className="col col-md-1">
                                                            <input
                                                                className=" "
                                                                name="onDayMonth"
                                                                type="radio"
                                                                id="on_day"
                                                                value="1"
                                                                disabled={doNotRepeats == 1}
                                                                checked={repeatBasis2 === 1}
                                                                onChange={(e) => handleSelectChange(1, setRepeatBasis2, false)}
                                                            />
                                                        </div>
                                                        On
                                                        <div className="col col-md-2">
                                                            <select
                                                                className="form-control"
                                                                aria-label=""
                                                                value={repeatDayNum}
                                                                disabled={doNotRepeats == 1}
                                                                onChange={(e) => handleSelectChange(e.target.value, setRepeatDayNum, false)}
                                                            >
                                                                <option value={1}>1</option>
                                                                <option value={2}>2</option>
                                                                <option value={3}>3</option>
                                                                <option value={4}>4</option>
                                                                <option value={5}>5</option>
                                                                <option value={6}>6</option>
                                                                <option value={7}>7</option>
                                                                <option value={8}>8</option>
                                                                <option value={9}>9</option>
                                                                <option value={10}>10</option>
                                                                <option value={11}>11</option>
                                                                <option value={12}>12</option>
                                                                <option value={13}>13</option>
                                                                <option value={14}>14</option>
                                                                <option value={15}>15</option>
                                                                <option value={16}>16</option>
                                                                <option value={17}>17</option>
                                                                <option value={18}>18</option>
                                                                <option value={19}>19</option>
                                                                <option value={20}>20</option>
                                                                <option value={21}>21</option>
                                                                <option value={22}>22</option>
                                                                <option value={23}>23</option>
                                                                <option value={24}>24</option>
                                                                <option value={25}>25</option>
                                                                <option value={26}>26</option>
                                                                <option value={27}>27</option>
                                                                <option value={28}>28</option>
                                                                <option value={29}>29</option>
                                                                <option value={30}>30</option>
                                                                <option value={31}>31</option>
                                                            </select>
                                                        </div>
                                                        <div className="col col-md-3">
                                                            <label htmlFor="trading-day">
                                                                <input
                                                                    className=" "
                                                                    name="onDayMonth"
                                                                    type="radio"
                                                                    id="first_day"
                                                                    value="2"
                                                                    disabled={doNotRepeats == 1}
                                                                    checked={repeatBasis2 === 2}
                                                                    onChange={(e) => handleSelectChange(2, setRepeatBasis2, false)}
                                                                />
                                                                First Day
                                                            </label>

                                                        </div>
                                                        <div className="col col-md-3">
                                                            <label htmlFor="trading-day">
                                                                <input
                                                                    className=" "
                                                                    name="onDayMonth"
                                                                    type="radio"
                                                                    id="last_day"
                                                                    value="3"
                                                                    disabled={doNotRepeats == 1}
                                                                    checked={repeatBasis2 === 3}
                                                                    onChange={(e) => handleSelectChange(3, setRepeatBasis2, false)}
                                                                />
                                                                Last Day
                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        <div
                                            className={"input-container col-md-12 fullWidthSearch" + (repeatBasis == 1 ? "" : " divHide")}>
                                            <div className="label col-md-2">
                                                {(doNotRepeats == "1" ? "" :
                                                    <b className={'mandatory-red-inside'}>*</b>)}

                                                Weekly On
                                            </div>
                                            <div className="label col-md-9">
                                                <label className="checkboxMarginLeft" htmlFor="access_sun"> <input
                                                    name="access_day" type="checkbox" id="access_sun"
                                                    disabled={doNotRepeats == 1}
                                                    onChange={e => setSunChecked(e.target.checked)}
                                                    checked={sunChecked} value="sunday"/> Sunday</label>
                                                <label htmlFor="access_mon">
                                                    <input name="access_day" type="checkbox"
                                                           id="access_mon"
                                                           disabled={doNotRepeats == 1}
                                                           onChange={e => setMonChecked(e.target.checked)}
                                                           checked={monChecked} value="monday"/> Monday</label>
                                                <label className="checkboxMarginLeft" htmlFor="access_tue"> <input
                                                    name="access_day" type="checkbox" id="access_tue"
                                                    disabled={doNotRepeats == 1}
                                                    onChange={e => setTueChecked(e.target.checked)}
                                                    checked={tueChecked} value="tuesday"/> Tuesday</label>
                                                <label className="checkboxMarginLeft" htmlFor="access_wed"> <input
                                                    name="access_day" type="checkbox" id="access_wed"
                                                    disabled={doNotRepeats == 1}
                                                    onChange={e => setWedChecked(e.target.checked)}
                                                    checked={wedChecked} value="wednesday"/> Wednesday</label>
                                                <label className="checkboxMarginLeft" htmlFor="access_thu"> <input
                                                    name="access_day" type="checkbox" id="access_thu"
                                                    disabled={doNotRepeats == 1}
                                                    onChange={e => setThuChecked(e.target.checked)}
                                                    checked={thuChecked} value="thursday"/> Thursday</label>
                                                <label className="checkboxMarginLeft" htmlFor="access_fri"> <input
                                                    name="access_day" type="checkbox" id="access_fri"
                                                    disabled={doNotRepeats == 1}
                                                    onChange={e => setFriChecked(e.target.checked)}
                                                    checked={friChecked} value="friday"/> Friday</label>
                                                <label className="checkboxMarginLeft" htmlFor="access_sat"> <input
                                                    name="access_day" type="checkbox" id="access_sat"
                                                    disabled={doNotRepeats == 1}
                                                    onChange={e => setSatChecked(e.target.checked)}
                                                    checked={satChecked} value="saturday"/> Saturday</label>

                                            </div>
                                        </div>

                                        <div className="input-container col-md-12 fullWidthSearch">
                                            <div className="label col-md-2">
                                                <b className='mandatory-red-inside'>*</b>
                                                Start Date
                                            </div>
                                            <div className="label col-md-3">
                                                <ReactDatepick
                                                    value={repeatStartDate}
                                                    dateSetter={setRepeatStartDate}
                                                    resetToggle={toggleReset}

                                                />
                                            </div>
                                            <div className="label col-md-1">
                                                <b className='mandatory-red-inside'>*</b>
                                                Time
                                            </div>

                                            <div className="label col-md-2">
                                                <TimePicker onChange={(time) => setRepeatStartTime(time)}
                                                            value={repeatStartTime}/>

                                            </div>
                                        </div>
                                        <div className="input-container col-md-12 fullWidthSearch">
                                            <div className="label col-md-2">
                                                End Date
                                            </div>
                                            <div className="label col-md-3">
                                                <ReactDatepick
                                                    value={repeatEndDate}
                                                    dateSetter={setRepeatEndDate}
                                                    resetToggle={toggleReset}

                                                />
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div className="card shadow mb-4">
                                    <div
                                        className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h5 className="m-0 font-weight-bold text-primary">Dependency </h5>

                                    </div>

                                    <div className="card-body">

                                        <div className="input-container col-md-12 taskHeader">
                                            <div className="label col-md-1">
                                                <input className="checkboxMarginLeft" name="removeSelectMain"
                                                       type="checkbox"
                                                       id="removeIdMain"
                                                       value="1"
                                                       onClick={handleCheckAll}
                                                />
                                            </div>
                                            <div className="label col-md-2">Task ID</div>
                                            <div className="label col-md-3">Descriptions</div>
                                            <div className="label col-md-2">Start Time</div>
                                            <div className="label col-md-2">Recurrence</div>
                                            <div className="label col-md-2">Dependency</div>
                                        </div>
                                        {tableData.map((el, index) => (
                                            <div className="input-container col-md-12 taskChild">
                                                <div className="label col-md-1">


                                                    <input className="checkboxMarginLeft" name="removeSelect"
                                                           type="checkbox"
                                                           id={"removeId_" + index}
                                                           value="1"
                                                    />
                                                </div>
                                                <>
                                                    <div className="label col-md-2">
                                                        <select className="form-control"
                                                                defaultValue={el.tss_id}
                                                                value={el.tss_id == "" ? "" : el.tss_id}
                                                                id={"task_" + index}
                                                                onChange={(event) => handleTableOnChange(index, event.target.value)}
                                                        >
                                                            <option value="">Please select task</option>

                                                            {taskLists.map(el2 => <option
                                                                value={el2.tss_id}>{el2.tss_id}</option>)}

                                                        </select>
                                                    </div>
                                                    <div className="label col-md-3">{el.tss_desc}</div>
                                                    <div className="label col-md-2">{el.tss_start}</div>
                                                    <div className="label col-md-2">{el.recurring}</div>
                                                    <div className="label col-md-2">{el.depend}</div>
                                                </>
                                            </div>
                                        ))}


                                        <div className="label col-md-11 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleTableAdd()}>Add
                                            </button>
                                            <button className="btn btn-primary "
                                                    onClick={() => handleTableRemove()}>Delete
                                            </button>
                                        </div>
                                    </div>


                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1"></div>

                                    {currentMode.toLowerCase() == 'edit' ?
                                        <div className="label col-md-11 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                            </button>
                                            <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                            </button>
                                            {accessItem['edit'] ? <button className="btn btn-primary "
                                                                          onClick={() => handleAddBtn()}>Save</button> : ""}

                                        </div> :

                                        <div className="label col-md-11 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                            </button>
                                            <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                            </button>
                                            <button className="btn btn-primary " onClick={() => handleAddBtn()}>Save
                                            </button>
                                        </div>
                                    }


                                </div>


                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    )
        ;
};

export default Index;
