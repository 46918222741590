import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react';
import '../../../../asset/styles/customer-profile-style.css';
import api from '../../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    convertPositiveNumberHundred,
    handlePositiveNumberChange,
    handlePositiveNumberHundredChange,
    handleSelectChange
} from "../../../../general/general-tools";



const Others = React.memo(props => {
    let othersBuySusChecked = props['othersBuySusChecked'] == null ? '' : props['othersBuySusChecked'];
    let setOthersBuySusChecked = props['setOthersBuySusChecked'] == null ? '' : props['setOthersBuySusChecked'];
    let othersSellSusChecked = props['othersSellSusChecked'] == null ? '' : props['othersSellSusChecked'];
    let setOthersSellSusChecked = props['setOthersSellSusChecked'] == null ? '' : props['setOthersSellSusChecked'];
    let othersShareChSusChecked = props['othersShareChSusChecked'] == null ? '' : props['othersShareChSusChecked'];
    let setOthersShareChSusChecked = props['setOthersShareChSusChecked'] == null ? '' : props['setOthersShareChSusChecked'];
    let othersTDaySusChecked = props['othersTDaySusChecked'] == null ? '' : props['othersTDaySusChecked'];
    let setOthersTDaySusChecked = props['setOthersTDaySusChecked'] == null ? '' : props['setOthersTDaySusChecked'];

    let othersBuySusReadOnly = props['othersBuySusReadOnly'] == null ? '' : props['othersBuySusReadOnly'];
    let othersSellSusReadOnly = props['othersSellSusReadOnly'] == null ? '' : props['othersSellSusReadOnly'];
    let othersShareChSusReadOnly = props['othersShareChSusReadOnly'] == null ? '' : props['othersShareChSusReadOnly'];
    let othersTDayChSusReadOnly = props['othersTDayChSusReadOnly'] == null ? '' : props['othersTDayChSusReadOnly'];
    let popupManager = props['popupManager']== null ? '' : props['popupManager'];



    return  <>

        <div className="input-container col-md-12 fullWidthSearch exp-input-parent-single">
            <b>Others</b>
        </div>
        <div className="input-container col-md-12 fullWidthSearch center-align-text">
            <div className="label col-md-2"></div>
            <div className="label col-md-2">
                 Suspended
            </div>

        </div>
        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-2">Buy</div>
            <div className="label col-md-2 center-align-text">
                <input
                className='checkboxHuge'
                type='checkbox'
                disabled={othersBuySusReadOnly}
                checked={othersBuySusReadOnly == true ? false : othersBuySusChecked}
                onChange={e => setOthersBuySusChecked(e.target.checked)}
                />
            </div>

        </div>
        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-2">Sell</div>
            <div className="label col-md-2 center-align-text">
                <input
                    className='checkboxHuge'
                    type='checkbox'
                    disabled={othersSellSusReadOnly}
                    checked={othersSellSusReadOnly == true ? false : othersSellSusChecked}
                    onChange={e => setOthersSellSusChecked(e.target.checked)}
                />
            </div>
        </div>
        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-2">Share Check</div>
            <div className="label col-md-2 center-align-text">
                <input
                    className='checkboxHuge'
                    type='checkbox'
                    disabled={othersShareChSusReadOnly}
                    checked={othersShareChSusReadOnly == true ? false : othersShareChSusChecked}
                    onChange={e => setOthersShareChSusChecked(e.target.checked)}
                />
            </div>
        </div>
        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-2">T-Day Amendment</div>
            <div className="label col-md-2 center-align-text">
                <input
                    className='checkboxHuge'
                    type='checkbox'
                    disabled={othersTDayChSusReadOnly}
                    checked={othersTDayChSusReadOnly == true ? false : othersTDaySusChecked}
                    onChange={e => setOthersTDaySusChecked(e.target.checked)}
                />
            </div>
        </div>
    </>
});
export default Others;
