import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    useLocalStorage,
    profileStatusOption,
    handlePopup,
    getDefaultAccessMat,
    getNavIdByPath, convertFuncPerm, convertErMsg, specialCharCheck
} from "../../../general/general-tools";
import Multiselect from "multiselect-react-dropdown";
import { usePopupManager } from "react-popup-manager";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";


const Index = () => {
    const {setLoading} = useLoading();

    const [tableData, setTableData] = useLocalStorage("mst_cd_tblDat",[]);
    const [totalRecords, setTotalRecords] = useLocalStorage("mst_cd_totRec",0);
    const [currentPage, setCurrentPage] = useLocalStorage("mst_cd_curPage",1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage("mst_cd_curPerPg",10);

    const [searchInput, setSearchInput] = useLocalStorage("mst_cd_searchInput","");
    const [searchDealerCode, serSearchDealerCode] = useLocalStorage("mst_cd_searchDealerCode","");
    const [searchBranchCode, setSearchBranchCode] = useLocalStorage("mst_cd_searchBranchCode","");
    const [searchStatus, setSearchStatus] = useLocalStorage("mst_cd_searchStatus","");
    const [branchList, setBranchList] = useState([]);
    const isInitialMount = useRef(true);
    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat():getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);



    useLayoutEffect(() => {
        handleBranchList();

        if (tableData.length > 0) {
            handleInitList(searchInput, searchBranchCode[0] == null ? "" : searchBranchCode[0]['id'], searchDealerCode, searchStatus)
        }else{
            handleResetBtn();
        }
    },[])


    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleInitList(searchInput, searchBranchCode[0] == null ? "" : searchBranchCode[0]['id'], searchDealerCode, searchStatus);
        }
    }, [currentPage, currentPerPage]);

    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {
        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }
    useEnterKeyPress(handleEnterKeyPress);

    const handleSearch = async (newSearch = false) => {
        if(searchInput.trim().length > 0 && searchInput.length < 3){
            handlePopup(true,"Error","Search term require at least 3 characters.",popupManager)

            return false;
        }

        if(searchDealerCode.trim().length > 0 && searchDealerCode.length < 3){
            handlePopup(true,"Error","Search term require at least 3 characters.",popupManager)
            return false;
        }
        handleInitList(searchInput, searchBranchCode[0] == null ? "" : searchBranchCode[0]['id'], searchDealerCode, searchStatus,newSearch)

    }

    const handleInitList = async (searchTerm,branchCode,dealerCode,status,newSearch = false) => {
        setLoading(true);

        let curPage = currentPage;
           if (newSearch) {
            setCurrentPage(1)
            curPage= 1;
        }

        api.getAccountDealerList(currentPerPage, curPage, searchTerm, branchCode, dealerCode, status).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    const timeout = setTimeout(() => {
                        setTableData(dataList);
                        let totalPage = parseInt(result.data.totalrow)
                        setTotalRecords(totalPage);
                        setLoading(false);
                    }, 500);
                    return () => clearTimeout(timeout);
                } else {
                    handlePopup(true,"Error","No results found",popupManager)
                    setTableData([]);
                    setTotalRecords(10);
                    setLoading(false);
                }
            }else{
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setTableData([]);
                setTotalRecords(10);
                setLoading(false);
            }

        });
    };

    const handleBranchList = async () => {
        setLoading(true);
        api.getBranchList(999, 1, 1, null).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdown(dataList);
                    setBranchList(TreeData);
                } else {
                    handlePopup(true,"Error","No results found",popupManager)
                    setTableData([]);
                    setTotalRecords(10);
                }
            } else {
                handlePopup(true,"Error","No results found",popupManager)
                setTableData([]);
                setTotalRecords(10);
            }
            setLoading(false);

        });
    };

    const convertRawIntoDropdown = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                code: data[i]['bb_id'],
                name: data[i]['bb_code'] + " | " + data[i]['bb_name'],
                id: data[i]['bb_id']
            });
        }
        return returnDat;
    }





    const handleOnclick = async (row) => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "clientdealer_opr", {state: {row: row,     perm: actualPerm,mode: 'Edit'}});
        setLoading(false);
    };
    const handleAddBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "clientdealer_opr", {state: {row: "",     perm: actualPerm,mode: 'Add'}});
        setLoading(false);
    };
    const handleResetBtn = async () => {
        await setLoading(true);
        setSearchInput("");
        serSearchDealerCode("");
        setSearchBranchCode("");
        setSearchStatus("");
        setLoading(false);
    };
    const onSelect = (selectedList, selectedItem) => {
        if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur();
        setSearchBranchCode([selectedItem]);
    }
    const onRemove = (selectedList, selectedItem) => {
        setSearchBranchCode("");
    }

    const columns = useMemo(
        () => [
            {
                id: row => row.a_acctno,
                name: "Account Code",
                selector: row => row.a_acctno,
                sortable: true,
                // center: true,
                cell: (row: { a_acctno: any }) => (
                    <>
                        <a className="tableItemsHlink" onClick={() => handleOnclick(row)}>{row.a_acctno}</a>
                    </>
                )
            },
            {
                name: 'Client Name',
                selector: row => row.p_fullname,
                sortable: true
            },
            {
                name: 'CDS Number',
                selector: row => row.p_cdsno,
                sortable: true,
            },
            {
                name: 'Branch Code',
                selector: row => row.bb_code,
                sortable: true,
            },
            {
                name: 'Dealer Code',
                selector: row => row.d_code,
                sortable: true,
            },
            {
                name: 'Status',
                selector: row => row.a_status == 0? "Inactive" : row.a_status == 1? "Active" : row.a_status == 2? "Suspended" : row.a_status == 3 ? "Expired": row.a_status == 4? "Dormant" : "Closed",
                sortable: true,
            }
        ],
        []
    );

    // "a_type": 0, // 0-cash, 1-margin, 2-cash-upfront, 3-collateralized
    // "a_status": 1 // 0-Inactive, 1-active, 2-suspended, 3-expired, 4-dormant, 5-closed
    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Master File
                                Maintenance &gt; Client Dealer - Search</h5>
                            <div className="dropdown no-arrow">
                                {/*<a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"*/}
                                {/*   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                                {/*    <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>*/}
                                {/*</a>*/}
                                {/*<div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"*/}
                                {/*     aria-labelledby="dropdownMenuLink">*/}
                                {/*    <div className="dropdown-header">Dropdown Header:</div>*/}
                                {/*    <a className="dropdown-item" href="#">Action</a>*/}
                                {/*    <a className="dropdown-item" href="#">Another action</a>*/}
                                {/*    <div className="dropdown-divider"></div>*/}
                                {/*    <a className="dropdown-item" href="#">Something else here</a>*/}
                                {/*</div>*/}
                            </div>

                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Search</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="form-control "
                                            autoComplete='off'

                                            type="text"
                                            placeholder="Search by Account No. / CDS No. / Client Name "
                                            aria-label="Search Input"
                                            value={searchInput}
                                            onChange={(e) => setSearchInput(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setSearchInput,popupManager)}

                                        />
                                    </div>


                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Dealer Code</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="form-control "

                                            type="text"
                                            placeholder="Search by Dealer Code"
                                            aria-label="Search Input"
                                            value={searchDealerCode}
                                            onChange={(e) => serSearchDealerCode(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,serSearchDealerCode,popupManager)}

                                        />
                                    </div>


                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Branch</div>
                                    <div className=" col-md-11">
                                        <Multiselect
                                            options={branchList}
                                            onSelect={onSelect}
                                            onRemove={onRemove}
                                            selectedValues={searchBranchCode}
                                            displayValue="name"
                                            // singleSelect={true}
                                            placeholder="Search by Branch Code / Branch Name"
                                            // selectionLimit={1}
                                            // hidePlaceholder={true}
                                            showCheckbox={false}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>


                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Status</div>
                                    <div className="label col-md-11">
                                        <select
                                            className="form-control"
                                            id="branch"
                                            aria-label=""
                                            value={searchStatus}
                                            onChange={(e) => setSearchStatus(e.target.value)}
                                        >
                                            {profileStatusOption()}

                                        </select>
                                    </div>


                                </div>
                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary "  id="btn-search-main"  onClick={() => handleSearch(true)}>Search
                                        </button>
                                        <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    perPageQty={setCurrentPerPage}
                                    columns={columns}
                                    data={tableData}
                                    pagination={{
                                        currentPage: currentPage,
                                        totalRecords: totalRecords,
                                        currentPerPage: currentPerPage,

                                    }}
                                />
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
