import {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import sidebarSource from '../nav-config';
import {useLoading} from '../../../context/LoadingContext';
import {Link} from 'react-router-dom';
import {changeBase, getDefaultAccessMat, isElementVisible, zeroFill} from "../../general/general-tools";
import {getUserAccessDetail, setLogout} from '../../../hooks/handleCookie';
import {useCookies} from "react-cookie";
import ichome from '../../../../public/img/icon/ic-dashboard.svg';
import icadmin from '../../../../public/img/icon/ic-admin.svg';
import icrisk from '../../../../public/img/icon/ic-risk.svg';
import icmaster from '../../../../public/img/icon/ic-masterfile.svg';
import icaudit from '../../../../public/img/icon/ic-enq.svg';
import icenquery from '../../../../public/img/icon/ic-audit.svg';


interface Props {
    position?: object;
}

interface siderPosition {
    left?: number;
}


const Index = (props: Props) => {
        const {loading, setLoading} = useLoading();
        let navigate = useNavigate();

        const location = useLocation();
        const [toggleMenu, setToggleMenu] = useState(true);
        const [sidebarProps, setSidebarProps] = useState([]);
        const [sidebarCurrentSel, setSidebarCurrentSel] = useState('');
        const [subMenuCurrentSel, setSubMenuCurrentSel] = useState('');
        const [onWidth, setOnWidth] = useState(0);
        const [offWidth, setoffWidth] = useState(0);
        const [isMobile, setIsMobile] = useState(true);
        const history = useLocation();
        const [cookies, setCookie, removeCookie] = useCookies(['admin_user']);

        let rightColEle = document.getElementById('rightColItem');
        useEffect(() => {
            let lastSegment = location.pathname;
            initSideOptions(sidebarSource, lastSegment);
            setTimeout(() => {
                if (document.getElementById('rightColItem')) {
                    let rightHeight = (document.getElementById('rightColItem').clientHeight);
                    let windowWidth = window.screen.width;
                    document.getElementById("sideBarDiv").style.height = ((parseInt(rightHeight) + 1000)).toString() + "px";
                    //Detect mobile view
                    if (windowWidth < 700) {
                        setIsMobile(false);
                        // setToggleMenu(false);
                        document.getElementById("rightColItem").style.width = "100%";
                    }
                }

            }, 10);

            document.getElementById('rightColItem').addEventListener('click', function () {
                removeAvailSideDrop();
                setSidebarCurrentSel("")
                setSubMenuCurrentSel("")
            }, false);

            // return  window.removeEventListener('click', onMouseMove);

        }, [cookies['access_level']]);


        const initSideOptions = (data: any, lastSegment: String) => {
            let foundPage = false;
            let newArr: any = [];
            let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat() : getUserAccessDetail();

            for (var i = 0; i < data.length; i++) {
                let row = data[i];
                let funcId = data[i]['id'];
                let funcPerm = userAccess[funcId];
                //L1 Checking
                if (!userAccess[data[i]['id']]) {
                    row['show'] = false;
                    continue;
                }
                let viewPerm = funcPerm.toString()[0];

                if (data[i]['url'] == "/" && viewPerm == 0) {
                    row['show'] = false;
                } else if (viewPerm == 1) {
                    row['show'] = true;
                } else {
                    row['show'] = false;
                }

                data[i].selected = false;
                if (row.url == lastSegment) {
                    data[i].selected = true;
                    foundPage = true;
                }

                if (row.subMenu && row.subMenu.length > 0) {
                    //check submenu data
                    for (var z = 0; z < row.subMenu.length; z++) {
                        //default set to false;
                        let innerRow = row.subMenu[z];
                        data[i].subMenu[z].selected = false;
                        if (!userAccess[data[i].subMenu[z]['id']]) {
                            innerRow['show'] = false;
                            continue;
                        }
                        let viewPermLvl2 = userAccess[data[i].subMenu[z]['id']].toString()[0];

                        //L2 Checking
                        if (viewPermLvl2 == 0) innerRow['show'] = false;
                        else {
                            if (!row['show']) row['show'] = true;
                            innerRow['show'] = true;
                        }
                        if (innerRow.url == lastSegment) {
                            //selected submenu option
                            data[i].subMenu[z].selected = true;
                            //selected parent option
                            data[i].selected = true;
                            foundPage = true;
                        }

                        if (innerRow.subMenu2 && innerRow.subMenu2.length > 0) {
                            let thirdLvlChild = innerRow.subMenu2;


                            for (let j = 0; j < thirdLvlChild.length; j++) {
                                let thridEle = thirdLvlChild[j];
                                let specialPerm = thridEle['specialPerm'] ?? false;
                                if (!userAccess[thridEle['id']] || specialPerm) {
                                    thridEle['show'] = false;
                                    continue;
                                }
                                let viewPermLvl3 = userAccess[thridEle['id']].toString()[0];
                                //L3 Checking
                                if (viewPermLvl3 == 0) thridEle['show'] = false;
                                else {
                                    if (!row['show']) row['show'] = true;
                                    if (!innerRow['show']) innerRow['show'] = true;
                                    thridEle['show'] = true;
                                }
                            }

                        }

                    }
                }
                newArr.push(row);
            }

            if (foundPage == false && data.length > 0) {
                data[0].selected = true;
            }
            setSidebarProps(newArr);
        };

        const setDropdownSide = async (ele_id: string) => {
            let element = document.getElementById("s_id_" + ele_id.replaceAll(' ', ''));
            let elementIcon = document.getElementById("i_id_" + ele_id.replaceAll(' ', ''));

            if (!element) return;
            removeAvailSideDrop();
            removeAvailThirdLevelDrop(ele_id);

            if (toggleMenu || !isMobile) {
                element.classList.remove('dropdown-outter-layer');
                element.classList.add('dropdown-outter-layer-shrink-view');
            } else {
                element.classList.add('dropdown-outter-layer');
                element.classList.remove('dropdown-outter-layer-shrink-view');
            }

            if (sidebarCurrentSel != element.id && sidebarCurrentSel == '') {
                setSidebarCurrentSel(element.id);
                elementIcon.classList.remove('collapsed');
                element.classList.add('show');
            } else {
                if (sidebarCurrentSel != "N/A") {
                    let elementIconPrev = document.getElementById("i_id_" + sidebarCurrentSel.replaceAll("s_id_", ''));
                    elementIconPrev.classList.add('collapsed');
                    elementIcon.classList.add('collapsed');
                    element.classList.remove('show');
                    setSidebarCurrentSel("N/A");
                } else {
                    setSidebarCurrentSel(element.id);
                    elementIcon.classList.remove('collapsed');
                    element.classList.add('show');
                }
            }
        };

        const toggleTopDropdownSize = (isToggled: boolean, leftColWidth, leftWidth, rightWidth) => {
            let width = rightWidth + leftWidth;
            let rightPerc = "-3%";
            if (isToggled) {
                width = rightWidth - leftColWidth;
                rightPerc = "1%";
            }
            document.getElementById("popUpMessage").style.width = "calc(" + width + "px - 10%";
            document.getElementById("popUpAlerts").style.width = "calc(" + width + "px - 10%)";
            // document.getElementById("userDropdown").style.width = "calc(" + width + "px - 10%)";
            document.getElementById("searchDropdown").style.width = "calc(" + width + "px - 10%)";
            document.getElementById("popUpMessage").style.right = rightPerc;
            document.getElementById("popUpAlerts").style.right = rightPerc;
            document.getElementById("userDropdown").style.right = rightPerc;
            document.getElementById("searchDropdown").style.right = rightPerc;
        }

        const setMenuState = async (url: string) => {
            setTimeout(() => {
                let leftWidth = document.getElementById('sideBarDiv').clientWidth;
                let leftColWidth = document.getElementById('leftColItem').clientWidth;
                let rightWidth = document.getElementById('rightColItem').clientWidth;
                let currentShowingSide = document.getElementById(sidebarCurrentSel);
                toggleTopDropdownSize(toggleMenu, leftColWidth, leftWidth, rightWidth);
                let sideMenuLabel = document.getElementsByClassName('sidebar_lbl_text');
                let sideNavLabel = document.getElementsByClassName('sideNavF1Lbl');

                if (onWidth == 0 && offWidth == 0) {
                    if (!isMobile) {
                        setOnWidth((rightWidth - leftColWidth));
                        if (sideMenuLabel) {
                            for (var i = 0; i < sideMenuLabel.length; i++) {
                                sideMenuLabel[i].style.display = "none";
                            }
                        }
                    } else {
                        setOnWidth((rightWidth - leftWidth));
                    }
                    setoffWidth(rightWidth);
                    document.getElementById("sidebarToggle").click();
                } else {

                    if (toggleMenu) {
                        if (sideNavLabel) {
                            for (var i = 0; i < sideNavLabel.length; i++) {
                                sideNavLabel[i].style.display = "none";
                            }
                        }
                        setToggleMenu(false);
                        if (sideMenuLabel && isMobile) for (var i = 0; i < sideMenuLabel.length; i++) {
                            sideMenuLabel[i].style.display = "inline";
                        }
                        document.getElementById("rightColItem").style.width = (onWidth - (onWidth * 0.03)).toString() + "px";
                        currentShowingSide.classList.remove('dropdown-outter-layer-shrink-view');
                        currentShowingSide.classList.add('dropdown-outter-layer');

                    } else {
                        if (sideNavLabel) {
                            for (var i = 0; i < sideNavLabel.length; i++) {
                                sideNavLabel[i].style.display = "block";
                            }
                        }
                        setToggleMenu(true);
                        if (sideMenuLabel) for (var i = 0; i < sideMenuLabel.length; i++) {
                            sideMenuLabel[i].style.display = "none";
                        }
                        document.getElementById("rightColItem").style.width = offWidth.toString() + "px";
                        currentShowingSide.classList.remove('dropdown-outter-layer');
                        currentShowingSide.classList.add('dropdown-outter-layer-shrink-view');
                    }
                }


            }, 50);


        };

        const removeAvailSideDrop = () => {
            var cusid_ele = document.getElementsByClassName('dropdown-outter-layer-shrink-view show');
            for (var i = 0; i < cusid_ele.length; ++i) {
                let itemToDisable = document.getElementById(cusid_ele[i]['id']);
                itemToDisable.classList.remove('show');
                itemToDisable.classList.add('collapsed');
            }

            var cusid_ele = document.getElementsByClassName('dropdown-outter-layer show');
            for (var i = 0; i < cusid_ele.length; ++i) {
                let itemToDisable = document.getElementById(cusid_ele[i]['id']);
                itemToDisable.classList.remove('show');
                itemToDisable.classList.add('collapsed');

            }

            for (var i = 0; i < cusid_ele.length; ++i) {
                if (cusid_ele[i]['id']) {
                    let itemToDisable = document.getElementById(cusid_ele[i]['id']);
                    itemToDisable.classList.add('collapsed');
                }
            }


        };

        const removeAvailThirdLevelDrop = (ele_id) => {
            let sndLvlBtn = "div_" + subMenuCurrentSel;
            let arrowEle = "arrow_" + subMenuCurrentSel;

            if (document.getElementById(sndLvlBtn) && document.getElementById(arrowEle)) {
                if (subMenuCurrentSel == ele_id) return;
                document.getElementById(sndLvlBtn).style.display = 'none';
                document.getElementById(arrowEle).classList.remove('arrow-up');
                document.getElementById(arrowEle).classList.add('arrow-down');
            } else {
                if (!subMenuCurrentSel) return;
                document.getElementById(sndLvlBtn).style.display = 'none';
                document.getElementById(arrowEle).classList.add('arrow-up');
                document.getElementById(arrowEle).classList.remove('arrow-down');
                setSubMenuCurrentSel(null);
            }
        };

        const expandThirdLevelMenu = async (ele_id: string) => {
            let sndLvlBtn = "div_" + ele_id;
            let arrowEle = "arrow_" + ele_id;
            let displayClass = document.getElementById(sndLvlBtn).style.display.toString();

            if (isElementVisible(sndLvlBtn) && displayClass != '' && displayClass != 'none') {
                document.getElementById(sndLvlBtn).style.display = 'none';
                document.getElementById(arrowEle).classList.remove('arrow-up');
                document.getElementById(arrowEle).classList.add('arrow-down');

            } else {
                document.getElementById(sndLvlBtn).style.display = 'block';
                document.getElementById(arrowEle).classList.remove('arrow-down');
                document.getElementById(arrowEle).classList.add('arrow-up');
            }
            removeAvailThirdLevelDrop(ele_id);
            setSubMenuCurrentSel(ele_id);

        };

        const redirect = async (url: string) => {
            if (url) {
                setSidebarCurrentSel("N/A");
                removeAvailSideDrop();
                await setLoading(true);
                navigate(url);
                setLoading(false);
            }
        };

        return (
            <div
                className={toggleMenu ? ' sidebar_width navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled' : 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion '}
                id="sideBarDiv">

                {/* <!-- Header Division --> */}

                <a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                    <div className="sidebar-brand-icon rotate-n-15">
                        <i className="fas fa-laugh-wink"></i>
                    </div>
                    <div className="sidebar-brand-text mx-3"><b>TDS Admin</b>

                    </div>
                </a>
                <hr className="sidebar-divider"/>

                {/* <!-- Render side menu --> */}
                {sidebarProps.map(function (row, i) {
                    // console.log(row)
                    let row_url = row.url;
                    let className = 'nav-link ';
                    let classNameSubMenu = 'dropdown-inner-item';
                    let dataToogle = '';

                    let subClassName = 'col-item-cls collapse ';
                    let subClassExpend = 'false ';

                    if (row.subMenu) {
                        className = 'nav-link collapsed';
                        dataToogle = 'collapse';
                    }

                    let parentIcons = ichome;

                    switch (row.icon) {

                        case 'fa fa-gears':
                            parentIcons = icadmin;
                            break;
                        case 'fa fa-thermometer-3':
                            parentIcons = icrisk;
                            break;
                        case 'fa fa-gear':
                            parentIcons = icmaster;
                            break;
                        case 'fa fa-tasks':
                            parentIcons = icenquery;
                            break;
                        case 'fa fa-laptop':
                            parentIcons = icaudit;
                            break;
                    }

                    subClassExpend = 'true';
                    if (row.url === undefined) row_url = "#";
                    let compt = (

                        <div>
                            {row.show == true && (
                                <li className="nav-item" onClick={() => {
                                    setDropdownSide((row.label).replaceAll(' ', ''))
                                }}>
                                    <a id={"i_id_" + (row.label).replaceAll(' ', '')} className={className}
                                       data-toggle={dataToogle}
                                       data-target="#collapseUtilities" aria-expanded={subClassExpend}
                                       redirect
                                       onClick={() => {
                                           redirect(row.url)
                                       }}
                                       aria-controls="collapseUtilities">
                                        <i
                                        >
                                            <img src={parentIcons} alt="SVG"
                                                 style={{width: "1.9em", "font-size": "1.5em", "marginRight": ".2em"}}/>


                                        </i>

                                        {toggleMenu ?
                                            <span className={"sidebar_lbl_text divHide"}
                                                  style={{"font-size": "1.1em", display: "none"}}>{row.label}</span> :
                                            <span className={"sidebar_lbl_text divHide"}
                                                  style={{"font-size": "1.1em"}}>{row.label}</span>
                                        }

                                    </a>

                                </li>
                            )}

                            {/* render if submenu data exist, besides 38 (Enquiries) */}
                            {row.subMenu && row.id != 38 && (
                                <div id={"s_id_" + (row.label).replaceAll(' ', '')} className={subClassName}
                                     aria-labelledby="">
                                    <div className="bg-white py-2 rounded dropdown-outter-layer">
                                        <div className="sideNavF1Lbl"><span className="sideNavF1LblSpan"><b>{row.label}</b></span>
                                        </div>
                                        {row.subMenu &&
                                            row.subMenu.length > 0 &&
                                            row.subMenu.map(function (row, i) {
                                                // row.subMenu == true
                                                if (row.show) {
                                                    return (

                                                        <div>

                                                            {/* Level 2 Menu */}
                                                            <Link className={classNameSubMenu} onClick={() => {
                                                                // Trigger different onclick
                                                                {
                                                                    row.subMenu2 && row.subMenu2.length > 0 && (expandThirdLevelMenu((row.label).replaceAll(' ', '')))
                                                                }
                                                                {
                                                                    !row.subMenu2 && (redirect(row.url))
                                                                }
                                                            }}>
                                                                <span style={{"font-size": "1.1em"}}>{row.label}</span>
                                                                {row.subMenu2 && row.subMenu2.length > 0 && (
                                                                    <i id={"arrow_" + (row.label).replaceAll(' ', '')}
                                                                       className="arrow-down"></i>)}
                                                            </Link>

                                                            {/* Level 3 Menu */}
                                                            {row.subMenu2 &&
                                                                row.subMenu2.length > 0 && (
                                                                    <div
                                                                        className="bg-white py-2 rounded  dropdown-sub-layer "
                                                                        id={"div_" + (row.label).replaceAll(' ', '')}>
                                                                        {
                                                                            row.subMenu2.map(function (row, i) {
                                                                                if (row.show) {
                                                                                    return (
                                                                                        <div>
                                                                                            <Link
                                                                                                style={{"font-size": "1em"}}
                                                                                                className="dropdown-inner-item-third-level"
                                                                                                onClick={() => {
                                                                                                    redirect(row.url)
                                                                                                }}>- {row.label}</Link>
                                                                                        </div>
                                                                                    );
                                                                                }

                                                                            })}
                                                                    </div>
                                                                )}
                                                        </div>

                                                    );
                                                }

                                            })}


                                    </div>

                                </div>
                            )}
                            {!row.subMenu && (

                                <div
                                    // style={{margin: "0.5em"}}
                                >
                                    <div className={subClassName} aria-labelledby=""
                                         data-parent="#accordionSidebar">

                                    </div>

                                </div>

                            )}
                            <hr className="sidebar-divider"/>

                        </div>

                    )
                    return compt;

                })
                }
                <hr className="sidebar-divider"/>


                {/* <!-- Sidebar Toggler (Sidebar) --> */}
                <div className="text-center d-none d-md-inline"
                     style={{visibility: isMobile ? "visible" : "hidden",}}
                >
                    <button onClick={() => {
                        setMenuState()
                    }} style={{margin: "0.8em"}} className="rounded-circle border-0" id="sidebarToggle"></button>
                </div>


            </div>
        );
    }
;
// export default connect(mapStateToProps, mapDispatchToProps)(Index);
export default Index;

