import React, {useState, useEffect, useMemo} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';

import {numberWithCommas} from '../../../general/convertor';
import {confirmAlert} from "react-confirm-alert";
import api from "../../../../apis";
import { usePopupManager } from "react-popup-manager";
import ModalConfirm from "../../public/ModalConfirm";
import {convertErMsg, disableAllEleInDiv, handlePopup, specialCharCheck} from "../../../general/general-tools";

const Index = () => {
    const {setLoading} = useLoading();


    const [currentMode, setCurrentMode] = useState("");
    const [currentBrokerId, setCurrentBrokerId] = useState("");
    const [currentBrokerCode, setCurrentBrokerCode] = useState("");
    const [currentBrokerShortName, setCurrentBrokerShortName] = useState("");
    const [currentBrokerName, setCurrentBrokerName] = useState("");
    const [accessItem, setAccessItem] = useState({});


    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();

    //Load initial data and mode
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        if(location.state){
            if (location.state.row) {
                setAccessItem(location.state.perm);
                if(!location.state.perm['edit']){
                    setTimeout(function() {
                        disableAllEleInDiv("mainDiv");
                    }, 50);
                }
                setCurrentBrokerId(location.state.row.cb_id);
                setCurrentBrokerCode(location.state.row.cb_code);
                setCurrentBrokerShortName(location.state.row.cb_sname);
                setCurrentBrokerName(location.state.row.cb_lname);

            }
            setCurrentMode(location.state.mode)
        }
    }, [location.state]);


    // Edit
    const handleBackBtn = async () => {
        await setLoading(true);
        navigate("/"+process.env.REACT_APP_URL_PREFIX+"broker",{state: {mode: 'back'}});
        setLoading(false);
    };
    const handleRemoveBtn = async (country_code) => {
        await setLoading(true);

        popupManager.open(ModalConfirm, {
            title: 'Delete',
            content: 'Are you sure you want to delete?',
            onClose: (...params) => handleRemoveItem(params[0])
        });

        setLoading(false);
    };
    const handleRemoveItem = async (selBoolean) => {
        if(!selBoolean) return;
        api.deleteBroker(currentBrokerId).then((result: any) => {
            if (result != false && result.status == 0) {
                setLoading(false);
                handlePopup(true, "Success", "Broker deleted successfully.", popupManager,true,"broker",navigate)
            } else {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
            }
        });
    };

    const handleSaveBtn = async () => {
        await setLoading(true);
        if (currentBrokerCode.trim().length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Broker Code not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if (currentBrokerName.trim().length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Broker Name not entered.", popupManager)
            setLoading(false);
            return false;
        }


        if (currentBrokerCode.trim().length <2 || currentBrokerCode.trim().length > 8) {
            handlePopup(true, "Error", "Mandatory Field - Broker Code not entered.", popupManager,null,null,null,"(Broker code : Minimum 2 up to 8 characters)")

            setLoading(false);
            return false;
        }


        if (
            currentBrokerCode.trim().length > 1 &&
            currentBrokerName.trim().length > 1
        ) {
            let data = {
                "id" : currentBrokerId,
                "code" : currentBrokerCode,
                "sname" : currentBrokerShortName,
                "lname" : currentBrokerName
            };

            api.updateBroker(data).then((result: any) => {
                if (result != false && result.status == 0) {
                    handlePopup(true, "Success", "Broker information updated successfully.", popupManager,true,"broker",navigate);
                } else {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    setLoading(false);
                }
            });
        } else  handlePopup(true,"Error","Mandatory fields require at least 2 characters.",popupManager)
        setLoading(false);
    };


    const handleResetBtn = async (row) => {
        var todayDate = getTodayDate();
        await setLoading(true);
        setCurrentBrokerCode("");
        setCurrentBrokerShortName("");
        setCurrentBrokerName("");
        setLoading(false);
    };


    const handleAddBtn = async () => {
        await setLoading(true);

        if (currentBrokerCode.trim().length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Broker Code not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if (currentBrokerName.trim().length == 0) {
            handlePopup(true, "Error", "Mandatory Field - Broker Name not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if (currentBrokerCode.trim().length <2 || currentBrokerCode.trim().length > 8) {
            handlePopup(true, "Error", "Mandatory Field - Broker Code not entered.", popupManager,null,null,null,"(Broker code : Minimum 2 up to 8 characters)")

            setLoading(false);
            return false;
        }

        if (
            currentBrokerCode.trim().length > 1 &&
            currentBrokerName.trim().length > 1
        ) {
            let data = {
                "id" : currentBrokerId,
                "code" : currentBrokerCode,
                "sname" : currentBrokerShortName,
                "lname" : currentBrokerName
            };

            api.addBroker(data).then((result: any) => {
                if (result != false && result.status == 0) {
                    setLoading(false);
                    handlePopup(true, "Success", "Broker created successfully.", popupManager,true,"broker",navigate)
                } else {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    setLoading(false);
                }
            });

        } else  handlePopup(true,"Error","Mandatory fields require at least 2 characters.",popupManager)
        setLoading(false);
    };


    const getTodayDate = ()=>{
        return new Date().toLocaleDateString('en-CA', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }


    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Master File
                                Maintenance &gt; Broker - {currentMode}</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-2">Broker Code</div>
                                        <input
                                            className="form-control "
                                            autoComplete='off'
                                            type="text"
                                            placeholder="e.g. 012"
                                            aria-label=""
                                            value={currentBrokerCode}
                                            onChange={(e) => setCurrentBrokerCode(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentBrokerCode,popupManager)}

                                        />
                                    <b className='mandatory-red'>*</b>



                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Broker Short Name</div>
                                        <input
                                            className="form-control "
                                            autoComplete='off'
                                            type="text"
                                            placeholder="e.g. NomuraSec"
                                            aria-label="Search Input"
                                            value={currentBrokerShortName}
                                            onChange={(e) => setCurrentBrokerShortName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentBrokerShortName,popupManager)}

                                        />


                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-2">Broker Name</div>
                                    <input
                                        className="form-control "
                                        autoComplete='off'
                                        type="text"
                                        placeholder="e.g. Nomura Securities Malaysi Sdn Bhd"
                                        aria-label="Search Input"
                                        value={currentBrokerName}
                                        onChange={(e) => setCurrentBrokerName(e.target.value)}
                                        onBlur={(e) => specialCharCheck(e.target.value,setCurrentBrokerName,popupManager)}

                                    />
                                    <b className='mandatory-red'>*</b>


                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2"></div>

                                    {currentMode.toLowerCase() == 'edit' ?
                                        <div className="label col-md-10 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>
                                            {accessItem['delete'] ? <button className="btn btn-primary " onClick={() => handleRemoveBtn(currentBrokerId)}>Delete</button> :""}
                                            {accessItem['edit'] ? <button className="btn btn-primary " onClick={() => handleSaveBtn()}>Save</button>:""}

                                        </div> :

                                        <div className="label col-md-10 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>
                                            <button className="btn btn-primary " onClick={() => handleResetBtn(currentBrokerId)}>Reset</button>
                                            <button className="btn btn-primary " onClick={() => handleAddBtn()}>Save</button>
                                        </div>
                                    }


                                </div>


                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
