import React, { useState, useLayoutEffect, useMemo } from 'react';
import api from '../../../../apis';

import { useParams } from 'react-router-dom';
import {getMimeTypes} from "../../../general/general-tools";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const Index = () => {
    let { file } = useParams();

    const [imgSrc, setImgSrc] = useState<any>('');
    const [mimeType, setMimeType] = useState<any>('');
    const [fileName, setFileName] = useState<any>('');
    const [documentUri, setDocumentUri] = useState<any>('');

    useLayoutEffect(() => {
        let mimeType = types[file.split('.').pop()] || null;
        if (mimeType) {
            api.getFile(file || '').then((result: any) => {
                // let img = new File([result], file);
                // setImgSrc(img);

                let linkSource =
                    'data:' +
                    mimeType +
                    ';base64,' +
                    btoa(
                        new Uint8Array(result).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            ''
                        )
                    );
                setMimeType(mimeType);
                setImgSrc(linkSource);
                setFileName(file);

                // const reader = new FileReader();
                // let fileBlb = b64toBlob(linkSource,mimeType);
                // const cumentUri = URL.createObjectURL(fileBlb);
                //
                // let fileBlobs = new Blob([new Uint8Array(result)], { type: mimeType});
                // setDocumentUri(fileBlobs);
                // console.log(fileBlobs)

                // reader.readAsDataURL(fileBlb);
                // reader.onload = () => {
                //     setDocumentUri(reader.result as string);
                // };
                // reader.onerror = () => {
                //     throw new Error('Fail to load the file');
                // };

                // const downloadLink = document.createElement('a');
                // const fileName = file;
                // downloadLink.href = linkSource;
                // downloadLink.download = fileName;
                // downloadLink.click();
            });

        }


    }, []);

    const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    const handeUnsupportedDownload = async () => {
        const downloadLink = document.createElement('a');
        downloadLink.href = imgSrc;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    return (
        <div className="w-100">
            {
                mimeType &&
            /[\/.](gif|jpg|jpeg|tiff|png)$/i.test(mimeType) == true ? (
                <img
                    src={imgSrc}
                    style={{
                        width: 'auto',
                        maxWidth: '50%'
                    }}
                />
            ) :
                    mimeType && /[\/.](docx|doc|xlxs|xlsx|msword|vnd.ms-excel|vnd.ms-powerpoint)$/i.test(mimeType) == true ? (
                   <div>
                       <button className={'btn btn-primary'} style={{'margin-top':'5%','margin-left':"40%"}} onClick={() => handeUnsupportedDownload()}>Preview not available, please download</button>
                   </div>



            ) : (
                // another file
                <embed
                    src={imgSrc}
                    style={{
                        width: '100%',
                        height: '100vh'
                    }}
                    type={mimeType}
                ></embed>


            )}



        </div>

    );
};

const types = getMimeTypes();

export default Index;
