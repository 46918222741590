import { handleRequestPost, handleRequestGet } from '../axios';

export const getAuditOprList = async () => {
    let url = '/adminuser';
    let result = await handleRequestGet(url, null);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

};

export const getAuditVarList = async (param) => {
    let url = '/auditlog';
    let result = await handleRequestGet(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }
};
