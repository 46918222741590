import {
    handleRequestPost,
    handleRequestGet,
    handleRequestMultipart,
    queryParamStr,

} from '../axios';
import {handleFormPost} from '../axiosFormData';
import {handleRequestGetFile} from '../axiosFile';

export const getFile = async (fileName) => {
    let url = '/file/' + fileName;

    let result = await handleRequestGetFile(url, null);
    try {
        return result;
    } catch (error) {
    }

    return false;
};

export const uploadFile = async (formData) => {
    let url = '/file';
    let result = await handleFormPost(url, formData);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};


