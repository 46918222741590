import {handleRequestGet, handleRequestPost} from '../axios';

export const getCountryList = async (size, page, status, term,isExclude = false) => {
    let url = '/country';

    let param = {
        size: size,
        page: page
    };
    if(term != "") param['term'] = term;
    if(isExclude) param['is_exclude'] = isExclude;

    let result = await handleRequestGet(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const addCountry = async (countryCode, countryName, currencyCode) => {
    let url = '/country/create';
    let result = await handleRequestPost(url, {
        code: countryCode,
        name: countryName,
        ccy: currencyCode,
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateCountry = async (countryId, countryCode, countryName, currencyCode) => {
    let url = '/country/update';
    // let currency = currencyCode == "" ?  0 : currencyCode;
    let result = await handleRequestPost(url, {
        id: countryId,
        code: countryCode,
        name: countryName,
        ccy: currencyCode,
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const deleteCountry = async (countryId) => {
    let url = '/country/delete';
    let result = await handleRequestPost(url, {
        id: countryId
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
