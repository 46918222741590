import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {handlePopup, useLocalStorage} from "../../../general/general-tools";
import {usePopupManager} from "react-popup-manager";


const Index = () => {
    const {setLoading} = useLoading();

    const [tableData, setTableData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPerPage, setCurrentPerPage] = useState(10);
    const [searchInput, setSearchInput] = useLocalStorage("mst_broker_searchInput", "");

    const [searchStatus, setSearchStatus] = useState("");
    const [searchEffFrom, setSearchEffFrom] = useState("");
    const [searchEffTo, setSearchEffTo] = useState("");
    const isInitialMount = useRef(true);

    const location = useLocation();
    let navigate = useNavigate();
    const popupManager = usePopupManager();


    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Error</h5>
                            <div className="dropdown no-arrow">

                            </div>

                        </div>
                        <div className="card-body">
                            <h4>You are not authorised to access this page. </h4>
                            <h5>Please contact tech-support if you are supposedly permitted to access this page. </h5>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
