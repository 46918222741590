import React, {useState, useEffect, useMemo} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';
import {useLoading} from '../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { usePopupManager } from "react-popup-manager";
import ModalConfirm from "../../public/ModalConfirm";
import {convertErMsg, disableAllEleInDiv, handlePopup, specialCharCheck} from "../../../general/general-tools";
import Multiselect from "multiselect-react-dropdown";


const Index = () => {
    const {setLoading} = useLoading();


    const [currentMode, setCurrentMode] = useState("");
    const [currentCountryCode, setCurrentCountryCode] = useState("");
    const [currentCountryName, setCurrentCountryName] = useState("");
    const [currentCurrencyCode, setCurrentCurrencyCode] = useState([]);
    const [currentCurrencyList, setCurrentCurrencyList] = useState([]);
    const [currentCountryId, setCurrentCountryId] = useState(9999);
    const [readOnlyCountryCode, setReadOnlyCountryCode] = useState(false);
    const [accessItem, setAccessItem] = useState({});

    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();


    //Load initial data and mode
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        if (location.state) {
            if (location.state.row) {
                setAccessItem(location.state.perm);
                if(!location.state.perm['edit']){
                    setTimeout(function() {
                        disableAllEleInDiv("mainDiv");

                    }, 50);
                }
                // setReadOnlyCountryCode(true);
                setCurrentCountryId(location.state.row.c_id);
                setCurrentCountryCode(location.state.row.c_code);
                setCurrentCountryName(location.state.row.c_name);


                let item = JSON.parse(location.state.row.currency) ?? [];
                let convertedCur = [];
                for (let i = 0; i <  item.length; i++) {
                    convertedCur.push({'id':item[i]['c_id'],'name':item[i]['c_code'] +" | "+item[i]['c_name']});
                }
                setCurrentCurrencyCode(convertedCur)
                handleCurrencyCode(item)

            }
            setCurrentMode(location.state.mode)

            if(location.state.mode.toLowerCase() =='add'){
                handleCurrencyCode()
            }
        }
    }, [location.state]);

    const handleCurrencyCode = async (existingItems = null) => {

        let result = await api.getCurrencyList('', 999, 1, 1);
        if (result != false && result.status == 0) {
            if (result.data.list) {
                let dataList = result.data.list;
                let list =convertRawCurrencyIntoDropdown(dataList,existingItems)
                setCurrentCurrencyList(list);
            }
        } else {
            return [];
            setLoading(false);
        }
    };


    const handleRemoveBtn = async (country_code) => {
        await setLoading(true);

        popupManager.open(ModalConfirm, {
            title: 'Delete',
            content: 'Are you sure you want to delete?',
            onClose: (...params) => handleRemoveItem(params[0])
        });


        setLoading(false);
    };

    const handleRemoveItem = async (selBoolean) => {
        if(!selBoolean) return;
        api.deleteCountry(currentCountryId).then((result: any) => {
            if (result != false && result.status == 0) {
                setLoading(false);
                handlePopup(true, "Success", "Country deleted successfully.", popupManager,true,"country",navigate)
            }else{
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
            }
        });


    };
    const handleSaveBtn = async () => {
        await setLoading(true);
        if (currentCountryCode == '') {
            handlePopup(true, "Error", "Mandatory Field - Country Code not entered.", popupManager)
            setLoading(false);
            return false;
        }
        if (
            currentCountryCode.trim().length > 1 && currentCountryCode.trim().length < 4
        ) {
            let cryStr = '';
            for (let i = 0; i < currentCurrencyCode.length ; i++) {
                if (i == 0) cryStr += currentCurrencyCode[i]['id'];
                else cryStr += "," + currentCurrencyCode[i]['id'];
            }

            api.updateCountry(currentCountryId, currentCountryCode, currentCountryName, cryStr).then((result: any) => {
                if (result != false && result.status == 0) {
                    handlePopup(true, "Success", "Country information updated successfully.", popupManager,true,"country",navigate)
                }else{
                    handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                    setLoading(false);
                }
            });
        } else  handlePopup(true,"Error","Country code require 3 characters.",popupManager)
        setLoading(false);
    };


    const handleResetBtn = async () => {
        await setLoading(true);
        setCurrentCountryCode("");
        setCurrentCountryName("");
        setCurrentCurrencyCode([]);
        setLoading(false);
    };

    const handleAddBtn = async () => {
        await setLoading(true);
        if (currentCountryCode == '') {
            handlePopup(true, "Error", "Mandatory Field - Country Code not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if (
            currentCountryCode.trim().length > 1 && currentCountryCode.trim().length < 4
        ) {
            let cryStr = '';
            for (let i = 0; i < currentCurrencyCode.length ; i++) {
                if (i == 0) cryStr += currentCurrencyCode[i]['id'];
                else cryStr += "," + currentCurrencyCode[i]['id'];
            }
            api.addCountry(currentCountryCode, currentCountryName, cryStr).then((result: any) => {
                if (result.status == 0) {
                    setLoading(false);
                    handlePopup(true, "Success", "Country created successfully.", popupManager,true,"country",navigate)
                }else{
                    handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                    setLoading(false);
                }
            });


        } else{
            setLoading(false);
            handlePopup(true,"Error","Country code require 3 characters.",popupManager)

        }


    };

    const handleBackBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "country",{state: {mode: 'back'}});
        setLoading(false);
    };


    const onCurrencyChange = (selectedList, selectedItem) => {
        setCurrentCurrencyCode(selectedList);
    }

    const convertRawCurrencyIntoDropdown = (data, existingItems) => {
        var returnDat = [];
        var setList = [];
        for (let i = 0; i < data.length; i++) {

            if (existingItems) {
                if (!existingItems.includes(data[i]['c_id'])) {
                    if (!setList.includes(data[i]['c_id'])) {
                        returnDat.push({
                            name: data[i]['c_code'] + " | "+data[i]['c_name'],
                            id: data[i]['c_id']
                        });
                        setList.push(data[i]['c_id']);
                    }
                    setList.push(data[i]['c_id']);
                }
            }else{
                if (!setList.includes(data[i]['c_id'])) {
                    returnDat.push({
                        name: data[i]['c_code'] + " | "+data[i]['c_name'],
                        id: data[i]['c_id']
                    });
                    setList.push(data[i]['c_id']);
                }
            }


        }
        return returnDat;
    }

    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Master File
                                Maintenance &gt; Country - {currentMode}</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Country Code</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            autoComplete='off'
                                            id="country_code"
                                            type="text"
                                            placeholder="e.g. MYS"
                                            aria-label=""
                                            value={currentCountryCode}
                                            onChange={(e) => setCurrentCountryCode(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentCountryCode,popupManager)}

                                        />
                                    </div>
                                    <b className='mandatory-red'>*</b>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Country Name</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            autoComplete='off'
                                            id="country_code"
                                            type="text"
                                            placeholder="e.g. Malaysia"
                                            aria-label=""
                                            value={currentCountryName}
                                            onChange={(e) => setCurrentCountryName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentCountryName,popupManager)}

                                        />

                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Currency Code</div>
                                    <div className=" col-md-10">
                                        <Multiselect
                                            options={currentCurrencyList}
                                            onSelect={onCurrencyChange}
                                            onRemove={onCurrencyChange}
                                            selectedValues={currentCurrencyCode}
                                            displayValue="name"
                                            placeholder="Search Currency"
                                            hidePlaceholder={true}
                                            showCheckbox={false}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />

                                    </div>

                                </div>


                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1"></div>

                                    {currentMode.toLowerCase() == 'edit' ?
                                        <div className="label col-md-11 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>
                                            {accessItem['delete'] ? <button className="btn btn-primary " onClick={() => handleRemoveBtn(currentCountryCode)}>Delete</button> :""}
                                            {accessItem['edit'] ? <button className="btn btn-primary " onClick={() => handleSaveBtn()}>Save</button> :""}

                                        </div> :

                                        <div className="label col-md-11 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>
                                            <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset</button>
                                            <button className="btn btn-primary " onClick={() => handleAddBtn()}>Save</button>
                                        </div>
                                    }



                                </div>


                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
