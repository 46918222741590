import React, {useState, useEffect, useMemo, useLayoutEffect} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';

import {numberWithCommas} from '../../../general/convertor';
import {confirmAlert} from "react-confirm-alert";
import api from "../../../../apis";
import ReactTable from "../../../general/form-control/react-table";
import Toggle from 'react-toggle'
import Multiselect from "multiselect-react-dropdown";
import {usePopupManager} from "react-popup-manager";
import {convertErMsg, disableAllEleInDiv, handlePopup} from "../../../general/general-tools";
import {updateDealerRemove} from "../../../../apis/models/Dealer";

const Index = () => {
    const {setLoading} = useLoading();


    const [currentMode, setCurrentMode] = useState("");
    const [currentDate, setCurrentDate] = useState(null);

    const [currentAccountId, setCurrentAccountId] = useState("");
    const [curentClientId, setCurentClientId] = useState("");
    const [curentClientStatus, setCurentClientStatus] = useState("");
    const [curentClientCDS, setCurentClientCDS] = useState("");
    const [curentClientBranch, setCurentClientBranch] = useState("");
    const [curentClientDlName, setCurentClientDlName] = useState("");
    const [curentClientDlCode, setCurentClientDlCode] = useState("");
    const [reasignClientDlName, setReasignClientDlName] = useState("");
    const [reasignToggle, setReasignToggle] = useState(false);
    const [reasignReadOnly, setReasignReadOnly] = useState(false);

    const [tableData, setTableData] = useState([]);
    const [dealerList, setDealerList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPerPage, setCurrentPerPage] = useState(10);
    const [accessItem, setAccessItem] = useState({});


    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();


    //Load initial data and mode
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        if (location.state) {
            if (location.state.row) {
                setAccessItem(location.state.perm);
                if (!location.state.perm['edit']) {
                    setTimeout(function () {
                        disableAllEleInDiv("mainDiv");
                    }, 50);
                }
                let status = location.state.row.a_status == 0 ? "Inactive" : location.state.row.a_status == 1 ? "Active" : location.state.row.a_status == 2 ? "Suspended" : location.state.row.a_status == 3 ? "Expired" : location.state.row.a_status == 4 ? "Dormant" : "Closed";
                setTableData(JSON.parse(location.state.row.accounts));
                setCurrentAccountId(location.state.row.p_fullname);
                setCurentClientId(location.state.row.a_id);
                setCurentClientCDS(location.state.row.p_cdsno);
                setCurentClientStatus(status);
                setCurentClientBranch(location.state.row.bb_code);
                setCurentClientDlName(location.state.row.d_fullname);
                setCurentClientDlCode(location.state.row.d_code);

                setReasignToggle(location.state.row.da_enable == 1 ? true : false);
                if (location.state.row.da_code) {
                    // setReasignToggle(true);
                    setReasignReadOnly(false);
                    if (location.state.row.da_code) {
                        let item = {
                            code: location.state.row['da_id'],
                            name: location.state.row['da_code'],
                            id: location.state.row['da_id']
                        }
                        setReasignClientDlName([item]);

                    }

                }
            }
            setCurrentMode(location.state.mode)
            handleDealerList();
        }
    }, [location.state]);

    const handleDealerList = async () => {
        setLoading(true);
        api.getDealerList(999, 1, "").then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdown(dataList);
                    setDealerList(TreeData);

                }
            }
        });
        setLoading(false);
    };

    const convertRawIntoDropdown = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            if (location.state.row.d_code) {
                if (location.state.row.d_code == data[i]['d_code']) continue;
            }
            if (location.state.row.da_code) {
                if (location.state.row.da_code == data[i]['d_code']) continue;
            }
            returnDat.push({
                code: data[i]['d_id'],
                name: data[i]['d_code'],
                id: data[i]['d_id']
            });
        }
        return returnDat;
    }

    const onSelect = (selectedList, selectedItem) => {
        if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur();
        handleReAsignId([selectedItem]);
    }
    const onRemove = (selectedList, selectedItem) => {
        handleReAsignId("");
    }

    // Edit
    const handleReassignDealer = (event) => {
        setReasignToggle(event.target.checked);
        setReasignReadOnly(!event.target.checked);
        // if(!event.target.checked)setReasignClientDlName("")
        // if (!reasignReadOnly) setReasignClientDlName("");
    };

    const handleBackBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "clientdealer", {state: {mode: 'back'}});
        setLoading(false);
    };

    const handleSaveBtn = async () => {
        await setLoading(true);

        if (reasignToggle && reasignClientDlName[0] == null) {
            handlePopup(true, "Error", "Mandatory Field - Dealer not selected.", popupManager)
            setLoading(false);
            return false;
        }

        let param = {
            "account": isNaN(parseInt(curentClientId)) ? 0 : parseInt(curentClientId),
            "dealer": reasignClientDlName[0] != null ? reasignClientDlName[0].code  : null,
            "enable": reasignToggle ? 1 : 0,
            // "date_fr":"",
            // "date_to":""
        };
        api.updateDealerAssign(param).then((result: any) => {
            if (result != false && result.status == 0) {
                handlePopup(true, "Success", "Client-Dealer information updated successfully", popupManager,true,'clientdealer',navigate)
                // setCurentClientDlCode(reasignClientDlName[0].name ?? "");
            }else{
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
            }
            setLoading(false);
        });


    };


    // Add
    const handleResetBtn = async () => {
        var todayDate = getTodayDate();
        await setLoading(true);

        setReasignToggle(false);
        setReasignReadOnly(true);
        setReasignClientDlName("");

        if (currentDate) setCurrentDate(todayDate);
        setLoading(false);
    };

    const handleReAsignId = async (data) => {
        if (reasignReadOnly) return;
        else setReasignClientDlName(data);
    };


    const getTodayDate = () => {
        return new Date().toLocaleDateString('en-CA', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }

    const columns = useMemo(
        () => [
            {
                id: row => row.a_acctno,
                name: "Account No",
                selector: row => row.a_acctno,
                sortable: true,

            },
            {
                name: 'Account Type',
                selector: row => row.a_type == 0 ? "Cash" : row.a_type == 1 ? "Margin" : row.a_type == 2 ? "Cash Upfront" : row.a_type == 3 ? "Collateralized" : "-",
                sortable: true
            },
            {
                name: 'Account Status',
                selector: row => row.a_status == 0? "Inactive" : row.a_status == 1? "Active" : row.a_status == 2? "Suspended" : row.a_status == 3 ? "Expired": row.a_status == 4? "Dormant" : row.a_status == 5 ? "Closed" : "-",
                sortable: true
            }
        ],
        []
    );


    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Master File
                                Maintenance &gt; Client Dealer - {currentMode}</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1">Client Name</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="underline-input"

                                            type="text"
                                            placeholder="e.g. John Doe"
                                            aria-label="Search Input"
                                            readOnly={true}
                                            value={currentAccountId}
                                        />
                                    </div>
                                    <div className="label col-md-1">Status</div>
                                    <div className="label col-md-5">

                                        <input
                                            className="underline-input"

                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={true}
                                            value={curentClientStatus}
                                        />

                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1">CDS No.</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="underline-input"

                                            type="text"
                                            placeholder="e.g. 000762712"
                                            readOnly={true}
                                            aria-label="Search Input"
                                            value={curentClientCDS}
                                            onChange={(e) => setCurentClientCDS(e.target.value)}
                                        />
                                    </div>
                                    <div className="label col-md-1">Branch</div>
                                    <div className="label col-md-5">

                                        <input
                                            className="underline-input"

                                            type="text"
                                            placeholder="e.g. 012"
                                            readOnly={true}
                                            aria-label=""
                                            value={curentClientBranch}
                                            onChange={(e) => setCurentClientBranch(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1">Dealer Name</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="underline-input"

                                            type="text"
                                            placeholder="e.g. John Doe"
                                            readOnly={true}
                                            aria-label="Search Input"
                                            value={curentClientDlName}
                                            onChange={(e) => setCurentClientDlName(e.target.value)}
                                        />
                                    </div>
                                    <div className="label col-md-1">Dealer Code</div>
                                    <div className="label col-md-5">

                                        <input
                                            className="underline-input"

                                            type="text"
                                            placeholder="e.g. 012"
                                            aria-label=""
                                            readOnly={true}
                                            value={curentClientDlCode}
                                            onChange={(e) => setCurentClientDlCode(e.target.value)}
                                        />
                                    </div>
                                </div>

                                {/*Table Portion*/}
                                <hr/>
                                <div className="col-12 search-container">
                                    <ReactTable
                                        rowClick={setCurrentPage}
                                        perPageQty={setCurrentPerPage}
                                        columns={columns}
                                        data={tableData}
                                        pagination={{
                                            currentPage: currentPage,
                                            currentPerPage: currentPerPage,
                                            totalRecords: totalRecords,
                                        }}
                                    />

                                </div>


                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Dealer Re-assignment</div>
                                    <div className="label col-md-3">
                                        <Toggle
                                            defaultChecked={reasignToggle}
                                            checked={reasignToggle}
                                            onClick={handleReassignDealer}/>
                                    </div>
                                    <div className="label col-md-3">Re-assign Dealer Code</div>
                                    <div className=" col-md-4">
                                        <Multiselect
                                            options={dealerList}
                                            onSelect={onSelect}
                                            onRemove={onRemove}
                                            selectedValues={reasignClientDlName}
                                            displayValue="name"
                                            // singleSelect={true}
                                            placeholder="Search Dealer"
                                            // selectionLimit={1}
                                            hidePlaceholder={true}
                                            showCheckbox={false}
                                            disable={reasignReadOnly}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />


                                    </div>
                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1"></div>

                                    {accessItem['edit'] ? <></> : ""}


                                    {currentMode.toLowerCase() == 'edit' ?
                                        <div className="label col-md-11 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                            </button>

                                            {accessItem['edit'] ? <>
                                                <button className="btn btn-primary "
                                                        onClick={() => handleResetBtn()}>Reset
                                                </button>
                                                <button className="btn btn-primary "
                                                        onClick={() => handleSaveBtn()}>Save
                                                </button>
                                            </> : ""}

                                        </div> :

                                        <div className="label col-md-11 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                            </button>
                                            <button className="btn btn-primary "
                                                    onClick={() => handleResetBtn()}>Reset
                                            </button>
                                        </div>
                                    }


                                </div>


                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
