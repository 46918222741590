import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react';
import '../../../../asset/styles/customer-profile-style.css';
import api from '../../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    convertPositiveNumberHundred, cursorToFront, handleIntegerChangeWithCursor,
    handlePositiveNumberChange,
    handlePositiveNumberHundredChange,
    handleSelectChange
} from "../../../../general/general-tools";



const BidLimit = React.memo(props => {
    let bLBuyBL = props['bLBuyBL'] == null ? '' : props['bLBuyBL'];
    let setBLBuyBL = props['setBLBuyBL'] == null ? '' : props['setBLBuyBL'];
    let bLSellBL = props['bLSellBL'] == null ? '' : props['bLSellBL'];
    let setBLSellBL = props['setBLSellBL'] == null ? '' : props['setBLSellBL'];
    let bLBuyBLRadio = props['bLBuyBLRadio'] == null ? '' : props['bLBuyBLRadio'];
    let setBLBuyBLBLRadio = props['setBLBuyBLBLRadio'] == null ? '' : props['setBLBuyBLBLRadio'];

    let bLBuyOL = props['bLBuyOL'] == null ? '' : props['bLBuyOL'];
    let setBLBuyOL = props['setBLBuyOL'] == null ? '' : props['setBLBuyOL'];
    let bLSellOL = props['bLSellOL'] == null ? '' : props['bLSellOL'];
    let setBLSellOL = props['setBLSellOL'] == null ? '' : props['setBLSellOL'];

    let bLBuyBOL = props['bLBuyBOL'] == null ? '' : props['bLBuyBOL'];
    let setBLBuyBOL = props['setBLBuyBOL'] == null ? '' : props['setBLBuyBOL'];
    let bLSellBOL = props['bLSellBOL'] == null ? '' : props['bLSellBOL'];
    let setBLSellBOL = props['bLSellBOL'] == null ? '' : props['setBLSellBOL'];

    let bLBuyBLReadOnly = props['bLBuyBLReadOnly'] == null ? '' : props['bLBuyBLReadOnly'];
    let bLSellBLReadOnly = props['bLSellBLReadOnly'] == null ? '' : props['bLSellBLReadOnly'];
    let bLSellBOLReadOnly = props['bLSellBOLReadOnly'] == null ? '' : props['bLSellBOLReadOnly'];
    let popupManager = props['popupManager']== null ? '' : props['popupManager'];


    return  <>

        <div className="input-container col-md-12 fullWidthSearch exp-input-parent-single">
            <b>Bid Limit</b>
        </div>

        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-2"></div>
            <div className="label col-md-3">Board Lot</div>
            <div className="label col-md-3">Odd Lot</div>
            <div className="label col-md-3">Board-odd Lot</div>
        </div>
        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-2">Buy</div>
            <div className="label col-md-3">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={bLBuyBLReadOnly}
                    value={bLBuyBL}
                    max={999999999}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager,e, setBLBuyBL)}
                />
            </div>
            <div className="label col-md-3">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={bLSellBLReadOnly}
                    value={bLBuyOL}
                    max={999999999}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager,e, setBLBuyOL)}

                />
            </div>
            <div className="label col-md-3">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={bLSellBOLReadOnly}
                    value={bLBuyBOL}
                    max={999999999}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager,e, setBLBuyBOL)}
                />
            </div>
        </div>
        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-2">Sell</div>
            <div className="label col-md-3">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={bLBuyBLReadOnly}
                    value={bLSellBL}
                    max={999999999}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager,e, setBLSellBL)}

                />
            </div>
            <div className="label col-md-3">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={bLSellBLReadOnly}
                    value={bLSellOL}
                    max={999999999}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager,e, setBLSellOL)}

                />
            </div>
            <div className="label col-md-3">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={bLSellBOLReadOnly}
                    value={bLSellBOL}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleIntegerChangeWithCursor(popupManager,e, setBLSellBOL)}

                />
            </div>
        </div>
        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-2">Control</div>
            <div className="label col-md-4">
                <input className="checkboxMarginRight" name="bid_limit_control" type="radio"
                       defaultChecked={bLBuyBLRadio == 'control_block' ? true : false}
                       checked={bLBuyBLRadio == 'control_block' ? true : false}
                       id='bid_limit_control_block' disabled={bLBuyBLReadOnly}
                       onChange={e => handleSelectChange('control_block', setBLBuyBLBLRadio, bLBuyBLReadOnly)}
                />
                <label className='checkboxMarginRight' htmlFor="bid_limit_control_block">

                    Block
                </label>


                <label className="checkboxMarginLeft" htmlFor="bid_limit_control_warning">
                    <input className="checkboxMarginLeft" name="bid_limit_control" type="radio"
                        defaultChecked={bLBuyBLRadio == 'control_warning' ? true : false}
                           checked={bLBuyBLRadio == 'control_warning' ? true : false}
                        id='bid_limit_control_warning' disabled={bLBuyBLReadOnly}
                        onChange={e => handleSelectChange('control_warning', setBLBuyBLBLRadio, bLBuyBLReadOnly)}

                    />
                    Warning
                </label>


            </div>
        </div>
    </>
});
export default BidLimit;
