import React, {useState, useEffect, useMemo, useLayoutEffect} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';
// import 'react-quill/dist/quill.snow.css';
import {convertToHTML, convertFromHTML} from 'draft-convert';
import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import {
    handleSelectChange,
    timeElement,
    holidayTypeOptions,
    userTypeCategory,
    researchCategory, handlePopup, getMimeTypes, disableAllEleInDiv, convertErMsg, specialCharCheck
} from "../../../general/general-tools";
import {confirmAlert} from "react-confirm-alert";
import {Editor} from 'react-draft-wysiwyg';
import {EditorState, ContentState, convertFromRaw} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ModalConfirm from "../../public/ModalConfirm";
import {usePopupManager} from "react-popup-manager";
import {stateToHTML} from 'draft-js-export-html';
import {broadcastApproval, createBroadcast} from "../../../../apis/models/Broadcast";
import {stateFromHTML} from "draft-js-import-html";
import ReactDatepick from "../../../general/form-control/react-datepick";


const Index = () => {
    const {setLoading} = useLoading();

    const [currentMode, setCurrentMode] = useState("");
    const [exchangeList, setExchangeList] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const [brId, setBrId] = useState("");
    const [brTitle, setBrTitle] = useState("");
    const [brSource, setBrSource] = useState("");
    const [brCreateDate, setBrCreateDate] = useState("");
    const [brCreator, setBrCreator] = useState("");
    const [brLstModifyDate, setBrLstModifyDate] = useState("");
    const [brEditor, setBrEditor] = useState("");
    const [brType, setBrType] = useState(1);
    const [brPublishStart, setBrPublishStart] = useState("");
    const [brPublishDate, setBrPublishDate] = useState("");
    const [brDocumentId, setBrDocumentId] = useState("");
    const [brPublishEnd, setBrPublishEnd] = useState("");

    const [brExchange, setBrExchange] = useState([]);
    const [brApprovalDate, setBrApprovalDate] = useState("");
    const [brSymbol, setBrSymbol] = useState([]);
    const [brSymbolList, setBrSymbolList] = useState([]);
    const [brApprover, setBrApprover] = useState("");
    const [brStatus, setBrStatus] = useState("");
    const [brUserType, setBrUserType] = useState([]);
    const [brUserTypeList, setBrUserTypeList] = useState([]);

    const [brVersion, setBrVersion] = useState("");
    const [brDesc, setBrDesc] = useState("");

    const [researchCat, setResearchCat] = useState("");
    const [researchCatList, setResearchCatList] = useState([]);
    const [anncType, setAnncType] = useState(2);
    const [fileContentPath, setFileContentPath] = useState("");
    const [fileThumbPath, setFileThumbPath] = useState("");
    const [fileContentName, setFileContentName] = useState("");
    const [fileThumbName, setFileThumbName] = useState("");
    const [exchangeSelAllLbl, setExchangeSelAllLbl] = useState("Exchange");
    const [hideExcItems, setHideExcItems] = useState(false);

    const [imgSrc, setImgSrc] = useState<any>('');
    const [mimeType, setMimeType] = useState<any>('');

    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();
    let fileSizeLbl = process.env.REACT_APP_FILE_LIMIT_LBL;
    let fileSize = process.env.REACT_APP_FILE_LIMIT_SIZE;
    const [accessItem, setAccessItem] = useState({});


    useLayoutEffect(() => {
        window.scrollTo({top: -10, behavior: 'smooth'});
        handleExchangeList();
        handleUserTypeList();
        handleResearchCategory();

        if (location.state) {
            if (location.state.row) {
                handleInitData(location.state.row.b_id, location.state.row.b_type, location.state.mode);
            }
            setAccessItem(location.state.perm);
            if (!location.state.perm['edit']) {
                setTimeout(function () {
                    disableAllEleInDiv("mainDiv");
                }, 50);
            }


            setCurrentMode(location.state.mode)
            if (location.state.mode.toLowerCase() == 'edit') {
                let cls_edit = document.getElementsByClassName("cls_edit");
                for (var i = 0; i < cls_edit.length; i++) {
                    cls_edit[i].classList.remove('divHide');
                }

            }
        }

    }, []);


    const handleInitData = async (id, broadcast_type, curMode) => {

        setLoading(true);
        let param = {
            id: id,
            broadcast_type: broadcast_type
        }
        api.getBroadcastDetail(param).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            setLoading(false);
            if (result.data) {
                if (result.data.list.length > 0) {
                    let dataList = result.data.list[0];
                    let navType = '';
                    if (dataList['b_type'] == 1) navType = 'btn_research'
                    if (dataList['b_type'] == 2 || dataList['b_type'] == 3) navType = 'btn_announcement'
                    if (dataList['b_type'] == 4) navType = 'btn_tnc'
                    let nav_btn = document.getElementById(navType);
                    nav_btn.classList.remove('divHide');
                    nav_btn.classList.add('inFormTopNavBtnSelected');
                    nav_btn.click();

                    setBrId(dataList['b_id']);
                    setBrTitle(dataList['title']);
                    setBrSource(dataList['br_source']);
                    setBrCreateDate(dataList['b_create_date'] ?? "-");
                    setBrCreator(dataList['b_create_by'] ?? "-");
                    setBrEditor(dataList['b_modify_by'] ?? "-");
                    setBrLstModifyDate(dataList['b_modify_date'] == "Invalid date" ? "-" : dataList['b_modify_date']);
                    setBrPublishStart(dataList['b_publish_date_start'] == "Invalid date" ? "-" : dataList['b_publish_date_start']);
                    setBrPublishDate(dataList['b_publish_date_start'] == "Invalid date" ? "-" : dataList['b_publish_date_start']);
                    setBrDocumentId(dataList['b_doc_id']);
                    setBrPublishEnd(dataList['b_publish_date_end'] == "Invalid date" ? "-" : dataList['b_publish_date_end']);
                    setBrApprovalDate(dataList['b_approved_date'] == "Invalid date" ? "-" : dataList['b_approved_date']);
                    setBrApprover(dataList['b_approved_by']);
                    setBrStatus(dataList['b_status'] == 0 ? "Pending Approval" : dataList['b_status'] == 1 ? "Approved" : dataList['b_status'] == 2 ? "Rejected" : dataList['b_status'] == 3 ? "Published" : dataList['b_status'] == 4 ? "Rejected" : "-");
                    setResearchCat(dataList['br_research_category']);
                    setBrType(dataList['b_type']);
                    setAnncType(dataList['b_type']);
                    setBrVersion(dataList['bt_version']);
                    setBrDesc(dataList['tnc_desc']);


                    if (dataList['file_content_path']) {
                        let file = dataList['file_content_path'];
                        let types = getMimeTypes();
                        let mimeType = types[file.split('.').pop()] || null;

                        api.getFile(file || '').then((result: any) => {
                            if(result?.status == 1 || result === undefined){
                                handlePopup(true,"Error",convertErMsg("Error occur during retrieving files."),popupManager)
                                setLoading(false);
                                return false;
                            }

                            let linkSource =
                                'data:' +
                                mimeType +
                                ';base64,' +
                                btoa(
                                    new Uint8Array(result).reduce(
                                        (data, byte) => data + String.fromCharCode(byte),
                                        ''
                                    )
                                );
                            setMimeType(mimeType);
                            setImgSrc(linkSource);


                        });
                    }

                    if (dataList['file_content_path']) {
                        let fileItem = dataList['file_content_path'];
                        setFileContentPath("/file/" + fileItem);
                    }

                    if (dataList['thumbnail_path']) {
                        let fileItem = dataList['thumbnail_path'];
                        setFileThumbPath("/file/" + fileItem);
                    }

                    if (dataList['file_content_name']) {
                        let fileItem = dataList['file_content_name'];
                        setFileContentName(fileItem)

                    } else {
                        let fileItem = dataList['file_content_path'];
                        let name = fileItem.split("/");
                        setFileContentName(name[name.length - 1])

                    }

                    if (dataList['thumbnail_name']) {
                        let fileItem = dataList['thumbnail_name'];
                        setFileThumbName(fileItem)

                    } else {
                        let fileItem = dataList['thumbnail_path'];
                        let name = fileItem.split("/");
                        setFileThumbName(name[name.length - 1])

                    }


                    if (dataList['exchanges']) {
                        let exchangeList = JSON.parse(dataList['exchanges']);
                        handleExchangeList(exchangeList)

                        // let exchange = [];
                        //
                        // for (let j = 0; j < exchangeList.length; j++) {
                        //     let currentEx = {};
                        //     currentEx['id'] = exchangeList[j]['em_id'];
                        //     currentEx['name'] = exchangeList[j]['em_code'];
                        //     exchange.push(currentEx);
                        // }
                        // setBrExchange(exchange);
                    }

                    if (dataList['tickers']) {
                        let tickerList = JSON.parse(dataList['tickers']);
                        let ticker = [];

                        for (let j = 0; j < tickerList.length; j++) {
                            let currentTic = {};
                            currentTic['id'] = tickerList[j]['t_id'];
                            currentTic['exchange'] = tickerList[j]['em_code'];
                            currentTic['symbol'] = tickerList[j]['t_code'];
                            currentTic['stockname'] = tickerList[j]['t_name'];
                            ticker.push(currentTic);
                        }
                        setBrSymbol(ticker);
                    }


                    if (dataList['usertypes']) {
                        let userTypeList = JSON.parse(dataList['usertypes']);
                        handleUserTypeList(userTypeList);

                    }
                }
            }

        });
        //  setLoading(false);
    };

    const handleResearchCategory = async () => {
        //  setLoading(true);
        api.getResearchCat().then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if (result.data) {
                if (result.data.list.length > 0) {
                    setResearchCatList(result.data.list)
                }
            }

        });
        //  setLoading(false);
    };

    const handleExchangeList = async (currentList = null) => {

        //setLoading(true);
        api.getExchangeMainList(999, 1, 1, null).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

            if (result.data) {
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdown(dataList);
                    setExchangeList(TreeData);

                    if (exchangeList != null) {
                        let exchanges = [];

                        if (currentList?.length == TreeData?.length) {
                            exchanges.push({
                                name: "Select All",
                                id: 0
                            });
                            setExchangeSelAllLbl("[All Exchange]")
                            setHideExcItems(true);
                        }
                        for (let j = 0; j < currentList?.length; j++) {
                            let currentUt = {};
                            currentUt['name'] = currentList[j]['em_code'];
                            currentUt['id'] = currentList[j]['em_id'];
                            exchanges.push(currentUt);
                        }

                        setBrExchange(exchanges);
                    }

                }
            }
        });
        //setLoading(false);
    };

    const handleTickerList = async (searchTerm, searchSymbols) => {
        let searchTermStr = "";
        for (let i = 0; i < searchTerm.length; i++) {
            searchTermStr += searchTerm[i]['id'] + ","
        }
        searchTermStr = searchTermStr.substring(0, searchTermStr.length - 1)
        //  setLoading(true);
        api.getBroadcastTicker(999, 1, null, searchSymbols == "" ? null : searchSymbols, searchTermStr).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

            if (result.status == 0 && result.data) {
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdownTicker(dataList);
                    setBrSymbolList(TreeData);
                }


            }
        });
        //  setLoading(false);
    };

    const handleUserTypeList = async (currentList = null) => {
        //setLoading(true);
        let param = {
            size: 999,
            page: 1,
            is_opr: false
        };
        api.getAuthLimitListUserType(param).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

            setLoading(false);

            if (result.data) {
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdownUt(dataList);
                    setBrUserTypeList(TreeData);

                    if (currentList != null) {
                        let userType = [];
                        if (currentList?.length == TreeData?.length - 1) {
                            userType.push({
                                name: "Select All",
                                id: 0
                            });
                            setUserTypeSelAllLbl("[All User Types]")
                            setHideUTItems(true);
                        }
                        for (let j = 0; j < currentList?.length; j++) {
                            let currentUt = {};
                            currentUt['name'] = currentList[j]['ut_name'];
                            currentUt['id'] = currentList[j]['ut_id'];
                            userType.push(currentUt);
                        }
                        setBrUserType(userType);
                    }


                }
            }
        });
    };

    const onExchangeHandle = (selectedList, selectedItem) => {
        setBrExchange(selectedList);
        const checkUsername = obj => obj.id == 0;
        if (selectedItem.id == 0) {
            handleTickerList(exchangeList, "");
            setBrExchange(exchangeList);
            if (!selectedList.some(checkUsername)) {
                setBrExchange([]);
                setExchangeSelAllLbl("Exchange")
                setHideExcItems(false);
            } else setExchangeSelAllLbl("[All Exchange]")
            setHideExcItems(true);
        } else {
            handleTickerList(selectedList, "");

            setExchangeSelAllLbl("Exchange")
            setHideExcItems(false);

            setBrExchange([]);
            let tempArr = [];
            for (let i = 0; i < selectedList.length; i++) {
                if (selectedList[i]['id'] == 0) continue;
                tempArr.push({name: selectedList[i]['name'], id: selectedList[i]['id']});
            }
            setBrExchange(tempArr);
        }


    }

    const onSymbolHandle = (selectedList, selectedItem) => {
        setBrSymbol(selectedList);
    }

    const onUserTypeHandle = (selectedList, selectedItem) => {
        setBrUserType(selectedList);
    }

    const onEditorChangeHandle = async (editorState) => {
        setEditorState(editorState)
    }


    const uploadCallback = async (file) => {
        return new Promise(
            (resolve, reject) => {
                if (file) {
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        resolve({data: {link: e.target.result}})
                    };
                    reader.readAsDataURL(file);
                }
            }
        );
    }


    const convertRawIntoDropdown = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                name: data[i]['em_code'],
                id: data[i]['em_id']
            });
        }
        return returnDat;
    }

    const convertRawIntoDropdownUt = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                name: data[i]['ut_name'],
                id: data[i]['ut_id']
            });
        }
        return returnDat;
    }


    const convertRawIntoDropdownTicker = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                id: data[i]['t_id'],
                exchange: data[i]['em_code'],
                symbol: data[i]['t_code'],
                stockname: data[i]['t_name']
            });
        }
        return returnDat;
    }


    const handleBackBtn = async () => {
        //  await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "br_app", {state: {mode: 'back'}});
        //  setLoading(false);
    };


    const handleResetBtn = async () => {
        //  await setLoading(true);
        location.reaload();
        //  setLoading(false);
    };


    const handleTopBtnClicked = (portion) => {
        let nav_btn = document.getElementsByClassName("nav_btn");
        for (var i = 0; i < nav_btn.length; i++) {
            nav_btn[i].classList.remove('inFormTopNavBtnSelected');
            nav_btn[i].classList.add('inFormTopNavBtn');
        }

        switch (portion) {
            case "research" :
                setBrType(1)
                break;
            case "announcement" :
                setBrType(2)
                break;
            case "tnc" :
                setBrType(4)
                break;
        }

        document.getElementById("btn_" + portion).classList.remove('inFormTopNavBtn');
        document.getElementById("btn_" + portion).classList.add('inFormTopNavBtnSelected');
        document.getElementById("div_research").classList.add('divHide');
        document.getElementById("div_announcement").classList.add('divHide');
        document.getElementById("div_tnc").classList.add('divHide');
        document.getElementById("div_" + portion).classList.remove('divHide');

    }


    const handleRemove = async () => {
        //  await setLoading(true);
        popupManager.open(ModalConfirm, {
            title: 'Delete',
            content: 'Are you sure you want to delete selected broadcast?',
            onClose: (...params) => handleRemoveItem(params[0])
        });

        //  setLoading(false);
    };
    const handleRemoveItem = async (selBoolean) => {
        if (!selBoolean) return;
        //  await setLoading(true);

        let param = {id: parseInt(brId)};
        api.deleteBroadcast(param).then((result: any) => {
            if (result != false && result.status == 0) {
                setLoading(false);
                handlePopup(true, "Success", "Broadcast deleted successfully.", popupManager, true, "br_app", navigate)

            } else {
                setLoading(false);
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
            }
        });


    };

    const onHandleSymbolSearch = (searchItem) => {
        if (searchItem.length > 2) handleTickerList(brExchange, searchItem);
        else if (searchItem.length == 0) handleTickerList(brExchange, "");
    }

    const handleApprove = async () => {
        //  await setLoading(true);

        confirmAlert({
            title: "Broadcast Approval",
            message: <div id="area_remark">
                <hr/>
                <div>
                    Approve the broadcasting entry?
                </div>

            </div>,
            buttons: [{label: 'Yes', onClick: () => handleNothing(1)}, {
                label: 'Close',
            }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });


        //  setLoading(false);
    };

    const handleDecline = async () => {
        //  await setLoading(true);
        confirmAlert({
            title: " Broadcast Approval",
            message: <div id="area_remark">
                <hr/>
                <div>
                    Decline the broadcasting entry?
                </div>

            </div>,
            buttons: [{label: 'Yes', onClick: () => handleNothing(2)}, {
                label: 'Close',
            }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
        //  setLoading(false);
    };

    const handleNothing = async (approval) => {
        //  await setLoading(true);
        let param = {
            id: brId,
            approval: approval
        }
        api.broadcastApproval(param).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

            if (result != false && result.status == 0) {
                setLoading(false);
                handlePopup(true, "Success", approval == 1 ? "Broadcast approved successfully." : "Broadcast rejected successfully.", popupManager, true, "br_app", navigate)

            } else handlePopup(true, "Error", convertErMsg(result.message), popupManager)
        });

        //  setLoading(false);
    };


    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div
                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration  &gt; Broadcast
                                Maintenance &gt; Approve</h5>
                            <div className="dropdown no-arrow">

                            </div>

                        </div>


                    </div>

                </div>

                <div className="col-xl-12 col-lg-12">
                    <div className="">
                        <button id='btn_research' className="nav_btn inFormTopNavBtnSelected divHide"
                                onClick={() => handleTopBtnClicked('research')}>Research
                        </button>
                        <button id='btn_announcement' className="nav_btn  inFormTopNavBtn divHide"
                                onClick={() => handleTopBtnClicked('announcement')}>Announcement / Event
                        </button>
                        <button id='btn_tnc' className="nav_btn  inFormTopNavBtn divHide"
                                onClick={() => handleTopBtnClicked('tnc')}>T&C
                        </button>
                    </div>
                    <div className="card shadow mb-4">


                        <div className="card-body">

                            {/*ResearchView*/}
                            <div id="div_research" className="col-12 search-container  ">
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Title</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="form-control"
                                            type="text"
                                            readOnly={true}
                                            placeholder="e.g. On The Spot Event"
                                            aria-label=""
                                            value={brTitle}
                                            onChange={(e) => setBrTitle(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setBrTitle,popupManager)}

                                        />
                                    </div>
                                    <b className='mandatory-red'>*</b>

                                </div>
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Research Category</div>
                                    <div className="label col-md-5">
                                        <select
                                            className="form-control selectItem"
                                            aria-label=""
                                            value={researchCat}
                                            disabled={true}
                                            onChange={e => handleSelectChange(e.target.value, setResearchCat, false)}
                                        >
                                            <option>Please select research category</option>
                                            {researchCatList.map(el => <option
                                                value={el.rc_id}>{el.rc_name}</option>)}
                                        </select>

                                    </div>

                                    <div className="label col-md-1 cls_research">
                                        <b className='mandatory-red-inside'>*</b>
                                        Source
                                    </div>
                                    <div className="col-md-5 cls_research">
                                        <input
                                            className="form-control"
                                            readOnly={true}
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={brSource}
                                            onChange={(e) => setBrSource(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setBrSource,popupManager)}

                                        />

                                    </div>

                                </div>
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Exchange</div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={exchangeList} // Options to display in the dropdown
                                            selectedValues={brExchange} // Preselected value to persist in dropdown
                                            onSelect={onExchangeHandle} // Function will trigger on select event
                                            onRemove={onExchangeHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            disable={true}
                                            placeholder={exchangeSelAllLbl}
                                            hideSelectedList={hideExcItems}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                    <div id='' className="label col-md-1 ">
                                        <b className='mandatory-red-inside'>*</b>
                                        Publish Date
                                    </div>
                                    <div id='' className="label col-md-5 ">

                                        <ReactDatepick
                                            disabled={true}
                                            value={brPublishDate}
                                            dateSetter={setBrPublishDate}
                                        />
                                    </div>

                                </div>
                                <div className="input-container fullWidthSearch ">
                                    <div className="label col-md-1  ">Symbol</div>
                                    <div className=" col-md-5 ">
                                        <Multiselect
                                            options={brSymbolList} // Options to display in the dropdown
                                            selectedValues={brSymbol} // Preselected value to persist in dropdown
                                            selectedValues={brSymbol}
                                            onSelect={onSymbolHandle} // Function will trigger on select event
                                            onRemove={onSymbolHandle} // Function will trigger on remove event
                                            displayValue="stockname" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            disable={true}
                                            onSearch={onHandleSymbolSearch}
                                            placeholder="Symbol"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="label col-md-1  ">
                                        Publish End
                                    </div>
                                    <div className="label col-md-5  ">

                                        <ReactDatepick
                                            disabled={true}
                                            value={brPublishEnd}
                                            dateSetter={setBrPublishEnd}
                                        />
                                    </div>
                                </div>
                                <div className="input-container fullWidthSearch ">
                                    <div className="label col-md-1">
                                        <b className='mandatory-red-inside'>*</b>
                                        User Type
                                    </div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={brUserTypeList} // Options to display in the dropdown
                                            selectedValues={brUserType} // Preselected value to persist in dropdown
                                            // selectedValues={holidayExchange}
                                            onSelect={onUserTypeHandle} // Function will trigger on select event
                                            onRemove={onUserTypeHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            disable={true}
                                            placeholder="User Type"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="input-container cls_edit divHide fullWidthSearch ">
                                    <div className="label col-md-1 ">Created By</div>
                                    <div className="label col-md-5 ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            readOnly={true}
                                            value={brCreator}
                                            onChange={(e) => setBrCreator(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setBrCreator,popupManager)}

                                        />
                                    </div>

                                    <div className="label col-md-1   ">Created Date</div>
                                    <div className="label col-md-5   ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brCreateDate}
                                            onChange={(e) => setBrCreateDate(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setBrCreateDate,popupManager)}

                                        />
                                    </div>
                                </div>
                                <div className="input-container cls_edit divHide fullWidthSearch ">
                                    <div className="label col-md-1 ">Last Modified</div>
                                    <div className="label col-md-5 ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brEditor}
                                            onChange={(e) => setBrEditor(e.target.value)}

                                        />
                                    </div>

                                    <div className="label col-md-1   ">Last Modified Date</div>
                                    <div className="label col-md-5   ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brLstModifyDate}
                                            onChange={(e) => setBrLstModifyDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="input-container cls_edit divHide  fullWidthSearch ">
                                    <div className="label col-md-1 ">Status</div>
                                    <div className="label col-md-5 ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brStatus}
                                            onChange={(e) => setBrStatus(e.target.value)}
                                        />
                                    </div>


                                </div>

                                <hr/>
                                {/*File upload section*/}
                                <div className="label col-md-3"><b>File Upload</b></div>
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-2">Content File</div>
                                    <div className="label col-md-5">
                                        <a target="_blank"
                                           href={fileContentPath}>{fileContentName == "" ? "" : "[View] " + fileContentName} </a>
                                    </div>
                                    <b id='mandatory-red-content-file' className='mandatory-red '>*</b>


                                </div>

                                <div id="div_thumbnail"
                                     className="cls_announceEvent cls_research input-container fullWidthSearch">
                                    <div className="label col-md-2">Thumbnail Image</div>
                                    <div className="label col-md-5">
                                        <a target="_blank"
                                           href={fileThumbPath}>{fileThumbName == "" ? "" : "[View] " + fileThumbName} </a>
                                    </div>

                                </div>
                                <hr/>
                            </div>

                            {/*Announcement and Event View*/}
                            <div id="div_announcement" className="col-12 search-container  divHide">
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Title</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="form-control"
                                            type="text"
                                            readOnly={true}
                                            placeholder="e.g. On The Spot Event"
                                            aria-label=""
                                            value={brTitle}
                                            onChange={(e) => setBrTitle(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setBrTitle,popupManager)}

                                        />
                                    </div>
                                    <b className='mandatory-red'>*</b>
                                </div>

                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Type</div>
                                    <div className=" col-md-5">
                                        <label htmlFor="announcement">

                                            <input name="announcement_event" type="radio" id="announcement"
                                                   value="2"
                                                   readOnly={true}
                                                   checked={anncType == '2'}
                                                   onChange={e => handleSelectChange(e.target.value, setAnncType, true)}
                                            />
                                            Announcement
                                        </label>

                                        <label className="checkboxMarginLeft" htmlFor="event">
                                            <input name="announcement_event" type="radio" id="event"
                                                   value="3"
                                                   readOnly={true}
                                                   checked={anncType == '3'}
                                                   onChange={e => handleSelectChange(e.target.value, setAnncType, true)}
                                            />
                                            Event
                                        </label>
                                    </div>
                                    <b className='mandatory-red'>*</b>

                                    <div className="label col-md-1  ">
                                        <b className='mandatory-red-inside'>*</b>
                                        Publish Start
                                    </div>
                                    <div className="label col-md-5  ">

                                        <ReactDatepick
                                            disabled={true}
                                            value={brPublishStart}
                                            dateSetter={setBrPublishStart}
                                        />
                                    </div>
                                </div>
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Exchange</div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={exchangeList} // Options to display in the dropdown
                                            selectedValues={brExchange} // Preselected value to persist in dropdown
                                            onSelect={onExchangeHandle} // Function will trigger on select event
                                            onRemove={onExchangeHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            placeholder={exchangeSelAllLbl}
                                            hideSelectedList={hideExcItems}
                                            disable={true}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>


                                    <div className="label col-md-1">
                                        <b className='mandatory-red-inside'>*</b>
                                        Publish End
                                    </div>
                                    <div className="label col-md-5">

                                        <ReactDatepick
                                            disabled={true}
                                            value={brPublishEnd}
                                            dateSetter={setBrPublishEnd}
                                        />
                                    </div>

                                </div>
                                <div className="input-container fullWidthSearch ">
                                    <div className="label col-md-1  div_symbol">Symbol</div>
                                    <div className=" col-md-5 div_symbol">
                                        <Multiselect
                                            options={brSymbolList} // Options to display in the dropdown
                                            selectedValues={brSymbol} // Preselected value to persist in dropdown
                                            selectedValues={brSymbol}
                                            onSelect={onSymbolHandle} // Function will trigger on select event
                                            onRemove={onSymbolHandle} // Function will trigger on remove event
                                            displayValue="stockname" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            disable={true}
                                            onSearch={onHandleSymbolSearch}
                                            placeholder="Symbol"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>


                                    <div className="label col-md-1">
                                        <b className='mandatory-red-inside'>*</b>
                                        User Type
                                    </div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={brUserTypeList} // Options to display in the dropdown
                                            selectedValues={brUserType} // Preselected value to persist in dropdown
                                            // selectedValues={holidayExchange}
                                            onSelect={onUserTypeHandle} // Function will trigger on select event
                                            onRemove={onUserTypeHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            disable={true}
                                            placeholder="User Type"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className=" cls_announceEvent div_Editor ">
                                    <div className="input-container cls_edit divHide fullWidthSearch ">
                                        <div className="label col-md-1 ">Created By</div>
                                        <div className="label col-md-5 ">
                                            <input
                                                className="underline-input "
                                                type="text"
                                                readOnly={true}
                                                value={brCreator}
                                                onChange={(e) => setBrCreator(e.target.value)}
                                            />
                                        </div>

                                        <div className="label col-md-1   ">Created Date</div>
                                        <div className="label col-md-5   ">
                                            <input
                                                className="underline-input "
                                                type="text"
                                                readOnly={true}
                                                value={brCreateDate}
                                                onChange={(e) => setBrCreateDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-container cls_edit divHide fullWidthSearch ">
                                        <div className="label col-md-1 ">Last Modified</div>
                                        <div className="label col-md-5 ">
                                            <input
                                                className="underline-input "
                                                type="text"
                                                readOnly={true}
                                                value={brEditor}
                                                onChange={(e) => setBrEditor(e.target.value)}
                                            />
                                        </div>

                                        <div className="label col-md-1   ">Last Modified Date</div>
                                        <div className="label col-md-5   ">
                                            <input
                                                className="underline-input "
                                                type="text"
                                                readOnly={true}
                                                value={brLstModifyDate}
                                                onChange={(e) => setBrLstModifyDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-container cls_edit divHide  fullWidthSearch ">
                                        <div className="label col-md-1 ">Status</div>
                                        <div className="label col-md-5 ">
                                            <input
                                                className="underline-input "
                                                type="text"
                                                readOnly={true}
                                                value={brStatus}
                                                onChange={(e) => setBrStatus(e.target.value)}
                                            />
                                        </div>


                                    </div>

                                    <hr/>
                                    <div className="w-100">
                                        {/* check is image */}
                                        {mimeType &&
                                        /[\/.](gif|jpg|jpeg|tiff|png)$/i.test(mimeType) == true ? (
                                            <img
                                                src={imgSrc}
                                                style={{
                                                    width: 'auto',
                                                    maxWidth: '50%'
                                                }}
                                            />
                                        ) : (
                                            // another file
                                            <embed
                                                src={imgSrc}
                                                style={{
                                                    width: '100%',
                                                    height: '100vh'
                                                }}
                                                type={mimeType}
                                            ></embed>
                                        )}
                                    </div>

                                </div>
                                <hr/>


                                <div className="label col-md-3"><b>File Upload</b></div>
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-2">Content File</div>
                                    <div className="label col-md-5">
                                        <a target="_blank"
                                           href={fileContentPath}>{fileContentName == "" ? "" : "[View] " + fileContentName} </a>
                                    </div>

                                </div>

                                {/*Announcement & Event Only, Thumbnail image*/}
                                <div id="div_thumbnail"
                                     className="cls_announceEvent cls_research input-container fullWidthSearch">
                                    <div className="label col-md-2">Thumbnail Image</div>
                                    <div className="label col-md-5">
                                        <a target="_blank"
                                           href={fileThumbPath}>{fileThumbPath == "" ? "" : "[View] " + fileThumbName} </a>
                                    </div>


                                </div>

                                <hr/>

                            </div>

                            {/*TnC View*/}
                            <div id="div_tnc" className="col-12 search-container  divHide">
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Title</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="form-control"
                                            type="text"
                                            readOnly={true}
                                            placeholder="e.g. On The Spot Event"
                                            aria-label=""
                                            value={brTitle}
                                            onChange={(e) => setBrTitle(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setBrTitle,popupManager)}

                                        />
                                    </div>
                                    <b className='mandatory-red'>*</b>

                                </div>
                                <div className="input-container fullWidthSearch  ">
                                    <div className="label col-md-1">Description</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="form-control"
                                            type="text"
                                            readOnly={true}
                                            placeholder="e.g. This is a TNC Changes"
                                            aria-label=""
                                            value={brDesc}
                                            onChange={(e) => setBrDesc(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setBrDesc,popupManager)}

                                        />
                                    </div>

                                </div>
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-1">Exchange</div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={exchangeList} // Options to display in the dropdown
                                            selectedValues={brExchange} // Preselected value to persist in dropdown
                                            onSelect={onExchangeHandle} // Function will trigger on select event
                                            onRemove={onExchangeHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            placeholder={exchangeSelAllLbl}
                                            hideSelectedList={hideExcItems}
                                            disable={true}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                    <div id='div_publishDate'
                                         className="label col-md-1 cls_research cls_tnc">
                                        <b className='mandatory-red-inside'>*</b>
                                        Publish Date
                                    </div>
                                    <div id='div_publishDateIpt'
                                         className="label col-md-5 cls_research cls_tnc">

                                        <ReactDatepick
                                            disabled={true}
                                            value={brPublishDate}
                                            dateSetter={setBrPublishDate}
                                        />
                                    </div>

                                    <div className="label col-md-1 cls_announceEvent divHide">
                                        <b className='mandatory-red-inside'>*</b>
                                        Publish End
                                    </div>
                                    <div className="label col-md-5 cls_announceEvent divHide">

                                        <ReactDatepick
                                            disabled={true}
                                            value={brPublishEnd}
                                            dateSetter={setBrPublishEnd}
                                        />
                                    </div>

                                </div>
                                <div className="input-container fullWidthSearch ">


                                    <div className="label col-md-1   ">Version</div>
                                    <div className="label col-md-5   ">
                                        <input
                                            className="form-control "
                                            type="text"
                                            readOnly={true}
                                            value={brVersion}
                                            onChange={(e) => setBrVersion(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setBrVersion,popupManager)}

                                        />
                                    </div>

                                    <div className="label col-md-1">
                                        <b className='mandatory-red-inside'>*</b>
                                        User Type
                                    </div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={brUserTypeList} // Options to display in the dropdown
                                            selectedValues={brUserType} // Preselected value to persist in dropdown
                                            // selectedValues={holidayExchange}
                                            onSelect={onUserTypeHandle} // Function will trigger on select event
                                            onRemove={onUserTypeHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            disable={true}
                                            placeholder="User Type"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="input-container cls_edit divHide fullWidthSearch ">
                                    <div className="label col-md-1 ">Created By</div>
                                    <div className="label col-md-5 ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brCreator}
                                            onChange={(e) => setBrCreator(e.target.value)}
                                        />
                                    </div>

                                    <div className="label col-md-1   ">Created Date</div>
                                    <div className="label col-md-5   ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brCreateDate}
                                            onChange={(e) => setBrCreateDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="input-container cls_edit divHide fullWidthSearch ">
                                    <div className="label col-md-1 ">Last Modified</div>
                                    <div className="label col-md-5 ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brEditor}
                                            onChange={(e) => setBrEditor(e.target.value)}
                                        />
                                    </div>

                                    <div className="label col-md-1   ">Last Modified Date</div>
                                    <div className="label col-md-5   ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brLstModifyDate}
                                            onChange={(e) => setBrLstModifyDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="input-container cls_edit divHide  fullWidthSearch ">
                                    <div className="label col-md-1 ">Status</div>
                                    <div className="label col-md-5 ">
                                        <input
                                            className="underline-input "
                                            type="text"
                                            readOnly={true}
                                            value={brStatus}
                                            onChange={(e) => setBrStatus(e.target.value)}
                                        />
                                    </div>


                                </div>

                                <hr/>
                                {/*File upload section*/}

                                <div className="label col-md-3"><b>File Upload</b></div>
                                <div className="input-container fullWidthSearch">
                                    <div className="label col-md-2">Content File</div>
                                    <div className="label col-md-5">
                                        <a target="_blank"
                                           href={fileContentPath}>{fileContentName == "" ? "" : "[View] " + fileContentName} </a>
                                    </div>

                                </div>

                                <hr/>
                            </div>


                            {/*Button Area*/}
                            <div className="input-container col-md-12 fullWidthSearch">

                                <div className="label col-md-1"></div>

                                <div className="label col-md-11 btn_search_padding">
                                    <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>
                                    {(accessItem['edit'] ?? false) ?
                                        <>
                                            <button className="btn btn-success "
                                                    disabled={brStatus == "Approved" ? true : false}
                                                    onClick={() => handleApprove()}>Approve
                                            </button>
                                            <button className="btn btn-danger "
                                                    disabled={brStatus == "Approved" ? true : false}
                                                    onClick={() => handleDecline()}>Reject
                                            </button>
                                        </>
                                        : ""}

                                </div>
                                )


                            </div>

                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
