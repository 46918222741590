import {handleRequestGet, handleRequestPost} from '../axios';

export const getDealerList = async (size, page, term) => {
    let url = '/dealer';

    let param = {
        size: size,
        page: page
    };

    if(term != "") param['term'] = term;

    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const updateDealerAssign = async (param) => {
    let url = '/accountdealer/reassign';
    let result = await handleRequestPost(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const updateDealerRemove = async (param) => {
    let url = '/accountdealer/delete';
    let result = await handleRequestPost(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getAccountDealerList = async (size, page, term,bterm,dterm,status) => {
    let url = '/accountdealer';

    let param = {
        size: size,
        page: page
    };

    if(term != "") param['term'] = term;
    if(bterm != "") param['bterm'] = bterm;
    if(dterm != "") param['dterm'] = dterm;
    if(status != "") param['status'] = status;

    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
