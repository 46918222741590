import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    accessStatusOptions,
    broadcastStatusOption,
    broadcastTypeOptions, convertFuncPerm,
    convertPositiveNumberHundred, dateFormats,
    getDefaultAccessMat, getNavIdByPath,
    handlePopup,
    handleSelectChange, momentTimeFormat,
    numProcessor,
    useLocalStorage,
    userTypeCategory, convertErMsg, specialCharCheck
} from "../../../general/general-tools";
import {confirmAlert} from "react-confirm-alert";
import Multiselect from "multiselect-react-dropdown";
import ModalConfirm from "../../public/ModalConfirm";
import { usePopupManager } from "react-popup-manager";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";
import ReactDatepick from "../../../general/form-control/react-datepick";
import dayjs from "dayjs";


const Index = () => {
    const {setLoading} = useLoading();

    const [tableData, setTableData] = useLocalStorage("caSearchNameEntry_tblDat",[]);
    const [totalRecords, setTotalRecords] = useLocalStorage("caSearchNameEntry_tblTotRec",0);
    const [currentPage, setCurrentPage] = useLocalStorage("caSearchNameEntry_tblCurPg",1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage("caSearchNameEntry_tblCurPerPg",10);
    const [selectedRows, setSelectedRows] = useLocalStorage("caSearchNameEntry_tblSelected",[]);

    const [caSearchName, setCaSearchName] = useLocalStorage("caSearchName","");
    const [caStockCode, setCaStockCode] = useLocalStorage("caStockCode","");
    const [caAccNum, setCaAccNum] = useLocalStorage("caAccNum","");
    const [caAmendAccNum, setCaAmendAccNum] = useLocalStorage("caAmendAccNum","");
    const [caBranchCode, setCaBranchCode] = useLocalStorage("caBranchCode","");
    const [caBranchList, setCaBranchList] = useLocalStorage("caBranchList",[]);
    const [caOrderId, setCaOrderId] = useLocalStorage("caOrderId","");
    const [caOrderEntryDate, setCaOrderEntryDate] = useLocalStorage("caOrderEntryDate","");
    const [caOrderMatchedDate, setCaOrderMatchedDate] = useLocalStorage("caOrderMatchedDate","");
    const [caApprovedDate, setCaApprovedDate] = useLocalStorage("caApprovedDate","");
    const [caApprovedBy, setCaApprovedBy] = useLocalStorage("caApprovedBy","");

    const [caStockList, setCaStockList] = useState([]);
    const [caStatus, setCaStatus] = useLocalStorage("caStatus","");
    const [toggleReset, setToggleReset] = useState(false);
    const isInitialMount = useRef(true);


    const location = useLocation();
    let navigate = useNavigate();
    const popupManager = usePopupManager();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat():getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);

    useLayoutEffect(() => {
        if(tableData.length >0){
            handleMainList()
        }
        handleTickerList("","");
        handleBranchList();
    },[])

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleMainList();
        }
    }, [currentPage, currentPerPage]);
    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {
        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }
    useEnterKeyPress(handleEnterKeyPress);


    const handleMainList = async (newSearch = false) => {

        setLoading(true);
        let curPage = currentPage;
           if (newSearch) {
            setCurrentPage(1)
            curPage= 1;
        }
        let searchTermStr = "";
        for (let i = 0; i < caStockCode.length; i++) {
            searchTermStr += caStockCode[i]['id']+","
        }
        searchTermStr= searchTermStr.substring(0,searchTermStr.length-1)

        let searchTermBranch = "";
        for (let i = 0; i < caBranchCode.length; i++) {
            searchTermBranch += caBranchCode[i]['id']+","
        }
        searchTermBranch= searchTermBranch.substring(0,searchTermBranch.length-1)


        let OrderEntryDate = dayjs(caOrderEntryDate).format(momentTimeFormat()) ;
        let OrderMatchedDate = dayjs(caOrderMatchedDate).format(momentTimeFormat()) ;

        let searchParams = {};
        searchParams['page'] = curPage;
        searchParams['size'] = currentPerPage;
        searchParams['aterm'] = caAccNum;
        searchParams['nterm'] = caSearchName;
        searchParams['oterm'] = caOrderId;
        searchParams['ticker'] = searchTermStr;
        searchParams['bterm'] = searchTermBranch;
        if(OrderEntryDate != '' && OrderEntryDate != 'Invalid Date')searchParams['entrydate'] = OrderEntryDate;
        if(OrderMatchedDate != '' && OrderMatchedDate != 'Invalid Date')searchParams['matchdate'] = OrderMatchedDate;


        api.getAmendmentList(searchParams).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

             if (result.data.list) {
                 if(result.data.list.length == 0)handlePopup(true,"Error","No results found",popupManager)

                 let tblDat = result.data.list;

                 let tblList = [];
                 for (let i = 0; i < tblDat.length; i++) {
                     let dataEle = tblDat[i];
                     let tempDat = dataEle;

                     tempDat['Symbol'] = dataEle['ticker_code'] +" | " + dataEle['ticker_name']
                     tblList.push(tempDat);
                 }
                 setTableData(tblList)

             }
            setLoading(false);
        });
    };

    const handleTickerList = async (searchTerm,searchSymbols) => {
        let searchTermStr = "";
        for (let i = 0; i < searchTerm.length; i++) {
            searchTermStr += searchTerm[i]['id']+","
        }
        searchTermStr= searchTermStr.substring(0,searchTermStr.length-1)
        setLoading(true);
        api.getBroadcastTicker(100, 1,null, searchSymbols == ""? null : searchSymbols,searchTermStr).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

            if (result.status == 0 && result.data) {
                if(result.data.list.length > 0){
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdownTicker(dataList);
                    setCaStockList(TreeData);
                }


            }
        });
        setLoading(false);
    };


    const onHandleSymbolSearch= (searchItem) =>{
        if(searchItem.length > 2) handleTickerList("",searchItem);
        else if (searchItem.length == 0){
            setCaStockList([])
        }
    }

    const onSelectBranch = (selectedList, selectedItem) => {
        setCaBranchCode(selectedList);
    }
    const onRemoveBranch = (selectedList, selectedItem) => {
        setCaBranchCode("");
    }

    const onSelect = (selectedList, selectedItem) => {
        setCaStockCode(selectedList);
    }
    const onRemove = (selectedList, selectedItem) => {
        setCaStockCode("");
    }

    const convertRawIntoDropdownTicker = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                exchange: data[i]['em_code'],
                symbol: data[i]['t_code'],
                stockname: data[i]['t_name'],
                id: data[i]['t_id']
            });
        }
        return returnDat;
    }

    const handleBranchList = async () => {
        setLoading(true);
        api.getBranchList(999, 1, 1, null).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

           if(result.data){
               if (result.data.list.length > 0) {
                   let dataList = result.data.list;
                   var TreeData = convertRawIntoDropdownBranch(dataList);
                   setCaBranchList(TreeData);
               } else {
                   handlePopup(true,"Error","No results found",popupManager)
                   setTableData([]);
                   setTotalRecords(10);
               }
           }else {
               handlePopup(true,"Error","No results found",popupManager)
               setTableData([]);
               setTotalRecords(10);
           }
        });
        setLoading(false);
    };

    const convertRawIntoDropdownBranch = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                code: data[i]['bb_code'],
                name: data[i]['bb_code'] + " | " + data[i]['bb_name'],
                id: data[i]['bb_id']
            });
        }
        return returnDat;
    }



    useLayoutEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, []);


    const handleOnclick = async (row) => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "cl_amend_opr", {state: {row: row, perm:actualPerm, mode: 'Entry'}});
        setLoading(false);
    };

    const handleResetBtn = async () => {
        await setLoading(true);
        setCaAccNum("");
        setCaSearchName("");
        setCaOrderId("");
        setCaOrderMatchedDate("");
        setCaOrderEntryDate("");
        setToggleReset(!toggleReset)
        setCaStockCode([]);
        setCaBranchCode([]);
        setTableData([]);
        setLoading(false);
    };
    // Document ID, Creation Date, Publish Start, Publish End, Approval Date, Type, Symbol, User Type, Created By, Approved By
    const columns = useMemo(
        () => [
            {
                id: row => row.order_entry,
                name: 'Ord.Date',
                selector: row => dayjs(row.order_entry).format(dateFormats()),
                sortable: true
            },
            {
                name: 'Ord.No',
                selector: row => row.order_no,
                sortable: true,
                cell: (row: { order_no: any }) => (
                    <>
                        <a className="tableItemsHlink" onClick={() => handleOnclick(row)}>{row.order_no}</a>
                    </>
                ),
            },
            {
                name: 'CL.Name',
                selector: row => row.username,
                sortable: true,

            },
            {
                name: 'Acc.No',
                selector: row => row.acct_no,
                sortable: true,

            },
            {
                name: 'Symbol',
                selector: row => row.Symbol,
                sortable: true,
                wrap:true

            },
            {
                name: 'Action',
                selector: row => row.order_action,
                sortable: true
            },
            {
                name: 'Price',
                selector: row => convertPositiveNumberHundred(row.order_avg_price ?? 0) ?? "-",
                sortable: true
            },
            {
                name: 'Qty',
                selector: row => convertPositiveNumberHundred(row.order_match_qty ?? 0) ?? "-",
                sortable: true,

            },
            {
                name: 'Mth Amt',
                selector: row => convertPositiveNumberHundred(row.order_match_price ?? 0) ?? "-",
                sortable: true,

            }
        ],
        []
    );


    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration  &gt; Client Amendment &gt; Entry</h5>
                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-2">User ID / Name</div>
                                    <div className="label col-md-4">
                                        <input
                                            className="form-control "
                                            type="text"
                                            placeholder="Search User ID / Name "
                                            value={caSearchName}
                                            onChange={(e) => setCaSearchName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCaSearchName,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-2">Stock Code / Name</div>
                                    <div className=" col-md-4">
                                        <Multiselect
                                            options={caStockList} // Options to display in the dropdown
                                            selectedValues={caStockCode} // Preselected value to persist in dropdown
                                            onSelect={onSelect} // Function will trigger on select event
                                            onRemove={onRemove} // Function will trigger on remove event
                                            displayValue="stockname" // Property name to display in the dropdown options
                                            showCheckbox={false}
                                            selectionLimit={1}
                                            onSearch={onHandleSymbolSearch}
                                            placeholder="Search Stock Code / Name"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />

                                    </div>
                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-2">Account Number</div>
                                    <div className="label col-md-4">
                                        <input
                                            className="form-control "
                                            type="text"
                                            placeholder="Search Account Number "
                                            value={caAccNum}
                                            onChange={(e) => setCaAccNum(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCaAccNum,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-2">Order Entry Date</div>
                                    <div className="label col-md-4">

                                        <ReactDatepick
                                            value={caOrderEntryDate}
                                            dateSetter={setCaOrderEntryDate}
                                            resetToggle={toggleReset}
                                        />

                                    </div>
                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-2">Branch Code</div>
                                    <div className=" col-md-4">
                                        <Multiselect
                                            options={caBranchList}
                                            onSelect={onSelectBranch}
                                            onRemove={onRemoveBranch}
                                            selectedValues={caBranchCode}
                                            displayValue="name"
                                            placeholder="Search Branch Code"
                                            selectionLimit={1}
                                            hidePlaceholder={true}
                                            showCheckbox={false}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />

                                    </div>
                                    <div className="label col-md-2">Order ID</div>
                                    <div className="label col-md-4">

                                        <input
                                            className="form-control "
                                            type="text"
                                            placeholder="Search Order ID"
                                            value={caOrderId}
                                            onChange={(e) => setCaOrderId(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCaOrderId,popupManager)}

                                        />
                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-2">Order Match Date</div>
                                    <div className="label col-md-4">

                                        <ReactDatepick
                                            value={caOrderMatchedDate}
                                            dateSetter={setCaOrderMatchedDate}
                                            resetToggle={toggleReset}
                                        />
                                    </div>

                                </div>


                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary " id="btn-search-main"  onClick={() => handleMainList(true)}>Search
                                        </button>
                                        <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Client Amendment Search Result</h5>
                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    perPageQty={setCurrentPerPage}
                                    handleSelect={setSelectedRows}
                                    columns={columns}
                                    data={tableData}
                                    pagination={{
                                        currentPage: currentPage,
                                        totalRecords: totalRecords,
                                        currentPerPage: currentPerPage,
                                        selectableHandle: {setSelectedRows},
                                    }}
                                />



                            </div>

                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
