import React from 'react';
import {createRoot} from 'react-dom/client';
import {CookiesProvider} from 'react-cookie';
import { PopupProvider } from "react-popup-manager";

// import './index.css';
import App from './App';
import './components/asset/styles/main-style.css';
//import reportWebVitals from './reportWebVitals';
import {AuthProvider} from './context/AuthProvider';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import './components/asset/font-awesome/css/all.css';

const root = createRoot(document.getElementById('root') as HTMLDivElement);
root.render(
    //   <React.StrictMode>
    <CookiesProvider>
        <BrowserRouter>
            <AuthProvider>
            <PopupProvider>
                <Routes>
                    <Route path="/*" element={<App/>}/>
                </Routes>
            </PopupProvider>
            </AuthProvider>
        </BrowserRouter>
    </CookiesProvider>
    //   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
