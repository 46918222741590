import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {tab} from "@testing-library/user-event/dist/tab";
import {
    convertFuncPerm,
    getDefaultAccessMat,
    getNavIdByPath,
    handlePopup,
    useLocalStorage, convertErMsg, specialCharCheck, dateFormats
} from "../../../general/general-tools";
import {usePopupManager} from "react-popup-manager";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";
import ReactTooltip from "react-tooltip-rc";
import Multiselect from "multiselect-react-dropdown";
import dayjs from "dayjs";


const Index = () => {
    const {setLoading} = useLoading();

    const [tableData, setTableData] = useLocalStorage("mst_taskSchedular_TblDat", []);
    const [totalRecords, setTotalRecords] = useLocalStorage("mst_taskSchedular_TotRec", 0);
    const [currentPage, setCurrentPage] = useLocalStorage("mst_taskSchedular_CurPg", 1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage("mst_taskSchedular_curPerPg", 10);

    const [taskId, setTaskId] = useLocalStorage("mst_taskSchedular_taskId", "");
    const [taskIdList, setTaskIdList] = useLocalStorage("mst_taskSchedular_taskIdList", []);

    const [recurrence, setRecurrence] = useLocalStorage("mst_taskSchedular_recurrence", "");
    const [recurrenceList, setRecurrenceList] = useLocalStorage("mst_taskSchedular_recurrenceList", []);

    const isInitialMount = useRef(true);

    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat() : getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);

    useLayoutEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        handleInitTskDropdown();
        if (tableData.length > 0) {
            handleInitList()
        } else {
            handleResetBtn();
        }
    }, [])

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleInitList();
        }
    }, [currentPage, currentPerPage]);

    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {
        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }

    useEnterKeyPress(handleEnterKeyPress);

    const handleInitTskDropdown = async () => {
        setLoading(true);

        api.getTaskSchedulerList(20, 1, 1, "", "").then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }

            if (result.data) {

                if (result?.data?.list?.length > 0) {
                    let dataList = result.data.list;
                    let termDropdown = convertRawIntoDropdown(dataList)
                    setTaskIdList(termDropdown);


                }
            }

            let recurSelection = [{
                name: "Day",
                id: 0
            }, {
                name: "Week",
                id: 1
            }, {
                name: "Month",
                id: 2
            },
            ];

            setRecurrenceList(recurSelection)

            setLoading(false);

        });

    }

    const convertRawIntoDropdown = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                name: data[i]['tss_name'],
                id: data[i]['tss_name']
            });
        }
        return returnDat;
    }

    const handleInitList = async (newSearch = false) => {
        // setLoading(true);
        let curPage = currentPage;
        if (newSearch) {
            setCurrentPage(1)
            curPage = 1;
        }

        let tempTaskStr = '';
        if (taskId.length > 0) {
            for (let i = 0; i < taskId.length; i++) {
                tempTaskStr += taskId[i]['name'] + ',';
            }

            tempTaskStr = tempTaskStr.substring(0, tempTaskStr.length - 1)
        }

        let tempRecurStr = '';
        if(recurrence.length >0){
            for (let i = 0; i < recurrence.length; i++) {
                tempRecurStr += recurrence[i]['id'] + ',';
            }
            tempRecurStr = tempRecurStr.substring(0, tempRecurStr.length - 1)

        }
        setLoading(true);
        api.getTaskSchedulerList(currentPerPage, curPage, 1, tempTaskStr, tempRecurStr).then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }

            if (result.data) {

                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    let tblList = [];


                    for (let i = 0; i < dataList.length; i++) {
                        let tempEle = dataList[i];
                        if (dataList[i]['tss_recurr_time']) {
                            let eleRecurTime = JSON.parse(dataList[i]['tss_recurr_time']);
                            tempEle['recurring'] = generateTypeDescription(eleRecurTime['type'], eleRecurTime['repeat'], eleRecurTime['time'], eleRecurTime['days'])
                        }
                        if (dataList[i]['tss_depend']) {
                            let tempEleDepends = JSON.parse(dataList[i]['tss_depend']);
                            let tempDepend = "";

                            for (let j = 0; j < tempEleDepends.length; j++) {
                                tempDepend += tempEleDepends[j] + "\n";
                            }
                            tempEle['depend'] = tempDepend;
                        }
                        tblList.push(tempEle);
                    }

                    setTableData(tblList);

                }else{
                    handlePopup(true,"Error","No results found",popupManager)
                    setTableData([]);
                    setTotalRecords(0);
                    setLoading(false);
                }


            }
            setLoading(false);

        });


    };

    const handleOnclick = async (row) => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "task_schedular_opr", {
            state: {
                row: row,
                perm: actualPerm,
                mode: 'Edit'
            }
        });
        setLoading(false);
    };
    const handleAddBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "task_schedular_opr", {
            state: {
                row: "",
                perm: actualPerm,
                mode: 'Add'
            }
        });
        setLoading(false);
    };
    const handleResetBtn = async () => {
        await setLoading(true);
        setTaskId([]);
        setRecurrence([]);
        handleInitTskDropdown();
        setLoading(false);
    };

    function generateTypeDescription(recurrType, recurrRepeat, recurrTime, tssRecurrTimeNV) {
        let typeDescription = '';

        if (recurrType === 0) {
            // Repeat by day
            typeDescription = `Every day ${recurrTime}`;
        } else if (recurrType === 1) {
            // Repeat by week
            typeDescription = `Every ${recurrRepeat} week(s) on `;
            let recurrDays = JSON.parse(tssRecurrTimeNV).days;

            let daysOfWeek = '';
            for (let dayPosition = 0; dayPosition < 7; dayPosition++) {
                // Check if the day is included
                if ((recurrDays & Math.pow(2, dayPosition)) > 0) {
                    // Add day to the list
                    daysOfWeek += (daysOfWeek.length > 0 ? ', ' : '') +
                        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayPosition] + ['\n'];
                }
            }

            if (recurrDays === 127) {
                daysOfWeek = 'everyday';
            }

            typeDescription += `${daysOfWeek} ${recurrTime}`;
        } else if (recurrType === 2) {
            // Repeat by month
            typeDescription = `Every ${recurrRepeat} month(s) on `;

            switch (recurrRepeat) {
                case 1:
                    typeDescription += '1st day';
                    break;
                case 99:
                    typeDescription += 'last day';
                    break;
                default:
                    typeDescription += `${recurrRepeat}th day`;
            }
        }

        return typeDescription;
    }

    const columns = useMemo(
        () => [
            {
                id: row => row.tss_id,
                name: 'Task Id',
                selector: row => row.tss_id,
                sortable: true,
                // center: true,
                cell: (row: { tss_id: any }) => (
                    <>
                        <a className="tableItemsHlink" onClick={() => handleOnclick(row)}>{row.tss_id}</a>
                    </>
                )
            },
            {
                name: 'Descriptions',
                selector: row => row.tss_desc,
                sortable: true,
            }
            ,
            {
                name: 'Start Time',
                cell: (row: { tss_start: any }) => (
                    <>
                        {!moment(row.tss_start).isValid() ? "-" : dayjs(row.tss_start).format(dateFormats())}

                    </>
                ),
                sortable: true
            },
            {
                name: 'Recurrence',
                cell: (row: { recurring: any }) => (
                    <>{row.recurring}</>
                    // <>
                    //     <ReactTooltip data-class="my-tooltip" multiline={true} place={'bottom'}/>
                    //     <div style={{"white-space":"break-spaces"}}>
                    //         {row.currencyStrF2}
                    //         <div style={{"white-space":"pre"}}>
                    //             {row.currencyStr ? <div style={{"color":"blue",'font-size':'0.8em'}} className={"linkCursor"} aria-hidden="true" data-class="my-tooltip" data-tip={row.currencyStr}><b>More</b></div> : ''}
                    //
                    //         </div>
                    //     </div>
                    //
                    //
                    // </>
                ),
                sortable: true
            },
            {
                name: 'Dependency',
                cell: (row: { depend: any }) => (
                    <>
                        <div style={{"white-space": "break-spaces"}}>
                            {row.depend}
                        </div>
                    </>

                ),
                sortable: false
            }
        ],
        []
    );

    const onTaskIdHandle = (selectedList, selectedItem) => {
        setTaskId(selectedList);
        // if (selectedList.length == 0) setTaskIdList([]);
    }

    const onRecurranceHandle = (selectedList, selectedItem) => {
        setRecurrence(selectedList);
        // if (selectedList.length == 0) setRecurrenceList([]);
    }

    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Task
                                Maintenance &gt; Task Scheduler - Search</h5>
                            <div className="dropdown no-arrow">
                            </div>

                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Task ID</div>
                                    <div className=" col-md-11">
                                        <Multiselect
                                            options={taskIdList} // Options to display in the dropdown
                                            selectedValues={taskId} // Preselected value to persist in dropdown
                                            onSelect={onTaskIdHandle} // Function will trigger on select event
                                            onRemove={onTaskIdHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            placeholder="Task ID"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>

                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Recurrence</div>
                                    <div className=" col-md-11">
                                        <Multiselect
                                            options={recurrenceList} // Options to display in the dropdown
                                            selectedValues={recurrence} // Preselected value to persist in dropdown
                                            onSelect={onRecurranceHandle} // Function will trigger on select event
                                            onRemove={onRecurranceHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={false}
                                            selectionLimit={1}
                                            placeholder="Recurrence"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>

                                </div>
                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary" id="btn-search-main"
                                                onClick={() => handleInitList(true)}>Search
                                        </button>
                                        <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                        </button>
                                        {actualPerm['add'] ? <button className="btn btn-primary "
                                                                     onClick={() => handleAddBtn()}>Add</button> : ""}
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    perPageQty={setCurrentPerPage}
                                    columns={columns}
                                    data={tableData}
                                    pagination={{
                                        currentPage: currentPage,
                                        currentPerPage: currentPerPage,
                                        totalRecords: totalRecords,
                                    }}
                                />

                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
