// import useAuth from "./useAuth";

import { Cookies } from 'react-cookie';
import moment from "moment";
import 'moment-timezone';

const cookies = new Cookies();

const setLogin = async (userDetail) => {
    cookies.set('expiredTime', (parseInt(moment().valueOf()) + parseInt((process.env.REACT_APP_SESSION_TIMEOUT))), { path: '/', maxAge: 2147483647 });
    cookies.set('admin_user', userDetail, { path: '/', maxAge: 2147483647 });
    return true;
};

const setAccessLevel = async (accessLevel,specialPerm) => {

    cookies.set('access_level', accessLevel, { path: '/', maxAge: 2147483647 });
    cookies.set('special_perm', specialPerm, { path: '/', maxAge: 2147483647 });
    return true;
};

const setSpecialPerm = async (specialPerm) => {
    cookies.set('special_perm', specialPerm, { path: '/', maxAge: 2147483647 });
    return true;
};


const setLogout = () => {
    return cookies.remove('admin_user');
    return cookies.remove('access_level');
    return cookies.remove('special_perm');
};



const getUserDetail = () => {
    return cookies.get('admin_user');
};


const getUserAccessDetail = () => {
    return cookies.get('access_level');
};

const getUserExpiredTime = () => {
    return cookies.get('expiredTime');
};

const resetUserExpiredTime = (expiredTime) => {
    let expiredTimes = moment(parseInt(expiredTime)).tz( 'Asia/Singapore').valueOf();
    cookies.set('expiredTime', expiredTimes, { path: '/', maxAge: 2147483647 });
    return true;
};


export { setLogin, setLogout, getUserDetail,getUserAccessDetail ,setAccessLevel,getUserExpiredTime,resetUserExpiredTime};
