import axios from 'axios';
import {
    gcmEncrypt,
    gcmDecrypt,
    generateBearerTokenKey
} from '../crypto/msgCrypto';
import { generateEcdhKeys } from '../crypto/ecdhCrypto';
import Config from '../config';
import { getUserDetail, setLogout } from '../hooks/handleCookie';

const axiosInstance = axios.create({
    baseURL: Config.baseURL,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

axiosInstance.interceptors.request.use(
    function (config) {
        let method = config.method;
        let userDetail = getUserDetail();

        //AES encryption with ECDH sharedKey
        //encrypt body data
        //generate ECDH Key
        let { sharedKey, publicKey } = generateEcdhKeys();
        config.ECDHPublicKey = publicKey;
        config.ECDHSharedKey = sharedKey;

        // if (method == 'get') {
        //     //get
        //     let encryptedBody = gcmEncrypt('{}', sharedKey);
        //     config.data = encryptedBody;
        // } else {
        //     console.log('post ', config.data);
        //     console.log('sharedKey ', sharedKey);
        //     //post
        //     let encryptedBody = gcmEncrypt(config.data, sharedKey);

        //     console.log('encryptedBody ', encryptedBody);
        //     config.data = encryptedBody;
        // }

        if (userDetail && userDetail.login_token) {
            let token = userDetail.login_token;
            //protected api
            config.headers['sesstoken'] = `${token}`;
            config.headers['Ecdh-Public-Key'] = publicKey;
        } else {
            //public api
            config.headers['Ecdh-Public-Key'] = publicKey;
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        if (response.status === 200 && response.data) {
            return response; //fetch data to display
        } else {
            alert(response.status);
            alert('Warning: Fail to get data. ' + response.data.message);
        }
    },
    (error) => {
        // console.log(error);
        if (error.response.status === 401) {
            // alert(error.response.status);
            //logout user
            setLogout();
            window.location.href = './';
        }}
);

//used to call api
const handleFormPost = async (url, param) => {
    // console.log('handleFormPost');
    // console.log(param);
    //stringify param before going to next step
    //let stringifyBody = JSON.stringify(param);
    return axiosInstance
        .post(url, param)
        .then(async function (response) {
            // handle success
            // console.log(response);
            if (response) {
                let status = response.status;

                if (status == 200) {
                    let data = response.data;
                    let userDetail = getUserDetail();

                    //logined
                    if (userDetail && userDetail.token) {
                        let token = userDetail.token;
                        // console.log("token")
                        // console.log(token)
                        let key = await generateBearerTokenKey(token);

                        // console.log('token', token);
                        // console.log('key', key);
                        let result = await gcmDecrypt(data, key);
                        // console.log('result', result);
                        return result;
                    } else {
                        const { config } = response;
                        //get sharedkey from config
                        let ECDHSharedKey = config.ECDHSharedKey;
                        let result = await gcmDecrypt(data, ECDHSharedKey);

                        return result;
                    }
                } else {
                    return response;
                }
            }
        })
        .catch(function (error) {
            // handle error
            // console.log(error);
        });
};

export { handleFormPost };
