import React, { createRef, useRef, useState, useEffect, useLayoutEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { useCookies } from 'react-cookie';

import { generateBearerTokenKey, gcmDecrypt } from '../../../crypto/msgCrypto';

import '../../asset/styles/dashboard-style.css';

import DoughtnutExample from '../../general/charts/multi-thickness-doughtnut';

import logoBursa from '../../asset/images/logo/logo_bursa.png';
import logoHS from '../../asset/images/logo/logo_hs.png';
import logoNikkei from '../../asset/images/logo/logo_nikkei.png';
import logoSSE from '../../asset/images/logo/logo_sse.png';

import event1 from '../../asset/images/event/1101.png';
import event2 from '../../asset/images/event/1102.png';
import event3 from '../../asset/images/event/1103.png';

import eventIC1 from '../../asset/images/event/logo_bursa.svg';
import eventIC2 from '../../asset/images/event/logo_ppl.svg';
import eventIC3 from '../../asset/images/event/logo_pinterest.svg';
import sideNav from '../../navigation/Sidebar';
import topNav from '../../navigation/Topbar';
import Sidebar from "../../navigation/Sidebar";
import api from "../../../apis";
import {
    getNavIdByPath,
    getNavItemById,
    getNavNumericIcon,
    convertErMsg,
    handlePopup, dateFormats
} from "../../general/general-tools";
import moment from "moment";
import {useLoading} from "../../../context/LoadingContext";
import dayjs from "dayjs";

const Index = () => {

    const {setLoading} = useLoading();


    useLayoutEffect(() => {
        // @ts-ignore
        window.AOS.init({
            duration: 300,
            once: true,
            mirror: true,
            startEvent: 'load'
        });

        //const script = document.createElement('script');
        //script.src = './asset/main.js';
        //script.async = true;
        //document.body.appendChild(script);
        //  userRef.current?.focus() as UserRefState;

        // let result =  await generateBearerTokenKey("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzIjoiVE1XSFZrNFVycm9oZmVVT21QeHp5Zz09Okw5WFpPck84bGxteEQwOTh1WUc4R0E9PTpWUXIwSlpJUFJ0c055RlNma1VhNUJpQ1dVZDk4YWw1U0ZMaEIvWngzeS9CNjBVY3p0bVovVFZURHhUblVQcXd3aEtJRDRjVUZiaVVMa2grdndVbDh5UzZxcDBGMVdSSmhnd3Ftb2xXMWYrdE1vd0hMZjlSK2hza20ybjd0bmNwU0tSUWRWVW5wNmRlOTdrSEM2YWNEaFFMcVpJaVIvTlJXVXFjSlFxTTlCSER2ckhib1VFVFR0Uys0NFBUeFVWT3NRUHJMbnhiaXJWVT0iLCJpYXQiOjE2NjExMzU5NzIsImF1ZCI6IlREUyBXZWIgUG9ydGFsIiwiaXNzIjoiVERTIFdlYiBTZXJ2aWNlIn0.0ZxkC_D3wqLhiLpmvy7JU4QIoqe5XRmgHVKKVIESTu8");
        //   console.log("gcmEncrypt")
        //   console.log(result)
        // const buff = Buffer.from("bhuCdY6npAHGIuEntnae23yStgzO6kkAiyLxtbiiEVLfvqpK2wvD0tQZ+dUflZtBrykNDqs3tRqHwE9yPWSQaFc/czX9L4IlWS0Bl4RWWRRQSqCYRr91ZAtPw2Lhgq76n7EzBqHRjUWzURmJCOzWbycW/RhflMu4F9Q=", "base64");

        // let result =  await gcmDecrypt(buff);
        // console.log("gcmDecrypt")
        // console.log(result)
    }, []);

    // @ts-ignore
    const { auth, setAuth } = useAuth();
    const [menuSource, setMenuSource] = useState([]);

    // const [cookies, setCookie,removeCookie] = useCookies(['user']);
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        setLoading(true);

        api.getMenu().then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            // console.log(result)
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

            let tempArr = [];
            if(result.data){
                let reversedList = result.data.list.reverse();

                for (let i = 0; i <  5 ; i++) {
                    if(!reversedList[i]) break;
                    let menuItem = getNavItemById(reversedList[i]['amal_amid']);
                    let temp = {
                        "url" : menuItem['url'],
                        "date" : reversedList[i]['amal_time_create'],
                        "title" : menuItem['label'],
                        "num" : i+1,
                    }
                    tempArr.push(temp);
                }
                setMenuSource(tempArr)

            }
            setLoading(false);

        });


    }, []);


    return (


    <div className="pt-3 ">
            <div className="fluid-container ">
                <div className="row gx-0 ">
                    <div className="col-md-7 p-0">
                        <div className="d-flex justify-content-center align-itmes-center">
                            <div className="container pt-4 summary-container ">
                                <div className="row gx-0">
                                    <div className="col-12 title">
                                        <h4>Last Visited Menu Link</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="fullWidthSearch research-reports-container">
                                            <ul
                                                className="nav nav-tabs custom-nav"
                                                id="research-reports-tabs"
                                                role="tablist"
                                            >


                                            </ul>
                                            <div className="tab-content pt-3" id="myTabContent">
                                                <div
                                                    className="tab-pane show active"
                                                    id="latest"
                                                    role="tabpanel"
                                                    aria-labelledby="latest-tab"
                                                >
                                                    <div className="report-blocks-container">
                                                        {menuSource.map(el =>
                                                            <div className="item" >
                                                                <div className="date"></div>
                                                                <div className="title"><a href={el.url}> {getNavNumericIcon(el.num)} {el.title} </a></div>
                                                                <div className="desc">{el.date}</div>
                                                            </div>

                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane "
                                                    id="past"
                                                    role="tabpanel"
                                                    aria-labelledby="past-tab"
                                                ></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-md-5 ">
                    </div>
                </div>
                <div className="row gx-0 p-0 ">
                    <div className="col-md-7 col-12  text-left pt-5">
                    </div>
                    <div className="col-md-5 col-12  text-left pt-5">
                    </div>
                </div>

                <div className="row gx-0 p-0 pt-5 ">
                    <div className="col-md-7 col-12  text-left">
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Index;
