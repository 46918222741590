import {handleRequestGet, handleRequestPost} from '../axios';

export const getCompanyDetail = async () => {
    let url = '/company';


    let result = await handleRequestGet(url, []);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateCompany = async (data) => {
    let url = '/company/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
