import React, {createRef, useRef, useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useLoading} from '../../../context/LoadingContext';
import {Cookies, useCookies} from 'react-cookie';
import {usePopupManager} from "react-popup-manager";

import '../../asset/styles/login-style.css';

import api from '../../../apis';
import bgImg4 from '../../asset/images/login/bg-4.jpg';
import {convertErMsg, disableAllEleInDiv, handlePopup} from "../../general/general-tools";
import {confirmAlert} from "react-confirm-alert";

const PgLogin = () => {
    //set background image
    const [cookies, setCookies, removeCookie] = useCookies(['admin_user']);

    const [bgImage, setBgImg] = useState(bgImg4);
    const {loading, setLoading} = useLoading();
    //const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();

    // const from = location.state?.from?.pathname || "/";

    const userRef = useRef<HTMLDivElement>();
    const errRef = createRef<HTMLDivElement>();

    const [uid, setUserId] = useState('');
    const [pwd, setUserPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [passwordShowToggle, setpPasswordShowToggle] = useState(false);
    const [pwInputType, setpwInputType] = useState('password');
    const [loginColLenClass, setLoginColLenClass] = useState('col-lg-7 ');

    useEffect(() => {
        let windowWidth = window.screen.width;

        if (windowWidth > 1400 && windowWidth < 1580) setLoginColLenClass('col-lg-9 ');
        else setLoginColLenClass('col-lg-7 ');

    }, [window.screen.width]);


    useEffect(() => {


        //Clear up wild interval
        var interval_id = window.setInterval(() => {
        }, 99999);
        for (var i = 0; i < interval_id; i++) window.clearInterval(i);

        //Set remember me
        if (cookies.remember_me == 'true') {
            setRememberMe(true)
            setUserId(cookies.remember_me_username)
        } else {
            setRememberMe(false)
            setCookies('remember_me', false, {path: '/', maxAge: 2147483647});
            setCookies('remember_me_username', '', {path: '/', maxAge: 2147483647});
        }
        userRef.current?.focus();

        const params = new URLSearchParams(window.location.search);

        // Get the value of the 'msg' parameter
        const msg = params.get('msg');
        if (msg) {
            confirmAlert({
                title: 'Logout',
                message: <div
                    id="area_confirm">{msg ? msg : "We had detected duplicated login in from another device. Please login again."}</div>,
                buttons: [
                    {label: 'Ok', onClick: () => navigateLogout()}
                ],
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
        }

    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [uid, pwd]);

    const navigateLogout = () => {
        removeCookie('admin_user', {path: '/'});
        removeCookie('access_level', {path: '/'});
        removeCookie('expiredTime', {path: '/'});
        localStorage.clear();
        window.location.reload();

    };


    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        await setLoading(true);
        e.preventDefault();
        if (rememberMe) setCookies('remember_me_username', uid, {path: '/', maxAge: 2147483647});
        api.userLogin({username: uid, password: pwd}).then((result: any) => {
            if (result && result.status == 0) {
                setLoading(true);
                setTimeout(function () {
                    setLoading(false);
                    window.location.reload();
                }, 1000);
            } else {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
            }
        });
    };


    const handleRememberMe = event => {
        setCookies('remember_me', event.target.checked, {path: '/', maxAge: 2147483647});
        if (event.target.checked) setRememberMe(true);
        else setRememberMe(false);
    };

    const handlePasswordShow = () => {
        const element = document.getElementById("div_pw_eye");
        if (passwordShowToggle) {
            element.classList.remove('fa-eye');
            element.classList.add('fa-eye-slash');
            setpwInputType("text")

        } else {
            element.classList.add('fa-eye');
            element.classList.remove('fa-eye-slash');
            setpwInputType("password")

        }
        setpPasswordShowToggle(!passwordShowToggle);
    }

    return (
        <div className="login-page-container">
            <div
                className="login-page-bg"
                style={{backgroundImage: `url(${bgImage})`}}
            ></div>
            <div className="container">
                <div className="row justify-content-center align-items-center login-form-container">
                    <div
                        className={loginColLenClass + " col-12 login-form-block"}
                        style={{backgroundImage: `url(${bgImage})`}}
                    >
                        <div className="row h-100">
                            <div className="col-md-6 col-12"></div>
                            <div className="col-md-6 col-12 login-form">
                                <div className="p-5">
                                    <div className="title">
                                        Log in
                                    </div>
                                    <form
                                        method="POST"
                                        className="pt-3"
                                        onSubmit={handleLogin}
                                    >
                                        <div className="form-group custom-form-group">
                                            <input
                                                className="form-control "
                                                autoComplete="off"
                                                placeholder="User ID"
                                                name="username"
                                                type="text"
                                                autoFocus
                                                required
                                                value={uid}
                                                onChange={(e) =>
                                                    setUserId(e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="form-group custom-form-group">
                                            <input
                                                className="form-control-login"
                                                placeholder="Password"
                                                name="password"
                                                type={pwInputType}
                                                autoFocus
                                                required
                                                value={pwd}
                                                onChange={(e) =>
                                                    setUserPwd(e.target.value)
                                                }
                                            />
                                            <div id="div_pw_eye"
                                                 onClick={() => handlePasswordShow()}
                                                 className="fa fa-eye showPasswordButtonLogin" aria-hidden="true"></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-check pl-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={rememberMe}
                                                        defaultChecked={rememberMe}
                                                        onClick={handleRememberMe}
                                                    />
                                                    <label className="form-check-label">
                                                        Remember me
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <a className="custom-href">
                                                    Forgot password?
                                                </a>
                                            </div>
                                        </div>

                                        <div className="pt-4">
                                            <button
                                                type="submit"
                                                className="btn custom-btn-primary"
                                            >
                                                Login &nbsp;
                                                <i className="fa-solid fa-angle-right"/>
                                            </button>
                                        </div>
                                        <div className="pt-3">
                                            {/*<button className="btn custom-btn-secondary">*/}
                                            {/*    Create an Account &nbsp;*/}
                                            {/*    <i className="fa-solid fa-angle-right" />*/}
                                            {/*</button>*/}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="footer-container">© 2023 FiNext Sdn. Bhd.</div>
            </footer>
        </div>
    );
};

export default PgLogin;
