import React, {useState, useEffect, useMemo} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';

import {numberWithCommas} from '../../../general/convertor';
import {confirmAlert} from "react-confirm-alert";
import api from "../../../../apis";
import {
    convertErMsg,
    dealerStatusOptions,
    disableAllEleInDiv,
    handlePopup,
    handleSelectChange, momentTimeFormat, performAntdTimeCheck,
    simpleTimeCheck, specialCharCheck
} from "../../../general/general-tools";
import {usePopupManager} from "react-popup-manager";
import ModalConfirm from "../../public/ModalConfirm";
import ReactDatepick from "../../../general/form-control/react-datepick";
import dayjs from "dayjs";

const Index = () => {
    const {setLoading} = useLoading();


    const [currentMode, setCurrentMode] = useState("");
    const [currentDate, setCurrentDate] = useState(null);

    const [currentGroupId, setCurrentGroupId] = useState("");
    const [currentGroupName, setCurrentGroupName] = useState("");
    const [currentGroupStatus, setCurrentGroupStatus] = useState("");
    const [currentEffForm, setCurrentEffForm] = useState(null);
    const [currentEffTo, setCurrentEffTo] = useState(null);
    const [accessItem, setAccessItem] = useState({});
    const [toggleReset, setToggleReset] = useState(false);

    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();
    const [isDateValid, setIsDateValid] = useState(false);

    useEffect(() => {
            performAntdTimeCheck(currentEffForm,currentEffTo,setCurrentEffTo,popupManager,setIsDateValid);
    }, [currentEffForm,currentEffTo]);


    //Load initial data and mode
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        if (location.state) {
            if (location.state.row) {
                setAccessItem(location.state.perm);
                if (!location.state.perm['edit']) {
                    setTimeout(function () {
                        disableAllEleInDiv("mainDiv");
                    }, 50);
                }

                setCurrentGroupId(location.state.row.dg_id);
                setCurrentGroupName(location.state.row.dg_name);
                setCurrentGroupStatus(location.state.row.dg_status);
                if (location.state.row.dg_date_fr) setCurrentEffForm(location.state.row.dg_date_fr);
                if (location.state.row.dg_date_to) setCurrentEffTo(location.state.row.dg_date_to);

            }
            setCurrentMode(location.state.mode)
        }
    }, [location.state]);


    // Edit
    const handleBackBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "dealergp", {state: {mode: 'back'}});
        setLoading(false);
    };
    const handleRemoveBtn = async () => {
        await setLoading(true);


        popupManager.open(ModalConfirm, {
            title: 'Delete',
            content: 'Are you sure you want to delete?',
            onClose: (...params) => handleRemoveItem(params[0])
        });


        setLoading(false);
    };
    const handleRemoveItem = async (selBoolean) => {
        if (!selBoolean) return;
        api.deleteDealerGroup(currentGroupId).then((result: any) => {
            if (result != false && result.status == 0) {
                setLoading(false);
                handlePopup(true, "Success", "Dealer Group deleted successfully.", popupManager,true,"dealergp",navigate)
            } else{
                setLoading(false);
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
            }
        });
    };


    const handleSaveBtn = async () => {
        await setLoading(true);
        if (currentGroupName == '') {
            handlePopup(true, "Error", "Mandatory Field - Dealer Group Name not entered.", popupManager)
            setLoading(false);
            return false;
        }


        if (currentGroupStatus == null || isNaN(parseInt(currentGroupStatus))) {
            handlePopup(true, "Error", "Mandatory Field - Status not selected.", popupManager)
            setLoading(false);
            return false;
        }


        if (
            currentGroupId != null &&
            currentGroupName.trim().length > 2
        ) {

            const date1 = moment(currentEffForm, 'YYYY-MM-DD').valueOf()
            const date2 = moment(currentEffTo, 'YYYY-MM-DD').valueOf()
            let fromDate = dayjs(currentEffForm).format(momentTimeFormat());
            let toDate = dayjs(currentEffTo).format(momentTimeFormat());

            if(fromDate != "Invalid Date" && toDate != "Invalid Date"){
                if (!isDateValid) {
                    handlePopup(true, "Error", "Please ensure that the ending date is not earlier than the starting date.", popupManager);
                    // setCurrentEffForm("");
                    await setLoading(false);
                    return false;
                }
            }


            let data = {
                "id": currentGroupId,
                "name": currentGroupName,
                "status": currentGroupStatus,
                "date_fr": fromDate == "Invalid Date" ? null : fromDate,
                "date_to": toDate == "Invalid Date" ? null : toDate
            };

            api.updateDealerGroup(data).then((result: any) => {
                if (result != false && result.status == 0) {
                    setLoading(false);
                    handlePopup(true, "Success", "Dealer Group updated successfully.", popupManager, true, "dealergp", navigate)
                } else{
                    setLoading(false);
                    handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                }
            });
        }
        setLoading(false);
    };



    const handleResetBtn = async () => {
        var todayDate = getTodayDate();
        await setLoading(true);
        setCurrentGroupName("");
        setCurrentGroupStatus("");
        setCurrentEffForm('');
        setCurrentEffTo('');
        setToggleReset(!toggleReset)
        setLoading(false);
    };


    const handleAddBtn = async () => {
        await setLoading(true);
        if (currentGroupName == '') {
            handlePopup(true, "Error", "Mandatory Field - Dealer Group Name not entered.", popupManager)
            setLoading(false);
            return false;
        }


        if (currentGroupStatus == null || isNaN(parseInt(currentGroupStatus))) {
            handlePopup(true, "Error", "Mandatory Field - Status not selected.", popupManager)
            setLoading(false);
            return false;
        }

        if (
            currentGroupName.trim().length > 2 &&
            currentGroupStatus != null
        ) {
            const date1 = moment(currentEffForm, 'YYYY-MM-DD').valueOf()
            const date2 = moment(currentEffTo, 'YYYY-MM-DD').valueOf()
            let fromDate = dayjs(currentEffForm).format(momentTimeFormat());
            let toDate = dayjs(currentEffTo).format(momentTimeFormat());

            if (date1 > date2) {
                handlePopup(true, "Error", "Please ensure that the ending date is not earlier than the starting date.", popupManager);
                setCurrentEffForm(null);
                await setLoading(false);
                return false;
            }



            let data = {
                "name": currentGroupName,
                "status": currentGroupStatus,
                "date_fr": fromDate == "Invalid Date" ? null : fromDate,
                "date_to": toDate == "Invalid Date" ? null : toDate
            };

            api.addDealerGroup(data).then((result: any) => {
                if (result != false && result.status == 0) {
                    setLoading(false);
                    handlePopup(true, "Success", "Dealer Group created successfully.", popupManager, true, "dealergp", navigate)
                } else{
                    setLoading(false);
                    handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                }
            });


        } else {
            handlePopup(true, "Error", "Please insert all required fields.", popupManager)
            setLoading(false);
        }
    };


    const getTodayDate = () => {
        return new Date().toLocaleDateString('en-CA', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }


    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Master File
                                Maintenance &gt; Dealer Group - {currentMode}</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Group Name</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="form-control "
                                            autoComplete='off'

                                            type="text"
                                            placeholder="e.g. KL Group 1"
                                            aria-label=""
                                            value={currentGroupName}
                                            onChange={(e) => setCurrentGroupName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentGroupName,popupManager)}

                                        />
                                    </div>
                                    <b className='mandatory-red'>*</b>


                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1">Status</div>
                                    <div className="label col-md-11">
                                        <select
                                            className="form-control"
                                            id="cur_nation"
                                            aria-label=""
                                            value={currentGroupStatus}
                                            onChange={e => handleSelectChange(e.target.value, setCurrentGroupStatus, false)}
                                        >
                                            {dealerStatusOptions()}

                                        </select>
                                    </div>
                                    <b className='mandatory-red'>*</b>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Effective From</div>
                                    <div className="label col-md-5">

                                        <ReactDatepick
                                            value={currentEffForm}
                                            dateSetter={setCurrentEffForm}
                                            resetToggle={toggleReset}
                                        />
                                    </div>
                                    <div className="col-md-1">To</div>
                                    <div className="col-md-5">

                                        <ReactDatepick
                                            value={currentEffTo}
                                            dateSetter={setCurrentEffTo}
                                            resetToggle={toggleReset}
                                        />
                                    </div>


                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1"></div>

                                    {currentMode.toLowerCase() == 'edit' ?
                                        <div className="label col-md-11 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                            </button>
                                            {accessItem['delete'] ? <button className="btn btn-primary "
                                                                            onClick={() => handleRemoveBtn()}>Delete</button> : ""}
                                            {accessItem['edit'] ? <button className="btn btn-primary "
                                                                          onClick={() => handleSaveBtn()}>Save</button> : ""}

                                        </div> :

                                        <div className="label col-md-11 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                            </button>
                                            <button className="btn btn-primary "
                                                    onClick={() => handleResetBtn()}>Reset
                                            </button>
                                            <button className="btn btn-primary " onClick={() => handleAddBtn()}>Save
                                            </button>
                                        </div>
                                    }


                                </div>


                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
