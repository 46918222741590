import React, {useState, useEffect, useMemo} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';

import DropdownSelect from '../../../general/form-control/dropdown-select';
import ReactTable from '../../../general/form-control/react-table';
import statusText from '../../../../constants/statusText';
import TreeSelect from '../../../general/form-control/tree-select';
import {parseAPIResutlToData} from '../../../general/form-control/tree-select/helper';
import DataTable from 'react-data-table-component';
import {useLocation, useNavigate} from 'react-router-dom';

import {numberWithCommas} from '../../../general/convertor';
import {
    convertPositiveNumberHundred,
    handlePopup,
    handleDecimalNumChangeWifCursor,
    handleSelectChange,
    userTypeCategory, handleDisableChkBxByVal, disableAllEleInDiv, convertErMsg
} from "../../../general/general-tools";
import {usePopupManager} from "react-popup-manager";
import ModalConfirm from "../../public/ModalConfirm";

const Index = () => {
    const {setLoading} = useLoading();


    const [currentMode, setCurrentMode] = useState("");
    const [currentUserId, setCurrentUserId] = useState("");
    const [currentUserTypeName, setCurrentUserTypeName] = useState("");
    const [currentUserTypeDesc, setCurrentUserTypeDesc] = useState("");
    const [currentUserName, setCurrentUserName] = useState("");

    const [elBuy, setElBuy] = useState(0.00);
    const [elBuyChkBox, setElBuyChkBox] = useState(false);
    const [elSell, setElSell] = useState(0.00);
    const [elSellChkBox, setElSellChkBox] = useState(false);
    const [elTotal, setElTotal] = useState(0.00);
    const [elTotalChkBox, setElTotalChkBox] = useState(false);
    const [elNett, setElNett] = useState(0.00);
    const [elNettChkBox, setElNettChkBox] = useState(false);
    const [elIDSS, setElIDSS] = useState(0.00);
    const [elIDSSChkBox, setElIDSSChkBox] = useState(false);
    const [elFST, setElFST] = useState(0.00);
    const [elFSTChkBox, setElFSTChkBox] = useState(false);

    const [topUpBuy, settopUpBuy] = useState(0.00);
    const [topUpBuyChkBox, settopUpBuyChkBox] = useState(false);
    const [topUpSell, settopupSell] = useState(0.00);
    const [topUpSellChkBox, settopUpSellChkBox] = useState(false);
    const [topUpTotal, settopUpTotal] = useState(0.00);
    const [topUpTotalChkBox, settopUpTotalChkBox] = useState(false);
    const [topUpNett, settopUpNett] = useState(0.00);
    const [topUpNettChkBox, settopUpNettChkBox] = useState(false);
    const [topUpIDSS, settopUpIDSS] = useState(0.00);
    const [topUpIDSSChkBox, settopUpIDSSChkBox] = useState(false);
    const [topUpFST, settopUpFST] = useState(0.00);
    const [topUpFSTChkBox, settopUpFSTChkBox] = useState(false);
    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();
    const [accessItem, setAccessItem] = useState({});

    //Load initial data and mode
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        if (location.state) {
            if (location.state.row) {
                setCurrentUserId(location.state.row.ut_id);
                setCurrentUserTypeName(location.state.row.ut_name);
                handleInitList(location.state.row.ut_id);
                setAccessItem(location.state.perm);
                if (!location.state.perm['edit']) {
                    setTimeout(function () {
                        disableAllEleInDiv("mainDiv");

                    }, 50);
                }
            }
            setCurrentMode(location.state.mode)
        }
    }, [location.state]);

    const handleInitList = async (userId) => {

        setLoading(true);
        api.getAuthLimitList({"id": parseInt(userId)}).then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }
            if (result.data) {
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    let tempData = [];
                    for (let i = 0; i < dataList.length; i++) {
                        let tempEle = {};
                        tempEle['ut_name'] = dataList[i].ut_name;
                        tempEle['ut_id'] = dataList[i].ut_id;

                        let explmt = JSON.parse(dataList[i].explmt) ?? [];
                        let topup = JSON.parse(dataList[i].topup) ?? [];

                        for (let j = 0; j < explmt.length; j++) {
                            if (explmt[j]['exp_lmt_type'] == 1) {
                                tempEle['explmt_buy'] = setElBuy(convertPositiveNumberHundred(explmt[j]['exp_lmt_amount'] ?? 0));
                                tempEle['explmt_buy_enable'] = setElBuyChkBox(explmt[j]['exp_lmt_enable'] ?? false);
                            } else if (explmt[j]['exp_lmt_type'] == 2) {
                                tempEle['explmt_sell'] = setElSell(convertPositiveNumberHundred(explmt[j]['exp_lmt_amount'] ?? 0));
                                tempEle['explmt_sell_enable'] = setElSellChkBox(explmt[j]['exp_lmt_enable'] ?? false);
                            } else if (explmt[j]['exp_lmt_type'] == 3) {
                                tempEle['explmt_nett'] = setElNett(convertPositiveNumberHundred(explmt[j]['exp_lmt_amount'] ?? 0));
                                tempEle['explmt_nett_enable'] = setElNettChkBox(explmt[j]['exp_lmt_enable'] ?? false);
                            } else if (explmt[j]['exp_lmt_type'] == 4) {
                                tempEle['explmt_gross'] = setElTotal(convertPositiveNumberHundred(explmt[j]['exp_lmt_amount'] ?? 0));
                                tempEle['explmt_gross_enable'] = setElTotalChkBox(explmt[j]['exp_lmt_enable'] ?? false);
                            } else if (explmt[j]['exp_lmt_type'] == 5) {
                                tempEle['explmt_idss'] = setElIDSS(convertPositiveNumberHundred(explmt[j]['exp_lmt_amount'] ?? 0));
                                tempEle['explmt_idss_enable'] = setElIDSSChkBox(explmt[j]['exp_lmt_enable'] ?? false);
                            } else if (explmt[j]['exp_lmt_type'] == 6) {
                                tempEle['explmt_idss'] = setElFST(convertPositiveNumberHundred(explmt[j]['exp_lmt_amount'] ?? 0));
                                tempEle['explmt_idss_enable'] = setElFSTChkBox(explmt[j]['exp_lmt_enable'] ?? false);
                            }
                        }
                        ;

                        for (let j = 0; j < topup.length; j++) {
                            if (topup[j]['topup_type'] == 1) {
                                tempEle['topup_buy'] = settopUpBuy(convertPositiveNumberHundred(topup[j]['topup_amount'] ?? 0));
                                tempEle['topup_buy_enable'] = settopUpBuyChkBox(topup[j]['topup_enable'] ?? false);
                            } else if (topup[j]['topup_type'] == 2) {
                                tempEle['topup_sell'] = settopupSell(convertPositiveNumberHundred(topup[j]['topup_amount'] ?? 0));
                                tempEle['topup_sell_enable'] = settopUpSellChkBox(topup[j]['topup_enable'] ?? false);
                            } else if (topup[j]['topup_type'] == 3) {
                                tempEle['topup_nett'] = settopUpNett(convertPositiveNumberHundred(topup[j]['topup_amount'] ?? 0));
                                tempEle['topup_nett_enable'] = settopUpNettChkBox(topup[j]['topup_enable'] ?? false);
                            } else if (topup[j]['topup_type'] == 4) {
                                tempEle['topup_gross'] = settopUpTotal(convertPositiveNumberHundred(topup[j]['topup_amount'] ?? 0));
                                tempEle['topup_gross_enable'] = settopUpTotalChkBox(topup[j]['topup_enable'] ?? false);
                            } else if (topup[j]['topup_type'] == 5) {
                                tempEle['topup_idss'] = settopUpIDSS(convertPositiveNumberHundred(topup[j]['topup_amount'] ?? 0));
                                tempEle['topup_idss_enable'] = settopUpIDSSChkBox(topup[j]['topup_enable'] ?? false);
                            } else if (topup[j]['topup_type'] == 6) {
                                tempEle['topup_idss'] = settopUpFST(convertPositiveNumberHundred(topup[j]['topup_amount'] ?? 0));
                                tempEle['topup_idss_enable'] = settopUpFSTChkBox(topup[j]['topup_enable'] ?? false);
                            }
                        }
                        ;
                        tempData.push(tempEle);
                    }


                }
            }
        });
        setLoading(false);
    };

    // Edit
    const handleBackBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "auth_limit", {state: {mode: 'back'}});
        setLoading(false);
    };


    const handleSaveBtn = async () => {
        await setLoading(true);


        let param = {
            "id": currentUserId,
            "explmt": "[" +
                "{\"risktype\":1,\"riskenable\":" + elBuyChkBox + ",\"amount\":" + elBuy.toString().replace(/,/g, '') + "}," +
                "{\"risktype\":2,\"riskenable\":" + elSellChkBox + ",\"amount\":" + elSell.toString().replace(/,/g, '') + "}," +
                "{\"risktype\":3,\"riskenable\":" + elNettChkBox + ",\"amount\":" + elNett.toString().replace(/,/g, '') + "}," +
                "{\"risktype\":4,\"riskenable\":" + elTotalChkBox + ",\"amount\":" + elTotal.toString().replace(/,/g, '') + "}," +
                "{\"risktype\":5,\"riskenable\":" + elIDSSChkBox + ",\"amount\":" + elIDSS.toString().replace(/,/g, '') + "}," +
                "{\"risktype\":6,\"riskenable\":" + elFSTChkBox + ",\"amount\":" + elFST.toString().replace(/,/g, '') + "}" +
                "]",
            "topup": "[" +
                "{\"risktype\":1,\"riskenable\":" + topUpBuyChkBox + ",\"amount\":" + topUpBuy.toString().replace(/,/g, '') + "}," +
                "{\"risktype\":2,\"riskenable\":" + topUpSellChkBox + ",\"amount\":" + topUpSell.toString().replace(/,/g, '') + "}," +
                "{\"risktype\":3,\"riskenable\":" + topUpNettChkBox + ",\"amount\":" + topUpNett.toString().replace(/,/g, '') + "}," +
                "{\"risktype\":4,\"riskenable\":" + topUpTotalChkBox + ",\"amount\":" + topUpTotal.toString().replace(/,/g, '') + "}," +
                "{\"risktype\":5,\"riskenable\":" + topUpIDSSChkBox + ",\"amount\":" + topUpIDSS.toString().replace(/,/g, '') + "}," +
                "{\"risktype\":6,\"riskenable\":" + topUpFSTChkBox + ",\"amount\":" + topUpFST.toString().replace(/,/g, '') + "}" +
                "]"
        }

        api.updateAuthLimitUserType(param).then((result: any) => {
            if (result != false && result.status == 0) {
                handlePopup(true, "Success", "Authorization updated successfully.", popupManager, true, "auth_limit", navigate)

            } else handlePopup(true, "Error", convertErMsg(result.message), popupManager)
            // } else handlePopup(true,"Error","Please insert all required fields.",popupManager)
            setLoading(false);
        });

    };


    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; User
                                Maintenance &gt; Authorization Limit - {currentMode}</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1">User Type</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="e.g. Super User"
                                            aria-label=""
                                            disabled={true}
                                            value={currentUserTypeName}
                                            onChange={(e) => setCurrentUserTypeName(e.target.value)}
                                        />

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Exposure Limit Authorization Level</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">
                                        <label className="" htmlFor="ElBuyChkBox">
                                            <input
                                            className="checkboxMarginRight"
                                            name="ElBuyChkBox" type="checkbox" id="ElBuyChkBox"
                                            onChange={e => handleDisableChkBxByVal(e.target.checked, elBuy, setElBuyChkBox)}
                                            checked={elBuyChkBox}
                                        />Buy Exposure</label>
                                    </div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={elBuy}
                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, setElBuy, false, elBuyChkBox, setElBuyChkBox)}
                                        />

                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">
                                        <label className="" htmlFor="elSellChkBox">
                                            <input className="checkboxMarginRight"
                                                   name="elSellChkBox" type="checkbox" id="elSellChkBox"
                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, elSell, setElSellChkBox)}
                                                   checked={elSellChkBox}
                                            />Sell Exposure</label>
                                    </div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"

                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={elSell}
                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, setElSell, false, elSellChkBox, setElSellChkBox)}
                                        />

                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">
                                        <label className="" htmlFor="elTotalChkBox">
                                            <input className="checkboxMarginRight"
                                                   name="elTotalChkBox" type="checkbox" id="elTotalChkBox"
                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, elTotal, setElTotalChkBox)}
                                                   checked={elTotalChkBox}
                                            />Total Exposure</label>
                                    </div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"

                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={elTotal}
                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, setElTotal, false, elTotalChkBox, setElTotalChkBox)}
                                        />

                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">
                                        <label className="" htmlFor="elNettChkBox">
                                            <input className="checkboxMarginRight"
                                                   name="elNettChkBox" type="checkbox" id="elNettChkBox"
                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, elNett, setElNettChkBox)}
                                                   checked={elNettChkBox}
                                            />Nett Exposure</label>
                                    </div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"

                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={elNett}
                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, setElNett, false, elNettChkBox, setElNettChkBox)}
                                        />

                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">
                                        <label className="" htmlFor="elIDSSChkBox">
                                            <input className="checkboxMarginRight"
                                                   name="elIDSSChkBox" type="checkbox" id="elIDSSChkBox"
                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, elIDSS, setElIDSSChkBox)}
                                                   checked={elIDSSChkBox}
                                            />IDSS Exposure</label>
                                    </div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"

                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={elIDSS}
                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, setElIDSS, false, elIDSSChkBox, setElIDSSChkBox)}
                                        />

                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">
                                        <label className="" htmlFor="elFSTchkBox">
                                            <input className="checkboxMarginRight"
                                                   name="elFSTchkBox" type="checkbox" id="elFSTchkBox"
                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, elFST, setElFSTChkBox)}
                                                   checked={elFSTChkBox}
                                            />FST Exposure</label>
                                    </div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={elFST}
                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, setElFST, false, elFSTChkBox, setElFSTChkBox)}
                                        />

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Topup Limit Authorization Level</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">
                                        <label className="" htmlFor="topUpBuyChkBox"> <input
                                            className="checkboxMarginRight"
                                            name="topUpBuyChkBox" type="checkbox" id="topUpBuyChkBox"
                                            onChange={e => handleDisableChkBxByVal(e.target.checked, topUpBuy, settopUpBuyChkBox)}
                                            checked={topUpBuyChkBox}
                                        />Buy Exposure</label>
                                    </div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={topUpBuy}
                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, settopUpBuy, false, topUpBuyChkBox, settopUpBuyChkBox)}
                                        />

                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">
                                        <label className="" htmlFor="topUpSellChkBox">
                                            <input className="checkboxMarginRight"
                                                   name="topUpSellChkBox" type="checkbox" id="topUpSellChkBox"
                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, topUpSell, settopUpSellChkBox)}
                                                   checked={topUpSellChkBox}
                                            />Sell Exposure</label>
                                    </div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={topUpSell}
                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, settopupSell, false, topUpSellChkBox, settopUpSellChkBox)}
                                        />

                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">
                                        <label className="" htmlFor="topUpTotalChkBox">
                                            <input className="checkboxMarginRight"
                                                   name="topUpTotalChkBox" type="checkbox" id="topUpTotalChkBox"
                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, topUpTotal, settopUpTotalChkBox)}
                                                   checked={topUpTotalChkBox}
                                            />Total Exposure</label>
                                    </div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"

                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={topUpTotal}
                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, settopUpTotal, false, topUpTotalChkBox, settopUpTotalChkBox)}
                                        />

                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">
                                        <label className="" htmlFor="topUpNettChkBox">
                                            <input className="checkboxMarginRight"
                                                   name="topUpNettChkBox" type="checkbox" id="topUpNettChkBox"
                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, topUpNett, settopUpNettChkBox)}
                                                   checked={topUpNettChkBox}
                                            />Nett Exposure</label>
                                    </div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"

                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={topUpNett}
                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, settopUpNett, false, topUpNettChkBox, settopUpNettChkBox)}
                                        />

                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">
                                        <label className="" htmlFor="topUpIDSSChkBox">
                                            <input className="checkboxMarginRight"
                                                   name="topUpIDSSChkBox" type="checkbox" id="topUpIDSSChkBox"
                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, topUpIDSS, settopUpIDSSChkBox)}
                                                   checked={topUpIDSSChkBox}
                                            />IDSS Exposure</label>
                                    </div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"

                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={topUpIDSS}
                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, settopUpIDSS, false, topUpIDSSChkBox, settopUpIDSSChkBox)}
                                        />

                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">
                                        <label className="" htmlFor="topUpFSTChkBox">
                                            <input className="checkboxMarginRight"
                                                   name="topUpFSTChkBox" type="checkbox" id="topUpFSTChkBox"
                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, topUpFST, settopUpFSTChkBox)}
                                                   checked={topUpFSTChkBox}
                                            />FST Exposure</label>
                                    </div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"

                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={topUpFST}
                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, settopUpFST, false, topUpFSTChkBox, settopUpFSTChkBox)}
                                        />

                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                    <div className="input-container col-md-12 fullWidthSearch">

                        <div className="label col-md-1"></div>

                        <div className="label col-md-11 btn_search_padding">
                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>
                            {accessItem['edit'] ?
                                <>
                                    <button className="btn btn-primary "
                                            onClick={() => handleInitList(currentUserId)}>Reset
                                    </button>
                                    <button className="btn btn-primary " onClick={() => handleSaveBtn()}>Save</button>
                                </> :
                                ""
                            }
                        </div>

                    </div>
                </div>


            </div>
        </div>
    );
};

export default Index;
