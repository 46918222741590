import {handleRequestGet, handleRequestPost} from '../axios';

export const getProfileList = async (param) => {
    let url = '/profile';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);

        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const createProfile = async (param) => {
    let url = '/profile/create';
    let result = await handleRequestPost(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const updateProfile = async (param) => {
    let url = '/profile/update';
    let result = await handleRequestPost(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const validateUserName = async (param) => {
    let url = '/username/validate';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getUserItems = async (param) => {
    let url = '/users';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);

        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const saveUserMarketMatrix = async (param) => {
    let url = '/users/market/update';
    let result = await handleRequestPost(url, param);

    try {
        let paresedResult = JSON.parse(result);

        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
