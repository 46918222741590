import React from "react";
import {getFunctionMenus, handleToggleCheckAllCurentClass, handleToggleChildControl} from "./UserAccessMatTools";

export function renderOperationForm(userId, userName) {
    let renderList = getFunctionMenus();


    const handleTitleRow = () => {
        if (userId != "") return <div><b>User ID : </b>{userId} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b>Name :</b> {userName}</div>;
        return <h5 className="m-0 font-weight-bold text-primary">Access Matrix </h5>;
    }


    return (
        <div className="col-xl-12 col-lg-12" id="operationDiv">
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    {handleTitleRow()}
                </div>
                <div className="card-body">
                    <div className="col-12 search-container ">
                        <div className="input-container col-md-12 fullWidthSearch">

                            <div className="label col-md-5"></div>
                            <div className="label col-md-1">All</div>
                            <div className="label col-md-1">View</div>
                            <div className="label col-md-1">Add</div>
                            <div className="label col-md-1">Edit</div>
                            <div className="label col-md-1">Delete</div>
                        </div>
                        {/*Level 1*/}
                        {renderList.map(function (row, i) {
                            let row_label = row.label;
                            let row_label_to_lower = (row_label).replaceAll(' ', '').toLowerCase();
                            let perm_parent = row.parent_perm ?? "";
                            let firstLevelChild = row.subMenu ?? [];
                            let cssClass = 'am-input-parent';
                            if (firstLevelChild.length == 0) cssClass = 'am-input-parent-single';
                            let renderedList = (
                                    <>
                                        <div className={"input-container col-md-12 fullWidthSearch " + cssClass}>
                                            <div className="label col-md-5">{row_label}</div>
                                            {perm_parent[0] == "1" ? (<>
                                                <div className="label col-md-1">
                                                    <input
                                                        id={row.id + "_" + row_label_to_lower + '_all_header'}
                                                        type='checkbox'
                                                        className={row_label_to_lower + '_all_header'}
                                                        onClick={() => handleToggleCheckAllCurentClass(row_label_to_lower + '_all')}
                                                    />
                                                </div>
                                            </>) : (<>
                                                <div className="label col-md-1">
                                                </div>
                                            </>)}
                                            {perm_parent[1] == "1" ? (<>
                                                <div className="label col-md-1">
                                                    <input
                                                        id={row.id + '_view_header'}
                                                        type='checkbox'
                                                        className={row_label_to_lower + '_view_header'}
                                                        onClick={() => handleToggleCheckAllCurentClass(row_label_to_lower + '_view')}
                                                    />
                                                </div>
                                            </>) : (<>
                                                <div className="label col-md-1">
                                                </div>
                                            </>)}
                                            {perm_parent[2] == "1" ? (<>
                                                <div className="label col-md-1">
                                                    <input
                                                        id={row.id + '_add_header'}
                                                        type='checkbox'
                                                        className={row_label_to_lower + '_add_header'}
                                                        onClick={() => handleToggleCheckAllCurentClass(row_label_to_lower + '_add')}
                                                    />
                                                </div>
                                            </>) : (<>
                                                <div className="label col-md-1">
                                                </div>
                                            </>)}
                                            {perm_parent[3] == "1" ? (<>
                                                <div className="label col-md-1">
                                                    <input
                                                        id={row.id + '_edit_header'}
                                                        type='checkbox'
                                                        className={row_label_to_lower + '_edit_header'}
                                                        onClick={() => handleToggleCheckAllCurentClass(row_label_to_lower + '_edit')}
                                                    />
                                                </div>
                                            </>) : (<>
                                                <div className="label col-md-1">
                                                </div>
                                            </>)}
                                            {perm_parent[4] == "1" ? (<>
                                                <div className="label col-md-1">
                                                    <input
                                                        id={row.id + '_delete_header'}
                                                        type='checkbox'
                                                        className={row_label_to_lower + '_delete_header'}
                                                        onClick={() => handleToggleCheckAllCurentClass(row_label_to_lower + '_delete')}
                                                    />
                                                </div>
                                            </>) : (<>
                                                <div className="label col-md-1">
                                                </div>
                                            </>)}
                                        </div>
                                        {/*Level 2*/}
                                        {firstLevelChild.map(function (rows, i) {
                                            if(rows['id'] >=39 && rows['id'] <= 54) return;
                                            let level_1_row_label = rows.label.replaceAll(/[\(\)]/g, "");
                                            let level_1_row_label_to_lower = (level_1_row_label).replaceAll(' ', '').replaceAll(' ', '').replaceAll(/[\(\)]/g, "").toLowerCase();
                                            if (row_label_to_lower.includes("riskmgmt") || row_label_to_lower.includes("masterfile")) level_1_row_label_to_lower = row_label_to_lower + "-" + level_1_row_label_to_lower;


                                            let level_1_perm = rows.perm ?? perm_parent;
                                            let secondLevelChild = rows.subMenu2 ?? [];

                                            let levelOneChild = (
                                                <>
                                                    <div
                                                        className="input-container col-md-12 fullWidthSearch am-input-child1-div">
                                                        <div className=" col-md-5 am-input-child1">
                                                            <b>{level_1_row_label}</b></div>
                                                        {level_1_perm[0] == "1" ? (<div className="label col-md-1">
                                                            <input
                                                                id={rows.id + "_" + level_1_row_label_to_lower + '_all'}
                                                                className={row_label_to_lower + '_all' + ' ' + level_1_row_label_to_lower + '_all' + ' ' + level_1_row_label_to_lower + '_all_subheader'}
                                                                onClick={() => handleToggleChildControl(row_label_to_lower + '_all' + "_" + level_1_row_label_to_lower + '_all')}
                                                                type='checkbox'
                                                            />
                                                        </div>) : ((<div className="label col-md-1"></div>))}
                                                        {level_1_perm[1] == "1" ? (<div className="label col-md-1"><input
                                                            id={rows.id + "_" + level_1_row_label_to_lower + '_view'}
                                                            className={"am-checkkbox-select-all " + row_label_to_lower + '_view' + ' ' + level_1_row_label_to_lower + '_view' + ' ' + level_1_row_label_to_lower + '_view_subheader'}
                                                            onClick={() => handleToggleChildControl(row_label_to_lower + '_view' + "_" + level_1_row_label_to_lower + '_view')}
                                                            type='checkbox'
                                                        />
                                                        </div>) : (<div className="label col-md-1"></div>)}
                                                        {level_1_perm[2] == "1" ? (<div className="label col-md-1">
                                                            <input
                                                                id={rows.id + "_" + level_1_row_label_to_lower + '_add'}
                                                                className={row_label_to_lower + '_add' + ' ' + level_1_row_label_to_lower + '_add' + ' ' + level_1_row_label_to_lower + '_add_subheader'}
                                                                onClick={() => handleToggleChildControl(row_label_to_lower + '_add' + "_" + level_1_row_label_to_lower + '_add')}
                                                                type='checkbox'
                                                            />
                                                        </div>) : (<div className="label col-md-1"></div>)}
                                                        {level_1_perm[3] == "1" ? (<div className="label col-md-1">
                                                            <input
                                                                id={rows.id + "_" + level_1_row_label_to_lower + '_edit'}
                                                                className={row_label_to_lower + '_edit' + ' ' + level_1_row_label_to_lower + '_edit' + ' ' + level_1_row_label_to_lower + '_edit_subheader'}
                                                                onClick={() => handleToggleChildControl(row_label_to_lower + '_edit' + "_" + level_1_row_label_to_lower + '_edit')}
                                                                type='checkbox'
                                                            />
                                                        </div>) : (<div className="label col-md-1"></div>)}
                                                        {level_1_perm[4] == "1" ? (<div className="label col-md-1">
                                                            <input
                                                                id={rows.id + "_" + level_1_row_label_to_lower + '_delete'}
                                                                className={row_label_to_lower + '_delete' + ' ' + level_1_row_label_to_lower + '_delete' + ' ' + level_1_row_label_to_lower + '_delete_subheader'}
                                                                onClick={() => handleToggleChildControl(row_label_to_lower + '_delete' + "_" + level_1_row_label_to_lower + '_delete')}
                                                                type='checkbox'
                                                            />
                                                        </div>) : (<div className="label col-md-1"></div>)}
                                                    </div>

                                                    {/*Level 3*/}
                                                    {secondLevelChild.map(function (childRows, i) {
                                                        let level_2_row_id = childRows.id;
                                                        let level_2_row_label = childRows.label.replaceAll(/[\(\)]/g, "");
                                                        let level_2_row_label_to_lower = (level_2_row_label).replaceAll(' ', '').replaceAll(/[\(\)]/g, "").toLowerCase();
                                                        let level_2_perm = childRows.perm ?? perm_parent;
                                                        let isSpecialPerm = childRows.specialPerm ?? false;


                                                        if (level_1_row_label_to_lower.includes("broadcastmaintenance") || level_1_row_label_to_lower.includes("clientamendment")) level_2_row_label_to_lower = level_1_row_label_to_lower + "-" + level_2_row_label_to_lower;

                                                        let levelTwoChild = (
                                                            <>
                                                                <div className="input-container col-md-12 fullWidthSearch ">
                                                                    {isSpecialPerm == false ?
                                                                        <div className=" col-md-5 am-input-child2 ">{level_2_row_label.replaceAll('EL', '').replaceAll('TS', '')}</div>
                                                                        :
                                                                        <div className=" col-md-5 am-input-child3 ">{level_2_row_label.replaceAll('EL', '').replaceAll('TS', '')}</div>
                                                                    }

                                                                    {level_2_perm[0] == "1" ? (
                                                                        <div className="label col-md-1">
                                                                            <input
                                                                                id={level_2_row_id + "_" + level_2_row_label_to_lower + '_all'}
                                                                                className={row_label_to_lower + '_all' + ' ' + level_2_row_label_to_lower + '_all' + ' ' + level_1_row_label_to_lower + '_all'}
                                                                                onClick={() => handleToggleChildControl(row_label_to_lower + '_all_' + level_2_row_label_to_lower + '_all_' + level_1_row_label_to_lower + '_all')}

                                                                                type='checkbox'
                                                                            />
                                                                        </div>) : (<div className="label col-md-1"></div>)}
                                                                    {level_2_perm[1] == "1" ? (
                                                                        <div className="label col-md-1">
                                                                            <input
                                                                                id={level_2_row_id + "_" + level_2_row_label_to_lower + '_view'}
                                                                                className={row_label_to_lower + '_view' + ' ' + level_2_row_label_to_lower + '_view' + ' ' + level_1_row_label_to_lower + '_view'}
                                                                                onClick={() => handleToggleChildControl(row_label_to_lower + '_view_' + level_2_row_label_to_lower + '_view_' + level_1_row_label_to_lower + '_view')}

                                                                                type='checkbox'
                                                                            />
                                                                        </div>) : (<div className="label col-md-1"></div>)}
                                                                    {level_2_perm[2] == "1" ? (
                                                                        <div className="label col-md-1">
                                                                            <input
                                                                                id={level_2_row_id + "_" + level_2_row_label_to_lower + '_add'}
                                                                                className={row_label_to_lower + '_add' + ' ' + level_2_row_label_to_lower + '_add' + ' ' + level_1_row_label_to_lower + '_add'}
                                                                                onClick={() => handleToggleChildControl(row_label_to_lower + '_add_' + level_2_row_label_to_lower + '_add_' + level_1_row_label_to_lower + '_add')}

                                                                                type='checkbox'
                                                                            />
                                                                        </div>) : (<div className="label col-md-1"></div>)}
                                                                    {level_2_perm[3] == "1" ? (
                                                                        <div className="label col-md-1">
                                                                            <input
                                                                                id={level_2_row_id + "_" + level_2_row_label_to_lower + '_edit'}
                                                                                className={row_label_to_lower + '_edit' + ' ' + level_2_row_label_to_lower + '_edit' + ' ' + level_1_row_label_to_lower + '_edit'}
                                                                                onClick={() => handleToggleChildControl(row_label_to_lower + '_edit_' + level_2_row_label_to_lower + '_edit_' + level_1_row_label_to_lower + '_edit')}

                                                                                type='checkbox'
                                                                            />
                                                                        </div>) : (<div className="label col-md-1"></div>)}
                                                                    {level_2_perm[4] == "1" ? (
                                                                        <div className="label col-md-1">
                                                                            <input
                                                                                id={level_2_row_id + "_" + level_2_row_label_to_lower + '_delete'}
                                                                                className={row_label_to_lower + '_delete' + ' ' + level_2_row_label_to_lower + '_delete' + ' ' + level_1_row_label_to_lower + '_delete'}
                                                                                onClick={() => handleToggleChildControl(row_label_to_lower + '_delete_' + level_2_row_label_to_lower + '_delete_' + level_1_row_label_to_lower + '_delete')}

                                                                                type='checkbox'
                                                                            />
                                                                        </div>) : (<div className="label col-md-1"></div>)}


                                                                </div>

                                                            </>


                                                        )

                                                        return levelTwoChild;

                                                    })}

                                                </>
                                            )
                                            return levelOneChild;

                                        })}

                                    </>
                                )
                            ;
                            return renderedList;


                        })}
                    </div>
                </div>

            </div>

        </div>
    )

}
