import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react';
import '../../../../asset/styles/customer-profile-style.css';
import api from '../../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    convertPositiveNumberHundred, dateTimeFormat, getNavItemById,
    handlePositiveNumberChange,
    handlePositiveNumberHundredChange,
    handleSelectChange
} from "../../../../general/general-tools";
import ReactTable from "../../../../general/form-control/react-table";
import dayjs from "dayjs";



const DealerGroup = React.memo(props => {

    let setCurrentPage = props['setCurrentPage']== null ? '' : props['setCurrentPage'];
    let setCurrentPerPage = props['setCurrentPerPage']== null ? '' : props['setCurrentPerPage'];
    let currentPage = props['tableData']== null ? '' : props['currentPage'];
    let currentPerPage = props['tableData']== null ? '' : props['currentPerPage'];
    let totalRecords = props['tableData']== null ? '' : props['totalRecords'];
    let setCurrentGroupId = props['setCurrentGroupId']== null ? '' : props['setCurrentGroupId'];

    let tableData = props['tableData']== null ? '' : props['tableData'];
    let popupManager = props['popupManager']== null ? '' : props['popupManager'];


    const handleOnclick = async (row) => {
        await setLoading(true);

        if(setCurrentGroupId)setCurrentGroupId(row.ut_id ?? 0);

        // navigate("/" + process.env.REACT_APP_URL_PREFIX + "br_entry_opr", {
        //     state: {
        //         row: row,
        //         perm: actualPerm,
        //         mode: 'Edit'
        //     }
        // });
        setLoading(false);
    };


    const columns = useMemo(
        () => [
            {
                id: row => row.dg_date,
                name: 'Group Name',
                selector: row => row.dg_name,
                sortable: true,
                // center: true,
                cell: (row: { dg_name: any }) => (
                    <>
                        <a className="tableItemsHlink" onClick={() => handleOnclick(row)}>{row.dg_name}</a>
                    </>
                )
            },
            {
                name: 'Effective From',
                selector: row => "N/A",
                sortable: true,

            },
            {
                name: 'Effective To',
                selector: row => "N/A",
                sortable: true,

            },
            {
                name: 'Expired Date',
                selector: row => "N/A",
                sortable: true,

            }
        ],
        []
    );


    return  <>

        <ReactTable
            rowClick={setCurrentPage}
            perPageQty={setCurrentPerPage}
            // handleSelect={setCurrentUsrTypeId}
            columns={columns}
            data={tableData}
            pagination={{
                currentPage: currentPage,
                totalRecords: totalRecords,
                currentPerPage: currentPerPage,
                // selectableHandle: {setCurrentUsrTypeId},
            }}
        />

    </>
});
export default DealerGroup;
