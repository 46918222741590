import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react';
import '../../../../asset/styles/customer-profile-style.css';
import {
    cursorToFront, handleDecimalNumChangeWifCursor, checkZeroMinMaxULimit
} from "../../../../general/general-tools";



const UpperLowerLimit = React.memo(props => {
    let uLLBLUp = props['uLLBLUp'] == null ? '' : props['uLLBLUp'];
    let setULLBLUp = props['setULLBLUp'] == null ? '' : props['setULLBLUp'];
    let uLLBLDown = props['uLLBLDown'] == null ? '' : props['uLLBLDown'];
    let setULLBLDown = props['setULLBLDown'] == null ? '' : props['setULLBLDown'];
    let uLLBLReadOnly = props['uLLBLReadOnly'] == null ? '' : props['uLLBLReadOnly'];

    let uLLDBTUp = props['uLLDBTUp'] == null ? '' : props['uLLDBTUp'];
    let setULLDBTUp = props['setULLDBTUp'] == null ? '' : props['setULLDBTUp'];
    let uLLDBTDown = props['uLLDBTDown'] == null ? '' : props['uLLDBTDown'];
    let setULLDBTDown = props['setULLDBTDown'] == null ? '' : props['setULLDBTDown'];
    let uLLDBTDownReadOnly = props['uLLDBTDownReadOnly'] == null ? '' : props['uLLDBTDownReadOnly'];
    let popupManager = props['popupManager']== null ? '' : props['popupManager'];



    return  <>

        <div className="input-container col-md-12 fullWidthSearch exp-input-parent-single">
            <b>Upper / Lower Limit</b>
        </div>

        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-2"></div>
            <div className="label col-md-3">Upper Limit %</div>
            <div className="label col-md-3">Lower Limit %</div>
            <div className="label col-md-3"></div>
        </div>
        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-2">Board Lot</div>
            <div className="label col-md-3">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={uLLBLReadOnly}
                    value={uLLBLUp}
                    min={1.00}
                    defaultValue={1.00}
                    max={999999999.999}

                    onBlur={(e) => checkZeroMinMaxULimit(e.target.value, setULLDBTUp,uLLBLDown,uLLBLUp,setULLBLDown,setULLBLUp,'max')}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, setULLBLUp)}

                />
            </div>

            <div className="label col-md-3">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={uLLDBTDownReadOnly}
                    value={uLLBLDown}
                    max={999999999.999}
                    onBlur={(e) => checkZeroMinMaxULimit(e.target.value, setULLBLDown,uLLBLDown,uLLBLUp,setULLBLDown,setULLBLUp,'min')}

                    // onBlur={((e) => checkZeroOnBlur(e.target.value, setULLBLDown))}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, setULLBLDown)}
                />
            </div>
            <div className="label col-md-3">

            </div>
        </div>
        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-2">DBT</div>
            <div className="label col-md-3">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={uLLBLReadOnly}
                    value={uLLDBTUp}
                    max={999999999.999}

                    onBlur={(e) => checkZeroMinMaxULimit(e.target.value, setULLDBTUp,uLLDBTDown,uLLDBTUp,setULLDBTDown,setULLDBTUp,'max')}

                    // onBlur={(e) => onMinMaxCheck(e.target.value, setULLDBTUp,minChar,maxChar,setMinChar,setMaxChar,'min')}

                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, setULLDBTUp)}
                />
            </div>
            <div className="label col-md-3">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={uLLDBTDownReadOnly}
                    value={uLLDBTDown}
                    max={999999999.999}
                    onBlur={(e) => checkZeroMinMaxULimit(e.target.value, setULLDBTDown,uLLDBTDown,uLLDBTUp,setULLDBTDown,setULLDBTUp,'min')}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, setULLDBTDown)}
                />
            </div>
            <div className="label col-md-3">

            </div>
        </div>

    </>
});
export default UpperLowerLimit;
