import axios from 'axios';
import {
    gcmEncrypt,
    gcmDecrypt,
    generateBearerTokenKey
} from '../crypto/msgCrypto';
import {generateEcdhKeys} from '../crypto/ecdhCrypto';
import Config from '../config';
import {getUserDetail, resetUserExpiredTime, setLogout} from '../hooks/handleCookie';
import moment from "moment/moment";


const axiosInstance = axios.create({
    baseURL: Config.baseURL,
    headers: {
        'Content-Type': 'application/octet-stream'
        // Authorization: `Bearer`
    }
});

let isDebug = Config.axiosDebuggingLog;
axiosInstance.interceptors.request.use(
    function (config) {
        let method = config.method;
        let userDetail = getUserDetail();

        //AES encryption with ECDH sharedKey
        //encrypt body data
        //generate ECDH Key
        let {sharedKey, publicKey} = generateEcdhKeys();
        config.ECDHPublicKey = publicKey;
        config.ECDHSharedKey = sharedKey;

        if (method == 'get') {
            //get
            let encryptedBody = gcmEncrypt('{}', sharedKey);
            config.data = encryptedBody;
        } else {
            //post
            let encryptedBody = gcmEncrypt(config.data, sharedKey);
            config.data = encryptedBody;
        }

        if (userDetail && userDetail.login_token) {
            let token = userDetail.login_token;
            //protected api
            config.headers['sesstoken'] = `${token}`;
            config.headers['Ecdh-Public-Key'] = publicKey;
        } else {
            //public api
            config.headers['Ecdh-Public-Key'] = publicKey;
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        if (response.status === 200 && response.data) {
            return response; //fetch data to display
        } else {
            alert(response.status);
            alert('Warning: Fail to get data. ' + response.data.message);
        }
    },
    (error) => {
        console.log(error);
        if (error.response.status === 401 || error.response.status === 408) {
            alert("Server busy, please try again later.");

        }else  if (error.response.status === 403) {
            setLogout();
            window.location.href = './?msg='+encodeURIComponent('Session Expired');
        }else  if (error.response.status === 430) {
            setLogout();
            window.location.href = './?msg='+encodeURIComponent('We had detected duplicated login in from another device. Please login again.');
        }
    }
);

const handleRequestPost = async (url, param) => {
    let stringifyBody = JSON.stringify(param);
    if(isDebug){
        console.log(url);
        console.log(param);
    }

    return axiosInstance
        .post(url, stringifyBody)
        .then(async function (response) {

            if (response) {
                let status = response.status;
                if (status == 502 && !url.includes("heartbeat")) {
                    console.log("502 Error Triggered!");
                    let resp = {status: 1, message: "Server under maintenance, please try again later"};
                    return JSON.stringify(resp);
                }


                if (status == 200) {
                    let data = response.data;
                    let userDetail = getUserDetail();
                    if (userDetail && userDetail.token) {
                        let token = userDetail.token;
                        let key = await generateBearerTokenKey(token);
                        let result = await gcmDecrypt(data, key);
                        let expTime = JSON.parse(result);

                        if(isDebug){
                            console.log('result', result);
                        }
                        if(expTime.status == 3){
                            setLogout();
                            window.location.href = './';
                        }
                        if (expTime.expires_in!= undefined || expTime.expires_in != null) {
                            var time =moment(expTime.expires_in).valueOf();
                            resetUserExpiredTime(parseInt(time.toString().substring(0, 10)));
                        }
                        return result;
                    } else {
                        const {config} = response;
                        //get sharedkey from config
                        let ECDHSharedKey = config.ECDHSharedKey;
                        let result = await gcmDecrypt(data, ECDHSharedKey);
                        let expTime = JSON.parse(result);

                        if(isDebug){
                            console.log('result', result);
                        }
                        if(expTime.status == 3){
                            setLogout();
                            window.location.href = './';
                        }
                        if (expTime.expires_in!= undefined || expTime.expires_in != null) {
                            var time = moment(expTime.expires_in).valueOf();
                            resetUserExpiredTime(parseInt(time.toString().substring(0, 10)));
                        }
                        return result;
                    }
                } else {
                    alert('Request Failed');
                }
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            if (!url.includes("heartbeat")) {
                console.log("502 Error Triggered!");
                let resp = {status: 1, message: "Please wait a few minutes before you try again."};
                return JSON.stringify(resp);
            }
        });
};

const handleRequestGet = async (url, param) => {
    //stringify param before going to next step
    // let stringifyBody = JSON.stringify(param);

    // let isDebug = false;
    if(isDebug){
        console.log(url);
        console.log(param);
    }

    return axiosInstance
        .get(url, {params: param})
        .then(async function (response) {
            if (response) {
                let status = response.status;

                if (status == 502 && !url.includes("heartbeat")) {
                    console.log("502 Error Triggered!");
                    let resp = {status: 1, message: "Server under maintenance, please try again later"};
                    return JSON.stringify(resp);
                }


                if (status == 200) {
                    let data = response.data;
                    let userDetail = getUserDetail();

                    if (userDetail && userDetail.token) {
                        let token = userDetail.token;
                        let key = await generateBearerTokenKey(token);
                        let result = await gcmDecrypt(data, key);
                        let expTime = JSON.parse(result);
                        if(isDebug){
                            console.log(result);
                        }
                        if(expTime.status == 3){
                            setLogout();
                            window.location.href = './';
                        }
                        if (expTime.expires_in!= undefined || expTime.expires_in != null) {
                            var time =moment(expTime.expires_in).valueOf();
                            resetUserExpiredTime(parseInt(time.toString().substring(0, 10)));
                        }
                        return result;
                    } else {
                        const {config} = response;
                        //get sharedkey from config
                        let ECDHSharedKey = config.ECDHSharedKey;
                        let result = await gcmDecrypt(data, ECDHSharedKey);
                        let expTime = JSON.parse(result);

                        if(expTime.status == 3){
                            setLogout();
                            window.location.href = './';
                        }

                        if(isDebug){
                            console.log(result);
                        }

                        if (expTime.expires_in!= undefined || expTime.expires_in != null) {
                        var time = moment(expTime.expires_in).valueOf();
                            resetUserExpiredTime(parseInt(time.toString().substring(0, 10)));
                        }

                        return result;
                    }

                } else {
                    alert('Request Failed');
                }
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            if (!url.includes("heartbeat")) {
                console.log("502 Error Triggered!");
                let resp = {status: 1, message: "Server under maintenance, please try again later"};
                return JSON.stringify(resp);
            }

        });
};

const handleRequestMultipart = async (url, formData) => {
    return axiosInstance

        // Axios({
        //     url:'/api/importExcel',
        //     method: 'post',
        //     headers:{'Content-Type':'multipart/form-data'},
        //     data:formdata
        // }).then(
        //     request =>{
        //         console.log(request.data)
        //     },
        //     error =>{
        //         console.log(error.data)
        //     }
        // )
        .post(
            url,
            formData,
            {
                headers: {'Content-Type': 'multipart/form-data'}
            }
        )
        .then(async function (response) {
            // handle success
            // console.log(response);
            if (response) {
                let status = response.status;

                if (status == 200) {
                    let data = response.data;
                    let userDetail = getUserDetail();

                    //logined
                    if (userDetail && userDetail.token) {
                        let token = userDetail.token;
                        // console.log("token")
                        // console.log(token)
                        let key = await generateBearerTokenKey(token);

                        // console.log('token', token);
                        // console.log('key', key);
                        let result = await gcmDecrypt(data, key);
                        let expTime = JSON.parse(result);
                        if (expTime.expires_in!= undefined || expTime.expires_in != null) {
                            var time =moment(expTime.expires_in).valueOf();
                            resetUserExpiredTime(parseInt(time.toString().substring(0, 10)));
                        }
                        console.log('result', result);
                        return result;
                    } else {
                        const {config} = response;
                        //get sharedkey from config
                        let ECDHSharedKey = config.ECDHSharedKey;
                        let result = await gcmDecrypt(data, ECDHSharedKey);
                        let expTime = JSON.parse(result);
                        if (expTime.expires_in!= undefined || expTime.expires_in != null) {
                            var time =moment(expTime.expires_in).valueOf();
                            resetUserExpiredTime(parseInt(time.toString().substring(0, 10)));
                        }
                        return result;
                    }
                } else {
                    alert('Request Failed');
                }
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });
};

export {handleRequestPost, handleRequestGet, handleRequestMultipart};
