import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react';
import '../../../../asset/styles/customer-profile-style.css';
import api from '../../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    convertPositiveNumberHundred, cursorToFront, handleDecimalNumChangeWifCursor,
    handlePositiveNumberChange,
    handlePositiveNumberHundredChange,
    handleSelectChange, handleTopUpdateEL
} from "../../../../general/general-tools";

const AmountLimit = React.memo(props => {
    let buy = props['amountLimitBuy'] == null ? '' : props['amountLimitBuy'];
    let buySetter = props['setAmountLimitBuy']== null ? '' : props['setAmountLimitBuy'];
    let sell = props['amountLimitSell']== null ? '' : props['amountLimitSell'];
    let sellSetter = props['setAmountLimitSell']== null ? '' : props['setAmountLimitSell'];
    let radio = props['amountLimitControl']== null ? '' : props['amountLimitControl'];
    let radioSetter = props['setAmountLimitControl']== null ? '' : props['setAmountLimitControl'];
    let readOnly = props['isAmountLimitReadOnly']== null ? '' : props['isAmountLimitReadOnly'];
    let popupManager = props['popupManager']== null ? '' : props['popupManager'];


    return  <>

        <div className="input-container col-md-12 fullWidthSearch exp-input-parent-single">
            <b>Amount Limit</b>
        </div>
        <div className="input-container col-md-12 fullWidthSearch">


            <hr/>
            <div className="label col-md-2">Buy</div>
            <div className="label col-md-4">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={readOnly}
                    value={buy}
                    max={999999999.999}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, buySetter)}

                />
            </div>

        </div>
        <div className="input-container col-md-12 fullWidthSearch">


            <hr/>
            <div className="label col-md-2">Sell</div>
            <div className="label col-md-4">
                <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    aria-label=""
                    readOnly={readOnly}
                    value={sell}
                    max={999999999.999}
                    onFocus={(e) => cursorToFront(e)}
                    onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, sellSetter)}
                />
            </div>

        </div>
        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-2">Control</div>
            <div className="label col-md-4">
                <input className="" name="control" type="radio" checked={radio == 'control_block' ? true : false} defaultChecked={radio == 'control_block' ? true : false} id='control_block' disabled={readOnly}
                       onChange={e => handleSelectChange('control_block', radioSetter, readOnly)}
                />
                <label className='checkboxMarginRight' htmlFor="control_block">

                    Block
                </label>


                <label className="checkboxMarginLeft" htmlFor="control_warning">
                    <input className="checkboxMarginLeft" name="control" type="radio" checked={radio == 'control_warning' ? true : false} defaultChecked={radio == 'control_warning' ? true : false} id='control_warning' disabled={readOnly}
                           onChange={e => handleSelectChange('control_warning', radioSetter, readOnly)}

                    />
                    Warning
                </label>


            </div>
        </div>

    </>
});
export default AmountLimit;


