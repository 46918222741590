import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react';
import '../../../../asset/styles/customer-profile-style.css';
import api from '../../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    convertPositiveNumberHundred, dateFormats, getNavItemById,
    handlePositiveNumberChange,
    handlePositiveNumberHundredChange,
    handleSelectChange
} from "../../../../general/general-tools";
import ReactTable from "../../../../general/form-control/react-table";
import dayjs from "dayjs";



const ClientAmend = React.memo(props => {

    let setCurrentPage = props['setCurrentPage']== null ? '' : props['setCurrentPage'];
    let setCurrentPerPage = props['setCurrentPerPage']== null ? '' : props['setCurrentPerPage'];
    let currentPage = props['tableData']== null ? '' : props['currentPage'];
    let currentPerPage = props['tableData']== null ? '' : props['currentPerPage'];
    let totalRecords = props['tableData']== null ? '' : props['totalRecords'];
    let tableData = props['tableData']== null ? '' : props['tableData'];

    let setEnqOrderId = props['setEnqOrderId']== null ? '' : props['setEnqOrderId'];



    let popupManager = props['popupManager']== null ? '' : props['popupManager'];


    const handleOnclick = async (row) => {
        await setLoading(true);

        if(setEnqOrderId)setEnqOrderId(row.ut_id ?? 0);

        // navigate("/" + process.env.REACT_APP_URL_PREFIX + "br_entry_opr", {
        //     state: {
        //         row: row,
        //         perm: actualPerm,
        //         mode: 'Edit'
        //     }
        // });
        setLoading(false);
    };


    const columns = useMemo(
        () => [
            {
                id: row => row.order_entry,
                name: 'Ord.Date',
                selector: row => dayjs(row.order_entry).format(dateFormats()),
                sortable: true
            },
            {
                name: 'Ord.No',
                selector: row => row.order_no,
                sortable: true,
                cell: (row: { order_no: any }) => (
                    <>
                        <a className="tableItemsHlink" onClick={() => handleOnclick(row)}>{row.order_no}</a>
                    </>
                ),
            },
            {
                name: 'CL.Name',
                selector: row => row.username,
                sortable: true,

            },
            {
                name: 'Acc.No',
                selector: row => row.acct_no,
                sortable: true,

            },
            {
                name: 'Symbol',
                selector: row => row.Symbol,
                sortable: true,
                wrap:true

            },
            {
                name: 'Action',
                selector: row => row.order_action,
                sortable: true
            },
            {
                name: 'Price',
                selector: row => convertPositiveNumberHundred(row.order_avg_price ?? 0) ?? "-",
                sortable: true
            },
            {
                name: 'Qty',
                selector: row => convertPositiveNumberHundred(row.order_match_qty ?? 0) ?? "-",
                sortable: true,

            },
            {
                name: 'Mth Amt',
                selector: row => convertPositiveNumberHundred(row.order_match_price ?? 0) ?? "-",
                sortable: true,

            }
        ],
        []
    );


    return  <>

        <ReactTable
            rowClick={setCurrentPage}
            perPageQty={setCurrentPerPage}
            // handleSelect={setEnqOrderId}
            columns={columns}
            data={tableData}
            pagination={{
                currentPage: currentPage,
                totalRecords: totalRecords,
                currentPerPage: currentPerPage,
                // selectableHandle: {setEnqOrderId},
            }}
        />

    </>
});
export default ClientAmend;
