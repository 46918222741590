import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react';
import '../../../../asset/styles/customer-profile-style.css';
import companyLogo from "../../../../asset/pdfheader/header.png";
import api from "../../../../../apis";
import {convertErMsg, getNavItemById, handlePopup} from "../../../../general/general-tools";
import dayjs from "dayjs";
import {getAuditPdfStyling, getEnquiryClientProfileStyle} from "../../../../general/pdf-tools";
import FontFaRegular400 from "../../../../asset/font-awesome/webfonts/NotoSans-Medium.ttf";
import FontFaRSolid900 from "../../../../asset/font-awesome/webfonts/NotoSans-Bold.ttf";
import {
    Document,
    Page,
    PDFDownloadLink,
    PDFViewer,
    StyleSheet,
    Text,
    View,
    Image,
    Line,
    Font
} from "@react-pdf/renderer";
import moment from "moment/moment";
import {useCookies} from "react-cookie";


const UserClientProfilePDF = React.memo(props => {

    let dataRows = props['dataRows']== null ? [] : props['dataRows'];
    let currentEnqId = props['currentEnqId']== null ? [] : props['currentEnqId'];

    //PDF use
    const [currentCompanyCode, setCurrentCompanyCode] = useState("");
    const [currentBranchCode, setCurrentBranchCode] = useState("");
    const [currentCompanyName, setCurrentCompanyName] = useState("");

    const [currentCompanyAddress1, setCurentBranchAddress1] = useState("");
    const [currentCompanyAddress2, setCurentBranchAddress2] = useState("");
    const [currentCompanyAddress3, setCurentBranchAddress3] = useState("");
    const [currentCompanyPostCode, setCurrentCompanyPostCode] = useState("");
    const [currentCompanyState, setCurrentCompanyState] = useState("");
    const [currentCompanyCountry, setCurrentCompanyCountry] = useState("");
    const [currentCompanyCountryText, setCurrentCompanyCountryText] = useState("");
    const [currentCompanyPhoneNum, setCurrentCompanyPhoneNum] = useState("");
    const [currentCompanyWebsite, setCurrentCompanyWebsite] = useState("");
    const [auditLogName, setAuditLogName] = useState("enquiry_" + dayjs().format("DDMMYYYYhhmmss"));
    const [cookies, setCookie, removeCookie] = useCookies(['admin_user']);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        renderHeader();
        setUserName(cookies.admin_user.username);

    }, []);
    //PDF Printing Function
    const styles = getEnquiryClientProfileStyle();

    Font.register({
        family: 'Regular',
        fonts: [
            {
                src: FontFaRegular400,
            },
            {
                src: FontFaRSolid900,
                fontWeight: 'bold',
            }
        ],
    });


    const renderHeader = () => {
        api.getCompanyDetail().then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }

            let companyEle = result.data.list[0];
            setCurrentCompanyCountryText(companyEle.co_name);
            setCurrentCompanyCode(companyEle.b_id)
            setCurrentBranchCode(companyEle.b_code);
            setCurrentCompanyName(companyEle.b_name);
            setCurentBranchAddress1(companyEle.b_address_line1 ?? "");
            setCurentBranchAddress2(companyEle.b_address_line2 ?? "");
            setCurentBranchAddress3(companyEle.b_address_line3 ?? "");
            setCurrentCompanyPostCode(companyEle.b_postcode ?? "");
            setCurrentCompanyState(companyEle.b_state ?? "");
            setCurrentCompanyCountry(companyEle.b_cid ?? "");
            if (companyEle.b_phone) setCurrentCompanyPhoneNum(companyEle.b_phone);
            setCurrentCompanyWebsite(companyEle.b_website);
        });

    }


    const handleRerender = () => {
        if(dataRows.accounts) dataRows.accountList = JSON.parse(dataRows.accounts);

        let dayEnabled = "";
        if(dataRows.is_monday == "1") dayEnabled += "Monday, ";
        if(dataRows.is_tuesday == "1") dayEnabled += "Tuesday, ";
        if(dataRows.is_wednesday == "1") dayEnabled += "Wednesday, ";
        if(dataRows.is_thursday == "1") dayEnabled += "Thursday, ";
        if(dataRows.is_friday == "1") dayEnabled += "Friday, ";
        if(dataRows.is_saturday == "1") dayEnabled += "Saturday, ";
        if(dataRows.is_sunday == "1") dayEnabled += "Sunday, ";

        dayEnabled = dayEnabled.slice(0, -2);

        return (

            <Page size="A4" style={styles.page}  orientation={'portrait'}>

                <View style={styles.headerDiv}>
                    <Image src={companyLogo} style={styles.image}/>
                    <View style={styles.headerTextRightDiv}>
                        <Text style={styles.headerTextBig}>{currentCompanyName}</Text>
                        <Text style={styles.headerTextMedium}>{currentCompanyAddress1}{'\n'}
                            {currentCompanyAddress2}{'\n'}
                            {(currentCompanyPostCode != "" ? currentCompanyPostCode + " ," : "")}{currentCompanyAddress3}</Text>
                        <Text style={styles.headerTextSmall}>Phone : {currentCompanyPhoneNum}{'\n'}
                            Website : {currentCompanyWebsite}</Text>
                    </View>

                </View>
                <View style={styles.headerDivEnquiryTitle}>
                    <Text style={[styles.headerTextMediumLeft,styles.bold]}>[Enquiry] {(getNavItemById(currentEnqId).actualLbl) ?  (getNavItemById(currentEnqId).actualLbl).replace("Enquiry","") : "-"}</Text>


                </View>

                <View style={styles.headerDivEnquiryTitle}>
                    <Text style={[styles.headerTextMediumLeft,styles.bold]}>Profile</Text>

                </View>

                <View style={styles.bodyDiv}>

                    <View style={styles.table}>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0, styles.bold]}>User ID</Text>
                            <Text style={styles.specialChildRow}> {dataRows.username}</Text>

                            <Text style={[styles.specialChildRow0, styles.bold]}>User Type</Text>
                            <Text style={styles.specialChildRow}> {dataRows.ut_name}</Text>
                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0, styles.bold]}>Title</Text>
                            <Text style={styles.specialChildRow}> {dataRows.p_title}</Text>
                            <Text style={[styles.specialChildRow0, styles.bold]}>Gender</Text>
                            <Text style={styles.specialChildRow}>{dataRows.p_gender == "M" ? " Male" : " Female"}</Text>

                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0, styles.bold]}>First Name</Text>
                            <Text style={styles.specialChildRow}> {dataRows.p_firstname}</Text>

                            <Text style={[styles.specialChildRow0, styles.bold]}>Last Name</Text>
                            <Text style={styles.specialChildRow}> {dataRows.p_lastname}</Text>
                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0, styles.bold]}>NRIC</Text>
                            <Text style={styles.specialChildRow}> {dataRows.p_nric ?? ""}</Text>

                            <Text style={[styles.specialChildRow0, styles.bold]}>Passport No</Text>
                            <Text style={styles.specialChildRow}> {dataRows.p_pptno ?? ""}</Text>
                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0, styles.bold]}>Nationality</Text>
                            <Text style={styles.specialChildRow}> {dataRows.p_nationality ?? ""}</Text>

                            <Text style={[styles.specialChildRow0, styles.bold]}>DOB</Text>
                            <Text style={styles.specialChildRow}> {dataRows.p_dob != 'Invalid date' ? (moment(dataRows.p_dob)).format("YYYY-MM-DD") : "N/A"}</Text>
                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0, styles.bold]}>Email</Text>
                            <Text style={styles.specialChildRow}> {dataRows.p_email ?? ""}</Text>

                            <Text style={[styles.specialChildRow0, styles.bold]}>Branch</Text>
                            <Text style={styles.specialChildRow}> {dataRows.bb_code +" | "+dataRows.bb_name}</Text>
                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0, styles.bold]}>Home Phone</Text>
                            <Text style={styles.specialChildRow}> {dataRows.p_phone ?? "N/A"}</Text>

                            <Text style={[styles.specialChildRow0, styles.bold]}>Mobile Phone</Text>
                            <Text style={styles.specialChildRow}> {dataRows.p_handphone ?? "N/A"}</Text>
                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0, styles.bold]}>Office Phone</Text>
                            <Text style={styles.specialChildRow}> {dataRows.p_office ?? "N/A"}</Text>

                            <Text style={[styles.specialChildRow0, styles.bold]}>Status</Text>
                            <Text style={styles.specialChildRow}> {dataRows.active_status == "0" ? "Inactive" :
                                dataRows.active_status == "1" ? "Active" :
                                dataRows.active_status == "2" ? "Suspended" :
                                dataRows.active_status == "3" ? "Expired" :
                                dataRows.active_status == "4" ? "Dormant" :
                                dataRows.active_status == "5" ? "Closed" : ""
                            }</Text>
                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0, styles.bold]}>Race</Text>
                            <Text style={styles.specialChildRow}> {dataRows.p_race ?? ""}</Text>

                            <Text style={[styles.specialChildRow0, styles.bold]}>Race (Others)</Text>
                            <Text style={styles.specialChildRow}> {(dataRows.p_race ?? "").includes("OTHERS") ? (dataRows.p_race).split('-')[1]: ""}</Text>
                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0, styles.bold]}>Client Type</Text>
                            <Text style={styles.specialChildRow}> {dataRows.ct_name ?? "N/A"}</Text>

                            <Text style={[styles.specialChildRow0, styles.bold]}>Dealer Code</Text>
                            <Text style={styles.specialChildRow}> {dataRows.dealer_code ?? "N/A"}</Text>
                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0, styles.bold]}>Bank AC.No</Text>
                            <Text style={styles.specialChildRow}> {dataRows.bank_account ?? "N/A"}</Text>

                            <Text style={[styles.specialChildRow0, styles.bold]}>Access Status</Text>
                            <Text style={styles.specialChildRow}> {dataRows.active_status == "1" ? "Unblock" : "Block"}</Text>
                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0, styles.bold]}>Bank Name</Text>
                            <Text style={styles.specialChildRow}> {dataRows.bk_name ?? "N/A"}</Text>

                            <Text style={[styles.specialChildRow0, styles.bold]}>eContract</Text>
                            <Text style={styles.specialChildRow}> {dataRows.econtract == "1" ? "Yes" : "No"}</Text>
                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0, styles.bold]}>IDSS</Text>
                            <Text style={styles.specialChildRow}> {dataRows.idss == "1" ? "Yes" : "No"}</Text>

                            <Text style={[styles.specialChildRow0, styles.bold]}>eStatement</Text>
                            <Text style={styles.specialChildRow}> {dataRows.estatement == "1" ? "Yes" : "No"}</Text>
                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0, styles.bold]}>Creation Date</Text>
                            <Text style={styles.specialChildRow}> {dataRows.create_date != 'Invalid date' ? (moment(dataRows.create_date)).format("YYYY-MM-DD") : "-"}</Text>

                            <Text style={[styles.specialChildRow0, styles.bold]}>T&C Accept Date</Text>
                            <Text style={styles.specialChildRow}> {dataRows.tnc_accept != 'Invalid date' ? (moment(dataRows.tnc_accept)).format("YYYY-MM-DD") : "-"}</Text>
                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0Single, styles.bold]}>Last Pw Change Date</Text>
                            <Text style={styles.specialChildRowSingle}> {dataRows.last_change_password != 'Invalid date' ? (moment(dataRows.last_change_password)).format("YYYY-MM-DD") : "-"}</Text>

                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0Single, styles.bold]}>Pw Expired Date</Text>
                            <Text style={styles.specialChildRowSingle}> {dataRows.password_expired_on != 'Invalid date' ? (moment(dataRows.password_expired_on)).format("YYYY-MM-DD") : "-"}</Text>

                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0Single, styles.bold]}>Last Update Date</Text>
                            <Text style={styles.specialChildRowSingle}> {dataRows.timelast_update != 'Invalid date' ? (moment(dataRows.timelast_update)).format("YYYY-MM-DD") : "-"}</Text>

                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0Single, styles.bold]}>Address 1</Text>
                            <Text style={styles.specialChildRowSingle}> {dataRows.p_addressline1 ?? "-"}</Text>

                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0Single, styles.bold]}>Address 2</Text>
                            <Text style={styles.specialChildRowSingle}> {dataRows.p_addressline2 ?? "-"}</Text>

                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0Single, styles.bold]}>Address 3</Text>
                            <Text style={styles.specialChildRowSingle}> {dataRows.p_addressline3 ?? "-"}</Text>

                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0, styles.bold]}>Country</Text>
                            <Text style={styles.specialChildRow}> {dataRows.p_country ?? "-"}</Text>

                            <Text style={[styles.specialChildRow0, styles.bold]}>State</Text>
                            <Text style={styles.specialChildRow}> {dataRows.p_state ?? "-"}</Text>
                        </View>
                        <View style={[styles.specialRow, styles.header]}>
                            <Text style={[styles.specialChildRow0Single, styles.bold]}>Postcode</Text>
                            <Text style={styles.specialChildRowSingle}> {dataRows.p_postcode ?? "-"}</Text>


                        </View>
                    </View>
                    {'\n'}

                </View>

                <View style={styles.headerDivEnquiryTitle}>
                    <Text style={[styles.headerTextMediumLeft,styles.bold]}>{"Access Schedule"}</Text>
                </View>

                <View style={styles.bodyDiv}>
                    <View style={[styles.specialRow, styles.header]}>
                        <Text style={[styles.specialChildRow0, styles.bold]}>Enable</Text>
                        <Text style={styles.specialChildRow}> {dataRows.p_country ?? "-"}</Text>

                        <Text style={[styles.specialChildRow0, styles.bold]}>Expired On</Text>
                        <Text style={styles.specialChildRow}> {dataRows.time_acs_end_date != 'Invalid date' ? (moment(dataRows.time_acs_end_date)).format("YYYY-MM-DD") : "-"}</Text>
                    </View>

                    <View style={[styles.specialRow, styles.header]}>
                        <Text style={[styles.specialChildRow0, styles.bold]}>Start Time</Text>
                        <Text style={styles.specialChildRow}> {dataRows.acs_start_time == 'Invalid date' ? null : dataRows.acs_start_time}</Text>

                        <Text style={[styles.specialChildRow0, styles.bold]}>End Time</Text>
                        <Text style={styles.specialChildRow}> {dataRows.acs_end_time == 'Invalid date' ? null : dataRows.acs_end_time}</Text>
                    </View>
                    <View style={[styles.specialRow, styles.header]}>
                        <Text style={[styles.specialChildRow0Single, styles.bold]}>Day</Text>
                        <Text style={styles.specialChildRowSingle}> {dayEnabled}</Text>
                    </View>
                </View>
                <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (
                    `Page ${pageNumber} / ${totalPages}`

                )} fixed/>
                <Text style={[styles.pageNumberLeft]} fixed>Generated by {userName + "@" + dayjs().format("DD-MM-YYYY hh:mm:ss")} </Text>
            </Page>


        )
    }

    return  <>

        <PDFViewer
            showToolbar={true}

        >
            <Document>

                {handleRerender()}

            </Document>
        </PDFViewer>


    </>
});
export default UserClientProfilePDF;
