import { createContext, useContext, useState } from 'react';
import ScreenLoader from '../components/general/loader';

const LoadingContext = createContext({});

interface Props {
    children: React.ReactNode;
}

export const LoadingProvider = ({ children }: Props) => {
    const [loading, setLoading] = useState(false);
    return (
        <LoadingContext.Provider value={{ loading, setLoading }}>
            {loading == true && <ScreenLoader />}
            {children}
        </LoadingContext.Provider>
    );
};

export const useLoading = () => {
    const context = useContext<any>(LoadingContext);
    if (!context) {
        throw new Error('useLoading must be used within LoadingProvider');
    }
    return context;
};
