import {handleRequestGet, handleRequestPost} from '../axios';

//Stock List
export const getTickerList = async (size, page, eterm,sterm) => {
    let url = '/ticker';
    let param ={
        size:size,
        page:page
    }

    if(eterm) param['eterm'] = eterm;
    if(sterm) param['sterm'] = sterm;
    let result = await handleRequestGet(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getELCompany = async () => {
    let url = '/riskcompany';
    let result = await handleRequestGet(url, []);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getTCCompany = async () => {
    let url = '/riskcompany/tradecontrol';
    let result = await handleRequestGet(url, []);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const updateCompanyEL = async (data) => {
    let url = '/riskcompany/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const updateCompanyTC = async (data) => {
    let url = '/riskcompany/tradecontrol/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const getExchangeEL = async (param) => {
    let url = '/riskexchange';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getExchangeTC = async (param) => {
    let url = '/riskexchange/tradecontrol';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateExchangeEL = async (data) => {
    let url = '/riskexchange/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateExchangeTC = async (data) => {
    let url = '/riskexchange/tradecontrol/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const getBranchEL = async (param) => {
    let url = '/riskbranch';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getBranchTC = async (param) => {
    let url = '/riskbranch/tradecontrol';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const updateBranchEL = async (data) => {
    let url = '/riskbranch/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const updateBranchTC = async (data) => {
    let url = '/riskbranch/tradecontrol/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getDealerEL = async (param) => {
    let url = '/riskdealer';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};


export const getDealerTC = async (param) => {
    let url = '/riskdealer/tradecontrol';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateDealerEL = async (data) => {
    let url = '/riskdealer/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};export const updateDealerTC = async (data) => {
    let url = '/riskdealer/tradecontrol/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const getAccountEL = async (param) => {
    let url = '/riskaccount';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getAccountTC = async (param) => {
    let url = '/riskaccount/tradecontrol';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const updateAccountEL = async (data) => {
    let url = '/riskaccount/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};export const updateAccountTC = async (data) => {
    let url = '/riskaccount/tradecontrol/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getStockCoEL = async (param) => {
    let url = '/riskstockcompany';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getStockCoTC = async (param) => {
    let url = '/riskstockcompany/tradecontrol';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateStockCoEL = async (data) => {
    let url = '/riskstockcompany/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const updateStockCoTC = async (data) => {
    let url = '/riskstockcompany/tradecontrol/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const getStockBranchEL = async (param) => {
    let url = '/riskstockbranch';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getStockBranchTC = async (param) => {
    let url = '/riskstockbranch/tradecontrol';
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const updateStockBranchEL = async (data) => {
    let url = '/riskstockbranch/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const updateStockBranchTC = async (data) => {
    let url = '/riskstockbranch/tradecontrol/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
