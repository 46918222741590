import React, {useState, useEffect, useMemo, useLayoutEffect} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';

import DropdownSelect from '../../../general/form-control/dropdown-select';
import ReactTable from '../../../general/form-control/react-table';
import statusText from '../../../../constants/statusText';
import TreeSelect from '../../../general/form-control/tree-select';
import {parseAPIResutlToData} from '../../../general/form-control/tree-select/helper';
import DataTable from 'react-data-table-component';
import {useLocation, useNavigate} from 'react-router-dom';

import {numberWithCommas} from '../../../general/convertor';
import {confirmAlert} from "react-confirm-alert";
import {
    changeBase, disableAllEleInDiv,
    getNavItemById,
    handlePopup,
    handleSelectChange,
    userTypeCategory,
    zeroFill, convertErMsg, IsJsonString
} from "../../../general/general-tools";
import {handleToggleCheckAllCurentClass, handleToggleChildControl} from "./AmountLimit";
import {renderOperationForm} from "./UserAccessMatOperation";
import {renderDealerForm} from "./UserAccessMatClientDeal";
import {usePopupManager} from "react-popup-manager";
import ModalConfirm from "../../public/ModalConfirm";
import {
    getExchangeMenu, getExchangeOrderType,
    getExchangeQty,
    getFunctionMenus,
    getOrderValidityItem,
    getToolsMenu
} from "./UserAccessMatTools";
import {updateUserAccessMatrixMarket} from "../../../../apis/models/UserType";
import {getOperationAccessMatList, updateOrderValidityUserType} from "../../../../apis/models/UserTypeMatrix";

const Index = () => {
    const {setLoading} = useLoading();


    const [currentMode, setCurrentMode] = useState("");
    const [currentUserId, setCurrentUserId] = useState("");
    const [currentUserType, setCurrentUserType] = useState("");
    const [currentUserTypeName, setCurrentUserTypeName] = useState("");
    const [currentUserTypeDesc, setCurrentUserTypeDesc] = useState("");
    const [currentUserAccessLevel, setCurrentUserTypeAccessLevel] = useState("");
    const [currentUserCategory, setCurrentUserCategory] = useState("");
    const [accessItem, setAccessItem] = useState({});

    const [isOrderNow, setIsOrderNow] = useState(false);
    const [isMarketNow, setIsMarketNow] = useState(false);

    const [clientMarketOri, setClientMarketOri] = useState(null);
    const [clientOrderOri, setClientOrderOri] = useState(null);
    const [currentClientOrderPortion, setCurrentClientOrderPortion] = useState('market');

    const [currentMarketDepth, setCurrentMarketDepth] = useState(null);

    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();

    //Load initial data and mode
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        if (location.state) {
            if (location.state.row) {
                setCurrentUserId(location.state.row.ut_id);
                setCurrentUserType(location.state.row.ut_catid);
                setCurrentUserTypeName(location.state.row.ut_name);
                setCurrentUserTypeDesc(location.state.row.ut_description);
                // setCurrentUserTypeAccessLevel(location.state.row.ut_accesslevel);
                setCurrentUserCategory(location.state.row.uc_name);
                handleInitList(location.state.row.ut_id, location.state.row.uc_name.toLowerCase());
                setAccessItem(location.state.perm);
                if (!location.state.perm['edit']) {
                    setTimeout(function () {
                        disableAllEleInDiv("mainDiv");
                        disableAllEleInDiv("operationDiv");
                        disableAllEleInDiv("dealerDiv");
                    }, 50);
                }


            }
            setCurrentMode(location.state.mode)
        }

    }, [location.state]);


    useEffect(() => {
        // handleInitList(location.state.row.ut_id, location.state.row.uc_name.toLowerCase());
    }, [currentClientOrderPortion]);


    const handleInitList = async (ut_id, user_type) => {
        setLoading(true);


        if (user_type == "operation") {
            api.getOperationAccessMatList({id: ut_id}).then((result: any) => {
                if (result.status == 1) {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    setLoading(false);
                }
                if (result.data) {
                    if (result.data.length > 0) {
                        initOperationItems(result.data);
                    }
                }

            });
        } else {
            setIsMarketNow(true);
            api.refreshAccessMatrixMarket(999, 1, 1, "", ut_id).then((result: any) => {
                if (result.status == 1) {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    setLoading(false);
                }
                if (result.data) {

                    if (result.data.list.length > 0) {
                        setCurrentUserTypeAccessLevel(result.data.list[0]['ut_accesslevel'] ?? "{\"al_exchange\": 0, \"al_trading\": [], \"al_tools\": 0}");
                        initItems(result.data.list[0]);
                        initOrderItems(result.data.list[0]);
                    }
                }
            });
        }

        setLoading(false);

    };


    const initItems = (accessLevel) => {

        if (accessLevel == null) return;
        var exchangeListLength = getExchangeQty();
        var exchangeLists = getExchangeMenu();
        let accessLvl = {};
        if (accessLevel) {
            accessLvl = JSON.parse(accessLevel['ut_accesslevel'])
        }

        setClientMarketOri(accessLvl);
        let exchangeSetup = (accessLvl?.al_exchange ?? 0);

        var base3Exchange = Array.from(changeBase(exchangeSetup[0], 10, 3)).reverse().join('');

        if(!isNaN(base3Exchange))base3Exchange = zeroFill(base3Exchange, exchangeListLength);
        else base3Exchange = zeroFill("0", exchangeListLength);

        for (let i = 0; i < exchangeLists.length; i++) {
            let radios = document.getElementsByName("eq_" + exchangeLists[i]["em_id"]);
            let value = base3Exchange[exchangeLists[i]["em_id"]];
            for (let j = 0; j < radios.length; j++) {
                if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_disabled" && value == '0') {
                    radios[j].checked = true;
                    break;
                } else if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_delay" && value == '2') {
                    radios[j].checked = true;
                    break;
                } else if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_live" && value == '1') {
                    radios[j].checked = true;
                    break;
                }
            }
        }


        let toolsMenuList = getToolsMenu();
        let toolsSetup = (accessLvl?.al_tools ?? 0);
        var base2Exchange = Array.from(changeBase(toolsSetup, 10, 2)).reverse().join('');
        base2Exchange = zeroFill(base2Exchange, toolsMenuList.length);

        var checkCounter = 0;
        for (let i = 0; i < toolsMenuList.length; i++) {
            let value = base2Exchange[toolsMenuList[i]["id"]];
            let checkbox = document.getElementById("tool_" + toolsMenuList[i]["id"]);
            if (value == "1") {
                if (checkbox) checkbox.checked = true;
                checkCounter += 1;
            } else {
                if (checkbox) checkbox.checked = false

            }
        }
        if (checkCounter == toolsMenuList.length) document.getElementById('tool_all').checked = true;

        let market_depth = accessLvl?.al_depth ?? {"BM":1,"SG":1,"HK":1,"O":1,"NQ":1,"A":1,"P":1,"OQ":1,"N":1,"Ca":1,"Cc":1,"L":1,"AX":1};
        setCurrentMarketDepth(market_depth);

    }

    const initOperationItems = (accessList) => {

        accessList.forEach((value, index) => {
            let funcId = (!isNaN(parseInt(value['page'])) ? parseInt(value['page']) : 0);
            let ctrlBin = (!isNaN(parseInt(value['ctrl'])) ? parseInt(value['ctrl']) : 0);
            let parentLabel = (getNavItemById(funcId)['parent_lbl'] ?? "").replace(/\s/g, "").replaceAll(/[\(\)]/g, "").toLowerCase();
            let specialLabel = (getNavItemById(funcId)['special_lbl'] ?? "").replace(/\s/g, "").replaceAll(/[\(\)]/g, "").toLowerCase();
            let label = (getNavItemById(funcId)['actualLbl'] ?? "").replace(/\s/g, "").toLowerCase();
            let currentLabel = label.replaceAll(' ', '').replaceAll(/[\(\)]/g, "").toLowerCase()
            let ctrlBinFunc = zeroFill(Array.from(changeBase(ctrlBin, 10, 2)).reverse().join(''), 4)


            if (ctrlBinFunc != 0) {
                if (parentLabel.includes("riskmgmt") || parentLabel.includes("masterfile")) {
                    currentLabel = (parentLabel == "" ? "" : parentLabel + "-") + label;
                }

                if (funcId == 11 || funcId == 12 || funcId == 58 || funcId == 59 || funcId == 60) {
                    currentLabel = specialLabel + "-" + label;
                }

                if (funcId == 64 || funcId == 65) {
                    return;
                }

                if (funcId == 4) {
                    let specialPerm = (!isNaN(parseInt(value['am'])) ? parseInt(value['am']) : 0);

                    let amId = 64;
                    let alId = 65;
                    let amLabel = (getNavItemById(amId)['actualLbl'] ?? "").replace(/\s/g, "").replaceAll(/[\(\)]/g, "").toLowerCase();
                    let alLabel = (getNavItemById(alId)['actualLbl'] ?? "").replace(/\s/g, "").replaceAll(/[\(\)]/g, "").toLowerCase();
                    let specialControlBin = "1110";
                    switch (specialPerm) {
                        case 0 :
                            checkBoxHelper(amId, amLabel, 0);
                            checkBoxHelper(alId, alLabel, 0);
                            break;
                        case 1 :
                            checkBoxHelper(amId, amLabel, specialControlBin);
                            break;
                        case 2 :
                            checkBoxHelper(alId, alLabel, specialControlBin);
                            break;
                        case 3 :
                            checkBoxHelper(amId, amLabel, specialControlBin);
                            checkBoxHelper(alId, alLabel, specialControlBin);
                            break;
                    }

                }

                checkBoxHelper(funcId, currentLabel, ctrlBinFunc);
            }

        });
    }
    const checkBoxHelper = (funcId, currentLabel, ctrlBinFunc) => {
        let elementIdAllHeader = funcId + "_" + currentLabel + "_all_header";
        let elementIdAll = funcId + "_" + currentLabel + "_all";
        let elementIdView = funcId + "_" + currentLabel + "_view";
        let elementIdViewSub = funcId + "_view_header";
        let elementIdAdd = funcId + "_" + currentLabel + "_add";
        let elementIdAddSub = funcId + "_add_header";
        let elementIdEdit = funcId + "_" + currentLabel + "_edit";
        let elementIdEditSub = funcId + "_edit_header";
        let elementIdDelete = funcId + "_" + currentLabel + "_delete";
        let elementIdDeleteSub = funcId + "_delete_header";

        let checkboxAllHeader = document.getElementById(elementIdAllHeader);
        let checkboxAll = document.getElementById(elementIdAll);
        let checkboxView = document.getElementById(elementIdView);
        let checkboxAdd = document.getElementById(elementIdAdd);
        let checkboxEdit = document.getElementById(elementIdEdit);
        let checkboxDelete = document.getElementById(elementIdDelete);

        //Subs
        let checkboxViewSubs = document.getElementById(elementIdViewSub);
        let checkboxAddSubs = document.getElementById(elementIdAddSub);
        let checkboxEditSubs = document.getElementById(elementIdEditSub);
        let checkboxDelSubs = document.getElementById(elementIdDeleteSub);


        if (checkboxAllHeader) checkboxAllHeader.checked = false;
        if (checkboxAll) checkboxAll.checked = false;
        if (checkboxView) checkboxView.checked = false
        if (checkboxAdd) checkboxAdd.checked = false
        if (checkboxEdit) checkboxEdit.checked = false
        if (checkboxDelete) checkboxDelete.checked = false


        if (ctrlBinFunc == "1111" || ctrlBinFunc == 1111) {
            if (checkboxAllHeader) checkboxAllHeader.checked = true;
            if (checkboxAll) checkboxAll.checked = true;
            let isAbleView = ctrlBinFunc[0];
            let isAbleAdd = ctrlBinFunc[1];
            let isAbleEdit = ctrlBinFunc[2];
            let isAbleDelete = ctrlBinFunc[3];
            if (checkboxViewSubs) checkboxViewSubs.checked = true
            if (checkboxAddSubs) checkboxAddSubs.checked = true
            if (checkboxEditSubs) checkboxEditSubs.checked = true
            if (checkboxDelSubs) checkboxDelSubs.checked = true

            if (checkboxView) checkboxView.checked = isAbleView == 1 ? true : false
            if (checkboxAdd) checkboxAdd.checked = isAbleAdd == 1 ? true : false
            if (checkboxEdit) checkboxEdit.checked = isAbleEdit == 1 ? true : false
            if (checkboxDelete) checkboxDelete.checked = isAbleDelete == 1 ? true : false
        } else {

            let isAbleView = ctrlBinFunc[0];
            let isAbleAdd = ctrlBinFunc[1];
            let isAbleEdit = ctrlBinFunc[2];
            let isAbleDelete = ctrlBinFunc[3];

            if (checkboxView) checkboxView.checked = isAbleView == 1 ? true : false
            if (checkboxAdd) checkboxAdd.checked = isAbleAdd == 1 ? true : false
            if (checkboxEdit) checkboxEdit.checked = isAbleEdit == 1 ? true : false
            if (checkboxDelete) checkboxDelete.checked = isAbleDelete == 1 ? true : false
            if (checkboxViewSubs) checkboxViewSubs.checked = isAbleView == 1 ? true : false
            if (checkboxAddSubs) checkboxAddSubs.checked = isAbleAdd == 1 ? true : false
            if (checkboxEditSubs) checkboxEditSubs.checked = isAbleEdit == 1 ? true : false
            if (checkboxDelSubs) checkboxDelSubs.checked = isAbleDelete == 1 ? true : false

        }
    }
    const initOrderItems = (accessLevel) => {

        if (accessLevel == null) return;

        let accessLvl = {};
        if (accessLevel) {
            accessLvl = JSON.parse(accessLevel['ut_accesslevel'])
        }
        let orderSetup = (accessLvl?.al_validity ?? {});

        setClientOrderOri(orderSetup)

        if (orderSetup) {
            var exchangeListLength = getOrderValidityItem().length;
            var checkAllLength = (getOrderValidityItem().length);
            var exchangeLists = getExchangeMenu();
            let orderItem = getOrderValidityItem();
            let al_validity = {};
            let al_validate_counter = {};


            for (let j = 0; j < exchangeLists.length; j++) {
                let itemVal = orderSetup[exchangeLists[j]['em_code']] ?? orderSetup[exchangeLists[j]['e_code']];
                var base2Exchange = Array.from(changeBase(itemVal, 10, 2)).reverse().join('');
                base2Exchange = zeroFill(base2Exchange, exchangeListLength);
                for (let k = 0; k < exchangeListLength; k++) {
                    let checkboxId = (orderItem[k] + "_" + exchangeLists[j]['e_code']).toLowerCase();
                    let checkbox = document.getElementById(checkboxId);
                    if (base2Exchange[k] == "1") {
                        if (checkbox) checkbox.checked = true;
                        if (!al_validate_counter[exchangeLists[j]['e_code']]) al_validate_counter[exchangeLists[j]['e_code']] = 0;
                        al_validate_counter[exchangeLists[j]['e_code']] += 1;
                    } else {
                        if (checkbox) checkbox.checked = false
                    }
                }
            }

            for (var prop in al_validate_counter) {
                if (al_validate_counter[prop] == checkAllLength) {
                    let checkboxId = "ordervalidity_" + prop.toLowerCase()
                    let checkbox = document.getElementById(checkboxId);
                    if (checkbox) checkbox.checked = true;
                }
            }
        }
        let exchgOrders = (accessLvl?.al_trading ?? {});

        if (exchgOrders) {
            let tempOrder = {};
            for (let key in exchgOrders) {
                let array = exchgOrders[key];
                for (let i = 0; i < array.length; i++) {
                    // Convert decimal to binary and reverse it
                    let binaryReversed = array[i].toString(2).split('').reverse().join('');
                    base2Exchange = zeroFill(binaryReversed, 33 ?? 0);
                    array[i] = base2Exchange;
                    tempOrder[key] = array;
                }
            }

            for (let key in tempOrder) {
                let exchange = key.toLowerCase();
                let binaries = tempOrder[key];
                let orderItems = getExchangeOrderType();

                for (let i = 0; i < orderItems.length - 1; i++) {
                    let normalItems = orderItems[i]['subMenu'];
                    let calculateQty = 0;

                    for (let j = 0; j < normalItems.length; j++) {
                        let eleLabel = normalItems[j]['label'] ?? "";
                        let eleLabelTemp = normalItems[j]['label'] ?? "";
                        let specialPrefix = ""
                        let elePosition = normalItems[j]['position'] ?? "";
                        let checkboxId = normalItems[j]['checkboxId'] ?? "";


                        if (binaries[i][elePosition] == 1) {
                            let checkBox = document.getElementById(checkboxId + exchange);
                            if (checkBox) checkBox.checked = true;
                            calculateQty += 1;
                        }

                        if (eleLabel.includes(';')) {
                            eleLabel = eleLabel.split(";")[1];
                            specialPrefix = eleLabelTemp.split(";")[0] + "_";
                            if (specialPrefix.includes("odd")) specialPrefix = "oddlots_";
                        }

                        if (i == 3) specialPrefix = "advanced_";
                        if (i == 4) specialPrefix = "others_";

                        if (calculateQty == normalItems.length) {
                            let checkBoxAll = document.getElementById(specialPrefix + exchange);
                            if (checkBoxAll) checkBoxAll.checked = true;
                        }

                    }

                }

            }

        }


    }

    const handleOperationSaveItems = () => {
        let renderList = getFunctionMenus();
        let saveList = [];
        let specialPermProfile = {};

        for (let i = 0; i < renderList.length; i++) {
            let parEle = renderList[i];
            let parentLbl = parEle['label'].replaceAll(/[\(\)]/g, "")


            let perm = zeroFill(checkPermCheckedById(parEle['id'], parentLbl), 4);
            var itemInDecimal = changeBase(Array.from(perm.toString().replaceAll(",", "")).reverse().join(''), 2, 10);
            saveList.push({"page": parEle['id'], "ctrl": itemInDecimal, "am": 0})

            if (renderList[i]['subMenu']) {
                let dataEle = renderList[i]['subMenu'];

                for (let j = 0; j < dataEle.length; j++) {
                    let perm = zeroFill(checkPermCheckedById(dataEle[j]['id'], parentLbl), 4);
                    var itemInDecimal = changeBase(Array.from(perm.toString().replaceAll(",", "")).reverse().join(''), 2, 10);


                    saveList.push({"page": dataEle[j]['id'], "ctrl": itemInDecimal, "am": 0})
                    if (dataEle[j]['subMenu2']) {
                        let dataEle2 = dataEle[j]['subMenu2'];
                        for (let k = 0; k < dataEle2.length; k++) {
                            let perm = zeroFill(checkPermCheckedById(dataEle2[k]['id'], parentLbl), 4);
                            var itemInDecimal = changeBase(Array.from(perm.toString().replaceAll(",", "")).reverse().join(''), 2, 10);
                            if (dataEle2[k]['id'] == 64 || dataEle2[k]['id'] == 65 || dataEle2[k]['id'] == 4) {
                                specialPermProfile[dataEle2[k]['id']] = itemInDecimal;
                            } else {
                                saveList.push({"page": dataEle2[k]['id'], "ctrl": itemInDecimal, "am": 0})

                            }
                        }
                    }
                }
            }


        }

        //Special for profile only
        let specialAmAL = 0;
        if (specialPermProfile[64] == 1 && specialPermProfile[65] == 1) {
            specialAmAL = 3;
        } else if (specialPermProfile[64] == 1 && specialPermProfile[65] == 0) {

            specialAmAL = 1;
        } else if (specialPermProfile[64] == 0 && specialPermProfile[65] == 1) {
            specialAmAL = 2;
        }

        let tempDat = {"page": 4, "ctrl": specialPermProfile[4], "am": specialAmAL};
        //Splice into 3 because its id 4
        saveList.splice(3, 0, tempDat);

        const sortedArray = saveList.sort((a, b) => a.page - b.page);

        let data = {
            "id": parseInt(currentUserId),
            "access": JSON.stringify(sortedArray),
        };

        api.updateOperationAccessMatList(data).then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }

            if (result != false && result.status == 0) {
                setLoading(false);
                handlePopup(true, "Success", "Access matrix updated successfully.", popupManager)
                handleInitList(location.state.row.ut_id, location.state.row.uc_name.toLowerCase());
            }
        });


    }

    const checkPermCheckedById = (funcId, parentLbl = "") => {

        parentLbl = parentLbl.replace(/\s/g, "").toLowerCase();
        let label = getNavItemById(funcId)['actualLbl'].replace(/\s/g, "").replaceAll(/[\(\)]/g, "").toLowerCase();
        let specialLabel = (getNavItemById(funcId)['special_lbl'] ?? "").replace(/\s/g, "").replaceAll(/[\(\)]/g, "").toLowerCase();
        let currentPerm = 0;
        let currentLabel = label;
        if (funcId == 11 || funcId == 12 || funcId == 58 || funcId == 59 || funcId == 60) {
            currentLabel = specialLabel + "-" + label;
        } else if ((parentLbl.includes("riskmgmt") || parentLbl.includes("masterfile")) && parentLbl != label) {
            currentLabel = parentLbl + "-" + label;
        }


        let elementIdAllHeader = funcId + "_" + currentLabel + "_all_header";
        let elementIdAll = funcId + "_" + currentLabel + "_all";
        let elementIdViewHeader = funcId + "_view_header";
        let elementIdView = funcId + "_" + currentLabel + "_view";
        let elementIdAddHeader = funcId + "_add_header";
        let elementIdAdd = funcId + "_" + currentLabel + "_add";
        let elementIdEditHeader = funcId + "_edit_header";
        let elementIdEdit = funcId + "_" + currentLabel + "_edit";
        let elementIdDeleteHeader = funcId + "_delete_header";
        let elementIdDelete = funcId + "_" + currentLabel + "_delete";


        let checkboxAllHeader = document.getElementById(elementIdAllHeader);
        let checkboxAll = document.getElementById(elementIdAll);
        let checkboxViewHeader = document.getElementById(elementIdViewHeader);
        let checkboxView = document.getElementById(elementIdView);
        let checkboxAddHeader = document.getElementById(elementIdAddHeader);
        let checkboxAdd = document.getElementById(elementIdAdd);
        let checkboxEditHeader = document.getElementById(elementIdEditHeader);
        let checkboxEdit = document.getElementById(elementIdEdit);
        let checkboxDeleteHeader = document.getElementById(elementIdDeleteHeader);
        let checkboxDelete = document.getElementById(elementIdDelete);

        if (checkboxAllHeader && checkboxAllHeader.checked) currentPerm = 1111;
        else if (checkboxAll && checkboxAll.checked) currentPerm = 1111;
        else {
            let perms = "";
            if (checkboxView && checkboxView.checked) perms += 1
            else if (checkboxViewHeader && checkboxViewHeader.checked) perms += 1
            else perms += 0

            if (checkboxAdd && checkboxAdd.checked) perms += 1
            else if (checkboxAddHeader && checkboxAddHeader.checked) perms += 1

            else perms += 0

            if (checkboxEdit && checkboxEdit.checked) perms += 1
            else if (checkboxEditHeader && checkboxEditHeader.checked) perms += 1

            else perms += 0

            if (checkboxDelete && checkboxDelete.checked) perms += 1
            else if (checkboxDeleteHeader && checkboxDeleteHeader.checked) perms += 1
            else perms += 0

            currentPerm = perms;
        }

        return currentPerm;
    }


    const handleMatrixArea = () => {
        if (currentUserCategory.toLowerCase() == "operation") {
            return renderOperationForm([]);
        } else if (currentUserCategory.toLowerCase() == "superadmin") {
            setLoading(true);
            handlePopup(true, "Error", "Super admin access matrix is not editable.", popupManager)
            navigate("/" + process.env.REACT_APP_URL_PREFIX + "user_mat",);
            setLoading(false);
        } else {
            console.log(currentUserCategory.toLowerCase() )
            // userId, userName, marketBtnSetter = false, orderBtnSetter = false, currentPortion = false, clientMarketOri = false, clientOrderOri = false, popupManager = false
           if(currentUserCategory.toLowerCase() == 'client')  return renderDealerForm([], null, setIsMarketNow, setIsOrderNow, setCurrentClientOrderPortion, clientMarketOri, clientOrderOri, popupManager,null,true);
            return renderDealerForm([], null, setIsMarketNow, setIsOrderNow, setCurrentClientOrderPortion, clientMarketOri, clientOrderOri, popupManager);

        }
    }


    // Edit
    const handleBackBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "user_mat", {state: {mode: 'back'}});
        setLoading(false);
    };

    const handleRemoveBtn = async () => {
        await setLoading(true);
        popupManager.open(ModalConfirm, {
            title: 'Reset',
            content: 'Are you sure you want to reset access matrices to default value?',
            onClose: (...params) => handleRemoveItem(params[0])
        });

        setLoading(false);
    };

    const handleRemoveItem = async (selBoolean) => {
        if (!selBoolean) return;
        handleInitList(location.state.row.ut_id, location.state.row.uc_name.toLowerCase());
    };


    const handleSaveBtn = async () => {
        await setLoading(true);

        if (location.state.row.uc_name.toLowerCase() == 'operation') {
            handleOperationSaveItems();
        } else {
            //Market data update
            var exchangeListLength = getExchangeQty();
            var exchangeLists = getExchangeMenu();
            if (!currentUserAccessLevel) setCurrentUserTypeAccessLevel("{\"al_exchange\": 0, \"al_trading\": [], \"al_tools\": 0}")

            let accessLvl = JSON.parse(currentUserAccessLevel.replace(/\s/g, ""));

            let exchangeSetup = (accessLvl?.al_exchange[0] ?? 0);

            var base3Exchange = Array.from(changeBase(exchangeSetup, 10, 3)).reverse().join('');
            base3Exchange = zeroFill(base3Exchange, exchangeListLength);

            let exchangeItem = [];
            for (let i = 0; i < exchangeListLength; i++) {
                let radios = document.getElementsByName("eq_" + exchangeLists[i]?.em_id);
                if(!exchangeLists[i]?.em_id && exchangeLists[i]?.em_id !=0) {
                    exchangeItem[i] = '0';
                    break;
                }
                if (i == 4) exchangeItem[4] ='0';
                if (i == 7) exchangeItem[7] = '0';
                if (i == 11) exchangeItem[11] = '0';
                if (i == 14) exchangeItem[14] = '0';
                if (i == 15) exchangeItem[15] = '0';
                if (exchangeLists[i]["em_id"] != '4' && exchangeLists[i]["em_id"] != '7') {
                    for (let j = 0; j < radios.length; j++) {
                        if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_disabled" && radios[j].checked) {
                            exchangeItem[exchangeLists[i]["em_id"]] = "0";
                            break;
                        } else if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_delay" && radios[j].checked) {
                            exchangeItem[exchangeLists[i]["em_id"]] = "2";
                            break;
                        } else if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_live" && radios[j].checked) {
                            exchangeItem[exchangeLists[i]["em_id"]] = "1";
                            break;
                        }
                    }
                }
            }

            let toolsMenuList = getToolsMenu();
            let toolsSetup = (accessLvl?.al_tools ?? 0);

            var base2Exchange = Array.from(changeBase(toolsSetup, 10, 2)).reverse().join('');
            base2Exchange = zeroFill(base2Exchange, toolsMenuList.length);

            let toolItem = [];
            for (let i = 0; i < toolsMenuList.length; i++) {
                let value = base2Exchange[toolsMenuList[i]["id"]];
                let checkbox = document.getElementById("tool_" + toolsMenuList[i]["id"]);
                if (value && checkbox.checked) {
                    toolItem[toolsMenuList[i]["id"]] = 1
                } else {
                    toolItem[toolsMenuList[i]["id"]] = 0

                }
            }

            var equityItemDecimal = changeBase(Array.from(exchangeItem.toString().replaceAll(",", "")).reverse().join(''), 3, 10);
            var toolItemDecimal = changeBase(Array.from(toolItem.toString().replaceAll(",", "")).reverse().join(''), 2, 10);

            let orderItems = getExchangeOrderType();

            let orderValidityItem = {};
            let orderValidityItemSubmit = {};

            let orderItemSubmit = {};
            let debugItem = {};


            for (let i = 0; i < orderItems.length; i++) {
                let tempOrderItm0 = new Array(33).fill("0");
                switch (i) {
                    //Orders
                    default:
                        let normalItems = orderItems[i]['subMenu'];

                        for (let k = 0; k < exchangeLists.length; k++) {
                            let currentExchange = exchangeLists[k]['e_code'];

                            for (let j = 0; j < normalItems.length; j++) {

                                let normalMainEle = normalItems[j]['label'] ?? "";
                                let elePosition = normalItems[j]['position'] ?? "";
                                let tempEle = normalItems[j]['label'] ?? "";
                                let specialPrefix = "";

                                if (tempEle.includes(';')) {
                                    tempEle = normalMainEle.split(";")[1];
                                    specialPrefix = "_" + normalMainEle.split(";")[0];
                                }
                                let elementId = (tempEle.replaceAll(' ', '') + specialPrefix + "_" + currentExchange).toLowerCase();
                                let checkBox = document.getElementById(elementId);

                                tempOrderItm0[elePosition] = checkBox.checked ? "1" : "0";
                                if (!orderItemSubmit[currentExchange]) orderItemSubmit[currentExchange] = [];
                                if (!debugItem[currentExchange]) debugItem[currentExchange] = [];
                                debugItem[currentExchange][i] = tempOrderItm0.join().replaceAll(",", "");
                                let conversion = changeBase(Array.from(tempOrderItm0.join().replaceAll(",", "")).reverse().join(''), 2, 10);
                                orderItemSubmit[currentExchange][i] = conversion;
                            }
                        }
                        break

                    case 5:
                        //Validity
                        let validityItems = orderItems[i]['subMenu'];
                        for (let i = 0; i < validityItems.length; i++) {
                            for (let j = 0; j < exchangeLists.length; j++) {
                                let checkboxId = (validityItems[i]['code'] + "_" + exchangeLists[j]['e_code']).toLowerCase();
                                let checkbox = document.getElementById(checkboxId);
                                if (!orderValidityItem[exchangeLists[j]['e_code']]) orderValidityItem[exchangeLists[j]['e_code']] = (checkbox.checked) ? "1" : "0";
                                else orderValidityItem[exchangeLists[j]['e_code']] += (checkbox.checked) ? "1" : "0";
                            }
                            for (var prop in orderValidityItem) {
                                base2Exchange = zeroFill(orderValidityItem[prop], validityItems.length ?? 0);
                                orderValidityItemSubmit[prop] = changeBase(Array.from(base2Exchange.toString().replaceAll(",", "")).reverse().join(''), 2, 10);
                            }
                        }
                        break

                }

            }



            if (currentClientOrderPortion === 'market') {
                handleSaveMarketMatrix(equityItemDecimal, toolItemDecimal);
            } else if (currentClientOrderPortion === 'order') {
                handleSaveOrderMatrix(orderValidityItemSubmit, orderItemSubmit);
            }
        }
    };


    const handleSaveMarketMatrix = async (equity, tool) => {
        await setLoading(true);
        let data = {
            "id": parseInt(currentUserId),
            "equity": equity,
            "tool": tool,
            "depth": market_depth,
        };


        api.updateUserAccessMatrixMarket(data).then((result: any) => {
            setLoading(false);

            if (result != false && result.status == 0) {
                handleInitList(location.state.row.ut_id, location.state.row.uc_name.toLowerCase());
                handlePopup(true, "Success", "User market matrix updated successfully.", popupManager)

            } else handlePopup(true, "Error", convertErMsg(result.message), popupManager);

        });

    };

    const handleSaveOrderMatrix = async (items, orderItemSubmit) => {
        await setLoading(true);
        let data = {
            "id": parseInt(currentUserId),
            "validity": JSON.stringify(items).replace(/\s/g, ""),
            "order": JSON.stringify(orderItemSubmit).replace(/\s/g, ""),
            // "order": "[]"

        };

        api.updateOrderValidityUserType(data).then((result: any) => {
            setLoading(false);

            if (result != false && result.status == 0) {
                handleInitList(location.state.row.ut_id, location.state.row.uc_name.toLowerCase());

                handlePopup(true, "Success", "User order matrix updated successfully.", popupManager)
            } else handlePopup(true, "Error", convertErMsg(result.message), popupManager);
        });


    }


    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; User
                                Maintenance &gt; Access Matrix </h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1">User Type</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="underline-input"
                                            readOnly={true}
                                            type="text"
                                            placeholder="e.g. Super User"
                                            aria-label=""
                                            value={currentUserTypeName}
                                            onChange={(e) => setCurrentUserTypeName(e.target.value)}
                                        />

                                    </div>
                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1">User Category</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="underline-input"
                                            readOnly={true}
                                            type="text"
                                            placeholder="e.g. Operation"
                                            aria-label=""
                                            value={currentUserCategory}
                                            onChange={(e) => setCurrentUserType(e.target.value)}
                                        />

                                    </div>
                                </div>


                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1">Description</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="underline-input"
                                            type="text"
                                            readOnly={true}
                                            placeholder="e.g. With highest access level to all modules, except Trading Platform"
                                            aria-label=""
                                            value={currentUserTypeDesc}
                                            onChange={(e) => setCurrentUserTypeDesc(e.target.value)}
                                        />

                                    </div>

                                </div>


                            </div>
                        </div>

                    </div>

                </div>

                {/*Operation Section*/}

                {handleMatrixArea()}
                {/*Client/Dealer Section*/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div className="card-body">
                            <div className="col-12 search-container ">

                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1"></div>
                                    <div className="label col-md-11 btn_search_padding">
                                        <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                        </button>
                                        {accessItem['edit'] ?
                                            <>
                                                <button className="btn btn-primary "
                                                        onClick={() => handleRemoveBtn()}>Reset
                                                </button>
                                                <button className="btn btn-primary "
                                                        onClick={() => handleSaveBtn()}>Save
                                                </button>
                                            </>
                                            :
                                            ""
                                        }


                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default Index;
