import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';
import {CSVLink} from "react-csv";
import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    convertFuncPerm,
    dateFormats,
    dealerStatusOptions,
    getDefaultAccessMat,
    getNavIdByPath,
    handlePopup,
    handleSelectChange,
    momentTimeFormat,
    useLocalStorage,
    convertErMsg,
    specialCharCheck,
    performAntdTimeCheck,
    getNavItemById,
    userTypeCategory,
    accountTypeOption, profileStatusOption, accessStatusOptions
} from "../../../general/general-tools";
import {getAuditPdfStyling} from "../../../general/pdf-tools"
import {usePopupManager} from "react-popup-manager";
import {useCookies} from 'react-cookie';

import {getUserAccessDetail} from "../../../../hooks/handleCookie";
import ReactDatepick from "../../../general/form-control/react-datepick";
import dayjs from "dayjs";
import Multiselect from "multiselect-react-dropdown";
import {
    Document,
    Page,
    PDFDownloadLink,
    Text,
    View,
    Image,
    Font
} from "@react-pdf/renderer";
import FontFaRegular400 from "../../../asset/font-awesome/webfonts/NotoSans-Medium.ttf";
import FontFaRSolid900 from "../../../asset/font-awesome/webfonts/NotoSans-Bold.ttf";
import companyLogo from "../../../asset/pdfheader/header.png";
import UserTypeList from "./sub-components/UserTypeList";
import AccessMatrix from "./sub-components/AccessMatrix";
import ClientAmend from "./sub-components/ClientAmend";
import UserProfile from "./sub-components/UserProfile";
import UserAccess from "./sub-components/UserAccess";
import DealerGroup from "./sub-components/DealerGroup";
import {calculateDuration, formatDates, handleInitProfileList, printProfileList} from "./MstEnquiryTools";
import Button from "react-bootstrap/Button";
import UserClientProfilePDF from "./pdf-components/UserClientProfilePDF";
import UserDealerProfilePDF from "./pdf-components/UserDealerProfilePDF";
import UserOperationProfilePDF from "./pdf-components/UserOperationProfilePDF";
import {tab} from "@testing-library/user-event/dist/tab";


const Index = () => {
    const {setLoading} = useLoading();
    const [cookies, setCookie, removeCookie] = useCookies(['admin_user']);

    const [tableData, setTableData] = useLocalStorage("mst_enqry_tblDat", []);
    const [totalRecords, setTotalRecords] = useLocalStorage("mst_enqry_totRec", 0);
    const [currentPage, setCurrentPage] = useLocalStorage("mst_enqry_curPg", 1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage("mst_enqry_curPrPg", 10);

    const [searchMenuId, setSearchMenuId] = useLocalStorage("mst_enqry_menuId", []);
    const [searchMenuIdList, setSearchMenuIdList] = useLocalStorage("mst_enqry_menuIdList", []);


    const [searchEffFrom, setSearchEffFrom] = useLocalStorage("mst_enqry_searchEffFrom", null);
    const [searchEffTo, setSearchEffTo] = useLocalStorage("mst_enqry_searchEffFrom", null);

    let navigate = useNavigate();
    const location = useLocation();
    const isInitialMount = useRef(true);
    const popupManager = usePopupManager();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat() : getUserAccessDetail();
    // let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);
    const [toggleReset, setToggleReset] = useState(false);
    const [isDateValid, setIsDateValid] = useState(false);

    //PDF use
    const [currentCompanyCode, setCurrentCompanyCode] = useState("");
    const [currentBranchCode, setCurrentBranchCode] = useState("");
    const [currentCompanyName, setCurrentCompanyName] = useState("");

    const [currentCompanyAddress1, setCurentBranchAddress1] = useState("");
    const [currentCompanyAddress2, setCurentBranchAddress2] = useState("");
    const [currentCompanyAddress3, setCurentBranchAddress3] = useState("");
    const [currentCompanyPostCode, setCurrentCompanyPostCode] = useState("");
    const [currentCompanyState, setCurrentCompanyState] = useState("");
    const [currentCompanyCountry, setCurrentCompanyCountry] = useState("");
    const [currentCompanyCountryText, setCurrentCompanyCountryText] = useState("");
    const [currentCompanyPhoneNum, setCurrentCompanyPhoneNum] = useState("");
    const [currentCompanyWebsite, setCurrentCompanyWebsite] = useState("");
    const [auditLogName, setAuditLogName] = useState("enquiry_" + moment().format("DDMMYYYYhhmmss"));
    const [userName, setUserName] = useState('');
    const [testBool, setTestBool] = useState(false);


    //Enquiry type -> to display the correct enquiry report criteria
    const [enqCurrentEnqTypeId, setEnqCurrentEnqTypeId] = useState(0);


    //User type
    const [showUserTypeList, setShowUserTypeList] = useState(false);
    const [currentUsrTypeId, setCurrentUsrTypeId] = useState(0);


    //Client amendment
    const [showUserClientAmendment, setShowClientAmendmentList] = useState(false);
    const [enqOrderId, setEnqOrderId] = useState("");

    const [enqSearchName, setEnqSearchName] = useState("");
    const [enqStockCode, setEnqStockCode] = useState("");
    const [enqAccNum, setEnqAccNum] = useState("");
    const [enqAmendAccNum, setEnqAmendAccNum] = useState("");
    const [enqBranchCode, setEnqBranchCode] = useState("");
    const [enqBranchList, setEnqBranchList] = useState([]);
    const [enqOrderEntryDate, setEnqOrderEntryDate] = useState("");
    const [enqOrderMatchedDate, setEnqOrderMatchedDate] = useState("");
    const [enqApprovedDate, setEnqApprovedDate] = useState("");
    const [enqApprovedBy, setEnqApprovedBy] = useState("");
    const [enqStockList, setEnqStockList] = useState([]);

    // - Profile
    const [showUserProfileList, setShowUserProfileList] = useState(false);
    const [showUserProfilePDF, setShowUserProfilePDF] = useState(false);
    const [showUserDealerPDF, setShowUserDealerPDF] = useState(false);
    const [showUserOperationPDF, setShowOperationProfilePDF] = useState(false);
    const [isProfileInActiveDisabled, setIsProfileInActiveDisabled] = useState(false);
    const [currentUserId, setCurrentUserId] = useState("");
    const [currentUserRow, setCurrentUserRow] = useState([]);

    const [searchUserId, setSearchUserId] = useState("");
    const [searchUserName, setSearchUserName] = useState("");
    const [searchUserType, setSearchUserType] = useLocalStorage("");
    const [searchAccountNo, setSearchAccountNo] = useState("");
    const [searchAccountType, setSearchAccountType] = useState("");
    const [searchDealerCode, setSearchDealerCode] = useState("");
    const [searchNRIC, setSearchNRIC] = useState("");
    const [searchBranch, setSearchBranch] = useState("");
    const [searchPassport, setSearchPassport] = useState("");
    const [searchStatus, setSearchStatus] = useState("");
    const [branchList, setBranchList] = useState([]);
    const [userTypeList, setUserTypeList] = useState([]);

    // - User Access
    const [showUserUserAccessList, setShowUserAccessList] = useState(false);
    const [uaUserType, setUaUserType] = useState("");
    const [uaUserName, setUaUserName] = useState("");
    const [uaAccessStatus, setUaAccessStatus] = useState("");

    // - Dealer group association
    const [showUserDealerGroupList, setShowDealerGroupList] = useState(false);
    const [currentGroupId, setCurrentGroupId] = useState("");
    const [searchGroupInput, setSearchGroupInput] = useState('');
    const [searchGroupName, setSearchGroupName] = useState('');


    const [csvContentHeader, setCsvContentHeader] = useState([]);
    const [csvContent, setCsvContent] = useState([]);
    const [printingCompanyDetail, setPrintingCompanyDetail] = useState({});
    const [printingDateStamp, setPrintingDateStamp] = useState("-");
    const [printingDateRange, setPrintingDateRange] = useState("-");


    const linkRef = useRef(null)
    const multiselectRef = useRef();

    const resetSelectField = () => {
        multiselectRef.current.resetSelectedValues();
    };

    useEffect(() => {
        performAntdTimeCheck(searchEffFrom, searchEffTo, setSearchEffTo, popupManager, setIsDateValid);
    }, [searchEffFrom, searchEffTo]);

    useLayoutEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        handleMenuList();
        handleBranchList();
        handleCompanyDetail();

        setUserName(cookies.admin_user.username);
        if (tableData.length > 0) {
            // handleInitList(searchStatus, searchInput, searchEffFrom, searchEffTo)
        } else {
            handleResetBtn();
        }
    }, [])


    const handleCompanyDetail = () => {
        api.getCompanyDetail().then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }

            let companyEle = result.data.list[0];

            let tmpCompanyDetail = {
                company_name: companyEle.b_name,
                company_address1: companyEle.b_address_line1,
                company_address2: companyEle.b_address_line2 ?? "-",
                company_address3: companyEle.b_address_line3 ?? "-",
                company_postcode: companyEle.b_postcode ?? "-",
                company_phone: companyEle.b_phone ?? "-",
                company_website: companyEle.b_website ?? "-",
            }


            setPrintingCompanyDetail(tmpCompanyDetail);
        });

    }

    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {
        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }

    useEnterKeyPress(handleEnterKeyPress);


    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleSearchItem()
        }

    }, [currentPage, currentPerPage]);
    // UserDealerProfilePDF

    useEffect(() => {
        if (currentUserRow.length == 0 || !currentUserRow) return;
        switch (enqCurrentEnqTypeId) {
            case '39': //access matrix
            case '41': //user type
            case '49': //Client limit
                //Show user type list
                break;
            case '40': // client amendment
                //Show client amendment list
                break;
            case '42': //inactive client
                handleShowContents();
                setShowUserProfilePDF(true);
                break;
            case '43': //inactive delaer-remisier
                handleShowContents();
                setShowUserDealerPDF(true);
            case '44': //inactive user enquiry
                handleShowContents();
                setShowOperationProfilePDF(true);
                break;
            case '45': //concurrent user
            case '47': //dealer-remisier enquiry
                // setIsProfileInActiveDisabled(true);
                // handleInitProfileList({
                //     "term": searchUserId,
                //     "aterm": searchAccountNo,
                //     "nterm": searchUserName,
                //     "atterm": searchAccountType,
                //     "utterm": searchUserType,
                //     "dterm": searchDealerCode,
                //     "iterm": searchNRIC,
                //     "bterm": searchBranch[0] == null ? "" : searchBranch[0]['id'], //BB_ID
                //     "pterm": searchPassport,
                //     "status": "0",
                //     "size": currentPerPage,
                //     "page": currentPage,
                // });
                break;
            case '46': //client login
            case '48': //user login
                break;
            case '50': //dealer group association
                break;
            case '51': //Client portfolio
            case '52': //Trade enquiry
            case '53': //Tnc
            case '54': //Foreign exchange tnc
                //[TBC, should be new search criteria]
                break;
            default:
                break;

        }
    }, [currentUserRow]);


    const handleMenuList = async () => {
        let accessLvl = cookies.access_level;
        let list = convertMenuListIntoDropdown(accessLvl)
        setSearchMenuIdList(list);
    }
    const convertMenuListIntoDropdown = (data) => {
        var returnDat = [];
        Object.entries(data).forEach(([key, value]) => {
            if (value) {
                if (data[38] == "1111" && (key >= 39 && key <= 54)) {
                    let item = getNavItemById(key);
                    if (item['parent']) {
                        returnDat.push({
                            parent: item['parent'],
                            name: item['actualLbl'],
                            id: key
                        });
                    }

                }
            }

        });

        return returnDat;
    }


    const handleBranchList = async () => {
        setLoading(true);
        api.getBranchList(999, 1, 1, null).then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }
            if (result.data) {
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdown(dataList);
                    setBranchList(TreeData);
                } else {
                    handlePopup(true, "Error", "No results found", popupManager)
                    setTableData([]);
                    setTotalRecords(10);
                }
            } else {
                handlePopup(true, "Error", "No results found", popupManager)
                setTableData([]);
                setTotalRecords(10);
            }
        });
        setLoading(false);
    };
    const convertRawIntoDropdown = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                code: data[i]['bb_code'],
                name: data[i]['bb_code'] + " | " + data[i]['bb_name'],
                id: data[i]['bb_id']
            });
        }
        return returnDat;
    }
    const onSelectBranch = (selectedList, selectedItem) => {
        setSearchBranch(selectedList);
    }
    const onRemoveBranch = (selectedList, selectedItem) => {
        setSearchBranch("");
    }


    const toggleMenuExpand = () => {
        var expandableDiv = document.getElementById('div_top');
        var div_top_arrow = document.getElementById('div_top_arrow');

        if (expandableDiv.classList.contains('enquiryFadeOutHide')) {
            expandableDiv.classList.remove('enquiryFadeOutHide');
            expandableDiv.classList.add('enquiryFadeOutShow');
        } else {
            expandableDiv.classList.add('enquiryFadeOutHide');
            expandableDiv.classList.remove('enquiryFadeOutShow');
        }

        div_top_arrow.classList.toggle('arrow-up');
        div_top_arrow.classList.toggle('arrow-down');
    }
    const onMenuRemove = (selectedList, selectedItem) => {
        setSearchMenuId(selectedList);
        setTableData([]);
        var div_list = document.getElementById('div_list');
        var div_content = document.getElementById('div_enquiry_content');

        div_list.classList.remove('enquiryFadeOutShow');
        div_list.classList.add('enquiryFadeOutHide');
        div_content.classList.remove('enquiryFadeOutShow');
        div_content.classList.add('enquiryFadeOutHide');

        handleResetBtn();
    }
    const handleShowContents = (type) => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        var div_list = document.getElementById('div_list');
        var div_content = document.getElementById('div_enquiry_content');

        div_list.classList.remove('enquiryFadeOutShow');
        div_list.classList.add('enquiryFadeOutHide');

        toggleMenuExpand();
        div_content.classList.remove('enquiryFadeOutHide');
        div_content.classList.add('enquiryFadeOutShow');
    }
    const toggleBackFromReport = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        var expandableDiv = document.getElementById('div_top');
        var div_top_arrow = document.getElementById('div_top_arrow');

        if (expandableDiv.classList.contains('enquiryFadeOutHide')) {
            expandableDiv.classList.remove('enquiryFadeOutHide');
            expandableDiv.classList.add('enquiryFadeOutShow');
        } else {
            expandableDiv.classList.add('enquiryFadeOutHide');
            expandableDiv.classList.remove('enquiryFadeOutShow');
        }

        var div_list = document.getElementById('div_list');
        var div_content = document.getElementById('div_enquiry_content');

        if (div_list.classList.contains('enquiryFadeOutHide')) {
            div_list.classList.remove('enquiryFadeOutHide');
            div_list.classList.add('enquiryFadeOutShow');
        } else {
            div_list.classList.add('enquiryFadeOutHide');
            div_list.classList.remove('enquiryFadeOutShow');
        }

        if (div_content.classList.contains('enquiryFadeOutHide')) {
            div_content.classList.remove('enquiryFadeOutHide');
            div_content.classList.add('enquiryFadeOutShow');
        } else {
            div_content.classList.add('enquiryFadeOutHide');
            div_content.classList.remove('enquiryFadeOutShow');
        }

    }

    const handleMenuSelect = (selectedList, selectedItem) => {
        handleCloseAllDivs();
        setSearchMenuId([selectedItem]);
        if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur();
        var div_list = document.getElementById('div_list');
        div_list.classList.remove('enquiryFadeOutHide');
        div_list.classList.add('enquiryFadeOutShow');

        setEnqCurrentEnqTypeId(selectedItem.id);
        switch (selectedItem.id) {
            case '39': //access matrix
            case '41': //user type
            case '49': //Client limit
                setShowUserTypeList(true);
                break;
            case '40': // client amendment
                setShowClientAmendmentList(true);
                break;
            case '42': //inactive client
            case '43': //inactive delaer-remisier
            case '44': //inactive user enquiry
                setShowUserProfileList(true);
                setIsProfileInActiveDisabled(true);
                setSearchStatus("0")
                handleUserTypeList(selectedItem.id);

                break;
            case '45': //concurrent user
            case '47': //dealer-remisier enquiry
                setShowUserProfileList(true);
                break;
            case '46': //client login
            case '48': //user login
                setShowUserAccessList(true);
                break;
            case '50': //dealer group association
                setShowDealerGroupList(true);
                break;
            case '51': //Client portfolio
            case '52': //Trade enquiry
            case '53': //Tnc
            case '54': //Foreign exchange tnc
                //[TBC, should be new search criteria]
                break;
            default:
                // handleResetBtn();
                break;

        }


    }
    const handleSearchItem = (newSearch) => {
        setCsvContentHeader([]);
        setCsvContent([]);
        switch (enqCurrentEnqTypeId) {
            case '39': //access matrix
            case '41': //user type
            case '49': //Client limit
                //Show user type list
                break;
            case '40': // client amendment
                //Show client amendment list
                break;
            case '42': //inactive client
            case '43': //inactive delaer-remisier
            case '44': //inactive user enquiry
                if (searchUserType == undefined || searchUserType == null || searchUserType == "") {
                    handlePopup(true, "Error", "Please select user type", popupManager)
                    return;
                    setLoading(false);
                }

                let format_date = formatDates(searchEffFrom, searchEffTo);
                let format_duration = calculateDuration(searchEffFrom, searchEffTo);
                setPrintingDateStamp(format_date);
                setPrintingDateRange(format_duration);

                handleInitProfileList({
                    "term": searchUserId,
                    "aterm": searchAccountNo,
                    "nterm": searchUserName,
                    "atterm": searchAccountType,
                    "utterm": searchUserType,
                    "dterm": searchDealerCode,
                    "iterm": searchNRIC,
                    "bterm": searchBranch[0] == null ? "" : searchBranch[0]['id'], //BB_ID
                    "pterm": searchPassport,
                    "status": 1,
                    "size": currentPerPage,
                    "page": currentPage,
                }, setTableData, setTotalRecords, setLoading, setCsvContentHeader, setCsvContent, popupManager);


                break;
            case '45': //concurrent user
            case '47': //dealer-remisier enquiry
                // setIsProfileInActiveDisabled(true);
                // handleInitProfileList({
                //     "term": searchUserId,
                //     "aterm": searchAccountNo,
                //     "nterm": searchUserName,
                //     "atterm": searchAccountType,
                //     "utterm": searchUserType,
                //     "dterm": searchDealerCode,
                //     "iterm": searchNRIC,
                //     "bterm": searchBranch[0] == null ? "" : searchBranch[0]['id'], //BB_ID
                //     "pterm": searchPassport,
                //     "status": "0",
                //     "size": currentPerPage,
                //     "page": currentPage,
                // });
                break;
            case '46': //client login
            case '48': //user login
                break;
            case '50': //dealer group association
                break;
            case '51': //Client portfolio
            case '52': //Trade enquiry
            case '53': //Tnc
            case '54': //Foreign exchange tnc
                //[TBC, should be new search criteria]
                break;
            default:
                // handleResetBtn();

                break;

        }
    }
    const handleUserTypeList = async (selectedId) => {
        setLoading(true);
        let tempArr = [];

        api.getUserTypeList(999, 1, 1, "").then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }
            if (result.data) {
                if (result.data.list.length > 0) {
                    let listEle = result.data.list;
                    switch (selectedId) {
                        case '39': //access matrix
                        case '41': //user type
                        case '49': //Client limit
                            //Show user type list
                            break;
                        case '40': // client amendment
                            //Show client amendment list
                            break;
                        case '42': //inactive client
                            //Filter only Client user type
                            tempArr = [];
                            for (let i = 0; i < listEle.length; i++) {
                                if (listEle[i]['ut_catid'] == 4) {
                                    tempArr.push(listEle[i]);
                                }
                            }
                            setUserTypeList(tempArr);
                            break;
                        case '43': //inactive delaer-remisier
                        case '47': //dealer-remisier enquiry
                            //Filter only delaer-remisier
                            tempArr = [];
                            for (let i = 0; i < listEle.length; i++) {
                                if (listEle[i]['ut_catid'] == 3) {
                                    tempArr.push(listEle[i]);
                                }
                            }
                            setUserTypeList(tempArr);

                            break;
                        case '44': //inactive user enquiry
                            //Filter admin
                            tempArr = [];
                            for (let i = 0; i < listEle.length; i++) {
                                if (listEle[i]['ut_catid'] == 2) {
                                    tempArr.push(listEle[i]);
                                }
                            }
                            setUserTypeList(tempArr);
                            break;
                        case '45': //concurrent user
                        case '46': //client login
                        case '48': //user login
                            break;
                        case '50': //dealer group association
                            break;
                        case '51': //Client portfolio
                        case '52': //Trade enquiry
                        case '53': //Tnc
                        case '54': //Foreign exchange tnc
                            //[TBC, should be new search criteria]
                            break;
                        default:
                            // handleResetBtn();

                            break;

                    }
                }
            }
        });
        setLoading(false);
    };


    const handleCloseAllDivs = () => {

        setShowUserTypeList(false);
        setShowClientAmendmentList(false);
        setShowUserProfileList(false);
        setShowUserAccessList(false);
        setShowDealerGroupList(false);
        setIsProfileInActiveDisabled(false);

        setShowUserProfilePDF(false);
        setShowUserDealerPDF(false);
        setShowOperationProfilePDF(false);
    }
    const handleResetBtn = async () => {
        await setLoading(true);
        window.scrollTo({top: 0, behavior: 'smooth'});

        setCsvContent([])
        setCsvContentHeader([])
        setSearchMenuId([]);
        setSearchMenuIdList([]);
        setSearchEffFrom("");
        setSearchEffTo("");
        setToggleReset(!toggleReset)
        resetSelectField();
        handleMenuList();
        setTableData([])

        //TODO: Reset all search criteria, include branch, user type, etc

        handleCloseAllDivs();
        //Profile
        setCurrentUserId("");
        setCurrentUserRow([]);
        setSearchUserId("");
        setSearchUserName("");
        setSearchUserType("");
        setSearchAccountNo("");
        setSearchAccountType("");
        setSearchDealerCode("");
        setSearchNRIC("");
        setSearchBranch("");
        setSearchPassport("");
        setSearchStatus("");

        var div_list = document.getElementById('div_list');
        var div_content = document.getElementById('div_enquiry_content');

        div_list.classList.remove('enquiryFadeOutShow');
        div_list.classList.add('enquiryFadeOutHide');

        div_content.classList.remove('enquiryFadeOutShow');
        div_content.classList.add('enquiryFadeOutHide');

        setLoading(false);
    };

    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Enquiries &gt; View</h5>
                            <div id="div_top_arrow" onClick={toggleMenuExpand} className="arrow-up linkCursor"></div>
                        </div>
                        <div id="div_top" className=" expandableDiv card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1" id="span_menu">Enquiries</div>
                                    <div className=" col-md-11">
                                        <Multiselect
                                            options={searchMenuIdList}
                                            onSelect={handleMenuSelect}
                                            onRemove={onMenuRemove}
                                            selectedValues={searchMenuId}
                                            displayValue="name"
                                            placeholder="Search by Menu"
                                            // selectionLimit={1}
                                            hidePlaceholder={true}
                                            // singleSelect={true}
                                            blurInputOnSelect={true}
                                            showCheckbox={false}
                                            // groupBy="parent"
                                            avoidHighlightFirstOption={true}
                                            ref={multiselectRef}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                },
                                                groupHeading: {
                                                    color: "#343434 "
                                                },
                                                searchBox: {
                                                    // width: "10%",
                                                },


                                            }}
                                        />
                                    </div>


                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Date From</div>
                                    <div className="label col-md-5">
                                        <ReactDatepick
                                            value={searchEffFrom}
                                            dateSetter={setSearchEffFrom}
                                            resetToggle={toggleReset}
                                        />
                                    </div>
                                    <div className="col-md-1">To</div>
                                    <div className="col-md-5">
                                        <ReactDatepick
                                            value={searchEffTo}
                                            dateSetter={setSearchEffTo}
                                            resetToggle={toggleReset}
                                        />
                                    </div>


                                </div>
                                {/*<div className="input-container col-md-12 fullWidthSearch">*/}

                                {/*    <div className="label col-md-1">Search</div>*/}
                                {/*    <div className="label col-md-11">*/}
                                {/*        <input*/}
                                {/*            className="form-control"*/}
                                {/*            type="text"*/}
                                {/*            placeholder="Search by keyword"*/}
                                {/*            aria-label=""*/}
                                {/*            // disabled={true}*/}
                                {/*            // value={currentUserTypeName}*/}
                                {/*            // onChange={(e) => setCurrentUserTypeName(e.target.value)}*/}
                                {/*        />*/}

                                {/*    </div>*/}
                                {/*</div>*/}
                                <hr/>

                                <div
                                    className={showUserTypeList ? "col-md-12 btn_search_padding" : " enquiryFadeOutHide col-md-12 btn_search_padding"}>

                                    <div className="input-container col-md-12 fullWidthSearch">
                                        <div className="label col-md-2 ">User Type</div>
                                        <input
                                            className="form-control "
                                            autoComplete='off'

                                            type="text"
                                            placeholder="Search by User Type  "
                                            aria-label="Search Input"
                                            // value={searchInput}
                                            onChange={(e) => setSearchInput(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value, setSearchInput, popupManager)}

                                        />
                                    </div>
                                    <div className="input-container col-md-12 fullWidthSearch">
                                        <div className="label col-md-2 ">User Category</div>
                                        <select
                                            className="form-control"
                                            aria-label=""
                                            // value={searchUserType}
                                            onChange={e => handleSelectChange(e.target.value, setSearchUserType, false)}
                                        >
                                            {userTypeCategory()}
                                        </select>
                                    </div>

                                    <hr/>

                                </div>

                                <div
                                    className={showUserClientAmendment ? "col-md-12 btn_search_padding" : " enquiryFadeOutHide col-md-12 btn_search_padding"}>

                                    <div className="input-container col-md-12 fullWidthSearch">
                                        <div className="label col-md-2">User ID / Name</div>
                                        <div className="label col-md-4">
                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="Search User ID / Name "
                                                value={enqSearchName}
                                                onChange={(e) => setEnqSearchName(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setEnqSearchName, popupManager)}

                                            />
                                        </div>
                                        <div className="label col-md-2">Stock Code / Name</div>
                                        <div className=" col-md-4">

                                            <Multiselect
                                                options={enqStockList} // Options to display in the dropdown
                                                selectedValues={enqStockCode} // Preselected value to persist in dropdown
                                                // onSelect={onSelect} // Function will trigger on select event
                                                // onRemove={onRemove} // Function will trigger on remove event
                                                displayValue="stockname" // Property name to display in the dropdown options
                                                showCheckbox={false}
                                                selectionLimit={1}
                                                // onSearch={onHandleSymbolSearch}
                                                placeholder="Search Stock Code / Name"
                                                avoidHighlightFirstOption={true}
                                                style={{
                                                    chips: {
                                                        background: "#344865"
                                                    }
                                                }}
                                            />

                                        </div>
                                    </div>

                                    <div className="input-container col-md-12 fullWidthSearch">
                                        <div className="label col-md-2">Account Number</div>
                                        <div className="label col-md-4">
                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="Search Account Number "
                                                value={enqAccNum}
                                                onChange={(e) => setEnqAccNum(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setEnqAccNum, popupManager)}

                                            />
                                        </div>
                                        <div className="label col-md-2">Order Entry Date</div>
                                        <div className="label col-md-4">

                                            <ReactDatepick
                                                value={enqOrderEntryDate}
                                                dateSetter={setEnqOrderEntryDate}
                                                resetToggle={toggleReset}
                                            />

                                        </div>
                                    </div>

                                    <div className="input-container col-md-12 fullWidthSearch">
                                        <div className="label col-md-2">Branch Code</div>
                                        <div className=" col-md-4">
                                            <Multiselect
                                                options={enqBranchList}
                                                onSelect={onSelectBranch}
                                                onRemove={onRemoveBranch}
                                                selectedValues={enqBranchCode}
                                                displayValue="name"
                                                placeholder="Search Branch Code"
                                                selectionLimit={1}
                                                hidePlaceholder={true}
                                                showCheckbox={false}
                                                avoidHighlightFirstOption={true}
                                                style={{
                                                    chips: {
                                                        background: "#344865"
                                                    }
                                                }}
                                            />

                                        </div>
                                        <div className="label col-md-2">Order ID</div>
                                        <div className="label col-md-4">

                                            <input
                                                className="form-control "
                                                type="text"
                                                placeholder="Search Order ID"
                                                value={enqOrderId}
                                                onChange={(e) => setEnqOrderId(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setEnqOrderId, popupManager)}

                                            />
                                        </div>
                                    </div>
                                    <div className="input-container col-md-12 fullWidthSearch">
                                        <div className="label col-md-2">Order Match Date</div>
                                        <div className="label col-md-4">

                                            <ReactDatepick
                                                value={enqOrderMatchedDate}
                                                dateSetter={setEnqOrderMatchedDate}
                                                resetToggle={toggleReset}
                                            />
                                        </div>

                                    </div>

                                    <hr/>

                                </div>


                                <div
                                    className={showUserProfileList ? "col-md-12 btn_search_padding" : " enquiryFadeOutHide col-md-12 btn_search_padding"}>

                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">User ID</div>
                                        <div className="label col-md-5">
                                            <input
                                                className="form-control"
                                                autoComplete='off'
                                                placeholder=""
                                                aria-label=""
                                                value={searchUserId}
                                                onChange={(e) => setSearchUserId(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setSearchUserId, popupManager)}

                                            />
                                        </div>
                                        <div className="label col-md-1">Account No</div>
                                        <div className="col-md-5">
                                            <input
                                                className="form-control"
                                                placeholder=""
                                                aria-label=""
                                                value={searchAccountNo}
                                                onChange={(e) => setSearchAccountNo(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setSearchAccountNo, popupManager)}

                                            />
                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">User Name</div>
                                        <div className="label col-md-5">
                                            <input
                                                className="form-control"
                                                placeholder=""
                                                aria-label=""
                                                value={searchUserName}
                                                onChange={(e) => setSearchUserName(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setSearchUserName, popupManager)}

                                            />
                                        </div>
                                        <div className="label col-md-1">Account Type</div>
                                        <div className="col-md-5">

                                            <select
                                                className="form-control"

                                                aria-label=""
                                                value={searchAccountType}
                                                onChange={(e) => setSearchAccountType(e.target.value)}
                                            >
                                                {accountTypeOption()}
                                            </select>


                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">User Type</div>
                                        <div className="label col-md-5">
                                            <select
                                                className="form-control"

                                                aria-label=""
                                                value={searchUserType}
                                                onChange={e => handleSelectChange(e.target.value, setSearchUserType, false)}
                                            >
                                                <option value="">Please select user type</option>
                                                {userTypeList.map(el => <option
                                                    value={el.ut_id}>{el.ut_name}</option>)}
                                            </select>

                                        </div>
                                        <div className="label col-md-1">Dealer Code</div>
                                        <div className="col-md-5">
                                            <input
                                                className="form-control"

                                                placeholder=""
                                                aria-label=""
                                                value={searchDealerCode}
                                                onChange={(e) => setSearchDealerCode(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setSearchDealerCode, popupManager)}

                                            />
                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">NRIC No</div>
                                        <div className="label col-md-5">
                                            <input
                                                className="form-control"

                                                placeholder=""
                                                aria-label=""
                                                value={searchNRIC}
                                                onChange={(e) => setSearchNRIC(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setSearchNRIC, popupManager)}

                                            />
                                        </div>
                                        <div className="label col-md-1">Branch</div>
                                        <div className="col-md-5">
                                            <Multiselect
                                                options={branchList}
                                                onSelect={onSelectBranch}
                                                onRemove={onRemoveBranch}
                                                selectedValues={searchBranch}
                                                displayValue="name"
                                                placeholder="Search by Branch Code / Branch Name"
                                                selectionLimit={1}
                                                hidePlaceholder={true}
                                                showCheckbox={false}
                                                avoidHighlightFirstOption={true}
                                                style={{
                                                    chips: {
                                                        background: "#344865"
                                                    }
                                                }}
                                            />

                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">Passport No</div>
                                        <div className="label col-md-5">
                                            <input
                                                className="form-control"

                                                placeholder=""
                                                aria-label=""
                                                value={searchPassport}
                                                onChange={(e) => setSearchPassport(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setSearchPassport, popupManager)}

                                            />
                                        </div>
                                        <div className="label col-md-1">Status</div>
                                        <div className="col-md-5">
                                            <select
                                                className="form-control"
                                                disabled={isProfileInActiveDisabled}
                                                aria-label=""
                                                value={searchStatus}
                                                onChange={e => handleSelectChange(e.target.value, setSearchStatus, false)}
                                            >
                                                {profileStatusOption()}
                                            </select>
                                        </div>


                                    </div>
                                    <hr/>

                                </div>

                                <div
                                    className={showUserUserAccessList ? "col-md-12 btn_search_padding" : " enquiryFadeOutHide col-md-12 btn_search_padding"}>

                                    <div className="input-container col-md-12 fullWidthSearch">
                                        <div className="label col-md-1">User ID</div>
                                        <div className="label col-md-5">
                                            <input
                                                autoComplete='off'
                                                className="form-control "

                                                type="text"
                                                placeholder="Search by User ID "
                                                aria-label="Search Input"
                                                value={currentUserId}
                                                onChange={(e) => setSearchInput(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setCurrentUserId, popupManager)}

                                            />
                                        </div>
                                        <div className="label col-md-1">User Type</div>
                                        <div className="label col-md-5">
                                            <select
                                                className="form-control"
                                                aria-label=""
                                                value={uaUserType}
                                                onChange={e => handleSelectChange(e.target.value, setUaUserType, false)}
                                            >
                                                <option value="">Please select user type</option>
                                                {userTypeList.map(el => <option
                                                    value={el.ut_id}>{el.ut_name}</option>)}
                                            </select>
                                        </div>

                                    </div>
                                    <div className="input-container col-md-12 fullWidthSearch">
                                        <div className="label col-md-1">Username</div>
                                        <div className="label col-md-5">
                                            <input
                                                autoComplete='off'
                                                className="form-control "

                                                type="text"
                                                placeholder="Search by username "
                                                aria-label="Search Input"
                                                value={uaUserName}
                                                onChange={(e) => setUaUserName(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setUserName, popupManager)}

                                            />
                                        </div>
                                        <div className="label col-md-1">Access Status</div>
                                        <div className="label col-md-5">
                                            <select
                                                className="form-control"

                                                aria-label=""
                                                value={uaAccessStatus}
                                                onChange={e => handleSelectChange(e.target.value, setUaAccessStatus, false)}
                                            >
                                                {accessStatusOptions()}
                                            </select>
                                        </div>

                                    </div>
                                    <hr/>

                                </div>

                                <div
                                    className={showUserDealerGroupList ? "col-md-12 btn_search_padding" : " enquiryFadeOutHide col-md-12 btn_search_padding"}>

                                    <div className="input-container col-md-12 fullWidthSearch">
                                        <div className="label col-md-1">Dealer Code</div>
                                        <div className=" col-md-5">
                                            <Multiselect
                                                // options={exchangeList} // Options to display in the dropdown
                                                // selectedValues={searchInput} // Preselected value to persist in dropdown
                                                // onSelect={onExchangeHandle} // Function will trigger on select event
                                                // onRemove={onExchangeHandle} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                                showCheckbox={true}
                                                placeholder="Dealer Code"
                                                avoidHighlightFirstOption={true}
                                                // singleSelect={true}
                                                style={{
                                                    chips: {
                                                        background: "#344865"
                                                    }
                                                }}
                                            />

                                        </div>
                                        <div className="label col-md-1">Group Name</div>
                                        <div className="label col-md-5">
                                            <input
                                                className="form-control "
                                                autoComplete='off'

                                                type="text"
                                                placeholder="Search by Group Name "
                                                aria-label="Search Input"
                                                value={searchGroupName}
                                                onChange={(e) => setSearchGroupName(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setSearchGroupName, popupManager)}

                                            />
                                        </div>

                                    </div>
                                    <div className="input-container col-md-12 fullWidthSearch">
                                        <div className="label col-md-1">Dealer Name</div>
                                        <div className="label col-md-5">
                                            <input
                                                className="form-control "
                                                autoComplete='off'

                                                type="text"
                                                placeholder="Search by Dealer Name "
                                                aria-label="Search Input"
                                                value={searchGroupInput}
                                                onChange={(e) => setSearchGroupInput(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setSearchGroupInput, popupManager)}

                                            />
                                        </div>
                                        <div className="label col-md-1">Branch</div>
                                        <div className="label col-md-5">
                                            <select
                                                className="form-control"
                                                aria-label=""
                                                value={searchBranch}
                                                onChange={(e) => handleSelectChange(e.target.value, setSearchBranch, false)}
                                            >
                                                <option value="">Please select Branch</option>
                                                {/*{branchList.map(el => <option*/}
                                                {/*    value={el.bb_id}>{el.bb_id + " | "+el.bb_name}</option>)}*/}
                                            </select>
                                        </div>

                                    </div>

                                    <hr/>

                                </div>

                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary " id="btn-search-main"
                                                onClick={() => handleSearchItem(true)}
                                        >Search
                                        </button>
                                        <button className="btn btn-primary "
                                                onClick={() => handleResetBtn()}
                                        >Reset
                                        </button>

                                        <button className="btn btn-primary btnExportCsv"
                                                disabled={csvContent.length == 0}
                                        >
                                            <CSVLink
                                                filename={"enquiries" + "_" + moment().format("DDMMYYYYhhmmss") + ".csv"}
                                                className="btnExportCsv"
                                                data={csvContent}
                                                headers={csvContentHeader}>
                                                Export to CSV
                                            </CSVLink>

                                        </button>

                                        <PDFDownloadLink document={
                                            <Document>
                                                {printProfileList(csvContentHeader, csvContent, userName, printingCompanyDetail, enqCurrentEnqTypeId, printingDateStamp, printingDateRange)}
                                            </Document>
                                        } fileName={"enquiry_" + moment().format("DDMMYYYYhhmmss") + ".pdf"}>
                                            {({blob, url, loading, error}) =>
                                                loading ? <button className="btn btn-primary btnExportCsv"
                                                                  disabled={true}>
                                                    Export to PDF
                                                </button> : <>
                                                    <button className="btn btn-primary btnExportCsv"
                                                            disabled={csvContent.length == 0}>
                                                        Export to PDF
                                                    </button>

                                                </>
                                            }
                                        </PDFDownloadLink>

                                        {/*<button className="btn btn-primary "*/}
                                        {/*    onClick={() => handleShowContents()}*/}
                                        {/*>Test On Item Select*/}
                                        {/*</button>*/}
                                        {/*<PDFDownloadLink document={*/}
                                        {/*    <Document>*/}
                                        {/*        {handleRerender()}*/}
                                        {/*    </Document>*/}
                                        {/*} fileName={auditLogName}>*/}
                                        {/*    {({blob, url, loading, error}) =>*/}
                                        {/*        loading ? tableData.length > 0 ?*/}
                                        {/*            <button className="btn btn-primary ">Export to PDF*/}
                                        {/*            </button> :*/}
                                        {/*            <button className="btn btn-primary " disabled={true}>Export to PDF*/}
                                        {/*            </button> : <>*/}
                                        {/*            {tableData.length > 0 ?*/}
                                        {/*                <button className="btn btn-primary ">Export to PDF*/}
                                        {/*                </button> :*/}
                                        {/*                <button className="btn btn-primary " disabled={true}>Export to*/}
                                        {/*                    PDF*/}
                                        {/*                </button>}*/}

                                        {/*        </>*/}
                                        {/*    }*/}
                                        {/*</PDFDownloadLink>*/}

                                        {/*<button className="btn btn-primary "*/}
                                        {/*    onClick={() => handleNewTabItms()}*/}
                                        {/*>Open New Tab*/}
                                        {/*</button>*/}

                                        {/*<button className="btn btn-primary "*/}
                                        {/*        onClick={() => handlecloseNewTabs()}*/}
                                        {/*>Close tab*/}
                                        {/*</button>*/}
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div id="div_list" className=" enquiryFadeOutHide card-body">
                            <div className="col-12 search-container">
                                {/*<div id="div_user_type_list" className=" enquiryFadeOutHide card-body">*/}


                                {
                                    showUserTypeList ?
                                        <UserTypeList
                                            setCurrentUsrTypeId={setCurrentUsrTypeId}
                                            setCurrentPage={setCurrentPage}
                                            setCurrentPerPage={setCurrentPerPage}
                                            currentPage={currentPage}
                                            currentPerPage={currentPerPage}
                                            totalRecords={totalRecords}
                                            tableData={tableData}
                                            popupManager={popupManager}
                                        /> : <></>
                                }
                                {
                                    showUserClientAmendment ?
                                        <ClientAmend
                                            setCurrentUserId={setCurrentUserRow}
                                            setCurrentPage={setCurrentPage}
                                            setCurrentPerPage={setCurrentPerPage}
                                            currentPage={currentPage}
                                            currentPerPage={currentPerPage}
                                            totalRecords={totalRecords}
                                            tableData={tableData}
                                            popupManager={popupManager}
                                        /> : <></>
                                }
                                {
                                    showUserProfileList ?
                                        <UserProfile
                                            searchUserRow={currentUserRow}
                                            setCurrentUserRow={setCurrentUserRow}
                                            setCurrentPage={setCurrentPage}
                                            setCurrentPerPage={setCurrentPerPage}
                                            currentPage={currentPage}
                                            currentPerPage={currentPerPage}
                                            totalRecords={totalRecords}
                                            tableData={tableData}
                                            popupManager={popupManager}
                                        /> : <></>
                                }
                                {
                                    showUserUserAccessList ?
                                        <UserAccess
                                            setSearchUserId={setCurrentUserRow}
                                            setCurrentPage={setCurrentPage}
                                            setCurrentPerPage={setCurrentPerPage}
                                            currentPage={currentPage}
                                            currentPerPage={currentPerPage}
                                            totalRecords={totalRecords}
                                            tableData={tableData}
                                            popupManager={popupManager}
                                        /> : <></>
                                }

                                {
                                    showUserDealerGroupList ?
                                        <DealerGroup
                                            setCurrentGroupId={setCurrentGroupId}
                                            setCurrentPage={setCurrentPage}
                                            setCurrentPerPage={setCurrentPerPage}
                                            currentPage={currentPage}
                                            currentPerPage={currentPerPage}
                                            totalRecords={totalRecords}
                                            tableData={tableData}
                                            popupManager={popupManager}
                                        /> : <></>
                                }
                            </div>

                        </div>

                        <div id="div_enquiry_content" className="enquiryFadeOutHide card-body">
                            <div id="div_back_arrow" onClick={toggleBackFromReport}
                                 className="arrow-left linkCursor "></div>
                            <div id="" onClick={toggleBackFromReport} className="linkCursor ">Back</div>


                            <hr/>
                            <div id="" className="col-12 search-container">

                                {showUserProfilePDF != false ?
                                    <UserClientProfilePDF
                                        dataRows={currentUserRow}
                                        currentEnqId={enqCurrentEnqTypeId}
                                    />
                                    : <></>}

                                {showUserDealerPDF != false ?
                                    <UserDealerProfilePDF
                                        dataRows={currentUserRow}
                                        currentEnqId={enqCurrentEnqTypeId}
                                    />
                                    : <></>}

                                {showUserOperationPDF != false ?
                                    <UserOperationProfilePDF
                                        dataRows={currentUserRow}
                                        currentEnqId={enqCurrentEnqTypeId}
                                    />
                                    : <></>}

                            </div>

                        </div>


                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
