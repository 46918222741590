import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    convertFuncPerm, dateFormats,
    dealerStatusOptions,
    getDefaultAccessMat, getNavIdByPath,
    handlePopup,
    handleSelectChange, momentTimeFormat,
    useLocalStorage, convertErMsg, specialCharCheck, performAntdTimeCheck
} from "../../../general/general-tools";
import {usePopupManager} from "react-popup-manager";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";
import ReactDatepick from "../../../general/form-control/react-datepick";
import dayjs from "dayjs";


const Index = () => {
    const {setLoading} = useLoading();

    const [tableData, setTableData] = useLocalStorage("mst_dlg_tblDat", []);
    const [totalRecords, setTotalRecords] = useLocalStorage("mst_dlg_totRec", 0);
    const [currentPage, setCurrentPage] = useLocalStorage("mst_dlg_curPg", 1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage("mst_dlg_curPrPg", 10);

    const [searchInput, setSearchInput] = useLocalStorage("mst_dlg_searchInput", "");
    const [searchStatus, setSearchStatus] = useLocalStorage("mst_dlg_searchStatus", "");
    const [searchEffFrom, setSearchEffFrom] = useLocalStorage("mst_dlg_searchEffFrom", null);
    const [searchEffTo, setSearchEffTo] = useLocalStorage("mst_dlg_searchEffFrom", null);
    let navigate = useNavigate();
    const location = useLocation();
    const isInitialMount = useRef(true);
    const popupManager = usePopupManager();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat() : getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);
    const [toggleReset, setToggleReset] = useState(false);
    const [isDateValid, setIsDateValid] = useState(false);

    const handleSearch = async (newSearch = false) => {
        handleInitList(searchStatus, searchInput, searchEffFrom, searchEffTo, newSearch)
    }

    useEffect(() => {
        performAntdTimeCheck(searchEffFrom, searchEffTo, setSearchEffTo, popupManager, setIsDateValid);

    }, [searchEffFrom, searchEffTo]);

    useLayoutEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        if (tableData.length > 0) {
            handleInitList(searchStatus, searchInput, searchEffFrom, searchEffTo)
        } else {
            handleResetBtn();
        }
    }, [])


    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleInitList(searchStatus, searchInput, searchEffFrom, searchEffTo);
        }

    }, [currentPage, currentPerPage]);

    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {
        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }
    useEnterKeyPress(handleEnterKeyPress);
    const handleInitList = async (status, searchTerm, effFr, effTo, newSearch = false) => {
        setLoading(true);
        if (searchInput.length < 2 && searchInput.length > 0) {
            handlePopup(true, "Error", "Search term require at least 2 characters.", popupManager)
            setLoading(false);
            return;
        }


        let fromDate = dayjs(effFr).format(momentTimeFormat());
        let toDate = dayjs(effTo).format(momentTimeFormat());
        let curPage = currentPage;
        if (newSearch) {
            setCurrentPage(1)
            curPage = 1;
        }
        if (fromDate != "Invalid Date" && toDate != "Invalid Date") {
            if (!isDateValid) {
                handlePopup(true, "Error", "Please ensure that the ending date is not earlier than the starting date.", popupManager);
                await setLoading(false);
                return false;
            }
        }


        api.getDealerGroupList(currentPerPage, curPage, status, searchTerm, fromDate == "Invalid Date" ? null : fromDate, toDate == "Invalid Date" ? null : toDate).then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }
            if (result.status == 0) {
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    for (let i = 0; i < dataList.length; i++) {
                        if (dataList[i].dg_status == 0) dataList[i]['dg_status_disp'] = "Active";
                        else if (dataList[i].dg_status == 1) dataList[i]['dg_status_disp'] = "Pending"
                        else if (dataList[i].dg_status == 2) dataList[i]['dg_status_disp'] = "Expired"
                    }
                    setTableData(dataList);
                    let totalPage = parseInt(result.data.totalrow)
                    setTotalRecords(totalPage);
                } else {
                    handlePopup(true, "Error", "No results found", popupManager)
                    setTableData([]);
                    setTotalRecords(10);
                    setLoading(false);
                }
            } else {
                if (result.message) {
                    setLoading(false);
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                } else {
                    handlePopup(true, "Error", "No results found", popupManager)
                }


                setTableData([]);
                setTotalRecords(10);
            }
            setLoading(false);

        });


    };


    const handleOnclick = async (row) => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "dealergp_opr", {
            state: {
                row: row,
                perm: actualPerm,
                mode: 'Edit'
            }
        });
        setLoading(false);
    };
    const handleAddBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "dealergp_opr", {
            state: {
                row: "",
                perm: actualPerm,
                mode: 'Add'
            }
        });
        setLoading(false);
    };
    const handleResetBtn = async () => {
        await setLoading(true);
        setSearchInput("");
        setSearchStatus("");
        setSearchEffFrom("");
        setSearchEffTo("");
        setToggleReset(!toggleReset)
        setLoading(false);
    };

    const columns = useMemo(
        () => [
            {
                id: row => row.dg_date,
                name: 'Group Name',
                selector: row => row.dg_name,
                sortable: true,
                // center: true,
                cell: (row: { dg_name: any }) => (
                    <>
                        <a className="tableItemsHlink" onClick={() => handleOnclick(row)}>{row.dg_name}</a>
                    </>
                )
            },
            {
                name: 'Status',
                selector: row => row.dg_status_disp,
                sortable: true,

            },
            {
                name: 'Effective From',
                selector: row => dayjs(row.dg_date_fr).format(dateFormats()) == 'Invalid Date' ? "-" : dayjs(row.dg_date_fr).format(dateFormats()),
                sortable: true,
            },
            {
                name: 'Effective To',
                selector: row => dayjs(row.dg_date_to).format(dateFormats()) == 'Invalid Date' ? "-" : dayjs(row.dg_date_to).format(dateFormats()),
                sortable: true,

            }
        ],
        []
    );


    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Master File
                                Maintenance &gt; Dealer Group - Search</h5>

                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Group Name</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="form-control "
                                            autoComplete='off'
                                            type="text"
                                            placeholder="Search by Group Name "
                                            aria-label="Search Input"
                                            value={searchInput}
                                            onChange={(e) => setSearchInput(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value, setSearchInput, popupManager)}

                                        />
                                    </div>


                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1">Status</div>
                                    <div className="label col-md-11">
                                        <select
                                            className="form-control"
                                            id="cur_nation"
                                            aria-label=""
                                            value={searchStatus}
                                            onChange={e => handleSelectChange(e.target.value, setSearchStatus, false)}
                                        >
                                            {dealerStatusOptions()}

                                        </select>
                                    </div>


                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Effective From</div>
                                    <div className="label col-md-5">
                                        <ReactDatepick
                                            value={searchEffFrom}
                                            dateSetter={setSearchEffFrom}
                                            resetToggle={toggleReset}
                                        />
                                    </div>
                                    <div className="col-md-1">To</div>
                                    <div className="col-md-5">
                                        <ReactDatepick
                                            value={searchEffTo}
                                            dateSetter={setSearchEffTo}
                                            resetToggle={toggleReset}
                                        />
                                    </div>


                                </div>

                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary "  id="btn-search-main"  onClick={() => handleSearch(true)}>Search
                                        </button>
                                        <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                        </button>
                                        {actualPerm['add'] ? <button className="btn btn-primary "
                                                                     onClick={() => handleAddBtn()}>Add</button> : ""}
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    perPageQty={setCurrentPerPage}
                                    columns={columns}
                                    data={tableData}
                                    pagination={{
                                        currentPage: currentPage,
                                        currentPerPage: currentPerPage,
                                        totalRecords: totalRecords,
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
