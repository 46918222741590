import React, {useState, useEffect, useMemo} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';

import {numberWithCommas} from '../../../general/convertor';
import {confirmAlert} from "react-confirm-alert";
import api from "../../../../apis";
import {
    convertPositiveNumberHundred,
    getOrderTypeById, getValidityType,
    handlePopup,
    numProcessor,
    convertErMsg, specialCharCheck
} from "../../../general/general-tools";
import ModalConfirm from "../../public/ModalConfirm";
import {usePopupManager} from "react-popup-manager";
import Multiselect from "multiselect-react-dropdown";
import {getAmendmentDetail} from "../../../../apis/models/ClientAmend";
import ReactDatepick from "../../../general/form-control/react-datepick";

const Index = () => {
    const {setLoading} = useLoading();

    const [dataRO, setDataRO] = useState(true);
    const [isEntry, setIsEntry] = useState(false);

    const [currentMode, setCurrentMode] = useState("");
    const [currentUserId, setCurrentUserId] = useState("");
    const [currentUserOrinId, setCurrentUserOrinId] = useState("");
    const [currentAccNo, setCurrentAccNo] = useState("");
    const [currentName, setCurrentName] = useState("");
    const [currentAccType, setCurrentAccType] = useState("");
    const [currentDealer, setCurrentAccDealer] = useState("");
    const [currentBranch, setCurrentBranch] = useState("");
    const [currentOrderId, setCurrentOrderId] = useState("");
    const [currentAction, setCurrentAction] = useState("");
    const [currentOrderType, setCurrentOrderType] = useState("");
    const [currentStkCode, setCurrentStkCode] = useState("");
    const [currentStkName, setCurrentStkName] = useState("");
    const [currentSymbol, setCurrentSymbol] = useState("");
    const [currentQty, setCurrentQty] = useState("");
    const [currentAvgMthPrc, setCurrentAvgMthPrc] = useState("");
    const [currentEntryDate, setCurrentEntryDate] = useState("");
    const [currentValidity, setCurrentValidity] = useState("");
    const [currentExpDate, setCurrentExpDate] = useState("");
    const [currentCtParty, setCurrentCtParty] = useState("");
    const [currentTrigPrice, setCurrentTrigPrice] = useState("");
    const [currentMinQty, setCurrentMinQty] = useState("");
    const [currentTolerance, setCurrentTolerance] = useState("");
    const [currentRemark, setCurrentRemark] = useState("");


    const [multiSelectName, setMultiSelectName] = useState([]);
    const [multiSelectNameList, setMultiSelectNameList] = useState([]);
    const [multiSelectAccNum, setMultiSelectAccNum] = useState([]);
    const [multiSelectAccNumList, setMultiSelectAccNumList] = useState([]);
    const [multiSelectDealerId, setMultiSelectDealerId] = useState("");
    const [multiSelectDealerName, setMultiSelectDealerIdName] = useState("");
    const [multiSelectBranchCode, setMultiSelectBranchCode] = useState("");
    const [multiSelectBranchCodeName, setMultiSelectBranchCodeName] = useState("");
    const [accessItem, setAccessItem] = useState({});


    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();


    //Load initial data and mode
    useEffect(() => {
        setLoading(true);
        window.scrollTo({top: 0, behavior: 'smooth'});

        if (location.state) {
            if (location.state.row) {
                setAccessItem(location.state.perm);

                let stateDat = location.state.row;
                setCurrentUserOrinId(stateDat['acct_id'])
                setCurrentRemark(stateDat['amend_remark'])
                if (stateDat['amend_acct'] && stateDat['amend_name']) {
                    let currentAmd = {};
                    currentAmd['id'] = stateDat['amend_acct_id'];
                    currentAmd['display_name'] = stateDat['amend_name'] + " | " + stateDat['amend_acct'];
                    currentAmd['account_no'] = stateDat['amend_acct'];
                    currentAmd['branch_id'] = stateDat['amend_branch_code'];
                    currentAmd['branch_code'] = stateDat['amend_branch_code'];
                    currentAmd['dealer_id'] = stateDat['amend_dealer'];
                    currentAmd['dealer_code'] = stateDat['amend_dealer'];
                    setMultiSelectName([currentAmd]);
                    setMultiSelectAccNum([currentAmd]);
                    setMultiSelectBranchCode(stateDat['amend_branch_code'])
                    setMultiSelectBranchCodeName(stateDat['amend_branch_code'])
                    setMultiSelectDealerIdName(stateDat['amend_dealer'])
                    setMultiSelectDealerId(stateDat['amend_dealer'])
                }
                handleDetail(stateDat['order_no']);
            }
            setCurrentMode(location.state.mode)

            if (location.state.mode == 'Entry') {
                setIsEntry(true);
            }

        }
    }, [location.state]);


    const handleDetail = async (id) => {
        let params = {};
        params['order_no'] = id;

        api.getAmendmentDetail(params).then((result: any) => {
            if(result.data){
                if (result.data.list) {
                    let amdDat = result.data.list[0];
                    setCurrentUserId(amdDat['username'])
                    setCurrentAccNo(amdDat['acct_no'])
                    setCurrentOrderId(amdDat['order_no'])
                    setCurrentAction(amdDat['order_action'])
                    setCurrentStkCode(amdDat['ticker_code'])
                    setCurrentStkName(amdDat['ticker_name'])
                    setCurrentSymbol(amdDat['ticker_exchange'])
                    setCurrentQty(convertPositiveNumberHundred(amdDat['order_visible_qty'] ?? 0));
                    setCurrentAvgMthPrc(convertPositiveNumberHundred(amdDat['order_avg_price'] ?? 0));
                    setCurrentTrigPrice(convertPositiveNumberHundred(amdDat['order_trigger_price'] ?? 0));
                    setCurrentMinQty(convertPositiveNumberHundred(amdDat['order_min_qty'] ?? 0));
                    setCurrentTolerance(convertPositiveNumberHundred(amdDat['order_tolerance'] ?? 0));
                    setCurrentEntryDate(amdDat['order_entry'] == 'Invalid date' ? null : amdDat['order_entry'])
                    setCurrentExpDate(amdDat['order_expired'] == 'Invalid date' ? null : amdDat['order_expired'])
                    setCurrentOrderType(getOrderTypeById(amdDat['order_type']))
                    setCurrentValidity(getValidityType(amdDat['order_validate']))
                    setCurrentAccDealer(amdDat['dealer'])
                    setCurrentBranch(amdDat['branch_code'])
                    setCurrentCtParty(amdDat['counter_party'] ?? "-")
                    setCurrentName(amdDat['fullname'])
                    setCurrentAccType(amdDat['acct_type'] == 0 ? "Cash" :
                        amdDat['acct_type'] == 1 ? "Margin" :
                            amdDat['acct_type'] == 2 ? "Cash Upfront" :
                                amdDat['acct_type'] == 3 ? "Collateralized" : "-")


                }
            }
            setLoading(false);
        });
    }

    const handleBackBtn = async () => {
        await setLoading(true);
        let url = '';
        if (location.state.mode == 'Entry') {
            url = 'cl_amend_entry';
        } else if (location.state.mode == 'Status') url = 'cl_amend_status';
        else if (location.state.mode == 'Approval') url = 'cl_amend_approval';
        navigate("/" + process.env.REACT_APP_URL_PREFIX + url, {state: {mode: 'back'}});
        setLoading(false);
    };


    const getBckUrl = () =>{
        let url = '';
        if (location.state.mode == 'Entry') {
            url = 'cl_amend_entry';
        } else if (location.state.mode == 'Status') url = 'cl_amend_status';
        else if (location.state.mode == 'Approval') url = 'cl_amend_approval';
        return url;
    }
    const handleResetBtn = async () => {
        await setLoading(true);
        setMultiSelectNameList([])
        setMultiSelectAccNumList([])
        setMultiSelectDealerIdList([])
        setMultiSelectBranchCodeList([])
        setLoading(false);
    };

    const handleApproveBtn = async (type) => {

        confirmAlert({
            title: type == 'approve' ? "Approve" : "Decline",
            message: <div id="area_remark">
                <hr/>
                <div>
                    Are you sure you want to {type == 'approve' ? "approve" : "decline"} the current amendment request?

                </div>

            </div>,
            buttons: [{
                label: 'Yes',
                // id:'btn_logout_confirm',
                onClick: () => handleApproval(type)
            }, {
                label: 'Close',
                // id:'btn_logout_confirm',
            }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });

        setLoading(false);
    };

    const handleApproval = (type) => {


        let param = {};
        param['id'] = parseInt(currentUserOrinId);
        param['approval_status'] = type == 'approve' ? 1 : 2;
        param['remark'] = currentRemark ?? "";

        api.clmtEntryApproval(param).then((result: any) => {
            if (result != false && result.status == 0){
                confirmAlert({
                    title: "Amendment",
                    message: <div id="area_remark">
                        <hr/>
                        <div>
                            {"Amendment " + type + " successfully"}
                        </div>

                    </div>,
                    buttons: [
                        {label: 'Check Status', onClick: () => navigate("/" + process.env.REACT_APP_URL_PREFIX + "cl_amend_status", {})}, {
                            label: 'Close',
                        }
                    ],
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                });
            }



        else{
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
            }
        });



    }

    const handleSubmit = async () => {

        confirmAlert({
            title: "Submit",
            message: <div id="area_remark">
                <hr/>
                <div>
                    Are you sure you want to submit the current amendment request?
                </div>

            </div>,
            buttons: [{label: 'Yes', onClick: () => handleSubmission()}, {
                label: 'Close',
            }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });

        setLoading(false);
    };

    const handleSubmission = (testitem) => {

        if (!multiSelectAccNum[0]) {
            handlePopup(true, "Error", "Mandatory Field - Amending account not selected.", popupManager)
            return false;
        }

        let param = {};
        param['order_no'] = currentOrderId.toString();
        param['orin_id'] = parseInt(currentUserOrinId);
        param['amend_id'] = multiSelectAccNum[0]['id'];
        param['remark'] = currentRemark ?? "";


        api.createNewEntry(param).then((result: any) => {
            if (result != false && result.status == 0) {
                setLoading(false);

                handlePopup(true, "Success", "Amendment created successfully", popupManager,true,getBckUrl(),navigate)
            }
            else{
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)

            }
        });
    }

    const onNameSearchHandle = (selectedList, selectedItem) => {
        setMultiSelectName(selectedList);
        setMultiSelectAccNum([]);
        setMultiSelectDealerId("");
        setMultiSelectDealerIdName("");
        setMultiSelectBranchCode("");
        setMultiSelectBranchCodeName("");

        setMultiSelectDealerIdName(selectedList[0]['dealer_code'])
        setMultiSelectDealerId(selectedList[0]['dealer_code'])
        setMultiSelectBranchCode(selectedList[0]['branch_id'])
        setMultiSelectBranchCodeName(selectedList[0]['branch_code'])
        setMultiSelectAccNum(selectedList)
    }

    const onAccSearchHandle = (selectedList, selectedItem) => {
        setMultiSelectAccNum(selectedList);
        setMultiSelectName([]);
        setMultiSelectDealerId("");
        setMultiSelectDealerIdName("");
        setMultiSelectBranchCode("");
        setMultiSelectBranchCodeName("");

        setMultiSelectDealerIdName(selectedList[0]['dealer_code'])
        setMultiSelectDealerId(selectedList[0]['dealer_code'])
        setMultiSelectBranchCode(selectedList[0]['branch_id'])
        setMultiSelectBranchCodeName(selectedList[0]['branch_code'])
        setMultiSelectName(selectedList);

    }

    const onHandleNameSearch = (searchItem) => {
        if (searchItem.length > 3) {
            setMultiSelectAccNum([]);
            setMultiSelectName([]);

            handleProfileList({
                // "aterm": searchAccountNo,
                "nterm": searchItem,
                "size": 100,
                "page": 1,
            });
        } else if (searchItem.length == 0) {
            setMultiSelectNameList([])
        }
    }

    const onHandleAccSearch = (searchItem) => {
        if (searchItem.length > 3) {
            handleProfileListAcc({
                "aterm": searchItem,
                // "nterm": searchItem,
                "size": 100,
                "page": 1,
            });
        } else if (searchItem.length == 0) {
            setMultiSelectAccNumList([])
        }
    }

    const handleProfileList = async (param) => {
        setLoading(true);
        api.getProfileList(param).then((result: any) => {
            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    let actualData = convertRawIntoDropdownUserName(dataList);
                    setMultiSelectNameList(actualData);
                    setLoading(false);

                } else {
                    setMultiSelectNameList([])

                    setLoading(false);
                }
            }else {
                setMultiSelectNameList([])

                setLoading(false);
            }
        });

        setLoading(false);
    };

    const handleProfileListAcc = async (param) => {
        setLoading(true);
        api.getProfileList(param).then((result: any) => {
            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    let actualData = convertRawIntoDropdownUserName(dataList);
                    setMultiSelectAccNumList(actualData);
                    setLoading(false);

                } else {
                    setMultiSelectNameList([])

                    setLoading(false);
                }
            }else {
                setMultiSelectNameList([])

                setLoading(false);
            }
        });

        setLoading(false);
    };

    const convertRawIntoDropdownUserName = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                name: data[i]['p_fullname'],
                display_name: data[i]['p_fullname'] + " | " + (data[i]['account_no'] ?? "-"),
                id: data[i]['user_id'],
                account_no: data[i]['account_no'] ?? "-",
                branch_id: data[i]['bb_id'] ?? "-",
                branch_code: data[i]['bb_code'] ?? "-",
                dealer_id: data[i]['dealer_id'] ?? "-",
                dealer_code: data[i]['dealer_code'] ?? "-",
            });
        }
        return returnDat;
    }

    const renderButtonByMode = (mode) => {
        if (mode.toLowerCase() == 'entry') {

            return (
                <div className="label col-md-11 btn_search_padding">
                    <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>

                    {accessItem['edit'] ?
                        <>
                            <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset</button>
                            <button className="btn btn-primary " onClick={() => handleSubmit()}>Submit</button>
                        </>
                        :""}

                </div>

            )
        } else if (mode.toLowerCase() == 'status') {
            return (
                <div className="label col-md-11 btn_search_padding">
                    <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>
                </div>

            )
        } else {
            return (
                <div className="label col-md-11 btn_search_padding">
                    <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>
                    {accessItem['edit'] ?
                        <>
                            <button className="btn btn-success " onClick={() => handleApproveBtn('approve')}>Approve</button>
                            <button className="btn btn-danger " onClick={() => handleApproveBtn('decline')}>Decline</button>
                        </>
                        :""}

                </div>)
        }
    }

    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration  &gt; Client
                                Amendment &gt;  {currentMode}</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1">User ID</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentUserId}
                                            onChange={(e) => setCurrentUserId(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentUserId,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-1">Account No.</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentAccNo}
                                            onChange={(e) => setCurrentAccNo(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentAccNo,popupManager)}

                                        />
                                    </div>
                                </div>


                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1">Name</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentName}
                                            onChange={(e) => setCurrentName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentName,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-1">Account Type</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentAccType}
                                            onChange={(e) => setCurrentAccType(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentAccType,popupManager)}

                                        />
                                    </div>
                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1">Dealer</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentDealer}
                                            onChange={(e) => setCurrentAccDealer(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentAccDealer,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-1">Branch</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentBranch}
                                            onChange={(e) => setCurrentBranch(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentBranch,popupManager)}

                                        />
                                    </div>
                                </div>

                                <hr/>


                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1">Order ID</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentOrderId}
                                            onChange={(e) => setCurrentOrderId(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentOrderId,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-1">Action</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentAction}
                                            onChange={(e) => setCurrentAction(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentAction,popupManager)}

                                        />
                                    </div>


                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">


                                    <div className="label col-md-1">Order Type</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentOrderType}
                                            onChange={(e) => setCurrentOrderType(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentOrderType,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-1">Stock Code</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentStkCode}
                                            onChange={(e) => setCurrentStkCode(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentStkCode,popupManager)}

                                        />
                                    </div>
                                </div>


                                <div className="input-container col-md-12 fullWidthSearch">


                                    <div className="label col-md-1">Stock Name</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentStkName}
                                            onChange={(e) => setCurrentStkName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentStkName,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-1">Exchange</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentSymbol}
                                            onChange={(e) => setCurrentSymbol(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">


                                    <div className="label col-md-1">Quantity</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentQty}
                                            onChange={(e) => setCurrentQty(e.target.value)}
                                        />
                                    </div>
                                    <div className="label col-md-1">Avg Matched Price</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentAvgMthPrc}
                                            onChange={(e) => setCurrentAvgMthPrc(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">


                                    <div className="label col-md-1">Order Entry Date Time</div>
                                    <div className="label col-md-5">

                                        <ReactDatepick
                                            disabled={dataRO}
                                            value={currentEntryDate}
                                            dateSetter={setCurrentEntryDate}
                                        />
                                    </div>
                                    <div className="label col-md-1">Validity</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentValidity}
                                            onChange={(e) => setCurrentValidity(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">


                                    <div className="label col-md-1">Expiry Date</div>
                                    <div className="label col-md-5">
                                        <ReactDatepick
                                            disabled={dataRO}
                                            value={currentExpDate}
                                            dateSetter={setCurrentExpDate}
                                        />
                                    </div>

                                    <div className="label col-md-1">Counter Party</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentCtParty}
                                            onChange={(e) => setCurrentCtParty(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">


                                    <div className="label col-md-1">Trigger Price</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentTrigPrice}
                                            onChange={(e) => setCurrentTrigPrice(e.target.value)}
                                        />
                                    </div>
                                    <div className="label col-md-1">Minimum Quantity</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentMinQty}
                                            onChange={(e) => setCurrentMinQty(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">


                                    <div className="label col-md-1">Visible Quantity</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentTrigPrice}
                                            onChange={(e) => setCurrentTrigPrice(e.target.value)}
                                        />
                                    </div>
                                    <div className="label col-md-1">Tolerance</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={dataRO}
                                            value={currentTolerance}
                                            onChange={(e) => setCurrentTolerance(e.target.value)}
                                        />
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>

                </div>

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Amend To</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">


                                    <div className="label col-md-1">
                                        <b className='mandatory-red-inside'>*</b>
                                        Client Name
                                    </div>

                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={multiSelectNameList}
                                            selectedValues={multiSelectName}
                                            onSelect={onNameSearchHandle}
                                            onRemove={onNameSearchHandle}
                                            displayValue="display_name"
                                            showCheckbox={false}
                                            selectionLimit={1}
                                            onSearch={onHandleNameSearch}
                                            placeholder="Search Account Number"
                                            disable={!isEntry}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="label col-md-1">
                                        <b className='mandatory-red-inside'>*</b>
                                        Account Number
                                    </div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={multiSelectAccNumList} // Options to display in the dropdown
                                            selectedValues={multiSelectAccNum} // Preselected value to persist in dropdown
                                            onSelect={onAccSearchHandle} // Function will trigger on select event
                                            onRemove={onAccSearchHandle} // Function will trigger on remove event
                                            displayValue="account_no" // Property name to display in the dropdown options
                                            showCheckbox={false}
                                            selectionLimit={1}
                                            onSearch={onHandleAccSearch}
                                            placeholder="Search Account Number"
                                            disable={!isEntry}
                                            avoidHighlightFirstOption={true}

                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">


                                    <div className="label col-md-1">Dealer ID</div>

                                    <div className="label col-md-5">


                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={true}
                                            value={multiSelectDealerName}
                                        />

                                    </div>
                                    <div className="label col-md-1">Branch ID</div>

                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            readOnly={true}
                                            value={multiSelectBranchCodeName}
                                        />
                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">


                                    <div className="label col-md-1">Remark</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            id="row"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            // readOnly={!isEntry}
                                            value={currentRemark}
                                            onChange={(e) => setCurrentRemark(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentRemark,popupManager)}

                                        />
                                    </div>

                                </div>
                                <hr/>

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1"></div>

                                    {renderButtonByMode(currentMode)}


                                </div>


                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default Index;
