import React from "react";
import {convertErMsg, dateTimeFormat, getNavItemById, handlePopup} from "../../../general/general-tools";
import api from "../../../../apis";
import dayjs from "dayjs";
import {Font, Image, Page, Text, View} from "@react-pdf/renderer";
import companyLogo from "../../../asset/pdfheader/header.png";
import moment from "moment";
import {getEnquiryClientProfilePDFStyle, getEnquiryClientProfileStyle} from "../../../general/pdf-tools";
import FontFaRegular400 from "../../../asset/font-awesome/webfonts/NotoSans-Medium.ttf";
import FontFaRSolid900 from "../../../asset/font-awesome/webfonts/NotoSans-Bold.ttf";

//Triggering Functions
//Market
export function handleInitProfileList(param,setTableData,setTotalRecords,setLoading,setCsvContentHeader,setCsvContent,popupManager) {
    setLoading(true);

    api.getProfileList(param).then((result: any) => {
        if(result.status == 1){
            handlePopup(true,"Error",convertErMsg(result.message),popupManager)
            setLoading(false);
            return false;
        }

        setCsvContentHeader(
            [
                { label: "User Name", key: "p_fullname" },
                { label: "User ID", key: "username" },
                { label: "User Type", key: "ut_name" },
                { label: "Account No", key: "account_no" },
                { label: "Account Type", key: "account_type" },
                { label: "Status", key: "active_status" },
                { label: "Access Status", key: "active_status" },
                { label: "Last Login", key: "timelast_update" }
            ]
        );



        let tempCsvContents = [];
        if(result.data){
            if (result.data.list.length > 0) {
                let dataList = result.data.list;
                for (let i = 0; i < dataList.length; i++) {
                    dataList[i]['p_id'] = dataList[i]['id'];
                    dataList[i]['id'] = "";
                    tempCsvContents.push(
                        { p_fullname: dataList[i]['p_fullname'],
                            username: dataList[i]['username'],
                            ut_name: dataList[i]['ut_name'] ,
                            account_no: dataList[i]['account_type'] == 0 ? "Cash" : dataList[i]['account_type'] == 1 ? "Margin" : dataList[i]['account_type'] == 2 ? "Cash Upfront" : dataList[i]['account_type'] == 3 ? "Collateralized" : "-" ,
                            account_type: dataList[i]['active_status'] == 0 ? "Inactive": dataList[i]['active_status'] ==1 ? "Active" : dataList[i]['active_status'] == 2 ? "Suspended" : dataList[i]['active_status'] == 3 ? "Expired" : dataList[i]['active_status'] == 4 ? "Dormant" : "Closed",
                            active_status: dataList[i]['active_status'] == 1 ? "Unblock" : row.active_status == 2 ? "Block" : "-" ,
                            timelast_update: dayjs(dataList[i].last_login).format(dateTimeFormat()) == 'Invalid Date' ? "-" : dayjs(dataList[i].last_login).format(dateTimeFormat()),
                        },

                    )


                }
                setCsvContent(tempCsvContents);
                setTableData(dataList);
                let totalPage = parseInt(result.data.totalrow)
                setTotalRecords(totalPage);
                setLoading(false);
                // }, 10);
                // return () => clearTimeout(timeout);
            } else {
                handlePopup(true,"Error","No results found",popupManager)
                setTableData([]);
                setTotalRecords(10);
                setLoading(false);
            }
        }else {
            handlePopup(true,"Error","No results found",popupManager)
            setTableData([]);
            setTotalRecords(10);
            setLoading(false);
        }
    });
};

export function printProfileList(header,contents,userName, companyDetail,currentEnqId,dateTitle,dateDuration) {
    const styles = getEnquiryClientProfilePDFStyle();

    Font.register({
        family: 'Regular',
        fonts: [
            {
                src: FontFaRegular400,
            },
            {
                src: FontFaRSolid900,
                fontWeight: 'bold',
            }
        ],
    });

    let currentCompanyName = companyDetail['company_name'];
    let currentCompanyAddress1 = companyDetail['company_address1'];
    let currentCompanyAddress2 = companyDetail['company_address2'];
    let currentCompanyAddress3 = companyDetail['company_address3'];
    let currentCompanyPostCode = companyDetail['company_postcode'];
    let currentCompanyPhoneNum = companyDetail['company_phone'];
    let currentCompanyWebsite = companyDetail['company_website'];


    return(
        <Page size="A4" style={styles.page}  orientation={'landscape'}>

            <View style={styles.headerDiv}>
                <Image src={companyLogo} style={styles.image}/>
                <View style={styles.headerTextRightDiv}>
                    <Text style={styles.headerTextBig}>{currentCompanyName}</Text>
                    <Text style={styles.headerTextMedium}>{currentCompanyAddress1}{'\n'}
                        {currentCompanyAddress2 ?? "-"}{'\n'}
                        {(currentCompanyPostCode != "" ? currentCompanyPostCode + " ," : "")}{currentCompanyAddress3  ?? "-"}</Text>
                    <Text style={styles.headerTextSmall}>Phone : {currentCompanyPhoneNum  ?? "-"}{'\n'}
                        Website : {currentCompanyWebsite  ?? "-"}</Text>
                </View>

            </View>
            <View style={styles.headerDivEnquiryTitle}>
                {
                    currentEnqId != 0 ?
                        <Text style={[styles.headerTextMediumLeft,styles.bold]}>Title :  {(getNavItemById(currentEnqId).actualLbl) ?  (getNavItemById(currentEnqId).actualLbl) : "-"} </Text>
                        : <></>
                }


            </View>
            <View style={styles.headerDivEnquiryTitle}>
                <Text style={[styles.headerTextMediumLeft,styles.bold]}>Date : {dateTitle} </Text> {'\n'}
            </View>
            <View style={styles.headerDivEnquiryTitle}>
                <Text style={[styles.headerTextMediumLeft,styles.bold]}>Duration : {dateDuration} Days </Text>
            </View>



            <View style={styles.bodyDiv}>


                <View style={styles.table}>

                        <View style={styles.table}>
                            <View style={[styles.specialRow, styles.header]}>
                                {(header ?? []).map((row, i) => (
                                    <Text style={[styles.specialChildRow,styles.bold]}>{row.label}</Text>

                                ))}

                            </View>


                            {(contents ?? []).map((row, i) => (
                                <View style={[styles.specialRow, styles.header]}>
                                    <Text style={i%2 == 0 ? [styles.specialChildRowWhite] : [styles.specialChildRowGrey]}>{row.p_fullname}</Text>
                                    <Text style={i%2 == 0 ? [styles.specialChildRowWhite] : [styles.specialChildRowGrey]}>{row.username}</Text>
                                    <Text style={i%2 == 0 ? [styles.specialChildRowWhite] : [styles.specialChildRowGrey]}>{row.ut_name}</Text>
                                    <Text style={i%2 == 0 ? [styles.specialChildRowWhite] : [styles.specialChildRowGrey]}>{row.account_no}</Text>
                                    <Text style={i%2 == 0 ? [styles.specialChildRowWhite] : [styles.specialChildRowGrey]}>{row.account_type}</Text>
                                    <Text style={i%2 == 0 ? [styles.specialChildRowWhite] : [styles.specialChildRowGrey]}> {row.active_status == "0" ? "Inactive" :
                                        row.active_status == "1" ? "Active" :
                                            row.active_status == "2" ? "Suspended" :
                                                row.active_status == "3" ? "Expired" :
                                                    row.active_status == "4" ? "Dormant" :
                                                        row.active_status == "5" ? "Closed" : ""
                                    }</Text>
                                    <Text style={i%2 == 0 ? [styles.specialChildRowWhite] : [styles.specialChildRowGrey]}> {row.active_status == "1" ? "Unblock" : "Block"}</Text>
                                    <Text style={i%2 == 0 ? [styles.specialChildRowWhite] : [styles.specialChildRowGrey]}>{row.timelast_update}</Text>


                                </View>

                            ))}
                        </View>


                </View>
                {'\n'}

            </View>

            <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (
                `Page ${pageNumber} / ${totalPages}`

            )} fixed/>
            <Text style={[styles.pageNumberLeft]} fixed>Generated by {userName + "@" + dayjs().format("DD-MM-YYYY hh:mm:ss")} </Text>
        </Page>
    )
}

export function formatDates (from, to)  {
    const dateFormat = 'DD-MMMM-YYYY'; // You can adjust the format as needed

    // Parse the dates using dayjs
    const fromDate = dayjs(from);
    const toDate = dayjs(to);

    // Check for invalid or empty dates
    const formattedFrom = fromDate.isValid() ? fromDate.format(dateFormat) : '-';
    const formattedTo = toDate.isValid() ? toDate.format(dateFormat) : '-';

    // Create the outcome string
    return `${formattedFrom} To  ${formattedTo}`;
};


export function calculateDuration(startDate, endDate) {
    const formatString = 'YYYY-MM-DD';

    // Parse the dates using dayjs
    const start = dayjs(startDate, { strict: true });
    const end = dayjs(endDate, { strict: true });

    // Check if both dates are valid
    if (start.isValid() && end.isValid()) {
        // Calculate the duration
        const durationInDays = end.diff(start, 'day');

        return durationInDays;
    }

    // Return "-" if unable to calculate the duration
    return '-';
}