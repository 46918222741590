import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    accessStatusOptions, convertFuncPerm, dateFormats, dateTimeFormat, getDefaultAccessMat, getNavIdByPath, handlePopup,
    handleSelectChange,
    numProcessor, useLocalStorage,
    userTypeCategory, convertErMsg, specialCharCheck
} from "../../../general/general-tools";
import {confirmAlert} from "react-confirm-alert";
import {usePopupManager} from "react-popup-manager";
import ModalConfirm from "../../public/ModalConfirm";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";
import dayjs from "dayjs";

const Index = () => {
    const {setLoading} = useLoading();
    const popupManager = usePopupManager();

    const [tableData, setTableData] = useLocalStorage("userAc_tableDat", []);
    ;
    const [totalRecords, setTotalRecords] = useLocalStorage("userAc_totRec",0);
    const [currentPage, setCurrentPage] = useLocalStorage("userAc_curPage",1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage("userAc_curPerpage",10);
    const [selectedRows, setSelectedRows] = useLocalStorage("userAc_selRow",null);

    const [searchInput, setSearchInput] = useLocalStorage("userAc_SearchIpt","");
    const [userType, setUserType] = useLocalStorage("userAc_usrType","");
    const [userName, setUserName] = useLocalStorage("userAc_usrName","");
    const [accessStatus, setAccessStatus] = useLocalStorage("userAc_accStat","");
    const [userTypeList, setUserTypeList] = useState([]);
    const [toggledClearRows, setToggleClearRows] = React.useState(false);


    let navigate = useNavigate();
    const location = useLocation();
    const isInitialMount = useRef(true);
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat() : getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);

    useLayoutEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        if (tableData.length >0) {
            handleInitList()
        }
        handleUserTypeList();
    }, []);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleInitList()
        }

    }, [currentPage, currentPerPage]);
    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {

        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }
    useEnterKeyPress(handleEnterKeyPress);
    const handleInitList = async (newSearch = false) => {

        setLoading(true);
        setToggleClearRows(!toggledClearRows);
        if (
            searchInput.length >= 2 ||
            userName.length >= 2 ||
            (userType != "" && userType != null) ||
            (accessStatus != "" && accessStatus != null)
        ) {
            let curPage = currentPage;
            if(newSearch){
                isInitialMount.current = true;
                curPage = 1;
            }
            api.getUserAccessList(currentPerPage, curPage, accessStatus, searchInput, userName, userType).then((result: any) => {
                if(result.status == 1){
                    handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                    setLoading(false);
                }

               if(result.data){
                   if (result.data && result.data.list.length > 0) {
                       let dataList = result.data.list;
                       const timeout = setTimeout(() => {
                           setTableData(dataList);
                           let totalPage = parseInt(result.data.totalrow)
                           setTotalRecords(totalPage);
                           setLoading(false);
                       }, 10);
                       return () => clearTimeout(timeout);
                   } else {
                       handlePopup(true, "Error", "No results found", popupManager)
                       setTableData([]);
                       setTotalRecords(10);
                       setLoading(false);
                   }
               }else {
                   handlePopup(true, "Error", "No results found", popupManager)
                   setTableData([]);
                   setTotalRecords(10);
                   setLoading(false);
               }
            });
        } else {
            handlePopup(true, "Error", "Search term must exceed 2 characters.", popupManager)
            setLoading(false);

        }



    };

    const handleUserTypeList = async () => {
        setLoading(true);
        api.getUserTypeList(999, 1, 1, null).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if(result.data){
                if (result.data.list.length > 0) {
                    setUserTypeList(result.data.list);
                }
            }
            setLoading(false);

        });
    };

    const handleResetAccess = async () => {
        await setLoading(true);
        popupManager.open(ModalConfirm, {
            title: 'Reset',
            content: 'Are you sure you want reset selected user access status?',
            onClose: (...params) => handleResetItem(params[0])
        });
        setLoading(false);
    };
    const handleResetItem = async (selBoolean) => {
        if (!selBoolean) return;

        let selectedItem = selectedRows['selectedRows'];



        let selectedList = [];
        for (let i = 0; i < selectedItem.length; i++) {
        //     if(selectedItem[i].device_id == null){
        //         handlePopup(true,"Error","Device Id not found, please try again.",popupManager)
        //         return false;
        //         break;
        //     }else{
        //         selectedList.push(
        //             {
        //                 id: selectedItem[i].user_id,
        //                 table_type: selectedItem[i].table_type,
        //                 device_id: selectedItem[i].device_id
        //             }
        //         );
        //     }
        //
            selectedList.push(
                {
                    id: selectedItem[i].user_id,
                    table_type: selectedItem[i].table_type,
                    device_id: selectedItem[i].device_id
                }
            );
        }


        api.updateUserAccess(JSON.stringify(selectedList)).then((result: any) => {
            setLoading(false);
            if (result != false && result.status == 0) {
                handleInitList();
                handlePopup(true, "Success", "Access status reset successfully.", popupManager)
                // handleInitList();
            } else {
                setLoading(false);
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
            }
        });

    };


    const handleResetBtn = async () => {
        await setLoading(true);
        setSearchInput("");
        setUserName("");
        setUserType("");
        setAccessStatus("");
        setLoading(false);
    };


    const columns = useMemo(
        () => [
            {
                id: row => row.p_fullname,
                name: 'Username',
                selector: row => row.p_fullname,
                sortable: true,
            },
            {
                name: 'User ID',
                selector: row => row.username,
                sortable: true
            }, {
                name: 'Device',
                selector: row => row.device ?? "-",
                sortable: true
            },
            {
                name: 'Last Login Date',
                selector: row => (dayjs(row.last_login).format(dateTimeFormat()) == 'Invalid Date' ? "-" :  dayjs(row.last_login).format(dateTimeFormat())),
                sortable: true
            },
            {
                name: 'Access Status',
                selector: row => row.active_status,
                sortable: true
            }
        ],
        []
    );
    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };


    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; User
                                Maintenance  &gt; User Access - Search</h5>
                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">User ID</div>
                                    <div className="label col-md-5">
                                        <input
                                            autoComplete='off'
                                            className="form-control "

                                            type="text"
                                            placeholder="Search by User ID "
                                            aria-label="Search Input"
                                            value={searchInput}
                                            onChange={(e) => setSearchInput(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setSearchInput,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-1">User Type</div>
                                    <div className="label col-md-5">
                                        <select
                                            className="form-control"
                                            aria-label=""
                                            value={userType}
                                            onChange={e => handleSelectChange(e.target.value, setUserType, false)}
                                        >
                                            <option value="">Please select user type</option>
                                            {userTypeList.map(el => <option
                                                value={el.ut_id}>{el.ut_name}</option>)}
                                        </select>
                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Username</div>
                                    <div className="label col-md-5">
                                        <input
                                            autoComplete='off'
                                            className="form-control "

                                            type="text"
                                            placeholder="Search by username "
                                            aria-label="Search Input"
                                            value={userName}
                                            onChange={(e) => setUserName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setUserName,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-1">Access Status</div>
                                    <div className="label col-md-5">
                                        <select
                                            className="form-control"

                                            aria-label=""
                                            value={accessStatus}
                                            onChange={e => handleSelectChange(e.target.value, setAccessStatus, false)}
                                        >
                                            {accessStatusOptions()}
                                        </select>
                                    </div>

                                </div>
                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary "   id="btn-search-main" onClick={() => handleInitList(true)}>Search
                                        </button>
                                        <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    perPageQty={setCurrentPerPage}
                                    handleSelect={setSelectedRows}
                                    columns={columns}
                                    data={tableData}
                                    clearRows={toggledClearRows}
                                    pagination={{
                                        currentPage: currentPage,
                                        currentPerPage: currentPerPage,
                                        totalRecords: totalRecords,
                                        selectable: actualPerm['edit'] ? true : false,
                                        showAllSelBool: true,
                                        selectableHandle: {handleChange},
                                    }}
                                />
                                {actualPerm['edit'] ?
                                    <button className="btn btn-primary " onClick={() => handleResetAccess()}>Reset
                                        Access
                                    </button> : ""}


                            </div>

                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
