import './App.css';

import {useLocation, Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import {useState, useEffect, useRef} from 'react';
import {useCookies} from 'react-cookie';
import moment from 'moment';
import 'moment-timezone';


import {setAccessLevel} from '/src/hooks/handleCookie'

//public path
import Missing from './components/pages/public/Missing';
import Unauthorized from './components/pages/public/Unauthorized';
import PgLogin from './components/pages/public/PgLogin';

//protected path
import Dashboard from './components/pages/protected/Dashboard';
import MstCurrency from './components/pages/protected/Currency/MstCurrency';
import MstCurrencyOpr from './components/pages/protected/Currency/MstCurrencyOpr';
import MstCountry from './components/pages/protected/Country/MstCountry';
import MstCountryOpr from './components/pages/protected/Country/MstCountryOpr';
import MstHoliday from './components/pages/protected/Holiday/MstHoliday';
import MstHolidayOpr from './components/pages/protected/Holiday/MstHolidayOpr';
import MstBranch from './components/pages/protected/Branch/MstBranch';
import MstBranchOpr from './components/pages/protected/Branch/MstBranchOpr';
import MstCompany from './components/pages/protected/Company/MstCompany';
import MstExchange from './components/pages/protected/Exchange/MstExchange';
import MstExchangeOpr from './components/pages/protected/Exchange/MstExchangeOpr';
import MstUserType from './components/pages/protected/UserType/MstUserType';
import MstUserTypeOpr from './components/pages/protected/UserType/MstUserTypeOpr';
import MstDealerGroup from './components/pages/protected/DealerGroup/MstDealerGroup';
import MstDealerGroupOpr from './components/pages/protected/DealerGroup/MstDealerGroupOpr';
import MstBroker from "./components/pages/protected/Broker/MstBroker";
import MstBrokerOpr from "./components/pages/protected/Broker/MstBrokerOpr";
import MstClientDealer from "./components/pages/protected/ClientDealer/MstClientDealer";
import MstClientDealerOpr from "./components/pages/protected/ClientDealer/MstClientDealerOpr";
import MstUserProfile from "./components/pages/protected/UserProfile/MstUserProfile";
import MstUserProfileOpr from "./components/pages/protected/UserProfile/MstUserProfileOpr";
import MstUserAccess from "./components/pages/protected/UserAccess/MstUserAccess";
import MstParameter from "./components/pages/protected/ParameterMaintain/MstParameter";
import MstAuthLimit from "./components/pages/protected/AuthLimit/MstAuthLimit";
import MstAuthLimitOpr from "./components/pages/protected/AuthLimit/MstAuthLimitOpr";
import MstDealerGroupAssign from "./components/pages/protected/DealerGroupAssign/MstDealerGroupAssign";
import MstDealerGroupAssignOpr from "./components/pages/protected/DealerGroupAssign/MstDealerGroupAssignOpr";
import MstBroadcastEntry from "./components/pages/protected/BroadcastEntry/MstBroadcastEntry";
import MstBroadcastApprove from "./components/pages/protected/BroadcastApprove/MstBroadcastApprove";
import MstBroadcastApproveOpr from "./components/pages/protected/BroadcastApprove/MstBroadcastApproveOpr";
import MstUserAccessMat from "./components/pages/protected/UserAccessMat/MstUserAccessMat";
import MstUserAccessMatOpr from "./components/pages/protected/UserAccessMat/MstUserAccessMatOpr";
import ExposureLimitCompany from "./components/pages/protected/ExposureLimit/ExposureLimitCompany";
import ExposureLimitExchange from "./components/pages/protected/ExposureLimit/ExposureLimitExchange";
import ExposureLimitExchangeOpr from "./components/pages/protected/ExposureLimit/ExposureLimitExchangeOpr";
import ExposureLimitBranch from "./components/pages/protected/ExposureLimit/ExposureLimitBranch";
import ExposureLimitBranchOpr from "./components/pages/protected/ExposureLimit/ExposureLimitBranchOpr";
import ExposureLimitDealer from "./components/pages/protected/ExposureLimit/ExposureLimitDealer";
import ExposureLimitDealerOpr from "./components/pages/protected/ExposureLimit/ExposureLimitDealerOpr";
import ExposureLimitAccount from "./components/pages/protected/ExposureLimit/ExposureLimitAccount";
import ExposureLimitAccountOpr from "./components/pages/protected/ExposureLimit/ExposureLimitAccountOpr";
import ExposureLimitStockCo from "./components/pages/protected/ExposureLimit/ExposureLimitStockCo";
import ExposureLimitStockCoOpr from "./components/pages/protected/ExposureLimit/ExposureLimitStockCoOpr";
import ExposureLimitStockBranch from "./components/pages/protected/ExposureLimit/ExposureLimitStockBranch";
import ExposureLimitStockBranchOpr from "./components/pages/protected/ExposureLimit/ExposureLimitStockBranchOpr";
import ExposureLimitStockBranchSub from "./components/pages/protected/ExposureLimit/ExposureLimitStockBranchSub";
import DisplayTempFile from "./components/pages/protected/File/DisplayTempFile";
import MstClientAdmEntry from "./components/pages/protected/ClientAmendment/MstClientAdmEntry";
import MstClientAdmStatus from "./components/pages/protected/ClientAmendment/MstClientAdmStatus";
import MstClientAdmApproval from "./components/pages/protected/ClientAmendment/MstClientAdmApproval";
import MstClientAdmOpr from "./components/pages/protected/ClientAmendment/MstClientAdmOpr";
import ErrorAuth from "./components/pages/protected/ErrorPage/UnauthPage";
import MstTaskSchedular from "./components/pages/protected/Task/MstTaskSchedular";
import MstBroadcastEntryOpr from "./components/pages/protected/BroadcastEntry/MstBroadcastEntryOpr";
import MstAudit from "./components/pages/protected/AuditView/MstAudit";

import LayoutContainer from './components/layouts/LayoutContainer';

//use global loading screen
import {LoadingProvider, useLoading} from './context/LoadingContext';
import api from '../src/apis';
import {confirmAlert} from "react-confirm-alert";
import {
    changeBase, convertAccessMatList, convertErMsg, convertSpecialPerm,
    getCookie,
    getDefaultAccessMat,
    getNavIdByPath,
    handleLoadingPopup,
} from "./components/general/general-tools";
import {getUserAccessDetail} from "./hooks/handleCookie";
import {usePopupManager} from "react-popup-manager";
import MstTaskSchedularOpr from "./components/pages/protected/Task/MstTaskSchedularOpr";
import MstTaskMonitor from "./components/pages/protected/Task/MstTaskMonitor";
import MstEnquiry from "./components/pages/protected/Enquiry/MstEnquiry";

function App() {
    const popupManager = usePopupManager();
    const navigate = useNavigate();

    const [cookies, setCookie, removeCookie] = useCookies(['admin_user']);
    const [popupTime] = useState(parseInt(process.env.REACT_APP_SESSION_TIMEOUT));
    const [logoutTime] = useState(process.env.REACT_APP_SESSION_WARNING_LOGOUT_TIME);
    const [timeZone] = useState(process.env.REACT_APP_TIME_ZONE);
    const [refreshRequired, setRefreshRequired] = useState(false);


    const [lastVisit, setLastVisit] = useState("");
    const [toggleHeartBeat, setToggleHeartBeat] = useState(false);
    const isInitialMount = useRef(true);
    const [heartBeatSwitch, setHeartBeatSwitch] = useState(true);
    const [loginMode, setLoginMode] = useState(0);

    let sess_exp = parseInt(process.env.REACT_APP_SESSION_TIMEOUT);
    let logout_counter_exp = process.env.REACT_APP_SESSION_WARNING_LOGOUT_TIME;
    let onGoingLogout = false;

    let expireTime = getCookie('expiredTime') ?? 99999999999999;
    const timeout = useRef(null);
    const logout_count = useRef(null);
    const heartbeat = useRef(null);


    //Forever not expired before init

    //Active event listener
    const location = useLocation();
    const [route, setRoute] = useState({ //--> it can be replaced with useRef or localStorage
        to: location.pathname,
        from: location.pathname
    });
    let userAccess = getUserAccessDetail();
    if (isInitialMount.current) {
        isInitialMount.current = false;
    }

    useEffect(() => {
        const onFocus = () => {
            console.log("[Browser Change OnFocus] Now user are GAINING focus in the tab, trigger the heartbeat (To refresh)")
            if (cookies.admin_user) {
                setLoginMode(cookies.admin_user.is_kick ?? 0);
                setTimeout(function () {
                    setHeartBeatSwitch(true);
                    setPopupTimer();
                }, 1000);
            }
        };

        const onBlur = () => {
            console.log("[Browser Change OnBlur] Now user are LOSE focus in the tab, trigger the heartbeat (To refresh)")
            setHeartBeatSwitch(false);

        };

        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);

        // Cleanup on unmount
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    }, []);


    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;

            document.addEventListener("visibilitychange", () => {
                if (document.hidden) {
                    console.log("[HeartBeats] Now user are off the tab, trigger the heartbeat (To refresh)")
                    if (cookies.admin_user) {
                        setLoginMode(cookies.admin_user.is_kick ?? 0);
                        setTimeout(function () {
                            setHeartBeatSwitch(true);
                            setPopupTimer();
                        }, 1000);
                    }                } else {
                    console.log("[HeartBeats] Now user are ON the tab, trigger the heartbeat (To refresh)")
                    if (cookies.admin_user) {
                        setLoginMode(cookies.admin_user.is_kick ?? 0);
                        setTimeout(function () {
                            setHeartBeatSwitch(true);
                            setPopupTimer();
                        }, 1000);
                    }
                }
            });
        } else {
            if (cookies.admin_user) {
                setLoginMode(cookies.admin_user.is_kick ?? 0);
                setTimeout(function () {
                    setHeartBeatSwitch(true);
                    setPopupTimer();
                }, 1000);
            }
        }
    }, [toggleHeartBeat]);

    useEffect(() => {
        //Visit records
        let toGo = location.pathname.replace("_opr", "").replace("_sub", "")
        let funcPerm = (userAccess != null ? [parseInt(getNavIdByPath(toGo))] : "999");
        // let viewPerm = funcPerm.toString()[0];
        if (funcPerm != null && funcPerm.toString()[0] == 0) {
            localStorage.clear();
            navigate("/" + process.env.REACT_APP_URL_PREFIX + "errorauth",)
            confirmAlert({
                title: 'Error',
                message: <div id="area_confirm">You are not authorised to access this page.</div>,
                buttons: [{
                    label: 'Ok'
                }],
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
        } else {
            if (location.pathname != "/" && !location.pathname.includes("_opr") && !location.pathname.includes("_sub")) {
                api.registerMenu(getNavIdByPath(location.pathname)).then((result: any) => {
                });
            }
        }

        if (toGo != lastVisit) {
            setLastVisit(toGo);
            if (lastVisit != '') {
                localStorage.clear();
            }

        }


    }, [location.pathname]);

    useEffect(() => {
        setTimeout(function () {
            sessionCookiesChecking();
        }, 1000);


    }, []);


    useEffect(() => {

        if((cookies.admin_user?.is_kick ?? 0) === 1){
            startHeartBeatServices();
        }

    }, [heartBeatSwitch]);


    function startHeartBeatServices(killSwitch) {
        handleGetHeartbeat(heartBeatSwitch);
        startTimeoutServices(heartBeatSwitch);

        clearInterval(heartbeat.current)
        clearInterval(timeout.current)
        heartbeat.current = null;
        timeout.current = null;

        heartbeat.current = setInterval(() => {
            handleGetHeartbeat(heartBeatSwitch);
        }, 10000);

        timeout.current = setInterval(() => {
            startTimeoutServices(heartBeatSwitch);
        }, 1000);

    }

    function startTimeoutServices(killSwitch) {
        if (killSwitch) {
            expireTime = getCookie('expiredTime').toString().substring(0,10);
            let timeNow = moment().valueOf().toString().substring(0,10);
            let compare1 = moment(parseInt(expireTime));
            let compare2 = moment(parseInt(timeNow));
            var seconds = moment.duration(compare1.diff(compare2));
            if (seconds <= 45) {
                clearInterval(timeout.current);
                showConfirmDiv();
            }
        }
    }

    const sessionCookiesChecking = () => {
        if (refreshRequired) {
            setRefreshRequired(false);
            window.location.reload();
        }
        if (cookies.admin_user) {
            handleLoadingPopup(true, "Verifying Session", "Please do not refresh.", setToggleHeartBeat(!toggleHeartBeat), popupManager)
            expireTime = getCookie('expiredTime');
            if (expireTime && ((parseInt(expireTime)) < parseInt(moment().valueOf().toString().substring(0, 10)))) {
                popupManager.closeAll();
                handleLogout();
            } else {
                popupManager.closeAll();
            }
        } else {

            removeCookie('admin_user', {path: '/'});
            removeCookie('access_level', {path: '/'});
            removeCookie('special_perm', {path: '/'});
            localStorage.clear();
            navigate('/');

        }
    }
    //Handle Timeout
    const setPopupTimer = () => {
        // clearInterval(logout_count.current);
        // logout_count.current = null;

        if (cookies.admin_user) {
            sess_exp = popupTime;

            let expireTime = getCookie('expiredTime');
            var exp_time = expireTime.substring(0, 10);

            if (expireTime && ((moment.unix(exp_time) < moment().valueOf()))) {
                handleLogout();
            }
        }
    };
    const showConfirmDiv = () => {
        setLogoutTimer();
        confirmAlert({
            title: 'Session Expiring Soon!',
            message: <div id="area_confirm">Your session is about to expire in 45 second(s), click
                "Ok" if you want to continue and stay logged in.</div>,
            buttons: [{label: 'Ok', onClick: () => resetAllCounters()}, {
                label: 'Logout',
                id: 'btn_logout_confirm',
                onClick: () => handleLogout()
            }],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });

    };

    const setLogoutTimer = () => {
        if (logout_count) {
            clearInterval(logout_count.current);
            logout_counter_exp = logoutTime;
        }
        onGoingLogout = true;
        logout_count.current = setInterval(function () {
            if (logout_counter_exp == 0) {
                clearInterval(logout_count.current);
                document.getElementById("btn_logout_confirm").click();
                handleLogout();
                setRefreshRequired(true);
            }
            logout_counter_exp -= 1000;
            document.getElementById('area_confirm').innerHTML =
                '<div id="area_confirm">Your session is about to expire in ' + (logout_counter_exp / 1000) + ' second(s), click "Ok" if you want to continue and stay logged in.</div>';

        }, 1000);
    };

    const resetAllCounters = () => {
        onGoingLogout = false;

        clearInterval(timeout.current);
        clearInterval(heartbeat.current);
        clearInterval(logout_count.current);

        handleResetHeartBeat();

    };

    const onMouseMove = () => {
        if (!onGoingLogout) {
            setPopupTimer();
        }
    };


    const handleLogout = () => {
        onGoingLogout = false;
        api.userLogout().then((result: any) => {
            removeCookie('admin_user', {path: '/'});
            removeCookie('access_level', {path: '/'});
            removeCookie('expiredTime', {path: '/'});
            localStorage.clear();
            navigate('/');
        });
    };


    const navigateLogout = () => {
        onGoingLogout = false;
        setHeartBeatSwitch(false);
        removeCookie('admin_user', {path: '/'});
        removeCookie('access_level', {path: '/'});
        removeCookie('expiredTime', {path: '/'});
        localStorage.clear();
        navigate('/');
    };


    const handleGetHeartbeat = () => {
        if(cookies?.admin_user){
            api.getHeartbeat().then((result: any) => {
                if(result.status == 4){
                    confirmAlert({
                        title: 'Logout',
                        message: <div
                            id="area_confirm">{result.message ? result.message : "We had detected duplicated login in from another device."}</div>,
                        buttons: [
                            {label: 'Ok', onClick: () => navigateLogout()}],
                        closeOnEscape: false,
                        closeOnClickOutside: false,
                    });
                } else {
                    if (result.data) {
                        let actualLvlArr = result.data.session ?? getDefaultAccessMat();
                        // let actualLvlArr =  getDefaultAccessMat();
                        let accessLevel = convertAccessMatList(actualLvlArr);
                        let specialPerm = convertSpecialPerm(actualLvlArr);
                        setCookie('access_level', accessLevel, {path: '/', maxAge: 2147483647});
                        setCookie('special_perm', specialPerm, {path: '/', maxAge: 2147483647});
                        var time = moment(result.data.expires_in).valueOf();
                        if (result.data.expires_in != undefined || result.data.expires_in != null) {
                            setCookie('expiredTime', parseInt(time.toString().substring(0, 10)), {
                                path: '/',
                                maxAge: 2147483647
                            });
                        }
                        setPopupTimer();
                    }
                }


            });
        }else{
            handleLogout();
        }

    };
    const handleResetHeartBeat = () => {
        api.resetHeartbeat().then((result: any) => {
            // removeCookie('expiredTime', {path: '/'});
            if (result.data) {
                var time = moment(result.expires_in).valueOf();
                setCookie('expiredTime', parseInt(time.toString().substring(0, 10)), {path: '/', maxAge: 2147483647});
                setPopupTimer();

                startHeartBeatServices(true);

            }
        });
    };


    return (
        <LoadingProvider>
            <Routes>
                {cookies.admin_user ? (
                    <>
                        <Route path="/" element={<LayoutContainer/>}>
                            <Route path="" element={<Dashboard/>}/>
                            <Route path="currency" element={<MstCurrency/>}/>
                            <Route path="currency_opr" element={<MstCurrencyOpr/>}/>
                            <Route path="country" element={<MstCountry/>}/>
                            <Route path="country_opr" element={<MstCountryOpr/>}/>
                            <Route path="holiday" element={<MstHoliday/>}/>
                            <Route path="holiday_opr" element={<MstHolidayOpr/>}/>
                            <Route path="branch" element={<MstBranch/>}/>
                            <Route path="branch_opr" element={<MstBranchOpr/>}/>
                            <Route path="company" element={<MstCompany/>}/>
                            <Route path="exchange" element={<MstExchange/>}/>
                            <Route path="exchange_opr" element={<MstExchangeOpr/>}/>
                            <Route path="user_type" element={<MstUserType/>}/>
                            <Route path="user_type_opr" element={<MstUserTypeOpr/>}/>
                            <Route path="dealergp" element={<MstDealerGroup/>}/>
                            <Route path="dealergp_opr" element={<MstDealerGroupOpr/>}/>
                            <Route path="broker" element={<MstBroker/>}/>
                            <Route path="broker_opr" element={<MstBrokerOpr/>}/>
                            <Route path="clientdealer" element={<MstClientDealer/>}/>
                            <Route path="clientdealer_opr" element={<MstClientDealerOpr/>}/>
                            <Route path="user_profile" element={<MstUserProfile/>}/>
                            <Route path="user_profile_opr" element={<MstUserProfileOpr/>}/>
                            <Route path="user_access" element={<MstUserAccess/>}/>
                            <Route path="parameter" element={<MstParameter/>}/>
                            <Route path="auth_limit" element={<MstAuthLimit/>}/>
                            <Route path="auth_limit_opr" element={<MstAuthLimitOpr/>}/>
                            <Route path="dealer_gpas" element={<MstDealerGroupAssign/>}/>
                            <Route path="dealer_gpas_opr" element={<MstDealerGroupAssignOpr/>}/>
                            <Route path="br_entry" element={<MstBroadcastEntry/>}/>
                            <Route path="br_entry_opr" element={<MstBroadcastEntryOpr/>}/>
                            <Route path="br_app" element={<MstBroadcastApprove/>}/>
                            <Route path="br_app_opr" element={<MstBroadcastApproveOpr/>}/>
                            <Route path="user_mat" element={<MstUserAccessMat/>}/>
                            <Route path="user_mat_opr" element={<MstUserAccessMatOpr/>}/>
                            <Route path="exp_company" element={<ExposureLimitCompany/>}/>
                            <Route path="exp_exc" element={<ExposureLimitExchange/>}/>
                            <Route path="exp_exc_opr" element={<ExposureLimitExchangeOpr/>}/>
                            <Route path="exp_branch" element={<ExposureLimitBranch/>}/>
                            <Route path="exp_branch_opr" element={<ExposureLimitBranchOpr/>}/>
                            <Route path="exp_dealer" element={<ExposureLimitDealer/>}/>
                            <Route path="exp_dealer_opr" element={<ExposureLimitDealerOpr/>}/>
                            <Route path="exp_acc" element={<ExposureLimitAccount/>}/>
                            <Route path="exp_acc_opr" element={<ExposureLimitAccountOpr/>}/>
                            <Route path="exp_stock" element={<ExposureLimitStockCo/>}/>
                            <Route path="exp_stock_opr" element={<ExposureLimitStockCoOpr/>}/>
                            <Route path="exp_stockbr" element={<ExposureLimitStockBranch/>}/>
                            <Route path="exp_stockbr_opr" element={<ExposureLimitStockBranchOpr/>}/>
                            <Route path="exp_stockbr_sub" element={<ExposureLimitStockBranchSub/>}/>
                            <Route path="cl_amend_entry" element={<MstClientAdmEntry/>}/>
                            <Route path="cl_amend_approval" element={<MstClientAdmApproval/>}/>
                            <Route path="cl_amend_status" element={<MstClientAdmStatus/>}/>
                            <Route path="cl_amend_opr" element={<MstClientAdmOpr/>}/>
                            <Route path="audit_trail" element={<MstAudit/>}/>
                            <Route path="task_schedular" element={<MstTaskSchedular/>}/>
                            <Route path="task_schedular_opr" element={<MstTaskSchedularOpr/>}/>
                            <Route path="task_monitor" element={<MstTaskMonitor/>}/>
                            <Route path="enquiry" element={<MstEnquiry/>}/>
                            <Route path="*" element={<Dashboard/>}/>
                            <Route path="errorauth" element={<ErrorAuth/>}/>


                            <Route
                                path="/file/:file"
                                element={<DisplayTempFile/>}
                            />

                            {/*{This route is STRICTLY ONLY FOR DEBUGGING AND LAYOUT ADJUSTMENT ONLY}*/}
                            {/*<Route path="pdfviewer" element={<DisplayPDF/>}/>*/}
                            {/*<Route path="enquiryTest" element={<DisplayProfileEnquiry/>}/>*/}

                        </Route>
                        <Route path="*" element={<Dashboard/>}/>

                    </>
                ) : (
                    <Route path="/">
                        {/* public routes */}
                        <Route path="" element={<PgLogin/>}/>
                        {/* } /> */}

                        <Route path="unauthorized" element={<Unauthorized/>}/>
                        {/* <Route path="/" element={<PgLanding />} /> */}
                        <Route path="login" element={<PgLogin/>}/>
                        {/* catch all */}
                        <Route path="*" element={<Missing/>}/>

                    </Route>


                )}
            </Routes>
        </LoadingProvider>

    );
}

export default App;
