import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {data} from "browserslist";
import {usePopupManager} from "react-popup-manager";
import {
    convertErMsg,
    convertFuncPerm,
    convertPositiveNumberHundred, getDefaultAccessMat, getNavIdByPath,
    handlePopup,
    handleSelectChange,
    useLocalStorage
} from "../../../general/general-tools";
import {getAuthLimitList} from "../../../../apis/models/AuthLimit";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";


const Index = () => {
    const {setLoading} = useLoading();

    const [tableData, setTableData] = useLocalStorage("al_search_searchTable", []);
    const [totalRecords, setTotalRecords] = useLocalStorage("al_search_searchTableRec", 0);
    const [currentPage, setCurrentPage] = useLocalStorage("al_search_searchTablePage", 1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage("al_search_searchTableperPage", 10);
    const [searchUserType, setSearchUserType] = useLocalStorage("al_search_searchUserType", "");
    const [userTypeList, setUserTypeList] = useLocalStorage("al_userTypeList_searchUserTypeList", []);

    const isInitialMount = useRef(true);
    const location = useLocation();
    let navigate = useNavigate();
    const popupManager = usePopupManager();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat():getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);


    // useLayoutEffect(() => {
    //
    //
    //     if (!location.state) {
    //         handleResetBtn();
    //
    //     } else {
    //         if (tableData) {
    //             handleInitList();
    //         }
    //     }
    // }, [])

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        handleUserTypeList();
        if(tableData.length > 0){
            handleInitList()
        }
    },[])

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleInitList();
        }
    }, [currentPage, currentPerPage]);

    const handleInitList = async (newSearch = false) => {

        setLoading(true);
        if (searchUserType == "" || isNaN(parseInt(searchUserType))) {
            handlePopup(true, "Error", "Please select user type before search.", popupManager)
            setLoading(false);
            return false;
        }

        if (newSearch) {
            setCurrentPage(1)
            curPage= 1;
        }


        api.getAuthLimitList({"id": parseInt(searchUserType)}).then((result: any) => {

            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    let tempData = [];
                    for (let i = 0; i < dataList.length; i++) {
                        let tempEle = {};
                        tempEle['ut_name'] = dataList[i].ut_name;
                        tempEle['ut_id'] = dataList[i].ut_id;

                        let explmt = JSON.parse(dataList[i].explmt) ?? [];
                        let topup = JSON.parse(dataList[i].topup) ?? [];

                        for (let j = 0; j < explmt.length; j++) {
                            if (explmt[j]['exp_lmt_type'] == 1) {
                                tempEle['explmt_buy'] = explmt[j]['exp_lmt_amount'];
                                tempEle['explmt_buy_enable'] = explmt[j]['exp_lmt_enable'];
                            } else if (explmt[j]['exp_lmt_type'] == 2) {
                                tempEle['explmt_sell'] = explmt[j]['exp_lmt_amount'];
                                tempEle['explmt_sell_enable'] = explmt[j]['exp_lmt_enable'];
                            } else if (explmt[j]['exp_lmt_type'] == 3) {
                                tempEle['explmt_nett'] = explmt[j]['exp_lmt_amount'];
                                tempEle['explmt_nett_enable'] = explmt[j]['exp_lmt_enable'];
                            } else if (explmt[j]['exp_lmt_type'] == 4) {
                                tempEle['explmt_gross'] = explmt[j]['exp_lmt_amount'];
                                tempEle['explmt_gross_enable'] = explmt[j]['exp_lmt_enable'];
                            } else if (explmt[j]['exp_lmt_type'] == 5) {
                                tempEle['explmt_idss'] = explmt[j]['exp_lmt_amount'];
                                tempEle['explmt_idss_enable'] = explmt[j]['exp_lmt_enable'];
                            } else if (explmt[j]['exp_lmt_type'] == 6) {
                                tempEle['explmt_fst'] = explmt[j]['exp_lmt_amount'];
                                tempEle['explmt_fst_enable'] = explmt[j]['exp_lmt_enable'];
                            }
                        }
                        ;

                        for (let j = 0; j < topup.length; j++) {
                            if (topup[j]['topup_type'] == 1) {
                                tempEle['topup_buy'] = topup[j]['topup_amount'];
                                tempEle['topup_buy_enable'] = topup[j]['topup_enable'];
                            } else if (topup[j]['topup_type'] == 2) {
                                tempEle['topup_sell'] = topup[j]['topup_amount'];
                                tempEle['topup_sell_enable'] = topup[j]['topup_enable'];
                            } else if (topup[j]['topup_type'] == 3) {
                                tempEle['topup_nett'] = topup[j]['topup_amount'];
                                tempEle['topup_nett_enable'] = topup[j]['topup_enable'];
                            } else if (topup[j]['topup_type'] == 4) {
                                tempEle['topup_gross'] = topup[j]['topup_amount'];
                                tempEle['topup_gross_enable'] = topup[j]['topup_enable'];
                            } else if (topup[j]['topup_type'] == 5) {
                                tempEle['topup_idss'] = topup[j]['topup_amount'];
                                tempEle['topup_idss_enable'] = topup[j]['topup_enable'];
                            }else if (topup[j]['topup_type'] == 5) {
                                tempEle['topup_fst'] = topup[j]['topup_amount'];
                                tempEle['topup_fst_enable'] = topup[j]['topup_enable'];
                            }
                        }
                        ;
                        tempData.push(tempEle);
                    }
                    setLoading(false);

                    setTableData(tempData);
                    let totalPage = parseInt(result.data.totalrow)
                    setTotalRecords(totalPage);
                    return () => clearTimeout(timeout);
                }
                else {
                    handlePopup(true, "Error", "No results found", popupManager)

                    setTableData([]);
                    setTotalRecords(10);
                }
            }else {
                handlePopup(true, "Error", "No results found", popupManager)
                setTableData([]);
                setTotalRecords(10);
            }
            setLoading(false);

        });
    };

    const handleOnclick = async (cur_code) => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "auth_limit_opr", {state: {row: cur_code,perm:actualPerm, mode: 'Edit'}});
        setLoading(false);
    };


    const handleResetBtn = async () => {
        await setLoading(true);
        setSearchUserType("");
        setLoading(false);
    };
    const handleSearch = async () => {
        setLoading(true);

        if (isNaN(searchUserType)) {
            setSearchUserType(null)
        }
        handleInitList()
        setLoading(false);
    };

    const columns = useMemo(
        () => [
            {
                id: row => row.ut_name ?? "N/A",
                name: 'User Type',
                selector: row => row.ut_name ?? "N/A",
                sortable: true,
                // center: true,
                cell: (row: { ut_name: any }) => (
                    <>
                        <a className="tableItemsHlink"
                           onClick={() => handleOnclick(row)}>{row.ut_name ?? "N/A" ?? "-"}</a>
                    </>
                )
            },
            {
                name: 'Buy ',
                selector: row => convertPositiveNumberHundred(row.explmt_buy ?? 0) ?? "-",
                sortable: true,
            },
            {
                name: 'Sell',
                selector: row => convertPositiveNumberHundred(row.explmt_sell ?? 0) ?? "-",
                sortable: true,
            },
            {
                name: 'Nett',
                selector: row => convertPositiveNumberHundred(row.explmt_nett ?? 0) ?? "-",
                sortable: true,
            },
            {
                name: 'Gross',
                selector: row => convertPositiveNumberHundred(row.explmt_gross ?? 0) ?? "-",
                sortable: true,
            },
            {
                name: 'IDSS',
                selector: row => convertPositiveNumberHundred(row.explmt_idss ?? 0) ?? "-",
                sortable: true,
            },
            {
                name: 'FST',
                selector: row => convertPositiveNumberHundred(row.explmt_fst ?? 0) ?? "-",
                sortable: true,
            }
        ],
        []
    );
    const handleUserTypeList = async () => {
        setLoading(true);
        let param = {
            size: 999,
            page: 1,
            is_opr: true
        };
        api.getAuthLimitListUserType(param).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if(result.data){
                if (result.data.list.length > 0) {
                    setUserTypeList(result.data.list);
                }
            }
        });
        setLoading(false);
    };


    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; User
                                Maintenance &gt; Authorization Limit - Search</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="input-container col-md-12 fullWidthSearch">
                                        <div className="label col-md-1">User Type</div>
                                        <select
                                            className="form-control"
                                            aria-label=""
                                            value={searchUserType}
                                            onChange={e => handleSelectChange(e.target.value, setSearchUserType, false)}
                                        >
                                            <option value="">Please select user type</option>
                                            {userTypeList.map(el => <option
                                                value={el.ut_id}>{el.ut_name}</option>)}
                                        </select>

                                    </div>
                                </div>
                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary " onClick={() => handleSearch(true)}>Search
                                        </button>
                                        <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    perPageQty={setCurrentPerPage}
                                    columns={columns}
                                    data={tableData}
                                    pagination={{
                                        currentPage: currentPage,
                                        currentPerPage: currentPerPage,
                                        totalRecords: totalRecords
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
