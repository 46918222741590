import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import {debounce} from "chart.js/types/helpers";
import {
    handleSelectChange,
    useLocalStorage,
    handlePopup,
    getDefaultAccessMat,
    getNavIdByPath,
    convertFuncPerm,
    performAntdTimeCheck,
    momentTimeFormat,
    dateTimeFormat,
    dateFormats, convertErMsg, specialCharCheck
} from "../../../general/general-tools";
import {usePopupManager} from "react-popup-manager";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";
import ReactDatepick from "../../../general/form-control/react-datepick";
import dayjs from "dayjs";
import ReactTooltip from "react-tooltip-rc";


const Index = () => {
    const {setLoading} = useLoading();

    //Data Table Realted
    const [tableData, setTableData] = useLocalStorage("mst_hol_tableData",[]);
    const [exchangeList, setExchangeList] =  useLocalStorage("mst_hol_tableExcg",[]);
    const [totalRecords, setTotalRecords] =useLocalStorage("mst_hol_totalRecord",0);
    const [currentPage, setCurrentPage] = useLocalStorage("mst_hol_currentPage",1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage("mst_hol_currentPerPage",10);

    //Search Related
    const [searchInput, setSearchInput] = useLocalStorage("mst_hol_searchInput", "");

    //Input Realated
    const [holidayFrom, setHolidayFrom] = useLocalStorage("mst_hol_holidayFrom", null);
    const [holidayTo, setHolidayTo] = useLocalStorage("mst_hol_holidayTo", null);
    const [holidayType, setHolidayType] = useLocalStorage("mst_hol_holidayType", "");
    const [holidayDesc, setHolidayDesc] = useLocalStorage("mst_hol_holidayDesc", "");
    const [holidayExchange, setHolidayExchange] = useLocalStorage("mst_hol_holidayExchange", null);
    const [toggleReset, setToggleReset] = useState(false);
    const [isDateValid, setIsDateValid] = useState(false);

    const isInitialMount = useRef(true);
    const popupManager = usePopupManager();

    const location = useLocation();
    let navigate = useNavigate();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat() : getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);

    useLayoutEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        if (tableData.length >0) {
            handleInitList(holidayDesc, holidayFrom, holidayTo, holidayExchange, holidayType)
        } else {
            handleResetBtn();
        }
    }, [])


    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleInitList(holidayDesc, holidayFrom, holidayTo, holidayExchange, holidayType);
        }

    }, [currentPage, currentPerPage]);

    useEffect(() => {
        performAntdTimeCheck(holidayFrom,holidayTo,setHolidayTo,popupManager,setIsDateValid);

    }, [holidayFrom,holidayTo]);

    useEffect(() => {
        handleExchangeList();
    }, []);
    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {
        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }
    useEnterKeyPress(handleEnterKeyPress);
    const handleInitList = async (holidayDescs, holidayFroms, holidayTos, holidayExchanges, holidayTypes,newSearch = false) => {
        let fromDate = dayjs(holidayFroms).format(momentTimeFormat()) ;
        let toDate = dayjs(holidayTos).format(momentTimeFormat()) ;

        setLoading(true);
        if (fromDate != "Invalid Date" && toDate != "Invalid Date") {
            if (!isDateValid) {
                handlePopup(true, "Error", "Please ensure that the ending date is not earlier than the starting date.", popupManager);
                await setLoading(false);
                return false;
            }
        }



        //newSearch = false
        let curPage = currentPage;
           if (newSearch) {
            setCurrentPage(1)
            curPage= 1;
        }


        api.getHolidayList(currentPerPage, curPage, 1, holidayDescs, fromDate == "Invalid Date" ? null:fromDate, toDate == "Invalid Date" ? null:toDate, null, null, holidayExchanges, holidayTypes).then((result: any) => {


            if(result.data){
                if (result.data.list) {
                    if (result.data.list.length > 0) {
                        let dataList = result.data.list;
                        for (let i = 0; i < dataList.length; i++) {
                            if (dataList[i].exchanges) {
                                let exchangeList = JSON.parse(dataList[i].exchanges);
                                dataList[i]['exchange_dispF2'] = "";
                                dataList[i]['exchange_disp'] = "";
                                for (let j = 0; j < exchangeList.length; j++) {
                                    if(j < 2){

                                        if (j == 0) dataList[i]['exchange_dispF2'] += "‣ "+exchangeList[j]['em_code']+"\n";
                                        else dataList[i]['exchange_dispF2'] +=  "‣ "+exchangeList[j]['em_code']+ "\n";
                                    } else{
                                        if (j == 0) dataList[i]['exchange_disp'] += "‣ "+exchangeList[j]['em_code']+"<br/>";
                                        else dataList[i]['exchange_disp'] +=  "‣ "+exchangeList[j]['em_code']+"<br/>";

                                    }
                                }
                            }
                            if (dataList[i].h_type == 0) dataList[i]['h_type_disp'] = "Closed";
                            else if (dataList[i].h_type == 1) dataList[i]['h_type_disp'] = "Partially Open"
                            else if (dataList[i].h_type == 2) dataList[i]['h_type_disp'] = "Test Day"
                        }
                        const timeout = setTimeout(() => {
                            setTableData(dataList);
                            let totalPage = parseInt(result.data.totalrow)
                            setTotalRecords(totalPage);
                        }, 10);
                        return () => clearTimeout(timeout);
                    } else {
                        handlePopup(true, "Error", "No results found", popupManager)
                        setTableData([]);
                        setTotalRecords(10);
                    }
                } else {
                    handlePopup(true, "Error", "No results found", popupManager)
                    setTableData(0);
                    setTotalRecords(0);
                }
            }else{
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)

            }
        });
        setLoading(false);
    };

    const handleExchangeList = async () => {

        setLoading(true);
        api.getExchangeMainList(999, 1, 1, null).then((result: any) => {
            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdown(dataList);
                    setExchangeList(TreeData);
                } else {
                    handlePopup(true, "Error", "No results found", popupManager)
                    setTableData([]);
                    setTotalRecords(10);
                }
            }else {
                handlePopup(true, "Error", "No results found", popupManager)
                setTableData([]);
                setTotalRecords(10);
            }
        });
        setLoading(false);
    };


    const convertRawIntoDropdown = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                code: data[i]['em_code'],
                name: data[i]['em_name'],
                id: data[i]['em_id']
            });
        }
        return returnDat;
    }

    const handleOnclick = async (row) => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "holiday_opr", {
            state: {
                row: row,
                perm: actualPerm,
                mode: 'Edit'
            }
        });
        setLoading(false);
    };

    const handleAddBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "holiday_opr", {
            state: {
                row: "",
                perm: actualPerm,
                mode: 'Add'
            }
        });
        setLoading(false);
    };

    const handleResetBtn = async () => {
        await setLoading(true);
        setSearchInput(null);
        setHolidayFrom("");
        setHolidayTo("");
        setHolidayDesc("");
        setHolidayType("");
        setHolidayExchange([]);
        setToggleReset(!toggleReset)
        setLoading(false);
    };


    // Date  Exchange Status Time
    const columns = useMemo(
        () => [
            {
                id: row => row.h_date,
                name: 'Date',
                selector: row => row.h_date,
                sortable: true,
                // center: true,
                cell: (row: { h_date: any }) => (
                    <>
                        <a className="tableItemsHlink"
                           onClick={() => handleOnclick(row)}>{ (dayjs(row.h_date).format(dateFormats()) == 'Invalid Date' ? "-" :  dayjs(row.h_date).format(dateFormats()))}
                        </a>
                    </>
                )
            },
            {
                name: 'Description',
                selector: row => row.h_remark,
                sortable: true,
            },
            {
                name: 'Exchange',
                cell: (row: { exchange_disp: any }) => (
                    <>
                        <ReactTooltip data-class="my-tooltip" multiline={true} place={'bottom'}/>
                        <div style={{"white-space":"break-spaces"}}>
                            {row.exchange_dispF2}
                            <div style={{"white-space":"pre"}}>
                                {row.exchange_disp ? <div style={{"color":"blue",'font-size':'0.8em'}} className={"linkCursor"} aria-hidden="true" data-class="my-tooltip" data-tip={row.exchange_disp}><b>More</b></div> : ''}

                            </div>
                        </div>


                    </>
                ),
                sortable: true
            },
            {
                name: 'Type',
                selector: row => row.h_type_disp,
                sortable: true
            },
            {
                name: 'Time',
                selector: row => (row.h_time_fr == 'Invalid date' ? "" : row.h_time_fr) + " - " + (row.h_time_to == 'Invalid date' ? "" : row.h_time_to),
                sortable: true
            }
        ],
        []
    );

    const onSelect = (selectedList, selectedItem) => {
        setHolidayExchange(selectedList);
    }
    const onRemove = (selectedList, selectedItem) => {
        setHolidayExchange(selectedList);
    }


    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Master File
                                Maintenance &gt; Holiday - Search</h5>

                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-1">Date</div>
                                    <div className="label col-md-5">


                                        <ReactDatepick
                                            value={holidayFrom}
                                            dateSetter={setHolidayFrom}
                                            // resetToggle={toggleReset}

                                        />

                                    </div>
                                    <div className="col-md-1">To</div>
                                    <div className="col-md-5">

                                        <ReactDatepick
                                            value={holidayTo}
                                            dateSetter={setHolidayTo}
                                            // resetToggle={toggleReset}

                                        />
                                    </div>


                                </div>

                                <div className="input-container  fullWidthSearch ">
                                    <div className="label col-md-1">Type</div>
                                    <div className="label col-md-11 ">
                                        <select
                                            className="form-control"
                                            aria-label=""
                                            value={holidayType}
                                            onChange={e => handleSelectChange(e.target.value, setHolidayType, false)}
                                        >
                                            <option value='' selected>All</option>
                                            <option value="0">Closed</option>
                                            <option value="1">Partially Open</option>
                                            <option value="2">Test Day</option>
                                        </select>
                                    </div>


                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-1">Description</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="form-control "

                                            type="text"
                                            placeholder="e.g. New Year Eve "
                                            value={holidayDesc}
                                            onChange={(e) => setHolidayDesc(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setHolidayDesc,popupManager)}

                                        />
                                    </div>
                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-1">Exchange</div>
                                    <div className=" col-md-11">
                                        <Multiselect
                                            options={exchangeList}
                                            onSelect={onSelect}
                                            onRemove={onRemove}
                                            selectedValues={holidayExchange}
                                            displayValue="code"
                                            showCheckbox={true}
                                            placeholder="Exchange"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary "  id="btn-search-main"
                                                onClick={() => handleInitList(holidayDesc, holidayFrom, holidayTo, holidayExchange, holidayType,true)}>Search
                                        </button>
                                        <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                        </button>
                                        {actualPerm['add'] ? <button className="btn btn-primary "
                                                                     onClick={() => handleAddBtn()}>Add</button> : ""}
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    perPageQty={setCurrentPerPage}
                                    columns={columns}
                                    data={tableData}
                                    pagination={{
                                        currentPage: currentPage,
                                        currentPerPage: currentPerPage,
                                        totalRecords: totalRecords
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
