import {handleRequestGet, handleRequestPost} from '../axios';

export const getHolidayList = async (size, page, status, term,date_fr,date_to,time_fr,time_to,exchange,type) => {
    let url = '/holiday';
    let exchange_str = null;
    if(exchange && exchange.length >0){
        for (let i = 0; i < exchange.length; i++) {
            if(i == 0)exchange_str += exchange[i]['id'];
            else exchange_str += ","+exchange[i]['id'];
        }
    }
    let param = {
        date_fr: date_fr,
        date_to: date_to,
        time_fr: time_fr,
        time_to: time_to,
        exchange: exchange_str,
        term: term,
        type: type,
        size: size,
        page: page
    };

    if(term != "") param['term'] = term;
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const addHoliday = async (data) => {
    let url = '/holiday/create';
    let exchange_str = null;


    let result = await handleRequestPost(url, {
        id: data['id'],
        type: data['type'],
        date: data['date'],
        time_fr: data['time_fr'] ,
        time_to: data['time_to'] ,
        remark: data['remark'] ,
        exchange: data['exchange'] == "" ? null : data['exchange']
    });

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateHolioday = async (data) => {
    let url = '/holiday/update';


    let result = await handleRequestPost(url, {
        id: data['id'],
        type: data['type'],
        date: data['date'],
        time_fr: data['time_fr'] ,
        time_to: data['time_to'],
        remark: data['remark'],
        exchange: data['exchange'] == "" ? null : data['exchange']

    });


    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const deleteHoliday = async (id) => {
    let url = '/holiday/delete';
    let result = await handleRequestPost(url, {
        id: id
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
