import {userLogin, userLogout, getHeartbeat, resetHeartbeat} from './models/UserLogin';
import {getCountryList, addCountry, updateCountry, deleteCountry} from './models/Country';
import {getCurrencyList, addCurrency, updateCurrency, deleteCurrency} from './models/Currency';
import {getBranchList, addBranch, updateBranch, deleteBranch} from './models/Branch';
import {getCompanyDetail, updateCompany} from './models/Company';
import {getBrokerList, addBroker, updateBroker, deleteBroker} from './models/Broker';
import {
    getUserTypeList,
    addUserType,
    updateUserType,
    deleteUSerType,
    updateUserAccessMatrixMarket,
    refreshAccessMatrixMarket,
    getAccessMatricList,
    getOrderValidity,
    updateOrderValidityUserType,
    updateOrderValidityUser,
    getOperationAccessMatList,
    updateOperationAccessMatList,
    updateOperationAccessMatListUserLevel,
    getOperationAccessMatListUserLvl,
    resetOperationAccessMatListUserLevel,
    resetClientDealerMarket, resetClientDealerOrder
} from './models/UserTypeMatrix';
import {getHolidayList, addHoliday, updateHolioday, deleteHoliday} from './models/Holiday';
import {getExchangeList, getExchangeMainList, addExchange, updateExchange, deleteExchange} from './models/Exchange';
import {
    getDealerGroupList,
    addDealerGroup,
    updateDealerGroup,
    deleteDealerGroup,
    getDealerAsscItem, updateDealerGroupAssc, getDealerGroupListDealerAssociation, getDealerGroupListDealerAssoc
} from './models/DealerGroup';
import {
    getELCompany,
    updateCompanyEL,
    getExchangeEL,
    updateExchangeEL,
    getBranchEL,
    updateBranchEL,
    getDealerEL,
    updateDealerEL,
    getAccountEL,
    updateAccountEL,
    getTickerList,
    getStockCoEL,
    updateStockCoEL,
    getStockBranchEL,
    updateStockBranchEL,
    getTCCompany,
    updateCompanyTC,
    getExchangeTC,
    updateExchangeTC,
    getBranchTC,
    updateBranchTC,
    getDealerTC,
    updateDealerTC,
    getAccountTC,
    updateAccountTC,
    getStockCoTC, updateStockCoTC, getStockBranchTC, updateStockBranchTC
} from './models/ExposureLimit';
import {TDSAuthUrl} from './models/TDS';
import {
    searchClientNameAccount,
    getClientProfile,
    getClientAccDetail,
    getAccountContra,
    getAccountContract,
    getAccountContractDetail
} from './models/Account';

import {getStockOptions} from './models/Property';
import {getAccountDealerList, getDealerList, updateDealerAssign, updateDealerRemove} from "./models/Dealer";
import {
    createProfile,
    getProfileList,
    getUserItems,
    saveUserMarketMatrix,
    updateProfile,
    validateUserName
} from "./models/Profile";
import {validatePw} from "./models/Password";
import {getUserAccessList, updateUserAccess} from "./models/UserAccess";
import {getParameterList, updateParameters} from "./models/Parameter";
import {getMenu, registerMenu} from "./models/BrowseHistory";
import {
    getAuthLimitList,
    getAuthLimitListAdmin, getAuthLimitListOwn,
    getAuthLimitListUserType, removeAuthLimitAdmin, updateAuthLimitAdmin,
    updateAuthLimitUserType
} from "./models/AuthLimit";
import {
    broadcastApproval,
    createBroadcast, deleteBroadcast,
    getBroadcastDetail,
    getBroadcastList,
    getBroadcastTicker, getResearchCat,
    updateBroadcast
} from "./models/Broadcast";
import {getFile, uploadFile} from "./models/Files";
import {
    clmtEntryApproval,
    createNewEntry,
    getAmendmentDetail,
    getAmendmentList,
    getAmendmentListStatus
} from "./models/ClientAmend";
import {getAuditOprList, getAuditVarList} from "./models/Audit";
import {
    createTask,
    getSpecificTask,
    getTaskMonitorList,
    getTaskSchedulerList,
    updateTask
} from "./models/TaskScheduler";

//available api function

function availableFunc() {
    return {
        userLogin,
        userLogout,
        getCountryList,
        addCountry,
        updateCountry,
        deleteCountry,
        getCurrencyList,
        addCurrency,
        updateCurrency,
        deleteCurrency,
        getBranchList,
        addBranch,
        updateBranch,
        deleteBranch,
        getCompanyDetail,
        updateCompany,
        getBrokerList,
        addBroker,
        updateBroker,
        deleteBroker,
        getUserTypeList,
        addUserType,
        updateUserType,
        deleteUSerType,
        getHolidayList,
        addHoliday,
        updateHolioday,
        deleteHoliday,
        getExchangeList,
        getExchangeMainList,
        addExchange,
        updateExchange,
        deleteExchange,
        getDealerGroupList,
        addDealerGroup,
        updateDealerGroup,
        deleteDealerGroup,
        getELCompany,
        getTCCompany,
        updateCompanyEL,
        updateCompanyTC,
        getExchangeEL,
        getExchangeTC,
        updateExchangeEL,
        updateExchangeTC,
        getBranchEL,
        getBranchTC,
        updateBranchEL,
        updateBranchTC,
        getDealerEL,
        getDealerTC,
        updateDealerEL,
        updateDealerTC,
        getAccountEL,
        getAccountTC,
        updateAccountEL,
        updateAccountTC,
        getDealerList,
        getAccountDealerList,
        getTickerList,
        getStockCoEL,
        getStockCoTC,
        updateStockCoEL,
        updateStockCoTC,
        getStockBranchEL,
        getStockBranchTC,
        updateStockBranchEL,
        updateStockBranchTC,
        updateDealerAssign,
        getProfileList,
        createProfile,
        updateProfile,
        validateUserName,
        validatePw,
        getUserAccessList,
        updateUserAccess,
        getParameterList,
        updateParameters,
        getHeartbeat,
        resetHeartbeat,
        updateUserAccessMatrixMarket,
        refreshAccessMatrixMarket,
        getUserItems,
        saveUserMarketMatrix,
        getAccessMatricList,
        registerMenu,
        getMenu,
        getOrderValidity,
        updateOrderValidityUser,
        updateOrderValidityUserType,
        getAuthLimitList,
        getAuthLimitListUserType,
        updateAuthLimitUserType,
        getAuthLimitListAdmin,
        updateAuthLimitAdmin,
        removeAuthLimitAdmin,
        getAuthLimitListOwn,
        getBroadcastList,
        createBroadcast,
        updateBroadcast,
        deleteBroadcast,
        broadcastApproval,
        getBroadcastDetail,
        getBroadcastTicker,
        getFile,
        uploadFile,
        getResearchCat,
        getAmendmentList,
        getAmendmentListStatus,
        getAmendmentDetail,
        createNewEntry,
        clmtEntryApproval,
        getOperationAccessMatList,
        updateOperationAccessMatList,
        updateOperationAccessMatListUserLevel,
        getOperationAccessMatListUserLvl,
        resetOperationAccessMatListUserLevel,
        updateDealerRemove,
        getAuditOprList,
        getAuditVarList,
        resetClientDealerOrder,
        resetClientDealerMarket,
        getTaskSchedulerList,
        getTaskMonitorList,
        createTask,
        getSpecificTask,
        updateTask,
        getDealerAsscItem,
        updateDealerGroupAssc,
        getDealerGroupListDealerAssoc

    };
}

export default availableFunc();


