import React, {useState, useEffect} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import {useLoading} from '../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';

import {
    convertErMsg,
    disableAllEleInDiv,
    handlePopup,
    handleSelectChange, specialCharCheck,
    userTypeCategory
} from "../../../general/general-tools";
import {usePopupManager} from "react-popup-manager";
import ModalConfirm from "../../public/ModalConfirm";

const Index = () => {
    const {setLoading} = useLoading();


    const [currentMode, setCurrentMode] = useState("");
    const [currentUserId, setCurrentUserId] = useState("");
    const [currentUserType, setCurrentUserType] = useState("");
    const [currentUserTypeName, setCurrentUserTypeName] = useState("");
    const [currentUserTypeDesc, setCurrentUserTypeDesc] = useState("");
    const [currentUserAccessLevel, setCurrentUserTypeAccessLevel] = useState("");
    const [editable, setEditable] = useState(false);
    const [accessItem, setAccessItem] = useState({});


    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();

    //Load initial data and mode
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        if (location.state) {
            if (location.state.row) {
                setAccessItem(location.state.perm);
                if (!location.state.perm['edit']) {
                    setTimeout(function () {
                        disableAllEleInDiv("mainDiv");
                    }, 50);
                }
                setCurrentUserId(location.state.row.ut_id);
                setCurrentUserType(location.state.row.ut_catid);
                setCurrentUserTypeName(location.state.row.ut_name);
                setCurrentUserTypeDesc(location.state.row.ut_description);
                // setCurrentUserTypeAccessLevel(location.state.row.ut_accesslevel);
            }
            setEditable(location.state.mode == 'Edit');
            setCurrentMode(location.state.mode)
        }
    }, [location.state]);


    // Edit
    const handleBackBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "user_type", {state: {mode: 'back'}});
        setLoading(false);
    };
    const handleRemoveBtn = async () => {
        await setLoading(true);
        popupManager.open(ModalConfirm, {
            title: 'Delete',
            content: 'Are you sure you want to delete?',
            onClose: (...params) => handleRemoveItem(params[0])
        });

        setLoading(false);
    };

    const handleRemoveItem = async (selBoolean) => {
        if (!selBoolean) return;
        api.deleteUSerType(currentUserId).then((result: any) => {
            if (result != false && result.status == 0) {
                setLoading(false);
                handlePopup(true, "Success", "User Type deleted successfully.", popupManager, true, "user_type", navigate)
            } else {
                setLoading(false);
                handlePopup(true, "Error", convertErMsg(result.message), popupManager);
            }
        });
    };


    const handleSaveBtn = async () => {
        await setLoading(true);

        if (!currentUserType) {
            handlePopup(true, "Error", "Mandatory Field - User Type Category not selected.", popupManager)
            setLoading(false);
            return false;
        }

        if (currentUserTypeName == "") {
            handlePopup(true, "Error", "Mandatory Field - User Type not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if (
            currentUserId &&
            currentUserType &&
            currentUserTypeName.trim().length > 2
        ) {
            let data = {
                "id": currentUserId,
                "category": currentUserType.toString(),
                "name": currentUserTypeName,
                "description": currentUserTypeDesc,
                // "accesslevel" : currentUserAccessLevel ?? '{}'
            };

            api.updateUserType(data).then((result: any) => {
                if (result != false && result.status == 0) {
                    setLoading(false);
                    handlePopup(true, "Success", "User type updated successfully.", popupManager, true, "user_type", navigate)
                } else {
                    setLoading(false);
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager);
                }
            });
        } else handlePopup(true, "Error", "Please insert all required fields.", popupManager)
        setLoading(false);
    };


    const handleResetBtn = async () => {
        await setLoading(true);
        setCurrentUserType("");
        setCurrentUserTypeName("");
        setCurrentUserTypeDesc("");
        setLoading(false);
    };

    const handleAddBtn = async () => {
        await setLoading(true);

        if (!currentUserType) {
            handlePopup(true, "Error", "Mandatory Field - User Type Category not selected.", popupManager)
            setLoading(false);
            return false;
        }

        if (currentUserTypeName == "") {
            handlePopup(true, "Error", "Mandatory Field - User Type not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if (
            currentUserType &&
            currentUserTypeName.trim().length > 2
        ) {
            let data = {
                "category": currentUserType.toString(),
                "name": currentUserTypeName,
                "description": currentUserTypeDesc,
                // "accesslevel" : currentUserAccessLevel == '' ? "{}" :currentUserAccessLevel
            };

            api.addUserType(data).then((result: any) => {
                if (result != false && result.status == 0) {
                    setLoading(false);
                    handlePopup(true, "Success", "User type created successfully.", popupManager, true, "user_type", navigate)
                } else {
                    setLoading(false);
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager);
                }
            });

        } else {
            handlePopup(true, "Error", "Please insert all required fields.", popupManager)
            setLoading(false);
        }
    };


    return (
        <div className="pt-3 text-left " id="mainDiv"
        >
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Master File
                                Maintenance &gt; User Type - {currentMode}</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">User Type Category</div>
                                    <div className="label col-md-10">
                                        <select
                                            className="form-control"
                                            aria-label=""
                                            value={currentUserType}
                                            disabled={editable}
                                            onChange={e => handleSelectChange(e.target.value, setCurrentUserType, editable)}
                                        >
                                            {userTypeCategory()}
                                        </select>


                                    </div>
                                    <b className='mandatory-red'>*</b>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">User Type</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="e.g. Super User"
                                            aria-label=""
                                            value={currentUserTypeName}
                                            onChange={(e) => setCurrentUserTypeName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value, setCurrentUserTypeName, popupManager)}

                                        />

                                    </div>
                                    <b className='mandatory-red'>*</b>
                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">User Type Description</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="e.g. With highest access level to all modules, except Trading Platform"
                                            aria-label=""
                                            value={currentUserTypeDesc}
                                            onChange={(e) => setCurrentUserTypeDesc(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value, setCurrentUserTypeDesc, popupManager)}

                                        />

                                    </div>

                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2"></div>


                                    {currentMode.toLowerCase() == 'edit' ?
                                        <div className="label col-md-10 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                            </button>
                                            {accessItem['delete'] ? <button className="btn btn-primary "
                                                                            onClick={() => handleRemoveBtn()}>Delete</button> : ""}
                                            {accessItem['edit'] ? <button className="btn btn-primary "
                                                                          onClick={() => handleSaveBtn()}>Save</button> : ""}

                                        </div> :

                                        <div className="label col-md-10 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                            </button>
                                            <button className="btn btn-primary "
                                                    onClick={() => handleResetBtn()}>Reset
                                            </button>
                                            <button className="btn btn-primary " onClick={() => handleAddBtn()}>Save
                                            </button>
                                        </div>
                                    }


                                </div>


                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
