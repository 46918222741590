import React, {useState, useEffect, useMemo, useLayoutEffect, memo} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    convertPositiveNumberHundred, cursorControlLocked, cursorToFront,
    handleDecimalNumChange,
    handlePositiveNumberChange,
    handlePositiveNumberHundredChange,
    handleTopUpdateEL,
    handleDecimalNumChangeWifCursor,
    handlePopup, disableAllEleInDiv, convertErMsg, specialCharCheck
} from "../../../general/general-tools";
import Toggle from 'react-toggle'
import AmountLimit from "./sub-components/AmountLimit";
import QuantityLimit from "./sub-components/QuantityLimit";
import BidLimit from "./sub-components/BidLimit";
import UpperLowerLimit from "./sub-components/UpperLowerLimit";
import TradeType from "./sub-components/TradeType";
import Others from "./sub-components/Others";
import {usePopupManager} from "react-popup-manager";


const Index = () => {
    const {setLoading} = useLoading();
    const popupManager = usePopupManager();

    const [currentMode, setCurrentMode] = useState("exp_limit");
    const [currentExchange, setCurrentExchange] = useState("");
    const [currentExchangeCode, setCurrentExchangeCode] = useState("");
    const [currentExchangeId, setCurrentExchangeId] = useState("");

    const [currentBuyLimitChecked, setCurrentBuyLimitChecked] = useState(false);
    const [currentExpLimit_buy, setCurrentExpLimit_buy] = useState("0.00");
    const [currentTopup1_buy, setCurrentTopup1_buy] = useState("0.00");
    const [currentTopup2_buy, setCurrentTopup2_buy] = useState("0.00");
    const [prevExp_buy, setPrevExp_buy] = useState("0.00");
    const [todayExp_buy, setTodayExp_buy] = useState("0.00");
    const [remainExp_buy, setRemainExp_buy] = useState("0.00");

    const [currentSellLimitChecked, setCurrentSellLimitChecked] = useState(false);
    const [currentExpLimit_sell, setCurrentExpLimit_sell] = useState("0.00");
    const [currentTopup1_sell, setCurrentTopup1_sell] = useState("0.00");
    const [currentTopup2_sell, setCurrentTopup2_sell] = useState("0.00");
    const [prevExp_sell, setPrevExp_sell] = useState("0.00");
    const [todayExp_sell, setTodayExp_sell] = useState("0.00");
    const [remainExp_sell, setRemainExp_sell] = useState("0.00");

    const [currentGrossLimitChecked, setCurrentGrossLimitChecked] = useState(false);
    const [currentExpLimit_gross, setCurrentExpLimit_gross] = useState("0.00");
    const [currentTopup1_gross, setCurrentTopup1_gross] = useState("0.00");
    const [currentTopup2_gross, setCurrentTopup2_gross] = useState("0.00");
    const [prevExp_gross, setPrevExp_gross] = useState("0.00");
    const [todayExp_gross, setTodayExp_gross] = useState("0.00");
    const [remainExp_gross, setRemainExp_gross] = useState("0.00");

    const [currentNettLimitChecked, setCurrentNettLimitChecked] = useState(false);
    const [currentExpLimit_nett, setCurrentExpLimit_nett] = useState("0.00");
    const [currentTopup1_nett, setCurrentTopup1_nett] = useState("0.00");
    const [currentTopup2_nett, setCurrentTopup2_nett] = useState("0.00");
    const [prevExp_nett, setPrevExp_nett] = useState("0.00");
    const [todayExp_nett, setTodayExp_nett] = useState("0.00");
    const [remainExp_nett, setRemainExp_nett] = useState("0.00");

    const [currentIDSSLimitChecked, setCurrentIDSSLimitChecked] = useState(false);
    const [currentExpLimit_idss, setCurrentExpLimit_idss] = useState("0.00");
    const [currentTopup1_idss, setCurrentTopup1_idss] = useState("0.00");
    const [currentTopup2_idss, setCurrentTopup2_idss] = useState("0.00");
    const [prevExp_idss, setPrevExp_idss] = useState("0.00");
    const [todayExp_idss, setTodayExp_idss] = useState("0.00");
    const [remainExp_idss, setRemainExp_idss] = useState("0.00");


    const [limitChecked, setLimitChecked] = useState(true);
    const [additionalLimit, setAdditionalLimit] = useState(0);
    const [remark, setRemark] = useState("");

    //Amount limit
    const [amountLimitBuy, setAmountLimitBuy] = useState("0.00");
    const [amountLimitSell, setAmountLimitSell] = useState("0.00");
    const [amountLimitControl, setAmountLimitControl] = useState('control_warning');
    const [isAmountLimitReadOnly, setIsAmountLimitReadOnly] = useState(false);

    // Quantity Limit
    const [perTradeBLB, setPerTradeBLB] = useState("0");
    const [perTradeBLS, setPerTradeBLS] = useState("0");
    const [perTradeBLReadOnly, setPerTradeBLReadOnly] = useState(false);
    const [perTradeDBTB, setPerTradeDBTB] = useState("0");
    const [perTradeDBTS, setPerTradeDBTS] = useState("0");
    const [perTradeDBTReadOnly, setPerTradeDBTReadOnly] = useState(false);
    const [perDayBLB, setPerDayBLB] = useState("0");
    const [perDayBLS, setPerDayBLS] = useState("0")
    const [perDayBLReadOnly, setPerDayBLReadOnly] = useState(true);
    const [perTradeBLRadio, setPerTradeBLRadio] = useState('control_warning');

    const [perDayOddLotReadonly, setPerDayOddLotReadonly] = useState(false);
    const [perDayOLB, setPerDayOLB] = useState("0");
    const [perDayOLS, setPerDayOLS] = useState("0");

    const [perTradeOLB, setPerTradeOLB] = useState("0");
    const [perTradeOLS, setPerTradeOLS] = useState("0");
    const [perTradeOLReadOnly, setPerTradeOLReadOnly] = useState(false);

    const [perTradeOLRadio, setPerTradeOLRadio] = useState('control_warning');

    // Bid Limit
    const [bLBuyBL, setBLBuyBL] = useState("0");
    const [bLSellBL, setBLSellBL] = useState("0");
    const [bLBuyBLRadio, setBLBuyBLBLRadio] = useState('control_warning');
    const [bLBuyBLReadOnly, setBLBuyBLReadOnly] = useState(false);
    const [bLBuyOL, setBLBuyOL] = useState("0");
    const [bLSellOL, setBLSellOL] = useState("0");
    const [bLSellBLReadOnly, setBLSellBLReadOnly] = useState(false);
    const [bLBuyBOL, setBLBuyBOL] = useState("0");
    const [bLSellBOL, setBLSellBOL] = useState("0");
    const [bLSellBOLReadOnly, setBLSellBOLReadOnly] = useState(false);

    //Upper Lower Limit
    const [uLLBLUp, setULLBLUp] = useState("1.00");
    const [uLLBLDown, setULLBLDown] = useState("1.00");
    const [uLLBLReadOnly, setULLBLReadOnly] = useState(false);

    const [uLLDBTUp, setULLDBTUp] = useState("1.00");
    const [uLLDBTDown, setULLDBTDown] = useState("1.00");
    const [uLLDBTDownReadOnly, setULLDBTDownReadOnly] = useState(false);

    //Trade Type
    const [IDSSChecked, setIDSSChecked] = useState(false);
    const [PDTChecked, setPDTChecked] = useState(false);
    const [PSSChecked, setPSSChecked] = useState(false);
    const [RSSChecked, setRSSChecked] = useState(false);

    const [IDSSReadOnly, setIDSSReadOnly] = useState(false);
    const [PDTReadOnly, setPDTReadOnly] = useState(false);
    const [PSSeadOnly, setPSSReadOnly] = useState(false);
    const [RSSeadOnly, setRSSReadOnly] = useState(false);

    //Others
    const [othersBuySusChecked, setOthersBuySusChecked] = useState(false);
    const [othersSellSusChecked, setOthersSellSusChecked] = useState(false);
    const [othersShareChSusChecked, setOthersShareChSusChecked] = useState(false);
    const [othersTDaySusChecked, setOthersTDaySusChecked] = useState(false);

    const [othersBuySusReadOnly, setOthersBuySusReadOnly] = useState(true);
    const [othersSellSusReadOnly, setOthersSellSusReadOnly] = useState(true);
    const [othersShareChSusReadOnly, setOthersShareChSusReadOnly] = useState(true);
    const [othersTDayChSusReadOnly, setOthersTDaySusReadOnly] = useState(true);

    const [accessItem, setAccessItem] = useState({});
    const [ownELData, setOwnELData] = useState(null);


    const location = useLocation();

    //Load initial data and mode
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        if (location.state) {
            if (location.state.row) {
                setAccessItem(location.state.perm);
                if (!location.state.perm['edit']) {
                    setTimeout(function () {
                        disableAllEleInDiv("mainDiv");
                    }, 50);
                }

                setCurrentExchange(location.state.row.em_name);
                setCurrentExchangeCode(location.state.row.em_code);
                setCurrentExchangeId(location.state.row.em_id);
                handleInitELData(location.state.row.em_id);
                handleInitTCData(location.state.row.em_id);


            }
        }
        getOwnELData();
    }, []);


    const handleInitELData = async (exchangeId) => {
        setLoading(true);
        let param = {'id': parseInt(exchangeId)}
        api.getExchangeEL(param).then((result: any) => {
            if (result.status == 0 && result.data) {
                if (result.data.list.length > 0) {
                    let data = result.data.list[0];
                    setAdditionalLimit(data.re_addl_limit == null ? 0.00 : parseFloat(data.re_addl_limit).toFixed(2));
                    setLimitChecked(data.re_ctrl_check);
                    setRemark(data.re_remarks);

                    let limits = JSON.parse(data.risk ?? "{}");

                    if (data.risk) {
                        for (let i = 0; i < limits.length; i++) {
                            let topUpRate = parseFloat(limits[i].reel_amt_topup) * 100.00 / parseFloat(limits[i].reel_exp_limit);
                            if (isNaN(topUpRate)) topUpRate = 0;
                            switch (limits[i].reel_type) {
                                case 1:
                                    setCurrentBuyLimitChecked(limits[i].reel_enable);
                                    setCurrentExpLimit_buy(convertPositiveNumberHundred(limits[i].reel_exp_limit));
                                    setCurrentTopup1_buy(convertPositiveNumberHundred(limits[i].reel_amt_topup));
                                    setCurrentTopup2_buy(topUpRate.toFixed(2));
                                    setPrevExp_buy(convertPositiveNumberHundred(limits[i].reel_exp_prevday));
                                    setTodayExp_buy(convertPositiveNumberHundred(limits[i].reel_exp_today));
                                    setRemainExp_buy(convertPositiveNumberHundred(limits[i].reel_exp_remain));
                                    break;
                                case 2:
                                    setCurrentSellLimitChecked(limits[i].reel_enable);
                                    setCurrentExpLimit_sell(convertPositiveNumberHundred(limits[i].reel_exp_limit));
                                    setCurrentTopup1_sell(convertPositiveNumberHundred(limits[i].reel_amt_topup));
                                    setCurrentTopup2_sell(topUpRate.toFixed(2));
                                    setPrevExp_sell(convertPositiveNumberHundred(limits[i].reel_exp_prevday));
                                    setTodayExp_sell(convertPositiveNumberHundred(limits[i].reel_exp_today));
                                    setRemainExp_sell(convertPositiveNumberHundred(limits[i].reel_exp_remain));
                                    break;
                                case 4:
                                    setCurrentGrossLimitChecked(limits[i].reel_enable);
                                    setCurrentExpLimit_gross(convertPositiveNumberHundred(limits[i].reel_exp_limit));
                                    setCurrentTopup1_gross(convertPositiveNumberHundred(limits[i].reel_amt_topup));
                                    setCurrentTopup2_gross(topUpRate.toFixed(2));
                                    setPrevExp_gross(convertPositiveNumberHundred(limits[i].reel_exp_prevday));
                                    setTodayExp_gross(convertPositiveNumberHundred(limits[i].reel_exp_today));
                                    setRemainExp_gross(convertPositiveNumberHundred(limits[i].reel_exp_remain));
                                    break;
                                case 3:
                                    setCurrentNettLimitChecked(limits[i].reel_enable);
                                    setCurrentExpLimit_nett(convertPositiveNumberHundred(limits[i].reel_exp_limit));
                                    setCurrentTopup1_nett(convertPositiveNumberHundred(limits[i].reel_amt_topup));
                                    setCurrentTopup2_nett(topUpRate.toFixed(2));
                                    setPrevExp_nett(convertPositiveNumberHundred(limits[i].reel_exp_prevday));
                                    setTodayExp_nett(convertPositiveNumberHundred(limits[i].reel_exp_today));
                                    setRemainExp_nett(convertPositiveNumberHundred(limits[i].reel_exp_remain));
                                    break;
                                case 5:
                                    setCurrentIDSSLimitChecked(limits[i].reel_enable);
                                    setCurrentExpLimit_idss(convertPositiveNumberHundred(limits[i].reel_exp_limit));
                                    setCurrentTopup1_idss(convertPositiveNumberHundred(limits[i].reel_amt_topup));
                                    setCurrentTopup2_idss(topUpRate.toFixed(2));
                                    setPrevExp_idss(convertPositiveNumberHundred(limits[i].reel_exp_prevday));
                                    setTodayExp_idss(convertPositiveNumberHundred(limits[i].reel_exp_today));
                                    setRemainExp_idss(convertPositiveNumberHundred(limits[i].reel_exp_remain));
                                    break;
                            }
                        }
                    }

                }

            } else {
                setLoading(false);
            }
            setLoading(false);

        });
    };
    const getOwnELData = async () => {
        setLoading(true);
        api.getAuthLimitListOwn().then((result: any) => {
            if (result.data.list) {
                let dataList = result.data.list;
                let tempData = [];
                for (let i = 0; i < dataList.length; i++) {
                    let tempEle = {};
                    tempEle['ut_name'] = dataList[i].ut_name;
                    tempEle['ut_id'] = dataList[i].ut_id;

                    let explmt = JSON.parse(dataList[i].explmt) ?? [];
                    let topup = JSON.parse(dataList[i].topup) ?? [];

                    for (let j = 0; j < explmt.length; j++) {
                        if (explmt[j]['exp_lmt_type'] == 1) {
                            tempEle['explmt_buy'] = explmt[j]['exp_lmt_amount'];
                            tempEle['explmt_buy_enable'] = explmt[j]['exp_lmt_enable'];
                        } else if (explmt[j]['exp_lmt_type'] == 2) {
                            tempEle['explmt_sell'] = explmt[j]['exp_lmt_amount'];
                            tempEle['explmt_sell_enable'] = explmt[j]['exp_lmt_enable'];
                        } else if (explmt[j]['exp_lmt_type'] == 3) {
                            tempEle['explmt_nett'] = explmt[j]['exp_lmt_amount'];
                            tempEle['explmt_nett_enable'] = explmt[j]['exp_lmt_enable'];
                        } else if (explmt[j]['exp_lmt_type'] == 4) {
                            tempEle['explmt_gross'] = explmt[j]['exp_lmt_amount'];
                            tempEle['explmt_gross_enable'] = explmt[j]['exp_lmt_enable'];
                        } else if (explmt[j]['exp_lmt_type'] == 5) {
                            tempEle['explmt_idss'] = explmt[j]['exp_lmt_amount'];
                            tempEle['explmt_idss_enable'] = explmt[j]['exp_lmt_enable'];
                        }
                    }
                    ;

                    for (let j = 0; j < topup.length; j++) {
                        if (topup[j]['topup_type'] == 1) {
                            tempEle['topup_buy'] = topup[j]['topup_amount'];
                            tempEle['topup_buy_enable'] = topup[j]['topup_enable'];
                        } else if (topup[j]['topup_type'] == 2) {
                            tempEle['topup_sell'] = topup[j]['topup_amount'];
                            tempEle['topup_sell_enable'] = topup[j]['topup_enable'];
                        } else if (topup[j]['topup_type'] == 3) {
                            tempEle['topup_nett'] = topup[j]['topup_amount'];
                            tempEle['topup_nett_enable'] = topup[j]['topup_enable'];
                        } else if (topup[j]['topup_type'] == 4) {
                            tempEle['topup_gross'] = topup[j]['topup_amount'];
                            tempEle['topup_gross_enable'] = topup[j]['topup_enable'];
                        } else if (topup[j]['topup_type'] == 5) {
                            tempEle['topup_idss'] = topup[j]['topup_amount'];
                            tempEle['topup_idss_enable'] = topup[j]['topup_enable'];
                        }
                    }

                    tempData.push(tempEle);
                }
                setOwnELData(tempData[0]);
            }

        });

    }
    const handleInitTCData = async (excgId) => {
        api.getExchangeTC({id: excgId}).then((result: any) => {
            if (result.data) {
                if (result.data.list.length > 0) {
                    let tradeItem = result.data.list[0];
                    // rb_bid
                    // tradeItem.rb_ctrl_check = 511
                    // tradeItem?.trade ="[{\"ctrltype\":1,\"ctrl_block\":\"control_block\",\"value1\":\"1.00\",\"value2\":\"2.00\"},{\"ctrltype\":2,\"ctrl_block\":1,\"value1\":\"3\",\"value2\":\"4\"},{\"ctrltype\":3,\"ctrl_block\":1,\"value1\":\"5\",\"value2\":\"6\"},{\"ctrltype\":4,\"ctrl_block\":1,\"value1\":\"0\",\"value2\":\"0\"},{\"ctrltype\":5,\"ctrl_block\":0,\"value1\":\"7\",\"value2\":\"8\"},{\"ctrltype\":6,\"ctrl_block\":0,\"value1\":\"9\",\"value2\":\"10\"},{\"ctrltype\":7,\"ctrl_block\":0,\"value1\":\"11\",\"value2\":\"12\"},{\"ctrltype\":8,\"ctrl_block\":0,\"value1\":\"13.00\",\"value2\":\"16.00\"},{\"ctrltype\":9,\"ctrl_block\":0,\"value1\":\"14.00\",\"value2\":\"15.00\"}]";

                    if (tradeItem?.trade) {
                        let trade = JSON.parse(tradeItem?.trade);

                        for (let i = 0; i < trade.length; i++) {
                            let currentEle = trade[i];
                            let currentType = currentEle['ctrltype'] === undefined ? currentEle['retc_type'] : currentEle['ctrltype'];
                            let currentValue1 = convertPositiveNumberHundred(currentEle['value_1']);
                            let currentValue2 = convertPositiveNumberHundred(currentEle['value_2']);
                            let ctrlBlock = currentEle['retc_block'] ? 1 : 0;
                            switch (currentType) {
                                case 1 :
                                    setAmountLimitControl(ctrlBlock == 0 ? 'control_warning' : 'control_block');
                                    setAmountLimitBuy(currentValue1)
                                    setAmountLimitSell(currentValue2)
                                    break;
                                case 2 :
                                    setPerTradeBLRadio(ctrlBlock == 0 ? 'control_warning' : 'control_block');
                                    setPerTradeBLB(currentValue1.split('.')[0]);
                                    setPerTradeBLS(currentValue2.split('.')[0])
                                    break;
                                case 3 :
                                    setPerTradeBLRadio(ctrlBlock == 0 ? 'control_warning' : 'control_block');
                                    setPerTradeDBTB(currentValue1.split('.')[0])
                                    setPerTradeDBTS(currentValue2.split('.')[0])
                                    break;
                                case 4 :
                                    setPerTradeBLRadio(ctrlBlock == 0 ? 'control_warning' : 'control_block');
                                    setPerDayBLB(currentValue1.split('.')[0])
                                    setPerDayBLS(currentValue2.split('.')[0])
                                    break;
                                case 5 :
                                    setBLBuyBLBLRadio(ctrlBlock == 0 ? 'control_warning' : 'control_block');
                                    setBLBuyBL(currentValue1.split('.')[0])
                                    setBLSellBL(currentValue2.split('.')[0])
                                    break;
                                case 6 :
                                    setBLBuyBLBLRadio(ctrlBlock == 0 ? 'control_warning' : 'control_block');
                                    setBLBuyOL(currentValue1.split('.')[0])
                                    setBLSellOL(currentValue2.split('.')[0])
                                    break;
                                case 7 :
                                    setBLBuyBLBLRadio(ctrlBlock == 0 ? 'control_warning' : 'control_block');
                                    setBLBuyBOL(currentValue1.split('.')[0])
                                    setBLSellBOL(currentValue2.split('.')[0])
                                    break;
                                case 8 :
                                    setULLBLUp(currentValue1.split('.')[0])
                                    setULLBLDown(currentValue2.split('.')[0])

                                    break;
                                case 9 :
                                    setULLDBTUp(currentValue1.split('.')[0])
                                    setULLDBTDown(currentValue2.split('.')[0])
                                    break;

                                case 10 :

                                    setPerTradeOLRadio(ctrlBlock == 0 ? 'control_warning' : 'control_block')
                                    setPerTradeOLB(currentValue1.split('.')[0])
                                    setPerTradeOLS(currentValue2.split('.')[0])
                                    break;

                                case 11 :
                                    setPerTradeOLRadio(ctrlBlock == 0 ? 'control_warning' : 'control_block')
                                    setPerDayOLB(currentValue1.split('.')[0])
                                    setPerDayOLS(currentValue2.split('.')[0])
                                    break;

                            }
                        }
                    }

                    if (tradeItem.re_ctrl_check) {
                        let decimalCtrlCheck = tradeItem.re_ctrl_check;
                        let binaryCtrlCheck = decimalCtrlCheck.toString(2).padStart(9, '0').split('').reverse().join('');

                        if (binaryCtrlCheck[0] == '1') {
                            setLimitChecked(true);
                        }
                        if (binaryCtrlCheck[1] == '0') {
                            setOthersShareChSusChecked(true);
                        }
                        if (binaryCtrlCheck[2] == '1') {
                            setOthersBuySusChecked(true);
                        }
                        if (binaryCtrlCheck[3] == '1') {
                            setOthersSellSusChecked(true);
                        }
                        if (binaryCtrlCheck[4] == '0') {
                            setOthersTDaySusChecked(true);
                        }
                        if (binaryCtrlCheck[5] == '1') {
                            setIDSSChecked(true);
                        }
                        if (binaryCtrlCheck[6] == '1') {
                            setPDTChecked(true);
                        }
                        if (binaryCtrlCheck[7] == '1') {
                            setRSSChecked(true);
                        }
                        if (binaryCtrlCheck[8] == '1') {
                            setPSSChecked(true);
                        }
                    }
                }
            }
        });
    }


    const handleSaveBtn = async () => {
        await setLoading(true);
//Do EL Checking
        if (ownELData) {
            let explmt_buy = currentExpLimit_buy == null ? 0 : currentExpLimit_buy.toString().replace(/,/g, '');
            let explmt_sell = currentExpLimit_sell == null ? 0 : currentExpLimit_sell.toString().replace(/,/g, '');
            let explmt_gross = currentExpLimit_gross == null ? 0 : currentExpLimit_gross.toString().replace(/,/g, '');
            let explmt_nett = currentExpLimit_nett == null ? 0 : currentExpLimit_nett.toString().replace(/,/g, '');
            let explmt_idss = currentExpLimit_idss == null ? 0 : currentExpLimit_idss.toString().replace(/,/g, '');

            let topup_buy = currentTopup1_buy == null ? 0 : currentTopup1_buy.toString().replace(/,/g, '');
            let topup_sell = currentTopup1_sell == null ? 0 : currentTopup1_sell.toString().replace(/,/g, '');
            let topup_gross = currentTopup1_gross == null ? 0 : currentTopup1_gross.toString().replace(/,/g, '');
            let topup_nett = currentTopup1_nett == null ? 0 : currentTopup1_nett.toString().replace(/,/g, '');
            let topup_idss = currentTopup1_idss == null ? 0 : currentTopup1_idss.toString().replace(/,/g, '');


            let OwnELExpBuy = ownELData['explmt_buy'] ?? 0;
            let OwnELExpSell = ownELData['explmt_sell'] ?? 0;
            let OwnELExpGross = ownELData['explmt_gross'] ?? 0;
            let OwnELExpIdss = ownELData['explmt_idss'] ?? 0;
            let OwnELExpNett = ownELData['explmt_nett'] ?? 0;


            let OwnELTopupBuy = ownELData['topup_buy'] ?? 0;
            let OwnELTopupSell = ownELData['topup_sell'] ?? 0;
            let OwnELTopupGross = ownELData['topup_gross'] ?? 0;
            let OwnELTopupIdss = ownELData['topup_idss'] ?? 0;
            let OwnELTopupNett = ownELData['topup_nett'] ?? 0;

            let errorMsg = ''
            if (OwnELExpBuy > 0 && OwnELExpBuy < parseFloat(explmt_buy)) {
                errorMsg += "Buy Exposure Limit, ";
            }
            if (OwnELExpSell > 0 && OwnELExpSell < parseFloat(explmt_sell)) {
                errorMsg += "Sell Exposure Limit, ";
            }
            if (OwnELExpGross > 0 && OwnELExpGross < parseFloat(explmt_gross)) {
                errorMsg += "Total  Exposure Limit, ";
            }
            if (OwnELExpIdss > 0 && OwnELExpIdss < parseFloat(explmt_idss)) {
                errorMsg += "IDSS Exposure Limit, ";
            }
            if (OwnELExpNett > 0 && OwnELExpNett < parseFloat(explmt_nett)) {
                errorMsg += "Nett Exposure Limit, ";
            }
            if (OwnELTopupBuy > 0 && OwnELTopupBuy < parseFloat(topup_buy)) {
                errorMsg += "Authorized Buy Topup Limit, ";
            }
            if (OwnELTopupSell > 0 && OwnELTopupSell < parseFloat(topup_sell)) {
                errorMsg += "Authorized Sell Topup Limit, ";
            }
            if (OwnELTopupGross > 0 && OwnELTopupGross < parseFloat(topup_gross)) {
                errorMsg += "Authorized Total Topup Limit, ";
            }
            if (OwnELTopupIdss > 0 && OwnELTopupIdss < parseFloat(topup_idss)) {
                errorMsg += "Authorized IDSS Topup Limit, ";
            }
            if (OwnELTopupNett > 0 && OwnELTopupNett < parseFloat(topup_nett)) {
                errorMsg += "Authorized Nett Topup Limit, ";
            }
            if (errorMsg != '' && currentMode == 'exp_limit') {
                handlePopup(true, "Error", "Exceeded authorization limit " + errorMsg.substring(0, errorMsg.length - 2), popupManager)
                setLoading(false);
                return false;
            }

            let param = [
                {
                    "risktype": 1, "riskenable": currentBuyLimitChecked,
                    "limit": currentExpLimit_buy == null ? 0 : currentExpLimit_buy.toString().replace(/,/g, ''),
                    "topup": currentTopup1_buy == null ? 0 : currentTopup1_buy.toString().replace(/,/g, '')
                },
                {
                    "risktype": 2, "riskenable": currentSellLimitChecked,
                    "limit": currentExpLimit_sell == null ? 0 : currentExpLimit_sell.toString().replace(/,/g, ''),
                    "topup": currentTopup1_sell == null ? 0 : currentTopup1_sell.toString().replace(/,/g, '')
                },
                {
                    "risktype": 4, "riskenable": currentGrossLimitChecked,
                    "limit": currentExpLimit_gross == null ? 0 : currentExpLimit_gross.toString().replace(/,/g, ''),
                    "topup": currentTopup1_gross == null ? 0 : currentTopup1_gross.toString().replace(/,/g, '')
                },
                {
                    "risktype": 3, "riskenable": currentNettLimitChecked,
                    "limit": currentExpLimit_nett == null ? 0 : currentExpLimit_nett.toString().replace(/,/g, ''),
                    "topup": currentTopup1_nett == null ? 0 : currentTopup1_nett.toString().replace(/,/g, '')
                },
                {
                    "risktype": 5, "riskenable": currentIDSSLimitChecked,
                    "limit": currentExpLimit_idss == null ? 0 : currentExpLimit_idss.toString().replace(/,/g, ''),
                    "topup": currentTopup1_idss == null ? 0 : currentTopup1_idss.toString().replace(/,/g, '')
                },


            ];

            let elParam = {
                "id": currentExchangeId,
                "ctrl_check": limitChecked == false ? 0 : 1,
                "add_limit": additionalLimit,
                "remark": remark,
                "risk": JSON.stringify(param),
                "e_code": location.state.row.e_code
            }


            let tcParam = handleGenerateTCData();
            console.log(elParam)

            setLoading(false)
            if (currentMode == 'exp_limit') {
                handleUpdateEL(elParam);
            } else {
                handleUpdateExhcangeTC(tcParam);
            }


        }
    };

    const handleUpdateEL = (elParam) => {
        api.updateExchangeEL(elParam).then((result: any) => {
            setLoading(false);

            if (result != false && result.status == 0) {
                handlePopup(true, "Success", "Exposure limit information updated successfully.", popupManager, true, "exp_exc", navigate)
                handleInitELData(currentExchangeId);
            } else {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
            }
        });
    }
    const handleUpdateExhcangeTC = (reqParam) => {
        setLoading(true);
        api.updateExchangeTC(reqParam).then((result: any) => {
            setLoading(false);
            if (result != false && result.status == 0) {
                handlePopup(true, "Success", "Exposure limit information updated successfully.", popupManager, true, "exp_exc", navigate)
                handleInitTCData(location.state.row.em_id);
            } else {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
            }
        });
    }

    const handleResetBtn = async () => {
        await setLoading(true);
        handleInitELData(currentExchangeId);
        handleInitTCData(location.state.row.em_id);
        setLoading(false);
    };
    const handleGenerateTCData = () => {
        let ctrlCheckItem = "00000000000";
        let actualCheckItem = "";
        let actualTradeItem = [];
        for (let i = 0; i < ctrlCheckItem.length; i++) {
            switch (i) {
                case 0:
                    actualCheckItem += limitChecked == false ? "0" : "1";
                    if (!isAmountLimitReadOnly) actualTradeItem.push({
                        "ctrltype": 1,
                        "ctrlblock": amountLimitControl == 'control_warning' ? 0 : 1,
                        "value1": amountLimitBuy.toString().replace(/,/g, ''),
                        "value2": amountLimitSell.toString().replace(/,/g, '')
                    })
                    break;
                case 1:
                    actualCheckItem += othersShareChSusChecked == false ? "1" : "0";
                    if (!perTradeBLReadOnly) actualTradeItem.push({
                        "ctrltype": 2,
                        "ctrlblock": perTradeBLRadio == 'control_warning' ? 0 : 1,
                        "value1": perTradeBLB.toString().replace(/,/g, ''),
                        "value2": perTradeBLS.toString().replace(/,/g, '')
                    })
                    break;
                case 2:
                    actualCheckItem += othersBuySusChecked == false ? "0" : "1";
                    if (!perTradeDBTReadOnly) actualTradeItem.push({
                        "ctrltype": 3,
                        "ctrlblock": perTradeBLRadio == 'control_warning' ? 0 : 1,
                        "value1": perTradeDBTB.replace(/,/g, ''),
                        "value2": perTradeDBTS.replace(/,/g, '')
                    })
                    break;
                case 3:
                    actualCheckItem += othersSellSusChecked == false ? "0" : "1";
                    if (!perDayBLReadOnly) actualTradeItem.push({
                        "ctrltype": 4,
                        "ctrlblock": perTradeBLRadio == 'control_warning' ? 0 : 1,
                        // "ctrlblock": 0,
                        "value1": perDayBLB.replace(/,/g, ''),
                        "value2": perDayBLS.replace(/,/g, '')
                    })
                    break;
                case 4:
                    actualCheckItem += othersTDaySusChecked == false ? "1" : "0";
                    if (!bLBuyBLReadOnly) actualTradeItem.push({
                        "ctrltype": 5,
                        "ctrlblock": bLBuyBLRadio == 'control_warning' ? 0 : 1,
                        "value1": bLBuyBL.replace(/,/g, ''),
                        "value2": bLSellBL.replace(/,/g, '')
                    })
                    break;
                case 5:
                    actualCheckItem += IDSSChecked == false ? "0" : "1";
                    if (!bLSellBLReadOnly) actualTradeItem.push({
                        "ctrltype": 6,
                        "ctrlblock": bLBuyBLRadio == 'control_warning' ? 0 : 1,
                        "value1": bLBuyOL.replace(/,/g, ''),
                        "value2": bLSellOL.replace(/,/g, '')
                    })
                    break;
                case 6:
                    actualCheckItem += PDTChecked == false ? "0" : "1";
                    if (!bLSellBOLReadOnly) actualTradeItem.push({
                        "ctrltype": 7,
                        "ctrlblock": bLBuyBLRadio == 'control_warning' ? 0 : 1,
                        "value1": bLBuyBOL.replace(/,/g, ''),
                        "value2": bLSellBOL.replace(/,/g, '')
                    })
                    break;
                case 7:
                    actualCheckItem += RSSChecked == false ? "0" : "1";
                    if (!uLLBLReadOnly) actualTradeItem.push({
                        "ctrltype": 8,
                        "ctrlblock": 0,
                        "value1": uLLBLUp.replace(/,/g, ''),
                        "value2": uLLBLDown.replace(/,/g, '')
                    })
                    break;
                case 8 :
                    actualCheckItem += PSSChecked == false ? "0" : "1";
                    if (!uLLDBTDownReadOnly) actualTradeItem.push({
                        "ctrltype": 9,
                        "ctrlblock": 0,
                        "value1": uLLDBTUp.replace(/,/g, ''),
                        "value2": uLLDBTDown.replace(/,/g, '')
                    })
                    break;
                case 9 :
                    actualTradeItem.push({
                        "ctrltype": 10,
                        "ctrlblock": perTradeOLRadio == 'control_warning' ? 0 : 1,
                        "value1": perTradeOLB.replace(/,/g, ''),
                        "value2": perTradeOLS.replace(/,/g, '')
                    })
                    break;
                case 10 :
                    actualTradeItem.push({
                        "ctrltype": 11,
                        "ctrlblock": perTradeOLRadio == 'control_warning' ? 0 : 1,
                        "value1": perDayOLB.replace(/,/g, ''),
                        "value2": perDayOLS.replace(/,/g, '')
                    })
                    break;


            }
        }
        actualCheckItem = actualCheckItem.split('').reverse().join('');
        let actualCheckItemInt = parseInt(actualCheckItem, 2);
        return {
            id: currentExchangeId,
            ctrl_check: actualCheckItemInt,
            e_code: location.state.row.e_code,
            trade: JSON.stringify(actualTradeItem)
        }
    }

    let navigate = useNavigate();
    const handleBackBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "exp_exc", {state: {mode: 'back'}});
        setLoading(false);
    };


    const handleLimitChecked = (event) => {
        setLimitChecked(event.target.checked);
    };


    const handleTopBtnClicked = (portion) => {
        let nav_btn = document.getElementsByClassName("nav_btn");
        for (var i = 0; i < nav_btn.length; i++) {
            nav_btn[i].classList.remove('inFormTopNavBtnSelected');
            nav_btn[i].classList.add('inFormTopNavBtn');
        }

        document.getElementById("btn_" + portion).classList.remove('inFormTopNavBtn');
        document.getElementById("btn_" + portion).classList.add('inFormTopNavBtnSelected');
        setCurrentMode(portion)

        if (portion == 'exp_limit') {
            document.getElementById("div_trade_control").classList.add('divHide');
            document.getElementById("div_trade_control_label").classList.add('divHide');
            document.getElementById("div_exposure").classList.remove('divHide');
        } else {
            document.getElementById("div_trade_control").classList.remove('divHide');
            document.getElementById("div_trade_control_label").classList.remove('divHide');
            document.getElementById("div_exposure").classList.add('divHide');
        }


    }

    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Risk Management &gt; Exposure Limit
                                - Exchange (Edit)</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Exchange</div>
                                    <div className="label col-md-10">
                                        <input
                                            autoComplete='off'

                                            className="underline-input"
                                            type="text"
                                            placeholder=""
                                            readOnly={true}
                                            aria-label=""
                                            value={currentExchange}
                                            // onChange={(e) => setCurrentExchangeCode(e.target.value)}
                                        />
                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Exchange Code</div>
                                    <div className="label col-md-10">
                                        <input
                                            autoComplete='off'

                                            className="underline-input"
                                            type="text"
                                            placeholder=""
                                            readOnly={true}
                                            aria-label=""
                                            value={currentExchangeCode}
                                            // onChange={(e) => setCurrentExchangeCode(e.target.value)}
                                        />
                                    </div>

                                </div>


                            </div>
                        </div>

                    </div>

                    <div className="card shadow mb-4">

                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="underline-input">
                                    <button id='btn_exp_limit' className="nav_btn inFormTopNavBtnSelected"
                                            onClick={() => handleTopBtnClicked('exp_limit')}
                                    >Exposure Limit
                                    </button>
                                    <button id='btn_trade_control' className="nav_btn inFormTopNavBtn"
                                            onClick={() => handleTopBtnClicked('trade_control')}
                                    >Trade Control
                                    </button>
                                </div>
                                <div id='div_exposure'>
                                    <div className="input-container col-md-12 fullWidthSearch ">

                                        <table className='am-tbl-width exp-tbl-td'>
                                            <tr className='exp-input-parent-single'>
                                                <td></td>
                                                <td></td>
                                                <td><b>Exposure Limit</b></td>
                                                <td><b>Top up (+/-)</b></td>
                                                <td><b>Top up % (+/-)</b></td>
                                                <td><b>Prev.Day Exposure</b></td>
                                                <td><b>Today Exposure</b></td>
                                                <td><b>Remaining Exposure</b></td>

                                            </tr>
                                            {/*Buy*/}
                                            <tr>
                                                <td>
                                                    <input
                                                        autoComplete='off'

                                                        className=""
                                                        type="checkbox"
                                                        placeholder=""
                                                        aria-label=""
                                                        onChange={e => setCurrentBuyLimitChecked(e.target.checked)}
                                                        checked={currentBuyLimitChecked}
                                                    />
                                                </td>
                                                <td>
                                                    <b>Buy</b>
                                                </td>
                                                <td>
                                                    <input
                                                        autoComplete='off'

                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        aria-label=""
                                                        value={currentExpLimit_buy}
                                                        onBlur={(e) => handleTopUpdateEL(currentTopup1_buy, currentExpLimit_buy, setCurrentTopup2_buy)}
                                                        onFocus={(e) => cursorToFront(e)}
                                                        onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setCurrentExpLimit_buy)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        autoComplete='off'

                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        aria-label=""
                                                        value={currentTopup1_buy}
                                                        onBlur={(e) => handleTopUpdateEL(currentTopup1_buy, currentExpLimit_buy, setCurrentTopup2_buy)}
                                                        onFocus={(e) => cursorToFront(e)}
                                                        onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setCurrentTopup1_buy, true)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        autoComplete='off'

                                                        className="underline-input"
                                                        type="text"
                                                        placeholder=""
                                                        disabled={true}
                                                        aria-label=""
                                                        value={currentTopup2_buy}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setCurrentTopup2_buy)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        autoComplete='off'

                                                        className="no-line-input"
                                                        type="text"
                                                        placeholder="" disabled={true}

                                                        aria-label=""
                                                        value={prevExp_buy}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setPrevExp_buy)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        autoComplete='off'

                                                        className="no-line-input"
                                                        type="text"
                                                        placeholder="" disabled={true}

                                                        aria-label=""
                                                        value={todayExp_buy}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setTodayExp_buy)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        autoComplete='off'

                                                        className="no-line-input"
                                                        type="text"
                                                        placeholder="" disabled={true}

                                                        aria-label=""
                                                        value={remainExp_buy}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setRemainExp_buy)}
                                                    />
                                                </td>

                                            </tr>

                                            {/*Sell*/}
                                            <td>
                                                <input
                                                    autoComplete='off'

                                                    className=""
                                                    type="checkbox"
                                                    placeholder=""
                                                    aria-label=""
                                                    onChange={e => setCurrentSellLimitChecked(e.target.checked)}
                                                    checked={currentSellLimitChecked}
                                                />
                                            </td>
                                            <td>
                                                <b>Sell</b>
                                            </td>
                                            <td>
                                                <input
                                                    autoComplete='off'

                                                    className="form-control"
                                                    type="text"
                                                    placeholder=""
                                                    aria-label=""
                                                    value={currentExpLimit_sell}
                                                    onBlur={(e) => handleTopUpdateEL(currentTopup1_sell, currentExpLimit_sell, setCurrentTopup2_sell)}
                                                    onFocus={(e) => cursorToFront(e)}
                                                    onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setCurrentExpLimit_sell)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    autoComplete='off'

                                                    className="form-control"
                                                    type="text"
                                                    placeholder=""
                                                    aria-label=""
                                                    value={currentTopup1_sell}
                                                    onBlur={(e) => handleTopUpdateEL(currentTopup1_sell, currentExpLimit_sell, setCurrentTopup2_sell)}
                                                    onFocus={(e) => cursorToFront(e)}
                                                    onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setCurrentTopup1_sell, true)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    autoComplete='off'

                                                    className="underline-input"
                                                    type="text"
                                                    placeholder=""
                                                    disabled={true}
                                                    aria-label=""
                                                    value={currentTopup2_sell}
                                                    onChange={(e) => handleDecimalNumChange(e.target.value, setCurrentTopup2_sell)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    autoComplete='off'

                                                    className="no-line-input"
                                                    type="text"
                                                    placeholder="" disabled={true}

                                                    aria-label=""
                                                    value={prevExp_sell}
                                                    onChange={(e) => handleDecimalNumChange(e.target.value, setPrevExp_sell)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    autoComplete='off'

                                                    className="no-line-input"
                                                    type="text"
                                                    placeholder="" disabled={true}

                                                    aria-label=""
                                                    value={todayExp_sell}
                                                    onChange={(e) => handleDecimalNumChange(e.target.value, setTodayExp_sell)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    autoComplete='off'

                                                    className="no-line-input"
                                                    type="text"
                                                    placeholder="" disabled={true}

                                                    aria-label=""
                                                    value={remainExp_sell}
                                                    onChange={(e) => handleDecimalNumChange(e.target.value, setRemainExp_sell)}
                                                />
                                            </td>


                                            {/*Nett*/}
                                            <tr>
                                                <td>
                                                    <input
                                                        autoComplete='off'

                                                        className=""
                                                        type="checkbox"
                                                        placeholder=""
                                                        aria-label=""
                                                        onChange={e => setCurrentNettLimitChecked(e.target.checked)}
                                                        checked={currentNettLimitChecked}
                                                    />
                                                </td>
                                                <td>
                                                    <b>Nett</b>
                                                </td>
                                                <td>
                                                    <input
                                                        autoComplete='off'

                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        aria-label=""
                                                        value={currentExpLimit_nett}
                                                        onBlur={(e) => handleTopUpdateEL(currentTopup1_nett, currentExpLimit_nett, setCurrentTopup2_nett)}
                                                        onFocus={(e) => cursorToFront(e)}
                                                        onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setCurrentExpLimit_nett)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        autoComplete='off'

                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        aria-label=""
                                                        value={currentTopup1_nett}
                                                        onBlur={(e) => handleTopUpdateEL(currentTopup1_nett, currentExpLimit_nett, setCurrentTopup2_nett)}
                                                        onFocus={(e) => cursorToFront(e)}
                                                        onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setCurrentTopup1_nett, true)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        autoComplete='off'

                                                        className="underline-input"
                                                        type="text"
                                                        placeholder=""
                                                        disabled={true}
                                                        aria-label=""
                                                        value={currentTopup2_nett}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setCurrentTopup2_nett)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="no-line-input"
                                                        type="text"
                                                        placeholder="" disabled={true}

                                                        aria-label=""
                                                        value={prevExp_nett}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setPrevExp_nett)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="no-line-input"
                                                        type="text"
                                                        placeholder="" disabled={true}

                                                        aria-label=""
                                                        value={todayExp_nett}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setTodayExp_nett)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="no-line-input"
                                                        type="text"
                                                        placeholder="" disabled={true}

                                                        aria-label=""
                                                        value={remainExp_nett}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setRemainExp_nett)}
                                                    />
                                                </td>

                                            </tr>
                                            {/*Gross*/}
                                            <tr>
                                                <td>
                                                    <input
                                                        className=""
                                                        type="checkbox"
                                                        placeholder=""
                                                        aria-label=""
                                                        onChange={e => setCurrentGrossLimitChecked(e.target.checked)}
                                                        checked={currentGrossLimitChecked}
                                                    />
                                                </td>
                                                <td>
                                                    <b>Total</b>
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        aria-label=""
                                                        value={currentExpLimit_gross}
                                                        onBlur={(e) => handleTopUpdateEL(currentTopup1_gross, currentExpLimit_gross, setCurrentTopup2_gross)}
                                                        onFocus={(e) => cursorToFront(e)}
                                                        onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setCurrentExpLimit_gross)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        aria-label=""
                                                        value={currentTopup1_gross}
                                                        onBlur={(e) => handleTopUpdateEL(currentTopup1_gross, currentExpLimit_gross, setCurrentTopup2_gross)}
                                                        onFocus={(e) => cursorToFront(e)}
                                                        onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setCurrentTopup1_gross, true)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="underline-input"
                                                        type="text"
                                                        placeholder=""
                                                        disabled={true}
                                                        aria-label=""
                                                        value={currentTopup2_gross}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setCurrentTopup2_gross)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="no-line-input"
                                                        type="text"
                                                        placeholder="" disabled={true}

                                                        aria-label=""
                                                        value={prevExp_gross}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setPrevExp_gross)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="no-line-input"
                                                        type="text"
                                                        placeholder="" disabled={true}

                                                        aria-label=""
                                                        value={todayExp_gross}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setTodayExp_gross)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="no-line-input"
                                                        type="text"
                                                        placeholder="" disabled={true}

                                                        aria-label=""
                                                        value={remainExp_gross}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setRemainExp_gross)}
                                                    />
                                                </td>

                                            </tr>
                                            {/*IDSS*/}
                                            <tr>
                                                <td>
                                                    <input
                                                        className=""
                                                        type="checkbox"
                                                        placeholder=""
                                                        aria-label=""
                                                        onChange={e => setCurrentIDSSLimitChecked(e.target.checked)}
                                                        checked={currentIDSSLimitChecked}
                                                    />
                                                </td>
                                                <td>
                                                    <b>IDSS</b>
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        aria-label=""
                                                        value={currentExpLimit_idss}
                                                        onBlur={(e) => handleTopUpdateEL(currentTopup1_idss, currentExpLimit_idss, setCurrentTopup2_idss)}
                                                        onFocus={(e) => cursorToFront(e)}
                                                        onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setCurrentExpLimit_idss)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder=""
                                                        aria-label=""
                                                        value={currentTopup1_idss}
                                                        onBlur={(e) => handleTopUpdateEL(currentTopup1_idss, currentExpLimit_idss, setCurrentTopup2_idss)}
                                                        onFocus={(e) => cursorToFront(e)}
                                                        onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setCurrentTopup1_idss, true)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="underline-input"
                                                        type="text"
                                                        placeholder=""
                                                        disabled={true}
                                                        aria-label=""
                                                        value={currentTopup2_idss}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setCurrentTopup2_idss)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="no-line-input"
                                                        type="text"
                                                        placeholder="" disabled={true}

                                                        aria-label=""
                                                        value={prevExp_idss}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setPrevExp_idss)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="no-line-input"
                                                        type="text"
                                                        placeholder="" disabled={true}

                                                        aria-label=""
                                                        value={todayExp_idss}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setTodayExp_idss)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="no-line-input"
                                                        type="text"
                                                        placeholder="" disabled={true}

                                                        aria-label=""
                                                        value={remainExp_idss}
                                                        onChange={(e) => handleDecimalNumChange(e.target.value, setRemainExp_idss)}
                                                    />
                                                </td>

                                            </tr>

                                        </table>


                                    </div>
                                    <hr/>

                                    <div className="input-container col-md-12 fullWidthSearch">

                                        <div className="label col-md-2">Limit Check</div>
                                        <div className="label col-md-11">
                                            <Toggle
                                                defaultChecked={limitChecked}
                                                checked={limitChecked}
                                                onClick={handleLimitChecked}
                                            />
                                        </div>

                                    </div>
                                    <div className="input-container col-md-12 fullWidthSearch">

                                        <div className="label col-md-2">Additional Limit</div>
                                        <div className="label col-md-3">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="e.g. 1.2"
                                                aria-label=""
                                                value={additionalLimit}
                                                onChange={(e) => handleDecimalNumChange(e.target.value, setAdditionalLimit, false)}
                                            />
                                        </div>
                                        <div className="label col-md-1">%</div>

                                    </div>
                                    <div className="input-container col-md-12 fullWidthSearch">

                                        <div className="label col-md-2">Remarks</div>
                                        <div className="label col-md-6">
                                        <textarea
                                            className="form-control exp-text-area"
                                            type="textarea"
                                            placeholder="e.g. Remarks"
                                            aria-label=""
                                            value={remark}
                                            onChange={(e) => setRemark(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value, setRemark, popupManager)}
                                        />
                                        </div>

                                    </div>
                                </div>
                                <div id='div_trade_control' className='divHide'>
                                    {/*{renderAmountLimit(amoutLimitConfig, isAmountLimitReadOnly)}*/}
                                    <AmountLimit
                                        amountLimitBuy={amountLimitBuy}
                                        setAmountLimitBuy={setAmountLimitBuy}
                                        amountLimitSell={amountLimitSell}
                                        setAmountLimitSell={setAmountLimitSell}
                                        amountLimitControl={amountLimitControl}
                                        setAmountLimitControl={setAmountLimitControl}
                                        isAmountLimitReadOnly={isAmountLimitReadOnly}
                                        popupManager={popupManager}
                                    />

                                    <QuantityLimit
                                        perTradeBLB={perTradeBLB}
                                        setPerTradeBLB={setPerTradeBLB}
                                        perTradeBLS={perTradeBLS}
                                        setPerTradeBLS={setPerTradeBLS}
                                        perTradeBLBReadOnly={perTradeBLReadOnly}
                                        perTradeDBTB={perTradeDBTB}
                                        setPerTradeDBTB={setPerTradeDBTB}
                                        perTradeDBTS={perTradeDBTS}
                                        setPerTradeDBTS={setPerTradeDBTS}
                                        perTradeDBTReadOnly={perTradeDBTReadOnly}
                                        perDayBLB={perDayBLB}
                                        setPerDayBLB={setPerDayBLB}
                                        perDayBLS={perDayBLS}
                                        setPerDayBLS={setPerDayBLS}
                                        perDayBLReadOnly={perDayBLReadOnly}
                                        perTradeBLRadio={perTradeBLRadio}
                                        setPerTradeBLRadio={setPerTradeBLRadio}
                                        perDayOddLotReadonly={perDayOddLotReadonly}
                                        perDayOLB={perDayOLB}
                                        perDayOLS={perDayOLS}
                                        setPerDayOLB={setPerDayOLB}
                                        setPerDayOLS={setPerDayOLS}
                                        perTradeOLRadio={perTradeOLRadio}
                                        setPerTradeOLRadio={setPerTradeOLRadio}
                                        perTradeOLRadioReadonly={false}
                                        perTradeOLB={perTradeOLB}
                                        perTradeOLS={perTradeOLS}
                                        setPerTradeOLB={setPerTradeOLB}
                                        setPerTradeOLS={setPerTradeOLS}
                                        perTradeOLReadOnly={perTradeOLReadOnly}
                                        popupManager={popupManager}
                                    />

                                    <BidLimit
                                        bLBuyBL={bLBuyBL}
                                        setBLBuyBL={setBLBuyBL}
                                        bLSellBL={bLSellBL}
                                        setBLSellBL={setBLSellBL}
                                        bLBuyBLRadio={bLBuyBLRadio}
                                        setBLBuyBLBLRadio={setBLBuyBLBLRadio}
                                        bLBuyBLReadOnly={bLBuyBLReadOnly}
                                        bLBuyOL={bLBuyOL}
                                        setBLBuyOL={setBLBuyOL}
                                        bLSellOL={bLSellOL}
                                        setBLSellOL={setBLSellOL}
                                        bLSellBLReadOnly={bLSellBLReadOnly}
                                        setBLSellBLReadOnly={setBLSellBLReadOnly}
                                        bLBuyBOL={bLBuyBOL}
                                        setBLBuyBOL={setBLBuyBOL}
                                        bLSellBOL={bLSellBOL}
                                        setBLSellBOL={setBLSellBOL}
                                        bLSellBOLReadOnly={bLSellBOLReadOnly}
                                        popupManager={popupManager}
                                    />
                                    <UpperLowerLimit
                                        uLLBLUp={uLLBLUp}
                                        setULLBLUp={setULLBLUp}
                                        uLLBLDown={uLLBLDown}
                                        setULLBLDown={setULLBLDown}
                                        uLLBLReadOnly={uLLBLReadOnly}
                                        uLLDBTUp={uLLDBTUp}
                                        setULLDBTUp={setULLDBTUp}
                                        uLLDBTDown={uLLDBTDown}
                                        setULLDBTDown={setULLDBTDown}
                                        uLLDBTDownReadOnly={uLLDBTDownReadOnly}
                                        popupManager={popupManager}
                                    />
                                    <TradeType
                                        IDSSChecked={IDSSChecked}
                                        setIDSSChecked={setIDSSChecked}
                                        PDTChecked={PDTChecked}
                                        setPDTChecked={setPDTChecked}
                                        PSSChecked={PSSChecked}
                                        setPSSChecked={setPSSChecked}
                                        RSSChecked={RSSChecked}
                                        setRSSChecked={setRSSChecked}
                                        IDSSReadOnly={IDSSReadOnly}
                                        PDTReadOnly={PDTReadOnly}
                                        PSSeadOnly={PSSeadOnly}
                                        RSSeadOnly={RSSeadOnly}
                                        popupManager={popupManager}
                                    />

                                    <Others
                                        othersBuySusChecked={othersBuySusChecked}
                                        setOthersBuySusChecked={setOthersBuySusChecked}
                                        othersSellSusChecked={othersSellSusChecked}
                                        setOthersSellSusChecked={setOthersSellSusChecked}
                                        othersShareChSusChecked={othersShareChSusChecked}
                                        setOthersShareChSusChecked={setOthersShareChSusChecked}
                                        othersTDaySusChecked={othersTDaySusChecked}
                                        setOthersTDaySusChecked={setOthersTDaySusChecked}
                                        othersBuySusReadOnly={othersBuySusReadOnly}
                                        othersSellSusReadOnly={othersSellSusReadOnly}
                                        othersShareChSusReadOnly={othersShareChSusReadOnly}
                                        othersTDayChSusReadOnly={othersTDayChSusReadOnly}
                                        popupManager={popupManager}


                                    />
                                </div>


                            </div>
                        </div>

                    </div>


                    <div className="input-container col-md-12 fullWidthSearch">

                        <div className="label col-md-1"></div>

                        <div className="label col-md-11 btn_search_padding">
                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>
                            {accessItem['edit'] ?
                                <>
                                    <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset</button>
                                    <button className="btn btn-primary " onClick={() => handleSaveBtn()}>Save</button>
                                </>
                                : ""}

                        </div>


                    </div>
                    <div id='div_trade_control_label' className='divHide'>
                        Note : Zero Value or empty for quantity, amount and percentage denote no checking.
                    </div>
                </div>


            </div>
        </div>
    );
};

export default Index;
