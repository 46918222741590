import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    accessStatusOptions,
    broadcastStatusOption,
    broadcastTypeOptions, convertErMsg,
    convertFuncPerm, dateFormats, dateTimeFormat,
    getDefaultAccessMat,
    getNavIdByPath,
    handlePopup,
    handleSelectChange, momentTimeFormat,
    numProcessor, performAntdTimeCheck, specialCharCheck,
    useLocalStorage,
    userTypeCategory
} from "../../../general/general-tools";
import {confirmAlert} from "react-confirm-alert";
import Multiselect from "multiselect-react-dropdown";
import ModalConfirm from "../../public/ModalConfirm";
import { usePopupManager } from "react-popup-manager";
import {data} from "browserslist";
import ReactTooltip from "react-tooltip-rc";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";
import ReactDatepick from "../../../general/form-control/react-datepick";
import dayjs from "dayjs";


const Index = () => {
    const {setLoading} = useLoading();

    const [tableData, setTableData] = useLocalStorage("brd_ApptblData", []);
    const [totalRecords, setTotalRecords] = useLocalStorage("brd_ApptlRecord", 0);
    const [currentPage, setCurrentPage] = useLocalStorage("brd_AppcurPage", 1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage("brd_AppcurItemQty", 10);
    const [selectedRows, setSelectedRows] = useLocalStorage("brd_AppselRow", []);

    const [brTitle, setBrTitle] = useLocalStorage("brd_app_title","");
    const [brCreateDate, setBrCreateDate] = useLocalStorage("brd_app_createDate","");
    const [brType, setBrType] = useLocalStorage("brd_app_type","");
    const [brPublishDate, setBrPublishDate] = useLocalStorage("brd_app_pub_date","");
    const [brDocumentId, setBrDocumentId] = useLocalStorage("brd_app_doc_id","");
    const [brPublishEnd, setBrPublishEnd] = useLocalStorage("brd_app_pub_end","");

    const [brExchange, setBrExchange] = useLocalStorage("brd_app_exchange",[]);
    const [exchangeList, setExchangeList] = useState([]);
    const [brApprovalDate, setBrApprovalDate] = useLocalStorage("brd_app_approve_date","");
    const [brSymbol, setBrSymbol] =useLocalStorage("brd_app_symbol",[]);
    const [brSymbolList, setBrSymbolList] = useState([]);
    const [brApprover, setBrApprover] =useLocalStorage("brd_app_approver","");
    const [brStatus, setBrStatus] = useLocalStorage("brd_app_br_status","");
    const [brUserType, setBrUserType] =useLocalStorage("brd_app_user_type",[]);
    const [brUserTypeList, setBrUserTypeList] = useState([]);
    const [searchInput, setSearchInput] = useState([]);
    const [toggleReset, setToggleReset] = useState(false);
    const isInitialMount = useRef(true);

    const location = useLocation();
    let navigate = useNavigate();
    const popupManager = usePopupManager();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat():getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);
    const [isDateValid, setIsDateValid] = useState(false);
    useEffect(() => {
        performAntdTimeCheck(brPublishDate,brPublishEnd,setBrPublishEnd,popupManager,setIsDateValid);

    }, [brPublishDate,brPublishEnd]);


    useLayoutEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        handleExchangeList();
        handleUserTypeList();
        if(tableData.length > 0){
            handleInitList()
        }else{
            handleResetBtn();
        }
    },[])

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleInitList();
        }
    }, [currentPage, currentPerPage]);

    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {
        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }
    useEnterKeyPress(handleEnterKeyPress);
    const handleInitList = async () => {


        if(tableData.length > 0) {
            handleMainList()
        }else{
            handleResetBtn();
        }


    };

    const handleMainList = async (newSearch = false) => {

        setLoading(true);

        let publishDate = dayjs(brPublishDate).format(momentTimeFormat()) ;
        let createDate = dayjs(brCreateDate).format(momentTimeFormat());
        let publicdateTo = dayjs(brPublishEnd).format(momentTimeFormat());
        let approveDate = dayjs(brApprovalDate).format(momentTimeFormat());

        if (publishDate != "Invalid Date" && publicdateTo != "Invalid Date"){
        if (!isDateValid) {
            handlePopup(true, "Error", "Please ensure that the ending date is not earlier than the starting date.", popupManager);
            await setLoading(false);
            return false;
        }}

        let curPage = currentPage;
        if (newSearch) {
            setCurrentPage(1)
            curPage= 1;
        }
        let searchParams = {};

        let searchTermExg = "";
        for (let i = 0; i < brExchange.length; i++) {
            searchTermExg += brExchange[i]['id']+","
        }
        searchTermExg= searchTermExg.substring(0,searchTermExg.length-1)

        let searchTermStr = "";
        for (let i = 0; i < brSymbol.length; i++) {
            searchTermStr += brSymbol[i]['id']+","
        }
        searchTermStr= searchTermStr.substring(0,searchTermStr.length-1)

        let searchTermUt = "";

        for (let i = 0; i < brUserType.length; i++) {
            searchTermUt += brUserType[i]['id']+","
        }
        searchTermUt= searchTermUt.substring(0,searchTermUt.length-1)


        searchParams['term'] = brTitle;
        searchParams['tterm'] = brType;
        searchParams['docid'] = brDocumentId;
        searchParams['exchange'] = searchTermExg;
        searchParams['ticker'] = searchTermStr;
        searchParams['usertype'] = searchTermUt;
        searchParams['status'] = brStatus;
        searchParams['publicdatefrom'] = publishDate == "Invalid Date" ? null : publishDate;
        searchParams['createdate'] = createDate== "Invalid Date" ? null : createDate;
        searchParams['publicdateto'] = publicdateTo== "Invalid Date" ? null : publicdateTo;
        searchParams['approvaldate'] = approveDate== "Invalid Date" ? null : approveDate;
        searchParams['approvalby'] = brApprover;

        api.getBroadcastList(currentPerPage, curPage,searchParams ).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if(result.data){
                if (result.data.list) {
                    let tblDat = result.data.list;
                    let tblList = [];
                    let totalPage = parseInt(result.data.totalrow)
                    setTotalRecords(totalPage);
                    for (let i = 0; i < tblDat.length; i++) {
                        let dataEle = tblDat[i];
                        let tempDat = dataEle;
                        let exchangeList = [];
                        let userTypeList = [];
                        if(dataEle['tickers'] != "" && dataEle['tickers'])exchangeList = JSON.parse(dataEle['tickers'])
                        if(dataEle['usertypes'] != "" && dataEle['usertypes'] )userTypeList = JSON.parse(dataEle['usertypes'])

                        for (let j = 0; j < exchangeList.length; j++) {
                            let exgEle = exchangeList[j];
                            if(!tempDat['excList']) tempDat['excList'] = exgEle['t_code'] + " | " +exgEle['t_name'] +"\n"
                            else tempDat['excList'] += exgEle['t_code'] + " | " +exgEle['t_name'] +"\n"
                        }

                        for (let k = 0; k < userTypeList.length; k++) {
                            let userType = userTypeList[k];

                            if(k < 2){
                                if(!tempDat['userTypeF2']) tempDat['userTypeF2'] = "‣ " + userType['ut_name'] +"\n"
                                else tempDat['userTypeF2'] += "‣ " + userType['ut_name'] +"\n"
                            }else {
                                if(!tempDat['userType']) tempDat['userType'] = "‣ " +userType['ut_name'] +"<br/>"
                                else tempDat['userType'] += "‣ " +userType['ut_name'] +"<br/>"
                            }

                        }

                        if(dataEle['file_content_path']){
                            let fileItem = dataEle['file_content_path'];
                            tempDat['file_content_path_parsed']= "/file/"+fileItem;
                            let name = fileItem.split("/");
                            tempDat['file_content_name_parsed']= (name[name.length-1])
                        }

                        tblList.push(tempDat);

                    }
                    setTableData(tblList)

                }
            }else{
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
            }
            setLoading(false);

        });
    };

    const handleExchangeList = async () => {

        setLoading(true);
        api.getExchangeMainList(999, 1, 1, null).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdown(dataList);
                    setExchangeList(TreeData);
                }
            }

        });
        setLoading(false);
    };

    const handleUserTypeList = async () => {
        setLoading(true);
        let param = {
            size: 999,
            page: 1,
            is_opr: false
        };
        api.getAuthLimitListUserType(param).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdownUt(dataList);
                    setBrUserTypeList(TreeData);
                }
            }
        });
        setLoading(false);
    };

    const handleTickerList = async (searchTerm,searchSymbols) => {
        let searchTermStr = "";
        for (let i = 0; i < searchTerm.length; i++) {
            searchTermStr += searchTerm[i]['id']+","
        }
        searchTermStr= searchTermStr.substring(0,searchTermStr.length-1)
        setLoading(true);
        api.getBroadcastTicker(100, 1,null, searchSymbols == ""? null : searchSymbols,searchTermStr).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if (result.status == 0 && result.data) {
                if( result.data.list.length > 0){
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdownTicker(dataList);
                    setBrSymbolList(TreeData);

                }

            }
        });
        setLoading(false);
    };

    const onExchangeHandle = (selectedList, selectedItem) => {
        setBrExchange(selectedList);
        handleTickerList(selectedList,"");

        if(selectedList.length == 0) setBrSymbolList([]);
    }

    const onUserTypeHandle = (selectedList, selectedItem) => {
        setBrUserType(selectedList);
    }

    const onSymbolHandle = (selectedList, selectedItem) => {
        setBrSymbol(selectedList);
    }

    const onHandleSymbolSearch= (searchItem) =>{
        if(searchItem.length > 2) handleTickerList(brExchange,searchItem);
        else if (searchItem.length == 0)handleTickerList(brExchange,"");
    }

    const convertRawIntoDropdown = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                name: data[i]['em_code'] ,
                id: data[i]['em_id']
            });
        }
        return returnDat;
    }

    const convertRawIntoDropdownUt = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                name: data[i]['ut_name'],
                id: data[i]['ut_id']
            });
        }
        return returnDat;
    }

    const convertRawIntoDropdownTicker = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                exchange: data[i]['em_code'],
                symbol: data[i]['t_code'],
                stockname: data[i]['t_name'],
                id: data[i]['t_id']
            });
        }
        return returnDat;
    }


    useLayoutEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, []);

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleRemove = async () => {
        await setLoading(true);
        popupManager.open(ModalConfirm, {
            title: 'Approve',
            content: 'Are you sure you want to delete selected broadcast?',
            onClose: (...params) => handleRemoveItem(params[0])
        });

        setLoading(false);
    };
    const handleRemoveItem = async (selBoolean) => {
        if (!selBoolean) return;
        await setLoading(true);

        let deleteTermStr = "";
        let selectedItem = selectedRows['selectedRows'];
        for (let i = 0; i < selectedItem.length ; i++) {
            deleteTermStr += selectedItem[i]['b_id'] + ","
        }
        deleteTermStr = deleteTermStr.substring(0, deleteTermStr.length - 1)

        let param = {id : deleteTermStr};
        api.deleteBroadcast(param).then((result: any) => {
            if (result != false && result.status == 0) {
                handlePopup(true, "Success", "Broadcast deleted successfully.", popupManager)
                setLoading(false);
            }else{
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
            }
        });


    };

    const handlenewTab = async (url) => {
        await setLoading(true);
        if(url == "#") {
            await setLoading(false);
            return false;
        }
        window.open(url, '_blank', 'noopener,noreferrer');
        setLoading(false);
    };
    const handleOnclick = async (row) => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "br_app_opr", {state: {row: row, perm:actualPerm, mode: 'Edit'}});
        setLoading(false);
    };
    const handleAddBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "br_app_opr", {state: {row: "", perm:actualPerm, mode: 'Add'}});
        setLoading(false);
    };
    const handleResetBtn = async () => {
        await setLoading(true);
        setBrSymbolList([]);
        setBrTitle("");
        setBrType("");
        setBrDocumentId("");
        setBrApprover("");
        setBrStatus("");

        // setBrCreateDate("");
        // setBrPublishDate("");
        // setBrPublishEnd("");
        // setBrApprovalDate("");
        setToggleReset(!toggleReset);


        setBrExchange([]);
        setBrSymbol([]);
        setBrUserType([]);
        setLoading(false);
    };
    // Document ID, Creation Date, Publish Start, Publish End, Approval Date, Type, Symbol, User Type, Created By, Approved By
    const columns = useMemo(
        () => [
            {
                id: row => row.b_doc_id,
                name: <div>Document ID</div>,
                selector: row => row.b_doc_id,
                sortable: true,
                // center: true,
                cell: (row: { b_doc_id: any }) => (
                    <>
                        <a className="tableItemsHlink" onClick={() => handleOnclick(row)}>{row.b_doc_id}</a>
                    </>
                ),
            },
            {
                name: <div>Creation Date</div>,
                selector: row => (dayjs(row.b_create_date).format(dateFormats()) == 'Invalid Date' ? "-" :  dayjs(row.b_create_date).format(dateFormats())),
                sortable: true,
                wrap : true
            },
            {
                name: <div>Publish Start</div>,
                selector: row => (dayjs(row.b_publish_date_start).format(dateTimeFormat()) == 'Invalid Date' ? "-" :  dayjs(row.b_publish_date_start).format(dateFormats())),
                sortable: true,
                wrap : true
            },
            {
                name: <div>Publish End</div>,
                selector: row => (dayjs(row.b_publish_date_end).format(dateTimeFormat()) == 'Invalid Date' ? "-" :  dayjs(row.b_publish_date_end).format(dateFormats())),
                sortable: true,
                wrap : true
            },
            {
                name: <div>Approve Date</div>,
                selector: row => (dayjs(row.b_approved_date).format(dateTimeFormat()) == 'Invalid Date' ? "-" :  dayjs(row.b_approved_date).format(dateFormats())),
                sortable: true,
                wrap : true
            },
            {
                name: 'Type',
                selector: row => (row.b_type == "1" ? "Research" : row.b_type == "2" ? "Announcement" : row.b_type== "3" ? "Event" : row.b_type == "4" ? "TnC": ""),
                sortable: true
            },
            {
                name: 'Symbol',
                selector: row => row.excList,
                cell: (row: { excList: any }) => (
                    <>
                        <div style={{"white-space":"pre"}}>{row.excList}</div>
                    </>
                ),
                style: {overflow: "visible"},
                allowOverflow: false,
                sortable: true
            },
            {
                name: "User Type",
                cell: (row: { userType: any }) => (
                    <>
                        <ReactTooltip data-class="my-tooltip" multiline={true} place={'bottom'}/>
                        <div style={{"white-space":"break-spaces"}}>
                            {row.userTypeF2}
                            <div style={{"white-space":"pre"}}>
                                {row.userType ? <div style={{"color":"blue",'font-size':'0.8em'}} className={"linkCursor"} aria-hidden="true" data-class="my-tooltip"data-tip={row.userType}><b>More</b></div> : ''}

                            </div>
                        </div>


                    </>
                ),
                sortable: true,
                wrap: false
            },
            {
                name: <div>Created By</div>,
                selector: row => row.b_create_by,
                sortable: true,
                wrap : true
            },
            {
                name: <div>Approved By</div>,
                selector: row => row.b_approved_by ?? "-",
                sortable: true,
                wrap : true
            },
            {
                name: <div>Status</div>,
                selector: row => (row.b_status == 0 ? "Pending Approval" : row.b_status == 1 ? "Approved" : row.b_status == 2 ? "Rejected":row.b_status == 3 ? "Published" : row.b_status == 4 ? "Rejected" : "-"),
                sortable: true,
                wrap : true
            }
            // {
            //     name: 'File',
            //     selector: row => row.file_content_path_parsed ?? "-",
            //     cell: (row: { file_content_path_parsed: any }) => (
            //         <>
            //             <a className="tableItemsHlink" onClick={() => handlenewTab(row.file_content_path_parsed ?? "#")} target="_blank">[View]</a>
            //         </>
            //     ),
            //     sortable: true
            // }
        ],
        []
    );


    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration  &gt; Broadcast Maintenance &gt; Approve - Search</h5>
                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Title</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control "
                                            type="text"
                                            placeholder="e.g. On The Spot Event "
                                            value={brTitle}
                                            onChange={(e) => setBrTitle(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setBrTitle,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-1">Creation Date</div>
                                    <div className="label col-md-5">

                                        <ReactDatepick
                                            value={brCreateDate}
                                            dateSetter={setBrCreateDate}
                                            resetToggle={toggleReset}

                                        />
                                    </div>
                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Type</div>
                                    <div className="label col-md-5">
                                        <select
                                            className="form-control"
                                            value={brType}
                                            onChange={(e) => handleSelectChange(e.target.value, setBrType, false)}
                                        >
                                            {broadcastTypeOptions()}
                                        </select>
                                    </div>
                                    <div className="label col-md-1">Publish Date</div>
                                    <div className="label col-md-5">

                                        <ReactDatepick
                                            value={brPublishDate}
                                            dateSetter={setBrPublishDate}
                                            resetToggle={toggleReset}

                                        />

                                    </div>
                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Document ID</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control "
                                            type="text"
                                            placeholder="e.g. 2022080010"
                                            value={brDocumentId}
                                            onBlur={(e) => specialCharCheck(e.target.value,setBrDocumentId,popupManager)}
                                            onChange={(e) => setBrDocumentId(e.target.value)}
                                        />


                                    </div>
                                    <div className="label col-md-1">Publish End</div>
                                    <div className="label col-md-5">

                                        <ReactDatepick
                                            value={brPublishEnd}
                                            dateSetter={setBrPublishEnd}
                                            resetToggle={toggleReset}

                                        />
                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Exchange</div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={exchangeList} // Options to display in the dropdown
                                            selectedValues={brExchange} // Preselected value to persist in dropdown
                                            onSelect={onExchangeHandle} // Function will trigger on select event
                                            onRemove={onExchangeHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            placeholder="Exchange"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="label col-md-1">Approval Date</div>
                                    <div className="label col-md-5">
                                        <ReactDatepick
                                            value={brApprovalDate}
                                            dateSetter={setBrApprovalDate}
                                            resetToggle={toggleReset}

                                        />
                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Symbol</div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={brSymbolList} // Options to display in the dropdown
                                            selectedValues={brSymbol} // Preselected value to persist in dropdown
                                            selectedValues={brSymbol}
                                            onSelect={onSymbolHandle} // Function will trigger on select event
                                            onRemove={onSymbolHandle} // Function will trigger on remove event
                                            displayValue="stockname" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            onSearch={onHandleSymbolSearch}
                                            placeholder="Symbol"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="label col-md-1">Approved By</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control "
                                            type="text"
                                            placeholder="e.g. Lee Chin Poh "
                                            value={brApprover}
                                            onChange={(e) => setBrApprover(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setBrApprover,popupManager)}

                                        />
                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Status</div>
                                    <div className="label col-md-5">
                                        <select
                                            className="form-control"
                                            value={brStatus}
                                            onChange={(e) => handleSelectChange(e.target.value, setBrStatus, false)}
                                        >
                                            {broadcastStatusOption()}
                                        </select>
                                    </div>
                                    <div className="label col-md-1">User Type</div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={brUserTypeList} // Options to display in the dropdown
                                            selectedValues={brUserType} // Preselected value to persist in dropdown
                                            // selectedValues={holidayExchange}
                                            onSelect={onUserTypeHandle} // Function will trigger on select event
                                            onRemove={onUserTypeHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            placeholder="User Type"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary " id="btn-search-main"  onClick={() => handleMainList(true)}>Search
                                        </button>
                                        <button className="btn btn-primary " id="btn-search-main"  onClick={() => handleResetBtn()}>Reset
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    perPageQty={setCurrentPerPage}
                                    handleSelect={setSelectedRows}
                                    columns={columns}
                                    data={tableData}
                                    pagination={{
                                        currentPage: currentPage,
                                        totalRecords: totalRecords,
                                        currentPerPage: currentPerPage,
                                        selectable: false,
                                        selectableHandle: {setSelectedRows},
                                    }}
                                />



                            </div>

                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
