import icHome from '../asset/images/nav-menu-icon/home.svg';
import icTradingPlatform from '../asset/images/nav-menu-icon/trading_platform.svg';
import icUser from '../asset/images/nav-menu-icon/user.svg';
import icTrade from '../asset/images/nav-menu-icon/my_trade.svg';
import icPortfolio from '../asset/images/nav-menu-icon/portfolio.svg';
import icFunding from '../asset/images/nav-menu-icon/funding.svg';
import icEstatement from '../asset/images/nav-menu-icon/estatement.svg';
import icEforms from '../asset/images/nav-menu-icon/eforms.svg';
import icCorporate_actions from '../asset/images/nav-menu-icon/corporate_actions.svg';
import icSupport from '../asset/images/nav-menu-icon/support.svg';
import icAnnouncement from '../asset/images/nav-menu-icon/announcement.svg';
import icResearch from '../asset/images/nav-menu-icon/research.svg';
import {getFunctionMenus} from "../pages/protected/UserAccessMat/UserAccessMatTools";

interface NavOptions {
    url?: string;
    label: string;
    icon: any;
    selected?: boolean;
    subMenu?: Array<SubMenu>;
}

interface SubMenu {
    url: string;
    label: string;
    selected?: boolean;
    subMenu2?: Array<SubMenu2>;
}

interface SubMenu2 {
    url: string;
    label: string;
    selected?: boolean;
}

const sidebarSource = getFunctionMenus();


export default sidebarSource;
