import {handleRequestGet, handleRequestPost} from '../axios';

export const getDealerGroupList = async (size, page, status, term,date_fr,date_to) => {
    let url = '/dealergroup';

    let param = {
        status: status == '' ? null : status ,
        date_fr: date_fr == "Invalid Date" ? null : date_fr,
        date_to: date_to == "Invalid Date" ? null : date_to,
        size: size,
        page: page
    };

    if(term != "") param['term'] = term;
    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getDealerGroupListDealerAssoc = async (param) => {
    let url = '/dealergroup';



    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const addDealerGroup = async (data) => {
    let url = 'dealergroup/create';

    let result = await handleRequestPost(url, {
        "name" : data['name'],
        "status" : data['status'],
        "date_fr" :  data['date_fr'],
        "date_to" : data['date_to']
    });

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateDealerGroup = async (data) => {
    let url = '/dealergroup/update';

    let result = await handleRequestPost(url, {
        "id" : data['id'],
        "name" : data['name'],
        "status" : data['status'],
        "date_fr" :  data['date_fr'],
        "date_to" : data['date_to']
    });

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const deleteDealerGroup = async (id) => {
    let url = '/dealergroup/delete';
    let result = await handleRequestPost(url, {
        id: id
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getDealerAsscItem = async (data) => {
    let url = '/dealergrpassoc';

    let result = await handleRequestGet(url, data);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateDealerGroupAssc = async (data) => {
    let url = '/dealergrpassoc/update';

    let result = await handleRequestPost(url, data);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

