import React, {useState, useEffect, useMemo} from 'react';

import api from '../../../../apis';
import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';

import DropdownSelect from '../../../general/form-control/dropdown-select';
import ReactTable from '../../../general/form-control/react-table';
import statusText from '../../../../constants/statusText';
import TreeSelect from '../../../general/form-control/tree-select';
import {parseAPIResutlToData} from '../../../general/form-control/tree-select/helper';
import DataTable from 'react-data-table-component';
import {useLocation, useNavigate} from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {convertErMsg, handlePopup, specialCharCheck} from "../../../general/general-tools";
import {usePopupManager} from "react-popup-manager";
import ModalConfirm from "../../public/ModalConfirm";

const Index = () => {
    const {setLoading} = useLoading();


    const [currentMode, setCurrentMode] = useState("");
    const [currentExchangeId, setCurrentExchangeId] = useState("");
    const [currentExchangeCode, setCurrentExchangeCode] = useState("");
    const [currentExchangeName, setCurrentExchangeName] = useState("");
    const [currentExchangeCountry, setCurrentExchangeCountry] = useState("");
    const [currentSource, setCurrentSource] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [exchangeList, setExchangeList] = useState([]);

    const [readOnlyBranchCode, setReadOnlyBranchCode] = useState(false);
    const [accessItem, setAccessItem] = useState({});

    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();

    //Load initial data and mode
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        if (location.state) {
            handleCountryList();
            // handleExchangeList();

            if (location.state.row) {
                setAccessItem(location.state.perm);
                if (!location.state.perm['edit']) {
                    setTimeout(function () {
                        disableAllEleInDiv("mainDiv");
                    }, 50);
                }
                setCurrentExchangeId(location.state.row.em_id);
                setCurrentExchangeCode(location.state.row.em_code);
                setCurrentExchangeName(location.state.row.em_name);
                setCurrentExchangeCountry(location.state.row.em_cid);
                setCurrentSource(location.state.row.em_eid);
            }
            setCurrentMode(location.state.mode)
        }
    }, [location.state]);

    const handleCountryList = async () => {
        api.getCountryList(999, 1, 1, null).then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }

            if (result.data) {
                if (result.data.list.length > 0) {
                    setCountryList(result.data.list);
                }
            }
        });
    };

    const handleExchangeList = async () => {
        api.getExchangeList(999, 1, 1, null).then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }

            if (result.data) {
                if (result.data.list.length > 0) {
                    setExchangeList(result.data.list);
                }
            }
        });
    };

    // Edit
    const handleBackBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "exchange", {state: {mode: 'back'}});
        setLoading(false);
    };
    const handleRemoveBtn = async () => {
        await setLoading(true);
        popupManager.open(ModalConfirm, {
            title: 'Delete',
            content: 'Are you sure you want to delete?',
            onClose: (...params) => handleRemoveItem(params[0])
        });

        setLoading(false);
    };

    const handleRemoveItem = async (selBoolean) => {
        if (!selBoolean) return;
        await setLoading(true);
        api.deleteExchange(currentExchangeId).then((result: any) => {
            if (result != false && result.status == 0) {
                setLoading(false);
                handlePopup(true, "Success", "Exchange deleted successfully.", popupManager, true, "exchange", navigate)
            } else{
                setLoading(false);
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
            }
        });


        setLoading(false);
    };


    const handleSaveBtn = async () => {
        await setLoading(true);

        if (!currentExchangeCode) {
            handlePopup(true, "Error", "Mandatory Field - Exchange Code not entered.", popupManager)
            setLoading(false);
            return false;
        }


        if (isNaN(parseInt(currentExchangeCountry)) || currentExchangeCountry == '') {
            handlePopup(true, "Error", "Mandatory Field - Country not selected.", popupManager)
            setLoading(false);
            return false;
        }

        let data = {
            "id": currentExchangeId,
            "code": currentExchangeCode,
            "name": currentExchangeName,
            "country": currentExchangeCountry,
            // "exchange" : currentSource
        };

        api.updateExchange(data).then((result: any) => {
            if (result != false && result.status == 0) {
                handlePopup(true, "Success", "Exchange information updated successfully.", popupManager, true, "exchange", navigate)
            } else{
                setLoading(false);
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
            }
        });
        setLoading(false);
    };


    const handleResetBtn = async () => {
        await setLoading(true);
        setCurrentExchangeCode("");
        setCurrentExchangeName("");
        setCurrentExchangeCountry("");

        setLoading(false);
    };

    const handleAddBtn = async () => {
        await setLoading(true);

        if (!currentExchangeCode) {
            handlePopup(true, "Error", "Mandatory Field - Exchange Code not entered.", popupManager)
            setLoading(false);
            return false;
        }


        if (isNaN(parseInt(currentExchangeCountry)) || currentExchangeCountry == '') {
            handlePopup(true, "Error", "Mandatory Field - Country not selected.", popupManager)
            setLoading(false);
            return false;
        }

        if (
            currentExchangeCode.trim().length > 0 &&
            currentExchangeCountry != ''
        ) {
            let data = {
                "code": currentExchangeCode,
                "name": currentExchangeName,
                "country": currentExchangeCountry,
                // "exchange" : currentSource
            };

            api.addExchange(data).then((result: any) => {
                if (result != false && result.status == 0) {
                    setLoading(false);
                    handlePopup(true, "Success", "Exchange created successfully.", popupManager, true, "exchange", navigate)
                }  else{
                    setLoading(false);
                    handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                }
            });
        }
        setLoading(false);
    };


    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary ">Administration &gt; Master File
                                Maintenance &gt; Exchange - {currentMode}</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Exchange Code</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            id="exchange_code"
                                            type="text"
                                            placeholder="e.g. HKEX"
                                            aria-label=""
                                            value={currentExchangeCode}
                                            onChange={(e) => setCurrentExchangeCode(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentExchangeCode,popupManager)}

                                        />
                                    </div>
                                    <b className='mandatory-red'>*</b>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Exchange Name</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            id="exchange_code"
                                            type="text"
                                            placeholder="e.g. Hong Kong Exchange"
                                            aria-label=""
                                            value={currentExchangeName}
                                            onChange={(e) => setCurrentExchangeName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentExchangeName,popupManager)}

                                        />

                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Country</div>
                                    <div className="label col-md-10">

                                        <select
                                            className="form-control"
                                            id="exchange_country"
                                            aria-label=""
                                            value={currentExchangeCountry}
                                            onChange={(e) => setCurrentExchangeCountry(e.target.value)}
                                        >
                                            <option>Please select Country</option>
                                            {countryList.map(el => <option
                                                value={el.c_id}>{el.c_name}</option>)}
                                        </select>
                                    </div>
                                    <b className='mandatory-red'>*</b>

                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1"></div>

                                    {currentMode.toLowerCase() == 'edit' ?
                                        <div className="label col-md-10 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                            </button>
                                            {accessItem['delete'] ? <button className="btn btn-primary "
                                                                            onClick={() => handleRemoveBtn()}>Delete</button> : ""}
                                            {accessItem['edit'] ? <button className="btn btn-primary "
                                                                          onClick={() => handleSaveBtn()}>Save</button> : ""}

                                        </div> :

                                        <div className="label col-md-11 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                            </button>
                                            <button className="btn btn-primary "
                                                    onClick={() => handleResetBtn()}>Reset
                                            </button>
                                            <button className="btn btn-primary " onClick={() => handleAddBtn()}>Save
                                            </button>
                                        </div>
                                    }


                                </div>


                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
