import Crypto from 'crypto';
import Config from '../config';

export const generateEcdhKeys = () => {
    // Generate an ECDH object for geekA
    const ECDH = Crypto.createECDH('secp521r1');
    const genKey = ECDH.generateKeys('base64');

    let publicKey = ECDH.getPublicKey('base64');

    let sharedKey = ECDH.computeSecret(
        Config.Crypto.ECDH.PublicKeyB,
        'base64',
        'base64'
    );

    // console.log('genKeyA');
    // console.log(genKey);
    // console.log('sharedKey');
    // console.log(sharedKey);

    // console.log('publicKey');
    // console.log(publicKey);

    return { sharedKey, publicKey };
};
