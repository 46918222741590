import axios from 'axios';
import {
    gcmEncrypt,
    gcmDecrypt,
    generateBearerTokenKey
} from '../crypto/msgCrypto';
import { generateEcdhKeys } from '../crypto/ecdhCrypto';
import Config from '../config';
import { getUserDetail, setLogout } from '../hooks/handleCookie';

const axiosInstance = axios.create({
    baseURL: Config.baseURL,
    headers: {
        'Content-Type': 'application/octet-stream'
        // Authorization: `Bearer asdasd`
    }
});

axiosInstance.interceptors.request.use(
    function (config) {
        let method = config.method;
        let userDetail = getUserDetail();

        //AES encryption with ECDH sharedKey
        //encrypt body data
        //generate ECDH Key
        let { sharedKey, publicKey } = generateEcdhKeys();
        config.ECDHPublicKey = publicKey;
        config.ECDHSharedKey = sharedKey;

        if (method == 'get') {
            //get
            let encryptedBody = gcmEncrypt('{}', sharedKey);
            config.data = encryptedBody;
        } else {
            //post
            let encryptedBody = gcmEncrypt(config.data, sharedKey);
            config.data = encryptedBody;
        }

        if (userDetail && userDetail.login_token) {
            let token = userDetail.login_token;
            //protected api
            config.headers['Authorization'] = `Bearer ${token}`;
        } else {
            //public api
            config.headers['Ecdh-Public-Key'] = publicKey;
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        if (response.status === 200 && response.data) {
            return response; //fetch data to display
        } else {
            alert(response.status);
            alert('Warning: Fail to get data. ' + response.data.message);
        }
    },
    (error) => {
        console.log(error);
        if (error.response.status === 401) {
            // alert(error.response.status);
            //logout user
            setLogout();
            window.location.href = './';
        }
    }
);

const handleRequestGetFile = async (url, param) => {
    //stringify param before going to next step
    let stringifyBody = JSON.stringify(param);
    return axiosInstance
        .get(url, {
            responseType: 'arraybuffer'
        })
        .then(async function (response) {
            if (response) {
                let status = response.status;
                if (status == 200) {
                    let data = response.data;
                    return data;
                    // let userDetail = getUserDetail();
                    // if (userDetail && userDetail.token) {
                    //     let token = userDetail.token;
                    //     let key = await generateBearerTokenKey(token);
                    //     let result = await gcmDecrypt(data, key);
                    //     // console.log(key);
                    //     // console.log(result);
                    //     return result;
                    // } else {
                    //     const { config } = response;
                    //     //get sharedkey from config
                    //     let ECDHSharedKey = config.ECDHSharedKey;
                    //     let result = await gcmDecrypt(data, ECDHSharedKey);
                    //     return result;
                    // }
                } else {
                    alert('Request Failed');
                }
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });
};

export { handleRequestGetFile };
