import React from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';

import {useState, useEffect, useLayoutEffect} from 'react';
import {useTable, usePagination} from 'react-table';
import {convertErMsg, dateFormats, datePlaceHolder, handlePopup} from "../../general-tools";
import dayjs from "dayjs";
import {DatePicker} from "antd";
import {usePopupManager} from "react-popup-manager";

//data format
interface PropsData {
    format?: any;
    placeholder?: any;
    disabled?: any;
    value?: any;
    dateSetter?: any;
    resetToggle?: any;
    className?: any;

}



const Index = ({format, placeholder,disabled,value,dateSetter,resetToggle,clsName}: PropsData,) => {

    const [dateFormat, setDateFormat] = useState("null");
    const [placeholderStr, setPlaceholderStr] = useState("null");

    const [disabledBool, setDisabledBool] = useState(false);
    const [dateValue, setDateValue] = useState(null);
    const [preDateValue, setPreDateValue] = useState(null);
    const [className, setClassName] = useState("");

    const popupManager = usePopupManager();

    const handleClearData = () => {
        handleDataChange(null)
        setPreDateValue(null)
    }

    useEffect(() => {
        handleClearData()

    }, [resetToggle]);


    useEffect(() => {
        handleDataChange(value)
    }, [value]);


    useEffect(() => {
        setDateFormat(dateFormats());
        setPlaceholderStr(datePlaceHolder());
        setDisabledBool(disabled);
        setClassName(clsName);
    }, []);





    const handleDataChange = (data) => {
        if(data == "Init"){
            setDateValue(null);
        }
        else {
            setPreDateValue(data);
            setDateValue(data);
        }
        if(dateSetter)dateSetter(data);
    }


    // Render the UI for your table
    return (
        <DatePicker
            className={className}
            format={dateFormat}
            placeholder={preDateValue == null ? placeholderStr : (dayjs(preDateValue).format(dateFormat) == "Invalid Date" ? placeholderStr : dayjs(preDateValue).format(dateFormat))}
            disabled={disabledBool}
            inputReadOnly={disabledBool}
            value={isNaN(dayjs(dateValue).date()) ? null : dayjs(dateValue)}
            onChange={(e) => handleDataChange(e)}

        />

    );
};

export default React.memo(Index);
