import {createRef, useRef, useState, useEffect, useContext} from 'react';
import useAuth from '../../../hooks/useAuth';

import sidebarSource from '../../../components/navigation/nav-config';

import '../../asset/styles/header-container-style.css';

import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';

import api from '../../../apis';
import {useLoading} from '../../../context/LoadingContext';
import moment from 'moment';

import avatarImg from '../../asset/images/undraw_profile.svg';

const Index = () => {
    const navigate = useNavigate();
    const {setLoading} = useLoading();
    const [cookies, setCookie, removeCookie] = useCookies(['admin_user']);

    //create constant reference to the useRef();
    const menuRef = useRef<HTMLLIElement | null>(null);
    const [avatarMenu, setAvatarMenu] = useState(false);
    const [topbarCurrentSel, setTopbarCurrentSel] = useState('');
    const [lastLoginTime, setLastLoginTime] = useState();

    const [userName, setUserName] = useState('');

    useEffect(() => {
        //check if logined
        if (cookies.admin_user) {
            setUserName(cookies.admin_user.username);
            let time = moment(cookies.admin_user.lastlogin)
                .utc()
                .format("ddd DD MMM GGGG, h:mm A")
            setLastLoginTime(time.toString() == "Invalid date" ? "-" : time.toString());
        }
    }, [cookies]);

    const toggleAvatarMenu = () => {
        setAvatarMenu(!avatarMenu);
    };

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleOutsideClicks);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleOutsideClicks);
        };
    }, [avatarMenu]);

    useEffect(() => {
        let leftWidth = window.screen.width;
        if (leftWidth < 770) {
            // Is mobile view
            document.getElementById("popUpMessage").style.width = "calc(" + leftWidth + "px - 10%)";
            document.getElementById("popUpAlerts").style.width = "calc(" + leftWidth + "px - 10%)";
            document.getElementById("userDropdown").style.width = "calc(" + leftWidth + "px - 10%)";
            document.getElementById("searchDropdown").style.width = "calc(" + leftWidth + "px - 10%)";
            document.getElementById("popUpMessage").style.right = "-3%";
            document.getElementById("popUpAlerts").style.right = "-3%";
            document.getElementById("userDropdown").style.right = "-3%";
            document.getElementById("searchDropdown").style.right = "-3%";
        }

    }, []);

    //create a function in your component to handleOutsideClicks
    const handleOutsideClicks = (event: any) => {
        if (
            avatarMenu &&
            menuRef.current &&
            !menuRef.current.contains(event.target)
        ) {
            setAvatarMenu(false);
        }
    };

    const handleLogout = () => {
        api.userLogout().then((result: any) => {
            removeCookie('admin_user', {path: '/'});
            removeCookie('access_level', {path: '/'});
            removeCookie('expiredTime', {path: '/'});
            localStorage.clear();

            navigate('/');
        });

    };

    /* @ts-ignore */
    const {auth, setAuth} = useAuth();
    useEffect(() => {
        if (auth.layout) {
            setSelectedLayout(auth.layout);
        }

        if (auth.mode) {
            setSelectedMode(auth.mode);
        }
    }, []);

    const [selectedMode, setSelectedMode] = useState('basic');
    const handleModelChange = (e: any) => {
        setSelectedMode(e.target.value);
        setAuth({...auth, mode: e.target.value});
    };

    const [selectedLayout, setSelectedLayout] = useState('side');
    const handleLayoutChange = (e: any) => {
        setSelectedLayout(e.target.value);
        setAuth({...auth, layout: e.target.value});
    };

    const [openMobileSideNav, setOpenMobileSideNav] = useState(false);
    const [mobileSideNavPad, setMobileSideNavPad] = useState(0);
    const handleOpenMobileSideNav = () => {
        setOpenMobileSideNav(!openMobileSideNav);
    };

    useEffect(() => {
        var elmnt = document.querySelector('.header-container') as HTMLElement;
        setMobileSideNavPad(elmnt.offsetHeight);
    }, []);

    const redirect = (url: string) => {
        if (url) navigate(url);
    };

    const handlePopUpEle = (elementId: string) => {
        removeAvailTopDrop();
        let element = document.getElementById(elementId);
        if (topbarCurrentSel != element.id) {
            setTopbarCurrentSel(element.id);
            element.classList.add('show');
        } else {
            setTopbarCurrentSel('');
            element.classList.remove('show');
        }
    }

    const removeAvailTopDrop = () => {
        var cusid_ele = document.getElementsByClassName('show');
        for (var i = 0; i < cusid_ele.length; ++i) {
            let itemToDisable = document.getElementById(cusid_ele[i]['id']);
            itemToDisable.classList.remove('show');
        }

        var cusid_ele = document.getElementsByClassName('show');
        for (var i = 0; i < cusid_ele.length; ++i) {
            let itemToDisable = document.getElementById(cusid_ele[i]['id']);
            itemToDisable.classList.remove('show');
        }
    }
    const triggerSideNav = () => {
        document.getElementById("sidebarToggle").click();
    }

    return (
        <>
            <div className="top-nav-overflow header-container">


                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                    {/* <!-- Sidebar Toggle (Topbar) --> */}
                    <button onClick={() => {
                        triggerSideNav()
                    }} id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                        <i className="fa fa-bars"></i>
                    </button>


                    {/* <!-- Topbar Search --> */}
                    <form
                        className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                        <div className="input-group">
                            <input type="text" className="form-control bg-light border-0 small"
                                   placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2"/>
                            <div className="input-group-append">
                                <button className="btn btn-primary" type="button">
                                    <i className="fas fa-search fa-sm"></i>
                                </button>
                            </div>
                        </div>
                    </form>

                    {/* <!-- Topbar Navbar --> */}
                    <ul className="navbar-nav ml-auto">


                        {/* <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
                        <li className="nav-item dropdown no-arrow d-sm-none">
                            <a className="nav-link dropdown-toggle" href="#"
                               onClick={() => {
                                   handlePopUpEle('searchDropdown')
                               }}
                               role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-search fa-fw"></i>
                            </a>
                            {/* <!-- Dropdown - Messages --> */}
                            <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                                 id="searchDropdown" aria-labelledby="searchDropdown">
                                <form className="form-inline mr-auto w-100 navbar-search">
                                    <div className="input-group">
                                        <input type="text" className="form-control bg-light border-0 small"
                                               placeholder="Search for..." aria-label="Search"
                                               aria-describedby="basic-addon2"/>
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" type="button">
                                                <i className="fas fa-search fa-sm"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </li>

                        {/* <!-- Nav Item - Alerts --> */}
                        <li className="nav-item dropdown no-arrow mx-1">
                            <a className="nav-link dropdown-toggle"
                               onClick={() => {
                                   handlePopUpEle('popUpAlerts')
                               }}
                               href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                               aria-expanded="false">
                                <i className="fas fa-bell fa-fw"></i>
                                {/* <!-- Counter - Alerts --> */}
                                {/*<span className="badge badge-danger badge-counter">3+</span>*/}
                            </a>
                            {/* <!-- Dropdown - Alerts --> */}
                            <div id='popUpAlerts'
                                 className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                 aria-labelledby="alertsDropdown">
                                <h6 className="dropdown-header">
                                    Alerts Center
                                </h6>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="mr-3">
                                        <div className="icon-circle bg-primary">
                                            <i className="fas fa-file-alt text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">December 12, 2019</div>
                                        <span
                                            className="font-weight-bold">A new monthly report is ready to download!</span>
                                    </div>
                                </a>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="mr-3">
                                        <div className="icon-circle bg-success">
                                            <i className="fas fa-donate text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">December 7, 2019</div>
                                        $290.29 has been deposited into your account!
                                    </div>
                                </a>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="mr-3">
                                        <div className="icon-circle bg-warning">
                                            <i className="fas fa-exclamation-triangle text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">December 2, 2019</div>
                                        Spending Alert: We've noticed unusually high spending for your account.
                                    </div>
                                </a>
                                <a className="dropdown-item text-center small text-gray-500" href="#">Show All
                                    Alerts</a>
                            </div>
                        </li>

                        {/* <!-- Nav Item - Messages --> */}
                        <li className="nav-item dropdown no-arrow mx-1">
                            <a className="nav-link dropdown-toggle"
                               onClick={() => {
                                   handlePopUpEle('popUpMessage')
                               }}
                               href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-envelope fa-fw"></i>
                                {/* <!-- Counter - Messages --> */}
                                {/*<span className="badge badge-danger badge-counter">7</span>*/}
                            </a>
                            {/* <!-- Dropdown - Messages --> */}
                            <div id="popUpMessage"
                                 className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                 aria-labelledby="messagesDropdown">
                                <h6 className="dropdown-header">
                                    Message Center
                                </h6>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="dropdown-list-image mr-3">
                                        {/*<img className="rounded-circle"*/}
                                        {/*     src="https://source.unsplash.com/fn_BT9fwg_E/60x60" alt=""/>*/}
                                        <div className="status-indicator bg-success"></div>
                                    </div>
                                    <div className="font-weight-bold">
                                        <div className="text-truncate">'Hi there! I am wondering if you can help me with
                                            a problem I've been having.
                                        </div>
                                        <div className="small text-gray-500">Emily Fowler · 58m</div>
                                    </div>
                                </a>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="dropdown-list-image mr-3">

                                        <div className="status-indicator"></div>
                                    </div>
                                    <div>
                                        <div className="text-truncate">I have the photos that you ordered last month,
                                            how would you like them sent to you?
                                        </div>
                                        <div className="small text-gray-500">Jae Chun · 1d</div>
                                    </div>
                                </a>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="dropdown-list-image mr-3">
                                        {/*<img className="rounded-circle"*/}
                                        {/*     src="https://source.unsplash.com/CS2uCrpNzJY/60x60" alt=""/>*/}
                                        <div className="status-indicator bg-warning"></div>
                                    </div>
                                    <div>
                                        <div className="text-truncate">Last month's report looks great, I am very happy
                                            with the progress so far, keep up the good work!
                                        </div>
                                        <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                                    </div>
                                </a>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    <div className="dropdown-list-image mr-3">
                                        {/*<img className="rounded-circle"*/}
                                        {/*     src="https://source.unsplash.com/Mv9hjnEUHR4/60x60" alt=""/>*/}
                                        <div className="status-indicator bg-success"></div>
                                    </div>
                                    <div>
                                        <div className="text-truncate">Am I a good boy? The reason I ask is because
                                            someone told me that people say this to all dogs, even if they aren't
                                            good...
                                        </div>
                                        <div className="small text-gray-500">Chicken the Dog · 2w</div>
                                    </div>
                                </a>
                                <a className="dropdown-item text-center small text-gray-500" href="#">Read More
                                    Messages</a>
                            </div>
                        </li>

                        <div className="topbar-divider d-none d-sm-block"></div>

                        {/* <!-- Nav Item - User Information --> */}
                        <li className="nav-item dropdown no-arrow">
                            <a className="nav-link dropdown-toggle" href="#"
                               onClick={() => {
                                   handlePopUpEle('userDropdown')
                               }}

                               role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="mr-2 d-none d-lg-inline text-gray-600 small">{userName}</span>
                                {/*<img className="img-profile rounded-circle"*/}
                                {/*     src="https://source.unsplash.com/QAB-WJcbgJk/60x60"/>*/}
                                <br/>

                            </a>

                            {/* <!-- Dropdown - User Information --> */}
                            <div id="userDropdown"
                                 className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                 aria-labelledby="userDropdown">
                                <a className="dropdown-item" href="#">
                                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Profile
                                </a>
                                <a className="dropdown-item" href="#">
                                    <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Settings
                                </a>
                                <a className="dropdown-item" href="#">
                                    <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Activity Log
                                </a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#"
                                   onClick={() => handleLogout()}
                                   data-toggle="modal" data-target="#logoutModal">
                                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Logout
                                </a>
                            </div>
                        </li>
                        <div className="lastLoginTime">Last Login Time : {lastLoginTime}</div>

                    </ul>
                </nav>
            </div>

            <div
                style={{
                    top: mobileSideNavPad + 'px'
                }}
                className={
                    'mobile-side-nav ' +
                    (openMobileSideNav == true ? 'show' : '')
                }
            >
                <ul
                    className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
                    id="accordionSidebar"
                >
                    {sidebarSource.map(function (row, i) {
                        let className = 'nav-item';
                        if (row.selected == true) className = 'nav-item active';

                        return (
                            <li
                                key={i}
                                className={className}
                                onClick={(e) => redirect(row.url)}
                            >
                                <MobileMenuOption data={row}/>
                            </li>
                        );
                    })}
                    <li className="pt-5">
                        <button
                            className="btn btn-light"
                            onClick={() => handleLogout()}
                        >
                            Logout
                        </button>
                    </li>
                </ul>

            </div>
        </>
    );
};

const MobileMenuOption = (props) => {
    const navigate = useNavigate();
    const [expand, setExpand] = useState(false);

    const data = props.data;
    const redirect = (url: string) => {
        if (url) navigate(url);
        setExpand(false);
    };

    return (
        <>
            <a className="nav-link" onClick={() => setExpand(!expand)}>
                <img src={data.icon}/>
                <span>{data.label}</span>
            </a>

            {data && data.subMenu && (
                <div
                    id={data.label}
                    className={'collapse  ' + (expand == true ? ' show ' : ' ')}
                    aria-labelledby=""
                    data-parent="#accordionSidebar"
                >
                    <div className="bg-white py-2 collapse-inner rounded">
                        {data.subMenu.map((item, i) => {
                            return (
                                <a
                                    key={i}
                                    className="collapse-item"
                                    onClick={(e) => redirect(item.url)}
                                >
                                    {item.label}
                                </a>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};

export default Index;
