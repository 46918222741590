import { handleRequestPost, handleRequestGet } from '../axios';
import { setLogin, getUserDetail } from '../../hooks/handleCookie';
import { parseResult } from './GeneralFunc';
import {getCookie, handlePopup} from "../../components/general/general-tools";
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import {usePopupManager} from "react-popup-manager/dist/src";



export const registerMenu = async (id) => {



    let url = '/menuaccess/create';
    let result = await handleRequestPost(url, {
        "menu_id":id
    });

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult.status == 0) {
            let data = paresedResult.data;
        }
        return paresedResult;
    } catch (error) {}

    return false;
};
export const getMenu = async () => {
    let url = '/menuaccess';

    let result = await handleRequestGet(url, []);


    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
