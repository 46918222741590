import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    accountTypeOption, convertFuncPerm, dateTimeFormat, getDefaultAccessMat, getNavIdByPath, handlePopup,
    handleSelectChange,
    numProcessor,
    profileStatusOption, useLocalStorage,
    userTypeCategory, convertErMsg, specialCharCheck
} from "../../../general/general-tools";
import Multiselect from "multiselect-react-dropdown";
import {usePopupManager} from "react-popup-manager/dist/src";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";
import dayjs from "dayjs";


const Index = () => {

    const {setLoading} = useLoading();

    const [tableData, setTableData] = useLocalStorage("profile_search_tableData", []);
    const [totalRecords, setTotalRecords] = useLocalStorage("profile_search_totalRecords", 0);
    const [currentPage, setCurrentPage] = useLocalStorage("profile_search_currentPage", 1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage("profile_search_currentPerPage", 10);
    const [searchInput, setSearchInput] = useLocalStorage("profile_search_searchInput", "");

    const [searchUserId, setSearchUserId] = useLocalStorage("profile_search_searchUserId", "");
    const [searchUserName, setSearchUserName] = useLocalStorage("profile_search_searchUserName", "");
    const [searchUserType, setSearchUserType] = useLocalStorage("profile_search_searchUserType", "");
    const [searchAccountNo, setSearchAccountNo] = useLocalStorage("profile_search_searchAccountNo", "");
    const [searchAccountType, setSearchAccountType] = useLocalStorage("profile_search_searchAccountType", "");
    const [searchDealerCode, setSearchDealerCode] = useLocalStorage("profile_search_searchDealerCode", "");
    const [searchNRIC, setSearchNRIC] = useLocalStorage("profile_search_searchNRIC", "");
    const [searchBranch, setSearchBranch] = useLocalStorage("profile_search_searchBranch", "");
    const [searchPassport, setSearchPassport] = useLocalStorage("profile_search_searchPassport", "");
    const [searchStatus, setSearchStatus] = useLocalStorage("profile_search_searchStatus", "");
    const [branchList, setBranchList] = useState([]);

    const [userTypeList, setUserTypeList] = useState([]);
    const isInitialMount = useRef(true);


    const location = useLocation();
    let navigate = useNavigate();
    const popupManager = usePopupManager();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat() : getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);
    useLayoutEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        if (tableData.length > 0) {
            handleInitList({
                "term": searchUserId,
                "aterm": searchAccountNo,
                "nterm": searchUserName,
                "atterm": searchAccountType,
                "utterm": searchUserType,
                "dterm": searchDealerCode,
                "iterm": searchNRIC,
                "bterm": searchBranch[0] == null ? "" : searchBranch[0]['id'], //BB_ID
                "pterm": searchPassport,
                "status": searchStatus,
                "size": currentPerPage,
                "page": currentPage,
            });
        } else {
            handleResetBtn();

        }
        handleUserTypeList();
        handleBranchList();
    }, [])

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleInitList({
                "term": searchUserId,
                "aterm": searchAccountNo,
                "nterm": searchUserName,
                "atterm": searchAccountType,
                "utterm": searchUserType,
                "dterm": searchDealerCode,
                "iterm": searchNRIC,
                "bterm": searchBranch[0] == null ? "" : searchBranch[0]['id'], //BB_ID
                "pterm": searchPassport,
                "status": searchStatus,
                "size": currentPerPage,
                "page": currentPage,
            });
        }

    }, [currentPage, currentPerPage]);

    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {
        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }

    useEnterKeyPress(handleEnterKeyPress);

    const handleSearch = async (newSearch = false) => {

        if (isNaN(searchUserType)) {
            setSearchAccountType(null)
        }
        let curPage = currentPage;
           if (newSearch) {
            setCurrentPage(1)
            curPage= 1;
        }

        handleInitList({
            "term": searchUserId == "" ? null : searchUserId,
            "aterm": searchAccountNo == "" ? null : searchAccountNo,
            "nterm": searchUserName == "" ? null : searchUserName,
            "atterm": searchAccountType == "" ? null : searchAccountType,
            "utterm": searchUserType == "" ? null : searchUserType,
            "dterm": searchDealerCode == "" ? null : searchDealerCode,
            "iterm": searchNRIC == "" ? null : searchNRIC,
            "bterm": searchBranch[0] == null ? "" : searchBranch[0]['id'], //BB_ID
            "pterm": searchPassport == "" ? null : searchPassport,
            "status": searchStatus == "" ? null : searchStatus,
            "size": currentPerPage,
            "page": curPage,
        })
    };

    const handleUserTypeList = async () => {
        setLoading(true);
        api.getUserTypeList(999, 1, 1, "").then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if(result.data){
                if (result.data.list.length > 0) {
                    setUserTypeList(result.data.list);
                }
            }
        });
        setLoading(false);
    };


    const handleInitList = (param) => {

        setLoading(true);
        api.getProfileList(param).then((result: any) => {

            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    for (let i = 0; i < dataList.length; i++) {
                        dataList[i]['p_id'] = dataList[i]['id'];
                        dataList[i]['id'] = "";
                    }
                    setTableData(dataList);
                    let totalPage = parseInt(result.data.totalrow)
                    setTotalRecords(totalPage);
                    setLoading(false);
                    // }, 10);
                    // return () => clearTimeout(timeout);
                } else {
                    handlePopup(true,"Error","No results found",popupManager)
                    setTableData([]);
                    setTotalRecords(10);
                    setLoading(false);
                }
            }else {
                handlePopup(true,"Error","No results found",popupManager)
                setTableData([]);
                setTotalRecords(10);
                setLoading(false);
            }
        });

    };


    const handleOnclick = async (row) => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "user_profile_opr", {
            state: {
                row: row,
                perm: actualPerm,
                mode: 'Edit'
            }
        });
        setLoading(false);
    };
    const handleAddBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "user_profile_opr", {
            state: {
                row: "",
                perm: actualPerm,
                mode: 'Add'
            }
        });
        setLoading(false);
    };
    const handleResetBtn = async () => {
        await setLoading(true);
        setSearchUserId("");
        setSearchInput("");
        setSearchUserName("");
        setSearchUserType("");
        setSearchAccountNo("");
        setSearchAccountType("");
        setSearchDealerCode("");
        setSearchNRIC("");
        setSearchBranch("");
        setSearchPassport("");
        setSearchStatus("");
        setLoading(false);
    };


    const columns = useMemo(
        () => [
            {
                id: row => row.p_fullname,
                name: 'User Name',
                selector: row => row.p_fullname,
                sortable: true,
                // center: true,
                cell: (row: { p_fullname: any }) => (
                    <>
                        <a className="linkCursor" onClick={() => handleOnclick(row)}>{row.p_fullname}</a>
                    </>
                )
            },
            {
                name: 'User Id',
                selector: row => row.username,
                sortable: true
            },
            {
                name: 'User Type',
                selector: row => row.ut_name,
                sortable: true,
            },
            {
                name: 'Account No',
                selector: row => row.account_no ?? "-",
                sortable: true,
                left: true

            },
            {
                name: 'Account Type',
                selector: row => row.account_type == 0 ? "Cash" : row.account_type == 1 ? "Margin" : row.account_type == 2 ? "Cash Upfront" : row.account_type == 3 ? "Collateralized" : "-",
                sortable: true
            },
            {
                name: 'Status',
                selector: row => row.active_status == 0 ? "Inactive" : row.active_status == 1 ? "Active" : row.active_status == 2 ? "Suspended" : row.active_status == 3 ? "Expired" : row.active_status == 4 ? "Dormant" : row.active_status == 11 ? "Active": "Closed",
                sortable: true
            },
            {
                name: 'Access Status',
                selector: row => row.active_status == 1 ? "Unblock" : row.active_status == 2 ? "Block" : "-",
                sortable: true
            },
            {
                name: 'Last Login',
                selector: row => dayjs(row.last_login).format(dateTimeFormat()) == 'Invalid Date' ? "-" : dayjs(row.last_login).format(dateTimeFormat()),
                sortable: true
            }
        ],
        []
    );
    const handleBranchList = async () => {
        setLoading(true);
        api.getBranchList(999, 1, 1, null).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdown(dataList);
                    setBranchList(TreeData);
                } else {
                    handlePopup(true, "Error", "No results found", popupManager)
                    setTableData([]);
                    setTotalRecords(10);
                }
            }else {
                handlePopup(true, "Error", "No results found", popupManager)
                setTableData([]);
                setTotalRecords(10);
            }
        });
        setLoading(false);
    };
    const convertRawIntoDropdown = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                code: data[i]['bb_code'],
                name: data[i]['bb_code'] + " | " + data[i]['bb_name'],
                id: data[i]['bb_id']
            });
        }
        return returnDat;
    }
    const onSelect = (selectedList, selectedItem) => {
        setSearchBranch(selectedList);
    }
    const onRemove = (selectedList, selectedItem) => {
        setSearchBranch("");
    }
    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; User
                                Maintenance &gt; Profile - Search</h5>

                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-1">User ID</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            autoComplete='off'
                                            placeholder=""
                                            aria-label=""
                                            value={searchUserId}
                                            onChange={(e) => setSearchUserId(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setSearchUserId,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-1">Account No</div>
                                    <div className="col-md-5">
                                        <input
                                            className="form-control"
                                            placeholder=""
                                            aria-label=""
                                            value={searchAccountNo}
                                            onChange={(e) => setSearchAccountNo(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setSearchAccountNo,popupManager)}

                                        />
                                    </div>


                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-1">User Name</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"
                                            placeholder=""
                                            aria-label=""
                                            value={searchUserName}
                                            onChange={(e) => setSearchUserName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setSearchUserName,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-1">Account Type</div>
                                    <div className="col-md-5">

                                        <select
                                            className="form-control"

                                            aria-label=""
                                            value={searchAccountType}
                                            onChange={(e) => setSearchAccountType(e.target.value)}
                                        >
                                            {accountTypeOption()}
                                        </select>


                                    </div>


                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-1">User Type</div>
                                    <div className="label col-md-5">
                                        <select
                                            className="form-control"

                                            aria-label=""
                                            value={searchUserType}
                                            onChange={e => handleSelectChange(e.target.value, setSearchUserType, false)}
                                        >
                                            <option value="">Please select user type</option>
                                            {userTypeList.map(el => <option
                                                value={el.ut_id}>{el.ut_name}</option>)}
                                        </select>

                                    </div>
                                    <div className="label col-md-1">Dealer Code</div>
                                    <div className="col-md-5">
                                        <input
                                            className="form-control"

                                            placeholder=""
                                            aria-label=""
                                            value={searchDealerCode}
                                            onChange={(e) => setSearchDealerCode(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setSearchDealerCode,popupManager)}

                                        />
                                    </div>


                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-1">NRIC No</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"

                                            placeholder=""
                                            aria-label=""
                                            value={searchNRIC}
                                            onChange={(e) => setSearchNRIC(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setSearchNRIC,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-1">Branch</div>
                                    <div className="col-md-5">

                                        <Multiselect
                                            options={branchList}
                                            onSelect={onSelect}
                                            onRemove={onRemove}
                                            selectedValues={searchBranch}
                                            displayValue="name"
                                            placeholder="Search by Branch Code / Branch Name"
                                            selectionLimit={1}
                                            hidePlaceholder={true}
                                            showCheckbox={false}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />

                                    </div>


                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-1">Passport No</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control"

                                            placeholder=""
                                            aria-label=""
                                            value={searchPassport}
                                            onChange={(e) => setSearchPassport(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setSearchPassport,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-1">Status</div>
                                    <div className="col-md-5">
                                        <select
                                            className="form-control"

                                            aria-label=""
                                            value={searchStatus}
                                            onChange={e => handleSelectChange(e.target.value, setSearchStatus, false)}
                                        >
                                            {profileStatusOption()}
                                        </select>
                                    </div>


                                </div>
                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary "   id="btn-search-main" onClick={() => handleSearch(true)}>Search
                                        </button>
                                        <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                        </button>
                                        {(actualPerm['add']) ? (<button className="btn btn-primary "
                                                                        onClick={() => handleAddBtn()}>Add</button>) : ""}

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    perPageQty={setCurrentPerPage}
                                    columns={columns}
                                    data={tableData}
                                    pagination={{
                                        currentPage: currentPage,
                                        currentPerPage: currentPerPage,
                                        totalRecords: totalRecords
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
