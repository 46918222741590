import React from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';

import {useState, useEffect, useLayoutEffect} from 'react';
import {useTable, usePagination} from 'react-table';
import './index.css';
import moment from "moment";
import dayjs from "dayjs";
import {dateFormats, dateTimeFormatTaskSchedule, timeFormat} from "../../general-tools";

//data format
interface PropsData {
    columns?: Array[];
    data?: Array[];
    rowClick?: any;
    pagination?: any;
    perPage?: any;
    showAllSelBool?: any;
    clearRows?: any;
    clearPage?: any;
    expandableRowsComponent?: any;

}

interface PaginationFormat {
    currentPage?: number;
    totalRecords?: number;
    currentPerPage?: number;
}

const Index = ({
                   columns,
                   data,
                   rowClick,
                   pagination,
                   perPageQty,
                   handleSelect,
                   style,
                   clearRows,
                   clearPage,
                   expandableRowsComponent
               }: PropsData,) => {
    const pageSize = 10;
    const pageRange = 5;
    const [visiblePages, setVisiblePages] = useState([]);
    const [tableTotalPage, setTableTotalPage] = useState(0);
    const [pending, setPending] = React.useState(true);
    const [tableTotalRow, setTableTotalRow] = useState(0);
    const [tableStyle, setTableStyle] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [tableDatas, setTableDatas] = useState([]);
    const [tableColumn, setTableColumn] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [selectable, setSelectable] = useState(false);
    const [showAllSel, setShowAllSel] = useState(false);
    const [selectableHandle, setSelectableHandle] = useState(null);
    const [toggledClearRows, setToggleClearRows] = React.useState(false);
    const [toggledClearPage, setToggleClearPage] = React.useState(false);
    const [curPerPage, setCurPerPage] = React.useState(10);


    const [expendDivs, setExpendDivs] = React.useState(null);

    const handleClearRows = () => {
        setToggleClearRows(!clearRows);
    }

    const handleClearPage = () => {
        setToggleClearPage(!toggledClearPage);
    }

    useEffect(() => {
        handleClearRows();
    }, [clearRows]);

    useEffect(() => {
        handleClearPage();
    }, [clearPage]);

    useEffect(() => {
        setTableColumn(columns ?? []);
    }, [tableColumn]);

    useEffect(() => {
        setTableStyle(style ?? "");
    }, [tableStyle]);

    useEffect(() => {
        setPending(true);
        const timeout = setTimeout(() => {
            setTableDatas(data ?? []);
        }, 10);
        setPending(false);

        return () => clearTimeout(timeout);

    }, [data, tableDatas]);

    useEffect(() => {
        setTableTotalRow(pagination['totalRecords']);
        setCurrentPage(pagination['currentPage']);
        setSelectable(pagination['selectable']);
        setSelectableHandle(pagination['selectableHandle']);
        setSelectableHandle(pagination['selectableHandle']);
        setShowAllSel(pagination['showAllSelBool'] ?? false);
        setCurPerPage(pagination['currentPerPage']);
        if (expandableRowsComponent) setExpendDivs(expandableRowsComponent);
    }, [pagination]);

    useEffect(() => {

        const timeout = setTimeout(() => {
            setCurPerPage(pagination['currentPerPage']);
            setPending(false);
        }, 10);
        return () => clearTimeout(timeout);
    }, []);


    const handlePageChange = page => {
        rowClick(page);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        perPageQty(newPerPage);
        setCurPerPage(newPerPage);
    };
    const handlePerRowsSelect = (selectedRows) => {
        if (handleSelect) handleSelect(selectedRows);
    };


    const CustomLoader = () => (
        <div style={{padding: '24px'}}>
            <div className="spinner-animated"></div>
        </div>
    );
    const paginationComponentOptions = {
        selectAllRowsItem: showAllSel

    };

    // data provides access to your row data
    const ExpandTaskSchedular = () => ({data}) => {
        return (
            <>
                <pre>
                  <div className="input-container col-md-12 taskMonHeader ">
                       <div className="input-container col-md-6 taskMonHeader ">
                            <div className=" col-md-3 "><b>Trading Day</b></div>
                            <div className=" col-md-3 "><b>: </b>{data.tss_trade_day == 0? "No" : "Yes"} </div>

                    </div>
                        <div className="input-container col-md-6 taskMonHeader ">
                            <div className=" col-md-3 "><b>Process Name</b></div>
                            <div className=" col-md-3 "><b>: </b>{data.tss_name}</div>
                    </div>
                  </div>

                    <div className="input-container col-md-12 taskMonHeader ">
                        <div className="input-container col-md-6 taskMonHeader ">
                            <div className=" col-md-3 "><b>Process Type</b></div>
                            <div className=" col-md-3 "><b>: </b>{data.tss_type == 0 ? "Program / Service" : data.tss_type == 1 ? "Stored Procedure" : "DLL"}</div>
                    </div>
                     <div className="input-container col-md-6 taskMonHeader ">
                            <div className=" col-md-3 "><b>Does Not Repeat</b></div>
                            <div className=" col-md-3 "><b>: </b>{data.tss_recurr_mode == 1? "Does not repeat" : "Recurrence"}</div>
                    </div>
                  </div>
                    <div className="input-container col-md-12 taskMonHeader ">
                       <div className="input-container col-md-6 taskMonHeader ">
                            <div className=" col-md-3 "><b>Recurrence</b></div>
                            <div className=" col-md-3 "><b>: </b>{data.recurring == "" ? "-" : data.recurring }</div>
                    </div>
                     <div className="input-container col-md-6 taskMonHeader ">
                            <div className=" col-md-3 "><b>Start Time</b></div>
                            <div className=" col-md-3 "><b>: </b>{!moment(data.tss_start, 'YYYY-MM-DDThh:mm:ss').isValid() ? "-" : dayjs(data.tss_start).format(timeFormat())}</div>
                    </div>
                  </div>
                    <div className="input-container col-md-12 taskMonHeader ">
                        <div className="input-container col-md-6 taskMonHeader ">
                            <div className=" col-md-3 "><b>Start Date</b></div>
                            <div className=" col-md-3 "><b>: </b>{!moment(data.tss_start, 'YYYY-MM-DDThh:mm:ss').isValid() ? "-" : dayjs(data.tss_start).format(dateFormats())}</div>
                    </div>
                     <div className="input-container col-md-6 taskMonHeader ">
                            <div className=" col-md-3 "><b>End Date</b></div>
                            <div className=" col-md-3 "><b>: </b>{!moment(data.tss_end, 'YYYY-MM-DDThh:mm:ss').isValid() ? "-" : dayjs(data.tss_end).format(dateFormats())}</div>
                     </div>
                  </div>
                <div className=" col-md-12 fullWidthTable taskMonTableHeader ">
                            <div className=" col-md-3 "><b>Date</b></div>
                            <div className=" col-md-3 "><b>Act.Start Time</b></div>
                            <div className=" col-md-3 "><b>Act.End Time</b></div>
                            <div className=" col-md-3 "><b>Status</b></div>
                </div>


                    {data.detail_list && data.detail_list.map((el, index) => (
                        <div className=" col-md-12 fullWidthTable taskMonHeader ">
                            <div className=" col-md-3 ">{moment(el.tssl_start, 'YYYY-MM-DD HH:MM', true).isValid() ? "-" : dayjs(el.tssl_start).format(dateFormats())}</div>
                            <div className=" col-md-3 ">{moment(el.tssl_start, 'YYYY-MM-DD HH:MM', true).isValid() ? "-" : dayjs(el.tssl_start).format(timeFormat())}</div>
                            <div className=" col-md-3 ">{moment(el.tssl_end, 'YYYY-MM-DD HH:MM', true).isValid() ? "-" : dayjs(el.tssl_end).format(timeFormat())}</div>
                            <div className=" col-md-3 ">{el.tssl_status}</div>
                        </div>
                    ))}

                    <hr/>

                </pre>
            </>
        );
    };

    // Render the UI for your table
    return (
        <DataTable
            columns={tableColumn}
            data={tableDatas}
            pagination
            paginationServer
            // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            persistTableHead
            selectableRows={selectable}
            onSelectedRowsChange={handlePerRowsSelect}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressPending={pending}
            progressComponent={<CustomLoader/>}
            paginationTotalRows={tableTotalRow}
            paginationComponentOptions={paginationComponentOptions}
            paginationDefaultPage={pagination['currentPage']}
            paginationRowsPerPageOptions={[10, 15, 30, 50, 100]}
            rowsPerPage={pagination['currentPerPage']}
            paginationPerPage={pagination['currentPerPage']}
            clearSelectedRows={toggledClearRows}
            paginationResetDefaultPage={toggledClearPage}
            customStyles={tableStyle ?? null}
            expandableRows={expendDivs == null ? false : true}
            expandableRowsComponent={expendDivs == "TaskSchedular" ? ExpandTaskSchedular() : ""}
        />

    );
};

export default Index;
