import React, {useState, useEffect, useMemo} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';

import {numberWithCommas} from '../../../general/convertor';
import {confirmAlert} from "react-confirm-alert";
import api from "../../../../apis";
import {
    disableAllEleInDiv,
    handleCountryChange,
    handlePopup,
    momentTimeFormat,
    numProcessor, convertErMsg, handleTopUpdateEL, cursorToFront, handleDecimalNumChangeWifCursor, specialCharCheck
} from "../../../general/general-tools";
import ModalConfirm from "../../public/ModalConfirm";
import {usePopupManager} from "react-popup-manager";
import Multiselect from "multiselect-react-dropdown";
import ReactDatepick from "../../../general/form-control/react-datepick";
import dayjs from "dayjs";

const Index = () => {
    const {setLoading} = useLoading();


    const [currentMode, setCurrentMode] = useState("");
    const [currentCurId, setCurrentCurId] = useState("");
    const [currentCurCode, setCurrentCurCode] = useState("");
    const [currentCurName, setCurrentCurName] = useState("");

    const [currentBuyRate, setCurrentBuyRate] = useState("0.00");
    const [currentSellRate, setCurrentSellRate] = useState("0.00");
    const [currentDate, setCurrentDate] = useState(null);
    const [accessItem, setAccessItem] = useState({});
    const [countryList, setCountryList] = useState([]);
    const [currentCountry, setCurrentCountry] = useState({});
    const [toggleReset, setToggleReset] = useState(false);

    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();


    //Load initial data and mode
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        if (location.state) {
            if (location.state.row) {
                setAccessItem(location.state.perm);

                if (!location.state.perm['edit']) {
                    setTimeout(function () {
                        disableAllEleInDiv("mainDiv");
                    }, 50);
                }

                setCurrentCurId(location.state.row.c_id);
                setCurrentCurCode(location.state.row.c_code);
                setCurrentCurName(location.state.row.c_name);
                setCurrentSellRate(numProcessor(location.state.row.c_sellrate == null ? 0.00 : location.state.row.c_sellrate));
                setCurrentBuyRate(numProcessor(location.state.row.c_buyrate== null ? 0.00 :location.state.row.c_buyrate));
                setCurrentDate(location.state.row.c_effectivedate);

                if (location.state.row.country) {
                    let selectedCountry = [];
                    let selectedList = [];
                    let ctData = JSON.parse(location.state.row.country);
                    for (let i = 0; i < ctData.length; i++) {
                        selectedList.push(ctData[i]['co_id']);
                        selectedCountry.push({
                            name: ctData[i]['co_name'],
                            id: ctData[i]['co_id']
                        });
                    }
                    setCurrentCountry(selectedCountry);
                    handleCountryList(selectedCountry);


                } else {
                    handleCountryList(null);
                }

            }else{
                handleCountryList(null);

            }
            setCurrentMode(location.state.mode)

        }
    }, [location.state]);

    const handleCountryList = async (existingList) => {
        api.getCountryList(999, 1, 1, null,true).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    setCountryList(convertRawCountryIntoDropdown(dataList, existingList));
                }
            }
        });
    };

    const convertRawCountryIntoDropdown = (data, existingItems) => {
        var returnDat = [];
        var setList = [];
        for (let i = 0; i < data.length; i++) {

            if (existingItems) {
                if (!existingItems.includes(data[i]['c_id'])) {
                    if (!setList.includes(data[i]['c_id'])) {
                        returnDat.push({
                            name: data[i]['c_name'],
                            id: data[i]['c_id']
                        });
                        setList.push(data[i]['c_id']);
                    }
                    setList.push(data[i]['c_id']);
                }
            }else{
                if (!setList.includes(data[i]['c_id'])) {
                    returnDat.push({
                        name: data[i]['c_name'],
                        id: data[i]['c_id']
                    });
                    setList.push(data[i]['c_id']);
                }
            }





        }
        return returnDat;
    }

    // Edit
    const handleBackBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "currency", {state: {mode: 'back'}});
        setLoading(false);
    };

    const onCountryChange = (selectedList, selectedItem) => {
        setCurrentCountry(selectedList);
    }

    const handleRemoveBtn = async (country_code) => {
        await setLoading(true);


        popupManager.open(ModalConfirm, {
            title: 'Delete',
            content: 'Are you sure you want to delete?',
            onClose: (...params) => handleRemoveItem(params[0])
        });


        setLoading(false);
    };
    const handleRemoveItem = async (selBoolean) => {
        if (!selBoolean) return;
        api.deleteCurrency(currentCurId).then((result: any) => {
            if (result != false && result.status == 0) {
                setLoading(false);
                handlePopup(true, "Success", "Currency deleted successfully.", popupManager,true,"currency",navigate)
            } else {
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
            }
        });

    };

    const handleSaveBtn = async () => {
        await setLoading(true);
        if (currentCurCode == '') {
            handlePopup(true, "Error", "Mandatory Field - Currency Code not entered.", popupManager)
            setLoading(false);
            return false;
        }


        if (
            currentCurCode.trim().length > 1 && currentCurCode.trim().length < 4
        ) {
            let curDate = dayjs(currentDate).format(momentTimeFormat()) ;

            let countryStr = "";
            for (let i = 0; i < currentCountry.length; i++) {
                if (i == 0) countryStr += currentCountry[i]['id'];
                else countryStr += "," + currentCountry[i]['id'];
            }
            api.updateCurrency(currentCurId, currentCurCode, currentCurName, currentBuyRate, currentSellRate, curDate, countryStr).then((result: any) => {
                setLoading(false);

                if (result != false && result.status == 0) {
                    handlePopup(true, "Success", "Currency information updated successfully.", popupManager,true,"currency",navigate)
                } else {
                    handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                }
            });
        } else{
            handlePopup(true, "Error", "Currency code must be in 2 to 3 character length.", popupManager)
            setLoading(false);
        }
    };



    const handleResetBtn = async () => {
        var todayDate = getTodayDate();
        await setLoading(true);
        setCurrentCurCode("");
        setCurrentBuyRate(0.00);
        setCurrentSellRate(0.00);
        setToggleReset(!toggleReset)
        setLoading(false);
    };


    const handleCurrencyClear = (val,setter)=>{

        //Is NaN || is ""
        if(isNaN(parseFloat(val)) || val == ""){
            setter(0.00)

        }else{
            setter("")
            setter(val.toString().replace(/^0+/, ''))
        }
    }


    const handleAddBtn = async () => {
        await setLoading(true);
        if (currentCurCode == '') {
            handlePopup(true, "Error", "Mandatory Field - Currency Code not entered.", popupManager)
            setLoading(false);
            return false;
        }

        if (
            currentCurCode.trim().length > 1 && currentCurCode.trim().length < 4
        ) {
            let curDate = dayjs(currentDate).format(momentTimeFormat()) ;

            if (currentCurCode.trim().length > 4) handlePopup(true, "Error", "Currency code shall not exceed 4 characters.", popupManager)

            api.addCurrency(currentCurCode, currentCurName, currentBuyRate, currentSellRate, curDate).then((result: any) => {
                if (result != false && result.status == 0) {
                    setLoading(false);
                    handlePopup(true, "Success", "Currency information inserted successfully.", popupManager,true,"currency",navigate)
                } else{
                    setLoading(false);
                    handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                }
            });


        } else{
            handlePopup(true, "Error", "Currency code must be in 2 to 3 character length.", popupManager)
            setLoading(false);
        }
    };


    const getTodayDate = () => {
        return new Date().toLocaleDateString('en-CA', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }

     function handleCurrencyRate(curValue, operationSetter) {
        curValue = curValue.replace(/,/g, '');
        let CuRate = parseFloat(curValue) ;
        if (isNaN(CuRate) || CuRate === Infinity) {
            operationSetter("0.00");
        } else {
            operationSetter((CuRate.toFixed(2)));
        }
    }


    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Master File
                                Maintenance &gt; Currency - {currentMode}</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Currency Code</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="e.g. MYR"
                                            max={3}
                                            aria-label=""
                                            value={currentCurCode}
                                            onChange={(e) => setCurrentCurCode(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentCurCode,popupManager)}

                                        />
                                    </div>
                                    <b className='mandatory-red'>*</b>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Currency Name</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="e.g. Malaysia Ringgit"
                                            aria-label=""
                                            value={currentCurName}
                                            onChange={(e) => setCurrentCurName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setCurrentCurName,popupManager)}

                                        />
                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Country</div>
                                    <div className=" col-md-10">

                                        <Multiselect
                                            options={countryList}
                                            onSelect={onCountryChange}
                                            onRemove={onCountryChange}
                                            selectedValues={currentCountry}
                                            displayValue="name"
                                            placeholder="Search Country"
                                            hidePlaceholder={true}
                                            showCheckbox={false}
                                            // selectionLimit={1}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Buy Rate</div>
                                    <div className="label col-md-10">

                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={currentBuyRate}
                                            onBlur={(e) => handleCurrencyRate(currentBuyRate, setCurrentBuyRate)}
                                            onFocus={(e) => cursorToFront(e)}
                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, setCurrentBuyRate)}
                                        />
                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Sell Rate</div>
                                    <div className="label col-md-10">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder=""
                                            aria-label=""
                                            value={currentSellRate}
                                            onBlur={(e) => handleCurrencyRate(currentSellRate, setCurrentSellRate)}
                                            onFocus={(e) => cursorToFront(e)}
                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager,e, setCurrentSellRate)}
                                        />
                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-2">Effective Date</div>

                                    <ReactDatepick
                                        clsName="ant-pck-margin"
                                        value={currentDate}
                                        dateSetter={setCurrentDate}
                                        resetToggle={toggleReset}
                                    />


                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1"></div>

                                    {/*{renderButtonByMode(currentMode)}*/}

                                    {currentMode.toLowerCase() == 'edit' ?
                                        <div className="label col-md-11 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                            </button>
                                            {accessItem['delete'] ? <button className="btn btn-primary "
                                                                            onClick={() => handleRemoveBtn(currentCurCode)}>Delete</button> : ""}
                                            {accessItem['edit'] ? <button className="btn btn-primary "
                                                                          onClick={() => handleSaveBtn()}>Save</button> : ""}

                                        </div> :

                                        <div className="label col-md-11 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                            </button>
                                            <button className="btn btn-primary "
                                                    onClick={() => handleResetBtn()}>Reset
                                            </button>
                                            <button className="btn btn-primary " onClick={() => handleAddBtn()}>Save
                                            </button>
                                        </div>
                                    }


                                </div>


                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
