import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import Multiselect from "multiselect-react-dropdown";
import {
    convertFuncPerm,
    getDefaultAccessMat,
    getNavIdByPath,
    handlePopup,
    useLocalStorage,convertErMsg
} from "../../../general/general-tools";
import { usePopupManager } from "react-popup-manager";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";



const Index = () => {
    const {setLoading} = useLoading();

    const [tableData, setTableData] = useLocalStorage("exp_branch_tblData",[]);
    const [totalRecords, setTotalRecords] = useLocalStorage("exp_branch_totRec",0);
    const [currentPage, setCurrentPage] = useLocalStorage("exp_branch_curPg",1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage("exp_branch_curPerPg",10);
    const [searchInput, setSearchInput] = useLocalStorage("exp_branch_searchInput","");
    const [branchList, setBranchList] = useLocalStorage("exp_branch_",[]);

    const isInitialMount = useRef(true);
    const popupManager = usePopupManager();

    const location = useLocation();
    let navigate = useNavigate();

    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat():getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);


    useLayoutEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        if (tableData.length > 0) {
            handleInitList(searchInput[0] == null ? "" : searchInput[0] ['code'])
        }else{
            handleResetBtn();
        }
        handleBranchList();
    },[])


    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleInitList(searchInput[0] == null ? "" : searchInput[0] ['code']);
        }

    }, [currentPage, currentPerPage]);


    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {
        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }
    useEnterKeyPress(handleEnterKeyPress);
    const handleInitList = async (searchTerm,newSearch = false) => {
        setLoading(true);
        let curPage = currentPage;
           if (newSearch) {
            setCurrentPage(1)
            curPage= 1;
        }
        api.getBranchList(currentPerPage, curPage, 1, searchTerm).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if (result.status == 0 && result.data) {
                if(result.data.list.length >0){
                    let dataList = result.data.list;
                    const timeout = setTimeout(() => {
                        setTableData(dataList);
                        let totalPage = parseInt(result.data.totalrow)
                        setTotalRecords(totalPage);
                        setLoading(false);
                    }, 10);
                    return () => clearTimeout(timeout);
                }else{
                    handlePopup(true,"Error","No results found",popupManager)
                    setTableData([]);
                    setTotalRecords(10);
                    setLoading(false);
                }
            }else{
                handlePopup(true,"Error","No results found",popupManager)
                setTableData([]);
                setTotalRecords(10);
                setLoading(false);
            }
        });
    };

    const handleOnclick = async (cur_code) => {
        await setLoading(true);
        navigate("/"+process.env.REACT_APP_URL_PREFIX+"exp_branch_opr",{state:{row:cur_code,perm:actualPerm,mode:'Edit'}});
        setLoading(false);
    };

    const handleResetBtn = async () => {
        await setLoading(true);
        setSearchInput("");
        setLoading(false);
    };


    const columns = useMemo(
        () => [
            {
                id:  row => row.bb_code,
                name: 'Branch Code',
                selector: row => row.bb_code,
                sortable: true,
                // center: true,
                cell: (row: { bb_code: any }) => (
                    <>
                        <a className="tableItemsHlink" onClick={() => handleOnclick(row)}>{row.bb_code}</a>
                    </>
                )
            },
            {
                name: 'Branch Name',
                selector: row => row.bb_name,
                sortable: true,
            }
        ],
        []
    );

    const onSelect = (selectedList, selectedItem) => {
            setSearchInput(selectedList);
    }
    const onRemove = (selectedList, selectedItem) => {
        setSearchInput("");
    }
    const handleBranchList = async () => {

        setLoading(true);
            api.getBranchList(999, 1, 1, null).then((result: any) => {
            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdown(dataList);
                    setBranchList(TreeData);
                } else {

                    handlePopup(true,"Error","No results found",popupManager)
                    setTableData([]);
                    setTotalRecords(10);
                }
            } else {

                handlePopup(true,"Error","No results found",popupManager)
                setTableData([]);
                setTotalRecords(10);
            }
        });
        setLoading(false);
    };


    const convertRawIntoDropdown = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                code: data[i]['bb_name'],
                name: data[i]['bb_code'] + " | " + data[i]['bb_name'],
                id: data[i]['bb_name']
            });
        }
        return returnDat;
    }
    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Risk Management &gt; Exposure Limit
                                - Branch</h5>
                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-2">Branch Code/Branch Name</div>

                                    <div className='col-md-10'>
                                        <Multiselect
                                            options={branchList}
                                            onSelect={onSelect}
                                            onRemove={onRemove}
                                            selectedValues={searchInput}
                                            displayValue="name"
                                            placeholder="Search by Branch Code / Branch Name"
                                            selectionLimit={1}
                                            hidePlaceholder={true}
                                            showCheckbox={false}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary "  id="btn-search-main" onClick={() => handleInitList(searchInput[0] == null ? "" : searchInput[0] ['code'],true)}>Search</button>
                                        <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset</button>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    perPageQty={setCurrentPerPage}
                                    columns={columns}
                                    data={tableData}
                                    pagination={{
                                        currentPage: currentPage,
                                        currentPerPage: currentPerPage,
                                        totalRecords: totalRecords
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
