import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {tab} from "@testing-library/user-event/dist/tab";
import {
    convertFuncPerm,
    getDefaultAccessMat,
    getNavIdByPath,
    handlePopup,
    useLocalStorage, convertErMsg, dateFormats, dateTimeFormat, dateTimeFormatTaskSchedule
} from "../../../general/general-tools";
import {usePopupManager} from "react-popup-manager";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";
import ReactTooltip from "react-tooltip-rc";
import Multiselect from "multiselect-react-dropdown";
import {ExpanderComponentProps} from "react-data-table-component";
import {getTaskMonitorList} from "../../../../apis/models/TaskScheduler";
import dayjs from "dayjs";


const Index = () => {
    const {setLoading} = useLoading();

    const [tableData, setTableData] = useLocalStorage("mst_taskMonitor_TblDat", []);
    const [totalRecords, setTotalRecords] = useLocalStorage("mst_taskMonitor_TotRec", 0);
    const [currentPage, setCurrentPage] = useLocalStorage("mst_taskMonitor_CurPg", 1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage("mst_taskMonitor_curPerPg", 10);
    const [selectedRows, setSelectedRows] = useLocalStorage("userAc_selRow", null);

    const [taskId, setTaskId] = useLocalStorage("mst_taskMonitor_taskId", "");
    const [taskIdList, setTaskIdList] = useLocalStorage("mst_taskMonitor_taskIdList", []);

    const [recurrence, setRecurrence] = useLocalStorage("mst_taskMonitor_recurrence", "");
    const [recurrenceList, setRecurrenceList] = useLocalStorage("mst_taskMonitor_recurrenceList", []);

    const isInitialMount = useRef(true);
    const [toggledClearRows, setToggleClearRows] = React.useState(false);

    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat() : getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);

    useLayoutEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        handleInitTskDropdown();
        if (tableData.length > 0) {
            handleInitList()
        } else {
            handleResetBtn();
        }
    }, [])

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleInitList();
        }
    }, [currentPage, currentPerPage]);

    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {
        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }

    useEnterKeyPress(handleEnterKeyPress);
    const handleInitTskDropdown = async () => {
        setLoading(true);

        api.getTaskSchedulerList(20, 1, 1, "", "").then((result: any) => {
            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }

            if (result.data) {

                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    let termDropdown = convertRawIntoDropdown(dataList)
                    setTaskIdList(termDropdown);


                }
            }

            let recurSelection = [{
                name: "Day",
                id: 0
            }, {
                name: "Week",
                id: 1
            }, {
                name: "Month",
                id: 2
            },
            ];

            setRecurrenceList(recurSelection)

            setLoading(false);

        });

    }

    const handleInitList = async (newSearch = false) => {
        // setLoading(true);
        let curPage = currentPage;
        if (newSearch) {
            setCurrentPage(1)
            curPage = 1;
        }

        let tempTaskStr = '';
        if (taskId.length > 0) {
            for (let i = 0; i < taskId.length; i++) {
                tempTaskStr += taskId[i]['name'] + ',';
            }

            tempTaskStr = tempTaskStr.substring(0, tempTaskStr.length - 1)
        }

        let tempRecurStr = '';
        if(recurrence.length >0){
            for (let i = 0; i < recurrence.length; i++) {
                tempRecurStr += recurrence[i]['id'] + ',';
            }
            tempRecurStr = tempRecurStr.substring(0, tempRecurStr.length - 1)

        }
        setLoading(true);
        api.getTaskMonitorList(currentPerPage, curPage, 1, tempTaskStr, tempRecurStr).then((result: any) => {

            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }

            if (result.data) {

                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    let tblList = [];


                    for (let i = 0; i < dataList.length; i++) {
                        let tempEle = dataList[i];
                        if (dataList[i]['tss_recurr_time']) {
                            let eleRecurTime = JSON.parse(dataList[i]['tss_recurr_time']);
                            tempEle['recurring'] = generateTypeDescription(eleRecurTime['type'], eleRecurTime['repeat'], eleRecurTime['time'], eleRecurTime['days'])
                        }
                        if (dataList[i]['tss_depend']) {
                            let tempEleDepends = JSON.parse(dataList[i]['tss_depend']);
                            let tempDepend = "";

                            for (let j = 0; j < tempEleDepends.length; j++) {
                                tempDepend += tempEleDepends[j] + "\n";
                            }
                            tempEle['depend'] = tempDepend;
                        }

                        if(dataList[i]['tss_start']){
                            tempEle['tss_start'] = dataList[i]['tss_start'].substring(0,tempEle['tss_start'].length-1);
                        }
                        if(dataList[i]['tss_end']){
                            tempEle['tss_end'] = dataList[i]['tss_end'].substring(0,tempEle['tss_end'].length-1);
                        }
                        tempEle['detail_last'] = "";
                        if(dataList[i]['tss_detail']){
                            let tempDetail = JSON.parse(dataList[i]['tss_detail']);
                            if(tempDetail.length >0){
                                tempEle['detail_last'] = tempDetail[tempDetail.length-1];
                            }
                            tempEle['detail_list'] = tempDetail;

                        }



                        tblList.push(tempEle);
                    }

                    setTableData(tblList);

                }else{
                    handlePopup(true,"Error","No results found",popupManager)
                    setTableData([]);
                    setTotalRecords(0);
                    setLoading(false);
                }


            }
            setLoading(false);

        });


    };
    const handleOnclick = async (row) => {
        await setLoading(true);
        // navigate("/" + process.env.REACT_APP_URL_PREFIX + "task_schedular_opr", {
        //     state: {
        //         row: row,
        //         perm: actualPerm,
        //         mode: 'Edit'
        //     }
        // });
        setLoading(false);
    };
    const handleAddBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "task_schedular_opr", {
            state: {
                row: "",
                perm: actualPerm,
                mode: 'Add'
            }
        });
        setLoading(false);
    };
    const handleResetBtn = async () => {
        await setLoading(true);
        setTaskId([]);
        setRecurrence([]);
        setLoading(false);
    };
    const convertRawIntoDropdown = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                name: data[i]['tss_name'],
                id: data[i]['tss_name']
            });
        }
        return returnDat;
    }

    const handleChange = ({selectedRows}) => {
        setSelectedRows(selectedRows);
    };

    function generateTypeDescription(recurrType, recurrRepeat, recurrTime, tssRecurrTimeNV) {
        let typeDescription = '';

        if (recurrType === 0) {
            // Repeat by day
            typeDescription = `Every day ${recurrTime}`;
        } else if (recurrType === 1) {
            // Repeat by week
            typeDescription = `Every ${recurrRepeat} week(s) on `;
            let recurrDays = JSON.parse(tssRecurrTimeNV).days;

            let daysOfWeek = '';
            for (let dayPosition = 0; dayPosition < 7; dayPosition++) {
                // Check if the day is included
                if ((recurrDays & Math.pow(2, dayPosition)) > 0) {
                    // Add day to the list
                    daysOfWeek += (daysOfWeek.length > 0 ? ', ' : '') +
                        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayPosition] + ['\n'];
                }
            }

            if (recurrDays === 127) {
                daysOfWeek = 'everyday';
            }

            typeDescription += `${daysOfWeek} ${recurrTime}`;
        } else if (recurrType === 2) {
            // Repeat by month
            typeDescription = `Every ${recurrRepeat} month(s) on `;

            switch (recurrRepeat) {
                case 1:
                    typeDescription += '1st day';
                    break;
                case 99:
                    typeDescription += 'last day';
                    break;
                default:
                    typeDescription += `${recurrRepeat}th day`;
            }
        }

        return typeDescription;
    }
    const columns = useMemo(
        () => [
            {
                id: row => row.tss_id,
                name: 'Task Id',
                selector: row => row.tss_id,
                sortable: false,
                // center: true,
                cell: (row: { tss_id: any }) => (
                    <>
                        <a className="tableItemsHlink" onClick={() => handleOnclick(row)}>{row.tss_id}</a>
                    </>
                )
            },
            {
                name: 'Descriptions',
                selector: row => row.tss_desc,
                sortable: false,
                wrap:true,
            }
            ,
            {
                name: 'Actual Start Time',
                cell: (row: { detail_last: any }) => (
                    <>
                        {!moment(row.detail_last.tssl_start, 'YYYY-MM-DDThh:mm:ss').isValid() ? "-" : dayjs(row.detail_last.tssl_start).format(dateTimeFormatTaskSchedule())}

                    </>
                ),
                sortable: false
            },
            {
                name: 'Actual End Time',
                cell: (row: { detail_last: any }) => (
                    <>
                        {!moment(row.detail_last.tssl_end, 'YYYY-MM-DDThh:mm:ss').isValid() ? "-" : dayjs(row.detail_last.tssl_end).format(dateTimeFormatTaskSchedule())}

                    </>
                ),
                sortable: false
            },
            {
                name: 'Duration',
                cell: (row: { tss_duration: any }) => (
                    <>{row.tss_duration == "H:M:S" ? "0H:0M:0S" : row.tss_duration}</>

                ),
                sortable: false
            },
            {
                name: 'Status',
                cell: (row: { detail_last: any }) => (
                    <>{row.detail_last.tssl_status ? (row.detail_last.tssl_status).toUpperCase()  : "-"}</>

                ),
                sortable: false
            }
        ],
        []
    );

    const onTaskIdHandle = (selectedList, selectedItem) => {
        setTaskId(selectedList);
        if (selectedList.length == 0) setTaskIdList([]);
    }
    const onRecurranceHandle = (selectedList, selectedItem) => {
        setRecurrence(selectedList);
        if (selectedList.length == 0) setRecurrenceList([]);
    }
    const handleTableStart = async () => {

        let selectedItem = selectedRows['selectedRows'];

        handlePopup(true, "Error", "Function under construction", popupManager)
    }

    const handleTableStop = async () => {
        let selectedItem = selectedRows['selectedRows'];
        handlePopup(true, "Error", "Function under construction", popupManager)

    }

    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Task
                                Maintenance &gt; Task Monitor - Search</h5>
                            <div className="dropdown no-arrow">
                            </div>

                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Task ID</div>
                                    <div className=" col-md-11">
                                        <Multiselect
                                            options={taskIdList} // Options to display in the dropdown
                                            selectedValues={taskId} // Preselected value to persist in dropdown
                                            onSelect={onTaskIdHandle} // Function will trigger on select event
                                            onRemove={onTaskIdHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            placeholder="Task ID"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>

                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Recurrence</div>
                                    <div className=" col-md-11">
                                        <Multiselect
                                            options={recurrenceList} // Options to display in the dropdown
                                            selectedValues={recurrence} // Preselected value to persist in dropdown
                                            onSelect={onRecurranceHandle} // Function will trigger on select event
                                            onRemove={onRecurranceHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            placeholder="Recurrence"
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>

                                </div>
                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary" id="btn-search-main"
                                                onClick={() => handleInitList(true)}>Search
                                        </button>
                                        <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                        </button>
                                        {/*{actualPerm['add'] ? <button className="btn btn-primary "*/}
                                        {/*                             onClick={() => handleAddBtn()}>Add</button> : ""}*/}
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    perPageQty={setCurrentPerPage}
                                    handleSelect={setSelectedRows}
                                    columns={columns}
                                    data={tableData}
                                    clearRows={toggledClearRows}
                                    expandableRowsComponent={"TaskSchedular"}
                                    pagination={{
                                        currentPage: currentPage,
                                        currentPerPage: currentPerPage,
                                        totalRecords: totalRecords,
                                        selectable: actualPerm['edit'] ? true : false,
                                        showAllSelBool: true,
                                        selectableHandle: {handleChange},
                                    }}
                                />

                            </div>
                            {actualPerm['edit'] ?
                                <>
                                    <button className="btn btn-primary " onClick={() => handleTableStart()}>Start
                                    </button>

                                    <button className="btn btn-danger checkboxMarginLeft"
                                            onClick={() => handleTableStop()}>Stop
                                    </button>
                                </>


                                : ""}
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
