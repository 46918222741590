import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    convertErMsg,
    convertFuncPerm, dateFormats,
    dealerStatusOptions, getDefaultAccessMat, getNavIdByPath,
    handleCountryChange,
    handlePopup,
    handleSelectChange, specialCharCheck, useLocalStorage
} from "../../../general/general-tools";
import Multiselect from "multiselect-react-dropdown";
import { usePopupManager } from "react-popup-manager";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";
import dayjs from "dayjs";
import {getDealerGroupListDealerAssoc} from "../../../../apis/models/DealerGroup";


const Index = () => {
    const {setLoading} = useLoading();

    const [tableData, setTableData] = useLocalStorage('dga_TblDat',null);
    const [totalRecords, setTotalRecords] = useLocalStorage('dga_totRec',0);
    const [currentPage, setCurrentPage] = useLocalStorage('dga_curPg',1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage('dga_curperPg',10);
    const [searchInput, setSearchInput] = useLocalStorage('dga_searchinput',"");
    const [searchGroupName, setSearchGroupName] = useLocalStorage('dga_searchgrpname',"");
    const [searchBranch, setSearchBranch] = useLocalStorage('dga_branchsearch',"");
    const [branchList, setBranchList] = useState([]);
    const isInitialMount = useRef(true);

    const [dealerList, setDealerList] = useLocalStorage('dga_DealerTblDat',[]);
    const [selectedDealer, setSelectedDealer] = useLocalStorage('dga_selDealer',[]);


    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat():getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);


    useLayoutEffect(() => {

        window.scrollTo({top: 0, behavior: 'smooth'});
        //TODO :  Handle Associate list auto search on load

        console.log(tableData)
        if (tableData) {
            handleInitList()
        }else{
            handleResetBtn();
        }
        handleBranchList();
        handleDealerList();

    },[])


    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleInitList();
        }

    }, [currentPage, currentPerPage]);

    const handleDealerList = async (searchInput = null) => {
        setLoading(true);

        let searchTermStr = "";
        if(searchInput){
            for (let i = 0; i < searchInput.length; i++) {
                searchTermStr += searchInput[i]['name']+","
            }
            searchTermStr= searchTermStr.substring(0,searchTermStr.length-1)

        }

        api.getDealerList(100, 1, searchTermStr).then((result: any) => {

            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdownDealer(dataList);
                    setDealerList(TreeData);

                }
            }
        });
        setLoading(false);
    };

    const convertRawIntoDropdownDealer = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {

            returnDat.push({
                code: data[i]['d_id'],
                name: data[i]['d_code'],
                id: data[i]['d_id']
            });
        }
        return returnDat;
    }

    const handleDealerSearch = (selectedList, selectedItem) => {
      setSelectedDealer(selectedList)
        if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur();

    }
    const handleDealerRemove = (selectedList) => {
        setSelectedDealer(selectedList)

    }


    const onHandleDealerSearch= (searchItem) =>{
        if(searchItem.length > 2) handleDealerList(searchItem);
        else if (searchItem.length == 0)handleDealerList(searchItem);
    }
    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {
        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }
    useEnterKeyPress(handleEnterKeyPress);
    const handleSearch = async (newSearch = false) => {
        if (!searchInput.length > 2) {
            handlePopup(true,"Error","Search term require at least 2 characters.",popupManager)
        } else {
            handleInitList(newSearch)
        }
    }

    const handleBranchList = async () => {
        api.getBranchList(999, 1, 1, null).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if(result.data){
                if (result.data.list.length > 0) {
                    setBranchList(result.data.list);
                }
            }

        });
    };


    //Data Load
    useLayoutEffect(() => {
    }, []);

    const handleInitList = async (newSearch = false) => {
        setLoading(true);
        let curPage = currentPage;
           if (newSearch) {
            setCurrentPage(1)
            curPage= 1;
        }

        let param = {
            // status: status == '' ? null : status ,
            size: currentPerPage,
            page: curPage,
            dcode: selectedDealer.length > 0 ? selectedDealer[0]['name'] : null,
            dterm: searchInput == '' ? null : searchInput,
            bterm: searchBranch == '' ? null : searchBranch,
            term: searchGroupName == '' ? null : searchGroupName,
        };


        api.getDealerGroupListDealerAssoc(param ).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

            if (result.status == 0) {
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    const timeout = setTimeout(() => {
                        for (let i = 0; i < dataList.length; i++) {
                            if (dataList[i].dg_status == 0) dataList[i]['dg_status_disp'] = "Active";
                            else if (dataList[i].dg_status == 1) dataList[i]['dg_status_disp'] = "Pending"
                            else if (dataList[i].dg_status == 2) dataList[i]['dg_status_disp'] = "Expired"
                        }

                        setTableData(dataList);
                        let totalPage = parseInt(result.data.totalrow)
                        setTotalRecords(totalPage);
                    }, 10);
                    return () => clearTimeout(timeout);
                }else{
                    handlePopup(true,"Error","No results found",popupManager)
                    setTableData([]);
                    setTotalRecords(0);
                }
            } else {
                if (result.message) {
                    handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                } else {
                    handlePopup(true,"Error","No results found",popupManager)
                }


                setTableData([]);
                setTotalRecords(10);
            }
        });

        setLoading(false);

    };



    const handleOnclick = async (row) => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "dealer_gpas_opr", {state: {row: row, perm:actualPerm,mode: 'Edit'}});
        setLoading(false);
    };
    const handleAddBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "dealer_gpas_opr", {state: {row: "", perm:actualPerm,mode: 'Add'}});
        setLoading(false);
    };
    const handleResetBtn = async () => {
        await setLoading(true);
        setSearchInput("");
        setBranchList([]);
        setDealerList([]);
        setSelectedDealer([]);
        setSearchBranch("");
        setSearchGroupName("");
        setCurrentPage(1);
        setCurrentPerPage(10);
        setTableData([]);
        setTotalRecords(0);
        handleBranchList();
        handleDealerList();

        setLoading(false);
    };

    const columns = useMemo(
        () => [
            {
                id: row => row.dg_date,
                name: 'Group Name',
                selector: row => row.dg_name,
                sortable: true,
                // center: true,
                cell: (row: { dg_name: any }) => (
                    <>
                        <a className="tableItemsHlink" onClick={() => handleOnclick(row)}>{row.dg_name}</a>
                    </>
                )
            },
            {
                name: 'Effective From',
                selector: row => dayjs(row.dg_date_fr).format(dateFormats()) == 'Invalid Date' ? "-" : dayjs(row.dg_date_fr).format(dateFormats()),
                sortable: true,

            },
            {
                name: 'Effective To',
                selector: row => dayjs(row.dg_date_to).format(dateFormats()) == 'Invalid Date' ? "-" : dayjs(row.dg_date_to).format(dateFormats()),
                sortable: true,

            },
            {
                name: 'Expired Date',
                selector: row => "N/A",
                sortable: true,

            }
        ],
        []
    );


    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; User Maintenance &gt; Dealer Group Association - Search</h5>


                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Dealer Code</div>
                                    <div className=" col-md-5">
                                        <Multiselect
                                            options={dealerList} // Options to display in the dropdown
                                            selectedValues={selectedDealer} // Preselected value to persist in dropdown
                                            onSelect={handleDealerSearch} // Function will trigger on select event
                                            onRemove={handleDealerRemove} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={false}
                                            placeholder="Dealer Code"
                                                onSearch={onHandleDealerSearch}
                                            avoidHighlightFirstOption={true}
                                            singleSelect={false}
                                            selectionLimit={1}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />

                                    </div>
                                    <div className="label col-md-1">Group Name</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control "
                                            autoComplete='off'

                                            type="text"
                                            placeholder="Search by Group Name "
                                            aria-label="Search Input"
                                            value={searchGroupName}
                                            onChange={(e) => setSearchGroupName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setSearchGroupName,popupManager)}

                                        />
                                    </div>

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Dealer Name</div>
                                    <div className="label col-md-5">
                                        <input
                                            className="form-control "
                                            autoComplete='off'

                                            type="text"
                                            placeholder="Search by Dealer Name "
                                            aria-label="Search Input"
                                            value={searchInput}
                                            onChange={(e) => setSearchInput(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setSearchInput,popupManager)}

                                        />
                                    </div>
                                    <div className="label col-md-1">Branch</div>
                                    <div className="label col-md-5">
                                        <select
                                            className="form-control"
                                            aria-label=""
                                            value={searchBranch}
                                            onChange={(e) => handleSelectChange(e.target.value,setSearchBranch,false)}
                                        >
                                            <option value="">Please select Branch</option>
                                            {branchList.map(el => <option
                                                value={el.bb_id}>{el.bb_id + " | "+el.bb_name}</option>)}
                                        </select>
                                    </div>

                                </div>

                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary "  id="btn-search-main" onClick={() => handleSearch(true)}>Search
                                        </button>
                                        <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                        </button>
                                        {/*{actualPerm['add'] ? <button className="btn btn-primary " onClick={() => handleAddBtn()}>Add</button> :""}*/}

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    perPageQty={setCurrentPerPage}
                                    columns={columns}
                                    data={tableData}
                                    pagination={{
                                        currentPage: currentPage,
                                        currentPerPage: currentPerPage,
                                        totalRecords: totalRecords,
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
