import {handleRequestGet, handleRequestPost} from '../axios';

export const getBranchList = async (size, page, status, term) => {
    let url = '/branch';

    let param = {
        size: size,
        page: page
    };
    if(term != "") param['term'] = term;

    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const addBranch = async (data) => {
    let url = '/branch/create';
    let result = await handleRequestPost(url, {
        "code" : data['code'],
        "name" : data['name'] == '' ? null : data['name'],
        "phone": data['phone'] == '' ? null : data['phone'],
        "address1" : data['address1'] == '' ? null : data['address1'],
        "address2" : data['address2'] == '' ? null : data['address2'],
        "address3" : data['address3'] == '' ? null : data['address3'],
        "postcode" : data['postcode'] == '' ? null : data['postcode'],
        "state" : data['state'] == '' ? null : data['state'],
        "country" : data['country'] == '' ? null : data['country'],
        "company" : data['company'] == '' ? null : data['company']
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateBranch = async (data) => {
    let url = '/branch/update';
    let result = await handleRequestPost(url, {
        "id" : data['id'],
        "code" : data['code'],
        "name" : data['name'] == '' ? null : data['name'],
        "phone": data['phone'] == '' ? null : data['phone'],
        "address1" : data['address1'] == '' ? null : data['address1'],
        "address2" : data['address2'] == '' ? null : data['address2'],
        "address3" : data['address3'] == '' ? null : data['address3'],
        "postcode" : data['postcode'] == '' ? null : data['postcode'],
        "state" : data['state'] == '' ? null : data['state'],
        "country" : data['country'] == '' ? null : data['country'],
        "company" : data['company'] == '' ? null : data['company']
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const deleteBranch = async (id) => {
    let url = '/branch/delete';
    let result = await handleRequestPost(url, {
        id: id
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
