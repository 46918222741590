import React, {useState, useEffect, useMemo, useRef, useCallback} from 'react';
import '../../../../asset/styles/customer-profile-style.css';
import api from '../../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    convertPositiveNumberHundred,
    handlePositiveNumberChange,
    handlePositiveNumberHundredChange,
    handleSelectChange
} from "../../../../general/general-tools";
import {handleToggleChildControl} from "../../UserAccessMat/UserAccessMatTools";



const TradeType = React.memo(props => {
    let IDSSChecked = props['IDSSChecked'] == null ? '' : props['IDSSChecked'];
    let setIDSSChecked = props['setIDSSChecked'] == null ? '' : props['setIDSSChecked'];
    let PDTChecked = props['PDTChecked'] == null ? '' : props['PDTChecked'];
    let setPDTChecked = props['setPDTChecked'] == null ? '' : props['setPDTChecked'];
    let PSSChecked = props['PSSChecked'] == null ? '' : props['PSSChecked'];
    let setPSSChecked = props['setPSSChecked'] == null ? '' : props['setPSSChecked'];
    let RSSChecked = props['RSSChecked'] == null ? '' : props['RSSChecked'];
    let setRSSChecked = props['setRSSChecked'] == null ? '' : props['setRSSChecked'];

    let IDSSReadOnly = props['IDSSReadOnly'] == null ? '' : props['IDSSReadOnly'];
    let PDTReadOnly = props['PDTReadOnly'] == null ? '' : props['PDTReadOnly'];
    let PSSeadOnly = props['PSSeadOnly'] == null ? '' : props['PSSeadOnly'];
    let RSSeadOnly = props['RSSeadOnly'] == null ? '' : props['RSSeadOnly'];
    let popupManager = props['popupManager']== null ? '' : props['popupManager'];


    return  <>

        <div className="input-container col-md-12 fullWidthSearch exp-input-parent-single">
            <b>Trade Type Allow (Bursa Only)</b>
        </div>

        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-1">IDSS</div>
            <div className="label col-md-1">
                <input
                className='checkboxHuge'
                type='checkbox'
                disabled={IDSSReadOnly}
                checked={IDSSReadOnly == true ? false : IDSSChecked}
                onChange={e => setIDSSChecked(e.target.checked)}
                />
            </div>
            <div className="label col-md-1">PDT</div>
            <div className="label col-md-1">
                <input
                    className='checkboxHuge'
                    type='checkbox'
                    disabled={PDTReadOnly}
                    checked={PDTChecked}
                    onChange={e => setPDTChecked(e.target.checked)}
                />
            </div>
        </div>
        <div className="input-container col-md-12 fullWidthSearch">
            <div className="label col-md-1">PSS</div>
            <div className="label col-md-1">
                <input
                    className='checkboxHuge'
                    type='checkbox'
                    disabled={PSSeadOnly}
                    checked={PSSeadOnly == true ? false : PSSChecked}
                    onChange={e => setPSSChecked(e.target.checked)}
                />
            </div>
            <div className="label col-md-1">RSS</div>
            <div className="label col-md-1">
                <input
                    className='checkboxHuge'
                    type='checkbox'
                    disabled={RSSeadOnly}
                    checked={RSSeadOnly == true ? false : RSSChecked}
                    onChange={e => setRSSChecked(e.target.checked)}
                />
            </div>
        </div>

    </>
});
export default TradeType;
