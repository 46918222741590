import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';


import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    convertFuncPerm, dateFormats,
    dealerStatusOptions,
    getDefaultAccessMat, getNavIdByPath,
    handlePopup,
    handleSelectChange, momentTimeFormat,
    useLocalStorage, convertErMsg, specialCharCheck, performAntdTimeCheck, getNavItemById
} from "../../../general/general-tools";
import {getAuditPdfStyling} from "../../../general/pdf-tools"
import {usePopupManager} from "react-popup-manager";
import {useCookies} from 'react-cookie';

import {getUserAccessDetail} from "../../../../hooks/handleCookie";
import ReactDatepick from "../../../general/form-control/react-datepick";
import dayjs from "dayjs";
import Multiselect from "multiselect-react-dropdown";
import {getAuditOprList, getAuditVarList} from "../../../../apis/models/Audit";
import ReactTooltip from "react-tooltip-rc";
import Pdfexport from "../../../general/form-control/pdfexport";
import {
    Document,
    Page,
    PDFDownloadLink,
    PDFViewer,
    StyleSheet,
    Text,
    View,
    Image,
    Line,
    Font
} from "@react-pdf/renderer";
import FontFaRegular400 from "../../../asset/font-awesome/webfonts/NotoSans-Medium.ttf";
import FontFaRSolid900 from "../../../asset/font-awesome/webfonts/NotoSans-Bold.ttf";
import companyLogo from "../../../asset/pdfheader/header.png";


const Index = () => {
        const {setLoading} = useLoading();
        const [cookies, setCookie, removeCookie] = useCookies(['admin_user']);

        const [tableData, setTableData] = useLocalStorage("mst_adt_tblDat", []);
        const [totalRecords, setTotalRecords] = useLocalStorage("mst_adt_totRec", 0);
        const [currentPage, setCurrentPage] = useLocalStorage("mst_adt_curPg", 1);
        const [currentPerPage, setCurrentPerPage] = useLocalStorage("mst_adt_curPrPg", 10);

        const [searchMenuId, setSearchMenuId] = useLocalStorage("mst_adt_menuId", []);
        const [searchMenuIdList, setSearchMenuIdList] = useLocalStorage("mst_adt_menuIdList", []);

        const [searchUserId, setSearchUserId] = useLocalStorage("mst_adt_searchUserId", []);
        const [searchUserIdList, setSearchUserIdList] = useLocalStorage("mst_adt_searchUserIdList", []);

        const [searchEffFrom, setSearchEffFrom] = useLocalStorage("mst_adt_searchEffFrom", null);
        const [searchEffTo, setSearchEffTo] = useLocalStorage("mst_adt_searchEffFrom", null);

        let navigate = useNavigate();
        const location = useLocation();
        const isInitialMount = useRef(true);
        const popupManager = usePopupManager();
        let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat() : getUserAccessDetail();
        // let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);
        const [toggleReset, setToggleReset] = useState(false);
        const [isDateValid, setIsDateValid] = useState(false);

        //PDF use
        const [currentCompanyCode, setCurrentCompanyCode] = useState("");
        const [currentBranchCode, setCurrentBranchCode] = useState("");
        const [currentCompanyName, setCurrentCompanyName] = useState("");

        const [currentCompanyAddress1, setCurentBranchAddress1] = useState("");
        const [currentCompanyAddress2, setCurentBranchAddress2] = useState("");
        const [currentCompanyAddress3, setCurentBranchAddress3] = useState("");
        const [currentCompanyPostCode, setCurrentCompanyPostCode] = useState("");
        const [currentCompanyState, setCurrentCompanyState] = useState("");
        const [currentCompanyCountry, setCurrentCompanyCountry] = useState("");
        const [currentCompanyCountryText, setCurrentCompanyCountryText] = useState("");
        const [currentCompanyPhoneNum, setCurrentCompanyPhoneNum] = useState("");
        const [currentCompanyWebsite, setCurrentCompanyWebsite] = useState("");
        const [auditLogName, setAuditLogName] = useState("auditlog_" + moment().format("DDMMYYYYhhmmss"));
        const [userName, setUserName] = useState('');

        const linkRef = useRef(null)
        const multiselectRef = useRef();

        const resetSelectField = () => {
            multiselectRef.current.resetSelectedValues();
        };


        useEffect(() => {
            performAntdTimeCheck(searchEffFrom, searchEffTo, setSearchEffTo, popupManager, setIsDateValid);
        }, [searchEffFrom, searchEffTo]);

        useLayoutEffect(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
            renderHeader();
            handleMenuList();
            handleUserList();
            setUserName(cookies.admin_user.username);
            if (tableData.length > 0) {
                // handleInitList(searchStatus, searchInput, searchEffFrom, searchEffTo)
            } else {
                handleResetBtn();
            }
        }, [])


        useEffect(() => {
            if (isInitialMount.current) {
                isInitialMount.current = false;
            } else {
                handleInitList()
            }

        }, [currentPage, currentPerPage]);

        const handleSearch = async (newSearch = false) => {
            handleInitList(newSearch)
        }

        const handleMenuList = async () => {
            let accessLvl = cookies.access_level;
            let list = convertMenuListIntoDropdown(accessLvl)
            setSearchMenuIdList(list);
        }

        const handleUserList = async () => {
            api.getAuditOprList().then((result: any) => {
                if (result.status == 1) {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    setLoading(false);
                }
                if (result.data) {
                    if (result.data.list.length > 0) {
                        let list = convertUserListIntoDropdown(result.data.list);
                        setSearchUserIdList(list);
                    }
                }

            });
        }

        function useEnterKeyPress(callback) {
            useEffect(() => {
                function handleKeyPress(event) {
                    if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                        callback();
                    }
                }

                document.addEventListener('keydown', handleKeyPress);

                return () => {
                    document.removeEventListener('keydown', handleKeyPress);
                };
            }, [callback]);
        }

        function handleEnterKeyPress() {
            const searchButton = document.getElementById('btn-search-main');
            if (searchButton) {
                searchButton.click();
            }
        }

        useEnterKeyPress(handleEnterKeyPress);

        const convertMenuListIntoDropdown = (data) => {
            var returnDat = [];

            Object.entries(data).forEach(([key, value]) => {

                if (value) {
                    if (value[0] && value[0] == "1") {
                        let item = getNavItemById(key);


                        if (item['parent'] && !(key >= 39 && key <= 54)) {
                            returnDat.push({
                                parent: item['parent'],
                                name: item['actualLbl'],
                                id: key
                            });

                        } else if (key == 38) {
                            item = getNavItemById(38);
                            returnDat.push({
                                parent: "Enquiries",
                                name: "View",
                                id: 38
                            });
                        }

                    }
                }

            });

            return returnDat;
        }

        const convertUserListIntoDropdown = (data) => {
            var returnDat = [];
            for (let i = 0; i < data.length; i++) {
                returnDat.push({
                    name: data[i]['username'],
                    id: data[i]['id']
                });
            }


            return returnDat;
        }
        const handleInitList = async (newSearch = false) => {
            setLoading(true);

            let fromDate = dayjs(searchEffFrom).format(momentTimeFormat());
            let toDate = dayjs(searchEffTo).format(momentTimeFormat());
            let curPage = currentPage;
            if (newSearch) {
                setCurrentPage(1)
                curPage = 1;
            }
            if (fromDate != "Invalid Date" && toDate != "Invalid Date") {
                if (!isDateValid) {
                    handlePopup(true, "Error", "Please ensure that the ending date is not earlier than the starting date.", popupManager);
                    await setLoading(false);
                    return false;
                }
            }

            let menuIdStr = null;
            if (searchUserId.length == 0 && searchMenuId.length == 0 && fromDate == "Invalid Date" && toDate == "Invalid Date") {
                handlePopup(true, "Error", "Please select at least one (1) search criteria.", popupManager);
                await setLoading(false);
                return false;
            }

            let usrIdStr = "";
            for (let i = 0; i < searchUserId.length; i++) {
                usrIdStr += searchUserId[i]['id'] + ",";
            }
            usrIdStr = usrIdStr.substring(0, usrIdStr.length - 1)

            if (searchMenuId[0]) menuIdStr = searchMenuId[0]['id'];
            var data = {
                menu: menuIdStr,
                userid: usrIdStr == "" ? null : usrIdStr,
                date_fr: (fromDate == "Invalid Date" ? null : fromDate),
                date_to: (toDate == "Invalid Date" ? null : toDate),
                size: currentPerPage,
                page: curPage
            }

            api.getAuditVarList(data).then((result: any) => {

                if (result.status == 1) {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    setLoading(false);
                    return false;
                }

                if (result.status == 0) {
                    if (result.data.list.length > 0) {


                        //Check resolution
                        let windowWidth = window.screen.width;
                        let dataList = result.data.list;

                        // for (let i = 0; i < dataList.length; i++) {
                        for (let i = 0; i < dataList.length; i++) {
                            const details = JSON.parse(dataList[i]['details']);
                            if (!details) {
                                continue;
                            }
                            const tabIndex = details.findIndex(element => element.variable === "Tab");
                            const titleIndex = details.findIndex(element => element.variable === "Title");
                            if (tabIndex !== -1) {
                                const tabElement = details.splice(tabIndex, 1)[0];
                                details.splice(0, 0, tabElement);
                            }

                            if (titleIndex !== -1) {
                                const tabElement = details.splice(titleIndex, 1)[0];
                                details.splice(tabIndex+1, 0, tabElement);
                            }


                            const key_name = dataList[i]['key'];
                            const key_sub = dataList[i]['sub'];

                            const variableArray = [];
                            const beforeArray = [];
                            const afterArray = [];
                            if (key_name) {
                                variableArray.push("Key (" + key_sub+")\n");
                                beforeArray.push(key_name + "\n");
                                afterArray.push(key_name + "\n");
                            }


                            if (dataList[i]['details']) {
                                for (let j = 0; j < details.length; j++) {
                                    const item = details[j];
                                    let mode = 0;
                                    const countVar = (item.variable ?? "").split('\\n').length;

                                    let itemBefore = (item.before ?? "");
                                    let itemAfter = (item.after ?? "");

                                    let countBef = (item.before ?? "").split('\\n').length;
                                    let countAft = (item.after ?? "").split('\\n').length;

                                    let processVar = replaceNewLines(item.variable, mode, windowWidth);
                                    let processBef = replaceNewLines(itemBefore, mode, windowWidth);
                                    let processAft = replaceNewLines(itemAfter, mode, windowWidth);

                                    let processBefOffset =processBef.split('⏎').length;
                                    let processAftOffset =processAft.split('⏎').length;

                                    countBef += processBefOffset -1;
                                    countAft += processAftOffset -1;
                                    processVar = processVar.replaceAll('⏎','\n')
                                    processBef = processBef.replaceAll('⏎','\n')
                                    processAft = processAft.replaceAll('⏎','\n')


                                    variableArray.push(processVar + "\n");
                                    beforeArray.push(processBef + "\n");
                                    afterArray.push(processAft + "\n");

                                    let offsetObj = beforeArray;
                                    let offsetLgth = countBef;
                                    if (countAft < countBef) {
                                        offsetLgth = countAft;
                                        offsetObj = afterArray;
                                    }
                                    let maxSpacing = (countAft > countBef ? countAft : countBef);
                                    let maxSpacingVar = (countAft > countBef ? countAft : countBef);
                                    maxSpacing = maxSpacing - offsetLgth + 1;

                                    let addingOffset = 1;
                                    if (maxSpacing > 1 && j != details.length - 1) {
                                        for (let k = 1; k < maxSpacingVar; k++) {
                                            variableArray.splice(j + 1 + maxSpacingVar, 0, "\n");
                                        }

                                        for (let k = 1; k < maxSpacing; k++) {
                                            offsetObj.splice(j + 1 + addingOffset, 0, "\n");
                                            addingOffset = j + 1 + addingOffset;
                                        }

                                    }
                                }
                            }

                            dataList[i]['var'] = variableArray;
                            dataList[i]['bf'] = beforeArray;
                            dataList[i]['af'] = afterArray;
                        }

                        setTableData(dataList);

                        let totalPage = parseInt(result.data.totalrow)
                        setTotalRecords(totalPage);
                    } else {
                        handlePopup(true, "Error", "No results found", popupManager)
                        setTableData([]);
                        setTotalRecords(10);
                        setLoading(false);
                    }
                } else {
                    if (result.message) {
                        setLoading(false);
                        handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    } else {
                        handlePopup(true, "Error", "No results found", popupManager)
                    }
                    setTableData([]);
                    setTotalRecords(10);
                }
                setLoading(false);

            });
        };


    function replaceNewLines(input, windowWidth) {
        let lines = [];

        // Split the input string into chunks based on the maximum length
        let maxLength = 20;
        if (windowWidth <= 1000) {
            maxLength = 50;
        } else if (windowWidth > 1001) {
            maxLength = 40;
        } else if (windowWidth > 1500) {
            maxLength = 20;
        }

        // Split the input string into chunks ensuring words are not split
        while (input.length > maxLength) {
            let chunk = input.substring(0, maxLength);
            let lastSpaceIndex = chunk.lastIndexOf(' ');
            if (lastSpaceIndex !== -1) {
                lines.push(chunk.substring(0, lastSpaceIndex));
                input = input.substring(lastSpaceIndex + 1);
            } else {
                lines.push(chunk);
                input = input.substring(maxLength);
            }
        }
        lines.push(input); // Push the remaining part or the whole input if its length is less than maxLength

        let result = '';

        // Append the lines with a line break
        for (let i = 0; i < lines.length; i++) {
            // Remove leading and trailing white space
            let line = lines[i].trim();
            result += line + '⏎';
        }

        // Remove the last line break indicator
        result = result.slice(0, -1);

        return result;
    }

    function insertNewlines(input, interval) {
        let result = '';
        for (let i = 0; i < input.length; i++) {
            result += input[i];
            if ((i + 1) % interval === 0 && i !== input.length - 1) {
                result += '\n';
            }
        }
        return result;
    }

    // function replaceNewLines(input, mode, windowWidth) {
        //     let lines = input.split('\\n');
        //     if (mode == 1) {
        //         lines = input.split(', ');
        //     }
        //
        //     let result = '';
        //
        //
        //     for (let i = 0; i < lines.length; i++) {
        //         // Remove leading and trailing white space
        //         let line = lines[i].trim();
        //
        //         if (windowWidth <= 1000) {
        //             if(line.length > 60){
        //                 line = line.substring(0,20) + "⏎" + line.substring(20,40) + "⏎" + line.substring(40,60)+ "⏎" + line.substring(60,line.length)
        //             }
        //             else if(line.length > 40){
        //                 line = line.substring(0,20) + "⏎" + line.substring(20,40) + "⏎" + line.substring(40,line.length)
        //             }
        //             else if(line.length > 20){
        //                 line = line.substring(0,20) + "⏎" + line.substring(20,line.length)
        //             }
        //
        //         } else if (windowWidth > 1001) {
        //             if(line.length > 60){
        //                 line = line.substring(0,30) + "⏎" + line.substring(30,60) + "⏎" + line.substring(60,line.length)
        //             }
        //             else if(line.length > 30){
        //                 line = line.substring(0,30) + "⏎" + line.substring(30,line.length)
        //             }
        //
        //         } else if (windowWidth > 1500) {
        //             line = line.substring(0,50) + "⏎" + line.substring(50,line.length)
        //
        //         }
        //
        //         result += line;
        //
        //         // Add a line break if we're not at the end of the lines array
        //         if (i < lines.length - 1) {
        //             result += '\n';
        //         }
        //     }
        //     return result;
        // }

        const handleResetBtn = async () => {
            await setLoading(true);
            setSearchMenuId([]);
            setSearchMenuIdList([]);
            setSearchUserIdList([]);
            setSearchUserId([]);
            setSearchEffFrom("");
            setSearchEffTo("");
            setToggleReset(!toggleReset)
            resetSelectField();
            handleMenuList();
            handleUserList();
            setLoading(false);
        };

        const columns = useMemo(
            () => [
                {
                    name: 'Info',
                    selector: row => row.created_time == "Invalid Date" ? "-" : row.created_time,
                    sortable: true,
                    wrap: true,
                    maxWidth: '15%',
                    cell: (row: { var: any }) => (
                        <>
                            <div>
                                <div><b>Date : </b><br/>{row.created_time}</div>
                                <br/>
                                <div><b>Action By : </b><br/>{row.created_by}</div>
                                <br/>
                                <div><b>Menu : </b><br/>{getNavItemById(row.menu_id)['label'] ?? ""}</div>
                                <br/>
                                <div><b>Action : </b><br/>{row.action}</div>
                                <br/>
                            </div>
                        </>
                    )

                }, {
                    name: 'Field',
                    selector: row => row.var,
                    sortable: true,
                    maxWidth: '25%',
                    width: "25%",
                    alignItems: 'top',
                    className: 'auditTbl',
                    cell: (row: { var: any }) => (
                        <>

                            <div className='auditTbl' style={{"white-space": "break-spaces"}}>

                                {row.var ?
                                    <div style={{'font-size': '1em'}}
                                    >{row.var}
                                    </div> : ''}

                            </div>

                        </>
                    ),
                    style: {overflow: "visible", display: "block"},
                    wrap: true
                }, {
                    name: 'Before',
                    maxWidth: '35%',
                    width: "auto",
                    // width: "fit-content",

                    selector: row => row.bf,
                    sortable: true,
                    className: 'auditTbl',

                    cell: (row: { var: any }) => (
                        <>

                            <div className='auditTbl' style={{"white-space": "break-spaces", 'align-items': 'baseline'}}>
                                <div style={{"white-space": "pre"}}>
                                    {row.bf ?
                                        <div style={{'font-size': '1em'}}
                                        >{row.bf}
                                        </div> : ''}

                                </div>
                            </div>

                        </>
                    ),
                    style: {overflow: "auto", display: "block"},
                    wrap: true
                }, {
                    name: 'After',
                    maxWidth: '35%',
                    width: "auto",
                    selector: row => row.af,
                    sortable: true,
                    className: 'auditTbl',
                    cell: (row: { var: any }) => (
                        <>
                            <div style={{"white-space": "break-spaces"}}>
                                <div style={{"white-space": "pre"}}>
                                    {row.af ?
                                        <div style={{'font-size': '1em'}}
                                        >{row.af}
                                        </div> : ''}
                                </div>
                            </div>

                        </>
                    ),
                    style: {overflow: "auto", display: "block"},

                    wrap: true
                }
                //
                // style: {
                //     'overflow-y': "hidden", display: "block", 'max-width': '40em'},

                // }
            ],
            []
        );
        const onMenuSelect = (selectedList, selectedItem) => {
            setSearchMenuId([selectedItem]);
            if (document.activeElement instanceof HTMLElement)
                document.activeElement.blur();

        }
        const onMenuRemove = (selectedList, selectedItem) => {
            setSearchMenuId(selectedList);
        }

        const onUserSelect = (selectedList, selectedItem) => {
            setSearchUserId(selectedList);

        }
        const onUserRemove = (selectedList, selectedItem) => {
            setSearchUserId(selectedList);


        }

        //PDF Printing Function
        const styles = getAuditPdfStyling();

        Font.register({
            family: 'Regular',
            fonts: [
                {
                    src: FontFaRegular400,
                },
                {
                    src: FontFaRSolid900,
                    fontWeight: 'bold',
                }
            ],
        });


        const handleRerender = () => {

            let fromDate = dayjs(searchEffFrom).format(momentTimeFormat());
            let toDate = dayjs(searchEffTo).format(momentTimeFormat());
            let menuIdStr = "";
            let usrIdStr = "";

            for (let i = 0; i < searchUserId.length; i++) {
                usrIdStr += searchUserId[i]['name'] + ",";
            }
            usrIdStr = usrIdStr.substring(0, usrIdStr.length - 1)
            if (searchMenuId && searchMenuId[0]) menuIdStr = searchMenuId[0]['name'];
            if (usrIdStr.length == 0) usrIdStr = "-";
            if (menuIdStr.length == 0) menuIdStr = "-";
            return (

                <Page size="A4" style={styles.page} orientation={'portrait'}>

                    <View style={styles.headerDiv}>
                        <Image src={companyLogo} style={styles.image}/>
                        <View style={styles.headerTextRightDiv}>
                            <Text style={styles.headerTextBig}>{currentCompanyName}</Text>
                            <Text style={styles.headerTextMedium}>{currentCompanyAddress1}{'\n'}
                                {currentCompanyAddress2}{'\n'}
                                {(currentCompanyPostCode != "" ? currentCompanyPostCode + " ," : "")}{currentCompanyAddress3}</Text>
                            <Text style={styles.headerTextSmall}>Phone : {currentCompanyPhoneNum}{'\n'}
                                Website : {currentCompanyWebsite}</Text>
                        </View>

                    </View>


                    <View style={styles.bodyDiv}>
                        <View style={styles.table}>
                            <View style={[styles.specialRow, styles.header]}>
                                <Text style={[styles.specialChildRow0, styles.bold]}>Menu</Text>
                                <Text style={styles.specialChildRow}>: {menuIdStr}</Text>
                            </View>
                            <View style={[styles.specialRow, styles.header]}>
                                <Text style={[styles.specialChildRow0, styles.bold]}>User ID </Text>
                                <Text style={styles.specialChildRow}>: {usrIdStr}</Text>
                            </View>
                            <View style={[styles.specialRow, styles.header]}>
                                <Text style={[styles.specialChildRow0, styles.bold]}>Date from </Text>
                                <Text style={styles.specialChildRow}>: {fromDate == "Invalid Date" ? "-" : fromDate}</Text>
                            </View>
                            <View style={[styles.specialRow, styles.header]}>
                                <Text style={[styles.specialChildRow0, styles.bold]}>Date to </Text>
                                <Text style={styles.specialChildRow}>: {toDate == "Invalid Date" ? "-" : toDate}</Text>
                            </View>
                            <View style={[styles.specialRow, styles.header]}>
                                <Text style={[styles.specialChildRow0, styles.bold]}>Generated at </Text>
                                <Text style={styles.specialChildRow}>: {moment().format("DD/MM/YYYY hh:mm:ss a")}</Text>
                            </View>
                            <View style={[styles.specialRow, styles.header]}>
                                <Text style={[styles.specialChildRow0, styles.bold]}>Generated by </Text>
                                <Text style={styles.specialChildRow}>: {userName}</Text>
                            </View>
                        </View>
                        {'\n'}
                        <View style={styles.table}>
                            <View style={[styles.row, styles.bold, styles.header]}>
                                <Text style={styles.row0}>#</Text>
                                <Text style={styles.row1}>Info</Text>

                                <Text style={styles.row2 ?? []}>Field</Text>
                                <Text style={styles.row3}>Before</Text>
                                <Text style={styles.row4}>After</Text>
                            </View>

                            {tableData.map((row, i) => (


                                <View key={i} style={styles.row} wrap={true}>
                                    {'\n'}
                                    <Text style={styles.row0}>
                                        {i + 1}
                                    </Text>

                                    <Text style={styles.row1}>
                                        <Text style={styles.bold}>Date : </Text>
                                        {'\n'}
                                        <Text>{row.created_time}</Text>
                                        {'\n'}
                                        {'\n'}
                                        <Text style={styles.bold}>Action By : </Text>
                                        {'\n'}
                                        <Text>{row.created_by}</Text>
                                        {'\n'}
                                        {'\n'}
                                        <Text style={styles.bold}>Menu : </Text>
                                        {'\n'}
                                        <Text>{getNavItemById(row.menu_id)['label'] ?? ""}</Text>
                                        {'\n'}
                                        {'\n'}
                                        <Text style={styles.bold}>Action : </Text>
                                        {'\n'}
                                        <Text>{row.action}</Text>
                                        {'\n'}

                                    </Text>
                                    <Text style={styles.row2 ?? []}>
                                        {(row.var ?? []).map((rows, j) => (
                                            <Text>{rows}</Text>
                                        ))}
                                    </Text>
                                    <Text style={styles.row3}>
                                        {(row.bf ?? []).map((rows, j) => (
                                            // <Text>{rows.length > 50 ? rows.substring(0,29) + "\n" + rows.substring(30,rows.length-1) :rows}</Text>
                                            <Text>{rows}</Text>

                                        ))}

                                    </Text>
                                    <Text style={styles.row4}>
                                        {(row.af ?? []).map((rows, j) => (
                                            // <Text>{rows.length > 50 ? rows.substring(0,29) + "\n" + rows.substring(30,rows.length-1) :rows}</Text>

                                            <Text>{rows}</Text>
                                        ))}
                                    </Text>


                                </View>
                            ))}


                        </View>

                    </View>
                    <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (
                        `Page ${pageNumber} / ${totalPages}`
                    )} fixed/>
                </Page>


            )
        }

        const customTableStyles = {
            rows: {
                style: {},
            },
            cells: {
                style: {
                    alignItems: 'baseline',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                    borderBottomColor: '#343434',
                },
            },
        };

        const customStyles = {
            rows: {
                style: {
                    minHeight: '72px', // override the row height
                },
            },
            headCells: {
                style: {
                    paddingLeft: '8px', // override the cell padding for head cells
                    paddingRight: '8px',
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px', // override the cell padding for data cells
                    paddingRight: '8px',
                },
            },
        };
        const renderHeader = () => {
            api.getCompanyDetail().then((result: any) => {
                if (result.status == 1) {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    setLoading(false);
                    return false;
                }

                let companyEle = result.data.list[0];
                setCurrentCompanyCountryText(companyEle.co_name);
                setCurrentCompanyCode(companyEle.b_id)
                setCurrentBranchCode(companyEle.b_code);
                setCurrentCompanyName(companyEle.b_name);
                setCurentBranchAddress1(companyEle.b_address_line1 ?? "");
                setCurentBranchAddress2(companyEle.b_address_line2 ?? "");
                setCurentBranchAddress3(companyEle.b_address_line3 ?? "");
                setCurrentCompanyPostCode(companyEle.b_postcode ?? "");
                setCurrentCompanyState(companyEle.b_state ?? "");
                setCurrentCompanyCountry(companyEle.b_cid ?? "");
                if (companyEle.b_phone) setCurrentCompanyPhoneNum(companyEle.b_phone);
                setCurrentCompanyWebsite(companyEle.b_website);
            });

        }
        return (
            <div className="pt-3 text-left ">
                <div className="row">
                    {/*<div className="col-12 title"> </div>*/}

                    {/*Header Section*/}
                    <div className="col-xl-12 col-lg-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Audit
                                    Trails &gt; View</h5>

                            </div>
                            <div className="card-body">
                                <div className="col-12 search-container ">
                                    <div className="input-container col-md-12 fullWidthSearch">
                                        <div className="label col-md-1" id="span_menu">Menu</div>
                                        <div className=" col-md-11">
                                            <Multiselect
                                                options={searchMenuIdList}
                                                onSelect={onMenuSelect}
                                                onRemove={onMenuRemove}
                                                selectedValues={searchMenuId}
                                                displayValue="name"
                                                placeholder="Search by Menu"
                                                // selectionLimit={1}
                                                hidePlaceholder={true}
                                                // singleSelect={true}
                                                blurInputOnSelect={true}
                                                showCheckbox={false}
                                                groupBy="parent"
                                                avoidHighlightFirstOption={true}
                                                ref={multiselectRef}
                                                style={{
                                                    chips: {
                                                        background: "#344865"
                                                    },
                                                    groupHeading: {
                                                        color: "#343434 "
                                                    }

                                                }}
                                            />
                                        </div>


                                    </div>
                                    <div className="input-container col-md-12 fullWidthSearch">

                                        <div className="label col-md-1">User Id</div>
                                        <div className=" col-md-11">


                                            <Multiselect
                                                options={searchUserIdList}
                                                onSelect={onUserSelect}
                                                onRemove={onUserRemove}
                                                selectedValues={searchUserId}
                                                displayValue="name"
                                                placeholder="Search by User Id"
                                                hidePlaceholder={true}
                                                showCheckbox={false}
                                                avoidHighlightFirstOption={true}
                                                style={{
                                                    chips: {
                                                        background: "#344865"
                                                    }
                                                }}
                                            />

                                        </div>


                                    </div>
                                    <div className="input-container col-md-12 fullWidthSearch">
                                        <div className="label col-md-1">Date From</div>
                                        <div className="label col-md-5">
                                            <ReactDatepick
                                                value={searchEffFrom}
                                                dateSetter={setSearchEffFrom}
                                                resetToggle={toggleReset}
                                            />
                                        </div>
                                        <div className="col-md-1">To</div>
                                        <div className="col-md-5">
                                            <ReactDatepick
                                                value={searchEffTo}
                                                dateSetter={setSearchEffTo}
                                                resetToggle={toggleReset}
                                            />
                                        </div>


                                    </div>

                                    <div className="col-md-12 btn_search_padding">
                                        <div>
                                            <button className="btn btn-primary " id="btn-search-main"
                                                    onClick={() => handleSearch(true)}>Search
                                            </button>
                                            <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                            </button>
                                            <PDFDownloadLink document={
                                                <Document>
                                                    {handleRerender()}
                                                </Document>
                                            } fileName={auditLogName}>
                                                {({blob, url, loading, error}) =>
                                                    loading ? tableData.length > 0 ?
                                                        <button className="btn btn-primary ">Export to PDF
                                                        </button> :
                                                        <button className="btn btn-primary " disabled={true}>Export to PDF
                                                        </button> : <>
                                                        {tableData.length > 0 ?
                                                            <button className="btn btn-primary ">Export to PDF
                                                            </button> :
                                                            <button className="btn btn-primary " disabled={true}>Export to
                                                                PDF
                                                            </button>}

                                                    </>
                                                }
                                            </PDFDownloadLink>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                    {/*Bottom Section*/}
                    <div className="col-xl-12 col-lg-12">
                        <div className="card shadow mb-4">

                            <div className="card-body">
                                <div className="col-12 search-container">
                                    <ReactTable
                                        rowClick={setCurrentPage}
                                        perPageQty={setCurrentPerPage}
                                        columns={columns}
                                        data={tableData}
                                        pagination={{
                                            currentPage: currentPage,
                                            currentPerPage: currentPerPage,
                                            totalRecords: totalRecords,
                                        }}
                                        style={customTableStyles}
                                    />

                                </div>
                            </div>

                        </div>

                    </div>


                </div>
            </div>
        );
    }
;

export default Index;
