import {handleRequestGet, handleRequestPost} from '../axios';


export const getCurrencyList = async (search_term,size,page,status) => {
    let url = '/currency';

    let param = {
        size: size,
        page: page
    };
    if(search_term != "") param['term'] = search_term;

    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {}

    return false;
};


export const addCurrency = async (currencyCode, currencyName, buyRate,sellRate,effitiveDate) => {
    let url = '/currency/create';
    let result = await handleRequestPost(url, {
        code: currencyCode,
        name: currencyName,
        buyrate: buyRate,
        sellrate: sellRate,
        effectivedate:  effitiveDate == 'Invalid Date' ? null :effitiveDate,
    });

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateCurrency = async (currencyId, currencyCode, currencyName, buyRate,sellRate,effitiveDate,countryStr) => {
    let url = '/currency/update';

    let result = await handleRequestPost(url, {
        id: currencyId,
        code: currencyCode,
        name: currencyName,
        buyrate: buyRate,
        sellrate: sellRate,
        effectivedate: effitiveDate == 'Invalid Date' ? null :effitiveDate ,
        country: countryStr
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const deleteCurrency = async (currencyId) => {
    let url = '/currency/delete';
    let result = await handleRequestPost(url, {
        id: currencyId
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
