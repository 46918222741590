import React, {useState} from "react";
import {
    getExchangeMenu, getExchangeOrderType,
    getToolsMenu, handleToggleAllExchange,
    handleToggleChildControl, handleToggleToolControl, getExchangeQty, getOrderValidityItem
} from "./UserAccessMatTools";
import {
    changeBase,
    validateDatesById,
    zeroFill
} from "../../../general/general-tools";
import ReactTooltip from 'react-tooltip-rc';
import {confirmAlert} from "react-confirm-alert";
import ReactDatepick from "../../../general/form-control/react-datepick";
import dayjs from "dayjs";
import {DatePicker} from "antd";

export function renderU3DealerForm(userId, userName, marketBtnSetter = false, orderBtnSetter = false, currentPortion = false, clientMarketOri = false, clientOrderOri = false, popupManager = false, currentUserAccessLevel = false,hideMarketDepth = false) {
    let exchangeList = getExchangeMenu();
    let toolList = getToolsMenu();
    let OrderTypeList = getExchangeOrderType();
    let userMatrixEditMode = 'market';
    var exchangeListLength = getExchangeQty();
    var exchangeLists = getExchangeMenu();
    var dateObjectValue = {};



    const handleDataChanged = () => {
        //Exchange

        let exchangeSetup = (clientMarketOri?.al_exchange ?? [0,0]);
        var base3Exchange = Array.from(changeBase(exchangeSetup, 10, 3)).reverse().join('');
        base3Exchange = zeroFill(base3Exchange, exchangeListLength);

        let exchangeItem = [];
        for (let i = 0; i < exchangeLists.length; i++) {
            let radios = document.getElementsByName("eq_" + exchangeLists[i]["em_id"]);

            if (i == 4) exchangeItem[4] = base3Exchange[4];
            if (i == 7) exchangeItem[7] = base3Exchange[7];
            //TODO 18Oct change to e_code
            if (exchangeLists[i]["em_id"] != '4' && exchangeLists[i]["em_id"] != '7'&& exchangeLists[i]["em_id"] != '11'&& exchangeLists[i]["em_id"] != '14'&& exchangeLists[i]["em_id"] != '15') {
                for (let j = 0; j < radios.length; j++) {
                    if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_disabled" && radios[j].checked) {
                        exchangeItem[exchangeLists[i]["em_id"]] = "0";
                        break;
                    } else if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_delay" && radios[j].checked) {
                        exchangeItem[exchangeLists[i]["em_id"]] = "2";
                        break;
                    } else if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_live" && radios[j].checked) {
                        exchangeItem[exchangeLists[i]["em_id"]] = "1";
                        break;
                    }
                }
            }
        }
        let toolsMenuList = getToolsMenu();
        let toolsSetup = (clientMarketOri?.al_tools ?? 0);

        var base2Exchange = Array.from(changeBase(toolsSetup, 10, 2)).reverse().join('');
        base2Exchange = zeroFill(base2Exchange, toolsMenuList.length);

        let toolItem = [];
        for (let i = 0; i < toolsMenuList.length; i++) {
            let value = base2Exchange[toolsMenuList[i]["id"]];
            let checkbox = document.getElementById("tool_" + toolsMenuList[i]["id"]);
            if (value && checkbox.checked) {
                toolItem[toolsMenuList[i]["id"]] = 1
            } else {
                toolItem[toolsMenuList[i]["id"]] = 0

            }
        }

        var equityItemDecimal = changeBase(Array.from(exchangeItem.toString().replaceAll(",", "")).reverse().join(''), 3, 10);
        var toolItemDecimal = changeBase(Array.from(toolItem.toString().replaceAll(",", "")).reverse().join(''), 2, 10);

        console.log("clientMarketOri",  clientMarketOri?.al_exchange[0] ?? 0);
        console.log("equityItemDecimal",equityItemDecimal)
        if (equityItemDecimal != (clientMarketOri?.al_exchange[0] ?? 0)) {
            return true;
        }

        console.log("toolItemDecimal",  toolItemDecimal);
        console.log("clientMarketOri",clientMarketOri?.al_tools ?? 0)
        if (toolItemDecimal != (clientMarketOri?.al_tools ?? 0)) {
            return true;
        }

        return false;
    }
    const handleOrderDataChange = () => {
        let orderItem = getOrderValidityItem();
        let orderValidityItem = {};
        let orderValidityItemSubmit = {};
        for (let i = 0; i < orderItem.length; i++) {
            for (let j = 0; j < exchangeLists.length; j++) {
                let checkboxId = (orderItem[i] + "_" + exchangeLists[j]['e_code']).toLowerCase();
                let checkbox = document.getElementById(checkboxId);
                if (!orderValidityItem[exchangeLists[j]['e_code']]) orderValidityItem[exchangeLists[j]['e_code']] = (checkbox.checked) ? "1" : "0";
                else orderValidityItem[exchangeLists[j]['e_code']] += (checkbox.checked) ? "1" : "0";
            }
        }
        let base2Exchange = 0;
        for (var prop in orderValidityItem) {
            base2Exchange = zeroFill(orderValidityItem[prop], orderItem.length);
            orderValidityItemSubmit[prop] = changeBase(Array.from(base2Exchange.toString().replaceAll(",", "")).reverse().join(''), 2, 10);
        }

        if (JSON.stringify(clientOrderOri) == "{}") clientOrderOri =
            {"BM":0,"SG":0,"HK":0,"O":0,"A":0,"P":0,"N":0,"Ca":0,"Cc":0};
        //Order
        let arr5 = orderValidityItemSubmit;
        let arr6 = clientOrderOri;
        const keys1 = Object.keys(arr5);
        console.log("clientOrderOri", JSON.stringify(clientOrderOri));
        console.log("orderValidityItemSubmit", JSON.stringify(orderValidityItemSubmit));

        if(arr5 != null && arr6 != null){
            for (const key of keys1) {
                if (arr5[key] !== arr6[key]) {
                    return true;
                }
            }
        }



        return false;
    }
    const handleTitleRow = () => {
        if (userId != "") return <div><b>User ID : </b>{userId} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b>Name :</b> {userName}</div>;
        return <h5 className="m-0 font-weight-bold text-primary">Access Matrix </h5>;
    }

    function showMarketTab(portion) {
        let nav_btn = document.getElementsByClassName("nav_btn");

        for (var i = 0; i < nav_btn.length; i++) {
            nav_btn[i].classList.remove('inFormTopNavBtnSelected');
            nav_btn[i].classList.add('inFormTopNavBtn');
        }

        document.getElementById("btn_" + portion).classList.remove('inFormTopNavBtn');
        document.getElementById("btn_" + portion).classList.add('inFormTopNavBtnSelected');

        if(currentUserAccessLevel) {
            if (marketBtnSetter) marketBtnSetter(currentUserAccessLevel['is_equity']);
        }else{
            if (marketBtnSetter) marketBtnSetter(true);
        }

        // if (orderBtnSetter) orderBtnSetter(false);
        if (currentPortion) currentPortion('market');
        document.getElementById("div_order").classList.add('divHide');
        document.getElementById("div_market").classList.remove('divHide');
    }

    function showOrderTab(portion) {
        let nav_btn = document.getElementsByClassName("nav_btn");

        for (var i = 0; i < nav_btn.length; i++) {
            nav_btn[i].classList.remove('inFormTopNavBtnSelected');
            nav_btn[i].classList.add('inFormTopNavBtn');
        }

        document.getElementById("btn_" + portion).classList.remove('inFormTopNavBtn');
        document.getElementById("btn_" + portion).classList.add('inFormTopNavBtnSelected');

        userMatrixEditMode = 'order';
        if(currentUserAccessLevel) {
            if (marketBtnSetter) marketBtnSetter(currentUserAccessLevel['is_order']);
        }else{
            if (marketBtnSetter) marketBtnSetter(true);
        }
        if (currentPortion) currentPortion('order');
        document.getElementById("div_order").classList.remove('divHide');
        document.getElementById("div_market").classList.add('divHide');
    }

    const handleTopBtnClicked = (portion) => {

        if (portion == 'market') {

            if (handleOrderDataChange()) {
                confirmAlert({
                    title: 'Changes not saved',
                    message: <div id="area_confirm">Are you sure you want to view another tab without saving current
                        change?</div>,
                    buttons: [{
                        label: 'Yes', onClick: () => {
                            showMarketTab(portion);
                        }
                    }, {
                        label: 'Cancel',
                        id: 'btn_cancel',
                        onClick: () => {
                            return false;
                        }
                    }],
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                });

                // handlePopup(true, "Error", "Please save changes before attempt to view other tabs.", popupManager)
                // return false;
            } else {
                showMarketTab(portion);
            }

        } else { //Order
            if (handleDataChanged()) {
                confirmAlert({
                    title: 'Changes not saved',
                    message: <div id="area_confirm">Are you sure you want to view another tab without saving current
                        change?</div>,
                    buttons: [{
                        label: 'Yes', onClick: () => {
                            showOrderTab(portion);
                        }
                    }, {
                        label: 'Cancel',
                        id: 'btn_cancel',
                        onClick: () => {
                            return false;
                        }
                    }],
                    closeOnEscape: false,
                    closeOnClickOutside: false,
                });
                // handlePopup(true, "Error", "Please save changes before attempt to view other tabs.", popupManager)
                // return false;
            } else {
                showOrderTab(portion);
            }


        }


    }

    const handleMarketDepthCheckbox = (exchangeId, level) => {
        let checkbox = document.getElementById("mkdepth_" + exchangeId + '_' + level);
        if (checkbox.checked) {
            let checkboxes = document.getElementsByName("mkdepth_" + exchangeId);
            for (let i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].id != "mkdepth_" + exchangeId + '_' + level) {
                    checkboxes[i].checked = false;
                }
            }
        }else{
            let checkboxes = document.getElementsByName("mkdepth_" + exchangeId);
            for (let i = 0; i < checkboxes.length; i++) {
                let checkbox1 = document.getElementById("mkdepth_" + exchangeId + '_1');
                let checkbox2 = document.getElementById("mkdepth_" + exchangeId + '_2');
                let checkbox3 = document.getElementById("mkdepth_" + exchangeId + '_3');
                if(!checkbox2.checked && !checkbox3.checked){
                    checkbox1.checked = true;
                }
            }
        }

    }

    return (

        <div className="col-xl-12 col-lg-12" id="dealerDiv">
            <ReactTooltip/>

            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    {handleTitleRow()}
                </div>

                <div className="card-body">
                    <div className="col-12 search-container ">
                        <div className="underline-input">
                            <button id='btn_market' className="nav_btn inFormTopNavBtnSelected"
                                    onClick={() => handleTopBtnClicked('market')}
                            >Market
                            </button>
                            <button id='btn_order_type' className="nav_btn inFormTopNavBtn"
                                    onClick={() => handleTopBtnClicked('order_type')}
                            >Order Type
                            </button>
                        </div>
                        <div id='div_market'>

                            <div className="input-container col-md-12 fullWidthSearch">

                                <div className="label col-md-4"></div>
                                <div className="label col-md-1"></div>
                                <div className="label center-align-text col-md-3 underline-input">Equity</div>
                                <div className="label center-align-text col-md-4 underline-input" style={{"margin-left":".45em","margin-right":".45em"}}>Validity</div>
                            </div>
                            <div className="input-container col-md-12 fullWidthSearch">

                                <div className="label col-md-4"></div>
                                <div className="label col-md-1"></div>
                                <div className="label center-align-text col-md-1">Live</div>
                                <div className="label center-align-text col-md-1">Delay</div>
                                <div className="label center-align-text col-md-1">Disabled</div>
                                <div className="label center-align-text col-md-2">Start Date
                                    <span className="toolTipSmall"  data-tip="Left the date blank if no time range are intended to being configured."> (?)</span>
                                    <ReactTooltip/>

                                </div>
                                <div className="label center-align-text col-md-2">End Date
                                    <span className="toolTipSmall"
                                          data-tip="Left the date blank if no time range are intended to being configured."> (?)</span>
                                    <ReactTooltip/>
                                </div>

                            </div>
                            <div className="input-container col-md-12 fullWidthSearch am-input-parent">
                                <div className="label col-md-4 ">Market Data</div>
                                <div className="label col-md-1"></div>
                                <div className="label center-align-text col-md-1"></div>
                                <div className="label center-align-text col-md-1"></div>
                                <div className="label center-align-text col-md-2"></div>
                                <div className="label center-align-text col-md-2"></div>
                            </div>
                            {exchangeList.map(function (row, i) {
                                let row_code = row.em_code;
                                let row_name = row.em_name;
                                let row_id = row.em_id;
                                let row_disable_rules = row?.disabled ?? "000";



                                let excList = (
                                    <>
                                        <div
                                            className="input-container col-md-12 fullWidthSearch am-input-child2-div">
                                            <div className=" col-md-4 ">{row_name}</div>
                                            <div className=" col-md-1">{row_code}</div>
                                            <div className=" center-align-text col-md-1">
                                                <input className='am-input-checkbox' name={"eq_" + row_id}
                                                       type="radio" disabled={row_disable_rules[0] == '1'}
                                                       id={"eq_" + row_id + '_live'}/>
                                            </div>
                                            <div className=" center-align-text col-md-1">
                                                <input className='am-input-checkbox' name={"eq_" + row_id}
                                                       type="radio" disabled={row_disable_rules[1] == '1'}
                                                       id={"eq_" + row_id + '_delay'}/>

                                            </div>

                                            <div className=" center-align-text col-md-1">
                                                <input className='am-input-checkbox' name={"eq_" + row_id}
                                                       type="radio" disabled={row_disable_rules[2] == '1'}
                                                       id={"eq_" + row_id + '_disabled'}/>

                                            </div>

                                            <div className=" center-align-text col-md-2">


                                                <DatePicker
                                                    className={""}
                                                    format={"DD-MMM-YYYY"}
                                                    id={"eq_" + row_id + '_start_date'}
                                                    defaultValue={null}
                                                    onChange={() => validateDatesById(`eq_${row_id}_start_date`, `eq_${row_id}_end_date`,popupManager,'start')}


                                                />


                                            </div>

                                            <div className=" center-align-text col-md-2">
                                                <DatePicker
                                                    className={""}
                                                    format={"DD-MMM-YYYY"}
                                                    id={"eq_" + row_id + '_end_date'}
                                                    defaultValue={null}
                                                    onChange={() => validateDatesById(`eq_${row_id}_start_date`, `eq_${row_id}_end_date`,popupManager,'end')}
                                                />

                                            </div>

                                        </div>
                                    </>
                                )
                                return excList;
                            })}


                            {!hideMarketDepth && (
                                <>
                                    <div className="input-container col-md-12 fullWidthSearch am-input-parent">
                                        <div className="label col-md-4 ">Market Depth</div>
                                        <div className="label col-md-1"></div>
                                        <div className="label center-align-text col-md-1"></div>
                                        <div className="label center-align-text col-md-1"></div>
                                        <div className="label col-md-1"></div>
                                        <div className="label center-align-text col-md-1"></div>
                                        <div className="label center-align-text col-md-1"></div>
                                    </div>

                                    <div className="input-container col-md-12 fullWidthSearch">

                                        <div className="label col-md-4"></div>
                                        <div className="label col-md-1"></div>
                                        <div className="label center-align-text col-md-1">Level 1</div>
                                        <div className="label center-align-text col-md-1">Level 2</div>
                                        <div className="label center-align-text col-md-21">Level 3</div>


                                    </div>
                                </>
                            )}

                            {!hideMarketDepth && exchangeList.map(function (row, i) {
                                let row_code = row.em_code;
                                let row_name = row.em_name;
                                let row_id = row.em_id;
                                let row_disable_rules = row?.disabled ?? "000";

                                let excList = (
                                    <>
                                        <div id={"div_market_depth"}
                                            // style={hideMarketDepth ? {display: 'none'} : {display: 'block'}}
                                             className="input-container col-md-12 fullWidthSearch am-input-child2-div">
                                            <div className=" col-md-4 ">{row_name}</div>
                                            <div className=" col-md-1">{row_code}</div>
                                            <div className=" center-align-text col-md-1">
                                                <input className='am-input-checkbox' name={"mkdepth_" + row_id}
                                                       defaultChecked={true}
                                                       onChange={() => handleMarketDepthCheckbox(row_id, 1)}
                                                       type="checkbox" disabled={row_disable_rules[0] == '1'}
                                                       id={"mkdepth_" + row_id + '_1'}/>
                                            </div>
                                            <div className=" center-align-text col-md-1">
                                                <input className='am-input-checkbox' name={"mkdepth_" + row_id}
                                                       onChange={() => handleMarketDepthCheckbox(row_id, 2)}
                                                    // onChange={e => setCurrentBuyLimitChecked(e.target.checked)}
                                                       type="checkbox" disabled={row_disable_rules[0] == '1'}
                                                       id={"mkdepth_" + row_id + '_2'}/>

                                            </div>

                                            <div className=" center-align-text col-md-1">

                                                <input className='am-input-checkbox' name={"mkdepth_" + row_id}
                                                       type="checkbox"
                                                       onChange={() => handleMarketDepthCheckbox(row_id, 3)}
                                                       disabled={row_disable_rules[0] == '1'}
                                                       id={"mkdepth_" + row_id + '_3'}/>

                                            </div>
                                            <div className=" center-align-text col-md-2">


                                                <DatePicker
                                                    className={""}
                                                    format={"DD-MMM-YYYY"}
                                                    id={"eqdepth_" + row_id + '_start_date'}
                                                    defaultValue={null}
                                                    onChange={() => validateDatesById(`eqdepth_${row_id}_start_date`, `eq_${row_id}_end_date`, popupManager)}


                                                />


                                            </div>

                                            <div className=" center-align-text col-md-2">
                                                <DatePicker
                                                    className={""}
                                                    format={"DD-MMM-YYYY"}
                                                    id={"eqdepth_" + row_id + '_end_date'}
                                                    defaultValue={null}
                                                    onChange={() => validateDatesById(`eqdepth_${row_id}_start_date`, `eq_${row_id}_end_date`, popupManager)}
                                                />

                                            </div>
                                        </div>
                                    </>
                                )
                                return excList;
                            })}

                            <div className="input-container col-md-12 fullWidthSearch am-input-parent">
                                <div className="label col-md-4 ">Tools</div>
                                <div className=" col-md-1"></div>
                                <div className=" center-align-text col-md-1">
                                    <input
                                        type='checkbox' id={"tool_all"}
                                        className={'tool_all tool_all_header'}
                                        onClick={() => handleToggleChildControl('tool_all')}
                                    />
                                </div>
                                <div className=" center-align-text col-md-1"></div>
                                <div className=" col-md-1"></div>
                                <div className=" center-align-text col-md-1"></div>
                                <div className=" center-align-text col-md-1"></div>
                            </div>
                            {toolList.map(function (row, i) {
                                let toolId = row.id;
                                let toolName = row.label;
                                let row_label_to_lower = (toolName).replaceAll(' ', '').toLowerCase();

                                return (
                                    <>
                                        <div className="input-container col-md-12 fullWidthSearch am-input-child1">
                                            <div className=" col-md-5 ">{toolName}</div>
                                            <div className=" center-align-text col-md-1">
                                                <input
                                                    type='checkbox' id={"tool_" + toolId}
                                                    className={row_label_to_lower + ' tool_all'}
                                                    onClick={() => handleToggleToolControl(row_label_to_lower)}
                                                />
                                            </div>
                                            <div className=" center-align-text col-md-1"></div>
                                            <div className=" col-md-1"></div>
                                            <div className=" center-align-text col-md-1"></div>
                                            <div className=" center-align-text col-md-1"></div>
                                        </div>
                                    </>
                                )

                            })}


                        </div>
                        <div id='div_order' className='divHide'>
                            <div className="input-container col-md-12 fullWidthSearch am-input-parent am-stick-row">
                                <div className="label col-md-2">Order Type</div>
                                <div className="label col-md-10">
                                    <table className='am-tbl-width equal-witdh-tbl center-aligned-tbl'>
                                        <tr>
                                            {exchangeList.map(function (row, i) {
                                                let row_code = row.em_code;
                                                let excList = (
                                                    <>
                                                        <td>
                                                            {row_code}
                                                        </td>
                                                    </>
                                                )
                                                return excList;
                                            })}
                                        </tr>

                                    </table>
                                </div>


                            </div>


                            {OrderTypeList.map(function (row, i) {
                                let firstLevel_row_label = row.label;
                                let firstLevel_row_label_to_lower = (firstLevel_row_label).replaceAll(' ', '').toLowerCase();

                                let firstLevel_row_subMenu = row.subMenu;
                                //
                                let excList = (
                                    <>
                                        <div className="input-container col-md-12 fullWidthSearch am-input-child1-div">

                                            <div className="col-md-2">{firstLevel_row_label}</div>
                                            <div className="label col-md-10">
                                                <table className='am-tbl-width center-aligned-tbl'>
                                                    <tr>
                                                        {exchangeList.map(function (row, i) {
                                                            let row_code = row.em_code;
                                                            let row_code_to_lower = row.e_code.replaceAll(' ', '').toLowerCase();
                                                            //
                                                            let excList = (
                                                                <>
                                                                    <td>
                                                                        <input
                                                                            type='checkbox'
                                                                            // data-tip={row_code_to_lower.toUpperCase()}
                                                                            id={firstLevel_row_label_to_lower + "_" + row_code_to_lower}
                                                                            className={firstLevel_row_label_to_lower + "_" + row_code_to_lower + "_all"}
                                                                            onClick={() => handleToggleAllExchange(firstLevel_row_label_to_lower + '_' + row_code_to_lower + '_all')}
                                                                        />
                                                                    </td>
                                                                </>
                                                            )
                                                            return excList;
                                                        })}
                                                    </tr>

                                                </table>
                                            </div>
                                        </div>
                                        {firstLevel_row_subMenu.map(function (row, i) {
                                            let secondLevel_subLabel = row.label;
                                            let secondLevel_subLabel_to_lower = row.label.replaceAll(' ', '').toLowerCase();
                                            let specialPrefix = "";
                                            if (secondLevel_subLabel.includes(';')){
                                                secondLevel_subLabel = secondLevel_subLabel.split(";")[1];
                                                specialPrefix = "_"+row.label.split(";")[0];

                                            }

                                            let secondLevel_class_label = row.code ?? secondLevel_subLabel.replaceAll(' ', '').toLowerCase();
                                            let excList = (
                                                <>
                                                    <div
                                                        className="input-container col-md-12 fullWidthSearch am-input-child2-div">
                                                        <div className="col-md-2 ">{secondLevel_subLabel}</div>
                                                        <div className="label col-md-10">
                                                            <table className='am-tbl-width center-aligned-tbl'>
                                                                <tr>
                                                                    {exchangeList.map(function (row, i) {
                                                                        let thirdLevel_row_code = row.em_code;
                                                                        let thirdLevel_row_code_to_lower = row.e_code.replaceAll(' ', '').toLowerCase();
                                                                        //
                                                                        let excList = (
                                                                            <>
                                                                                <td>
                                                                                    <input
                                                                                        type='checkbox'
                                                                                        // data-tip={thirdLevel_row_code_to_lower.toUpperCase()}
                                                                                        id={secondLevel_class_label.toLowerCase()+specialPrefix.toLowerCase() + "_" + thirdLevel_row_code_to_lower}
                                                                                        className={firstLevel_row_label_to_lower+ '_' + thirdLevel_row_code_to_lower + ' ' + secondLevel_class_label + '_' + thirdLevel_row_code_to_lower}
                                                                                        onClick={() => handleToggleAllExchange(firstLevel_row_label_to_lower + '_' + thirdLevel_row_code_to_lower + '_' + secondLevel_class_label + '_' + thirdLevel_row_code_to_lower)}
                                                                                    />
                                                                                </td>
                                                                            </>
                                                                        )
                                                                        return excList;
                                                                    })}
                                                                </tr>

                                                            </table>
                                                        </div>
                                                    </div>
                                                </>

                                            );
                                            return excList;
                                        })}

                                    </>
                                )
                                return excList;
                            })}


                        </div>


                    </div>

                </div>

            </div>

        </div>
    )


}

