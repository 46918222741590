import React, {useState, useEffect, useMemo} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import {
    handleSelectChange,
    timeElement,
    holidayTypeOptions,
    handlePopup,
    disableAllEleInDiv, simpleTimeCheck, momentTimeFormat, convertErMsg, specialCharCheck
} from "../../../general/general-tools";
import {confirmAlert} from "react-confirm-alert";
import {usePopupManager} from "react-popup-manager";
import ModalConfirm from "../../public/ModalConfirm";
import ReactDatepick from "../../../general/form-control/react-datepick";
import dayjs from "dayjs";

const Index = () => {
    const {setLoading} = useLoading();

    const [currentMode, setCurrentMode] = useState("");
    const [holidayDate, setHolidayDate] = useState("");

    const [holidayId, setHolidayId] = useState("");
    const [holidayFrom, setHolidayFrom] = useState("");
    const [holidayDesc, setHolidayDesc] = useState("");
    const [holidayExchange, setHolidayExchange] = useState([]);
    const [exchangeList, setExchangeList] = useState([]);
    const [timeValueFrom, setTimeValueFrom] = useState(null);
    const [timeValueTo, setTimeValueTo] = useState(null);
    const [holidayType, setHolidayType] = useState("");
    const [accessItem, setAccessItem] = useState({});
    const [toggleReset, setToggleReset] = useState(false);
    const [exchangeSelAllLbl, setExchangeSelAllLbl] = useState("Exchange");
    const [hideExcItems, setHideExcItems] = useState(false);

    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();

    //Load initial data and mode
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        if (location.state) {
            if(location.state.mode.toLowerCase() == 'edit')  handleExchangeList();
            if (location.state.row) {
                let exchange = [];
                setAccessItem(location.state.perm);

                if (!location.state.perm['edit']) {
                    setTimeout(function () {
                        disableAllEleInDiv("mainDiv");
                    }, 50);
                }
                setHolidayId(location.state.row.h_id);
                setHolidayFrom(location.state.row.h_date);
                setTimeValueFrom(location.state.row.h_time_fr);
                setTimeValueTo(location.state.row.h_time_to);
                setHolidayType(location.state.row.h_type.toString());
                setHolidayDesc(location.state.row.h_remark);
                if (location.state.row.exchanges) {
                    let exchangeList = JSON.parse(location.state.row.exchanges);
                    handleExchangeList(exchangeList);

                }


            } else {
                handleExchangeList();

            }
            // setHolidayDate(location.state.holiday_date);
            setCurrentMode(location.state.mode)
        }
    }, [location.state]);


    const handleExchangeList = async (currentList = null) => {

        setLoading(true);
        api.getExchangeMainList(999, 1, 1, null).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }

            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdown(dataList);
                    setExchangeList(TreeData);


                    if (exchangeList != null && TreeData != null) {
                        let holidayExchg = [];

                        if(currentList){
                            if (currentList.length == TreeData.length-1) {
                                holidayExchg.push({
                                    name: "Select All",
                                    id: 0
                                });
                                setExchangeSelAllLbl("[All Exchange]")
                                setHideExcItems(true);
                            }
                            for (let j = 0; j < currentList.length; j++) {
                                let currentUt = {};
                                currentUt['name'] = currentList[j]['em_code'];
                                currentUt['id'] = currentList[j]['em_id'];
                                holidayExchg.push(currentUt);
                            }
                            setHolidayExchange(holidayExchg);

                        }


                    }

                }
            }
        });
        setLoading(false);
    };


    const convertRawIntoDropdown = (data) => {
        var returnDat = [];
        if (data.length > 0) {
            returnDat.push({
                name: "Select All",
                id: 0
            });
        }

        for (let i = 0; i < data.length; i++) {
            returnDat.push({
                name: data[i]['em_code'],
                id: data[i]['em_id']
            });
        }
        return returnDat;
    }

    // Edit
    const handleBackBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "holiday", {state: {mode: 'back'}});
        setLoading(false);
    };

    const handleRemoveBtn = async () => {
        await setLoading(true);

        popupManager.open(ModalConfirm, {
            title: 'Delete',
            content: 'Are you sure you want to delete?',
            onClose: (...params) => handleRemoveItem(params[0])
        });
        setLoading(false);
    };

    const handleRemoveItem = async (selBoolean) => {
        if (!selBoolean) return;
        await setLoading(true);
        api.deleteHoliday(holidayId).then((result: any) => {
            if (result != false && result.status == 0) {
                handlePopup(true, "Success", "Holiday deleted successfully.", popupManager, true, "holiday", navigate)
                setLoading(false);
            } else {
                setLoading(false);
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)

            }
        });



    };


    const handleSaveBtn = async () => {
        await setLoading(true);
        if (
            holidayFrom
        ) {
            if(holidayType){
                if(holidayType.toString() == ""){
                    handlePopup(true, "Error", "Mandatory field - Holiday type not selected.", popupManager)
                    setLoading(false);
                    return;
                }
            }else{
                handlePopup(true, "Error", "Mandatory field - Holiday type not selected.", popupManager)
                setLoading(false);
                return;
            }



            if (holidayType.toString() == '1' && ((!timeValueFrom || !timeValueTo) || (timeValueFrom == 'Invalid date' || !timeValueTo == 'Invalid date'))) {
                handlePopup(true, "Error", "Time range is required for partially open holiday.", popupManager)
                setLoading(false);
                return;
            }


            let fromDate = dayjs(holidayFrom).format(momentTimeFormat());


            let holidayExchangeStr = '';
            for (let j = 0; j < holidayExchange.length; j++) {
                let currentEx = {};
                if (j == 0) holidayExchangeStr += holidayExchange[j]['id']
                else holidayExchangeStr += "," + holidayExchange[j]['id']
            }



            if (holidayExchange.length == 0) {
                handlePopup(true, "Error", "Mandatory Field - Exchange not entered.", popupManager)
                setLoading(false);
                return;
            }

            let data = {
                "id": holidayId,
                "type": holidayType,
                "date": fromDate == 'Invalid Date' ? null : fromDate,
                "time_fr": timeValueFrom == 'Invalid date' ? null : timeValueFrom,
                "time_to": timeValueTo == 'Invalid date' ? null : timeValueTo,
                "remark": holidayDesc,
                "exchange": holidayExchangeStr
            };

            api.updateHolioday(data).then((result: any) => {
                if (result != false && result.status == 0) {
                    handlePopup(true, "Success", "Holiday updated successfully.", popupManager, true, "holiday", navigate)
                }
                else {
                    setLoading(false);
                    handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                }
            });

        } else {
            handlePopup(true, "Error", "Mandatory Field - Date not entered.", popupManager)
        }


        setLoading(false);
    };

    const onExchangeHandle = (selectedList, selectedItem) => {
        // setExchangeList(selectedList);
        const checkUsername = obj => obj.id == 0;
        if (selectedItem.id == 0) {
            setHolidayExchange(exchangeList);
            if (!selectedList.some(checkUsername)) {
                setHolidayExchange([]);
                setExchangeSelAllLbl("Exchange")
                setHideExcItems(false);
            } else setExchangeSelAllLbl("[All Exchange]")
            setHideExcItems(true);
        } else {
            setExchangeSelAllLbl("Exchange")
            setHideExcItems(false);

            setHolidayExchange([]);
            let tempArr = [];
            for (let i = 0; i < selectedList.length; i++) {
                if (selectedList[i]['id'] == 0) continue;
                tempArr.push({name: selectedList[i]['name'], id: selectedList[i]['id']});
            }
            setHolidayExchange(tempArr);
        }


    }


    const handleResetBtn = async () => {
        await setLoading(true);
        setHolidayFrom("dd/mm/yyyy");
        setHolidayDesc("");
        setHolidayType("");
        setTimeValueFrom("");
        setTimeValueTo("");
        setHolidayExchange([]);
        setToggleReset(!toggleReset);
        setLoading(false);
    };

    const handleAddBtn = async () => {
        await setLoading(true);
        if (
            holidayFrom
        ) {
            if(holidayType){
                if(holidayType.toString() == ""){
                    handlePopup(true, "Error", "Mandatory field - Holiday type not selected.", popupManager)
                    setLoading(false);
                    return;
                }
            }else{
                handlePopup(true, "Error", "Mandatory field - Holiday type not selected.", popupManager)
                setLoading(false);
                return;
            }


            if (holidayType.toString() == '1' && ((!timeValueFrom || !timeValueTo) || (timeValueFrom == 'Invalid date' || !timeValueTo == 'Invalid date'))) {
                handlePopup(true, "Error", "Time range is required for partially open holiday.", popupManager)

                setLoading(false);
                return;
            }

            const date1 = moment(timeValueFrom, 'YYYY-MM-DD').valueOf()
            const date2 = moment(timeValueTo, 'YYYY-MM-DD').valueOf()

            if (date1 > date2) {
                handlePopup(true, "Error", "Please ensure that the ending time is not earlier than the starting time.", popupManager);
                setTimeValueFrom(null);
                return false;
            }
            let fromDate = dayjs(holidayFrom).format(momentTimeFormat());

            let holidayExchangeStr = '';

            for (let j = 0; j < holidayExchange.length; j++) {
                let currentEx = {};
                if (j == 0) holidayExchangeStr += holidayExchange[j]['id']
                else holidayExchangeStr += "," + holidayExchange[j]['id']
            }

            if (holidayExchange.length == 0) {
                handlePopup(true, "Error", "Mandatory Field - Exchange not entered.", popupManager)
                setLoading(false);
                return;
            }

            let data = {
                "type": holidayType,
                "date": fromDate,
                "time_fr": timeValueFrom == 'Invalid date' ? null : timeValueFrom,
                "time_to": timeValueTo == 'Invalid date' ? null : timeValueTo,
                "remark": holidayDesc,
                "exchange": holidayExchangeStr
            };

            api.addHoliday(data).then((result: any) => {
                if (result != false && result.status == 0) {
                    setLoading(false);
                    handlePopup(true, "Success", "Holiday created successfully.", popupManager, true, "holiday", navigate)

                } else {
                    setLoading(false);
                    handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                }
            });


        } else {
            handlePopup(true, "Error", "Mandatory Field - Date not entered.", popupManager)

        }


        setLoading(false);
    };


    const onSelect = (selectedList, selectedItem) => {
        setHolidayExchange(selectedList);
    }
    const onRemove = (selectedList, selectedItem) => {
        setHolidayExchange(selectedList);
    }

    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Master File
                                Maintenance &gt; Holiday - {currentMode}</h5>
                            <div className="dropdown no-arrow">

                            </div>

                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-1">Date</div>
                                    <div className="label col-md-11">


                                        <ReactDatepick
                                            value={holidayFrom}
                                            dateSetter={setHolidayFrom}
                                            resetToggle={toggleReset}

                                        />
                                    </div>
                                    <b className='mandatory-red'>*</b>

                                </div>
                                <div className="input-container  fullWidthSearch ">
                                    <div className="label col-md-1">Type</div>
                                    <div className="label col-md-11 ">
                                        <select
                                            className="form-control"

                                            placeholder="e.g. MYR"
                                            aria-label=""
                                            value={holidayType}
                                            onChange={e => handleSelectChange(e.target.value, setHolidayType, false)}
                                        >
                                            {holidayTypeOptions()}
                                        </select>
                                    </div>
                                    <b className='mandatory-red'>*</b>

                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-1">Time</div>
                                    <div className="label col-md-5">
                                        <select
                                            className="form-control"
                                            id="holiday_from_time"
                                            aria-label=""
                                            value={timeValueFrom}
                                            onBlur={e => simpleTimeCheck(e.target.value, timeValueTo, setTimeValueTo, popupManager)}
                                            onChange={e => handleSelectChange(e.target.value, setTimeValueFrom, false)}
                                        >
                                            {timeElement()}
                                        </select>

                                    </div>
                                    <div className="col-md-1">To</div>
                                    <div className="col-md-5">
                                        <select
                                            className="form-control"
                                            id="holiday_to_time"
                                            aria-label=""
                                            value={timeValueTo}
                                            onBlur={e => simpleTimeCheck(timeValueFrom, e.target.value, setTimeValueTo, popupManager)}
                                            onChange={e => handleSelectChange(e.target.value, setTimeValueTo, false)}
                                        >
                                            {timeElement()}
                                        </select>
                                    </div>

                                </div>


                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-1">Description</div>
                                    <div className="label col-md-11">
                                        <input
                                            className="form-control "

                                            type="text"
                                            placeholder="e.g. New Year Eve "
                                            value={holidayDesc}
                                            onChange={(e) => setHolidayDesc(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value,setHolidayDesc,popupManager)}

                                        />
                                    </div>
                                </div>
                                <div className="input-container  fullWidthSearch">
                                    <div className="label col-md-1">Exchange</div>
                                    <div className=" col-md-11">
                                        <Multiselect
                                            options={exchangeList} // Options to display in the dropdown
                                            // selectedValues={exchangeSelected} // Preselected value to persist in dropdown
                                            selectedValues={holidayExchange}
                                            onSelect={onExchangeHandle} // Function will trigger on select event
                                            onRemove={onExchangeHandle} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            placeholder={exchangeSelAllLbl}
                                            hideSelectedList={hideExcItems}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    background: "#344865"
                                                }
                                            }}
                                        />
                                    </div>
                                    <b className='mandatory-red'>*</b>
                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">

                                    <div className="label col-md-1"></div>

                                    {currentMode.toLowerCase() == 'edit' ?
                                        <div className="label col-md-11 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                            </button>
                                            {accessItem['delete'] ? <button className="btn btn-primary "
                                                                            onClick={() => handleRemoveBtn()}>Delete</button> : ""}
                                            {accessItem['edit'] ? <button className="btn btn-primary "
                                                                          onClick={() => handleSaveBtn()}>Save</button> : ""}

                                        </div> :

                                        <div className="label col-md-11 btn_search_padding">
                                            <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                            </button>
                                            <button className="btn btn-primary "
                                                    onClick={() => handleResetBtn()}>Reset
                                            </button>
                                            <button className="btn btn-primary " onClick={() => handleAddBtn()}>Save
                                            </button>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
