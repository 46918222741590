import { createContext, useState } from 'react';

const AuthContext = createContext({});

interface Props {
    children: React.ReactNode;
}

interface Auth {}

export const AuthProvider = ({ children }: Props) => {
    const [auth, setAuth] = useState<Auth>({});

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
