import React, {useState, useEffect, useMemo, useLayoutEffect, useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {numberWithCommas} from '../../../general/convertor';
import {confirmAlert} from "react-confirm-alert";
import api from "../../../../apis";
import {
    handlePopup,
    handleCountryChange,
    numProcessor,
    profileStatusOption,
    stateField,
    timeElement,
    testEmailAddress,
    changeBase,
    zeroFill,
    handleDecimalNumChangeWifCursor,
    convertPositiveNumberHundred,
    handleDisableChkBxByVal,
    getNavItemById,
    disableAllEleInDiv,
    dateFormats,
    datePlaceHolder,
    convertErMsg,
    inLineConvertErMsg, momentTimeFormat, simpleTimeCheck, specialCharCheck, IsJsonString
} from "../../../general/general-tools";
import PhoneInput from "react-phone-input-2";
import ReactTable from "../../../general/form-control/react-table";
import {handleSelectChange} from "../../../general/general-tools";
import Toggle from 'react-toggle'
import Multiselect from "multiselect-react-dropdown";
import {usePopupManager} from "react-popup-manager";
import {renderOperationForm} from "../UserAccessMat/UserAccessMatOperation";
import {renderDealerForm} from "../UserAccessMat/UserAccessMatClientDeal";
import {renderU3DealerForm} from "../UserAccessMat/UserAccessU3MatClientDeal";
import {
    getExchangeMenu, getExchangeOrderType,
    getExchangeQty,
    getFunctionMenus,
    getOrderValidityItem,
    getToolsMenu,
    findPositionsByOrderPosition, getEmIdByECode
} from "../UserAccessMat/UserAccessMatTools";
import ModalConfirm from "../../public/ModalConfirm";
import {DatePicker, Space} from 'antd';
import dayjs from 'dayjs';
import ReactDatepick from "../../../general/form-control/react-datepick";
import {Cookies, useCookies} from 'react-cookie';
import {resetClientDealerMarket, resetClientDealerOrder} from "../../../../apis/models/UserTypeMatrix";
import Config from "../../../../config";

const Index = () => {
        const {setLoading} = useLoading();
        const [cookies, setCookies] = useCookies();
        const [defaultPhoneCountry] = useState(process.env.REACT_APP_PHONE_COUNTRY);

        const [currentItemToSave, setCurrentItemToSave] = useState("profile");
        const [currentUserAccessLevel, setCurrentUserTypeAccessLevel] = useState("");

        const [currentRole, setCurrentRole] = useState("");
        const [currentMode, setCurrentMode] = useState("");
        const [currentTableId, setCurrentTableId] = useState("");

        const [currentUserId, setCurrentUserId] = useState("");
        const [currentAccountId, setCurrentAccountId] = useState("");
        const [currentUserIdReadOnly, setCurrentUserIdReadOnly] = useState(false);

        const [currentUserType, setCurrentUserType] = useState("");
        const [currentUserTypeReadOnly, setCurrentUserTypeReadOnly] = useState(false);

        const [currentUserName, setCurrentUserName] = useState("");
        const [currentUserLastName, setCurrentUserLastName] = useState("");
        const [currentUserNameReadOnly, setCurrentUserNameReadOnly] = useState(false);

        const [currentUserTitle, setCurrentUserTitle] = useState("");
        const [currentUserTitleReadOnly, setCurrentUserTitleReadOnly] = useState(false);

        const [currentNRIC, setCurrentNRIC] = useState("");
        const [currentNRICReadOnly, setCurrentNRICReadOnly] = useState(false);

        const [currentPassport, setCurrentPassport] = useState("");
        const [currentPassportReadOnly, setCurrentPassportReadOnly] = useState(false);

        const [currentGenderMaleChecked, setCurrentGenderMaleChecked] = useState("");
        const [currentGenderMaleReadOnly, setCurrentGenderMaleReadOnly] = useState(false);

        const [currentGenderFeMaleChecked, setCurrentGenderFeMaleChecked] = useState(false);
        const [currentGenderFeMaleReadOnly, setCurrentGenderFeMaleReadOnly] = useState(false);

        const [currentDOB, setCurrentDOB] = useState(null);
        const [currentDOBReadOnly, setCurrentDOBReadOnly] = useState(false);

        const [currentNationality, setCurrentNationality] = useState("");
        const [currentNationalityReadOnly, setCurrentNationalityReadOnly] = useState(false);

        const [currentEmail, setCurrentEmail] = useState("");
        const [currentEmailReadOnly, setCurrentEmailReadOnly] = useState(false);

        const [currentHomePhone, setCurrentHomePhone] = useState("");
        const [currentHomePhoneReadOnly, setCurrentHomePhoneReadOnly] = useState(false);

        const [currentBranch, setCurrentBranch] = useState("");
        const [currentBranchReadOnly, setCurrentBranchReadOnly] = useState(false);

        const [currentMobilePhone, setCurrentMobilePhone] = useState("");
        const [currentMobilePhoneReadOnly, setCurrentMobilePhoneReadOnly] = useState(false);

        const [currentStatus, setCurrentStatus] = useState(1);
        const [currentPrevStatus, setCurrentPrevStatus] = useState(1);
        const [currentStatusReadOnly, setCurrentStatusReadOnly] = useState(false);

        const [currentOfficePhone, setCurrentOfficePhone] = useState("");
        const [currentOfficePhoneReadOnly, setCurrentOfficePhoneReadOnly] = useState(false);

        const [currentRace, setCurrentRace] = useState("");
        const [currentRaceReadOnly, setCurrentRaceReadOnly] = useState(false);

        const [currentOtherRace, setCurrentOtherRace] = useState("");
        const [currentOtherRaceReadOnly, setCurrentOtherRaceReadOnly] = useState(true);

        const [currentCreationDate, setCurrentCreationDate] = useState("");
        const [currentCreationDateReadOnly, setCurrentCreationDateReadOnly] = useState(false);

        const [currentLastUpdateDate, setCurrentLastUpdateDate] = useState("");
        const [currentLastUpdateDateReadOnly, setCurrentLastUpdateDateReadOnly] = useState(false);

        const [currentClientType, setCurrentClientType] = useState("");
        const [currentClientTypeReadOnly, setCurrentClientTypeReadOnly] = useState(false);

        const [currentLicenseNo, setCurrentLicenseNo] = useState("");
        const [currentLicenseNoReadOnly, setCurrentLicenseNoReadOnly] = useState(false);

        const [currentDealerCode, setCurrentDealerCode] = useState("");
        const [currentDealerCodeReadOnly, setCurrentDealerCodeReadOnly] = useState(false);

        const [currentBankAcNo, setCurrentBankAcNo] = useState("");
        const [currentBankAcNoReadOnly, setCurrentBankAcNoCodeReadOnly] = useState(false);

        const [currentAccStatus, setCurrentAccStatus] = useState("");
        const [currentAccStatusReadOnly, setCurrentAccStatusCodeReadOnly] = useState(false);

        const [currentBankName, setCurrentBankName] = useState("");
        const [currentBankNameReadOnly, setCurrentBankNameCodeReadOnly] = useState(false);

        const [currenteContract, setCurrenteContract] = useState("");
        const [currenteContractReadOnly, setCurrenteContractCodeReadOnly] = useState(false);

        const [currentIdss, setCurrentIdss] = useState("");
        const [currentIdssReadOnly, setCurrentIdssReadOnly] = useState(false);

        const [currentEstatement, setCurrentEstatement] = useState("");
        const [currentEstatementReadOnly, setCurrentEstatementReadOnly] = useState(false);

        const [currentTNCAcceptDate, setCurrentTNCAcceptDate] = useState("");
        const [currentTNCAcceptDateReadOnly, setCurrentTNCAcceptDateReadOnly] = useState(false);

        const [currentAddress1, setCurrentAddress1] = useState("");
        const [currentAddress1ReadOnly, setCurrentAddress1ReadOnly] = useState(false);

        const [currentAddress2, setCurrentAddress2] = useState("");
        const [currentAddress2ReadOnly, setCurrentAddress2ReadOnly] = useState(false);

        const [currentAddress3, setCurrentAddress3] = useState("");
        const [currentAddress3ReadOnly, setCurrentAddress3ReadOnly] = useState(false);

        const [currentCountry, setCurrentCountry] = useState("");
        const [currentCountryReadOnly, setCurrentCountryReadOnly] = useState(false);

        const [currentState, setCurrentState] = useState("");
        const [currentStateReadOnly, setCurrentStateReadOnly] = useState(false);

        const [currentPostCode, setCurrentPostCode] = useState("");
        const [currentPostCodeReadOnly, setCurrentPostCodeReadOnly] = useState(false);

        const [currentPassword, setCurrentPassword] = useState("");
        const [currentPasswordReadOnly, setCurrentPasswordReadOnly] = useState(false);

        const [lastPasswordChange, setLastPasswordChange] = useState("");
        const [passwordExpiredDate, setPasswordExpiredDate] = useState("");

        //Access Schedule
        const [currentEnaYesChecked, setCurrentEnaYesChecked] = useState("0");
        const [currentEnaYesReadOnly, setCurrentEnaYesReadOnly] = useState(false);

        const [currentEnaNoChecked, setCurrentEnaNoChecked] = useState(false);
        const [currentEnaNoReadOnly, setCurrentEnaNoReadOnly] = useState(false);

        const [currentExpiredOn, setCurrentExpiredOn] = useState(null);
        const [currentExpiredOnReadOnly, setCurrentExpiredOnReadOnly] = useState(false);

        const [currentStartTime, setCurrentStartTime] = useState("");
        const [currentStartTimeReadOnly, setCurrentStartTimenReadOnly] = useState(false);

        const [currentStopTime, setCurrentStopTime] = useState(null);
        const [currentStopTimeReadOnly, setCurrentStopTimenReadOnly] = useState(false);


        const [monChecked, setMonChecked] = useState(false);
        const [tueChecked, setTueChecked] = useState(false);
        const [wedChecked, setWedChecked] = useState(false);
        const [thuChecked, setThuChecked] = useState(false);
        const [friChecked, setFriChecked] = useState(false);
        const [satChecked, setSatChecked] = useState(false);
        const [sunChecked, setSunChecked] = useState(false);

        //Table Data
        const [tableData, setTableData] = useState([]);
        const [totalRecords, setTotalRecords] = useState(0);
        const [currentPage, setCurrentPage] = useState(1);
        const [currentPerPage, setCurrentPerPage] = useState(10);
        const [passwordShowToggle, setpPasswordShowToggle] = useState(false);
        const [generatePwToggle, setGeneratePwToggle] = useState(false);
        const [pwInputType, setpwInputType] = useState('password');
        const [countryList, setCountryList] = useState([]);
        const [currentCountryText, setCurrentCountryText] = useState("");
        const [userTypeList, setUserTypeList] = useState([]);
        const [branchList, setBranchList] = useState([]);
        const [currentRoleId, setCurrentRoleId] = useState([]);
        const [isPasswordValid, setIsPasswordValid] = useState(false);
        const [isUsernameValid, setIsUsernameValid] = useState(false);
        const [passwordAlert, setPasswordAlert] = useState("");
        const [usernameAlert, setUsernameAlert] = useState("");

        //Auth Limit data
        const [elBuy, setElBuy] = useState(0.00);
        const [elBuyChkBox, setElBuyChkBox] = useState(false);
        const [elSell, setElSell] = useState(0.00);
        const [elSellChkBox, setElSellChkBox] = useState(false);
        const [elTotal, setElTotal] = useState(0.00);
        const [elTotalChkBox, setElTotalChkBox] = useState(false);
        const [elNett, setElNett] = useState(0.00);
        const [elNettChkBox, setElNettChkBox] = useState(false);
        const [elIDSS, setElIDSS] = useState(0.00);
        const [elIDSSChkBox, setElIDSSChkBox] = useState(false);
        const [elFST, setElFST] = useState(0.00);
        const [elFSTChkBox, setElFSTChkBox] = useState(false);

        const [topUpBuy, settopUpBuy] = useState(0.00);
        const [topUpBuyChkBox, settopUpBuyChkBox] = useState(false);
        const [topUpSell, settopupSell] = useState(0.00);
        const [topUpSellChkBox, settopUpSellChkBox] = useState(false);
        const [topUpTotal, settopUpTotal] = useState(0.00);
        const [topUpTotalChkBox, settopUpTotalChkBox] = useState(false);
        const [topUpNett, settopUpNett] = useState(0.00);
        const [topUpNettChkBox, settopUpNettChkBox] = useState(false);
        const [topUpIDSS, settopUpIDSS] = useState(0.00);
        const [topUpIDSSChkBox, settopUpIDSSChkBox] = useState(false);
        const [topUpFST, settopUpFST] = useState(0.00);
        const [topUpFSTChkBox, settopUpFSTChkBox] = useState(false);
        const [showResetAMBtn, setShowResetAMBtn] = useState(false);
        const [isUserLvlProf, setIsUserLvlProf] = useState(false);

        const [isUserLvlClientProf, setIsUserLvlClientProf] = useState(false);

        const [showResetbtn, setShowResetbtn] = useState(true);
        const [showResetOrderBtn, setShowResetOrderBtn] = useState(false);
        const [showResetMarketBtn, setShowResetMarketBtn] = useState(false);

        const [showResetAuthLimitBtn, setShowResetAuthLimitBtn] = useState(false);

        const [toggleReset, setToggleReset] = useState(false);
        const [accessItem, setAccessItem] = useState({});

        const [operationMatrixOri, setOperationMatrixOri] = useState(null);
        const [operationAuthLimitOri, setOperationAuthLimitOri] = useState(null);

        const [clientAccListOri, setClientAccListOri] = useState([]);
        const [clientMarketOri, setClientMarketOri] = useState(null);
        const [clientOrderOri, setClientOrderOri] = useState(null);
        const [backupState, setBackupState] = useState(null);

        const [currentClientOrderPortion, setCurrentClientOrderPortion] = useState('market');
        const [showTopAccessStatus, setShowTopAccessStatus] = useState(false);


        let navigate = useNavigate();
        let currentPort = 'profile';
        const location = useLocation();
        const popupManager = usePopupManager();
        const isInitialMount = useRef(true);
        let isU3Version = Config.isU3Version;

        const handleOtherRace = (value, selectOptionSetter, readOnly) => {
            if (!readOnly) selectOptionSetter(value);
            if (value == 'OTHERS') setCurrentOtherRaceReadOnly(false);
            else setCurrentOtherRaceReadOnly(true);
        }

        useEffect(() => {
            if (!location.state) location.state = backupState;
        }, [location]);

        useEffect(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
            setLoading(true);
            handleCountryList();
            handleUserTypeList();
            handleBranchList();
            initProfileData();
            setLoading(false);
        }, []);


        let counter = 0;
        useEffect(() => {
            if (isInitialMount.current) {
                isInitialMount.current = false;
            } else {
                currentPort = 'accessMatrix'
                initProfileData();
            }

        }, [currentClientOrderPortion]);

        const initProfileData = () => {
            setLoading(true);
            if (!location.state) location.state = backupState;
            if (location.state) {

                setBackupState(location.state);
                let accessPerm = location.state.perm;
                setAccessItem(accessPerm);
                if (location.state.mode.toLowerCase() == 'add') {
                    renderBottomSection('operation', location.state.mode, currentPort)
                    setDisabledInput('operation');
                } else if (location.state.row) {
                    if (!accessPerm['edit']) {
                        setTimeout(function () {
                            disableAllEleInDiv("mainDiv");
                            disableAllEleInDiv("operationDiv");
                            disableAllEleInDiv("dealerDiv");
                        }, 50);
                    }

                    let time_create_date = moment(location.state.row.create_date);
                    let timelast_update = moment(location.state.row.last_update);
                    let time_acs_end_date = moment(location.state.row.acs_end_date).format(momentTimeFormat());
                    let time_p_dob = moment(location.state.row.p_dob);
                    let pwChange = moment(location.state.row.last_change_password);
                    let pwExp = moment(location.state.row.password_expired_on);


                    if (pwChange != 'Invalid date') pwChange = pwChange.format("YYYY-MM-DD hh:MM A");
                    if (pwExp != 'Invalid date') pwExp = pwExp.format("YYYY-MM-DD hh:MM A");
                    if (time_create_date != 'Invalid date') time_create_date = time_create_date.format("YYYY-MM-DD");
                    if (timelast_update != 'Invalid date') timelast_update = timelast_update.format("YYYY-MM-DD");
                    if (time_p_dob != 'Invalid date') time_p_dob = time_p_dob.format("YYYY-MM-DD");

                    handleInitList(location.state.row.user_id, (location.state.row.uc_name).toLowerCase())

                    setCurrentRole((location.state.row.uc_name))
                    renderBottomSection((location.state.row.uc_name), location.state.mode, currentPort)
                    setDisabledInput((location.state.row.uc_name));
                    if ((location.state.row.uc_name).toLowerCase() == 'operation') handleInitListAuthLimit(location.state.row.user_id);

                    setCurrentRoleId(location.state.row.ut_id)
                    // setCurrentRole("Client")
                    setCurrentAccountId(location.state.row.user_id);
                    setCurrentUserId(location.state.row.username);

                    setCurrentUserType(location.state.row.ut_id ?? "");
                    setCurrentUserName(location.state.row.p_firstname ?? "");
                    setCurrentUserLastName(location.state.row.p_lastname ?? "");
                    setCurrentUserTitle(location.state.row.p_title ?? "");
                    setCurrentNRIC(location.state.row.p_nric ?? "");
                    setCurrentPassport(location.state.row.p_pptno ?? "");
                    setCurrentGenderMaleChecked(location.state.row.p_gender);

                    setCurrentDOB(time_p_dob == 'Invalid date' ? null : time_p_dob);
                    setCurrentNationality(location.state.row.p_nationality ?? "");
                    setCurrentEmail(location.state.row.p_email ?? "");
                    setCurrentHomePhone(location.state.row.p_phone ?? "");
                    setCurrentBranch(location.state.row.bb_id ?? "");
                    setCurrentTableId(location.state.row.table_type ?? "");


                    if (location.state.row.bb_id) {
                        let currentBrch = {};
                        let branch = [];
                        currentBrch['id'] = location.state.row.bb_id;
                        currentBrch['name'] = (location.state.row.bb_code ?? "") + "|" + location.state.row.bb_name;
                        currentBrch['code'] = location.state.row.bb_id ?? "";
                        branch.push(currentBrch);
                        setCurrentBranch(branch);
                    }
                    setCurrentMobilePhone(location.state.row.p_handphone ?? "");
                    setCurrentOfficePhone(location.state.row.p_office ?? "");

                    setCurrentStatus(location.state.row.active_status ?? 1);
                    setCurrentPrevStatus(location.state.row.active_status ?? 1);
                    if (location.state.row.dormancy_date != 'Invalid Date') {
                        const dormancyDate = dayjs(location.state.row.dormancy_date);
                        const currentDate = dayjs();

                        if ((dormancyDate.isSame(currentDate) || dormancyDate.isBefore(currentDate)) && !location.state.row.active_status == 11) {
                            setCurrentStatus("4");
                            setCurrentPrevStatus("4");
                        }
                    }

                    if (location.state.row.p_race.includes("OTHERS")) {
                        setCurrentRace("OTHERS");
                        let raceOther = location.state.row.p_race.split('-');
                        setCurrentOtherRace(raceOther[1] ?? "");
                        setCurrentOtherRaceReadOnly(false);
                    } else {
                        setCurrentRace(location.state.row.p_race ?? "");
                        setCurrentOtherRaceReadOnly(true);

                    }

                    setCurrentCreationDate(time_create_date == 'Invalid date' ? null : time_create_date);
                    setCurrentLastUpdateDate(timelast_update == 'Invalid date' ? null : timelast_update);
                    setCurrentClientType(location.state.row.ct_name);
                    // setCurrentLicenseNo(location.state.row.ut_id);
                    setCurrentDealerCode(location.state.row.dealer_code ?? "")
                    setCurrentBankAcNo(location.state.row.bank_account ?? "")
                    //Requested by Mr Tan as 16 Dec 2022
                    setCurrentAccStatus(location.state.row.active_status ?? 1)
                    setCurrentEnaYesChecked(location.state.row.acs_status ?? "0")
                    // setCurrentEnaNoChecked(!location.state.row.acs_status)
                    setCurrentBankName(location.state.row.bk_name ?? "")
                    setCurrenteContract(location.state.row.econtract ?? "")
                    setCurrentIdss(location.state.row.idss)
                    setCurrentEstatement(location.state.row.estatement ?? "")
                    setCurrentTNCAcceptDate(location.state.row.tnc_accept == 'Invalid date' ? "-" : location.state.row.tnc_accept)
                    setCurrentAddress1(location.state.row.p_addressline1 ?? "")
                    setCurrentAddress2(location.state.row.p_addressline2 ?? "")
                    setCurrentAddress3(location.state.row.p_addressline3 ?? "")
                    setCurrentCountry(location.state.row.p_country ?? "")


                    setCurrentState(location.state.row.p_state ?? "")
                    setCurrentPostCode(location.state.row.p_postcode ?? "")
                    setLastPasswordChange(pwChange == "Invalid date" ? "-" : pwChange)
                    setPasswordExpiredDate(pwExp == "Invalid date" ? "-" : pwExp)

                    setCurrentStopTime(location.state.row.acs_end_time == 'Invalid date' ? null : location.state.row.acs_end_time)
                    setCurrentStartTime(location.state.row.acs_start_time == 'Invalid date' ? null : location.state.row.acs_start_time)
                    setCurrentExpiredOn(time_acs_end_date == 'Invalid date' ? null : time_acs_end_date)
                    setMonChecked(location.state.row.is_monday)
                    setTueChecked(location.state.row.is_tuesday)
                    setWedChecked(location.state.row.is_wednesday)
                    setThuChecked(location.state.row.is_thursday)
                    setFriChecked(location.state.row.is_friday)
                    setSatChecked(location.state.row.is_saturday)
                    setSunChecked(location.state.row.is_sunday)

                    if (location.state.row.accounts != "" && location.state.row.accounts != null) {
                        setTableData(JSON.parse(location.state.row.accounts));
                        setClientAccListOri(JSON.parse(location.state.row.accounts))
                    }
                    // setTableData(JSON.parse("[{\"a_acctno\":\"CSH1388C\",\"a_type\":0},{\"a_acctno\":\"MGN0868D\",\"a_type\":1}]"));
                    // setCurrentUserType(location.state.row.user_category);

                }
                setCurrentMode(location.state.mode)

            }


        }

        const setDisabledInput = async (role: string) => {
            if (role.toLowerCase() == 'operation') {
                setCurrentUserIdReadOnly(true);
                if (location.state.mode.toLowerCase() == 'add') {
                    setCurrentUserTypeReadOnly(false);
                    setCurrentUserIdReadOnly(false);
                    document.getElementById("btn_authLimit").classList.add('divHide');
                    document.getElementById("btn_accessMatrix").classList.add('divHide');

                } else {
                    setCurrentUserTypeReadOnly(true)
                    setCurrentCreationDateReadOnly(true);
                    setCurrentLastUpdateDateReadOnly(true);
                    setCurrentAccStatusCodeReadOnly(false);

                    let specialPerm = cookies.special_perm;

                    if (specialPerm) {
                        if (specialPerm[4] != null) {
                            switch (specialPerm[4]) {
                                case 0:
                                    document.getElementById("btn_authLimit").classList.add('divHide');
                                    document.getElementById("btn_accessMatrix").classList.add('divHide');
                                    break;
                                case 1:
                                    document.getElementById("btn_authLimit").classList.add('divHide');
                                    document.getElementById("btn_accessMatrix").classList.remove('divHide');

                                    break;
                                case 2:
                                    document.getElementById("btn_authLimit").classList.remove('divHide');
                                    document.getElementById("btn_accessMatrix").classList.add('divHide');
                                    break;
                                case 3:
                                    document.getElementById("btn_authLimit").classList.remove('divHide');
                                    document.getElementById("btn_accessMatrix").classList.remove('divHide');
                                    break;
                            }
                        }
                    }

                }


            } else if (role.toLowerCase() == 'dealer') {
                document.getElementById("btn_accessMatrix").classList.remove('divHide');

                setCurrentUserIdReadOnly(true);
                setCurrentUserTypeReadOnly(true);
                setCurrentUserNameReadOnly(true);
                setCurrentUserTitleReadOnly(true);
                setCurrentNRICReadOnly(true);
                setCurrentPassportReadOnly(true);
                setCurrentGenderMaleReadOnly(true);
                setCurrentGenderFeMaleReadOnly(true);
                setCurrentDOBReadOnly(true);
                setCurrentNationalityReadOnly(true);
                setCurrentEmailReadOnly(false);
                setCurrentHomePhoneReadOnly(false);
                setCurrentBranchReadOnly(true);
                setCurrentMobilePhoneReadOnly(false);
                //Changes on request 8Apr2024
                setCurrentStatusReadOnly(false);
                setCurrentAccStatusCodeReadOnly(true);
                setCurrentOfficePhoneReadOnly(false);
                setCurrentRaceReadOnly(true);
                setCurrentCreationDateReadOnly(true);
                setCurrentLicenseNoReadOnly(true);
                setCurrentDealerCodeReadOnly(true);
                setCurrentLastUpdateDateReadOnly(true);
                setCurrentTNCAcceptDateReadOnly(true);
                setCurrentAddress1ReadOnly(true);
                setCurrentAddress2ReadOnly(true);
                setCurrentAddress3ReadOnly(true);
                setCurrentCountryReadOnly(true);
                setCurrentStateReadOnly(true);
                setCurrentPostCodeReadOnly(true);
                setCurrentPasswordReadOnly(false);
            } else if (role.toLowerCase() == 'client') {
                document.getElementById("btn_accessMatrix").classList.remove('divHide');

                setCurrentUserIdReadOnly(true);
                setCurrentUserTypeReadOnly(true);
                setCurrentUserNameReadOnly(true);
                setCurrentUserTitleReadOnly(true);
                setCurrentNRICReadOnly(true);
                setCurrentPassportReadOnly(true);
                setCurrentGenderMaleReadOnly(true);
                setCurrentGenderFeMaleReadOnly(true);
                setCurrentDOBReadOnly(true);
                setCurrentNationalityReadOnly(true);
                setCurrentEmailReadOnly(true);
                setCurrentHomePhoneReadOnly(true);
                setCurrentBranchReadOnly(true);
                setCurrentMobilePhoneReadOnly(true);
                setCurrentOfficePhoneReadOnly(true);
                //Changes on request 8Apr2024
                setCurrentStatusReadOnly(false);
                setCurrentRaceReadOnly(true);
                setCurrentCreationDateReadOnly(true);
                setCurrentLicenseNoReadOnly(true);
                setCurrentDealerCodeReadOnly(true);
                setCurrentClientTypeReadOnly(true);
                setCurrentBankAcNoCodeReadOnly(true);
                setCurrentAccStatusCodeReadOnly(true);
                setCurrentBankNameCodeReadOnly(true);
                setCurrenteContractCodeReadOnly(true);
                setCurrentIdssReadOnly(false);
                setCurrentEstatementReadOnly(true);
                setCurrentLastUpdateDateReadOnly(true);
                setCurrentTNCAcceptDateReadOnly(true);
                setCurrentAddress1ReadOnly(true);
                setCurrentAddress2ReadOnly(true);
                setCurrentAddress3ReadOnly(true);
                setCurrentCountryReadOnly(true);
                setCurrentStateReadOnly(true);
                setCurrentPostCodeReadOnly(true);
                setCurrentPasswordReadOnly(false);
            }

        };

        const handleInitListAuthLimit = async (userId) => {

            setLoading(true);
            api.getAuthLimitListAdmin({"id": parseInt(userId)}).then((result: any) => {
                if (result.status == 1) {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    setLoading(false);
                }
                if (result.data) {
                    setShowResetAuthLimitBtn(result.data.list[0]['is_user'])
                    if (result.data.list.length > 0) {
                        let dataList = result.data.list;
                        setOperationAuthLimitOri(dataList);
                        let tempData = [];
                        for (let i = 0; i < dataList.length; i++) {
                            let tempEle = {};
                            tempEle['ut_name'] = dataList[i].ut_name;
                            tempEle['ut_id'] = dataList[i].ut_id;

                            let explmt = JSON.parse(dataList[i].explmt) ?? [];
                            let topup = JSON.parse(dataList[i].topup) ?? [];

                            for (let j = 0; j < explmt.length; j++) {
                                if (explmt[j]['exp_lmt_type'] == 1) {
                                    tempEle['explmt_buy'] = setElBuy(convertPositiveNumberHundred(explmt[j]['exp_lmt_amount'] ?? 0));
                                    tempEle['explmt_buy_enable'] = setElBuyChkBox(explmt[j]['exp_lmt_enable'] ?? false);
                                } else if (explmt[j]['exp_lmt_type'] == 2) {
                                    tempEle['explmt_sell'] = setElSell(convertPositiveNumberHundred(explmt[j]['exp_lmt_amount'] ?? 0));
                                    tempEle['explmt_sell_enable'] = setElSellChkBox(explmt[j]['exp_lmt_enable'] ?? false);
                                } else if (explmt[j]['exp_lmt_type'] == 3) {
                                    tempEle['explmt_nett'] = setElNett(convertPositiveNumberHundred(explmt[j]['exp_lmt_amount'] ?? 0));
                                    tempEle['explmt_nett_enable'] = setElNettChkBox(explmt[j]['exp_lmt_enable'] ?? false);
                                } else if (explmt[j]['exp_lmt_type'] == 4) {
                                    tempEle['explmt_gross'] = setElTotal(convertPositiveNumberHundred(explmt[j]['exp_lmt_amount'] ?? 0));
                                    tempEle['explmt_gross_enable'] = setElTotalChkBox(explmt[j]['exp_lmt_enable'] ?? false);
                                } else if (explmt[j]['exp_lmt_type'] == 5) {
                                    tempEle['explmt_idss'] = setElIDSS(convertPositiveNumberHundred(explmt[j]['exp_lmt_amount'] ?? 0));
                                    tempEle['explmt_idss_enable'] = setElIDSSChkBox(explmt[j]['exp_lmt_enable'] ?? false);
                                } else if (explmt[j]['exp_lmt_type'] == 6) {
                                    tempEle['explmt_idss'] = setElFST(convertPositiveNumberHundred(explmt[j]['exp_lmt_amount'] ?? 0));
                                    tempEle['explmt_idss_enable'] = setElFSTChkBox(explmt[j]['exp_lmt_enable'] ?? false);
                                }
                            }
                            ;

                            for (let j = 0; j < topup.length; j++) {
                                if (topup[j]['topup_type'] == 1) {
                                    tempEle['topup_buy'] = settopUpBuy(convertPositiveNumberHundred(topup[j]['topup_amount'] ?? 0));
                                    tempEle['topup_buy_enable'] = settopUpBuyChkBox(topup[j]['topup_enable'] ?? false);
                                } else if (topup[j]['topup_type'] == 2) {
                                    tempEle['topup_sell'] = settopupSell(convertPositiveNumberHundred(topup[j]['topup_amount'] ?? 0));
                                    tempEle['topup_sell_enable'] = settopUpSellChkBox(topup[j]['topup_enable'] ?? false);
                                } else if (topup[j]['topup_type'] == 3) {
                                    tempEle['topup_nett'] = settopUpNett(convertPositiveNumberHundred(topup[j]['topup_amount'] ?? 0));
                                    tempEle['topup_nett_enable'] = settopUpNettChkBox(topup[j]['topup_enable'] ?? false);
                                } else if (topup[j]['topup_type'] == 4) {
                                    tempEle['topup_gross'] = settopUpTotal(convertPositiveNumberHundred(topup[j]['topup_amount'] ?? 0));
                                    tempEle['topup_gross_enable'] = settopUpTotalChkBox(topup[j]['topup_enable'] ?? false);
                                } else if (topup[j]['topup_type'] == 5) {
                                    tempEle['topup_idss'] = settopUpIDSS(convertPositiveNumberHundred(topup[j]['topup_amount'] ?? 0));
                                    tempEle['topup_idss_enable'] = settopUpIDSSChkBox(topup[j]['topup_enable'] ?? false);
                                } else if (topup[j]['topup_type'] == 6) {
                                    tempEle['topup_idss'] = settopUpFST(convertPositiveNumberHundred(topup[j]['topup_amount'] ?? 0));
                                    tempEle['topup_idss_enable'] = settopUpFSTChkBox(topup[j]['topup_enable'] ?? false);
                                }
                            }
                            ;
                            tempData.push(tempEle);
                        }


                    }
                }

            });
            setLoading(false);
        };

        const handleMatrixArea = () => {
            if (currentRole.toLowerCase() == "operation") {
                try {
                    return renderOperationForm(location.state.row.user_id, location.state.row.p_firstname + " " + location.state.row.p_lastname);

                } catch (e) {
                    return renderOperationForm(currentUserId, currentUserName + " " + currentUserLastName);
                    console.log(e)
                }
            } else if (currentRole.toLowerCase() == "superadmin") {
                setLoading(true);
                handlePopup(true, "Error", "Super admin access matrix is not editable.", popupManager)
                navigate("/" + process.env.REACT_APP_URL_PREFIX + "user_profile",);
                setLoading(false);
            } else {
                try {
                    if(isU3Version)return renderU3DealerForm(location.state.row.user_id, location.state.row.p_firstname + " " + location.state.row.p_lastname, setShowResetMarketBtn, setShowResetOrderBtn, setCurrentClientOrderPortion, clientMarketOri, clientOrderOri, popupManager, currentUserAccessLevel);
                    else return renderDealerForm(location.state.row.user_id, location.state.row.p_firstname + " " + location.state.row.p_lastname, setShowResetMarketBtn, setShowResetOrderBtn, setCurrentClientOrderPortion, clientMarketOri, clientOrderOri, popupManager, currentUserAccessLevel);
                } catch (e) {
                    if(isU3Version)return renderU3DealerForm(currentUserId, currentUserName + " " + currentUserLastName, setShowResetMarketBtn, setShowResetOrderBtn, setCurrentClientOrderPortion, clientMarketOri, clientOrderOri, popupManager, currentUserAccessLevel);
                    else return renderDealerForm(currentUserId, currentUserName + " " + currentUserLastName, setShowResetMarketBtn, setShowResetOrderBtn, setCurrentClientOrderPortion, clientMarketOri, clientOrderOri, popupManager, currentUserAccessLevel);
                    console.log(e)
                }
            }
        }

        const handleBackBtn = async () => {
            await setLoading(true);
            navigate("/" + process.env.REACT_APP_URL_PREFIX + "user_profile", {state: {mode: 'back'}});
            setLoading(false);
        };

        const handleSaveBtn = async () => {
            setLoading(true);

            if (currentItemToSave == 'profile') {
                handleSaveProfile();
            } else if (currentItemToSave == 'accessMatrix') {
                handleSaveMatrices();
            } else {
                handleSaveAuthLimit();
            }
        }

        const handleInitList = async (userId, userRole) => {
            setLoading(true);
            let param = {"id": userId}

            let itemSet = currentItemToSave;
            api.getUserItems(param).then((result: any) => {

                if (result.status == 1) {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    setLoading(false);
                }
                if (result.data) {
                    if (result.data.list.length > 0) {
                        setCurrentUserTypeAccessLevel(result.data?.list[0] );
                        if (userRole.toLowerCase() != 'operation') {

                            initItems(result.data.list[0]);
                            initOrderItems(result.data.list[0]);
                            setIsUserLvlClientProf(result.data.list[0]['is_user'])
                            if ((location.state.row.uc_name).toLowerCase() != 'operation' && currentPort == 'accessMatrix' && (currentClientOrderPortion == 'market' || currentClientOrderPortion == 'order')) {
                                if (currentClientOrderPortion === 'market') {
                                    setShowResetMarketBtn(result.data.list[0]['is_equity'])
                                } else if (currentClientOrderPortion === 'order') {
                                    setShowResetMarketBtn(result.data.list[0]['is_order'])
                                }
                            }
                        }


                    } else {
                        initItems(null);
                        initOrderItems(null);

                    }
                }
                setLoading(false);
            });

            api.getOperationAccessMatListUserLvl({id: userId}).then((result: any) => {
                if (result.status == 1) {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    setLoading(false);
                    return false;
                }

                if (result.data.is_user) {
                    setIsUserLvlProf(result.data?.is_user)
                    setShowResetAMBtn(result.data?.is_user);
                } else {
                    setIsUserLvlProf(false)
                    setShowResetAMBtn(false);
                }

                if (result.data.access) {
                    if (result.data.access.length > 0) {
                        initOperationItems(result.data.access);
                    }
                }
                setLoading(false);
            });

        };


        const initOperationItems = (accessList) => {

            setOperationMatrixOri(accessList);
            accessList.forEach((value, index) => {
                let funcId = (!isNaN(parseInt(value['page'])) ? parseInt(value['page']) : 0);
                let ctrlBin = (!isNaN(parseInt(value['ctrl'])) ? parseInt(value['ctrl']) : 0);
                let parentLabel = (getNavItemById(funcId)['parent_lbl'] ?? "").replace(/\s/g, "").replaceAll(/[\(\)]/g, "").toLowerCase();
                let specialLabel = (getNavItemById(funcId)['special_lbl'] ?? "").replace(/\s/g, "").replaceAll(/[\(\)]/g, "").toLowerCase();
                let label = (getNavItemById(funcId)['actualLbl'] ?? "").replace(/\s/g, "").toLowerCase();
                let currentLabel = label.replaceAll(' ', '').replaceAll(/[\(\)]/g, "").toLowerCase()
                let ctrlBinFunc = zeroFill(Array.from(changeBase(ctrlBin, 10, 2)).reverse().join(''), 4)

                if (parentLabel.includes("riskmgmt") || parentLabel.includes("masterfile")) {
                    currentLabel = (parentLabel == "" ? "" : parentLabel + "-") + label;
                }
                if (funcId == 11 || funcId == 12 || funcId == 58 || funcId == 59 || funcId == 60) {
                    currentLabel = specialLabel + "-" + label;
                }
                if (funcId == 64 || funcId == 65) {
                    return;
                }

                if (funcId == 4) {
                    let specialPerm = (!isNaN(parseInt(value['am'])) ? parseInt(value['am']) : 0);
                    let amId = 64;
                    let alId = 65;
                    let amLabel = (getNavItemById(amId)['actualLbl'] ?? "").replace(/\s/g, "").replaceAll(/[\(\)]/g, "").toLowerCase();
                    let alLabel = (getNavItemById(alId)['actualLbl'] ?? "").replace(/\s/g, "").replaceAll(/[\(\)]/g, "").toLowerCase();
                    let specialControlBin = "1110";

                    switch (specialPerm) {
                        case 0 :
                            checkBoxHelper(amId, amLabel, 0);
                            checkBoxHelper(alId, alLabel, 0);
                            break;
                        case 1 :
                            checkBoxHelper(amId, amLabel, specialControlBin);
                            break;
                        case 2 :
                            checkBoxHelper(alId, alLabel, specialControlBin);
                            break;
                        case 3 :
                            checkBoxHelper(amId, amLabel, specialControlBin);
                            checkBoxHelper(alId, alLabel, specialControlBin);
                            break;
                    }

                }

                checkBoxHelper(funcId, currentLabel, ctrlBinFunc);


            });
        }
        const checkBoxHelper = (funcId, currentLabel, ctrlBinFunc) => {

            let elementIdAllHeader = funcId + "_" + currentLabel + "_all_header";
            let elementIdAll = funcId + "_" + currentLabel + "_all";
            let elementIdView = funcId + "_" + currentLabel + "_view";
            let elementIdViewSub = funcId + "_view_header";
            let elementIdAdd = funcId + "_" + currentLabel + "_add";
            let elementIdAddSub = funcId + "_add_header";
            let elementIdEdit = funcId + "_" + currentLabel + "_edit";
            let elementIdEditSub = funcId + "_edit_header";
            let elementIdDelete = funcId + "_" + currentLabel + "_delete";
            let elementIdDeleteSub = funcId + "_delete_header";

            let checkboxAllHeader = document.getElementById(elementIdAllHeader);
            let checkboxAll = document.getElementById(elementIdAll);
            let checkboxView = document.getElementById(elementIdView);
            let checkboxAdd = document.getElementById(elementIdAdd);
            let checkboxEdit = document.getElementById(elementIdEdit);
            let checkboxDelete = document.getElementById(elementIdDelete);

            //Subs
            let checkboxViewSubs = document.getElementById(elementIdViewSub);
            let checkboxAddSubs = document.getElementById(elementIdAddSub);
            let checkboxEditSubs = document.getElementById(elementIdEditSub);
            let checkboxDelSubs = document.getElementById(elementIdDeleteSub);


            if (checkboxAllHeader) checkboxAllHeader.checked = false;
            if (checkboxAll) checkboxAll.checked = false;
            if (checkboxView) checkboxView.checked = false
            if (checkboxAdd) checkboxAdd.checked = false
            if (checkboxEdit) checkboxEdit.checked = false
            if (checkboxDelete) checkboxDelete.checked = false


            if (ctrlBinFunc == "1111" || ctrlBinFunc == 1111) {
                if (checkboxAllHeader) checkboxAllHeader.checked = true;
                if (checkboxAll) checkboxAll.checked = true;
                let isAbleView = ctrlBinFunc[0];
                let isAbleAdd = ctrlBinFunc[1];
                let isAbleEdit = ctrlBinFunc[2];
                let isAbleDelete = ctrlBinFunc[3];
                if (checkboxViewSubs) checkboxViewSubs.checked = true
                if (checkboxAddSubs) checkboxAddSubs.checked = true
                if (checkboxEditSubs) checkboxEditSubs.checked = true
                if (checkboxDelSubs) checkboxDelSubs.checked = true

                if (checkboxView) checkboxView.checked = isAbleView == 1 ? true : false
                if (checkboxAdd) checkboxAdd.checked = isAbleAdd == 1 ? true : false
                if (checkboxEdit) checkboxEdit.checked = isAbleEdit == 1 ? true : false
                if (checkboxDelete) checkboxDelete.checked = isAbleDelete == 1 ? true : false
            } else {


                let isAbleView = ctrlBinFunc[0];
                let isAbleAdd = ctrlBinFunc[1];
                let isAbleEdit = ctrlBinFunc[2];
                let isAbleDelete = ctrlBinFunc[3];

                if (checkboxView) checkboxView.checked = isAbleView == 1 ? true : false
                if (checkboxAdd) checkboxAdd.checked = isAbleAdd == 1 ? true : false
                if (checkboxEdit) checkboxEdit.checked = isAbleEdit == 1 ? true : false
                if (checkboxDelete) checkboxDelete.checked = isAbleDelete == 1 ? true : false
                if (checkboxViewSubs) checkboxViewSubs.checked = isAbleView == 1 ? true : false
                if (checkboxAddSubs) checkboxAddSubs.checked = isAbleAdd == 1 ? true : false
                if (checkboxEditSubs) checkboxEditSubs.checked = isAbleEdit == 1 ? true : false
                if (checkboxDelSubs) checkboxDelSubs.checked = isAbleDelete == 1 ? true : false

            }
        }

        const handleOperationSaveItems = () => {
            let renderList = getFunctionMenus();
            let saveList = [];
            let specialPermProfile = {};

            for (let i = 0; i < renderList.length; i++) {
                let parEle = renderList[i];
                let parentLbl = parEle['label'].replaceAll(/[\(\)]/g, "")


                let perm = zeroFill(checkPermCheckedById(parEle['id'], parentLbl), 4);
                var itemInDecimal = changeBase(Array.from(perm.toString().replaceAll(",", "")).reverse().join(''), 2, 10);
                saveList.push({"page": parEle['id'], "ctrl": itemInDecimal, "am": 0})

                if (renderList[i]['subMenu']) {
                    let dataEle = renderList[i]['subMenu'];

                    for (let j = 0; j < dataEle.length; j++) {
                        let perm = zeroFill(checkPermCheckedById(dataEle[j]['id'], parentLbl), 4);
                        var itemInDecimal = changeBase(Array.from(perm.toString().replaceAll(",", "")).reverse().join(''), 2, 10);


                        saveList.push({"page": dataEle[j]['id'], "ctrl": itemInDecimal, "am": 0})
                        if (dataEle[j]['subMenu2']) {
                            let dataEle2 = dataEle[j]['subMenu2'];
                            for (let k = 0; k < dataEle2.length; k++) {
                                let perm = zeroFill(checkPermCheckedById(dataEle2[k]['id'], parentLbl), 4);
                                var itemInDecimal = changeBase(Array.from(perm.toString().replaceAll(",", "")).reverse().join(''), 2, 10);
                                if (dataEle2[k]['id'] == 64 || dataEle2[k]['id'] == 65 || dataEle2[k]['id'] == 4) {
                                    specialPermProfile[dataEle2[k]['id']] = itemInDecimal;
                                } else {
                                    saveList.push({"page": dataEle2[k]['id'], "ctrl": itemInDecimal, "am": 0})

                                }
                            }
                        }
                    }
                }


            }

            //Special for profile only
            let specialAmAL = 0;
            if (specialPermProfile[64] == 1 && specialPermProfile[65] == 1) {
                specialAmAL = 3;
            } else if (specialPermProfile[64] == 1 && specialPermProfile[65] == 0) {

                specialAmAL = 1;
            } else if (specialPermProfile[64] == 0 && specialPermProfile[65] == 1) {
                specialAmAL = 2;
            }

            let tempDat = {"page": 4, "ctrl": specialPermProfile[4], "am": specialAmAL};
            //Splice into 3 because its id 4
            saveList.splice(3, 0, tempDat);

            const sortedArray = saveList.sort((a, b) => a.page - b.page);

            let data = {
                "id": parseInt(location.state.row.user_id),
                "access": JSON.stringify(sortedArray),
            };

            setLoading(false);

            api.updateOperationAccessMatListUserLevel(data).then((result: any) => {
                if (result.status == 1) {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    setLoading(false);
                }
                if (result != false && result.status == 0) {
                    handlePopup(true, "Success", "Access matrix updated successfully.", popupManager)
                    // handlePopup(true, "Success", "Access matrix updated successfully.", popupManager, true, "user_profile", navigate)
                    handleInitList(location.state.row.user_id, (location.state.row.uc_name).toLowerCase())

                    setLoading(false);


                } else {
                    setLoading(false);
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager);
                }
            });


        }

        const checkPermCheckedById = (funcId, parentLbl = "") => {
            parentLbl = parentLbl.replace(/\s/g, "").toLowerCase();
            let label = getNavItemById(funcId)['actualLbl'].replace(/\s/g, "").replaceAll(/[\(\)]/g, "").toLowerCase();
            let specialLabel = (getNavItemById(funcId)['special_lbl'] ?? "").replace(/\s/g, "").replaceAll(/[\(\)]/g, "").toLowerCase();
            let currentPerm = 0;
            let currentLabel = label;
            if (funcId == 11 || funcId == 12 || funcId == 58 || funcId == 59 || funcId == 60) {
                currentLabel = specialLabel + "-" + label;
            } else if ((parentLbl.includes("riskmgmt") || parentLbl.includes("masterfile")) && parentLbl != label) {
                currentLabel = parentLbl + "-" + label;
            }
            let elementIdAllHeader = funcId + "_" + currentLabel + "_all_header";
            let elementIdAll = funcId + "_" + currentLabel + "_all";
            let elementIdViewHeader = funcId + "_view_header";
            let elementIdView = funcId + "_" + currentLabel + "_view";
            let elementIdAddHeader = funcId + "_add_header";
            let elementIdAdd = funcId + "_" + currentLabel + "_add";
            let elementIdEditHeader = funcId + "_edit_header";
            let elementIdEdit = funcId + "_" + currentLabel + "_edit";
            let elementIdDeleteHeader = funcId + "_delete_header";
            let elementIdDelete = funcId + "_" + currentLabel + "_delete";


            let checkboxAllHeader = document.getElementById(elementIdAllHeader);
            let checkboxAll = document.getElementById(elementIdAll);
            let checkboxViewHeader = document.getElementById(elementIdViewHeader);
            let checkboxView = document.getElementById(elementIdView);
            let checkboxAddHeader = document.getElementById(elementIdAddHeader);
            let checkboxAdd = document.getElementById(elementIdAdd);
            let checkboxEditHeader = document.getElementById(elementIdEditHeader);
            let checkboxEdit = document.getElementById(elementIdEdit);
            let checkboxDeleteHeader = document.getElementById(elementIdDeleteHeader);
            let checkboxDelete = document.getElementById(elementIdDelete);

            if (checkboxAllHeader && checkboxAllHeader.checked) currentPerm = 1111;
            else if (checkboxAll && checkboxAll.checked) currentPerm = 1111;
            else {
                let perms = "";
                if (checkboxView && checkboxView.checked) perms += 1
                else if (checkboxViewHeader && checkboxViewHeader.checked) perms += 1
                else perms += 0

                if (checkboxAdd && checkboxAdd.checked) perms += 1
                else if (checkboxAddHeader && checkboxAddHeader.checked) perms += 1

                else perms += 0

                if (checkboxEdit && checkboxEdit.checked) perms += 1
                else if (checkboxEditHeader && checkboxEditHeader.checked) perms += 1

                else perms += 0

                if (checkboxDelete && checkboxDelete.checked) perms += 1
                else if (checkboxDeleteHeader && checkboxDeleteHeader.checked) perms += 1
                else perms += 0

                currentPerm = perms;
            }
            return currentPerm;
        }


        const initItems = (accessLevel) => {
            if (accessLevel == null) return;

            var exchangeListLength = getExchangeQty();
            var exchangeLists = getExchangeMenu();
            let accessLvl = {};
            if (accessLevel) {
                accessLvl = JSON.parse(accessLevel['u_accesslevel'])
            }

            let exchangeSetup = (accessLvl?.al_exchange ? accessLvl?.al_exchange : 0);
            let toolsSetup = (accessLvl?.al_tools ?? {});

            // setClientMarketOri(exchangeSetup ?? []);
            setClientMarketOri({"al_exchange": exchangeSetup, "al_tools": toolsSetup});
            // let exchangeSetup = accessLvl.al_exchange ?? {};
            var base3Exchange = Array.from(changeBase(exchangeSetup[0], 10, 3)).reverse().join('');
            base3Exchange = zeroFill(base3Exchange, exchangeListLength);

            let market_depth = accessLvl?.al_depth ?? {
                "BM": 1,
                "SG": 1,
                "HK": 1,
                "O": 1,
                "NQ": 1,
                "A": 1,
                "P": 1,
                "OQ": 1,
                "N": 1,
                "Ca": 1,
                "Cc": 1,
                "L": 1,
                "AX": 1
            };

            for (let i = 0; i < exchangeLists.length; i++) {
                let radios = document.getElementsByName("eq_" + exchangeLists[i]["em_id"]);
                let value = base3Exchange[exchangeLists[i]["em_id"]];

                for (let j = 0; j < radios.length; j++) {
                    if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_disabled" && value == '0') {

                        radios[j].checked = true;
                        break;
                    } else if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_delay" && value == '2') {
                        radios[j].checked = true;
                        break;
                    } else if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_live" && value == '1') {
                        radios[j].checked = true;
                        break;
                    }
                }

                let exchangeItemId = getEmIdByECode(exchangeLists[i]["e_code"]);
                let depthItemLevel = market_depth[exchangeLists[i]["e_code"]] ?? 0;
                let checkBox = document.getElementById("mkdepth_" + exchangeItemId + "_" + depthItemLevel);

                if (depthItemLevel != 1 && depthItemLevel != 0) {
                    //disable all checkboxes
                    for (let l = 0; l < 3; l++) {
                        let checkboxId = ('mkdepth_' + exchangeItemId + '_' + l).toLowerCase();
                        let checkbox = document.getElementById(checkboxId);
                        if (checkbox) checkbox.checked = false
                    }
                    if (checkBox) checkBox.checked = true;

                } else if (depthItemLevel == 0) {
                    checkBox = document.getElementById("mkdepth_" + exchangeItemId + "_1");
                } else {
                    if (checkBox) checkBox.checked = true;
                }


            }

            let toolsMenuList = getToolsMenu();
            var base2Exchange = Array.from(changeBase(toolsSetup, 10, 2)).reverse().join('');
            base2Exchange = zeroFill(base2Exchange, toolsMenuList.length);

            var checkCounter = 0;
            for (let i = 0; i < toolsMenuList.length; i++) {
                let value = base2Exchange[toolsMenuList[i]["id"]];
                let checkbox = document.getElementById("tool_" + toolsMenuList[i]["id"]);
                if (!checkbox) continue;
                if (value == "1") {
                    if (checkbox) checkbox.checked = true;
                    checkCounter += 1;
                } else {
                    if (checkbox) checkbox.checked = false

                }
            }
            if (checkCounter == toolsMenuList.length) document.getElementById('tool_all').checked = true;


        }

        const initOrderItems = (accessLevel) => {
            if (accessLevel == null) return;

            let accessLvl = {};
            if (accessLevel) {
                accessLvl = JSON.parse(accessLevel['u_accesslevel'])
            }

            let orderSetup = (accessLvl?.al_validity ?? {});
            setClientOrderOri(orderSetup)
            if (orderSetup) {
                var exchangeListLength = getOrderValidityItem().length;
                var checkAllLength = (getOrderValidityItem().length);
                var exchangeLists = getExchangeMenu();
                let orderItem = getOrderValidityItem();
                let al_validity = {};
                let al_validate_counter = {};

                for (let j = 0; j < exchangeLists.length; j++) {
                    let itemVal = orderSetup[exchangeLists[j]['em_code']] ?? orderSetup[exchangeLists[j]['e_code']];

                    //Clear all checkboxes
                    for (let l = 0; l < exchangeListLength; l++) {
                        let checkboxId = ('ordervalidity_' + exchangeLists[l]['e_code']).toLowerCase();
                        let checkbox = document.getElementById(checkboxId);
                        if (checkbox) checkbox.checked = false
                    }

                    var base2Exchange = Array.from(changeBase(itemVal, 10, 2)).reverse().join('');
                    base2Exchange = zeroFill(base2Exchange, exchangeListLength);


                    for (let k = 0; k < exchangeListLength; k++) {
                        let checkboxId = (orderItem[k] + "_" + exchangeLists[j]['e_code']).toLowerCase();
                        let checkbox = document.getElementById(checkboxId);
                        if (!checkbox) continue;

                        if (base2Exchange[k] == "1") {
                            if (checkbox) checkbox.checked = true;
                            if (!al_validate_counter[exchangeLists[j]['e_code']]) al_validate_counter[exchangeLists[j]['e_code']] = 0;
                            al_validate_counter[exchangeLists[j]['e_code']] += 1;
                        } else {
                            if (checkbox) checkbox.checked = false
                        }
                    }
                }

                for (var prop in al_validate_counter) {
                    if (al_validate_counter[prop] == checkAllLength) {
                        let checkboxId = "ordervalidity_" + prop.toLowerCase()
                        let checkbox = document.getElementById(checkboxId);
                        if (checkbox) checkbox.checked = true;
                    }
                }
            }

            let exchgOrders = (accessLvl?.al_trading ?? []);

            if (exchgOrders) {
                let tempOrder = {};
                for (let key in exchgOrders) {
                    let array = exchgOrders[key];
                    for (let i = 0; i < array.length; i++) {
                        let binaryReversed = array[i].toString(2).split('').reverse().join('');
                        base2Exchange = zeroFill(binaryReversed, 33 ?? 0);
                        array[i] = base2Exchange;
                        tempOrder[key] = array;
                    }
                }

                for (let key in tempOrder) {
                    let exchange = key.toLowerCase();
                    let binaries = tempOrder[key];
                    let orderItems = getExchangeOrderType();

                    for (let i = 0; i < orderItems.length - 1; i++) {
                        let normalItems = orderItems[i]['subMenu'];
                        let calculateQty = 0;

                        for (let j = 0; j < normalItems.length; j++) {
                            let eleLabel = normalItems[j]['label'] ?? "";
                            let eleLabelTemp = normalItems[j]['label'] ?? "";
                            let specialPrefix = ""
                            let elePosition = normalItems[j]['position'] ?? "";
                            let checkboxId = normalItems[j]['checkboxId'] ?? "";


                            if (binaries[i][elePosition] == 1) {
                                let checkBox = document.getElementById(checkboxId + exchange);
                                if (checkBox) checkBox.checked = true;
                                calculateQty += 1;
                            }

                            if (eleLabel.includes(';')) {
                                eleLabel = eleLabel.split(";")[1];
                                specialPrefix = eleLabelTemp.split(";")[0] + "_";
                                if (specialPrefix.includes("odd")) specialPrefix = "oddlots_";
                            }

                            if (i == 3) specialPrefix = "advanced_";
                            if (i == 4) specialPrefix = "others_";

                            if (calculateQty == normalItems.length) {
                                let checkBoxAll = document.getElementById(specialPrefix + exchange);
                                if (checkBoxAll) checkBoxAll.checked = true;
                            }

                        }

                    }

                }

            }

        }

        const handleSaveMatrices = async () => {
            setLoading(true);
            if ((location.state.row.uc_name).toLowerCase() == 'operation') {
                handleOperationSaveItems();
            } else {
                var exchangeListLength = getExchangeQty();
                var exchangeLists = getExchangeMenu();
                let accessLvl = currentUserAccessLevel ?? {};

                let exchangeSetup = (accessLvl?.al_exchange ? accessLvl?.al_exchange : 0);

                var base3Exchange = Array.from(changeBase(exchangeSetup, 10, 3)).reverse().join('');
                base3Exchange = zeroFill(base3Exchange, exchangeListLength);


                let exchangeItem = [];
                let depthItem = {};
                for (let i = 0; i < exchangeListLength; i++) {
                    let radios = document.getElementsByName("eq_" + exchangeLists[i]?.em_id);

                    if (!exchangeLists[i]?.em_id && exchangeLists[i]?.em_id != 0) {
                        exchangeItem[i] = '0';
                        break;
                    }
                    if (i == 4) exchangeItem[4] = '0';
                    if (i == 7) exchangeItem[7] = '0';
                    if (i == 11) exchangeItem[11] = '0';
                    if (i == 14) exchangeItem[14] = '0';
                    if (i == 15) exchangeItem[15] = '0';

                    if (exchangeLists[i]["em_id"] != '4' && exchangeLists[i]["em_id"] != '7' && exchangeLists[i]["em_id"] != '11' && exchangeLists[i]["em_id"] != '14' && exchangeLists[i]["em_id"] != '15') {
                        for (let j = 0; j < radios.length; j++) {
                            if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_disabled" && radios[j].checked) {
                                exchangeItem[exchangeLists[i]["em_id"]] = "0";
                                break;
                            } else if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_delay" && radios[j].checked) {
                                exchangeItem[exchangeLists[i]["em_id"]] = "2";
                                break;
                            } else if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_live" && radios[j].checked) {
                                exchangeItem[exchangeLists[i]["em_id"]] = "1";
                                break;
                            }
                        }


                    }
                }

                for (let i = 0; i < exchangeListLength; i++) {
                    //get chcecked checkboxes of mkdepth
                    let depthItemLevel = 1;
                    for (let k = 1; k < 4; k++) {
                        if (!exchangeLists[i]?.em_id || typeof exchangeLists[i].em_id === 'undefined') continue;
                        let checkboxId = ('mkdepth_' + exchangeLists[i]?.em_id + '_' + k).toLowerCase();
                        let checkbox = document.getElementById(checkboxId);
                        if (checkbox && checkbox.checked) {
                            depthItemLevel = k;
                        }
                    }
                    let e_code = exchangeLists[i]?.e_code;
                    if (e_code) {
                        depthItem[e_code] = depthItemLevel;
                    }
                }

                let toolsMenuList = getToolsMenu();

                let toolsSetup = (accessLvl?.al_tools ?? {});
                var base2Exchange = Array.from(changeBase(toolsSetup, 10, 2)).reverse().join('');
                base2Exchange = zeroFill(base2Exchange, toolsMenuList.length);

                let toolItem = [];
                for (let i = 0; i < toolsMenuList.length; i++) {
                    let value = base2Exchange[toolsMenuList[i]["id"]];
                    let checkbox = document.getElementById("tool_" + toolsMenuList[i]["id"]);
                    if (value && checkbox.checked) {
                        toolItem[toolsMenuList[i]["id"]] = 1
                    } else {
                        toolItem[toolsMenuList[i]["id"]] = 0

                    }
                }

                var equityItemDecimal = changeBase(Array.from(exchangeItem.toString().replaceAll(",", "")).reverse().join(''), 3, 10);
                var toolItemDecimal = changeBase(Array.from(toolItem.toString().replaceAll(",", "")).reverse().join(''), 2, 10);


                let orderItems = getExchangeOrderType();

                let orderValidityItem = {};
                let orderValidityItemSubmit = {};

                let orderItemSubmit = {};
                let debugItem = {};


                for (let i = 0; i < orderItems.length; i++) {
                    let tempOrderItm0 = new Array(33).fill("0");
                    switch (i) {
                        //Orders
                        default:
                            let normalItems = orderItems[i]['subMenu'];

                            for (let k = 0; k < exchangeLists.length; k++) {
                                let currentExchange = exchangeLists[k]['e_code'];

                                for (let j = 0; j < normalItems.length; j++) {

                                    let normalMainEle = normalItems[j]['label'] ?? "";
                                    let elePosition = normalItems[j]['position'] ?? "";
                                    let tempEle = normalItems[j]['label'] ?? "";
                                    let specialPrefix = "";

                                    if (tempEle.includes(';')) {
                                        tempEle = normalMainEle.split(";")[1];
                                        specialPrefix = "_" + normalMainEle.split(";")[0];
                                    }
                                    let elementId = (tempEle.replaceAll(' ', '') + specialPrefix + "_" + currentExchange).toLowerCase();
                                    let checkBox = document.getElementById(elementId);

                                    tempOrderItm0[elePosition] = checkBox.checked ? "1" : "0";
                                    if (!orderItemSubmit[currentExchange]) orderItemSubmit[currentExchange] = [];
                                    if (!debugItem[currentExchange]) debugItem[currentExchange] = [];
                                    debugItem[currentExchange][i] = tempOrderItm0.join().replaceAll(",", "");
                                    let conversion = changeBase(Array.from(tempOrderItm0.join().replaceAll(",", "")).reverse().join(''), 2, 10);
                                    orderItemSubmit[currentExchange][i] = conversion;
                                }
                            }
                            break

                        case 5:
                            //Validity
                            let validityItems = orderItems[i]['subMenu'];
                            for (let i = 0; i < validityItems.length; i++) {
                                for (let j = 0; j < exchangeLists.length; j++) {
                                    let checkboxId = (validityItems[i]['code'] + "_" + exchangeLists[j]['e_code']).toLowerCase();
                                    let checkbox = document.getElementById(checkboxId);
                                    if (!orderValidityItem[exchangeLists[j]['e_code']]) orderValidityItem[exchangeLists[j]['e_code']] = (checkbox.checked) ? "1" : "0";
                                    else orderValidityItem[exchangeLists[j]['e_code']] += (checkbox.checked) ? "1" : "0";
                                }
                                for (var prop in orderValidityItem) {
                                    base2Exchange = zeroFill(orderValidityItem[prop], validityItems.length ?? 0);
                                    orderValidityItemSubmit[prop] = changeBase(Array.from(base2Exchange.toString().replaceAll(",", "")).reverse().join(''), 2, 10);
                                }
                            }
                            break

                    }

                }

                // console.log(depthItem)
                if (currentClientOrderPortion == 'market') {
                    handleSaveMarketMatrix(equityItemDecimal, toolItemDecimal, depthItem);
                } else if (currentClientOrderPortion == 'order') {
                    handleSaveOrderMatrix(orderValidityItemSubmit, orderItemSubmit);

                }


            }
        }

        const handleSaveMarketMatrix = async (equity, tool, depth) => {
            setLoading(true);

            let data = {
                "id": parseInt(currentAccountId),
                "equity": equity,
                "tool": tool,
                "depth": JSON.stringify(depth)
            };

            api.saveUserMarketMatrix(data).then((result: any) => {
                setLoading(false);
                if (result != false && result.status == 0) {
                    setLoading(false);
                    handlePopup(true, "Success", "User market matrix information updated successfully.", popupManager);
                    handleResetBtn();
                    currentPort = 'accessMatrix';
                    initItems(currentUserAccessLevel);
                } else {
                    setLoading(false);
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager);
                }
            });


        };

        const handleSaveOrderMatrix = async (items, orderItems) => {
            await setLoading(true);
            let data = {
                "id": parseInt(currentAccountId),
                "validity": JSON.stringify(items).replace(/\s/g, ""),
                // "order": "[]"
                "order": JSON.stringify(orderItems).replace(/\s/g, ""),
            };

            api.updateOrderValidityUser(data).then((result: any) => {
                if (result != false && result.status == 0) {
                    handlePopup(true, "Success", "User order matrix updated successfully.", popupManager)
                    handleResetBtn();
                    setLoading(false);

                } else handlePopup(true, "Error", convertErMsg(result.message), popupManager);
                setLoading(false);
            });


        }

        const handleSaveProfile = async () => {

            await setLoading(true);

            let role = currentRole.toLowerCase();
            if (currentUserId.replace(/ /g, "").length == 0) {
                handlePopup(true, "Error", "Mandatory Field - User ID not entered.", popupManager)
                setLoading(false);
                return false;
            }
            if (isNaN(parseInt(currentUserType))) {
                handlePopup(true, "Error", "Mandatory Field - User Type not selected.", popupManager)
                setLoading(false);
                return;
            }

            if (currentUserName.length < 2) {
                handlePopup(true, "Error", "Mandatory Field - First Name not entered.", popupManager, null, null, null, "(First Name : Minimum 1 characters)")

                setLoading(false);
                return false;
            }


            if (currentUserLastName.length < 2) {
                handlePopup(true, "Error", "Mandatory Field - Last Name not entered.", popupManager, null, null, null, "(Last Name : Minimum 1 characters)")

                setLoading(false);
                return false;
            }

            if (currentNationality.length == 0) {
                handlePopup(true, "Error", "Mandatory Field - Nationality not entered.", popupManager)
                setLoading(false);
                return false;
            }


            let endDate = dayjs(currentExpiredOn).format(momentTimeFormat());
            let dOb = dayjs(currentDOB).format(momentTimeFormat());

            let currentStatusActual = currentStatus;
            if (currentPrevStatus == 4 && currentStatus == 1) currentStatusActual = 11;

            let param = {
                "id": currentAccountId,
                "first_name": currentUserName,
                "last_name": currentUserLastName,
                "title": currentUserTitle,
                "branch": currentBranch[0] == null ? null : parseInt(currentBranch[0]['code']),
                "nric": currentNRIC == "" ? null : currentNRIC.substring(0, 12),
                "dob": dOb == "" ? null : dOb,
                "gender": currentGenderMaleChecked,
                "phone": currentHomePhone,
                "office": currentOfficePhone,
                "handphone": currentMobilePhone,
                "email": currentEmail,
                "passport": currentPassport == "" ? null : currentPassport,
                "nationality": currentNationality,
                "race": currentRace == 'OTHERS' ? 'OTHERS-' + currentOtherRace : currentRace,
                "address1": currentAddress1,
                "address2": currentAddress2,
                "address3": currentAddress3,
                "postcode": currentPostCode.substring(0, 5),
                "city": "",
                "state": currentState,
                "country": currentCountry,
                "user_status": currentStatusActual,
                "access_status": isNaN(parseInt(currentEnaYesChecked)) ? null : parseInt(currentEnaYesChecked),
                "end_date": endDate == "Invalid Date" ? null : endDate,
                "start_time": currentStartTime == "" ? null : currentStartTime,
                "end_time": currentStopTime == "" ? null : currentStopTime,
                "is_monday": monChecked ? 1 : 0,
                "is_tuesday": tueChecked ? 1 : 0,
                "is_wednesday": wedChecked ? 1 : 0,
                "is_thursday": thuChecked ? 1 : 0,
                "is_friday": friChecked ? 1 : 0,
                "is_saturday": satChecked ? 1 : 0,
                "is_sunday": sunChecked ? 1 : 0,
                "table_type": currentTableId,
                "is_auto": generatePwToggle
            }


            if (role != 'operation') {
                let isAccAvail = false;
                if (tableData) {
                    isAccAvail = true;
                    for (let i = 0; i < tableData.length; i++) {
                        let a_statusEle = document.getElementById('a_status_' + tableData[i]['a_acctno']);
                        tableData[i]['a_status'] = a_statusEle.value;
                    }
                }


                param = {
                    "id": currentAccountId,
                    "phone": currentHomePhone,
                    "office": currentOfficePhone,
                    "handphone": currentMobilePhone,
                    "email": currentEmail,
                    "user_status": currentStatusActual,
                    "idss": currentIdss ?? 1,
                    "account": JSON.stringify(isAccAvail ? tableData : []),
                    "table_type": currentTableId,
                    "is_auto": passwordShowToggle
                }
            }

            if (generatePwToggle && currentEmail.length < 5) {
                let emailCorrect = testEmailAddress(currentEmail);
                if (!emailCorrect) {
                    handlePopup(true, "Error", "Please enter a valid email if auto-generate password is checked.", popupManager)
                    setLoading(false);
                    return;
                }
            }

            if (currentPassword.trim().length > 0 && !generatePwToggle) {
                if (!isPasswordValid) {
                    let pWparam = {
                        "id": currentAccountId,
                        "table_type": currentTableId,
                        "password": currentPassword,
                        "is_new": 0
                    }

                    api.validatePw(pWparam).then((result: any) => {
                        if (result.status == 1) {
                            handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                            setLoading(false);
                        }
                        if (result.status) {
                            setPasswordAlert(inLineConvertErMsg(result.message));
                            setIsPasswordValid(false);
                            setLoading(false);
                            return;
                        } else {
                            setPasswordAlert("");
                            setIsPasswordValid(true);
                            param['password'] = currentPassword;
                            handleUpdateApi(param);
                        }
                    });

                } else {
                    param['password'] = currentPassword;
                    handleUpdateApi(param);
                }
            } else {
                handleUpdateApi(param);
            }


            setLoading(false);
        };

        const handleSaveAuthLimit = async () => {
            await setLoading(true);


            let param = {
                "id": currentAccountId,
                "explmt": "[" +
                    "{\"risktype\":1,\"riskenable\":" + elBuyChkBox + ",\"amount\":" + elBuy.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":2,\"riskenable\":" + elSellChkBox + ",\"amount\":" + elSell.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":3,\"riskenable\":" + elNettChkBox + ",\"amount\":" + elNett.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":4,\"riskenable\":" + elTotalChkBox + ",\"amount\":" + elTotal.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":5,\"riskenable\":" + elIDSSChkBox + ",\"amount\":" + elIDSS.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":6,\"riskenable\":" + elFSTChkBox + ",\"amount\":" + elFST.toString().replace(/,/g, '') + "}" +
                    "]",
                "topup": "[" +
                    "{\"risktype\":1,\"riskenable\":" + topUpBuyChkBox + ",\"amount\":" + topUpBuy.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":2,\"riskenable\":" + topUpSellChkBox + ",\"amount\":" + topUpSell.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":3,\"riskenable\":" + topUpNettChkBox + ",\"amount\":" + topUpNett.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":4,\"riskenable\":" + topUpTotalChkBox + ",\"amount\":" + topUpTotal.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":5,\"riskenable\":" + topUpIDSSChkBox + ",\"amount\":" + topUpIDSS.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":6,\"riskenable\":" + topUpFSTChkBox + ",\"amount\":" + topUpFST.toString().replace(/,/g, '') + "}" +
                    "]"
            }


            api.updateAuthLimitAdmin(param).then((result: any) => {
                if (result != false && result.status == 0) {
                    handleInitListAuthLimit(currentAccountId);
                    handlePopup(true, "Success", "Authorization limit updated successfully.", popupManager, true, "user_profile", navigate)
                    setLoading(false);

                } else {
                    setLoading(false);
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                }
            });


        }


        const handleResetBtn = async () => {

            if (currentItemToSave == 'profile') {
                if (currentMode.toLowerCase() == 'edit') {
                    initProfileData();
                } else if (mode.toLowerCase() == 'add') {
                    location.reload();
                }
            } else if (currentItemToSave == 'accessMatrix') {
                handleInitList(currentAccountId, (location.state.row.uc_name).toLowerCase());
            } else {
                handleInitListAuthLimit(currentAccountId)
            }

        }

        const handleResetAuthLimit = async () => {
            await setLoading(true);
            popupManager.open(ModalConfirm, {
                title: 'Reset',
                content: 'By reset, Authorization Limit for this user will revert back to it\'s user type Authorization Limit setup.',
                onClose: (...params) => handleResetAuthLimitAPI(params[0])
            });

            setLoading(false);
        };

        const handleResetAuthLimitAPI = async (selBoolean) => {
            if (!selBoolean) return;

            api.removeAuthLimitAdmin({"id": currentAccountId}).then((result: any) => {
                if (result != false && result.status == 0) {
                    handleResetBtn();
                    setShowResetAuthLimitBtn(false);
                    handlePopup(true, "Success", "Authorization limit had been reset successfully.", popupManager);
                } else {
                    setLoading(false);
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager);
                }
            });


        }

        const handleResetAccessMatrix = async () => {

            if (!isUserLvlProf) {
                handlePopup(true, "Error", "Not allow to reset as access matrix is already on user type level.", popupManager)
                return false;
            }
            await setLoading(true);
            popupManager.open(ModalConfirm, {
                title: 'Reset',
                content: 'By reset, Access matrices for this user will revert back to it\'s user type access matrix setup.',
                onClose: (...params) => handleResetAccessMatrixAPI(params[0])
            });

            setLoading(false);
        };

        const handlResetToDefaultAccessMatrix = () => {
            if (currentClientOrderPortion == 'market') {
                handleResetMarket();
            } else if (currentClientOrderPortion == 'order') {
                handleResetOrder();
            }
        }
        const handleResetMarket = async () => {

            if (!showResetMarketBtn) {
                handlePopup(true, "Error", "Not allow to reset as Market setting is already on user type level.", popupManager)
                return false;
            }
            await setLoading(true);
            popupManager.open(ModalConfirm, {
                title: 'Reset',
                content: 'By reset, Market setting for this user will revert back to it\'s user type access matrix setup.',
                onClose: (...params) => handleResetMarketAPI(params[0])
            });

            setLoading(false);
        };

        const handleResetOrder = async () => {

            if (!showResetMarketBtn) {
                handlePopup(true, "Error", "Not allow to reset as Order setting is already on user type level.", popupManager)
                return false;
            }
            await setLoading(true);
            popupManager.open(ModalConfirm, {
                title: 'Reset',
                content: 'By reset, Order setting for this user will revert back to it\'s user type access matrix setup.',
                onClose: (...params) => handleResetOrderAPI(params[0])
            });

            setLoading(false);
        };

        const handleResetAccessMatrixAPI = async (selBoolean) => {
            if (!selBoolean) return;

            api.resetOperationAccessMatListUserLevel({"id": currentAccountId}).then((result: any) => {
                if (result != false && result.status == 0) {
                    handlePopup(true, "Success", "Access matrix had been reset successfully.", popupManager);
                    handleInitList(location.state.row.user_id, (location.state.row.uc_name).toLowerCase())
                    setLoading(false);

                } else {
                    setLoading(false);
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager);
                }
            });

        }

        const handleResetMarketAPI = async (selBoolean) => {
            if (!selBoolean) return;


            api.resetClientDealerMarket({"id": currentAccountId}).then((result: any) => {
                if (result != false && result.status == 0) {
                    handleInitList(location.state.row.user_id, (location.state.row.uc_name).toLowerCase())
                    handlePopup(true, "Success", "Market setting had been reset successfully.", popupManager);
                    setShowResetMarketBtn(false);

                } else {
                    setLoading(false);
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager);
                }
            });


        }

        const handleResetOrderAPI = async (selBoolean) => {
            if (!selBoolean) return;
            api.resetClientDealerOrder({"id": currentAccountId}).then((result: any) => {
                if (result != false && result.status == 0) {
                    handleInitList(location.state.row.user_id, (location.state.row.uc_name).toLowerCase())
                    handlePopup(true, "Success", "Order setting had been reset successfully.", popupManager);
                    setShowResetOrderBtn(false);

                } else {
                    setLoading(false);
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager);
                }
            });

        }
        const handleAddBtn = async () => {
            await setLoading(true);

            if (currentUserId.replace(/ /g, "").length == 0) {
                handlePopup(true, "Error", "Mandatory Field - User ID not entered.", popupManager)
                setLoading(false);
                return false;
            }
            if (isNaN(parseInt(currentUserType))) {
                handlePopup(true, "Error", "Mandatory Field - User Type not selected.", popupManager)
                setLoading(false);
                return;
            }

            if (currentUserName.length < 2) {
                handlePopup(true, "Error", "Mandatory Field - First Name not entered.", popupManager, null, null, null, "(First Name : Minimum 1 characters)")

                setLoading(false);
                return false;
            }


            if (currentUserLastName.length < 2) {
                handlePopup(true, "Error", "Mandatory Field - Last Name not entered.", popupManager, null, null, null, "(Last Name : Minimum 1 characters)")

                setLoading(false);
                return false;
            }

            if (currentNationality.length == 0) {
                handlePopup(true, "Error", "Mandatory Field - Nationality not entered.", popupManager)
                setLoading(false);
                return false;
            }


            if (
                currentUserId.trim().length > 2 &&
                currentUserName.trim().length > 2 &&
                currentUserLastName.trim().length > 2 &&
                currentNationality.length > 2
            ) {

                if (generatePwToggle && currentEmail.length < 5) {
                    let emailCorrect = testEmailAddress(currentEmail);
                    if (!emailCorrect) {
                        handlePopup(true, "Error", "Please enter a valid email if auto-generate password is checked.", popupManager)
                        setLoading(false);
                        return;
                    }
                }

                let dOb = dayjs(currentDOB).format(momentTimeFormat());

                let param = {
                    "first_name": currentUserName,
                    "last_name": currentUserLastName,
                    "title": currentUserTitle,
                    "nric": currentNRIC == "" ? null : currentNRIC.substring(0, 12),
                    "dob": dOb == "" ? null : dOb,
                    "gender": currentGenderMaleChecked,
                    "phone": currentHomePhone,
                    "office": currentOfficePhone,
                    "branch": currentBranch[0] == null ? null : parseInt(currentBranch[0]['code']),
                    "handphone": currentMobilePhone,
                    "email": currentEmail,
                    "passport": currentPassport == "" ? null : currentPassport,
                    "nationality": currentNationality,
                    "race": currentRace == 'OTHERS' ? 'OTHERS-' + currentOtherRace : currentRace,
                    "address1": currentAddress1,
                    "address2": currentAddress2,
                    "address3": currentAddress3,
                    "postcode": currentPostCode.substring(0, 5),
                    "city": "",
                    "state": currentState,
                    "country": currentCountry,
                    "user_type": parseInt(currentUserType),
                    "user_status": currentStatus,
                    "username": currentUserId.replace(/ /g, ""),
                    "access_status": isNaN(parseInt(currentEnaYesChecked)) ? null : parseInt(currentEnaYesChecked),
                    "end_date": currentExpiredOn,
                    "start_time": currentStartTime == "" ? null : currentStartTime,
                    "end_time": currentStopTime == "" ? null : currentStopTime,
                    "is_monday": monChecked ? 1 : 0,
                    "is_tuesday": tueChecked ? 1 : 0,
                    "is_wednesday": wedChecked ? 1 : 0,
                    "is_thursday": thuChecked ? 1 : 0,
                    "is_friday": friChecked ? 1 : 0,
                    "is_saturday": satChecked ? 1 : 0,
                    "is_sunday": sunChecked ? 1 : 0,
                    "is_auto": generatePwToggle
                };


                if (currentPassword.trim().length > 0 && !generatePwToggle) {
                    if (!isPasswordValid) {
                        let pWparam = {
                            "id": 1,
                            "table_type": "1",
                            "password": currentPassword,
                            "is_new": 1,
                        }

                        api.validatePw(pWparam).then((result: any) => {
                            if (result.status == 1) {
                                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                                setLoading(false);
                            }
                            if (result.status == 0) {
                                setPasswordAlert(inLineConvertErMsg(result.message));
                                setIsPasswordValid(false);
                                setLoading(false);
                                return;
                            } else {
                                setPasswordAlert("");
                                setIsPasswordValid(true);
                                param['password'] = currentPassword;
                                handleCreateApi(param);
                            }
                        });

                    } else {
                        param['password'] = currentPassword;
                        handleCreateApi(param);
                    }
                } else {
                    handlePopup(true, "Error", "Password is mandatory.", popupManager)

                }


            } else {
                handlePopup(true, "Error", "Mandatory fields require at least 2 characters.", popupManager)

            }

            setLoading(false);
        };

        function handleCountryChange(value: string, text: string, selectOptionSetter: {
            (value: React.SetStateAction<string>): void;
            (arg0: any): void;
        }, selectTextSetter: { (value: React.SetStateAction<string>): void; (arg0: any): void; }) {
            selectOptionSetter(value);
            selectTextSetter(text);
        }

        const renderBottomSection = (type: string, mode: string, portion = false) => {

            if (currentPort == 'profile') {
                setShowResetbtn(true);
                setShowResetAMBtn(false);
                setShowResetMarketBtn(false);
                setShowResetOrderBtn(false);


                if (type.toLowerCase() == 'client') {
                    document.getElementById("div_operator").classList.add('divHide');
                    document.getElementById("div_client").classList.remove('divHide');
                    document.getElementById("div_client_inform").classList.remove('divHide');
                } else if (type.toLowerCase() == 'operation') {

                    document.getElementById("div_operator").classList.remove('divHide');
                    document.getElementById("div_client").classList.add('divHide');
                    document.getElementById("div_operation_inform").classList.remove('divHide');
                    setShowTopAccessStatus(true);
                } else if (type.toLowerCase() == 'dealer') {

                    document.getElementById("div_operator").classList.add('divHide');
                    document.getElementById("div_client").classList.add('divHide');
                    document.getElementById("div_dealer_inform").classList.remove('divHide');
                    setShowTopAccessStatus(true);

                }
            } else {
                document.getElementById("div_operator").classList.add('divHide');
                document.getElementById("div_client").classList.add('divHide');
                document.getElementById("div_dealer_inform").classList.add('divHide');
            }

            if (mode.toLowerCase() == 'edit') {
                document.getElementById("div_edit").classList.remove('divHide');
            } else if (mode.toLowerCase() == 'add') {
                document.getElementById("div_add").classList.remove('divHide');
                document.getElementById("div_usertype_mandatory").classList.remove('divHide');
            }
        }

        const columns = useMemo(
            () => [
                {
                    id: (row: { a_acctno: any; }) => row.a_acctno,
                    name: "Account No",
                    selector: (row: { a_acctno: any; }) => row.a_acctno,
                    sortable: true,

                },
                {
                    name: 'Account Type',
                    selector: row => row.a_type == 0 ? "Cash" : row.a_type == 1 ? "Margin" : row.a_type == 2 ? "Cash Upfront" : row.a_type == 3 ? "Collateralized" : "-",
                    sortable: true
                },
                {
                    name: 'Account Open Date',
                    selector: (row: {
                        a_timecreate: any;
                    }) => moment(row.a_timecreate).format("YYYY-MM-DD hh:MM A") == 'Invalid date' ? "-" : moment(row.a_timecreate).format("YYYY-MM-DD hh:MM A"),
                    sortable: true,

                },
                {
                    name: 'Status',
                    cell: (row: { a_status: any }) => (
                        <>
                            {
                                <select
                                    className="form-control"
                                    defaultValue={row.a_status}
                                    id={"a_status_" + row.a_acctno}
                                >

                                    <option value=''>Please select status</option>
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                    <option value="2">Suspended</option>
                                    <option value="3">Expired</option>
                                    <option value="4">Dormant</option>
                                    <option value="5">Closed</option>
                                </select>}
                        </>
                    )
                }
            ],
            []
        );

        const handlePasswordShow = () => {
            const element = document.getElementById("div_pw_eye");
            if (passwordShowToggle) {
                element.classList.remove('fa-eye');
                element.classList.add('fa-eye-slash');
                setpwInputType("text")


            } else {
                element.classList.add('fa-eye');
                element.classList.remove('fa-eye-slash');
                setpwInputType("password")

            }
            setpPasswordShowToggle(!passwordShowToggle);
        }

        const handleUpdateApi = (param) => {
            api.updateProfile(param).then((result: any) => {
                if (!result || result.message) {
                    handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                } else {
                    handlePopup(true, "Success", "Profile updated successfully.", popupManager, true, "user_profile", navigate)
                    setLoading(false);
                }
            });
        }


        const handleValidateUsername = (param) => {
            let usernameParam = {
                "username": param
            }
            setLoading(true);
            api.validateUserName(usernameParam).then((result: any) => {
                if (result.status) {
                    setUsernameAlert(inLineConvertErMsg(result.message));
                    setIsUsernameValid(false);
                    setLoading(false);
                } else {
                    setUsernameAlert("");
                    setIsUsernameValid(true);
                    setLoading(false);
                }

            });
        }

        const handleCreateApi = (param) => {
            let usernameParam = {
                "username": currentUserId
            }
            if (isUsernameValid) {
                api.createProfile(param).then((result: any) => {
                    if (!result || result.message) {
                        handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                    } else {
                        setLoading(false);
                        handlePopup(true, "Success", "Profile created successfully.", popupManager, true, "user_profile", navigate)


                    }
                });
            } else {
                api.validateUserName(usernameParam).then((result: any) => {
                    if (result.status) {
                        setUsernameAlert(inLineConvertErMsg(result.message));
                        handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                        setIsUsernameValid(false);
                        setLoading(false);
                    } else {
                        setUsernameAlert("");
                        setIsUsernameValid(true);
                        setLoading(false);
                        api.createProfile(param).then((result: any) => {
                            if (!result || result.message) {
                                handlePopup(true, "Error", convertErMsg(result.message) ?? "Error creating profile", popupManager)
                            } else {
                                setLoading(false);
                                handlePopup(true, "Success", "Profile created successfully.", popupManager, true, "user_profile", navigate)

                            }
                        });
                    }

                });
            }


        }


        const handleAutoGenPassword = () => {
            setGeneratePwToggle(!generatePwToggle)
            setCurrentPasswordReadOnly(!generatePwToggle)
            setCurrentPassword("")
        }

        const handlePwValidation = (password) => {

            if (password.trim().length == 0) {
                setPasswordAlert("");
                return;
            }

            let param = {
                "id": currentAccountId,
                "table_type": currentTableId,
                "password": password,
                "is_new": 0,
            }
            if (location.state.mode.toLowerCase() == 'add') {
                param['id'] = 1;
                param['table_type'] = "1";
                param['is_new'] = 1;
            }

            api.validatePw(param).then((result: any) => {
                if (result.status) {
                    setPasswordAlert(inLineConvertErMsg(result.message));

                    setIsPasswordValid(false);
                } else {
                    setPasswordAlert("");
                    setIsPasswordValid(true);
                }
            });
        }

        const handleCountryList = async () => {
            api.getCountryList(999, 1, 1, null).then((result: any) => {
                if (result.data) {
                    if (result.data.list.length > 0) {
                        setCountryList(result.data.list);
                    }
                }
            });
        };

        const handleUserTypeList = async () => {

            setLoading(true);
            api.getUserTypeList(999, 1, 1, "").then((result: any) => {
                if (result.data) {
                    if (result.data.list.length > 0) {
                        if (location.state.mode.toLowerCase() == 'add') {
                            let dataList = [];
                            for (let i = 0; i < result.data.list.length; i++) {
                                if (result.data.list[i].ut_catid == 2) {
                                    dataList.push(result.data.list[i]);
                                }
                            }
                            setUserTypeList(dataList);

                        } else {
                            setUserTypeList(result.data.list);

                        }
                    }
                }
            });
            setLoading(false);
        };

        const handleBranchList = async () => {
            setLoading(true);
            api.getBranchList(999, 1, 1, null).then((result: any) => {
                if (result.data) {
                    if (result.data.list.length > 0) {
                        let dataList = result.data.list;
                        var TreeData = convertRawIntoDropdown(dataList);
                        setBranchList(TreeData);
                    }
                }
            });
            setLoading(false);
        };

        const convertRawIntoDropdown = (data) => {
            var returnDat = [];
            for (let i = 0; i < data.length; i++) {
                returnDat.push({
                    code: data[i]['bb_id'],
                    name: data[i]['bb_code'] + " | " + data[i]['bb_name'],
                    id: data[i]['bb_id']
                });
            }
            return returnDat;
        }

        const onSelect = (selectedList, selectedItem) => {
            setCurrentBranch(selectedList);
        }

        const onRemove = (selectedList, selectedItem) => {
            setCurrentBranch("");
        }

        const checkProfileChange = () => {

            if ((location.state.row.uc_name).toLowerCase() != 'operation') {
                let param = {
                    "phone": currentHomePhone == "" ? null : currentHomePhone,
                    "office": currentOfficePhone == "" ? null : currentOfficePhone,
                    "handphone": currentMobilePhone == "" ? null : currentMobilePhone,
                    "email": currentEmail == "" ? null : currentEmail,
                    "user_status": currentStatus,
                    "idss": currentIdss ?? 1,
                    "account": tableData,
                    "is_auto": passwordShowToggle
                }

                if (tableData) {
                    for (let i = 0; i < tableData.length; i++) {
                        let a_statusEle = document.getElementById('a_status_' + tableData[i]['a_acctno']);
                        tableData[i]['a_status'] = parseInt(a_statusEle.value);
                    }
                }

                for (let i = 0; i < clientAccListOri.length; i++) {
                    const item1 = clientAccListOri[i];
                    const item2 = tableData[i];
                    // Check if values are the same
                    for (const key in item1) {
                        if (item1[key] !== item2[key]) {
                            return true; // Arrays are not equal
                        }
                    }
                }

                let debug = true;
                if (debug) {
                    if (param['phone'] != location.state.row.p_phone) {
                        console.log("1. Changed");
                        console.log("Original: " + location.state.row.p_phone);
                        console.log("Changed: " + param['phone']);
                    }

                    if (param['office'] != location.state.row.p_office) {
                        console.log("2. Changed");
                        console.log("Original: " + location.state.row.p_office);
                        console.log("Changed: " + param['office']);
                    }

                    if (param['handphone'] != location.state.row.p_handphone) {
                        console.log("3. Changed");
                        console.log("Original: " + location.state.row.p_handphone);
                        console.log("Changed: " + param['handphone']);
                    }

                    if (param['email'] != location.state.row.p_email) {
                        console.log("4. Changed");
                        console.log("Original: " + location.state.row.p_email);
                        console.log("Changed: " + param['email']);
                    }

                    if (param['user_status'] != location.state.row.active_status) {
                        console.log("5. Changed");
                        console.log("Original: " + location.state.row.active_status);
                        console.log("Changed: " + param['user_status']);
                    }

                    if (param['idss'] != location.state.row.idss) {
                        console.log("6. Changed");
                        console.log("Original: " + location.state.row.idss);
                        console.log("Changed: " + param['idss']);
                    }

                    if (param['is_auto'] != true) {
                        console.log("7. Changed");
                        console.log("Original: " + param['is_auto']);
                        console.log("Changed: " + true);
                    }


                }


                if (
                    param['phone'] != location.state.row.p_phone == "" ? null : location.state.row.p_phone ||
                    param['office'] != location.state.row.p_office == "" ? null : location.state.row.p_office ||
                    param['handphone'] != location.state.row.p_handphone == "" ? null : currentlocation.state.row.p_handphone ||
                    param['email'] != location.state.row.p_email == "" ? null : location.state.row.p_email ||
                        param['user_status'] != location.state.row.active_status ||
                        param['idss'] != location.state.row.idss ||
                        param['is_auto'] == true

                ) {
                    return true;
                } else {
                    return false;
                }

            } else {
                let endDateOri = moment(location.state.row.acs_end_date).format(momentTimeFormat());
                let endDate = moment(currentExpiredOn).format(momentTimeFormat());
                let time_p_dob = moment(location.state.row.p_dob).format(momentTimeFormat());
                let time_p_dobNow = moment(currentDOB).format(momentTimeFormat());
                let substringedNRIC = location.state.row.p_nric ? (location.state.row.p_nric).substring(0, 12) : null


                let param = {
                    "id": currentAccountId,
                    "first_name": currentUserName,
                    "last_name": currentUserLastName,
                    "title": currentUserTitle,
                    "branch": currentBranch[0] == null ? null : parseInt(currentBranch[0]['code']),
                    "nric": currentNRIC == "" ? null : currentNRIC.substring(0, 12),
                    "dob": time_p_dobNow,
                    "gender": currentGenderMaleChecked,
                    "phone": currentHomePhone == "" ? null : currentHomePhone,
                    "office": currentOfficePhone == "" ? null : currentOfficePhone,
                    "handphone": currentMobilePhone == "" ? null : currentMobilePhone,
                    "email": currentEmail == "" ? null : currentEmail,
                    "passport": currentPassport == "" ? null : currentPassport,
                    "nationality": currentNationality,
                    "race": currentRace == 'OTHERS' ? 'OTHERS-' + currentOtherRace : currentRace,
                    "address1": currentAddress1,
                    "address2": currentAddress2,
                    "address3": currentAddress3,
                    "postcode": currentPostCode.substring(0, 5),
                    "city": "",
                    "state": currentState,
                    "country": currentCountry,
                    "user_status": currentStatus,
                    "access_status": isNaN(parseInt(currentEnaYesChecked)) ? null : parseInt(currentEnaYesChecked),
                    "end_date": endDate == "Invalid Date" ? null : endDate,
                    "start_time": currentStartTime == null ? "Invalid date" : currentStartTime,
                    "end_time": currentStopTime == null ? "Invalid date" : currentStopTime,
                    "is_monday": monChecked ? 1 : 0,
                    "is_tuesday": tueChecked ? 1 : 0,
                    "is_wednesday": wedChecked ? 1 : 0,
                    "is_thursday": thuChecked ? 1 : 0,
                    "is_friday": friChecked ? 1 : 0,
                    "is_saturday": satChecked ? 1 : 0,
                    "is_sunday": sunChecked ? 1 : 0,
                    "is_auto": generatePwToggle
                };

                let tmpAcs_start_time = location.state.row.acs_start_time;
                let tmpAcs_end_time = location.state.row.acs_end_time;

                let isDebug = true;
                if (isDebug) {
                    if (param['first_name'] !== location.state.row.p_firstname) {
                        console.log("1. Changed");
                        console.log("Original: " + location.state.row.p_firstname);
                        console.log("Changed: " + param['first_name']);
                    }

                    if (param['last_name'] !== location.state.row.p_lastname) {
                        console.log("2. Changed");
                        console.log("Original: " + location.state.row.p_lastname);
                        console.log("Changed: " + param['last_name']);
                    }

                    if (param['title'] !== location.state.row.p_title) {
                        console.log("3. Changed");
                        console.log("Original: " + location.state.row.p_title);
                        console.log("Changed: " + param['title']);
                    }


                    if (param['nric'] != substringedNRIC) {
                        console.log("4. Changed");
                        console.log("Original: " + location.state.row.p_nric);
                        console.log("Changed: " + param['nric']);
                    }

                    if (param['dob'] !== (time_p_dob)) {
                        console.log("5. Changed");
                        console.log("Original: " + (time_p_dob == 'Invalid date' ? null : time_p_dob));
                        console.log("Changed: " + param['dob']);
                    }

                    if (param['gender'] !== location.state.row.p_gender) {
                        console.log("6. Changed");
                        console.log("Original: " + location.state.row.p_gender);
                        console.log("Changed: " + param['gender']);
                    }

                    if (param['phone'] !== (location.state.row.p_phone == "" ? null : location.state.row.p_phone)) {
                        console.log("7. Changed");
                        console.log("Original: " + (location.state.row.p_phone == "" ? null : location.state.row.p_phone));
                        console.log("Changed: " + param['phone']);
                    }

                    if (param['office'] !== (location.state.row.p_office == "" ? null : location.state.row.p_office)) {
                        console.log("8. Changed");
                        console.log("Original: " + (location.state.row.p_office == "" ? null : location.state.row.p_office));
                        console.log("Changed: " + param['office']);
                    }

                    if (param['handphone'] !== (location.state.row.p_handphone == "" ? null : location.state.row.p_handphone)) {
                        console.log("9. Changed");
                        console.log("Original: " + (location.state.row.p_handphone == "" ? null : location.state.row.p_handphone));
                        console.log("Changed: " + param['handphone']);
                    }

                    if (param['email'] !== (location.state.row.p_email == "" ? null : location.state.row.p_email)) {
                        console.log("10. Changed");
                        console.log("Original: " + (location.state.row.p_email == "" ? null : location.state.row.p_email));
                        console.log("Changed: " + param['email']);
                    }

                    if (param['passport'] !== location.state.row.p_pptno) {
                        console.log("11. Changed");
                        console.log("Original: " + location.state.row.p_pptno);
                        console.log("Changed: " + param['passport']);
                    }

                    if (param['nationality'] !== location.state.row.p_nationality) {
                        console.log("12. Changed");
                        console.log("Original: " + location.state.row.p_nationality);
                        console.log("Changed: " + param['nationality']);
                    }

                    if (param['race'] !== location.state.row.p_race) {
                        console.log("13. Changed");
                        console.log("Original: " + location.state.row.p_race);
                        console.log("Changed: " + param['race']);
                    }

                    if (param['address1'] !== location.state.row.p_addressline1) {
                        console.log("14. Changed");
                        console.log("Original: " + location.state.row.p_addressline1);
                        console.log("Changed: " + param['address1']);
                    }

                    if (param['address2'] !== location.state.row.p_addressline2) {
                        console.log("15. Changed");
                        console.log("Original: " + location.state.row.p_addressline2);
                        console.log("Changed: " + param['address2']);
                    }

                    if (param['address3'] !== location.state.row.p_addressline3) {
                        console.log("16. Changed");
                        console.log("Original: " + location.state.row.p_addressline3);
                        console.log("Changed: " + param['address3']);
                    }

                    if (param['postcode'] !== location.state.row.p_postcode) {
                        console.log("17. Changed");
                        console.log("Original: " + location.state.row.p_postcode);
                        console.log("Changed: " + param['postcode']);
                    }

                    if (param['city'] !== "") {
                        console.log("18. Changed");
                        console.log("Original: " + location.state.row.p_city); // Assuming there is a 'city' property in the state
                        console.log("Changed: " + param['city']);
                    }

                    if (param['state'] !== location.state.row.p_state) {
                        console.log("19. Changed");
                        console.log("Original: " + location.state.row.p_state);
                        console.log("Changed: " + param['state']);
                    }

                    if (param['country'] !== location.state.row.p_country) {
                        console.log("20. Changed");
                        console.log("Original: " + location.state.row.p_country);
                        console.log("Changed: " + param['country']);
                    }

                    if (param['user_status'] !== location.state.row.active_status) {
                        console.log("21. Changed");
                        console.log("Original: " + location.state.row.active_status);
                        console.log("Changed: " + param['user_status']);
                    }

                    if (param['end_date'] !== endDateOri) {
                        console.log("22. Changed");
                        console.log("Original: " + endDateOri);
                        console.log("Changed: " + param['end_date']);
                    }

                    if (param['end_time'] !== tmpAcs_end_time) {
                        console.log("23. Changed");
                        console.log("Original: " + tmpAcs_end_time);
                        console.log("Changed: " + param['end_time']);
                    }

                    if (param['start_time'] !== tmpAcs_start_time) {
                        console.log("24. Changed");
                        console.log("Original: " + tmpAcs_start_time);
                        console.log("Changed: " + param['start_time']);
                    }

                    if (param['is_monday'] !== (location.state.row.is_monday ? 1 : 0)) {
                        console.log("25. Changed");
                        console.log("Original: " + (location.state.row.is_monday ? 1 : 0));
                        console.log("Changed: " + param['is_monday']);
                    }

                    if (param['is_tuesday'] !== (location.state.row.is_tuesday ? 1 : 0)) {
                        console.log("26. Changed");
                        console.log("Original: " + (location.state.row.is_tuesday ? 1 : 0));
                        console.log("Changed: " + param['is_tuesday']);
                    }

                    if (param['is_wednesday'] !== (location.state.row.is_wednesday ? 1 : 0)) {
                        console.log("27. Changed");
                        console.log("Original: " + (location.state.row.is_wednesday ? 1 : 0));
                        console.log("Changed: " + param['is_wednesday']);
                    }

                    if (param['is_thursday'] !== (location.state.row.is_thursday ? 1 : 0)) {
                        console.log("28. Changed");
                        console.log("Original: " + (location.state.row.is_thursday ? 1 : 0));
                        console.log("Changed: " + param['is_thursday']);
                    }

                    if (param['is_friday'] !== (location.state.row.is_friday ? 1 : 0)) {
                        console.log("29. Changed");
                        console.log("Original: " + (location.state.row.is_friday ? 1 : 0));
                        console.log("Changed: " + param['is_friday']);
                    }

                    if (param['is_saturday'] !== (location.state.row.is_saturday ? 1 : 0)) {
                        console.log("30. Changed");
                        console.log("Original: " + (location.state.row.is_saturday ? 1 : 0));
                        console.log("Changed: " + param['is_saturday']);
                    }

                    if (param['is_sunday'] !== (location.state.row.is_sunday ? 1 : 0)) {
                        console.log("31. Changed");
                        console.log("Original: " + (location.state.row.is_sunday ? 1 : 0));
                        console.log("Changed: " + param['is_sunday']);
                    }

                    if (param['is_auto'] == true) {
                        console.log("32. Changed");
                        console.log("Original: " + location.state.row.is_auto);
                        console.log("Changed: " + param['is_auto']);
                    }
                }

                if (
                    param['first_name'] != location.state.row.p_firstname ||
                    param['last_name'] != location.state.row.p_lastname ||
                    param['title'] != location.state.row.p_title ||
                    param['nric'] != substringedNRIC ||
                    param['dob'] != (time_p_dob) ||
                    param['gender'] != location.state.row.p_gender ||
                    param['phone'] != (location.state.row.p_phone == "" ? null : location.state.row.p_phone) ||
                    param['office'] != (location.state.row.p_office == "" ? null : location.state.row.p_office) ||
                    param['handphone'] != (location.state.row.p_handphone == "" ? null : location.state.row.p_handphone) ||
                    param['email'] != (location.state.row.p_email == "" ? null : location.state.row.p_email) ||
                    param['passport'] != location.state.row.p_pptno ||
                    param['nationality'] != location.state.row.p_nationality ||
                    param['race'] != location.state.row.p_race ||
                    param['address1'] != location.state.row.p_addressline1 ||
                    param['address2'] != location.state.row.p_addressline2 ||
                    param['address3'] != location.state.row.p_addressline3 ||
                    param['postcode'] != location.state.row.p_postcode ||
                    param['city'] != "" ||
                    param['state'] != location.state.row.p_state ||
                    param['country'] != location.state.row.p_country ||
                    param['user_status'] != location.state.row.active_status ||
                    param['end_date'] != endDateOri ||
                    param['end_time'] != tmpAcs_end_time ||
                    param['start_time'] != tmpAcs_start_time ||
                    param['is_monday'] != location.state.row.is_monday ? 1 : 0 ||
                    param['is_tuesday'] != location.state.row.is_tuesday ? 1 : 0 ||
                    param['is_wednesday'] != location.state.row.is_wednesday ? 1 : 0 ||
                    param['is_thursday'] != location.state.row.is_thursday ? 1 : 0 ||
                    param['is_friday'] != location.state.row.is_friday ? 1 : 0 ||
                    param['is_saturday'] != location.state.row.is_saturday ? 1 : 0 ||
                    param['is_sunday'] != location.state.row.is_sunday ? 1 : 0 ||
                        param['is_auto'] == true
                ) {
                    return true;
                } else {
                    return false;
                }
            }


        }
        const checkMatrixChange = () => {
            if ((location.state.row.uc_name).toLowerCase() != 'operation') {
                var exchangeListLength = getExchangeQty();
                var exchangeLists = getExchangeMenu();
                let accessLvl = currentUserAccessLevel;
                // let accessLvl = JSON.parse("{\"al_exchange\": [0,0], \"al_trading\": [], \"al_toolss\": 0}");
                // if (currentUserAccessLevel || currentUserAccessLevel != "") {
                //     let item = currentUserAccessLevel.replaceAll(/\s/g, '')
                //     if (IsJsonString(item)) {
                //         accessLvl = JSON.parse(item);
                //     }
                // }

                let exchangeSetup = (accessLvl?.al_exchange ? accessLvl?.al_exchange : 0);
                var base3Exchange = Array.from(changeBase(exchangeSetup, 10, 3)).reverse().join('');
                base3Exchange = zeroFill(base3Exchange, exchangeListLength);

                let exchangeItem = [];
                for (let i = 0; i < exchangeLists.length; i++) {
                    let radios = document.getElementsByName("eq_" + exchangeLists[i]["em_id"]);

                    if (i == 4) exchangeItem[4] = base3Exchange[4];
                    if (i == 7) exchangeItem[7] = base3Exchange[7];
                    //18Oct23 change to e_code
                    if (exchangeLists[i]["em_id"] != '4' && exchangeLists[i]["em_id"] != '7') {
                        for (let j = 0; j < radios.length; j++) {
                            if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_disabled" && radios[j].checked) {
                                exchangeItem[exchangeLists[i]["em_id"]] = "0";
                                break;
                            } else if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_delay" && radios[j].checked) {
                                exchangeItem[exchangeLists[i]["em_id"]] = "2";
                                break;
                            } else if (radios[j].id == "eq_" + exchangeLists[i]["em_id"] + "_live" && radios[j].checked) {
                                exchangeItem[exchangeLists[i]["em_id"]] = "1";
                                break;
                            }
                        }
                    }
                }

                let toolsMenuList = getToolsMenu();
                let toolsSetup = (accessLvl?.al_tools ?? {});

                var base2Exchange = Array.from(changeBase(toolsSetup, 10, 2)).reverse().join('');
                base2Exchange = zeroFill(base2Exchange, toolsMenuList.length);

                let toolItem = [];
                for (let i = 0; i < toolsMenuList.length; i++) {
                    let value = base2Exchange[toolsMenuList[i]["id"]];
                    let checkbox = document.getElementById("tool_" + toolsMenuList[i]["id"]);
                    if (value && checkbox.checked) {
                        toolItem[toolsMenuList[i]["id"]] = 1
                    } else {
                        toolItem[toolsMenuList[i]["id"]] = 0

                    }
                }

                var equityItemDecimal = changeBase(Array.from(exchangeItem.toString().replaceAll(",", "")).reverse().join(''), 3, 10);
                var toolItemDecimal = changeBase(Array.from(toolItem.toString().replaceAll(",", "")).reverse().join(''), 2, 10);

                //Order matrix - validity
                let orderItem = getOrderValidityItem();
                let orderValidityItem = {};
                let orderValidityItemSubmit = {};
                for (let i = 0; i < orderItem.length; i++) {
                    for (let j = 0; j < exchangeLists.length; j++) {
                        let checkboxId = (orderItem[i] + "_" + exchangeLists[j]['e_code']).toLowerCase();
                        let checkbox = document.getElementById(checkboxId);
                        if (!orderValidityItem[exchangeLists[j]['e_code']]) orderValidityItem[exchangeLists[j]['e_code']] = (checkbox.checked) ? "1" : "0";
                        else orderValidityItem[exchangeLists[j]['e_code']] += (checkbox.checked) ? "1" : "0";
                    }
                }
                for (var prop in orderValidityItem) {
                    base2Exchange = zeroFill(orderValidityItem[prop], orderItem.length);
                    orderValidityItemSubmit[prop] = changeBase(Array.from(base2Exchange.toString().replaceAll(",", "")).reverse().join(''), 2, 10);
                }


                //al_exchange
                let arr1 = [equityItemDecimal, 0];
                let arr2 = clientMarketOri['al_exchange'];
                for (let i = 0; i < arr1.length; i++) {
                    if (arr1[i] !== arr2[i]) {
                        return true; // Arrays are not equal
                    }
                }

                console.log(arr1)
                console.log(arr2)


                //Tools
                let arr3 = toolItemDecimal;
                let arr4 = clientMarketOri['al_tools'];
                for (let i = 0; i < arr3.length; i++) {
                    if (arr3[i] !== arr4[i]) {
                        return true; // Arrays are not equal
                    }
                }

                console.log(arr3)
                console.log(arr4)

                //Order
                let arr5 = orderValidityItemSubmit;
                let arr6 = clientOrderOri;
                const keys1 = Object.keys(arr5);

                console.log(arr5)
                console.log(arr6)

                if (arr5 != null && arr6 != null) {
                    for (const key of keys1) {
                        if (arr5[key] !== arr6[key]) {
                            return true;
                        }
                    }
                }

                return false;

            } else {
                let renderList = getFunctionMenus();
                let saveList = [];
                let specialPermProfile = {};

                for (let i = 0; i < renderList.length; i++) {
                    let parEle = renderList[i];
                    let parentLbl = parEle['label'].replaceAll(/[\(\)]/g, "")


                    let perm = zeroFill(checkPermCheckedById(parEle['id'], parentLbl), 4);
                    var itemInDecimal = changeBase(Array.from(perm.toString().replaceAll(",", "")).reverse().join(''), 2, 10);
                    saveList.push({"page": parEle['id'], "ctrl": itemInDecimal, "am": 0})

                    if (renderList[i]['subMenu']) {
                        let dataEle = renderList[i]['subMenu'];

                        for (let j = 0; j < dataEle.length; j++) {
                            let perm = zeroFill(checkPermCheckedById(dataEle[j]['id'], parentLbl), 4);
                            var itemInDecimal = changeBase(Array.from(perm.toString().replaceAll(",", "")).reverse().join(''), 2, 10);


                            saveList.push({"page": dataEle[j]['id'], "ctrl": itemInDecimal, "am": 0})
                            if (dataEle[j]['subMenu2']) {
                                let dataEle2 = dataEle[j]['subMenu2'];
                                for (let k = 0; k < dataEle2.length; k++) {
                                    let perm = zeroFill(checkPermCheckedById(dataEle2[k]['id'], parentLbl), 4);
                                    var itemInDecimal = changeBase(Array.from(perm.toString().replaceAll(",", "")).reverse().join(''), 2, 10);
                                    if (dataEle2[k]['id'] == 64 || dataEle2[k]['id'] == 65 || dataEle2[k]['id'] == 4) {
                                        specialPermProfile[dataEle2[k]['id']] = itemInDecimal;
                                    } else {
                                        saveList.push({"page": dataEle2[k]['id'], "ctrl": itemInDecimal, "am": 0})

                                    }
                                }
                            }
                        }
                    }


                }

                //Special for profile only
                let specialAmAL = 0;
                if (specialPermProfile[64] == 1 && specialPermProfile[65] == 1) {
                    specialAmAL = 3;
                } else if (specialPermProfile[64] == 1 && specialPermProfile[65] == 0) {

                    specialAmAL = 1;
                } else if (specialPermProfile[64] == 0 && specialPermProfile[65] == 1) {
                    specialAmAL = 2;
                }

                let tempDat = {"page": 4, "ctrl": specialPermProfile[4], "am": specialAmAL};
                //Splice into 3 because its id 4
                saveList.splice(3, 0, tempDat);


                let currentMatrix = saveList.sort((a, b) => a.page - b.page);
                let originalMatrix = operationMatrixOri.sort((a, b) => a.page - b.page);


                for (let i = 0; i < saveList.length; i++) {
                    const page1 = currentMatrix[i];
                    const page2 = originalMatrix[i];
                    if (page2.page.toString() != "64" && page2.page.toString() != "65" && !(parseInt(page2.page) >= 39 && parseInt(page2.page) <= 54)) { //Special Perm
                        if (page1.page == page2.page) {

                            if (page1.ctrl.toString() !== page2.ctrl.toString() || page1.am.toString() !== page2.am.toString()) {
                                return true;
                            }
                        }
                    }


                }

                return false;
            }


        }

        const checkOperationAuthLimitChange = () => {
            let latestParam = {
                "explmt": "[" +
                    "{\"risktype\":1,\"riskenable\":" + elBuyChkBox + ",\"amount\":" + elBuy.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":2,\"riskenable\":" + elSellChkBox + ",\"amount\":" + elSell.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":3,\"riskenable\":" + elNettChkBox + ",\"amount\":" + elNett.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":4,\"riskenable\":" + elTotalChkBox + ",\"amount\":" + elTotal.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":5,\"riskenable\":" + elIDSSChkBox + ",\"amount\":" + elIDSS.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":6,\"riskenable\":" + elFSTChkBox + ",\"amount\":" + elFST.toString().replace(/,/g, '') + "}" +
                    "]",
                "topup": "[" +
                    "{\"risktype\":1,\"riskenable\":" + topUpBuyChkBox + ",\"amount\":" + topUpBuy.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":2,\"riskenable\":" + topUpSellChkBox + ",\"amount\":" + topUpSell.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":3,\"riskenable\":" + topUpNettChkBox + ",\"amount\":" + topUpNett.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":4,\"riskenable\":" + topUpTotalChkBox + ",\"amount\":" + topUpTotal.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":5,\"riskenable\":" + topUpIDSSChkBox + ",\"amount\":" + topUpIDSS.toString().replace(/,/g, '') + "}," +
                    "{\"risktype\":6,\"riskenable\":" + topUpFSTChkBox + ",\"amount\":" + topUpFST.toString().replace(/,/g, '') + "}" +
                    "]"
            }

            let oriParam = operationAuthLimitOri[0];

            let oriexplmt = oriParam['explmt']
            let oritpoup = oriParam['topup']


            console.log(oriParam)
            console.log(latestParam)

            let arr1 = JSON.parse(oriexplmt);
            let arr2 = JSON.parse(latestParam['explmt']);
            let arr3 = JSON.parse(oritpoup);
            let arr4 = JSON.parse(latestParam['topup']);


            for (let i = 0; i < arr1.length; i++) {
                const item1 = arr1[i];
                const item2 = arr2[i];
                if (
                    item1.exp_lmt_type !== item2.risktype ||
                    item1.exp_lmt_enable !== item2.riskenable ||
                    item1.exp_lmt_amount !== item2.amount
                ) {
                    return true;
                }
            }

            for (let i = 0; i < arr3.length; i++) {
                const item1 = arr3[i];
                const item2 = arr4[i];
                if (
                    item1.topup_type !== item2.risktype ||
                    item1.topup_enable !== item2.riskenable ||
                    item1.topup_amount !== item2.amount
                ) {
                    return true;
                }
            }


            return false;
        }
        const triggerButtonSelected = (portion) => {
            let nav_btn = document.getElementsByClassName("nav_btn_profile");
            for (var i = 0; i < nav_btn.length; i++) {
                setShowResetAMBtn(false);

                nav_btn[i].classList.remove('inFormTopNavBtnSelected');
                nav_btn[i].classList.add('inFormTopNavBtn');
            }

            document.getElementById("btn_" + portion).classList.remove('inFormTopNavBtn');
            document.getElementById("btn_" + portion).classList.add('inFormTopNavBtnSelected');
        }
        const handleTopBtnClickedProfile = (portion) => {

            let cls_profile = document.getElementsByClassName("cls_profile");
            currentPort = portion;
            if (portion == 'profile') {
                setCurrentItemToSave('profile')

                function showProfileTabs() {
                    initProfileData();
                    setShowResetMarketBtn(false);
                    setShowResetOrderBtn(false);
                    setShowResetbtn(true);
                    for (var i = 0; i < cls_profile.length; i++) {
                        cls_profile[i].classList.remove('divHide');
                    }
                    triggerButtonSelected(portion)
                    document.getElementById("div_accessmatrix").classList.add('divHide');
                    document.getElementById("div_authLimit").classList.add('divHide');
                }

                setShowResetbtn(true);
                setShowResetAMBtn(false);
                setShowResetMarketBtn(false);
                setShowResetOrderBtn(false);

                if ((location.state.row.uc_name).toLowerCase() == 'operation') {

                    //Operation user
                    if (checkMatrixChange() || checkOperationAuthLimitChange()) {
                        confirmAlert({
                            title: 'Changes not saved',
                            message: <div id="area_confirm">Are you sure you want to view another tab without saving current
                                change?</div>,
                            buttons: [{
                                label: 'Yes', onClick: () => {
                                    showProfileTabs();
                                }
                            }, {
                                label: 'Cancel',
                                id: 'btn_cancel',
                                onClick: () => {
                                    return false;
                                }
                            }],
                            closeOnEscape: false,
                            closeOnClickOutside: false,
                        });

                        // handlePopup(true, "Error", "Please save changes before attempt to view other tabs.", popupManager)
                        // return false;
                    } else {
                        showProfileTabs();
                    }
                } else {
                    //Dealer Client
                    if (checkMatrixChange()) {
                        confirmAlert({
                            title: 'Changes not saved',
                            message: <div id="area_confirm">Are you sure you want to view another tab without saving current
                                change?</div>,
                            buttons: [{
                                label: 'Yes', onClick: () => {
                                    showProfileTabs();
                                }
                            }, {
                                label: 'Cancel',
                                id: 'btn_cancel',
                                onClick: () => {
                                    return false;
                                }
                            }],
                            closeOnEscape: false,
                            closeOnClickOutside: false,
                        });

                        // handlePopup(true, "Error", "Please save changes before attempt to view other tabs.", popupManager)
                        // return false;
                    } else {
                        showProfileTabs();
                    }
                }

            } else if (portion == 'accessMatrix') {

                function showAccessMatrixTabs() {
                    initProfileData();
                    triggerButtonSelected(portion)
                    setShowResetbtn(false);

                    // setCurrentTabPortion('accessMatrix')
                    currentPort = 'accessMatrix'
                    if ((location.state.row.uc_name).toLowerCase() == 'operation' && isUserLvlProf) setShowResetAMBtn(true);
                    if ((location.state.row.uc_name).toLowerCase() != 'operation' && showResetMarketBtn) {
                        setShowResetMarketBtn(true);
                    }

                    for (var i = 0; i < cls_profile.length; i++) {
                        cls_profile[i].classList.add('divHide');
                    }
                    setCurrentItemToSave('accessMatrix')
                    document.getElementById("div_accessmatrix").classList.remove('divHide');
                    document.getElementById("div_authLimit").classList.add('divHide');
                }

                if ((location.state ? currentRole : location.state.row.uc_name).toLowerCase() == 'operation') {
                    if (checkProfileChange() || checkOperationAuthLimitChange()) {
                        confirmAlert({
                            title: 'Changes not saved',
                            message: <div id="area_confirm">Are you sure you want to view another tab without saving current
                                change?</div>,
                            buttons: [{
                                label: 'Yes', onClick: () => {
                                    showAccessMatrixTabs();
                                }
                            }, {
                                label: 'Cancel',
                                id: 'btn_cancel',
                                onClick: () => {
                                    return false;
                                }
                            }],
                            closeOnEscape: false,
                            closeOnClickOutside: false,
                        });

                        // handlePopup(true, "Error", "Please save changes before attempt to view other tabs.", popupManager)
                        // return false;
                    } else {
                        showAccessMatrixTabs();
                    }

                } else {
                    if (checkProfileChange()) {
                        confirmAlert({
                            title: 'Changes not saved',
                            message: <div id="area_confirm">Are you sure you want to view another tab without saving current
                                change?</div>,
                            buttons: [{
                                label: 'Yes', onClick: () => {
                                    showAccessMatrixTabs();
                                }
                            }, {
                                label: 'Cancel',
                                id: 'btn_cancel',
                                onClick: () => {
                                    return false;
                                }
                            }],
                            closeOnEscape: false,
                            closeOnClickOutside: false,
                        });

                        // handlePopup(true, "Error", "Please save changes before attempt to view other tabs.", popupManager)
                        // return false;
                    } else {
                        showAccessMatrixTabs();

                    }
                }


            } else if (portion == 'authLimit') {

                function showAuthLimitTabs() {
                    initProfileData();
                    setShowResetAMBtn(false);
                    setShowResetMarketBtn(false);
                    setShowResetbtn(false);
                    // setCurrentTabPortion('authLimit')
                    currentPort = 'authLimit'

                    for (var i = 0; i < cls_profile.length; i++) {
                        cls_profile[i].classList.add('divHide');
                    }
                    triggerButtonSelected(portion)

                    setCurrentItemToSave('authLimit')
                    document.getElementById("div_accessmatrix").classList.add('divHide');
                    document.getElementById("div_authLimit").classList.remove('divHide');
                }


                if (checkProfileChange()) {
                    confirmAlert({
                        title: 'Changes not saved',
                        message: <div id="area_confirm">Are you sure you want to view another tab without saving current
                            change?</div>,
                        buttons: [{
                            label: 'Yes', onClick: () => {
                                showAuthLimitTabs();
                            }
                        }, {
                            label: 'Cancel',
                            id: 'btn_cancel',
                            onClick: () => {
                                return false;
                            }
                        }],
                        closeOnEscape: false,
                        closeOnClickOutside: false,
                    });
                    // handlePopup(true, "Error", "Please save changes before attempt to view other tabs.", popupManager)
                    // return false;
                } else {
                    showAuthLimitTabs();
                }

                if (checkMatrixChange()) {
                    confirmAlert({
                        title: 'Changes not saved',
                        message: <div id="area_confirm">Are you sure you want to view another tab without saving current
                            change?</div>,
                        buttons: [{
                            label: 'Yes', onClick: () => {
                                showAuthLimitTabs();
                            }
                        }, {
                            label: 'Cancel',
                            id: 'btn_cancel',
                            onClick: () => {
                                return false;
                            }
                        }],
                        closeOnEscape: false,
                        closeOnClickOutside: false,
                    });
                    // handlePopup(true, "Error", "Please save changes before attempt to view other tabs.", popupManager)
                    // return false;
                } else {
                    showAuthLimitTabs();
                }


            }

            renderBottomSection(currentRole, currentMode, portion)

        }
        return (


            <div className="pt-3 text-left" id="mainDiv">

                {/*/>*/}
                <div className="row">
                    {/*<div className="col-12 title"> </div>*/}
                    {/*Header Section*/}
                    <div className="col-xl-12 col-lg-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h5 className="m-0 font-weight-bold text-primary">Administration &gt; User
                                    Maintenance &gt; Profile - {currentMode}</h5>


                            </div>
                            <div className="underline-input">
                                <button id='btn_profile' className="nav_btn_profile inFormTopNavBtnSelected"
                                        onClick={() => handleTopBtnClickedProfile('profile')}
                                >Profile
                                </button>
                                <button id='btn_accessMatrix' className="divHide nav_btn_profile inFormTopNavBtn"
                                        onClick={() => handleTopBtnClickedProfile('accessMatrix')}
                                >Access Matrix
                                </button>
                                <button id='btn_authLimit' className="divHide nav_btn_profile inFormTopNavBtn"
                                        onClick={() => handleTopBtnClickedProfile('authLimit')}
                                >Authorization Limit
                                </button>
                            </div>

                            <div id="div_accessmatrix" className={"divHide"}>{handleMatrixArea()}</div>
                            <div id="div_authLimit" className={"divHide"}>
                                <div className="col-xl-12 col-lg-12">

                                    <div className="card shadow mb-4">
                                        <div
                                            className="card-header py-3 align-items-center justify-content-between">
                                            <b>User ID
                                                : </b>{currentUserId} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>Name :</b> {currentUserName + " " + currentUserLastName}

                                        </div>


                                    </div>
                                    <div className="card shadow mb-4">
                                        <div
                                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h5 className="m-0 font-weight-bold text-primary">Exposure Limit Authorization
                                                Level</h5>


                                        </div>
                                        <div className="card-body">
                                            <div className="col-12 search-container ">

                                                <div className="input-container col-md-12 fullWidthSearch">

                                                    <div className="label col-md-2">
                                                        <label className="" htmlFor="ElBuyChkBox"> <input
                                                            className="checkboxMarginRight"
                                                            name="ElBuyChkBox" type="checkbox" id="ElBuyChkBox"
                                                            onChange={e => handleDisableChkBxByVal(e.target.checked, elBuy, setElBuyChkBox)}
                                                            checked={elBuyChkBox}
                                                        />Buy Exposure</label>
                                                    </div>
                                                    <div className="label col-md-5">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder=""
                                                            aria-label=""
                                                            value={elBuy}
                                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setElBuy, false, elBuyChkBox, setElBuyChkBox)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="input-container col-md-12 fullWidthSearch">

                                                    <div className="label col-md-2">
                                                        <label className="" htmlFor="elSellChkBox">
                                                            <input className="checkboxMarginRight"
                                                                   name="elSellChkBox" type="checkbox" id="elSellChkBox"
                                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, elSell, setElSellChkBox)}
                                                                   checked={elSellChkBox}
                                                            />Sell Exposure</label>
                                                    </div>
                                                    <div className="label col-md-5">
                                                        <input
                                                            className="form-control"

                                                            type="text"
                                                            placeholder=""
                                                            aria-label=""
                                                            value={elSell}
                                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setElSell, false, elSellChkBox, setElSellChkBox)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="input-container col-md-12 fullWidthSearch">

                                                    <div className="label col-md-2">
                                                        <label className="" htmlFor="elTotalChkBox">
                                                            <input className="checkboxMarginRight"
                                                                   name="elTotalChkBox" type="checkbox" id="elTotalChkBox"
                                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, elTotal, setElTotalChkBox)}
                                                                   checked={elTotalChkBox}
                                                            />Total Exposure</label>
                                                    </div>
                                                    <div className="label col-md-5">
                                                        <input
                                                            className="form-control"

                                                            type="text"
                                                            placeholder=""
                                                            aria-label=""
                                                            value={elTotal}
                                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setElTotal, false, elTotalChkBox, setElTotalChkBox)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="input-container col-md-12 fullWidthSearch">

                                                    <div className="label col-md-2">
                                                        <label className="" htmlFor="elNettChkBox">
                                                            <input className="checkboxMarginRight"
                                                                   name="elNettChkBox" type="checkbox" id="elNettChkBox"
                                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, elNett, setElNettChkBox)}
                                                                   checked={elNettChkBox}
                                                            />Nett Exposure</label>
                                                    </div>
                                                    <div className="label col-md-5">
                                                        <input
                                                            className="form-control"

                                                            type="text"
                                                            placeholder=""
                                                            aria-label=""
                                                            value={elNett}
                                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setElNett, false, elNettChkBox, setElNettChkBox)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="input-container col-md-12 fullWidthSearch">

                                                    <div className="label col-md-2">
                                                        <label className="" htmlFor="elIDSSChkBox">
                                                            <input className="checkboxMarginRight"
                                                                   name="elIDSSChkBox" type="checkbox" id="elIDSSChkBox"
                                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, elIDSS, setElIDSSChkBox)}
                                                                   checked={elIDSSChkBox}
                                                            />IDSS Exposure</label>
                                                    </div>
                                                    <div className="label col-md-5">
                                                        <input
                                                            className="form-control"

                                                            type="text"
                                                            placeholder=""
                                                            aria-label=""
                                                            value={elIDSS}
                                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setElIDSS, false, elIDSSChkBox, setElIDSSChkBox)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="input-container col-md-12 fullWidthSearch">

                                                    <div className="label col-md-2">
                                                        <label className="" htmlFor="elFSTchkBox">
                                                            <input className="checkboxMarginRight"
                                                                   name="elFSTchkBox" type="checkbox" id="elFSTchkBox"
                                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, elFST, setElFSTChkBox)}
                                                                   checked={elFSTChkBox}
                                                            />FST Exposure</label>
                                                    </div>
                                                    <div className="label col-md-5">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder=""
                                                            aria-label=""
                                                            value={elFST}
                                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, setElFST, false, elFSTChkBox, setElFSTChkBox)}
                                                        />

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div className="card shadow mb-4">
                                        <div
                                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h5 className="m-0 font-weight-bold text-primary">Topup Limit Authorization
                                                Level</h5>


                                        </div>
                                        <div className="card-body">
                                            <div className="col-12 search-container ">

                                                <div className="input-container col-md-12 fullWidthSearch">

                                                    <div className="label col-md-2">
                                                        <label className="" htmlFor="topUpBuyChkBox"> <input
                                                            className="checkboxMarginRight"
                                                            name="topUpBuyChkBox" type="checkbox" id="topUpBuyChkBox"
                                                            onChange={e => handleDisableChkBxByVal(e.target.checked, topUpBuy, settopUpBuyChkBox)}
                                                            checked={topUpBuyChkBox}
                                                        />Buy Exposure</label>
                                                    </div>
                                                    <div className="label col-md-5">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder=""
                                                            aria-label=""
                                                            value={topUpBuy}
                                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, settopUpBuy, false, topUpBuyChkBox, settopUpBuyChkBox)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="input-container col-md-12 fullWidthSearch">

                                                    <div className="label col-md-2">
                                                        <label className="" htmlFor="topUpSellChkBox">
                                                            <input className="checkboxMarginRight"
                                                                   name="topUpSellChkBox" type="checkbox"
                                                                   id="topUpSellChkBox"
                                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, topUpSell, settopUpSellChkBox)}
                                                                   checked={topUpSellChkBox}
                                                            />Sell Exposure</label>
                                                    </div>
                                                    <div className="label col-md-5">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder=""
                                                            aria-label=""
                                                            value={topUpSell}
                                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, settopupSell, false, topUpSellChkBox, settopUpSellChkBox)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="input-container col-md-12 fullWidthSearch">

                                                    <div className="label col-md-2">
                                                        <label className="" htmlFor="topUpTotalChkBox">
                                                            <input className="checkboxMarginRight"
                                                                   name="topUpTotalChkBox" type="checkbox"
                                                                   id="topUpTotalChkBox"
                                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, topUpTotal, settopUpTotalChkBox)}
                                                                   checked={topUpTotalChkBox}
                                                            />Total Exposure</label>
                                                    </div>
                                                    <div className="label col-md-5">
                                                        <input
                                                            className="form-control"

                                                            type="text"
                                                            placeholder=""
                                                            aria-label=""
                                                            value={topUpTotal}
                                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, settopUpTotal, false, topUpTotalChkBox, settopUpTotalChkBox)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="input-container col-md-12 fullWidthSearch">

                                                    <div className="label col-md-2">
                                                        <label className="" htmlFor="topUpNettChkBox">
                                                            <input className="checkboxMarginRight"
                                                                   name="topUpNettChkBox" type="checkbox"
                                                                   id="topUpNettChkBox"
                                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, topUpNett, settopUpNettChkBox)}
                                                                   checked={topUpNettChkBox}
                                                            />Nett Exposure</label>
                                                    </div>
                                                    <div className="label col-md-5">
                                                        <input
                                                            className="form-control"

                                                            type="text"
                                                            placeholder=""
                                                            aria-label=""
                                                            value={topUpNett}
                                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, settopUpNett, false, topUpNettChkBox, settopUpNettChkBox)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="input-container col-md-12 fullWidthSearch">

                                                    <div className="label col-md-2">
                                                        <label className="" htmlFor="topUpIDSSChkBox">
                                                            <input className="checkboxMarginRight"
                                                                   name="topUpIDSSChkBox" type="checkbox"
                                                                   id="topUpIDSSChkBox"
                                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, topUpIDSS, settopUpIDSSChkBox)}
                                                                   checked={topUpIDSSChkBox}
                                                            />IDSS Exposure</label>
                                                    </div>
                                                    <div className="label col-md-5">
                                                        <input
                                                            className="form-control"

                                                            type="text"
                                                            placeholder=""
                                                            aria-label=""
                                                            value={topUpIDSS}
                                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, settopUpIDSS, false, topUpIDSSChkBox, settopUpIDSSChkBox)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="input-container col-md-12 fullWidthSearch">

                                                    <div className="label col-md-2">
                                                        <label className="" htmlFor="topUpFSTChkBox">
                                                            <input className="checkboxMarginRight"
                                                                   name="topUpFSTChkBox" type="checkbox" id="topUpFSTChkBox"
                                                                   onChange={e => handleDisableChkBxByVal(e.target.checked, topUpFST, settopUpFSTChkBox)}
                                                                   checked={topUpFSTChkBox}
                                                            />FST Exposure</label>
                                                    </div>
                                                    <div className="label col-md-5">
                                                        <input
                                                            className="form-control"

                                                            type="text"
                                                            placeholder=""
                                                            aria-label=""
                                                            value={topUpFST}
                                                            onChange={(e) => handleDecimalNumChangeWifCursor(popupManager, e, settopUpFST, false, topUpFSTChkBox, settopUpFSTChkBox)}
                                                        />

                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                    </div>

                                    <button className={showResetAuthLimitBtn ? "btn btn-primary" : "divHide"}
                                            style={{"margin-bottom": "1rem"}}
                                            onClick={() => handleResetAuthLimit()}>
                                        Reset Authorization Limit
                                    </button>


                                </div>
                            </div>

                            <div className="cls_profile card-body">
                                <div className="col-12 search-container ">
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">User ID</div>
                                        <div className="label col-md-5">
                                            <input
                                                className="form-control"

                                                placeholder="e.g fntUsr2"
                                                aria-label=""
                                                readOnly={currentUserIdReadOnly}
                                                value={currentUserId}
                                                onChange={(e) => setCurrentUserId(e.target.value)}
                                                onBlur={(e) => handleValidateUsername(e.target.value)}

                                            />
                                            <b className='password-alert-red'>{usernameAlert.toString()}</b>

                                        </div>
                                        <b className='mandatory-red'>*</b>


                                        <div className="label col-md-1">
                                            <b id='div_usertype_mandatory' className='mandatory-red-inside divHide'>*</b>
                                            User Type
                                        </div>
                                        <div className="col-md-5">

                                            <select
                                                className="form-control"
                                                aria-label=""
                                                value={currentUserType}
                                                disabled={currentUserTypeReadOnly}
                                                onChange={e => handleSelectChange(e.target.value, setCurrentUserType, false)}
                                            >
                                                <option value="">Please select user type</option>

                                                {userTypeList.map(el => <option
                                                    value={el.ut_id}>{el.ut_name}</option>)}
                                            </select>

                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">First Name</div>
                                        <div className="label col-md-2">
                                            <input
                                                className="form-control"

                                                placeholder="e.g. Kuang Yeow"
                                                aria-label=""
                                                readOnly={currentUserNameReadOnly}
                                                value={currentUserName}
                                                onChange={(e) => setCurrentUserName(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setCurrentUserName, popupManager)}

                                            />
                                        </div>

                                        <div className="label col-md-1">
                                            <b className='mandatory-red-inside'>*</b>

                                            Last Name
                                        </div>
                                        <div className="label col-md-2">
                                            <input
                                                className="form-control"
                                                placeholder="e.g. Lee"
                                                aria-label=""
                                                readOnly={currentUserNameReadOnly}
                                                value={currentUserLastName}
                                                onChange={(e) => setCurrentUserLastName(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setCurrentUserLastName, popupManager)}

                                            />

                                        </div>
                                        <b className='mandatory-red'>*</b>

                                        <div className="label col-md-1">Title</div>
                                        <div className="col-md-5">
                                            <input
                                                className="form-control"
                                                placeholder="e.g. Mr, Mrs, Ms, Dato"
                                                aria-label=""
                                                readOnly={currentUserTitleReadOnly}
                                                value={currentUserTitle}
                                                onChange={(e) => setCurrentUserTitle(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setCurrentUserTitle, popupManager)}

                                            />
                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">NRIC No</div>
                                        <div className="label col-md-5">
                                            <input
                                                className="inputNoNum form-control"
                                                placeholder="e.g. 901012049982"
                                                aria-label=""
                                                type='number'
                                                readOnly={currentNRICReadOnly}
                                                value={currentNRIC}
                                                onChange={(e) => setCurrentNRIC(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setCurrentNRIC, popupManager)}

                                            />
                                        </div>
                                        <div className="label col-md-1">Passport No</div>
                                        <div className="col-md-5">
                                            <input
                                                className="form-control"

                                                placeholder=""
                                                aria-label=""
                                                readOnly={currentPassportReadOnly}
                                                value={currentPassport}
                                                onChange={(e) => setCurrentPassport(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setCurrentPassport, popupManager)}

                                            />
                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">Gender</div>
                                        <div className="label col-md-5">
                                            <label htmlFor="chk_male">
                                                <input name="gender" type="radio" id="chk_male" value="M"
                                                       checked={currentGenderMaleChecked == 'M'}
                                                       onChange={e => handleSelectChange(e.target.value, setCurrentGenderMaleChecked, currentGenderMaleReadOnly)}
                                                />
                                                Male
                                            </label>

                                            <label className="checkboxMarginLeft" htmlFor="chk_female">
                                                <input name="gender" type="radio" id="chk_female" value="F"
                                                       checked={currentGenderMaleChecked == 'F'}
                                                       onChange={e => handleSelectChange(e.target.value, setCurrentGenderMaleChecked, currentGenderMaleReadOnly)}
                                                />
                                                Female
                                            </label>


                                        </div>
                                        <div className="label col-md-1">D.O.B</div>
                                        <div className="col-md-5">

                                            <DatePicker
                                                format={dateFormats()}
                                                placeholder={datePlaceHolder()}
                                                disabled={currentDOBReadOnly}
                                                value={isNaN(dayjs(currentDOB)) ? null : dayjs(currentDOB)}
                                                onChange={(e) => setCurrentDOB(e)}
                                            />
                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">Nationality</div>
                                        <div className="label col-md-5">
                                            <input
                                                className="form-control"

                                                placeholder="e.g. Malaysian"
                                                aria-label=""
                                                readOnly={currentNationalityReadOnly}
                                                value={currentNationality}
                                                onChange={(e) => setCurrentNationality(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setCurrentNationality, popupManager)}

                                            />
                                        </div>
                                        <div className="label col-md-1">Email</div>
                                        <div className="col-md-5">
                                            <input
                                                className="form-control"
                                                placeholder="leekw@somemail.com"
                                                aria-label=""
                                                type='email'
                                                readOnly={currentEmailReadOnly}
                                                value={currentEmail}
                                                onChange={(e) => setCurrentEmail(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setCurrentEmail, popupManager)}

                                            />
                                        </div>
                                        <b className='mandatory-red'>*</b>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">Home Phone</div>
                                        <div className="label col-md-5">
                                            <PhoneInput
                                                containerClass='react-tel-input-full'
                                                inputClass='react-tel-input-full'
                                                dropdownClass='react-tel-input-full' country={defaultPhoneCountry}
                                                preferredCountries={['my', 'sg']}
                                                enableAreaCodeStretch
                                                masks={{defaultPhoneCountry: '...........'}}
                                                value={currentHomePhone}
                                                disabled={currentHomePhoneReadOnly}
                                                countryCodeEditable={false}
                                                onChange={phone => handleSelectChange(phone, setCurrentHomePhone, currentHomePhoneReadOnly)}
                                            />
                                        </div>
                                        <div className="label col-md-1">Branch</div>
                                        <div className="col-md-5 ">
                                            <Multiselect
                                                options={branchList}
                                                onSelect={onSelect}
                                                onRemove={onRemove}
                                                selectedValues={currentBranch}
                                                displayValue="name"
                                                placeholder="Search by Branch Code / Branch Name"
                                                selectionLimit={1}
                                                hidePlaceholder={true}
                                                showCheckbox={false}
                                                customCloseIcon={<></>}
                                                disable={currentBranchReadOnly}
                                                avoidHighlightFirstOption={true}
                                                style={{
                                                    chips: {
                                                        background: 'transparent',
                                                        color: "#6e707e",
                                                        'font-size': "1em",
                                                        'padding-right': "0px"
                                                    },
                                                    closeIcon: {
                                                        display: "none"
                                                    }
                                                }}
                                            />
                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">Mobile Phone</div>
                                        <div className="label col-md-5">
                                            <PhoneInput
                                                containerClass='react-tel-input-full'
                                                inputClass='react-tel-input-full'
                                                dropdownClass='react-tel-input-full' country={defaultPhoneCountry}
                                                preferredCountries={['my', 'sg']}
                                                enableAreaCodeStretch
                                                masks={{defaultPhoneCountry: '...........'}}
                                                disabled={currentMobilePhoneReadOnly}
                                                value={currentMobilePhone}
                                                countryCodeEditable={false}
                                                onChange={phone => handleSelectChange(phone, setCurrentMobilePhone, currentMobilePhoneReadOnly)}
                                            />
                                        </div>
                                        <div className="label col-md-1">Status</div>
                                        <div className="col-md-5">
                                            <select
                                                className="form-control"

                                                aria-label=""
                                                disabled={currentStatusReadOnly}
                                                value={currentStatus == 11 ? 1 : currentStatus}
                                                onChange={e => handleSelectChange(e.target.value, setCurrentStatus, currentStatusReadOnly)}
                                            >
                                                {profileStatusOption()}

                                            </select>
                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">Office Phone</div>
                                        <div className="label col-md-5 react-tel-input-full">
                                            <PhoneInput
                                                containerClass='react-tel-input-full'
                                                inputClass='react-tel-input-full'
                                                dropdownClass='react-tel-input-full'
                                                country={defaultPhoneCountry}
                                                preferredCountries={['my', 'sg']}
                                                enableAreaCodeStretch
                                                masks={{defaultPhoneCountry: '...........'}}
                                                disabled={currentOfficePhoneReadOnly}
                                                value={currentOfficePhone}
                                                countryCodeEditable={false}
                                                onChange={phone => handleSelectChange(phone, setCurrentOfficePhone, currentOfficePhoneReadOnly)}

                                            />
                                        </div>
                                        <div className="label col-md-1">Race</div>
                                        <div className="col-md-5">
                                            <select
                                                className="form-control"

                                                aria-label=""
                                                disabled={currentRaceReadOnly}
                                                value={currentRace}
                                                onChange={e => handleOtherRace(e.target.value, setCurrentRace, currentRaceReadOnly)}
                                            >
                                                <option>Please select race</option>
                                                <option value="BUMIPUTERA">Bumiputra</option>
                                                <option value="CHINESE">Chinese</option>
                                                <option value="INDIAN">Indian</option>
                                                <option value="OTHERS">Others</option>
                                            </select>

                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">

                                        <div style={showTopAccessStatus ? {} : {display: 'none'}}
                                             className=" label col-md-1">Access Status
                                        </div>
                                        <div style={showTopAccessStatus ? {} : {display: 'none'}} className=" col-md-5">
                                            <select
                                                className="form-control"
                                                aria-label=""
                                                disabled={currentAccStatusReadOnly}
                                                value={currentAccStatus}
                                                onChange={e => handleSelectChange(e.target.value, setCurrentAccStatus, currentAccStatusReadOnly)}

                                                // onChange={e => setCurrentAccStatus(e.target.value, setCurrentIdss, currentIdssReadOnly)}
                                            >
                                                <option value='1'>Unblock</option>
                                                <option value='2'>Block</option>
                                            </select>
                                        </div>

                                        <div style={!showTopAccessStatus ? {} : {display: 'none'}}
                                             className=" label col-md-1"></div>
                                        <div style={!showTopAccessStatus ? {} : {display: 'none'}}
                                             className=" label col-md-5"></div>

                                        <div className=" col-md-1"></div>
                                        <div className="col-md-5">
                                            <input
                                                className="form-control"
                                                placeholder="Other race"
                                                aria-label=""
                                                readOnly={currentOtherRaceReadOnly}
                                                value={currentOtherRace}
                                                onChange={(e) => setCurrentOtherRace(e.target.value)}
                                            />
                                        </div>


                                    </div>
                                    {/*{renderInFormDates(currentRole)}*/}
                                    <div id='div_dealer_inform' className='divHide'>
                                        <div className="input-container  fullWidthSearch">
                                            <div className="label col-md-1">License No.</div>
                                            <div className="label col-md-5">
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-label=""
                                                    readOnly={currentLicenseNoReadOnly}
                                                    value={currentLicenseNo}
                                                    onChange={(e) => setCurrentLicenseNo(e.target.value)}
                                                    onBlur={(e) => specialCharCheck(e.target.value, setCurrentLicenseNo, popupManager)}

                                                />
                                            </div>
                                            <div className="label col-md-1">Dealer Code</div>
                                            <div className="col-md-5">
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-label=""
                                                    readOnly={currentDealerCodeReadOnly}
                                                    value={currentDealerCode}
                                                    onChange={(e) => setCurrentDealerCode(e.target.value)}
                                                    onBlur={(e) => specialCharCheck(e.target.value, setCurrentDealerCode, popupManager)}

                                                />
                                            </div>
                                        </div>
                                        <div className="input-container  fullWidthSearch">
                                            <div className="label col-md-1">Creation Date</div>
                                            <div className="label col-md-5">
                                                <input
                                                    className="form-control"
                                                    placeholder="#Auto generated"
                                                    aria-label=""
                                                    readOnly={currentCreationDateReadOnly}
                                                    value={currentCreationDate}
                                                    onChange={(e) => setCurrentCreationDate(e.target.value)}
                                                />
                                            </div>
                                            <div className="label col-md-1">Last Update Date</div>
                                            <div className="col-md-5">
                                                <input
                                                    className="form-control"
                                                    placeholder="#Auto generated"
                                                    aria-label=""
                                                    readOnly={currentLastUpdateDateReadOnly}
                                                    value={currentLastUpdateDate}
                                                    onChange={(e) => setCurrentLastUpdateDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div id='div_client_inform' className='divHide'>
                                        <div className="input-container  fullWidthSearch">
                                            <div className="label col-md-1">Client Type</div>
                                            <div className="label col-md-5">
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-label=""
                                                    readOnly={currentClientTypeReadOnly}
                                                    value={currentClientType}
                                                    onChange={(e) => setCurrentClientType(e.target.value)}
                                                />
                                            </div>
                                            <div className="label col-md-1">Dealer Code</div>
                                            <div className="col-md-5">
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-label=""
                                                    readOnly={currentDealerCodeReadOnly}
                                                    value={currentDealerCode}
                                                    onChange={(e) => setCurrentDealerCode(e.target.value)}
                                                    onBlur={(e) => specialCharCheck(e.target.value, setCurrentDealerCode, popupManager)}

                                                />
                                            </div>
                                        </div>
                                        <div className="input-container  fullWidthSearch">
                                            <div className="label col-md-1">Bank AC No.</div>
                                            <div className="label col-md-5">
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-label=""
                                                    readOnly={currentBankAcNoReadOnly}
                                                    value={currentBankAcNo}
                                                    onChange={(e) => setCurrentBankAcNo(e.target.value)}
                                                    onBlur={(e) => specialCharCheck(e.target.value, setCurrentBankAcNo, popupManager)}

                                                />
                                            </div>
                                            <div className="label col-md-1">Access Status</div>
                                            <div className="col-md-5">
                                                <select
                                                    className="form-control"
                                                    aria-label=""
                                                    disabled={currentAccStatusReadOnly}
                                                    value={currentAccStatus}
                                                    onChange={e => handleSelectChange(e.target.value, setCurrentAccStatus, currentAccStatusReadOnly)}
                                                >
                                                    <option value='1'>Unblock</option>
                                                    <option value='2'>Block</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-container  fullWidthSearch">
                                            <div className="label col-md-1">Bank Name</div>
                                            <div className="label col-md-5">
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-label=""
                                                    readOnly={currentBankNameReadOnly}
                                                    value={currentBankName}
                                                    onChange={(e) => setCurrentBankName(e.target.value)}
                                                    onBlur={(e) => specialCharCheck(e.target.value, setCurrentBankName, popupManager)}

                                                />


                                            </div>
                                            <div className="label col-md-1">eContract</div>
                                            <div className="col-md-5">
                                                <select
                                                    className="form-control"
                                                    aria-label=""
                                                    disabled={currenteContractReadOnly}
                                                    value={currenteContract}
                                                    onChange={e => handleSelectChange(e.target.value, setCurrenteContract, currenteContractReadOnly)}

                                                >
                                                    <option value='1'>Yes</option>
                                                    <option value='0'>No</option>
                                                </select>

                                            </div>
                                        </div>
                                        <div className="input-container  fullWidthSearch">
                                            <div className="label col-md-1">IDSS</div>
                                            <div className="label col-md-5">
                                                <select
                                                    className="form-control"

                                                    aria-label=""
                                                    disabled={currentIdssReadOnly}
                                                    value={currentIdss}
                                                    onChange={e => handleSelectChange(e.target.value, setCurrentIdss, currentIdssReadOnly)}
                                                >
                                                    <option value='1'>Yes</option>
                                                    <option value='0'>No</option>
                                                </select>
                                            </div>
                                            <div className="label col-md-1">eStatement</div>
                                            <div className="col-md-5">
                                                <select
                                                    className="form-control"
                                                    aria-label=""
                                                    disabled={currentEstatementReadOnly}
                                                    value={currentEstatement}
                                                    // onChange={e => setCurrentEstatement(e.target.value, setCurrentIdss, currentIdssReadOnly)}
                                                >
                                                    <option value='1'>Yes</option>
                                                    <option value='0'>No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-container  fullWidthSearch">
                                            <div className="label col-md-1">Creation Date</div>
                                            <div className="label col-md-5">
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-label=""
                                                    readOnly={currentCreationDateReadOnly}
                                                    value={currentCreationDate}
                                                    onChange={(e) => setCurrentCreationDate(e.target.value)}
                                                />
                                            </div>
                                            <div className="label col-md-1">T&C Accept Date</div>
                                            <div className="col-md-5">
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-label=""
                                                    readOnly={currentTNCAcceptDateReadOnly}
                                                    value={currentTNCAcceptDate}
                                                    onChange={(e) => setCurrentTNCAcceptDate(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="input-container  fullWidthSearch">
                                            <div className="label col-md-1">Last Update Date</div>
                                            <div className="col-md-5">
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-label=""
                                                    readOnly={currentLastUpdateDateReadOnly}
                                                    value={currentLastUpdateDate}
                                                    onChange={(e) => setCurrentLastUpdateDate(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-1"></div>
                                            <div className="col-md-5">

                                            </div>
                                        </div>
                                    </div>
                                    <div id='div_operation_inform' className='divHide'>
                                        <div className="input-container  fullWidthSearch">
                                            <div className="label col-md-1">Creation Date</div>
                                            <div className="label col-md-5">
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-label=""
                                                    readOnly={currentCreationDateReadOnly}
                                                    value={currentCreationDate}
                                                    onChange={(e) => setCurrentCreationDate(e.target.value)}
                                                />
                                            </div>
                                            <div className="label col-md-1">Last Update Date</div>
                                            <div className="col-md-5">
                                                <input
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-label=""
                                                    readOnly={currentLastUpdateDateReadOnly}
                                                    value={currentLastUpdateDate}
                                                    onChange={(e) => setCurrentLastUpdateDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">Address</div>
                                        <div className="label col-md-11">
                                            <input
                                                className="form-control"

                                                placeholder="e.g. No.1 Jalan Pertama"
                                                aria-label=""
                                                readOnly={currentAddress1ReadOnly}
                                                value={currentAddress1}
                                                onChange={(e) => setCurrentAddress1(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setCurrentAddress1, popupManager)}

                                            />
                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1"></div>
                                        <div className="label col-md-11">
                                            <input
                                                className="form-control"

                                                placeholder="e.g. Taman Sentosa "
                                                aria-label=""
                                                readOnly={currentAddress2ReadOnly}
                                                value={currentAddress2}
                                                onChange={(e) => setCurrentAddress2(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setCurrentAddress2, popupManager)}

                                            />
                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1"></div>
                                        <div className="label col-md-11">
                                            <input
                                                className="form-control"

                                                placeholder="e.g. Seri Kembangan"
                                                aria-label=""
                                                readOnly={currentAddress3ReadOnly}
                                                value={currentAddress3}
                                                onChange={(e) => setCurrentAddress3(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setCurrentAddress3, popupManager)}

                                            />
                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">Country</div>
                                        <div className="label col-md-3">
                                            <select
                                                className="form-control"

                                                aria-label=""
                                                disabled={currentCountryReadOnly}
                                                value={currentCountry}
                                                onChange={(e) => handleCountryChange(e.target.value, e.target.options[e.target.selectedIndex].text, setCurrentCountry, setCurrentCountryText)}

                                            >
                                                <option>Please select country</option>
                                                {countryList.map(el => <option
                                                    value={el.c_name}>{el.c_name}</option>)}
                                            </select>
                                        </div>
                                        <div className="label col-md-1">State</div>
                                        <div className="label col-md-2">
                                            {stateField(currentState, setCurrentState, (currentCountry == 2 || currentCountry == "Malaysia" ? true : false), currentStateReadOnly, popupManager)}

                                        </div>
                                        <div className="label col-md-1">Post Code</div>
                                        <div className="col-md-4">
                                            <input
                                                className="form-control"
                                                placeholder="e.g. Seri Kembangan"
                                                aria-label=""
                                                readOnly={currentPostCodeReadOnly}
                                                value={currentPostCode}
                                                onChange={(e) => setCurrentPostCode(e.target.value)}
                                                onBlur={(e) => specialCharCheck(e.target.value, setCurrentPostCode, popupManager)}

                                            />
                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1">Password</div>
                                        <div className=" col-md-3">
                                            <input
                                                className="form-control showPasswordInput"
                                                type={pwInputType}
                                                placeholder=""
                                                aria-label=""
                                                readOnly={currentPasswordReadOnly}
                                                value={currentPassword}
                                                onBlur={(e) => handlePwValidation(e.target.value)}
                                                onChange={(e) => setCurrentPassword(e.target.value)}
                                            />
                                            <b className='password-alert-red'>{passwordAlert.toString()}</b>

                                        </div>

                                        <div className="label col-md-1">
                                            <div id="div_pw_eye"
                                                 onClick={() => handlePasswordShow()}
                                                 className="fa fa-eye showPasswordButton" aria-hidden="true"></div>

                                        </div>
                                        <div className="col-md-3">Last Password Change Date</div>
                                        <div className="col-md-3">
                                            <div>{lastPasswordChange == "" ? "-" : lastPasswordChange}</div>
                                        </div>


                                    </div>
                                    <div className="input-container  fullWidthSearch">
                                        <div className="label col-md-1"></div>
                                        <div className="label col-md-4 generatePasswordBtn">
                                            <Toggle
                                                defaultChecked={generatePwToggle}
                                                checked={generatePwToggle}
                                                onClick={() => handleAutoGenPassword()}/>
                                            Auto Generate Password
                                        </div>

                                        <div className="col-md-3">Password Expired Date</div>

                                        <div className="col-md-3">
                                            <div>{passwordExpiredDate == "" ? "-" : passwordExpiredDate}</div>
                                        </div>

                                    </div>


                                </div>
                            </div>

                        </div>

                    </div>


                    <div id="div_client" className=" divHide">

                        <div className="col-xl-12 col-lg-12">
                            <div className="card shadow mb-4">
                                <div
                                    className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h5 className="m-0 font-weight-bold text-primary">Client Account</h5>


                                </div>
                                <div className="card-body">
                                    <div className="col-12 search-container">
                                        <ReactTable
                                            rowClick={setCurrentPage}
                                            perPageQty={setCurrentPerPage}
                                            columns={columns}
                                            data={tableData}
                                            pagination={{
                                                currentPage: currentPage,
                                                currentPerPage: currentPerPage,
                                                totalRecords: totalRecords,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div id="div_operator" className=" divHide">

                        <div className="col-xl-12 col-lg-12">
                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h5 className="m-0 font-weight-bold text-primary">Access Schedule</h5>


                            </div>
                            <div className="card shadow mb-4">

                                <div className="card-body">
                                    <div className="col-12 search-container ">

                                        <div className="input-container  fullWidthSearch">
                                            <div className="label col-md-1">Enable</div>
                                            <div className="label col-md-5">
                                                <label htmlFor="enable_yes">
                                                    <input name="enable_acs" type="radio" id="enable_yes" value="1"
                                                           checked={currentEnaYesChecked == "1"}
                                                           onChange={e => handleSelectChange(e.target.value, setCurrentEnaYesChecked, false)}
                                                    />
                                                    Yes
                                                </label>
                                                <label className="checkboxMarginLeft" htmlFor="enable_no">
                                                    <input name="enable_acs" type="radio" id="enable_no" value="0"
                                                           checked={currentEnaYesChecked == "0"}
                                                           onChange={e => handleSelectChange(e.target.value, setCurrentEnaYesChecked, false)}

                                                    />
                                                    No
                                                </label>


                                            </div>
                                            <div className="label col-md-1">Expired On</div>
                                            <div className="col-md-5">

                                                <ReactDatepick
                                                    value={currentExpiredOn}
                                                    dateSetter={setCurrentExpiredOn}
                                                    resetToggle={toggleReset}
                                                    disabled={currentExpiredOnReadOnly}

                                                />
                                            </div>


                                        </div>
                                        <div className="input-container  fullWidthSearch">
                                            <div className="label col-md-1">Start Time</div>
                                            <div className="label col-md-5">
                                                <select
                                                    className="form-control"
                                                    id="holiday_from_time"
                                                    aria-label=""
                                                    disabled={currentStartTimeReadOnly}
                                                    value={currentStartTime}
                                                    onChange={e => handleSelectChange(e.target.value, setCurrentStartTime, currentStartTimeReadOnly)}
                                                    onBlur={e => simpleTimeCheck(e.target.value, currentStartTime, setCurrentStopTime, popupManager)}
                                                >
                                                    {timeElement()}
                                                </select>


                                            </div>
                                            <div className="label col-md-1">End Time</div>
                                            <div className="col-md-5">
                                                <select
                                                    className="form-control"
                                                    id="holiday_to_time"
                                                    aria-label=""
                                                    disabled={currentStartTimeReadOnly}
                                                    value={currentStopTime}
                                                    onChange={e => handleSelectChange(e.target.value, setCurrentStopTime, currentStopTimeReadOnly)}
                                                    onBlur={e => simpleTimeCheck(currentStartTime, e.target.value, setCurrentStopTime, popupManager)}

                                                >
                                                    {timeElement()}

                                                </select>
                                            </div>


                                        </div>
                                        <div className="input-container  fullWidthSearch">
                                            <div className="label col-md-1">Day</div>
                                            <div className="label col-md-11">
                                                <label htmlFor="access_mon">
                                                    <input name="access_day" type="checkbox"
                                                           id="access_mon"
                                                           onChange={e => setMonChecked(e.target.checked)}
                                                           checked={monChecked} value="monday"/> Monday</label>
                                                <label className="checkboxMarginLeft" htmlFor="access_tue"> <input
                                                    name="access_day" type="checkbox" id="access_tue"
                                                    onChange={e => setTueChecked(e.target.checked)}
                                                    checked={tueChecked} value="tuesday"/> Tuesday</label>
                                                <label className="checkboxMarginLeft" htmlFor="access_wed"> <input
                                                    name="access_day" type="checkbox" id="access_wed"
                                                    onChange={e => setWedChecked(e.target.checked)}
                                                    checked={wedChecked} value="wednesday"/> Wednesday</label>
                                                <label className="checkboxMarginLeft" htmlFor="access_thu"> <input
                                                    name="access_day" type="checkbox" id="access_thu"
                                                    onChange={e => setThuChecked(e.target.checked)}
                                                    checked={thuChecked} value="thursday"/> Thursday</label>
                                                <label className="checkboxMarginLeft" htmlFor="access_fri"> <input
                                                    name="access_day" type="checkbox" id="access_fri"
                                                    onChange={e => setFriChecked(e.target.checked)}
                                                    checked={friChecked} value="friday"/> Friday</label>
                                                <label className="checkboxMarginLeft" htmlFor="access_sat"> <input
                                                    name="access_day" type="checkbox" id="access_sat"
                                                    onChange={e => setSatChecked(e.target.checked)}
                                                    checked={satChecked} value="saturday"/> Saturday</label>
                                                <label className="checkboxMarginLeft" htmlFor="access_sun"> <input
                                                    name="access_day" type="checkbox" id="access_sun"
                                                    onChange={e => setSunChecked(e.target.checked)}
                                                    checked={sunChecked} value="sunday"/> Sunday</label>
                                            </div>


                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>


                    {/*Bottom Button Section*/}
                    <div className="col-xl-12 col-lg-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <div className="label col-md-1"></div>
                                <div id='div_edit' className="divHide label col-md-11 btn_search_padding">
                                    <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>
                                    {accessItem['edit'] ?
                                        <>
                                            <button className={showResetAMBtn ? "btn btn-primary" : "divHide"}
                                                    onClick={() => handleResetAccessMatrix()}>Reset To Default
                                            </button>
                                            <button className={showResetMarketBtn ? "btn btn-primary" : "divHide"}
                                                    onClick={() => handlResetToDefaultAccessMatrix()}>Reset To Default
                                            </button>
                                            <button className={showResetOrderBtn ? "btn btn-primary" : "divHide"}
                                                    onClick={() => handlResetToDefaultAccessMatrix()}>Reset To Default
                                            </button>

                                            <button className={showResetbtn ? "btn btn-primary" : "divHide"}
                                                    onClick={() => handleResetBtn()}>Reset
                                            </button>
                                            <button className="btn btn-primary " onClick={() => handleSaveBtn()}>Save
                                            </button>
                                        </>
                                        :
                                        ""
                                    }


                                </div>
                                <div id='div_add' className="divHide label col-md-11 btn_search_padding">
                                    <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back</button>
                                    {accessItem['add'] ?
                                        <>
                                            <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset
                                            </button>
                                            <button className="btn btn-primary " onClick={() => handleAddBtn()}>Add</button>
                                        </>
                                        :
                                        ""
                                    }

                                </div>
                            </div>
                        </div>


                    </div>


                </div>
            </div>
        );
    }
;

export default Index;
