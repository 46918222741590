// @ts-nocheck
import { createRef, useRef, useState, useEffect, useContext } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import '../../asset/sb-admin-2/scss/sb-admin-2.scss';

import '../../asset/styles/layout-style.css';

import useAuth from '../../../hooks/useAuth';

import Header from '../Header';
import Sidebar from '../../navigation/Sidebar';

const Layout = (props) => {
    const [sidebarPosition, setSidebarPosition] = useState(null);
    const [isIframe, setIsIframe] = useState(false);
    const [pushCss, setPushCss] = useState(false);
    const [topbarCss, setTopBarCss] = useState(false);

    /* @ts-ignore */
    const { auth } = useAuth();
    const mode = auth.mode;

    // const isIframe = props.isIframe||true;
    useEffect(() => {
        const handleWindowResize = (e: UIEvent) => {
            // console.log("TIME TO RESIZE!")
            let leftWidth = window.screen.width;
            if(leftWidth > 1500)document.getElementById("rightColItem").style.width = "94.5%";
            else if(leftWidth > 2000)document.getElementById("rightColItem").style.width = "96.5%";
            else if(leftWidth < 768)document.getElementById("rightColItem").style.width = "100%";
            else if(leftWidth < 800)document.getElementById("rightColItem").style.width = "85.9%";
            else document.getElementById("rightColItem").style.width = "92.5%";
        };

        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    useEffect(() => {
        let leftWidth = window.screen.width;
        if(leftWidth > 1500)document.getElementById("rightColItem").style.width = "94.5%";
        else if(leftWidth > 2000)document.getElementById("rightColItem").style.width = "96.5%";
        else if(leftWidth < 768)document.getElementById("rightColItem").style.width = "100%";
        else if(leftWidth < 800)document.getElementById("rightColItem").style.width = "85.9%";
        else document.getElementById("rightColItem").style.width = "92.5%";
    }, []);

    return (
        <div className="App">
            <div
                className={'layout-container ' + (mode == 'dark' ? 'dark' : '')}
            >
                <div className="main-container fluid-container overflow-hidden  tds-iframe-container">

                    <div className="row" style={{padding_right:"0"}} id="">
                    <div className="" id="leftColItem"
                         // style={{float:"left"}}
                         style={{maxWidth:"100px",min_height:"100em"}}
                    >
                        <Sidebar position={sidebarPosition} />

                    </div>

                    <div className="rightColItem" id="rightColItem"
                         // style={{float:"right"}}
                         style={{position:"absolute",right:"0",padding:"0"}}
                    >
                        <Header />

                        <div
                             className={
                                 'side-menu-page-container' +
                                 (isIframe == false ? ' pl-4 pr-4 ' : ' ')
                             }
                        >
                            <div
                                className={
                                    'page-content pb-1' +
                                    (isIframe == false ? ' ' : ' iframe-block ')
                                }
                            >

                                <Outlet context={{ setIsIframe }} />
                                <footer className="footer-customized pt-5">
                                    <div className="footer-container protected">
                                        Copyright © 2023 FiNext Sdn. Bhd.
                                    </div>
                                </footer>
                            </div>

                        </div>
                    </div>


                    </div>



                    </div>
                </div>
            </div>
    );
};

export default Layout;
