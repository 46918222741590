import React, {useState, useEffect, useMemo} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import {useLocation, useNavigate} from 'react-router-dom';

import {numberWithCommas} from '../../../general/convertor';
import {confirmAlert} from "react-confirm-alert";
import api from "../../../../apis";
import {
    dealerStatusOptions,
    disableAllEleInDiv,
    elementIndexSearch,
    handleSelectChange,
    jsonContains,
    removeElementByIndex,
    simpleTimeCheck,
    convertErMsg,
    specialCharCheck,
    dateFormats,
    momentTimeFormat,
    handlePopup, performAntdTimeCheck, useLocalStorage
} from "../../../general/general-tools";
import ReactTable from "../../../general/form-control/react-table";
import Multiselect from "multiselect-react-dropdown";
import {usePopupManager} from "react-popup-manager";
import ModalConfirm from "../../public/ModalConfirm";
import dayjs from "dayjs";
import ReactDatepick from "../../../general/form-control/react-datepick";
import {getDealerAsscItem, updateDealerGroupAssc} from "../../../../apis/models/DealerGroup";
import {tab} from "@testing-library/user-event/dist/tab";

const Index = () => {
    const {setLoading} = useLoading();


    const [currentMode, setCurrentMode] = useState("");
    const [currentDate, setCurrentDate] = useState(null);

    const [currentGroupId, setCurrentGroupId] = useState("");
    const [currentGroupName, setCurrentGroupName] = useState("");
    const [currentGroupStatus, setCurrentGroupStatus] = useState("");
    const [currentEffForm, setCurrentEffForm] = useState(null);
    const [currentEffTo, setCurrentEffTo] = useState(null);

    const [tableData, setTableData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPerPage, setCurrentPerPage] = useState(10);
    const [selectedRows, setSelectedRows] = React.useState([]);


    const [toggledClearRows, setToggleClearRows] = React.useState(false);
    const [selectable, setSelectable] = React.useState(false);
    const [accessItem, setAccessItem] = useState({});
    const [toggleReset, setToggleReset] = useState(false);
    const [dealerList, setDealerList] = useState([]);
    const [selectedDealer, setSelectedDealer] = useState([]);

    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();
    const [isDateValid, setIsDateValid] = useState(false);

    useEffect(() => {
        performAntdTimeCheck(currentEffForm, currentEffTo, setCurrentEffTo, popupManager, setIsDateValid);
    }, [currentEffForm, currentEffTo]);

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        if (location.state) {
            if (location.state.row) {
                setCurrentGroupId(location.state.row.dg_id);
                setCurrentGroupName(location.state.row.dg_name);
                setCurrentGroupStatus(location.state.row.dg_status);
                if (location.state.row.dg_date_fr) setCurrentEffForm(dayjs(location.state.row.dg_date_fr).format(dateFormats()) == 'Invalid Date' ? "-" : dayjs(location.state.row.dg_date_fr).format(dateFormats()));
                if (location.state.row.dg_date_to) setCurrentEffTo(dayjs(location.state.row.dg_date_to).format(dateFormats()) == 'Invalid Date' ? "-" : dayjs(location.state.row.dg_date_to).format(dateFormats()));
                setAccessItem(location.state.perm);
                if (!location.state.perm['edit']) {
                    setTimeout(function () {
                        disableAllEleInDiv("mainDiv");
                    }, 50);
                } else {
                    setSelectable(true);
                }
            }
            setCurrentMode(location.state.mode)
            handleDealerList();
            handleInitAssoList();

        }
    }, []);

    const handleInitAssoList = async (reInitRules = false) => {
        setLoading(true);

        let param = {
            id: location.state.row.dg_id
        }

        api.getDealerAsscItem(param).then((result: any) => {
            setLoading(false);


            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                return false;
            }
            if (result.data) {
                if (result.data.list.length > 0) {
                    let tempList = result.data.list;

                    let tempArr = [];
                    for (let i = 0; i < tempList.length; i++) {
                        let tempEle = {};
                        tempEle['d_id'] = tempList[i]['dealer_id'];
                        tempEle['name'] = tempList[i]['dealer_name'];
                        tempEle['group_id'] = tempList[i]['id'];
                        tempEle['d_code'] = tempList[i]['dealer_code'];
                        tempEle['p_fullname'] = tempList[i]['dealer_name'];
                        tempEle['bb_code'] = tempList[i]['branch_code'];
                        tempEle['d_type'] = tempList[i]['user_type'];
                        tempEle['ut_name'] = tempList[i]['user_type'];
                        tempEle['rules'] = tempList[i]['rules'] ?? 0;
                        tempArr.push(tempEle);
                    }

                    setTableData(tempArr)
                    var TreeData = convertRawIntoDropdownDealer(tempArr);


                    setSelectedDealer(TreeData);



                    sleep(100).then(() => {
                        setLoading(false);

                        for (let i = 0; i < tempList.length; i++) {
                            if (tempList[i]['rules']) {
                                let viewEle = document.getElementById(tempList[i]['dealer_code'] + "_view_order");
                                let viewAccount = document.getElementById(tempList[i]['dealer_code'] + "_view_account");
                                let placeAccount = document.getElementById(tempList[i]['dealer_code'] + "_place_order");
                                let modifyAccount = document.getElementById(tempList[i]['dealer_code'] + "_modify_order");
                                let rules = tempList[i]['rules'];
                                let binaryRules = rules.toString(2).padStart(4, '0')
                                let viewOrder = binaryRules[2];
                                let viewAcc = binaryRules[3];
                                let placeOrder = binaryRules[1];
                                let modifyOrder = binaryRules[0];

                                if (viewEle) viewEle.checked = (viewOrder == "1" ? true : false)
                                if (viewAccount) viewAccount.checked = (viewAcc == "1" ? true : false)
                                if (placeAccount) placeAccount.checked = (placeOrder == "1" ? true : false)
                                if (modifyAccount) modifyAccount.checked = (modifyOrder == "1" ? true : false)
                            }
                        }
                    });


                    //
                }
            }
        });
    };

    function sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }
    const onDealerHandle = (selectedList, selectedItem) => {
        setToggleClearRows(!toggledClearRows);

        setSelectedDealer(selectedList);
        let tempTableDat = tableData;
        if(!isItemExist(tempTableDat,selectedItem)){
            tempTableDat.push(selectedItem);

        }
        setTableData(tempTableDat);

    }



    const onDealerRemove = (selectedList, selectedItem) => {
        if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur();

         setLoading(true);
        setSelectedDealer(selectedList);

        const tempData = appendObjTo(selectedList, selectedItem);


        popupManager.open(ModalConfirm, {
            title: 'Delete',
            content: 'Are you sure you want to delete?',
            onClose: (...params) => handleOnDealerRemove(params[0],tempData,selectedItem,selectedList),

        });
        setLoading(false);
    }

    const handleOnDealerRemove = (selBoolean,selectedList,selectedItem,defaultItems) => {

        if (!selBoolean){
            // setToggleClearRows(!toggledClearRows);
            setSelectedDealer(selectedList);
            // setTableData(selectedList);

            //Reinit permissions if dealer is removed


            return;
        }else{
            setLoading(true);
            setToggleClearRows(!toggledClearRows);
            setSelectedDealer(defaultItems);
            setTableData(defaultItems);

            sleep(100).then(() => {
                setLoading(false);

                let tempList = selectedList;
                for (let i = 0; i < tempList.length; i++) {
                    if (tempList[i]['rules']) {
                        let viewEle = document.getElementById(tempList[i]['d_code'] + "_view_order");
                        let viewAccount = document.getElementById(tempList[i]['d_code'] + "_view_account");
                        let placeAccount = document.getElementById(tempList[i]['d_code'] + "_place_order");
                        let modifyAccount = document.getElementById(tempList[i]['d_code'] + "_modify_order");
                        let rules = tempList[i]['rules'];
                        let binaryRules = rules.toString(2).padStart(4, '0')
                        let viewOrder = binaryRules[0];
                        let viewAcc = binaryRules[1];
                        let placeOrder = binaryRules[2];
                        let modifyOrder = binaryRules[3];



                        if (viewEle) viewEle.checked = (viewOrder == "1" ? true : false)
                        if (viewAccount) viewAccount.checked = (viewAcc == "1" ? true : false)
                        if (placeAccount) placeAccount.checked = (placeOrder == "1" ? true : false)
                        if (modifyAccount) modifyAccount.checked = (modifyOrder == "1" ? true : false)
                    }


                }
            });
        }
    }

    function appendObjTo(thatArray, newObj) {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }

    function isItemExist(arr, newItem) {
        for (let item of arr) {
            if (item.d_id === newItem.d_id) {
                return true;
            }
        }
        return false;
    }


    function removeItemByDId(arr, dIdToRemove) {
        const indexToRemove = arr.findIndex(item => item.d_id === dIdToRemove);

        if (indexToRemove !== -1) {
            arr.splice(indexToRemove, 1);
        }

        return arr; // Item with specified d_id not found
    }


    const handleDealerList = async (searchInput = null) => {
        setLoading(true);

        let searchTermStr = "";
        if (searchInput) {
            for (let i = 0; i < searchInput.length; i++) {
                searchTermStr += searchInput[i]['name'] + ","
            }
            searchTermStr = searchTermStr.substring(0, searchTermStr.length - 1)

        }

        api.getDealerList(100, 1, searchTermStr).then((result: any) => {

            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                setLoading(false);
                return false;
            }
            if (result.data) {
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    var TreeData = convertRawIntoDropdownDealer(dataList);
                    setDealerList(TreeData);
                }
            }
        });
        setLoading(false);
    };
    const convertRawIntoDropdownDealer = (data) => {
        var returnDat = [];
        for (let i = 0; i < data.length; i++) {

            returnDat.push({
                d_id: data[i]['d_id'],
                name: data[i]['d_code'] + " | "+data[i]['p_fullname'],
                group_id: data[i]['group_id'],
                d_code: data[i]['d_code'],
                p_fullname: data[i]['p_fullname'],
                bb_code: data[i]['bb_code'],
                d_type: data[i]['ut_name'],
                user_type: data[i]['ut_name'],
                rules: data[i]['rules'] ?? 0,
            });
        }
        return returnDat;
    }
    const handleBackBtn = async () => {
        await setLoading(true);
        navigate("/" + process.env.REACT_APP_URL_PREFIX + "dealer_gpas", {state: {mode: 'back'}});
        setLoading(false);
    };
    const handleRemoveBtn = async () => {
        let selectedItem = selectedRows['selectedRows'];
        if (selectedItem.length == 0) {
            handlePopup(true, "Error", "Please select at least one dealer to delete.", popupManager)
            return false;
        }
        await setLoading(true);
        popupManager.open(ModalConfirm, {
            title: 'Delete',
            content: 'Are you sure you want to delete?',
            onClose: (...params) => handleRemoveItem(params[0])
        });
        setLoading(false);
    };


    const handleRemoveItem = async (selBoolean) => {
        if (!selBoolean) return;
        await setLoading(true);

        let newArr = tableData;
        for (let i = 0; i < selectedRows['selectedRows'].length; i++) {
            newArr = newArr.filter(item => item.d_code !== selectedRows['selectedRows'][i]['d_code'])
        }

        setSelectedDealer(newArr);
        setTableData(newArr);
        // setToggleReset(!toggleReset)
        setSelectedRows(newArr);
        setLoading(false);

    };

    function handleCheckboxChange(event, id) {
        let ObjItm = id.split("_")
        let dealerId = ObjItm[0];
        let type = ObjItm[1] + "_" + ObjItm[2];
        if (type == "modify_order" || type == "place_order") {
            let elementId = dealerId + "_view_order";
            let viewObj = document.getElementById(elementId);
            if (event.target.checked) {
                if (viewObj) {
                    viewObj.checked = true;
                }
            }
        } else if (type == "view_order") {
            let modifyId = dealerId + "_modify_order";
            let placeId = dealerId + "_place_order";
            let modifyObj = document.getElementById(modifyId);
            let placeObj = document.getElementById(placeId);
            if (!event.target.checked) {
                if (modifyObj) {
                    modifyObj.checked = false;
                }
                if (placeObj) {
                    placeObj.checked = false;
                }
            }
        }

    }

    const handleSaveBtn = async () => {
        await setLoading(true);


        let fromDate = dayjs(currentEffForm).format(momentTimeFormat());
        let toDate = dayjs(currentEffTo).format(momentTimeFormat());

        if (fromDate != "Invalid Date" && toDate != "Invalid Date") {
            if (!isDateValid) {
                handlePopup(true, "Error", "Please ensure that the ending date is not earlier than the starting date.", popupManager);
                // setCurrentEffForm("");
                await setLoading(false);
                return false;
            }
        }

        if (fromDate == "Invalid Date" || toDate == "Invalid Date") {
                handlePopup(true, "Error", "Mandatory Field - From and to time is required.", popupManager)
                // setCurrentEffForm("");
                await setLoading(false);
                return false;

        }


        let decimalVals = 0;
        let dealerArr = [];

        if (selectedDealer) {
            for (let i = 0; i < selectedDealer.length; i++) {

                let temp = {};
                let viewEle = document.getElementById(selectedDealer[i]['d_code'] + "_view_order");
                let viewAccount = document.getElementById(selectedDealer[i]['d_code'] + "_view_account");
                let placeAccount = document.getElementById(selectedDealer[i]['d_code'] + "_place_order");
                let modifyAccount = document.getElementById(selectedDealer[i]['d_code'] + "_modify_order");


                let tempStr = "";
                modifyAccount.checked ? tempStr += "1" : tempStr += "0";
                placeAccount.checked ? tempStr += "1" : tempStr += "0";
                viewEle.checked ? tempStr += "1" : tempStr += "0";
                viewAccount.checked ? tempStr += "1" : tempStr += "0";

                decimalVals = parseInt(tempStr, 2);
                temp['dealer'] = selectedDealer[i]['d_id'];
                temp['ctrl'] = decimalVals;
                dealerArr.push(temp);
            }
        }


        if (currentGroupName.length < 3) {
            handlePopup(true, "Error", "Mandatory Field - Group name must be more than 3 characters.", popupManager)
            setLoading(false);
            return false;
        }

        let param = {
            "id": location.state.row.dg_id,
            "date_fr": dayjs(currentEffForm).format(momentTimeFormat()) == 'Invalid Date' ? "-" : dayjs(currentEffForm).format(momentTimeFormat()),
            "date_to": dayjs(currentEffTo).format(momentTimeFormat()) == 'Invalid Date' ? "-" : dayjs(currentEffTo).format(momentTimeFormat()),
            "rules": JSON.stringify(dealerArr)
        }


        api.updateDealerGroupAssc(param).then((result: any) => {
            setLoading(false);

            if (result.status == 1) {
                handlePopup(true, "Error", convertErMsg(result.message), popupManager)
                return false;
            }
            if (result != false && result.status == 0) {
                handlePopup(true, "Success", "Dealer group association updated successfully.", popupManager, true, "dealer_gpas", navigate)
            }

        });

        setLoading(false);
    };

    const columns = useMemo(
        () => [
            {
                id: row => row.d_code,
                name: "Dealer Code",
                selector: row => row.d_code,
                sortable: true,

            },
            {
                name: 'Dealer Name',
                selector: row => row.p_fullname,
                sortable: true
            },
            {
                name: 'Branch',
                selector: row => row.bb_code,
                sortable: true
            },
            {
                name: 'Type',
                selector: row => row.d_type ?? "-",
                sortable: true
            },
            {
                name: 'Modify Order',
                selector: row => row.v_morder,
                sortable: true,
                cell: (row: { v_morder: any }) => (
                    <>
                        {location.state.perm['edit'] ?
                            <input type="checkbox" id={row.d_code + "_modify_order"}
                                   onChange={(event => handleCheckboxChange(event, row.d_code + "_modify_order"))}
                                   defaultChecked={row.v_morder}/>
                            : <input type="checkbox"
                                     onChange={(event => handleCheckboxChange(event, row.d_code + "_modify_order"))}
                                     disabled defaultChecked={row.v_morder}/>
                        }

                    </>
                )
            },
            {
                name: 'Place Order',
                selector: row => row.v_porder,
                sortable: true,
                cell: (row: { v_porder: any }) => (
                    <>
                        {location.state.perm['edit'] ?
                            <input type="checkbox" id={row.d_code + "_place_order"}
                                   onChange={(event => handleCheckboxChange(event, row.d_code + "_place_order"))}
                                   defaultChecked={row.v_porder}/>
                            : <input type="checkbox"
                                     onChange={(event => handleCheckboxChange(event, row.d_code + "_place_order"))}
                                     disabled defaultChecked={row.v_porder}/>
                        }

                    </>
                )
            },{
                name: 'View Order',
                selector: row => row.v_order,
                sortable: true,
                cell: (row: { any }) => (

                    <>
                        {location.state.perm['edit'] ?
                            <input type="checkbox" id={row.d_code + "_view_order"}
                                   onChange={(event => handleCheckboxChange(event, row.d_code + "_view_order"))}
                                   defaultChecked={row.v_order}/>
                            : <input type="checkbox"
                                     onChange={(event => handleCheckboxChange(event, row.d_code + "_view_order"))}
                                     disabled defaultChecked={row.v_order}/>
                        }


                    </>
                )
            },{
                name: 'View Account',
                selector: row => row.v_account,
                sortable: true,
                cell: (row: { any }) => (
                    <>
                        {location.state.perm['edit'] ?
                            <input type="checkbox" id={row.d_code + "_view_account"}
                                   onChange={(event => handleCheckboxChange(event, row.d_code + "_view_account"))}
                                   defaultChecked={row.v_account}/>
                            : <input type="checkbox"
                                     onChange={(event => handleCheckboxChange(event, row.d_code + "_view_account"))}
                                     disabled defaultChecked={row.v_account}/>
                        }

                    </>
                )
            }


        ],
        []
    );
    return (
        <div className="pt-3 text-left " id="mainDiv">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}

                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; User
                                Maintenance &gt; Dealer Group Association  &gt; Edit</h5>

                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Group Name</div>
                                    <div className="label col-md-11">

                                        <input
                                            className="form-control "
                                            autoComplete='off'
                                            type="text"
                                            min={3}
                                            placeholder=""
                                            disabled={true}
                                            value={currentGroupName}
                                            onChange={(e) => setCurrentGroupName(e.target.value)}
                                            onBlur={(e) => specialCharCheck(e.target.value, setCurrentGroupName, popupManager)}

                                        />


                                    </div>
                                    <b className='mandatory-red'>*</b>


                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Effective From</div>
                                    <div className="label col-md-5">
                                        <ReactDatepick
                                            value={currentEffForm}
                                            dateSetter={setCurrentEffForm}
                                            resetToggle={toggleReset}
                                        />
                                    </div>
                                    <div className="col-md-1">To</div>
                                    <div className="col-md-5">
                                        <ReactDatepick
                                            value={currentEffTo}
                                            dateSetter={setCurrentEffTo}
                                            resetToggle={toggleReset}
                                        />
                                    </div>
                                    <b className='mandatory-red'>*</b>
                                </div>

                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label col-md-1">Dealer</div>
                                    <div className=" col-md-11">
                                        <Multiselect
                                            options={dealerList} // Options to display in the dropdown
                                            selectedValues={selectedDealer} // Preselected value to persist in dropdown
                                            onSelect={onDealerHandle} // Function will trigger on select event
                                            onRemove={onDealerRemove} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                            showCheckbox={true}
                                            closeIcon=''
                                            closeOnSelect={true}
                                            placeholder="Search by Dealer Code / Name"
                                            controlShouldRenderValue={false}
                                            avoidHighlightFirstOption={true}
                                            style={{
                                                chips: {
                                                    display: "none"
                                                }
                                            }}
                                        />

                                    </div>


                                </div>

                                {/*Table Portion*/}
                                <hr/>
                                <div id="div_Table" className="col-12 search-container">
                                    <ReactTable
                                        rowClick={setCurrentPage}
                                        perPageQty={setCurrentPerPage}
                                        handleSelect={setSelectedRows}
                                        columns={columns}
                                        data={tableData}
                                        clearRows={toggledClearRows}
                                        pagination={{
                                            currentPage: currentPage,
                                            currentPerPage: currentPerPage,
                                            totalRecords: totalRecords,
                                            selectable: selectable,
                                            selectableHandle: {setSelectedRows},
                                        }}
                                    />
                                    **Please note that no changes would being made to the existing association if "Save"
                                    button is not clicked.

                                </div>
                                <div className="input-container col-md-12 fullWidthSearch">


                                    <div className="label col-md-11 btn_search_padding">
                                        <button className="btn btn-primary " onClick={() => handleBackBtn()}>Back
                                        </button>
                                        <button className="btn btn-primary " onClick={() => handleRemoveBtn()}>Delete
                                            Selected Dealer from List
                                        </button>
                                        {accessItem['edit'] ? <button className="btn btn-primary "
                                                                      onClick={() => handleSaveBtn()}>Save</button> : ""}
                                    </div>


                                </div>


                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
