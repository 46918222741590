import {handleRequestGet, handleRequestPost} from '../axios';

export const getAmendmentList = async (param) => {
    let url = '/clntamend';

    let result = await handleRequestGet(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getAmendmentListStatus = async (param) => {
    let url = '/clntamend/liststatus';
    let result = await handleRequestGet(url, param);
    //
    // console.log(url)
    // console.log(param)
    // console.log(result)

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getAmendmentDetail = async (param) => {
    let url = '/clntamend/detail';


    let result = await handleRequestGet(url, param);


    // console.log(url)
    // console.log(param)
    // console.log(result)

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const createNewEntry = async (param) => {
    let url = '/clntamend/create';
    let result = await handleRequestPost(url,param)

    //
    // console.log(url)
    // console.log(param)
    // console.log(result)

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const clmtEntryApproval = async (param) => {
    let url = '/clntamend/approval';
    let result = await handleRequestPost(url,param)
    //
    //
    // console.log(url)
    // console.log(param)
    // console.log(result)
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
