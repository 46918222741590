import Crypto from 'crypto';
import { generateEcdhKeys } from './ecdhCrypto';

//import Config from '../config';
// const masterkey = Config.Crypto.ECDH.masterkey;
const digest = 'sha512';

/**
 * Encrypts text by given key
 * @param String text to encrypt
 * @param Buffer masterkey
 * @returns String encrypted text, base64 encoded
 */

export const getSessKey = (masterkey: string) => {
    const key = Crypto.pbkdf2Sync(
        masterkey,
        Buffer.from(masterkey, 'utf-8'),
        2145,
        32,
        digest
    );
    return key.toString('base64');
};

export const gcmEncrypt = (text: string, masterkey: string) => {
    // let ECDHKey = generateEcdhKeys();
    // random initialization vector
    const iv = Crypto.randomBytes(16);

    // random salt
    const salt = Crypto.randomBytes(64);

    // derive encryption key: 32 byte key length
    // in assumption the masterkey is a cryptographic and NOT a password there is no need for
    // a large number of iterations. It may can replaced by HKDF
    // the value of 2145 is randomly chosen!
    const key = Crypto.pbkdf2Sync(masterkey, salt, 2145, 32, digest);

    // AES 256 GCM Mode
    const cipher = Crypto.createCipheriv('aes-256-gcm', key, iv);

    // encrypt the given text
    const encrypted = Buffer.concat([
        cipher.update(text, 'utf8'),
        cipher.final()
    ]);

    // extract the auth tag
    const tag = cipher.getAuthTag();

    // generate output
    return Buffer.concat([salt, iv, tag, encrypted]).toString('base64');
};

/**
 * Decrypts text by given key
 * @param String base64 encoded input data
 * @param Buffer masterkey
 * @returns String decrypted (original) text
 */
export const gcmDecrypt = (encdata: string, masterkey: string) => {
    // base64 decoding
    const bData = Buffer.from(encdata, 'base64');

    // convert data to buffers
    const salt = bData.slice(0, 64);
    const iv = bData.slice(64, 80);
    const tag = bData.slice(80, 96);
    const text = bData.slice(96);

    // if (mainKey == null) mainKey = 'ECDHKey';

    // derive key using; 32 byte key length
    const key = Crypto.pbkdf2Sync(masterkey, salt, 2145, 32, digest);

    // AES 256 GCM Mode
    const decipher = Crypto.createDecipheriv('aes-256-gcm', key, iv);
    decipher.setAuthTag(tag);

    // encrypt the given text
    const decrypted =
        decipher.update(text, undefined, 'utf8') + decipher.final('utf8');

    return decrypted;
};

/**
 * Encrypt bearer token
 */
export const generateBearerTokenKey = (token: string) => {
    // random salt
    const salt = Buffer.from(token, 'utf-8');
    const key = Crypto.pbkdf2Sync(token, salt, 2145, 32, digest);

    // generate output
    return key.toString('base64');
};
