import React from "react";
import {getNavItemById} from "../../../general/general-tools";

//Triggering Functions
//Market
export function handleToggleCheckAllCurentClass(currentClass) {
    let segment = currentClass.split('_')[0];
    let inputElementsHeader = document.getElementsByClassName(currentClass + '_header');
    let inputElementsHeaderTop = document.getElementsByClassName("." + segment + '_all_header');
    const inputElements = Array.from(document.getElementsByClassName(segment + '_all'));
    let countItems = {};

    if (currentClass.includes('_all')) {
        const elementLists = document.querySelectorAll("." + segment + '_view,.' + segment + '_add,.' + segment + '_edit,.' + segment + '_delete,.' + segment + '_view_header,.' + segment + '_add_header,.' + segment + '_edit_header,.' + segment + '_delete_header');
        let isChecked = inputElementsHeader[0].checked;
        if (inputElements) for (var i = 0; i < inputElements.length; i++) {
            inputElements[i].checked = isChecked;
        }
        if (inputElementsHeaderTop) for (var i = 0; i < inputElementsHeaderTop.length; i++) {
            inputElementsHeaderTop[i].checked = isChecked;
        }
        if (elementLists) for (var i = 0; i < elementLists.length; i++) {
            //Check in All Case
            elementLists[i].checked = isChecked;
        }
    } else {
        const elementLists = document.querySelectorAll("." + segment + '_all_header,.' + segment + '_all');
        const inputHeader = Array.from(document.getElementsByClassName(currentClass + '_header'));
        const inputElementRawChild = Array.from(document.getElementsByClassName(currentClass));
        const elementListsAll2 = document.querySelectorAll("." + segment + '_view,.' + segment + '_add,.' + segment + '_edit,.' + segment + '_delete,.' + segment + '_view_header,.' + segment + '_add_header,.' + segment + '_edit_header,.' + segment + '_delete_header');

        if (inputHeader) {
            let isAllRightChecked = true;
            const elementListsFirstLvl = document.querySelectorAll("." + segment + '_view_header,.' + segment + '_add_header,.' + segment + '_edit_header,.' + segment + '_delete_header');

            if (elementListsFirstLvl) for (var i = 0; i < elementListsFirstLvl.length; i++) {
                let tmpEle = elementListsFirstLvl[i].id.split('_')[1];
                let LvlOne = segment + "_" + tmpEle + "_header";
                const lvlOneArr = Array.from(document.getElementsByClassName(LvlOne));
                if (lvlOneArr) for (var j = 0; j < lvlOneArr.length; j++) {
                    if (!lvlOneArr[j].checked) {
                        isAllRightChecked = false;
                        break;
                    }

                }
            }

            let isChecked = inputHeader[0].checked;
            if (elementLists) for (var i = 0; i < elementLists.length; i++) {
                elementLists[i].checked = isAllRightChecked;
            }
            if (inputElementRawChild) for (var i = 0; i < inputElementRawChild.length; i++) {
                inputElementRawChild[i].checked = isChecked;
            }

            if (elementListsAll2) for (var i = 0; i < elementListsAll2.length; i++) {
                let currentChildBox = document.getElementById(elementListsAll2[i].id);
                let funcIds = elementListsAll2[i].id.split('_')[0];
                let funcChildIds = elementListsAll2[i].id.split('_')[1];
                let funcChildAction = elementListsAll2[i].id.split('_')[2];

                let currentItemDetail = getNavItemById(funcIds);
                let perms = currentItemDetail.parent_perm ?? "00000";
                let countOnes = (perms.match(/1/g) || []).length <= 1 ? (perms.match(/1/g) || []).length : (perms.match(/1/g) || []).length - 1;

                if (countItems[funcIds]) {
                    countItems[funcIds] = {
                        "total": countOnes,
                        "checked": currentChildBox.checked ? countItems[funcIds].checked += 1 : countItems[funcIds].checked,
                        "funcChildIds": funcChildIds,
                    };
                } else {
                    countItems[funcIds] = {
                        "total": countOnes,
                        "checked": currentChildBox.checked ? 1 : 0,
                        "funcChildIds": funcChildIds,
                    };
                }

            }
            for (let key in countItems) {
                if (countItems.hasOwnProperty(key)) {
                    let entry = countItems[key];
                    // Check if total is equal to checked
                    if (entry.total === entry.checked) {
                        // Construct the checkbox ID pattern
                        let checkboxId = key + "_" + entry.funcChildIds + "_all";
                        let currentChildBox = document.getElementById(checkboxId);
                        if (currentChildBox) currentChildBox.checked = true;
                        // Check the checkbox

                    }
                }
            }
        }
    }
};

export function handleToggleChildControl(currentClass) {

    let parentSegment = currentClass.split('_')[0];
    let headerSegments = currentClass.split('_')[0] + "_" + currentClass.split('_')[1];
    let segment = currentClass.split('_')[2] ?? currentClass.split('_')[0];
    let subSegment = currentClass.split('_')[4] ?? null;
    let countItems = {};
    const elementListsAll2 = document.querySelectorAll("." + segment + "_view," + "." + segment + "_add," + "." + segment + "_edit," + "." + segment + "_delete,." + segment + "_all,." + segment + "_header");

    if (currentClass.includes('_all')) {

        const inputElementsHeaderTop = document.querySelectorAll("." + parentSegment + '_all_header');
        removeAllCheck(segment);
        if (currentClass.split('_').length > 2) removeheaderCheck(parentSegment);
        if (!subSegment) {

            const elementLists = document.querySelectorAll("." + subSegment + '_all,.' + segment + '_all');
            const elementListsAll = document.querySelectorAll("." + segment + "_view_subheader," + "." + segment + "_add_subheader," + "." + segment + "_edit_subheader," + "." + segment + "_delete_subheader,." + segment + "_all_subheader,." + segment + "_header");
            // const elementListsAll2 = document.querySelectorAll("." + segment + "_view," + "." + segment + "_add," + "." + segment + "_edit," + "." + segment + "_delete,." + segment + "_all,." + segment + "_header");


            let inputElementsHeader = document.getElementsByClassName(segment + '_all');
            let isChecked = inputElementsHeader[0].checked;
            if (elementListsAll) for (var i = 0; i < elementListsAll.length; i++) {
                elementListsAll[i].checked = isChecked;
            }
            if (elementListsAll2) for (var i = 0; i < elementListsAll2.length; i++) {
                elementListsAll2[i].checked = isChecked;
            }
            if (elementLists) for (var i = 0; i < elementLists.length; i++) {
                elementLists[i].checked = isChecked;
            }
            if (inputElementsHeaderTop) for (var i = 0; i < inputElementsHeaderTop.length; i++) {
                inputElementsHeaderTop[i].checked = false;
            }


        } else {
            const elementLists = document.querySelectorAll("." + subSegment + "_view_subheader," + "." + subSegment + "_add_subheader," + "." + subSegment + "_edit_subheader," + "." + subSegment + "_delete_subheader,." + headerSegments + "_all_subheader,." + headerSegments + "_header");
            const elementListsAll = document.querySelectorAll("." + segment + "_view," + "." + segment + "_add," + "." + segment + "_edit," + "." + segment + "_delete,." + segment + "_all,." + segment + "_header");
            if (elementLists) for (var i = 0; i < elementLists.length; i++) {
                elementLists[i].checked = false;
            }
            let currentClick = document.getElementsByClassName(currentClass.split('_')[2] + '_' + currentClass.split('_')[3]);
            let inputElementsHeader = document.getElementsByClassName(currentClass.split('_')[4] + '_' + currentClass.split('_')[5]);
            let currentChecked = currentClick[0].checked;
            if (elementListsAll) for (var i = 0; i < elementListsAll.length; i++) {
                elementListsAll[i].checked = currentChecked;
            }
            if (inputElementsHeader[0].checked) inputElementsHeader[0].checked = currentChecked;


        }
        let allHeaderChecked = true;

        const elementListsAll = document.querySelectorAll("." + parentSegment + "_all");
        if (elementListsAll) for (var i = 0; i < elementListsAll.length; i++) {
            if (!elementListsAll[i].checked) {
                allHeaderChecked = false
                break;
            }
        }
        const elementListsAllHeader = document.querySelectorAll("." + parentSegment + "_all_header");
        if (elementListsAllHeader) for (var i = 0; i < elementListsAllHeader.length; i++) {
            elementListsAllHeader[i].checked = allHeaderChecked;
        }

        let isLevel1Checked = true;
        let isLevel2Checked = true;
        const level2All = document.querySelectorAll("." + subSegment + '_all');
        const level2AllHeader = document.querySelectorAll("." + subSegment + "_view_subheader," + "." + subSegment + "_add_subheader," + "." + subSegment + "_edit_subheader," + "." + subSegment + "_delete_subheader,." + subSegment + "_all_subheader");

        let countUck = 0;
        if (level2All) for (var i = 0; i < level2All.length; i++) {
            if (!level2All[i].checked) countUck += 1;
        }
        if (countUck > 1) {
            isLevel2Checked = false;
        }

        if (level2AllHeader) for (var i = 0; i < level2AllHeader.length; i++) {
            level2AllHeader[i].checked = isLevel2Checked;
        }

        const level1Parent = document.querySelectorAll("." + parentSegment + "_view_header," + "." + parentSegment + "_add_header," + "." + parentSegment + "_edit_header," + "." + parentSegment + "_delete_header,." + parentSegment + "_all_header");
        let isLevel2CheckedLvl1 = true;
        if (level2AllHeader) for (var i = 0; i < level2AllHeader.length; i++) {
            if (!level2AllHeader[i].checked) {
                isLevel2CheckedLvl1 = false;
                break;
            }
        }

        if (!subSegment) {
            const level2AllHeaderNoSeg = document.querySelectorAll("." + segment + "_view_subheader," + "." + segment + "_add_subheader," + "." + segment + "_edit_subheader," + "." + segment + "_delete_subheader,." + segment + "_all_subheader");
            if (level2AllHeaderNoSeg) for (var i = 0; i < level2AllHeaderNoSeg.length; i++) {
                if (!level2AllHeaderNoSeg[i].checked) {
                    isLevel2CheckedLvl1 = false;
                    break;
                }
            }
        }
        if (level1Parent) for (var i = 0; i < level1Parent.length; i++) {
            level1Parent[i].checked = isLevel2CheckedLvl1;
        }


    } else {
        const elementLists = document.querySelectorAll("." + parentSegment + '_all_header,.' + segment + '_all_header,.' + segment + '_all,.' + subSegment + '_all_subheader,.' + headerSegments + "_header");
        const elementListsSubsOnly = document.querySelectorAll("." + segment + '_view_subheader,' + "." + segment + '_add_subheader,' + "." + segment + '_edit_subheader,' + "." + segment + '_delete_subheader');

        let isAllRightChecked = true;

        if (elementListsSubsOnly) for (var i = 0; i < elementListsSubsOnly.length; i++) {
            if (!elementListsSubsOnly[i].checked) {
                isAllRightChecked = false;
                break;
            }
        }

        if (elementLists) for (var i = 0; i < elementLists.length; i++) {
            elementLists[i].checked = false;
        }

        if (!subSegment) {
            //Level 2 Fulfill, check all level 3
            const lvl2HeaderAll = document.querySelectorAll("." + segment + '_all_subheader');
            let isLvl2AllChecked = lvl2HeaderAll[0].checked;
            const elementListsAll = document.querySelectorAll("." + segment + "_all");


            if (elementListsAll) for (var i = 0; i < elementListsAll.length; i++) {
                elementListsAll[i].checked = isAllRightChecked;
            }

            const elementLists = document.querySelectorAll('.' + segment + '_' + currentClass.split('_')[3]);
            let inputElementsHeader = document.getElementsByClassName(segment + '_' + currentClass.split('_')[3]);
            let isChecked = inputElementsHeader[0].checked;
            if (elementLists) for (var i = 0; i < elementLists.length; i++) {
                elementLists[i].checked = isChecked;
            }


        } else {

            //Level 3 Fulfill Same level All
            let Lvl3SameLayerChecked = true;
            const Lvl3SameLayer = document.querySelectorAll("." + segment + '_view,' + "." + segment + '_add,' + "." + segment + '_edit,' + "." + segment + '_delete');
            if (Lvl3SameLayer) for (var i = 0; i < Lvl3SameLayer.length; i++) {
                if (!Lvl3SameLayer[i].checked) Lvl3SameLayerChecked = false;
            }
            const Lvl3SameLayerAll = document.querySelectorAll("." + currentClass.split('_')[2] + '_all');
            if (Lvl3SameLayerAll) for (var i = 0; i < Lvl3SameLayerAll.length; i++) {
                Lvl3SameLayerAll[i].checked = Lvl3SameLayerChecked
            }

            //Level 3 fulfill Level 2 subHeaders
            let Lvl3UpperLayerChecked = true;
            let action = currentClass.split('_')[3];
            let checkCounter = 0;
            const Lvl3UpperLayer = document.querySelectorAll("." + subSegment + '_' + action);
            const Lvl2LayerLvl3 = document.querySelectorAll("." + subSegment + '_' + action + "_subheader");
            if (Lvl3UpperLayer) for (var i = 0; i < Lvl3UpperLayer.length; i++) {
                if (!Lvl3UpperLayer[i].checked) checkCounter += 1;
            }
            if (checkCounter == 1) {
                Lvl3UpperLayerChecked = false;
            }
            if (Lvl2LayerLvl3) for (var i = 0; i < Lvl2LayerLvl3.length; i++) {
                Lvl2LayerLvl3[i].checked = !Lvl3UpperLayerChecked
            }
            let currentClick = document.getElementsByClassName(currentClass.split('_')[2] + '_' + currentClass.split('_')[3]);
            let inputElementsHeader = document.getElementsByClassName(currentClass.split('_')[4] + '_' + currentClass.split('_')[5]);
            let currentChecked = currentClick[0].checked;
            if (inputElementsHeader[0].checked) inputElementsHeader[0].checked = currentChecked;


            //Level 3 Fulfill then check level 2 all
            let lvl2SubHeadersChecked = true;
            const lvl3ForUpperLayer = document.querySelectorAll("." + currentClass.split('_')[4] + '_view_subheader,' + "." + currentClass.split('_')[4] + '_add_subheader,' + "." + currentClass.split('_')[4] + '_edit_subheader,' + "." + currentClass.split('_')[4] + '_delete_subheader');
            const Lvl2SameLayerAll = document.querySelectorAll("." + currentClass.split('_')[4] + '_all_subheader');
            if (lvl3ForUpperLayer) for (var i = 0; i < lvl3ForUpperLayer.length; i++) {
                if (!lvl3ForUpperLayer[i].checked) {
                    lvl2SubHeadersChecked = false;
                    break;
                }
            }

            if (Lvl2SameLayerAll) for (var i = 0; i < Lvl2SameLayerAll.length; i++) {
                Lvl2SameLayerAll[i].checked = lvl2SubHeadersChecked;

            }

        }


        let viewHeaderChecked = true;
        let addHeaderChecked = true;
        let editHeaderChecked = true;
        let deleteHeaderChecked = true;


        const elementListsAllView = document.querySelectorAll("." + parentSegment + "_view");
        if (elementListsAllView) for (var i = 0; i < elementListsAllView.length; i++) {
            if (!elementListsAllView[i].checked) {
                viewHeaderChecked = false
                break;
            }
        }
        const elementListsAllViewHeader = document.querySelectorAll("." + parentSegment + "_view_header");
        if (elementListsAllViewHeader) for (var i = 0; i < elementListsAllViewHeader.length; i++) {
            elementListsAllViewHeader[i].checked = viewHeaderChecked;
        }

        const elementListsAllAdd = document.querySelectorAll("." + parentSegment + "_add");
        if (elementListsAllAdd) for (var i = 0; i < elementListsAllAdd.length; i++) {
            if (!elementListsAllAdd[i].checked) {
                addHeaderChecked = false
                break;
            }
        }
        const elementListsAllAddHeader = document.querySelectorAll("." + parentSegment + "_add_header");
        if (elementListsAllAddHeader) for (var i = 0; i < elementListsAllAddHeader.length; i++) {
            elementListsAllAddHeader[i].checked = addHeaderChecked;
        }
        //
        const elementListsAllEdit = document.querySelectorAll("." + parentSegment + "_edit");
        if (elementListsAllEdit) for (var i = 0; i < elementListsAllEdit.length; i++) {
            if (!elementListsAllEdit[i].checked) {
                editHeaderChecked = false
                break;
            }
        }
        const elementListsAllEditHeader = document.querySelectorAll("." + parentSegment + "_edit_header");
        if (elementListsAllEditHeader) for (var i = 0; i < elementListsAllEditHeader.length; i++) {
            elementListsAllEditHeader[i].checked = editHeaderChecked;
        }

        const elementListsAllDelete = document.querySelectorAll("." + parentSegment + "_delete");
        if (elementListsAllDelete) for (var i = 0; i < elementListsAllDelete.length; i++) {
            if (!elementListsAllDelete[i].checked) {
                deleteHeaderChecked = false
                break;
            }
        }
        const elementListsAllDeleteHeader = document.querySelectorAll("." + parentSegment + "_delete_header");
        if (elementListsAllDeleteHeader) for (var i = 0; i < elementListsAllDeleteHeader.length; i++) {
            elementListsAllDeleteHeader[i].checked = deleteHeaderChecked;
        }


        let bigHeaderCheck = true;
        const bigHeaderList = document.querySelectorAll("." + parentSegment + '_view_header,.' + parentSegment + '_add_header,.' + parentSegment + '_edit_header,.' + parentSegment + '_delete_header');
        if (bigHeaderList) for (var i = 0; i < bigHeaderList.length; i++) {
            if (!bigHeaderList[i].checked) {
                bigHeaderCheck = false;
                break;
            }
        }

        const elementListsAllHeaders = document.querySelectorAll("." + parentSegment + "_all_header");
        if (elementListsAllHeaders) for (var i = 0; i < elementListsAllHeaders.length; i++) {
            elementListsAllHeaders[i].checked = bigHeaderCheck;
        }

        if (elementListsAll2) for (var i = 0; i < elementListsAll2.length; i++) {
            let currentChildBox = document.getElementById(elementListsAll2[i].id);
            let funcIds = elementListsAll2[i].id.split('_')[0];
            let funcChildIds = elementListsAll2[i].id.split('_')[1];
            let funcChildAction = elementListsAll2[i].id.split('_')[2];

            let currentItemDetail = getNavItemById(funcIds);
            let perms = currentItemDetail.parent_perm ?? "00000";
            let countOnes = (perms.match(/1/g) || []).length <= 1 ? (perms.match(/1/g) || []).length : (perms.match(/1/g) || []).length - 1;

            if (countItems[funcIds]) {
                countItems[funcIds] = {
                    "total": countOnes,
                    "checked": currentChildBox.checked ? countItems[funcIds].checked += 1 : countItems[funcIds].checked,
                    "funcChildIds": funcChildIds,
                };
            } else {
                countItems[funcIds] = {
                    "total": countOnes,
                    "checked": currentChildBox.checked ? 1 : 0,
                    "funcChildIds": funcChildIds,
                };
            }

        }
        for (let key in countItems) {
            if (countItems.hasOwnProperty(key)) {
                let entry = countItems[key];
                if (entry.total === entry.checked) {
                    // Construct the checkbox ID pattern
                    let checkboxId = key + "_" + entry.funcChildIds + "_all";
                    let currentChildBox = document.getElementById(checkboxId);
                    if (currentChildBox) currentChildBox.checked = true;

                }
            }
        }
    }


};

function removeAllCheck(segment) {
    const elementLists = document.querySelectorAll("." + segment + '_view, .' + segment + '_add, .' + segment + '_edit, .' + segment + '_delete');
    for (var i = 0; i < elementLists.length; i++) {
        elementLists[i].checked = false;
    }
    removeheaderCheck(segment);
}

function removeheaderCheck(segment) {
    const elementLists = document.querySelectorAll("." + segment + '_view_header,.' + segment + '_add_header,.' + segment + '_edit_header,.' + segment + '_delete_header');
    if (elementLists) for (var i = 0; i < elementLists.length; i++) {
        elementLists[i].checked = false;
    }
}


//Order Type
export function handleToggleAllExchange(currentClass) {
    let allClass = currentClass.split("_")[0] + "_" + currentClass.split("_")[1];
    let inputElementsHeader = document.getElementsByClassName(currentClass);
    if (currentClass.includes('_all')) {
        let isChecked = inputElementsHeader[0].checked;
        const elementLists = document.querySelectorAll("." + allClass);
        for (var i = 0; i < elementLists.length; i++) {
            elementLists[i].checked = isChecked;
        }
    } else {
        let inputElementsHeader = document.getElementsByClassName(allClass + '_all');
        for (var i = 0; i < inputElementsHeader.length; i++) {
            inputElementsHeader[i].checked = false;
        }
        let tempClass = currentClass.split("_")[0] + "_" + currentClass.split("_")[1];
        // console.log(tempClass)
        // console.log(tempClass.length)
        let headerCount = document.getElementsByClassName(tempClass);
        let checkedCount = 0;
        for (var i = 0; i < headerCount.length; i++) {
            if(headerCount[i].checked) checkedCount += 1;
        }
        if(headerCount.length == checkedCount ) {
            const hedaerEle = document.getElementById(tempClass);
            if(hedaerEle) hedaerEle.checked = true;
        }

    }
}

export function handleToggleToolControl(currentClass) {
    if (!currentClass.includes('_all')) {
        let inputElementsHeader = document.getElementsByClassName('tool_all_header');
        for (var i = 0; i < inputElementsHeader.length; i++) {
            inputElementsHeader[i].checked = false;
        }
    }
}

//Get Functional Menus

export function getFunctionMenus() {
    let funcArray = [

        {
            id: 1,
            url: '/',
            label: 'Dashboard',
            parent_perm: "10000",
            icon: 'fa fa-home'
        },
        {
            id: 2,
            label: 'Administration',
            icon: 'fa fa-gears',
            parent_perm: "11111",
            subMenu: [
                {
                    id: 3,
                    url: '#',
                    label: 'User Maintenance',
                    perm: "11111",
                    subMenu2: [
                        {
                            id: 4,
                            url: '/user_profile',
                            label: 'Profile',
                            perm: "11110",
                        },
                        {
                            id: 64,
                            url: '#',
                            label: 'Access Matrix (User Level)',
                            perm: "01000",
                            specialPerm: true,
                            parentFuncId: 4
                        },
                        {
                            id: 65,
                            url: '#',
                            label: 'Authorization Limit (User Level)',
                            perm: "01000",
                            specialPerm: true,
                            parentFuncId: 4
                        },
                        {
                            id: 5,
                            url: '/user_access',
                            label: 'User Access',
                            perm: "11010"
                        },
                        {
                            id: 6,
                            url: '/user_mat',
                            label: 'Access Matrix',
                            perm: "11010"
                        },
                        {
                            id: 7,
                            url: '/auth_limit',
                            label: 'Authorization Limit',
                            perm: "11010"
                        },
                        {
                            id: 8,
                            url: '/dealer_gpas',
                            label: 'Dealer Group Association',
                            perm: "11011"
                        }
                    ]
                }, {
                    id: 9,
                    url: 'parameter',
                    label: 'Parameters Maintenance',
                    perm: "11010"
                }, {
                    id: 10,
                    url: '#',
                    label: 'Broadcast Maintenance',
                    perm: "11111",
                    subMenu2: [
                        {
                            id: 11,
                            url: '/br_entry',
                            label: 'Entry',
                            perm: "11111"
                        }, {
                            id: 12,
                            url: '/br_app',
                            label: 'Approval',
                            perm: "11010"
                        }
                    ]
                }, {
                    id: 13,
                    url: '#',
                    label: 'Client Amendment',
                    perm: "11110",
                    subMenu2: [
                        {
                            id: 58,
                            url: '/cl_amend_entry',
                            label: 'Entry',
                            perm: "11010"
                        }, {
                            id: 59,
                            url: '/cl_amend_approval',
                            label: 'Approval',
                            perm: "11010"
                        }, {
                            id: 60,
                            url: '/cl_amend_status',
                            label: 'Status',
                            perm: "11000"
                        }
                    ]
                }, {
                    id: 14,
                    url: '#',
                    label: 'Share Adjustment',
                    perm: "11111"
                }, {
                    id: 15,
                    url: '#',
                    label: 'Task Maintenance',
                    perm: "11110",
                    subMenu2: [
                        {
                            id: 61,
                            url: '/task_schedular',
                            label: 'Scheduler',
                            perm: "11110"
                        }, {
                            id: 62,
                            url: '/task_monitor',
                            label: 'Monitor',
                            perm: "11000"
                        },
                        {
                            id: 63,
                            url: '#',
                            label: 'History',
                            perm: "11000"
                        }
                    ]
                }

            ]
        },
        {
            id: 16,
            label: 'Risk Mgmt',
            icon: 'fa fa-thermometer-3',
            parent_perm: "11010",
            subMenu: [
                {
                    id: 17,
                    url: '/exp_company',
                    label: 'Company',
                    perm: "11010"

                }, {
                    id: 18,
                    url: '/exp_exc',
                    label: 'Exchange',
                    perm: "11010"
                }, {
                    id: 19,
                    url: '/exp_branch',
                    label: 'Branch',
                    perm: "11010"
                }, {
                    id: 20,
                    url: '/exp_dealer',
                    label: 'Dealer',
                    perm: "11010"
                }, {
                    id: 21,
                    url: '/exp_acc',
                    label: 'Account',
                    perm: "11010"
                }, {
                    id: 22,
                    url: '/exp_stock',
                    label: 'Stock - Company',
                    perm: "11010"
                }, {
                    id: 23,
                    url: '/exp_stockbr',
                    label: 'Stock - Branch',
                    perm: "11010"
                }
            ]
        },
        {
            id: 24,
            label: 'Master File',
            icon: 'fa fa-gear',
            parent_perm: "11111",
            subMenu: [
                {
                    id: 25,
                    url: '/currency',
                    label: 'Currency',
                    perm: "11111"
                }, {
                    id: 26,
                    url: '/country',
                    label: 'Country',
                    perm: "11111"
                }, {
                    id: 27,
                    url: '/exchange',
                    label: 'Exchange',
                    perm: "11111"
                }, {
                    id: 28,
                    url: '/company',
                    label: 'Company',
                    perm: "11111"
                }, {
                    id: 29,
                    url: '/branch',
                    label: 'Branch',
                    perm: "11111"
                }, {
                    id: 30,
                    url: '/holiday',
                    label: 'Holiday',
                    perm: "11111"
                }, {
                    id: 31,
                    url: '/user_type',
                    label: 'User Type',
                    perm: "11111"
                }, {
                    id: 32,
                    url: '/dealergp',
                    label: 'Dealer Group',
                    perm: "11111"
                }, {
                    id: 33,
                    url: '/clientdealer',
                    label: 'Client-Dealer',
                    perm: "11011"
                }, {
                    id: 34,
                    url: '/broker',
                    label: 'Broker',
                    perm: "11111"
                }, {
                    id: 35,
                    url: '#',
                    label: 'Brokerage',
                    perm: "11111"
                }, {
                    id: 36,
                    url: '#',
                    label: 'Server',
                    perm: "11111"
                }, {
                    id: 37,
                    url: '#',
                    label: 'Stock',
                    perm: "11111"
                }

            ]
        },
        {
            id: 38,
            label: 'Enquiries',
            icon: 'fa fa-tasks',
            url: '/enquiry',
            parent_perm: "10000",
            subMenu: [
                {
                    id: 39,
                    url: '#',
                    label: 'User Type Access Matrix Enquiry'
                }, {
                    id: 40,
                    url: '#',
                    label: 'Client Amendment Enquiry'
                }, {
                    id: 41,
                    url: '#',
                    label: 'User Type Enquiry'
                }, {
                    id: 42,
                    url: '#',
                    label: 'Inactive Client Enquiry'
                }, {
                    id: 43,
                    url: '#',
                    label: 'Inactive Dealer-Remisier Enquiry'
                }, {
                    id: 44,
                    url: '#',
                    label: 'Inactive User Enquiry'
                }, {
                    id: 45,
                    url: '#',
                    label: 'Con-current User (Client) Enquiry'
                }, {
                    id: 46,
                    url: '#',
                    label: 'Client Login Enquiry'
                }, {
                    id: 47,
                    url: '#',
                    label: 'Dealer-Remisier Enquiry'
                }, {
                    id: 48,
                    url: '#',
                    label: 'User Login Enquiry'
                }, {
                    id: 49,
                    url: '#',
                    label: 'Client Limit Enquiry'
                }, {
                    id: 50,
                    url: '#',
                    label: 'Dealer-Group Association Enquiry'
                }, {
                    id: 51,
                    url: '#',
                    label: 'Client Portfolio Enquiry'
                }, {
                    id: 52,
                    url: '#',
                    label: 'Trade Enquiry'
                }, {
                    id: 53,
                    url: '#',
                    label: 'T&C Acceptance Status Enquiry'
                }, {
                    id: 54,
                    url: '#',
                    label: 'Foreign Exchange T&C Acceptance Status Enquiry'
                }
            ]

        },
        {
            id: 55,
            label: 'Audit Trials',
            icon: 'fa fa-laptop',
            parent_perm: "10000",
            subMenu: [
                {
                    id: 56,
                    url: '/audit_trail',
                    label: 'View',
                    perm: "11000"
                }, {
                    id: 57,
                    url: '#',
                    label: 'Purge',
                    perm: "11000"
                }
            ]

        },

    ];
    return funcArray;
}

export function getExchangeQty() {
    return 16;
}

export function getExchangeMenu() {
    return [
        {
            "em_id": 0,
            "e_code": "BM",
            "em_code": "BM",
            "em_name": "Bursa Malaysia"
        }, {
            "em_id": 1,
            "e_code": "SG",
            "em_code": "SGX",
            "em_name": "Singapore Stock Exchange"
        }, {
            "em_id": 2,
            "e_code": "HK",
            "em_code": "HKEX",
            "em_name": "Stock Exchange Of Hong Kong"
        }, {
            "em_id": 3,
            "e_code": "O",
            "em_code": "NASDAQ",
            "em_name": "NASDAQ"
        },
        // {
        //     "em_id": 4, //Ignore in admin
        //"e_code": "NQ",
        //     "em_code": "NYSE",
        //     "em_name": "New York Stock Exchange"
        // },
        {
            "em_id": 5,
            "e_code": "A",
            "em_code": "AMEX",
            "em_name": "NYSE American Stock Exchange"
        }, {
            "em_id": 6,
            "e_code": "P",
            "em_code": "ARCA",
            "em_name": "NYSE ARCA Stock Exchange"
        },
        // {
        //     "em_id": 7, //Ignore in admin
        //"e_code": "OQ",
        //     "em_code": "NASDQ",
        //     "em_name": "NASDAQ"
        // },
        {
            "em_id": 8,
            "e_code": "N",
            "em_code": "NYSE",
            "em_name": "New York Stock Exchange"
        }, {
            "em_id": 9,
            "e_code": "Ca",
            "em_code": "SZSE",
            "em_name": "Shenzhen Stock Exchange"
        }, {
            "em_id": 10,
            "e_code": "Cc",
            "em_code": "SSE",
            "em_name": "Shanghai Stock Exchange"
        }
        // , {
        //     "em_id": 11,
        //     "e_code": null,
        //     "em_code": "TSE",
        //     "em_name": "Tokyo Stock Exchange"
        // }
        , {
            "em_id": 12,
            "e_code": 'L',
            "disabled": '100',
            "em_code": "LSE",
            "em_name": "London Stock Exchange"
        }
        ,{
            "em_id": 13,
            "e_code": 'AX',
            "disabled": '100',
            "em_code": "ASX",
            "em_name": "Australia Securities Exchange"
        }
        // , {
        //     "em_id": 14,
        //     "e_code": null,
        //     "em_code": "TSXV",
        //     "em_name": "Toronto Venture  Exchange"
        // }, {
        //     "em_id": 15,
        //     "e_code": null,
        //     "em_code": "TSX",
        //     "em_name": "Toronto Stock Exchange"
        // }
    ]
}

export function getEmIdByECode(e_code) {
    let stockExchanges = getExchangeMenu();
    for (let exchange of stockExchanges) {
        if (exchange.e_code === e_code) {
            return exchange.em_id;
        }
    }
    return null; // Return null if the e_code is not found
}

export function getToolsMenu() {
    return [{
        "id": 0,
        "label": "Technical Analysis"
    }, {
        "id": 1,
        "label": "Asset Correlation"

    }, {
        "id": 2,
        "label": "Seasonality"

    }, {
        "id": 3,
        "label": "Stock Screener"

    }, {
        "id": 4,
        "label": "Fundamental Analysis"

    }, {
        "id": 5,
        "label": "Volume Spread Analysis"
    }
    ]
}


export function getOrderValidityItem() {
    return ['Day', 'GTD', 'GTC', 'FAK', 'FOK', 'E_GTD', 'E_GTC']
}

export function getOrderNormalItem() {
    return ['Limit', 'Market', 'Market To Limit', 'Stop', 'Stop Limit']
}

export function getExchangeOrderType() {
    return [
        {
            label: 'Normal',
            orderPosition: 0,
            subMenu: [
                {
                    label: 'normal;Limit',
                    checkboxId : 'limit_normal_',
                    position: 0
                }, {
                    label: 'normal;Market',
                    checkboxId : 'market_normal_',
                    position: 1
                }, {
                    label: 'normal;Market To Limit',
                    checkboxId : 'markettolimit_normal_',
                    position: 2
                }, {
                    label: 'normal;Stop Limit',
                    checkboxId : 'stoplimit_normal_',
                    position: 3
                }, {
                    label: 'normal;Stop',
                    checkboxId : 'stop_normal_',
                    position: 4
                },
            ]
        },
        {
            label: 'Odd Lots',
            orderPosition: 1,
            subMenu: [
                {
                    label: 'odd;Limit',
                    operationLbl: 'Odd Lots',
                    checkboxId: 'limit_odd_',
                    position: 0
                }, {
                    label: 'odd;Market',
                    operationLbl: 'Odd Lots',
                    checkboxId: 'market_odd_',
                    position: 1
                }, {
                    label: 'odd;Market To Limit',
                    operationLbl: 'Odd Lots',
                    checkboxId: 'markettolimit_odd_',
                    position: 2
                }, {
                    label: 'odd;Stop Limit',
                    operationLbl: 'Odd Lots',
                    checkboxId: 'stoplimit_odd_',
                    position: 3
                }, {
                    label: 'odd;Stop',
                    operationLbl: 'Odd Lots',
                    checkboxId: 'stop_odd_',
                    position: 4
                }, {
                    label: 'odd;Married',
                    operationLbl: 'Odd Lots',
                    checkboxId: 'married_odd_',
                    position: 10
                }, {
                    label: 'odd;Crossing',
                    operationLbl: 'Odd Lots',
                    checkboxId: 'crossing_odd_',
                    position: 11
                }
            ]
        }, {
            label: 'DBT',
            orderPosition: 2,
            subMenu: [
                {
                    label: 'dbt;Married',
                    checkboxId: 'married_dbt_',
                    position: 10
                }, {
                    label: 'dbt;Crossing',
                    checkboxId: 'crossing_dbt_',
                    position: 11
                }
            ]
        },
        {
            label: 'Advanced',
            orderPosition: 3,
            subMenu: [
                {
                    label: 'Market On Open',
                    checkboxId: 'marketonopen_',
                    position: 12
                },
                {
                    label: 'Limit On Open',
                    checkboxId: 'limitonopen_',
                    position: 13
                },
                {
                    label: 'Market On Close',
                    checkboxId: 'marketonclose_',
                    position: 14
                },
                {
                    label: 'Limit On Close',
                    checkboxId: 'limitonclose_',
                    position: 15
                },
                {
                    label: 'One Cancel Others',
                    checkboxId: 'onecancelothers_',
                    position: 16
                },
                {
                    label: 'Bracket Order',
                    checkboxId: 'bracketorder_',
                    position: 17
                },
                {
                    label: 'Market If Touched',
                    checkboxId: 'marketiftouched_',
                    position: 18
                },
                {
                    label: 'Limit If Touched',
                    checkboxId: 'limitiftouched_',
                    position: 19
                },
                {
                    label: 'Market Pegged',
                    checkboxId: 'marketpegged_',
                    position: 20
                },
                {
                    label: 'Primary Pegged',
                    checkboxId: 'primarypegged_',
                    position: 21
                },
                {
                    label: 'Trailing Stop',
                    checkboxId: 'trailingstop_',
                    position: 25
                },
                {
                    label: 'Trailing Stop Limit',
                    checkboxId: 'trailingstoplimit_',
                    position: 26
                },
                {
                    label: 'Iceberg',
                    checkboxId: 'iceberg_',
                    position: 27
                }

            ]
        }, {
            label: 'Others',
            orderPosition: 4,
            subMenu: [
                {
                    label: 'OMMT',
                    checkboxId: 'ommt_',
                    position: 5
                }, {
                    label: 'IDSS',
                    checkboxId: 'idss_',
                    position: 6
                }, {
                    label: 'RSS',
                    checkboxId: 'rss_',
                    position: 7
                }, {
                    label: 'PDT',
                    checkboxId: 'pdt_',
                    position: 8
                }, {
                    label: 'PSS',
                    checkboxId: 'pss_',
                    position: 29
                }

            ]
        },
        {
            label: 'Order Validity',
            orderPosition: 5,
            subMenu: [
                {label: 'Day ', code: 'Day'},
                {label: 'GTD', code: 'GTD'},
                {label: 'GTC', code: 'GTC'},
                {label: 'FAK', code: 'FAK'},
                {label: 'FOK', code: 'FOK'},
                {label: 'GTD (Exchange)', code: 'E_GTD'},
                {label: 'GTC (Exchange)', code: 'E_GTC'},

            ]
        }

    ]
}

export function findPositionsByOrderPosition(orderPosition) {
    let menuItems = getExchangeOrderType();
    let positions = [];
    for (let i = 0; i < menuItems.length; i++) {
        if (menuItems[i].orderPosition === orderPosition) {
            positions.push(...menuItems[i].subMenu.map(submenu => submenu.position));
        }
    }
    return positions;
}

//
// export function convertBase3To10(num, index) {
//     // let ExAccess = [...zeroPad(Respond.equity[1].toString(3), 19) + zeroPad(Respond.equity[0].toString(3), 19)].reverse().join("");
//
//     let exchangeList = getExchangeMenu();
//
//
//     // for (let idx in exchangeList) {
//     //     if(idx['em_id'] !== '4' && idx['em_id'] !== '7') {    // ignore for idx 4 & 7
//     //         if(idx['em_id'] === '0') {
//     //             // exchange here is disable
//     //             // disable your check box
//     //         }
//     //         else if(idx['em_id'] === '1') {
//     //             // exchange here is enable in real-time mode
//     //             // tick for real-time checkbox or radiobutton
//     //         }
//     //         else if(idx['em_id'] === '2') {
//     //             // exchange here is enable in delay mode
//     //             // tick for delay checkbox or radiobutton
//     //         }
//     //     }
//     // }
// }
