import React, {useState, useEffect, useMemo, useLayoutEffect,useRef} from 'react';
import '../../../asset/styles/customer-profile-style.css';
import api from '../../../../apis';

import moment from 'moment';
import {useLoading} from '../../../../context/LoadingContext';
import ReactTable from '../../../general/form-control/react-table';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    convertFuncPerm,
    getDefaultAccessMat,
    getNavIdByPath,
    handlePopup,
    useLocalStorage, convertErMsg, specialCharCheck
} from "../../../general/general-tools";
import { usePopupManager } from "react-popup-manager";
import {getUserAccessDetail} from "../../../../hooks/handleCookie";



const Index = () => {
    const {setLoading} = useLoading();

    const [tableData, setTableData] = useLocalStorage("mst_brcTblDat",[]);
    const [totalRecords, setTotalRecords] = useLocalStorage("mst_brcTblDatRec",0);
    const [currentPage, setCurrentPage] = useLocalStorage("mst_brcTblDatPage",1);
    const [currentPerPage, setCurrentPerPage] = useLocalStorage("mst_brcTblDatPerPg",10);
    const [searchInput, setSearchInput] = useLocalStorage("mst_brc_searchInput","");
    const isInitialMount = useRef(true);

    let navigate = useNavigate();
    const location = useLocation();
    const popupManager = usePopupManager();
    let userAccess = getUserAccessDetail() == {} ? getDefaultAccessMat():getUserAccessDetail();
    let actualPerm = convertFuncPerm(userAccess[getNavIdByPath(location.pathname)]);

    useEffect(() => {

        if(tableData.length > 0){
            handleInitList(searchInput)
        }
    },[])

    //Listen at page changes
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            handleInitList(searchInput);
        }
    }, [currentPage, currentPerPage]);

    function useEnterKeyPress(callback) {
        useEffect(() => {
            function handleKeyPress(event) {
                if (event.key === 'Enter' && document.activeElement.tagName.toLowerCase() === 'input') {
                    callback();
                }
            }

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }, [callback]);
    }

    function handleEnterKeyPress() {
        const searchButton = document.getElementById('btn-search-main');
        if (searchButton) {
            searchButton.click();
        }
    }
    useEnterKeyPress(handleEnterKeyPress);
    const handleInitList = async (searchTerm,newSearch = false) => {
        setLoading(true);
        let curPage = currentPage;
        if (newSearch) {
            setCurrentPage(1)
            curPage= 1;
        }

        api.getBranchList(currentPerPage, curPage, 1, searchTerm).then((result: any) => {
            if(result.status == 1){
                handlePopup(true,"Error",convertErMsg(result.message),popupManager)
                setLoading(false);
                return false;
            }
            if(result.data){
                if (result.data.list.length > 0) {
                    let dataList = result.data.list;
                    const timeout = setTimeout(() => {
                        setTableData(dataList);
                        let totalPage = parseInt(result.data.totalrow)
                        setTotalRecords(totalPage);
                        setLoading(false);
                    }, 10);
                    return () => clearTimeout(timeout);
                }else{
                    handlePopup(true,"Error","No results found",popupManager)
                    setTableData([]);
                    setTotalRecords(10);
                    setLoading(false);
                }
            }else{
                handlePopup(true,"Error","No results found",popupManager)
                setTableData([]);
                setTotalRecords(10);
                setLoading(false);
            }
        });
    };

    const handleOnclick = async (cur_code) => {
        await setLoading(true);
        navigate("/"+process.env.REACT_APP_URL_PREFIX+"branch_opr",{state:{row:cur_code,perm: actualPerm,mode:'Edit'}});
        setLoading(false);
    };
    const handleAddBtn = async () => {
        await setLoading(true);
        navigate("/"+process.env.REACT_APP_URL_PREFIX+"branch_opr",{state:{row:"",perm: actualPerm,mode:'Add'}});
        setLoading(false);
    };
    const handleResetBtn = async () => {
        await setLoading(true);
        setSearchInput("");
        setLoading(false);
    };


    const columns = useMemo(() => [
        {
            id:  row => row.bb_code,
            name: 'Branch Code',
            selector: row => row.bb_code,
            sortable: true,
            // center: true,
            cell: (row: { bb_code: any }) => (
                <>
                    <a className="tableItemsHlink" onClick={() => handleOnclick(row)}>{row.bb_code}</a>
                </>
            )
        },
        {
            name: 'Branch Name',
            selector: row => row.bb_name,
            sortable: true,
        }
    ], []);


    return (
        <div className="pt-3 text-left ">
            <div className="row">
                {/*<div className="col-12 title"> </div>*/}

                {/*Header Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h5 className="m-0 font-weight-bold text-primary">Administration &gt; Master File
                                Maintenance &gt; Branch - Search</h5>
                        </div>
                        <div className="card-body">
                            <div className="col-12 search-container ">
                                <div className="input-container col-md-12 fullWidthSearch">
                                    <div className="label ">Search</div>
                                    <input
                                        autoComplete='off'
                                        className="form-control "

                                        type="text"
                                        placeholder="Search by Branch Code / Name "
                                        aria-label="Search Input"
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                        onBlur={(e) => specialCharCheck(e.target.value,setSearchInput,popupManager)}
                                    />
                                </div>
                                <div className="col-md-12 btn_search_padding">
                                    <div>
                                        <button className="btn btn-primary "  id="btn-search-main"  onClick={() => handleInitList(searchInput,true)}>Search</button>
                                        <button className="btn btn-primary " onClick={() => handleResetBtn()}>Reset</button>
                                        {actualPerm['add'] ? <button className="btn btn-primary " onClick={() => handleAddBtn()}>Add</button> :""}
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                {/*Bottom Section*/}
                <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-4">

                        <div className="card-body">
                            <div className="col-12 search-container">
                                <ReactTable
                                    rowClick={setCurrentPage}
                                    columns={columns}
                                    perPageQty={setCurrentPerPage}
                                    data={tableData}
                                    pagination={{
                                        currentPage: currentPage,
                                        currentPerPage: currentPerPage,
                                        totalRecords: totalRecords
                                    }}
                                />
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
};

export default Index;
