import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ModalPop from '../../pages/public/ModalPop';
import ModalConfirm from '../../pages/public/ModalConfirm';
import api from "../../../apis";
import {usePopupManager} from "react-popup-manager";
import moment from "moment";
import dayjs from "dayjs";
import {confirmAlert} from "react-confirm-alert";
import ModalPopNoReturn from "../../pages/public/ModalPopNoReturn";
import {StyleSheet} from "@react-pdf/renderer";


export function getAuditPdfStyling() {
    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#FFFFFF',
            paddingTop: 25,
            paddingBottom: 25,
            borderBottom: 1,
            fontFamily: 'Regular',


        },
        headerDiv: {
            flexDirection: 'row',

            // border:1,
            // width:'100%',
            // margin:10,
            // marginTop: 5,
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 20,
            height: '13%',
            // flexGrow: 1,
            borderBottom: 1,
            overflow: 'auto'
            // paddingBottom: 6,

        },
        image: {
            height: '45em',
            width: '150em',
            float: 'left',
            display: "flex",
            flex: 5

        },
        headerTextRightDiv: {

            display: "flex",
            flex: 10

        },
        headerTextBig: {
            float: 'right',
            alignSelf: 'right',
            textAlign: 'right',
            fontSize: 16,
            right: 0,
            display: "flex",

        },
        headerTextMedium: {
            textAlign: 'right',
            fontSize: 10,
            flexDirection: 'row',
            display: 'flex',
            marginTop: 3,

        },
        headerTextSmall: {
            textAlign: 'right',
            fontSize: 9,
            flexDirection: 'row',
            display: 'flex',
            marginTop: 5,
            marginBottom: 10,
            flex: 10

        },
        bodyDiv: {
            // height: '190em',
            // flexGrow: 1,
            // border: 1,
            // border: 1,
        },
        boldText: {
            fontWeight: 'Times-Bold'
        },
        bodyEnqHeader: {
            textAlign: 'left',
            fontSize: 10,
            marginLeft: 15,
            marginTop: 10,

            // height: '190em',
            // flexGrow: 1,
            // border: 1,
            // border: 1,
        },
        table: {
            // width: '100%',
            // border: 1,
            fontSize: 9,
            marginTop: 2,
            marginLeft: 15,
            marginRight: 15,
        },
        specialRow: {
            flexDirection: 'row',
            borderTop: '1px solid #EEE',
            fontSize: 10,
            // border: 1
            // borderBottom:1,
            // borderBottomColor:'#808080'
        },
        specialChildRow0: {
            width: '15%',
            fontSize: 10,
            // border: 1
            // borderBottom:1,
            // borderBottomColor:'#808080'
        },
        specialChildRow: {
            width: '85%',
            fontSize: 10,
            // border: 1
            // borderBottom:1,
            // borderBottomColor:'#808080'
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            borderTop: '1px solid #111111',
            paddingTop: 8,
            paddingBottom: 8,

            // borderBottom:1,
            // borderBottomColor:'#808080'
        },
        header: {
            borderTop: 'none',
        },
        bold: {
            fontWeight: 'bold',
        },
        row0: {
            width: '3%',
            'overflow': 'hidden',
            'white-space': 'break-spaces',
            margin: '1px'

        }, row1: {
            width: '12%',
            margin: '1px'


        },
        row2: {
            width: '16%',
            margin: '0px'


        },
        row3: {
            width: '30%',
            margin: '1px'

        },
        row4: {
            width: '30%',
            margin: '1px'


        },
        pageNumber: {

            position: 'absolute',
            fontSize: 9,
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: 'right',
            color: 'grey',
            marginBottom: 10,
            marginRight: 10
        }
    });

    return styles;
}

export function getEnquiryClientProfileStyle() {
    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#FFFFFF',
            paddingTop: 25,
            paddingBottom: 45,
            borderBottom: 1,
            fontFamily: 'Regular',


        },
        headerDiv: {
            flexDirection: 'row',

            // border:1,
            // width:'100%',
            // margin:10,
            // marginTop: 5,
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 5,
            height: '13%',
            // flexGrow: 1,
            borderBottom: 1,
            overflow: 'auto'
            // paddingBottom: 6,

        },headerDivEnquiryTitle: {
            flexDirection: 'row',

            // border:1,
            // width:'100%',
            // margin:10,
            // marginTop: 5,
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 1,
            height: '1%',
            // flexGrow: 1,
            // borderBottom: 1,
            overflow: 'visible',
            paddingBottom: 20,

        },
        image: {
            height: '45em',
            width: '150em',
            float: 'left',
            display: "flex",
            flex: 5

        },
        headerTextRightDiv: {

            display: "flex",
            flex: 10

        },
        headerTextBig: {
            float: 'right',
            alignSelf: 'right',
            textAlign: 'right',
            fontSize: 16,
            right: 0,
            display: "flex",

        },
        headerTextMedium: {
            textAlign: 'right',
            fontSize: 11,
            flexDirection: 'row',
            display: 'flex',
            marginTop: 3,

        },
        headerTextMediumLeft: {
            textAlign: 'left',
            fontSize: 11,
            flexDirection: 'row',
            display: 'flex',
            marginTop: 3,

        },
        headerTextSmall: {
            textAlign: 'right',
            fontSize: 10,
            flexDirection: 'row',
            display: 'flex',
            marginTop: 5,
            marginBottom: 10,
            flex: 10

        },
        bodyDiv: {
            // height: '190em',
            // flexGrow: 1,
            // border: 1,
            // border: 1,
        },
        boldText: {
            fontWeight: 'Times-Bold'
        },
        bodyEnqHeader: {
            textAlign: 'left',
            fontSize: 12,
            marginLeft: 15,
            marginTop: 10,

            // height: '190em',
            // flexGrow: 1,
            // border: 1,
            // border: 1,
        },
        table: {
            // width: '100%',
            // border: 1,
            fontSize: 10,
            marginTop: 2,
            marginLeft: 15,
            marginRight: 15,

        },
        specialRow: {
            // border: 1,

            flexDirection: 'row',
            borderTop: '1px solid #EEE',
            fontSize: 12,
            // marginBottom:2,
            // border: 1,
            // borderBottom:1,
            // borderBottomColor:'#808080'
        },
        specialChildRow0Single: {
            width: '12.5%',
            height: 30,
            fontSize: 11,
            border: 1,
            paddingLeft:1

            // border: 1
            // borderBottom:1,
            // borderBottomColor:'#808080'
        },
        specialChildRowSingle: {
            width: '87.5%',
            fontSize: 11,
            border: 1,
            paddingLeft:1,
            margin:0,
            height: 30,


            // border: 1
            // borderBottom:1,
            // borderBottomColor:'#808080'
        },
        specialChildRow0: {
            width: '25%',
            fontSize: 11,
            border: 1,
            paddingLeft:1,
            height: 30,

            // border: 1
            // borderBottom:1,
            // borderBottomColor:'#808080'
        },
        specialChildRow: {
            width: '75%',
            fontSize: 11,
            border: 1,
            paddingLeft:1,
            margin:0,
            height: 30,

            // border: 1
            // borderBottom:1,
            // borderBottomColor:'#808080'
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            borderTop: '1px solid #111111',
            paddingTop: 8,
            paddingBottom: 8,

            // borderBottom:1,
            // borderBottomColor:'#808080'
        },
        header: {
            borderTop: 'none',
        },
        bold: {
            fontWeight: 'bold',
        },
        row0: {
            width: '8%',
            'overflow': 'hidden',
            'white-space': 'break-spaces',
            margin: '1px'

        }, row1: {
            width: '14%',
            margin: '1px'


        },
        row2: {
            width: '14%',
            margin: '2px'


        },
        row3: {
            width: '30%',
            margin: '1px'

        },
        row4: {
            width: '30%',
            margin: '1px'


        },
        pageNumber: {
            position: 'absolute',
            fontSize: 11,
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: 'right',
            color: 'grey',
            marginBottom: 10,
            marginRight: 10
        },
        pageNumberLeft: {

            position: 'absolute',
            fontSize: 10,
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: 'left',
            color: 'grey',
            marginBottom: 10,
            marginRight: 10,
            marginLeft: 10
        }
    });

    return styles;
}

export function getEnquiryClientProfilePDFStyle() {
    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#FFFFFF',
            paddingTop: 25,
            paddingBottom: 50,
            borderBottom: 1,
            fontFamily: 'Regular',


        },
        headerDiv: {
            flexDirection: 'row',

            // border:1,
            // width:'100%',
            // margin:10,
            // marginTop: 5,
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 10,
            height: '23%',
            // flexGrow: 1,
            borderBottom: 1,
            overflow: 'auto'
            // paddingBottom: 6,

        },headerDivEnquiryTitle: {
            flexDirection: 'row',

            // border:1,
            // width:'100%',
            // margin:10,
            // marginTop: 5,
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 1,
            height: '4%',
            // flexGrow: 1,
            // borderBottom: 1,
            overflow: 'visible',
            paddingLeft: 15,

        },
        image: {
            height: '45em',
            width: '150em',
            float: 'left',
            display: "flex",
            flex: 5

        },
        headerTextRightDiv: {

            display: "flex",
            flex: 10

        },
        headerTextBig: {
            float: 'right',
            alignSelf: 'right',
            textAlign: 'right',
            fontSize: 16,
            right: 0,
            display: "flex",

        },
        headerTextMedium: {
            textAlign: 'right',
            fontSize: 11,
            flexDirection: 'row',
            display: 'flex',
            marginTop: 3,

        },
        headerTextMediumLeft: {
            textAlign: 'left',
            fontSize: 11,
            flexDirection: 'row',
            display: 'flex',
            marginTop: 3,

        },
        headerTextSmall: {
            textAlign: 'right',
            fontSize: 10,
            flexDirection: 'row',
            display: 'flex',
            marginTop: 5,
            marginBottom: 10,
            flex: 10

        },
        bodyDiv: {
            // height: '190em',
            // flexGrow: 1,
            // border: 1,
            // border: 1,
        },
        boldText: {
            fontWeight: 'Times-Bold'
        },
        bodyEnqHeader: {
            textAlign: 'left',
            fontSize: 12,
            marginLeft: 15,
            marginTop: 10,

            // height: '190em',
            // flexGrow: 1,
            // border: 1,
            // border: 1,
        },
        table: {

            fontSize: 10,
            marginTop: 2,
            marginLeft: 15,
            marginRight: 15,

        },
        specialRow: {
            flexDirection: 'row',
            borderTop: '1px solid #EEE',
            fontSize: 12,

        },
        specialChildRow0Single: {
            width: '12.5%',
            height: 30,
            fontSize: 11,
            border: 1,
            paddingLeft:1

        },
        specialChildRowSingle: {
            width: '87.5%',
            fontSize: 11,
            border: 1,
            paddingLeft:1,
            margin:0,
            height: 30,

        },
        specialChildRow0: {
            width: '25%',
            fontSize: 11,
            paddingLeft:1,
            height: 30,
            backgroundColor:'#f2f2f2'

        },specialChildRowGrey: {
            width: '25%',
            fontSize: 11,
            paddingLeft:1,
            height: 30,
            backgroundColor:'#D3D3D3',
            marginRight:1

        },
        specialChildRowWhite: {
        width: '25%',
            fontSize: 11,
            paddingLeft:1,
            height: 30,
            backgroundColor:'#f2f2f2',
            marginRight:1

    },
        specialChildRow: {
            width: '75%',
            fontSize: 11,
            // border: 1,
            paddingLeft:1,
            margin:0,
            height: 30,
            backgroundColor:'#344865',
            color:'#ffffff',
            marginRight:1

        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            borderTop: '1px solid #111111',
            paddingTop: 8,
            paddingBottom: 8,

            // borderBottom:1,
            // borderBottomColor:'#808080'
        },
        header: {
            borderTop: 'none',
        },
        bold: {
            fontWeight: 'bold',
        },
        row0: {
            width: '8%',
            'overflow': 'hidden',
            'white-space': 'break-spaces',
            margin: '1px'

        }, row1: {
            width: '14%',
            margin: '1px'


        },
        row2: {
            width: '14%',
            margin: '2px'


        },
        row3: {
            width: '30%',
            margin: '1px'

        },
        row4: {
            width: '30%',
            margin: '1px'


        },
        pageNumber: {
            position: 'absolute',
            fontSize: 11,
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: 'right',
            color: 'grey',
            marginBottom: 10,
            marginRight: 10
        },
        pageNumberLeft: {

            position: 'absolute',
            fontSize: 10,
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: 'left',
            color: 'grey',
            marginBottom: 10,
            marginRight: 10,
            marginLeft: 10
        }
    });

    return styles;
}