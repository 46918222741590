import {handleRequestGet, handleRequestPost} from '../axios';

export const getBrokerList = async (size, page, status, term) => {
    let url = '/broker';

    let param = {
        size: parseInt(size),
        page: page
    };
    if(term != "") param['term'] = term;

    let result = await handleRequestGet(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const addBroker = async (data) => {
    let url = '/broker/create';
    let result = await handleRequestPost(url, {
        "code" : data['code'],
        "sname" : data['sname'],
        "lname": data['lname']
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateBroker = async (data) => {
    let url = '/broker/update';
    let result = await handleRequestPost(url, {
        "id" : data['id'],
        "code" : data['code'],
        "sname" : data['sname'],
        "lname": data['lname']
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const deleteBroker = async (id) => {
    let url = '/broker/delete';
    let result = await handleRequestPost(url, {
        id: id
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
