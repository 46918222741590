import {handleRequestGet, handleRequestPost} from '../axios';

export const getTaskSchedulerList = async (size, page, status, term, recurr_mode) => {
    let url = '/taskscheduler';

    let param = {
        size: size,
        page: page
    };
    if(term != "") param['term'] = term;
    if(recurr_mode != "") param['recurr_mode'] = recurr_mode;

    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getTaskMonitorList = async (size, page, status, term, recurr_mode) => {
    let url = '/taskscheduler/taskmonitor';

    let param = {
        size: size,
        page: page
    };
    if(term != "") param['term'] = term;
    if(recurr_mode != "") param['recurr_mode'] = recurr_mode;

    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const createTask = async (data) => {
    let url = '/taskscheduler/create';



    let result = await handleRequestPost(url, {
        "tss_id" : data['tss_id'],
        "tss_active" : data['tss_active'],
        "tss_trade_day" : data['tss_trade_day'],
        "tss_name" : data['tss_name'],
        "tss_desc" : data['tss_desc'],
        "tss_fail_approach" : data['tss_fail_approach'],
        "tss_type" : data['tss_type'],
        "tss_start" : data['tss_start'],
        "tss_end" : data['tss_end'],
        "tss_recurr_mode" : data['tss_recurr_mode'],
        "tss_recurr_time" : data['tss_recurr_time'],
        "dependencies" : data['dependencies']
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};


export const getSpecificTask = async (term) => {
    let url = '/taskscheduler/taskdetail';

    let param = {};
    if(term != "") param['term'] = term;

    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};


export const updateTask = async (data) => {
    let url = '/taskscheduler/update';
    let result = await handleRequestPost(url, {
        "tss_id" : data['tss_id'],
        "tss_active" : data['tss_active'],
        "tss_trade_day" : data['tss_trade_day'] ,
        "tss_name" : data['tss_name'] ,
        "tss_desc" : data['tss_desc'] ,
        "tss_fail_approach" : data['tss_fail_approach'] ,
        "tss_type" : data['tss_type'] ,
        "tss_start" : data['tss_start'] ,
        "tss_end" : data['tss_end'] ,
        "tss_recurr_mode" : data['tss_recurr_mode'] ,
        "tss_recurr_time" : data['tss_recurr_time'] ,
        "dependencies" : data['dependencies'] ,
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const deleteBranch = async (id) => {
    let url = '/branch/delete';
    let result = await handleRequestPost(url, {
        id: id
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
