import {handleRequestGet, handleRequestPost} from '../axios';

export const getUserTypeList = async (size, page, status, term) => {
    let url = '/usertype';

    let param = {
        size: parseInt(size),
        page: page
    };
    if (term != "") param['term'] = term;

    let result = await handleRequestGet(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const addUserType = async (data) => {
    let url = '/usertype/create';
    let result = await handleRequestPost(url, {
        "id": data['id'],
        "name": data['name'],
        "category": data['category'],
        "description": data['description'],
        // "accesslevel": data['accesslevel'] ?? ""
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateUserType = async (data) => {
    let url = '/usertype/update';
    let result = await handleRequestPost(url, {
        "id": parseInt(data['id']),
        "name": data['name'],
        "category": data['category'],
        "description": data['description'],
        // "accesslevel": data['accesslevel'] ?? ""
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const deleteUSerType = async (id) => {
    let url = '/usertype/delete';
    let result = await handleRequestPost(url, {
        id: id
    });
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateUserAccessMatrixMarket = async (data) => {
    let url = '/usertype/market/update';
    let result = await handleRequestPost(url, data);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const refreshAccessMatrixMarket = async (size, page, status, term,id) => {
    let url = '/usertype';

    let param = {
        size: parseInt(size),
        page: page
    };
    if (term != "") param['term'] = term;
    if (id != "") param['id'] = parseInt(id);

    let result = await handleRequestGet(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getAccessMatricList = async (size, page, status, term,userCat) => {
    let url = '/usertype';

    let param = {
        size: parseInt(size),
        page: page
    };
    if (term != "") param['term'] = term;
    if (userCat != "") param['category'] = userCat;

    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getOrderValidity = async (id) => {
    let url = '/user/order/list';

    let param = {
        id: id,
    };

    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateOrderValidityUserType = async (data) => {
    let url = '/usertype/order/update';
    let result = await handleRequestPost(url, data);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const updateOrderValidityUser = async (data) => {
    let url = '/users/order/update';
    let result = await handleRequestPost(url, data);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};


export const getOperationAccessMatList = async (param) => {
    let url = '/usertype/access';

    //param = { id: 9}

    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const getOperationAccessMatListUserLvl = async (param) => {
    let url = '/adminuser/access';

    //param = { id: 9}

    let result = await handleRequestGet(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateOperationAccessMatList = async (param) => {
    let url = '/usertype/access/update';
    let result = await handleRequestPost(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const updateOperationAccessMatListUserLevel = async (param) => {
    let url = '/adminuser/access/update';
    let result = await handleRequestPost(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const resetOperationAccessMatListUserLevel = async (param) => {
    let url = '/adminuser/access/delete';
    let result = await handleRequestPost(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
// //adminuser/access/update
// /adminuser/access?id=15
// /adminuser/access/delete

export const resetClientDealerMarket = async (param) => {
    let url = '/users/market/delete';
    let result = await handleRequestPost(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const resetClientDealerOrder = async (param) => {
    let url = '/users/order/delete';
    let result = await handleRequestPost(url, param);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

