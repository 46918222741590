import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ModalPop from '../../pages/public/ModalPop';
import ModalConfirm from '../../pages/public/ModalConfirm';
import api from "../../../apis";
import {usePopupManager} from "react-popup-manager";
import moment from "moment";
import dayjs from "dayjs";
import {confirmAlert} from "react-confirm-alert";
import ModalPopNoReturn from "../../pages/public/ModalPopNoReturn";

const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function numProcessor(number) {
    number = parseFloat(number).toFixed(2)
    if (isNaN(number)) return "-";
    return numberWithCommas(number);
}

function numberWithCommas(x) {
    let isNegative = false;
    if (x.includes("-")) {
        x = x.replace("-", "")
        isNegative = true;
    }
    x = String(x).toString();
    var afterPoint = '.00';
    if (x.indexOf('.') > 0)
        afterPoint = x.substring(x.indexOf('.'), x.length);
    x = Math.floor(x);
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != '')
        lastThree = ',' + lastThree;
    if (isNegative) return ("-" + otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree + afterPoint);
    return otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree + afterPoint;
}

export function isElementVisible(ele) {
    return (ele.clientWidth !== 0 && ele.clientHeight !== 0);
}

function stateTransformer(shortName, isMalaysia) {
    let nameList =
        {
            'JHR': "Johor",
            'KDH': "Kedah",
            'KTN': "Kelantan",
            'KUL': "W.P.Kuala Lumpur",
            'LBN': "W.P.Labuan",
            'MLK': "Melaka",
            'NSN': "Negeri Sembilan",
            'PHG': "Pahang",
            'PNG': "Penang",
            'PRK': "Perak",
            'PLS': "Perlis",
            'PJY': "Putrajaya",
            'SBH': "Sabah",
            'SWK': "Sarawak",
            'SGR': "Selangor",
            'TRG': "Terengganu"
        }

    if (isMalaysia) {
        if (shortName.length == 3) return shortName;
        Object.keys(nameList).forEach(function (key) {
            if (nameList[key] == shortName) {
                return key;
            }
        })
    } else {
        return shortName;
    }

}

export function stateField(value, valueSetter, isMalaysia, isReadOnly, popupManager) {
    let values = stateTransformer(value, isMalaysia);


    if (isMalaysia) {
        return (
            <>
                <select
                    className="form-control"
                    id="branch_state"
                    aria-label=""
                    disabled={isReadOnly}
                    value={values}
                    defaultValue={values}
                    onChange={(e) => valueSetter(e.target.value)}
                >
                    <option>Please select State</option>
                    <option value="JHR">Johor</option>
                    <option value="KDH">Kedah</option>
                    <option value="KTN">Kelantan</option>
                    <option value="KUL">W.P.Kuala Lumpur</option>
                    <option value="LBN">W.P.Labuan</option>
                    <option value="MLK">Melaka</option>
                    <option value="NSN">Negeri Sembilan</option>
                    <option value="PHG">Pahang</option>
                    <option value="PNG">Penang</option>
                    <option value="PRK">Perak</option>
                    <option value="PLS">Perlis</option>
                    <option value="PJY">Putrajaya</option>
                    <option value="SBH">Sabah</option>
                    <option value="SWK">Sarawak</option>
                    <option value="SGR">Selangor</option>
                    <option value="TRG">Terengganu</option>
                </select>

            </>
        )
    } else {

        return (
            <input
                className="form-control"
                type="text"
                placeholder=""
                aria-label=""
                value={values}
                readOnly={isReadOnly}
                onChange={(e) => valueSetter(e.target.value)}
                onBlur={(e) => specialCharCheck(e.target.value, valueSetter, popupManager)}

            />
        )
    }


}

export function researchCategory() {
    return (
        <>
            <option value="">Please select research category</option>
            <option value="1">Cat 1</option>
            <option value="2">Cat 2</option>
            <option value="3">Cat 3</option>

        </>
    )
}

export function userTypeCategory() {
    return (
        <>
            <option value="">Please select user type</option>
            <option value="2">Operation</option>
            <option value="3">Dealer-Remisier</option>
            <option value="4">Client</option>

        </>
    )
}

export function accessStatusOptions() {
    return (
        <>
            <option value="">Please select access status</option>
            <option value="0">Blocked</option>
            <option value="1">Login</option>

        </>
    )
}

export function holidayTypeOptions() {
    return (
        <>
            <option value=''>Please select type</option>
            <option value="0">Closed</option>
            <option value="1">Partially Open</option>
            <option value="2">Test Day</option>
        </>
    )
}

export function dealerStatusOptions() {
    return (
        <>
            <option value=''>Please select status</option>
            <option value="0">Active</option>
            <option value="1">Pending</option>
            <option value="2">Expired</option>
        </>
    )
}

export function broadcastTypeOptions() {
    return (
        <>
            <option value=''>Please select type</option>
            <option value="1">Research</option>
            <option value="2">Announcement</option>
            <option value="3">Events</option>
            <option value="4">T&C</option>
        </>
    )
}

export function broadcastStatusOption() {
    return (
        <>
            <option value=''>Please select status</option>
            <option value="0">Pending Approval</option>
            <option value="1">Approved</option>
            <option value="2">Declined</option>
            <option value="3">Published</option>
            <option value="4">Expired</option>
        </>
    )
}

export function profileStatusOption() {
    return (
        <>
            <option value=''>Please select status</option>
            <option value="0">Inactive</option>
            <option value="1">Active</option>
            <option value="2">Suspended</option>
            <option value="3">Expired</option>
            <option value="4">Dormant</option>
            <option value="5">Closed</option>
        </>
    )
}

export function accountTypeOption() {
    return (
        <>
            <option value=''>Please select account type</option>
            <option value="0">Cash</option>
            <option value="1">Margin</option>
            <option value="2">Cash Upfront</option>
            <option value="3">Collateralized</option>
        </>
    )
}

export function handleRadioChange(value, selectOptionSetter, readOnly) {
    if (!readOnly) selectOptionSetter(value);
    if (value == '') selectOptionSetter(null);
}


export function handleSelectChange(value, selectOptionSetter, readOnly) {
    if (!readOnly) selectOptionSetter(value);
    if (value == '') selectOptionSetter(null);
}


export function handleCheckboxChange(checked, value, selectOptionSetter, readOnly) {
    if (!readOnly && checked) selectOptionSetter(value);
}

export function handleDisableChkBxByVal(checked, itemValue, setChkBoxSetter) {
    setChkBoxSetter(checked)
    //Changed on request 6 Dec 2023
    // if (itemValue) itemValue = itemValue.replace(/,/g, '');
    // if (!isNaN(itemValue)) {
    //     setChkBoxSetter(checked)
    //     if (!parseFloat(itemValue) > 0) setChkBoxSetter(false);
    //     else setChkBoxSetter(checked)
    // }
}


export function handleCountryChange(value, text, selectOptionSetter, selectTextSetter) {
    selectOptionSetter(value);
    selectTextSetter(text);
}

export function handlePositiveNumberChange(value, selectOptionSetter, isDecimanDisabled = false) {
    if (isDecimanDisabled) value = parseInt(value);
    if (isNaN(value)) {
        selectOptionSetter(null);
        return;
    } else {
        if (value < 0) {
            selectOptionSetter(0);
            alert("Number can be only positive.")
        } else selectOptionSetter(value);
    }
}


export function handlePositiveNumberHundredChange(value, selectOptionSetter) {
    if (value.length > 20) return;
    var value = value.replace(/,/g, '');
    if (isNaN(value)) return;
    if (value < 0) alert("Number can be only positive.")
    else selectOptionSetter(numberWithCommas(value));

}

export function convertPositiveNumberHundred(value) {
    if (value.toString() == "0") return "0.00";
    value = Number.parseFloat(value).toFixed(2);
    value = value.toString().replace(/,/g, '');
    if (value.length > 20) return numberWithCommas(value.substr(0, 20));
    else return numberWithCommas(value)
}

export function onMinMaxCheck(value, setter , minValue, maxValue, selectMinOptionSetter, selectMaxOptionSetter, type,prevMinValue,prevMinValSetter,prevMaxValue,prevMaxValSetter) {

    const numericValue = parseInt(value);

    if(isNaN(numericValue)){
        return;
    }
    if(!minValue &&!maxValue){
        return;
    }
    if (numericValue === 0) {
        setter(1.00);
    }

    if (minValue && maxValue) {
        if (parseFloat(minValue) > parseFloat(maxValue)) {
            alert("Min value cannot being larger than max value, nor max value cannot being lower than min value.")
            if (type === 'min'){
                selectMinOptionSetter(prevMinValue);
            }else{
                selectMaxOptionSetter(prevMaxValue);
            }
        }else{
            if (type === 'min'){
                prevMinValSetter(value);
            }else{
                prevMaxValSetter(value);
            }
        }
    }

}

export function checkZeroMinMaxULimit(value, setter , minValue, maxValue, selectMinOptionSetter, selectMaxOptionSetter, type) {
    // Parse the value to ensure it's treated as a number
    const numericValue = parseFloat(value);

    if (numericValue === 0) {
        setter(1.00);
    }

    if(!minValue &&!maxValue){
        return;
    }
    minValue = minValue.replace(/,/g, '');
    maxValue = maxValue.replace(/,/g, '');

    if (minValue && maxValue) {
        if (parseFloat(minValue) > parseFloat(maxValue)) {
            alert("Min value cannot being larger than max value, nor max value cannot being lower than min value.")
            if (type === 'min'){
                if (parseFloat(maxValue) - 1 == 0) selectMinOptionSetter("1.00");
                else selectMinOptionSetter((parseFloat(maxValue) - 1) + ".00");
            }
            if (type === 'max'){
                if(parseFloat(minValue) +1 == 0) selectMaxOptionSetter("1.00");
                else selectMaxOptionSetter((parseFloat(minValue) +1) +".00");
            }
        }
    }
}

export function timeElement() {
    return (
        <>
            <option value="">Please select time</option>
            <option value="00:00:00">0:00</option>
            <option value="00:30:00">0:30</option>
            <option value="01:00:00">1:00</option>
            <option value="01:30:00">1:30</option>
            <option value="02:00:00">2:00</option>
            <option value="02:30:00">2:30</option>
            <option value="03:00:00">3:00</option>
            <option value="03:30:00">3:30</option>
            <option value="04:00:00">4:00</option>
            <option value="04:30:00">4:30</option>
            <option value="05:00:00">5:00</option>
            <option value="05:30:00">5:30</option>
            <option value="06:00:00">6:00</option>
            <option value="06:30:00">6:30</option>
            <option value="07:00:00">7:00</option>
            <option value="07:30:00">7:30</option>
            <option value="08:00:00">8:00</option>
            <option value="08:30:00">8:30</option>
            <option value="09:00:00">9:00</option>
            <option value="09:30:00">9:30</option>
            <option value="10:00:00">10:00</option>
            <option value="10:30:00">10:30</option>
            <option value="11:00:00">11:00</option>
            <option value="11:30:00">11:30</option>
            <option value="12:00:00">12:00</option>
            <option value="12:30:00">12:30</option>
            <option value="13:00:00">13:00</option>
            <option value="13:30:00">13:30</option>
            <option value="14:00:00">14:00</option>
            <option value="14:30:00">14:30</option>
            <option value="15:00:00">15:00</option>
            <option value="15:30:00">15:30</option>
            <option value="16:00:00">16:00</option>
            <option value="16:30:00">16:30</option>
            <option value="17:00:00">17:00</option>
            <option value="17:30:00">17:30</option>
            <option value="18:00:00">18:00</option>
            <option value="18:30:00">18:30</option>
            <option value="19:00:00">19:00</option>
            <option value="19:30:00">19:30</option>
            <option value="20:00:00">20:00</option>
            <option value="20:30:00">20:30</option>
            <option value="21:00:00">21:00</option>
            <option value="21:30:00">21:30</option>
            <option value="22:00:00">22:00</option>
            <option value="22:30:00">22:30</option>
            <option value="23:00:00">23:00</option>
            <option value="23:30:00">23:30</option>

        </>
    )
}

export function timeElement15() {
    return (
        <>
            <option value="">Please select time</option>
            <option value="00:00:00">0:00</option>
            <option value="00:15:00">0:15</option>
            <option value="00:30:00">0:30</option>
            <option value="00:45:00">0:45</option>
            <option value="01:00:00">1:00</option>
            <option value="01:15:00">1:15</option>
            <option value="01:30:00">1:30</option>
            <option value="01:45:00">1:45</option>
            <option value="02:00:00">2:00</option>
            <option value="02:15:00">2:15</option>
            <option value="02:30:00">2:30</option>
            <option value="03:00:00">3:00</option>
            <option value="03:15:00">3:15</option>
            <option value="03:30:00">3:30</option>
            <option value="03:45:00">3:45</option>
            <option value="04:00:00">4:00</option>
            <option value="04:15:00">4:15</option>
            <option value="04:30:00">4:30</option>
            <option value="04:45:00">4:45</option>
            <option value="05:00:00">5:00</option>
            <option value="05:15:00">5:15</option>
            <option value="05:30:00">5:30</option>
            <option value="05:45:00">5:45</option>
            <option value="06:00:00">6:00</option>
            <option value="06:15:00">6:15</option>
            <option value="06:30:00">6:30</option>
            <option value="06:45:00">6:45</option>
            <option value="07:00:00">7:00</option>
            <option value="07:15:00">7:15</option>
            <option value="07:30:00">7:30</option>
            <option value="07:45:00">7:45</option>
            <option value="08:00:00">8:00</option>
            <option value="08:15:00">8:15</option>
            <option value="08:30:00">8:30</option>
            <option value="08:45:00">8:45</option>
            <option value="09:00:00">9:00</option>
            <option value="09:15:00">9:15</option>
            <option value="09:30:00">9:30</option>
            <option value="09:45:00">9:45</option>
            <option value="10:00:00">10:00</option>
            <option value="10:15:00">10:15</option>
            <option value="10:30:00">10:30</option>
            <option value="10:45:00">10:45</option>
            <option value="11:00:00">11:00</option>
            <option value="11:15:00">11:15</option>
            <option value="11:30:00">11:30</option>
            <option value="11:45:00">11:45</option>
            <option value="12:00:00">12:00</option>
            <option value="12:15:00">12:15</option>
            <option value="12:30:00">12:30</option>
            <option value="12:45:00">12:45</option>
            <option value="13:00:00">13:00</option>
            <option value="13:15:00">13:15</option>
            <option value="13:30:00">13:30</option>
            <option value="13:45:00">13:45</option>
            <option value="14:00:00">14:00</option>
            <option value="14:15:00">14:15</option>
            <option value="14:30:00">14:30</option>
            <option value="14:45:00">14:45</option>
            <option value="15:00:00">15:00</option>
            <option value="15:15:00">15:15</option>
            <option value="15:30:00">15:30</option>
            <option value="15:45:00">15:45</option>
            <option value="16:00:00">16:00</option>
            <option value="16:15:00">16:15</option>
            <option value="16:30:00">16:30</option>
            <option value="16:45:00">16:45</option>
            <option value="17:00:00">17:00</option>
            <option value="17:15:00">17:15</option>
            <option value="17:30:00">17:30</option>
            <option value="17:45:00">17:45</option>
            <option value="18:00:00">18:00</option>
            <option value="18:15:00">18:15</option>
            <option value="18:30:00">18:30</option>
            <option value="18:45:00">18:45</option>
            <option value="19:00:00">19:00</option>
            <option value="19:15:00">19:15</option>
            <option value="19:30:00">19:30</option>
            <option value="19:45:00">19:45</option>
            <option value="20:00:00">20:00</option>
            <option value="20:15:00">20:15</option>
            <option value="20:30:00">20:30</option>
            <option value="20:45:00">20:45</option>
            <option value="21:00:00">21:00</option>
            <option value="21:15:00">21:15</option>
            <option value="21:30:00">21:30</option>
            <option value="21:45:00">21:45</option>
            <option value="22:00:00">22:00</option>
            <option value="22:15:00">22:15</option>
            <option value="22:30:00">22:30</option>
            <option value="22:45:00">22:45</option>
            <option value="23:00:00">23:00</option>
            <option value="23:15:00">23:15</option>
            <option value="23:30:00">23:30</option>
            <option value="23:45:00">23:45</option>

        </>
    )
}

export function jsonContains(arr, key, val) {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i][key] === val) return true;
    }
    return false;
}

export function elementIndexSearch(arr, key, val) {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i][key] === val) return i;
    }
    return -1;
}

export function removeElementByIndex(arr, index) {
    if (index > -1) { // only splice array when item is found
        arr.splice(index, 1); // 2nd parameter means remove one item only
    }

    return arr;
}

export function handleTopUpdateEL(topupValue, elValue, operationSetter) {
    elValue = elValue.replace(/,/g, '');
    topupValue = topupValue.replace(/,/g, '');
    let topUpRate = parseFloat(topupValue) * 100.00 / parseFloat(elValue);
    //console.log(topupValue, elValue, topUpRate);

    if (isNaN(topUpRate) || topUpRate === Infinity) {
        operationSetter("0.00");
    } else {
        operationSetter((topUpRate.toFixed(2)));
    }
}


export function handleDecimalNumChange(value, selectOptionSetter, allowNegative = false) {
    let regexp = /^[+]?((\d*\.?\d{0,2})|(\d\.?\d))$/
    if (allowNegative) regexp = /^[+-]?((\d*\.?\d{0,2})|(\d\.?\d))$/
    if (value.length > 20) return;
    value = value.replace(/,/g, '');

    if (value == '0-') value = "-"
    if (!allowNegative && value.includes("-")) value = "";

    if (isNaN(value) && !value.includes("-")) {
        selectOptionSetter("");
        return;
    } else {
        if (value.includes("-") && !value.includes("0") && value.length == 1) {
            selectOptionSetter("-");
            return;
        }
        if (!regexp.test(value) && value.length > 2) {
            alert("Input can be only combination of number with maximum 2 decimal places.")
        }
        // else selectOptionSetter((value));
        else {
            let rstValue = numberWithCommas(value);
            selectOptionSetter(rstValue);
        }
    }
}

// export function handleInitNumber(event, selectOptionSetter) {
//     if(handleInitNumber == 0.00){
//
//     }
//     console.log(event.target.value)
//
// }

export function handleDecimalNumChangeWifCursor(popupManager = null, event, selectOptionSetter, allowNegative = false, checkboxVal = false, setCheckbox = null) {

    if(event.target.value === undefined) return;
    let origValue = event.target.value;
    const element = event.target;


    if (origValue === '-') {
        selectOptionSetter('-');
    } else {
        // made sure maintenance for 2 d.p.
        while ((origValue.length - origValue.indexOf('.')) < 2) {
            origValue += "0";
        }
        // count comma
        let countComma = 0;
        for (let i = 0; i < origValue.indexOf('.'); i++) {
            if (origValue[i] == ',')
                countComma++;
        }
        // remove extra dots
        let secondDotPos = origValue.indexOf('.') + 1;
        if (secondDotPos < origValue.length && origValue[secondDotPos] === '.') {

            origValue = origValue.slice(0, secondDotPos) + origValue.slice(secondDotPos + 1);
        }
        let valueWithoutComma = origValue.replace(/,/g, '');

        let value = formatter.format(valueWithoutComma);
        if (origValue.indexOf('.') == -1) {
            let temp = formatter.format(valueWithoutComma);
            if (temp.split('.')[0].length > 1) {
                value = temp.split('.')[0].substring(0, temp.split('.')[0].length - 2) + ".00";
                value = value.replace(/,/g, '').replace(/./g, '')
            } else {
                value = temp.split('.')[0] + ".00";
            }
            if (isNaN(value)) {
                value = formatter.format(valueWithoutComma);
            }
        }
        const maxValue = 999999999.99;
        if (parseFloat(valueWithoutComma) > maxValue && origValue.indexOf('.') != -1) {
            if (popupManager) handlePopup(true, "Error", "Exceed maximum value of 999,999,999.99.", popupManager);
            return false;
        }
        // tuning cursor position
        let offset = origValue[event.target.selectionStart - 1] !== value[event.target.selectionStart - 1] ? 1 : 0;


        if (!value.includes(".")) {
            offset = 0;
        }
        if (valueWithoutComma.indexOf('.') === (countComma) * 3) {
            offset = -1;
        }

        if (origValue == '0') {
            window.requestAnimationFrame(() => {
                element.selectionStart = 1;
                element.selectionEnd = 1;
            })
        } else {
            cursorControlLocked(event, offset, value.split(',').length - 1);

        }
        if (!isNaN(parseFloat(value))) {
            selectOptionSetter(value);
            if (setCheckbox) {
                if (!parseFloat(value) > 0 && checkboxVal) setCheckbox(false);
            }
        }

    }
}

export function handleIntegerChangeWithCursor(popupManager,event, selectOptionSetter, allowNegative = false, checkboxVal = false, setCheckbox = null) {
    let origValue = event.target.value;
    if (origValue === '-') {
        selectOptionSetter('-');
    } else {
        // Remove any non-numeric characters
        let valueWithoutComma = origValue.replace(/[^\d]/g, '');
        // Ensure the value is a positive integer
        let integerValue = parseInt(valueWithoutComma, 10);
        if (isNaN(integerValue) || integerValue < 0) {
            integerValue = 0;
        }

        // Ensure the value is within the specified limit
        const maxValue = 999999999;
        if (parseInt(valueWithoutComma) > maxValue) {
            if (popupManager) handlePopup(true, "Error", "Exceed maximum value of 999,999,999.", popupManager);
            return false;
        }
        // Add commas to the number for better readability
        let value = formatter.format(integerValue);

        // Remove any decimal places
        value = value.split('.')[0];

        // Tuning cursor position
        let offset = origValue[event.target.selectionStart - 1] !== value[event.target.selectionStart - 1] ? 1 : 0;
        cursorControlLocked(event, offset);

        // Update state with the formatted value
        selectOptionSetter(value);

        // Additional logic for checkbox handling
        if (setCheckbox) {
            if (!parseInt(valueWithoutComma) > 0 && checkboxVal) {
                setCheckbox(false);
            }
        }
    }
}

export function checkZeroOnBlur(value, setter) {
    // Parse the value to ensure it's treated as a number
    const numericValue = parseFloat(value);

    // Check if the parsed value is zero
    if (numericValue === 0) {
        // Set the value to 1 as the lowest value
        setter(1.00);
    }
}

export function cursorToFront(e) {
    // e.target.value = e.target.value.replace(/,/g, '');
    setTimeout(() => {
        e.target.selectionStart = 0;
        //e.target.selectionEnd = 0; // commented by Ray Tan => currently will using start 0 until mouse curser position to hightlight value
    }, 5);
}

export function cursorControlLocked(event, offset = 0, commaCount) {
    const caret = event.target.selectionStart;
    const element = event.target;


    let actualStart = caret + offset;
    let actualEnd = caret + offset;

    if (offset < 0) {
        switch (commaCount) {
            case 0:
                actualStart = caret - offset - commaCount - 2;
                actualEnd = caret - offset - commaCount - 2;
                break;
            default:
                actualStart = caret - offset - commaCount - 1;
                actualEnd = caret - offset - commaCount - 1;
                break;
        }

    } else if(caret == 0){
        actualStart = 2;
        actualEnd = 2;
    } else {
        switch (commaCount) {
            case 0:
                actualStart = caret - offset;
                actualEnd = caret - offset;
                break;
            case 1:

                if (caret == 4) {
                    if (offset == 0) {
                        actualStart = caret - offset + 1;
                        actualEnd = caret - offset + 1;
                    } else {
                        actualStart = caret + offset;
                        actualEnd = caret + offset;
                    }
                } else if (caret == 6 || caret == 7) {
                    actualStart = caret - offset;
                    actualEnd = caret - offset;
                } else {
                    actualStart = caret - offset + 1;
                    actualEnd = caret - offset + 1;
                }
                break;
            case 2:
                if (caret == 8) {
                    if (offset == 0) {
                        actualStart = caret - offset + 1;
                        actualEnd = caret - offset + 1;
                    } else {
                        actualStart = caret + offset ;
                        actualEnd = caret + offset ;
                    }
                } else if (caret == 11) {
                    actualStart = caret - offset + 1;
                    actualEnd = caret - offset + 1;
                } else {
                    actualStart = caret - offset;
                    actualEnd = caret - offset;
                }
                break;
        }

    }

    window.requestAnimationFrame(() => {
        element.selectionStart = actualStart;
        element.selectionEnd = actualEnd;
    })
}

export const useLocalStorage = (keyName, defaultValue) => {
    const [storedValue, setStoredValue] = React.useState(() => {
        try {
            const value = window.localStorage.getItem(keyName);

            if (value) {
                return JSON.parse(value);
            } else {
                window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
                return defaultValue;
            }
        } catch (err) {
            return defaultValue;
        }
    });

    const setValue = newValue => {
        try {
            window.localStorage.setItem(keyName, JSON.stringify(newValue));
        } catch (err) {
        }
        setStoredValue(newValue);
    };

    return [storedValue, setValue];
};


export const handlePopup = (isOpen, title, content, popupManager, isConfirm = false, navPath = null, navigate = null, secondContent = null) => {


    if (isConfirm && navPath) {
        confirmAlert({
            title: title,
            message: <div id="area_remark">
                <hr/>
                <div>
                    {content}
                </div>
                <div>
                    {secondContent}
                </div>

            </div>,
            buttons: [{label: 'OK', onClick: () => navigate("/" + process.env.REACT_APP_URL_PREFIX + navPath)}
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    } else {
        popupManager.open(ModalPop, {
            title: title,
            content: content,
            content2: secondContent,
        });
    }


}

export const handleLoadingPopup = (isOpen, title, content, onClose, popupManager) => {

    if (isOpen) {
        popupManager.open(ModalPopNoReturn, {
            title: title,
            content: content,
            onClose: onClose
        });
    } else {
        popupManager.closeAll();
    }


}

export function testEmailAddress(emailToTest) {
    // check for @
    var atSymbol = emailToTest.indexOf("@");
    if (atSymbol < 1) return false;

    var dot = emailToTest.indexOf(".");
    if (dot <= atSymbol + 2) return false;

    // check that the dot is not at the end
    if (dot === emailToTest.length - 1) return false;

    return true;
}


export function changeBase(number, fromBase, toBase) {
    if (fromBase == 10)
        return (parseInt(number)).toString(toBase)
    else if (toBase == 10)
        return parseInt(number, fromBase);
    else {
        var numberInDecimal = parseInt(number, fromBase);
        return parseInt(numberInDecimal).toString(toBase);
    }
}

export function zeroFill(text, size) {
    while (text.length < size) {
        text = text + "0";
    }

    return text;
}

export function getNavIdByPath(path) {
    let funcArray = {
        "/": "1",
        "#ADMINISTRATION_HEADER": "2",
        "#USER_MAINTENANCE_HEADER": "3",
        "/user_profile": "4",
        "/user_access": "5",
        "/user_mat": "6",
        "/auth_limit": "7",
        "/dealer_gpas": "8",
        "/parameter": "9",
        "#BROADCAST_HEADER": "10",
        "/br_entry": "11",
        "/br_app": "12",
        "#CLIENT_AMENDMENT_HEADER": "13",
        "#share_adjust": "14",
        "#task_maintenance": "15",
        "#RISK_MGMT_HEADER": "16",
        "/exp_company": "17",
        "/exp_exc": "18",
        "/exp_branch": "19",
        "/exp_dealer": "20",
        "/exp_acc": "21",
        "/exp_stock": "22",
        "/exp_stockbr": "23",
        "#MASTER_FILE": "24",
        "/currency": "25",
        "/country": "26",
        "/exchange": "27",
        "/company": "28",
        "/branch": "29",
        "/holiday": "30",
        "/user_type": "31",
        "/dealergp": "32",
        "/clientdealer": "33",
        "/broker": "34",
        "#brokerage": "35",
        "#server": "36",
        "#stock": "37",
        "/enquiry": "38",
        "#enq_utam": "39",
        "#enq_cae": "40",
        "#enq_ute": "41",
        "#enq_ice": "42",
        "#enq_idr": "43",
        "#enq_iue": "44",
        "#enq_cue": "45",
        "#enq_cle": "46",
        "#enq_cdre": "47",
        "#enq_ule": "48",
        "#enq_cloe": "49",
        "#enq_dre": "50",
        "#enq_clport": "51",
        "#enq_te": "52",
        "#enq_tncase": "53",
        "#enq_fetc": "54",
        "#AUDIT_TRIAL_HEADER": "55",
        "/audit_trail": "56",
        "#at_purge": "57",
        "/cl_amend_entry": "58",
        "/cl_amend_status": "59",
        "/cl_amend_approval": "60",
        "/task_schedular": "61",
        "/task_monitor": "62",
        // "/task_history": "63",

    }
    return funcArray[path]
}

export function getNavItemById(id) {
    let funcArray = {
        "1": {"url": "/", "label": "Dashboard", "actualLbl": "Dashboard", "parent_perm": "10000"},
        "2": {"url": "#ADMINISTRATION_HEADER", "actualLbl": "Administration", "parent_perm": "11111"},
        "3": {"url": "#USER_MAINTENANCE_HEADER", "actualLbl": "User Maintenance", "parent_perm": "11111"},
        "4": {
            "parent": "User Maintenance",
            "url": "/user_profile",
            "label": "Administration > User Maintenance > Profile",
            "actualLbl": "Profile",
            "parent_perm": "11110"
        },
        "5": {
            "url": "/user_access",
            "label": "Administration > User Maintenance > User Access",
            "actualLbl": "User Access",
            "parent": "User Maintenance",
            "parent_perm": "11010"
        },
        "6": {
            "url": "/user_mat",
            "label": "Administration > User Maintenance > Access Matrix",
            "actualLbl": "Access Matrix",
            "parent": "User Maintenance",
            "parent_perm": "11010"
        },
        "7": {
            "url": "/auth_limit",
            "label": "Administration > User Maintenance > Authorization Limit",
            "actualLbl": "Authorization Limit",
            "parent": "User Maintenance",
            "parent_perm": "11010"
        },
        "8": {
            "url": "/dealer_gpas",
            "label": "Administration > User Maintenance > Dealer Group Association",
            "actualLbl": "Dealer Group Association",
            "parent": "User Maintenance",
            "parent_perm": "11011"
        },
        "9": {
            "url": "/parameter",
            "label": "Administration > Parameters Maintenance",
            "actualLbl": "Parameters Maintenance",
            "parent": "User Maintenance",
            "parent_perm": "11010"
        },
        "10": {"url": "#BROADCAST_HEADER", "actualLbl": "Broadcast Maintenance"},
        "11": {
            "parent": "Broadcast Maintenance",
            "url": "/br_entry",
            "label": "Administration > Broadcast > Entry",
            "actualLbl": "Entry",
            "special_lbl": "broadcastmaintenance",
            "parent_perm": "11111"
        },
        "12": {
            "parent": "Broadcast Maintenance",
            "url": "/br_app",
            "label": "Administration > Broadcast > Approval",
            "actualLbl": "Approval",
            "special_lbl": "broadcastmaintenance",
            "parent_perm": "11010"
        },
        "13": {"url": "#CLIENT_AMENDMENT_HEADER", "actualLbl": "Client Amendment", "parent_perm": "11110"},
        "14": {
            "parent": "Share Adjustment",
            "url": "#share_adjust",
            "actualLbl": "Share Adjustment",
            "parent_perm": "11111"
        },
        "15": {"url": "#task_maintenance", "actualLbl": "Task Maintenance", "parent_perm": "11110"},
        "16": {"url": "#RISK_MGMT_HEADER", "actualLbl": "Risk Mgmt", "parent_perm": "11010"},
        "17": {
            "url": "/exp_company",
            "label": "Risk Mgmt > Company",
            "actualLbl": "Company",
            "parent_lbl": "Risk Mgmt",
            "parent": "Risk Mgmt",
            "parent_perm": "11010"
        },
        "18": {
            "parent": "Risk Mgmt",
            "url": "/exp_exc",
            "label": "Risk Mgmt > Exchange",
            "actualLbl": "Exchange",
            "parent_lbl": "Risk Mgmt",
            "parent_perm": "11010"
        },
        "19": {
            "parent": "Risk Mgmt",
            "url": "/exp_branch",
            "label": "Risk Mgmt > Branch",
            "actualLbl": "Branch",
            "parent_lbl": "Risk Mgmt",
            "parent_perm": "11010"
        },
        "20": {
            "parent": "Risk Mgmt",
            "url": "/exp_dealer",
            "label": "Risk Mgmt > Dealer",
            "actualLbl": "Dealer",
            "parent_lbl": "Risk Mgmt",
            "parent_perm": "11010"
        },
        "21": {
            "parent": "Risk Mgmt",
            "url": "/exp_acc",
            "label": "Risk Mgmt > Account",
            "actualLbl": "Account",
            "parent_lbl": "Risk Mgmt",
            "parent_perm": "11010"
        },
        "22": {
            "url": "/exp_stock",
            "label": "Risk Mgmt > Stock - Company",
            "actualLbl": "Stock - Company",
            "parent_lbl": "Risk Mgmt",
            "parent": "Risk Mgmt",
            "parent_perm": "11010"
        },
        "23": {
            "url": "/exp_stockbr",
            "label": "Risk Mgmt > Stock - Branch",
            "actualLbl": "Stock - Branch",
            "parent_lbl": "Risk Mgmt",
            "parent": "Risk Mgmt",
            "parent_perm": "11010"
        },
        "24": {"url": "#MASTER_FILE", "actualLbl": "Master File", "parent_perm": "11111"},
        "25": {
            "url": "/currency",
            "label": "Master File > Currency",
            "actualLbl": "Currency",
            "parent_lbl": "Master File",
            "parent": "Master File",
            "parent_perm": "11111"
        },
        "26": {
            "url": "/country",
            "label": "Master File > Country",
            "actualLbl": "Country",
            "parent_lbl": "Master File",
            "parent": "Master File",
            "parent_perm": "11111"
        },
        "27": {
            "url": "/exchange",
            "label": "Master File > Exchange",
            "actualLbl": "Exchange",
            "parent_lbl": "Master File",
            "parent": "Master File",
            "parent_perm": "11111"
        },
        "28": {
            "url": "/company",
            "label": "Master File > Company",
            "actualLbl": "Company",
            "parent_lbl": "Master File",
            "parent": "Master File",
            "parent_perm": "11111"
        },
        "29": {
            "url": "/branch",
            "label": "Master File > Branch",
            "actualLbl": "Branch",
            "parent_lbl": "Master File",
            "parent": "Master File",
            "parent_perm": "11111"
        },
        "30": {
            "url": "/holiday",
            "label": "Master File > Holiday",
            "actualLbl": "Holiday",
            "parent_lbl": "Master File",
            "parent": "Master File",
            "parent_perm": "11111"
        },
        "31": {
            "url": "/user_type",
            "label": "Master File > User Type",
            "actualLbl": "User Type",
            "parent_lbl": "Master File",
            "parent": "Master File",
            "parent_perm": "11111"
        },
        "32": {
            "url": "/dealergp",
            "label": "Master File > Dealer Group",
            "actualLbl": "Dealer Group",
            "parent_lbl": "Master File",
            "parent": "Master File",
            "parent_perm": "11111"
        },
        "33": {
            "url": "/clientdealer",
            "label": "Master File > Client-Dealer",
            "actualLbl": "Client-Dealer",
            "parent_lbl": "Master File",
            "parent": "Master File",
            "parent_perm": "11011"
        },
        "34": {
            "parent": "Master File",
            "url": "/broker",
            "label": "Master File > Broker",
            "actualLbl": "Broker",
            "parent_lbl": "Master File",
            "parent_perm": "11111"
        },
        "35": {
            "parent": "Master File",
            "url": "#brokerage",
            "actualLbl": "Brokerage",
            "parent_lbl": "Master File",
            "parent_perm": "11111"
        },
        "36": {
            "parent": "Master File",
            "url": "#server",
            "actualLbl": "Server",
            "parent_lbl": "Master File",
            "parent_perm": "11111"
        },
        "37": {
            "parent": "Master File",
            "url": "#stock",
            "actualLbl": "Stock",
            "parent_lbl": "Master File",
            "parent_perm": "11111"
        },
        "38": {"url": "/enquiry", "actualLbl": "Enquiries", "label": "Enquiries", "parent_perm": "10000"},
        "39": {"url": "#enq_utam", "parent": "Enquiries", "actualLbl": "User Type Access Matrix Enquiry"},
        "40": {"url": "#enq_cae", "parent": "Enquiries", "actualLbl": "Client Amendment Enquiry"},
        "41": {"url": "#enq_ute", "parent": "Enquiries", "actualLbl": "User Type Enquiry"},
        "42": {"url": "#enq_ice", "parent": "Enquiries", "actualLbl": "Inactive Client Enquiry"},
        "43": {"url": "#enq_idr", "parent": "Enquiries", "actualLbl": "Inactive Dealer-Remisier Enquiry"},
        "44": {"url": "#enq_iue", "parent": "Enquiries", "actualLbl": "Inactive User Enquiry"},
        "45": {"url": "#enq_cue", "parent": "Enquiries", "actualLbl": "Con-current User (Client) Enquiry"},
        "46": {"url": "#enq_cle", "parent": "Enquiries", "actualLbl": "Client Login Enquiry"},
        "47": {"url": "#enq_cdre", "parent": "Enquiries", "actualLbl": "Dealer-Remisier Enquiry"},
        "48": {"url": "#enq_ule", "parent": "Enquiries", "actualLbl": "User Login Enquiry"},
        "49": {"url": "#enq_cloe", "parent": "Enquiries", "actualLbl": "Client Limit Enquiry"},
        "50": {"url": "#enq_dre", "parent": "Enquiries", "actualLbl": "Dealer-Group Association Enquiry"},
        "51": {"url": "#enq_clport", "parent": "Enquiries", "actualLbl": "Client Portfolio Enquiry"},
        "52": {"url": "#enq_te", "parent": "Enquiries", "actualLbl": "Trade Enquiry"},
        "53": {"url": "#enq_tncase", "parent": "Enquiries", "actualLbl": "T&C Acceptance Status Enquiry"},
        "54": {
            "url": "#enq_fetc",
            "parent": "Enquiries",
            "actualLbl": "Foreign Exchange T&C Acceptance Status Enquiry"
        },
        "55": {"url": "#AUDIT_TRIAL_HEADER", "actualLbl": "Audit Trials"},
        "56": {
            "parent": "Audit Trials",
            "url": "/audit_trail",
            "actualLbl": "View",
            "label": "Audit Trail > View",
            "parent_perm": "11000"
        },
        "57": {"parent": "Audit Trials", "url": "#at_purge", "actualLbl": "Purge", "parent_perm": "11000"},
        "58": {
            "url": "/cl_amend_entry",
            "label": "Administration > User Maintenance > Amendment > Entry",
            "actualLbl": "Entry",
            "special_lbl": "clientamendment",
            "parent": "User Maintenance",
            "parent_perm": "11010"
        },
        "59": {
            "url": "/cl_amend_approval",
            "label": "Administration > User Maintenance > Amendment > Approval",
            "actualLbl": "Approval",
            "special_lbl": "clientamendment",
            "parent": "User Maintenance",
            "parent_perm": "11010"
        },
        "60": {
            "url": "/cl_amend_status",
            "label": "Administration > User Maintenance > Amendment > Status",
            "actualLbl": "Status",
            "special_lbl": "clientamendment",
            "parent": "User Maintenance",
            "parent_perm": "11000"
        },
        "61": {
            "parent": "Task Maintenance",
            "url": "/task_schedular",
            "actualLbl": "Scheduler",
            "label": "Administration > Task Maintenance > Task Scheduler",
            "parent_perm": "11110"
        },
        "62": {
            "parent": "Task Maintenance",
            "url": "/task_monitor",
            "actualLbl": "Monitor",
            "label": "Administration > Task Maintenance > Task Monitor",
            "parent_perm": "11000"
        },
        "63": {
            "parent": "Task Maintenance",
            "url": "#History",
            "actualLbl": "History",
            "label": "Administration > Task Maintenance > Task History",
            "parent_perm": "11000"
        },
        "64": {
            "url": "#ProfileSubItem",
            "parent": "User Maintenance",
            "actualLbl": "Access Matrix (User Level)",
            "label": "Administration > User Maintenance > Profile",
            "parent_perm": "01000"
        },
        "65": {
            "url": "#ProfileSubItem2",
            "parent": "User Maintenance",
            "actualLbl": "Authorization Limit (User Level)",
            "label": "Administration > User Maintenance > Profile",
            "parent_perm": "01000"
        },
    };
    return funcArray[id]
}

export function getNavNumericIcon(num) {
    switch (num) {
        case 1:
            return "①";
        case 2:
            return "②";
        case 3:
            return "③";
        case 4:
            return "④";
        case 5:
            return "⑤";

    }
}

export function getMimeTypes() {
    const types = {
        //   File Extension   MIME Type
        abs: 'audio/x-mpeg',
        ai: 'application/postscript',
        aif: 'audio/x-aiff',
        aifc: 'audio/x-aiff',
        aiff: 'audio/x-aiff',
        aim: 'application/x-aim',
        art: 'image/x-jg',
        asf: 'video/x-ms-asf',
        asx: 'video/x-ms-asf',
        au: 'audio/basic',
        avi: 'video/x-msvideo',
        avx: 'video/x-rad-screenplay',
        bcpio: 'application/x-bcpio',
        bin: 'application/octet-stream',
        bmp: 'image/bmp',
        body: 'text/html',
        cdf: 'application/x-cdf',
        cer: 'application/pkix-cert',
        class: 'application/java',
        cpio: 'application/x-cpio',
        csh: 'application/x-csh',
        css: 'text/css',
        dib: 'image/bmp',
        doc: 'application/msword',
        docx: 'application/msword',
        dtd: 'application/xml-dtd',
        dv: 'video/x-dv',
        dvi: 'application/x-dvi',
        eot: 'application/vnd.ms-fontobject',
        eps: 'application/postscript',
        etx: 'text/x-setext',
        exe: 'application/octet-stream',
        gif: 'image/gif',
        gtar: 'application/x-gtar',
        gz: 'application/x-gzip',
        hdf: 'application/x-hdf',
        hqx: 'application/mac-binhex40',
        htc: 'text/x-component',
        htm: 'text/html',
        html: 'text/html',
        ief: 'image/ief',
        jad: 'text/vnd.sun.j2me.app-descriptor',
        jar: 'application/java-archive',
        java: 'text/x-java-source',
        jnlp: 'application/x-java-jnlp-file',
        jpe: 'image/jpeg',
        jpeg: 'image/jpeg',
        jpg: 'image/jpeg',
        js: 'application/javascript',
        jsf: 'text/plain',
        json: 'application/json',
        jspf: 'text/plain',
        kar: 'audio/midi',
        latex: 'application/x-latex',
        m3u: 'audio/x-mpegurl',
        mac: 'image/x-macpaint',
        man: 'text/troff',
        mathml: 'application/mathml+xml',
        me: 'text/troff',
        mid: 'audio/midi',
        midi: 'audio/midi',
        mif: 'application/x-mif',
        mov: 'video/quicktime',
        movie: 'video/x-sgi-movie',
        mp1: 'audio/mpeg',
        mp2: 'audio/mpeg',
        mp3: 'audio/mpeg',
        mp4: 'video/mp4',
        mpa: 'audio/mpeg',
        mpe: 'video/mpeg',
        mpeg: 'video/mpeg',
        mpega: 'audio/x-mpeg',
        mpg: 'video/mpeg',
        mpv2: 'video/mpeg2',
        ms: 'application/x-wais-source',
        nc: 'application/x-netcdf',
        oda: 'application/oda',
        odb: 'application/vnd.oasis.opendocument.database',
        odc: 'application/vnd.oasis.opendocument.chart',
        odf: 'application/vnd.oasis.opendocument.formula',
        odg: 'application/vnd.oasis.opendocument.graphics',
        odi: 'application/vnd.oasis.opendocument.image',
        odm: 'application/vnd.oasis.opendocument.text-master',
        odp: 'application/vnd.oasis.opendocument.presentation',
        ods: 'application/vnd.oasis.opendocument.spreadsheet',
        odt: 'application/vnd.oasis.opendocument.text',
        otg: 'application/vnd.oasis.opendocument.graphics-template',
        oth: 'application/vnd.oasis.opendocument.text-web',
        otp: 'application/vnd.oasis.opendocument.presentation-template',
        ots: 'application/vnd.oasis.opendocument.spreadsheet-template',
        ott: 'application/vnd.oasis.opendocument.text-template',
        ogx: 'application/ogg',
        ogv: 'video/ogg',
        oga: 'audio/ogg',
        ogg: 'audio/ogg',
        otf: 'application/x-font-opentype',
        spx: 'audio/ogg',
        flac: 'audio/flac',
        anx: 'application/annodex',
        axa: 'audio/annodex',
        axv: 'video/annodex',
        xspf: 'application/xspf+xml',
        pbm: 'image/x-portable-bitmap',
        pct: 'image/pict',
        pdf: 'application/pdf',
        pgm: 'image/x-portable-graymap',
        pic: 'image/pict',
        pict: 'image/pict',
        pls: 'audio/x-scpls',
        png: 'image/png',
        pnm: 'image/x-portable-anymap',
        pnt: 'image/x-macpaint',
        ppm: 'image/x-portable-pixmap',
        ppt: 'application/vnd.ms-powerpoint',
        pps: 'application/vnd.ms-powerpoint',
        ps: 'application/postscript',
        psd: 'image/vnd.adobe.photoshop',
        qt: 'video/quicktime',
        qti: 'image/x-quicktime',
        qtif: 'image/x-quicktime',
        ras: 'image/x-cmu-raster',
        rdf: 'application/rdf+xml',
        rgb: 'image/x-rgb',
        rm: 'application/vnd.rn-realmedia',
        roff: 'text/troff',
        rtf: 'application/rtf',
        rtx: 'text/richtext',
        sfnt: 'application/font-sfnt',
        sh: 'application/x-sh',
        shar: 'application/x-shar',
        sit: 'application/x-stuffit',
        snd: 'audio/basic',
        src: 'application/x-wais-source',
        sv4cpio: 'application/x-sv4cpio',
        sv4crc: 'application/x-sv4crc',
        svg: 'image/svg+xml',
        svgz: 'image/svg+xml',
        swf: 'application/x-shockwave-flash',
        t: 'text/troff',
        tar: 'application/x-tar',
        tcl: 'application/x-tcl',
        tex: 'application/x-tex',
        texi: 'application/x-texinfo',
        texinfo: 'application/x-texinfo',
        tif: 'image/tiff',
        tiff: 'image/tiff',
        tr: 'text/troff',
        tsv: 'text/tab-separated-values',
        ttf: 'application/x-font-ttf',
        txt: 'text/plain',
        ulw: 'audio/basic',
        ustar: 'application/x-ustar',
        vxml: 'application/voicexml+xml',
        xbm: 'image/x-xbitmap',
        xht: 'application/xhtml+xml',
        xhtml: 'application/xhtml+xml',
        xls: 'application/vnd.ms-excel',
        xml: 'application/xml',
        xpm: 'image/x-xpixmap',
        xsl: 'application/xml',
        xslt: 'application/xslt+xml',
        xul: 'application/vnd.mozilla.xul+xml',
        xwd: 'image/x-xwindowdump',
        vsd: 'application/vnd.visio',
        wav: 'audio/x-wav',
        wbmp: 'image/vnd.wap.wbmp',
        wml: 'text/vnd.wap.wml',
        wmlc: 'application/vnd.wap.wmlc',
        wmls: 'text/vnd.wap.wmlsc',
        wmlscriptc: 'application/vnd.wap.wmlscriptc',
        wmv: 'video/x-ms-wmv',
        woff: 'application/font-woff',
        woff2: 'application/font-woff2',
        wrl: 'model/vrml',
        wspolicy: 'application/wspolicy+xml',
        z: 'application/x-compress',
        zip: 'application/zip'
    };
    return types;

}

export function getOrderTypeById(id) {

    let param = {
        0: "OT_NONE",
        1: "Limit",
        2: "Market",
        3: "MarketToLimit",
        4: "StopLimit",
        5: "StopMarket",
        6: "OMMT",
        7: "IDSS",
        8: "RSS",
        9: "PDT",
        10: "BuyingIn",
        11: "Married",
        12: "Crossing",
        13: "MarketOnOpen",
        14: "LimitOnOpen",
        15: "MarketOnClose",
        16: "LimitOnClose",
        17: "OneCancelOther",
        18: "BracketOrder",
        19: "MarketIfTouched",
        20: "LimitIfTouched",
        21: "MarketPegged",
        22: "PrimaryPegged",
        23: "MarketSnap",
        24: "PrimarySnap",
        25: "PassiveRelative",
        26: "TrailingStop",
        27: "TrailingStopLimit",
        28: "Iceberg",

    }


    return param[parseInt(id)];
}

export function getValidityType(id) {
    let param = {
        0: "VT_None",
        1: "Day",
        2: "GTD",
        3: "GTC",
        4: "FAK",
        5: "FOK",
        6: "E_GTD",
        7: "E_GTC",

    }
    return param[parseInt(id)];

}


export function getDefaultAccessMat() {
    return [{"page": "1", "ctrl": 15}, {"page": "2", "ctrl": 15}, {"page": "3", "ctrl": 15}, {
        "page": "4",
        "ctrl": 15
    }, {"page": "5", "ctrl": 15}, {"page": "6", "ctrl": 15}, {"page": "7", "ctrl": 15}, {
        "page": "8",
        "ctrl": 15
    }, {"page": "9", "ctrl": 15}, {"page": "10", "ctrl": 15}, {"page": "11", "ctrl": 15}, {
        "page": "12",
        "ctrl": 15
    }, {"page": "13", "ctrl": 15}, {"page": "14", "ctrl": 15}, {"page": "15", "ctrl": 15}, {
        "page": "16",
        "ctrl": 15
    }, {"page": "17", "ctrl": 15}, {"page": "18", "ctrl": 15}, {"page": "19", "ctrl": 15}, {
        "page": "20",
        "ctrl": 15
    }, {"page": "21", "ctrl": 15}, {"page": "22", "ctrl": 15}, {"page": "23", "ctrl": 15}, {
        "page": "24",
        "ctrl": 15
    }, {"page": "25", "ctrl": 15}, {"page": "26", "ctrl": 15}, {"page": "27", "ctrl": 15}, {
        "page": "28",
        "ctrl": 15
    }, {"page": "29", "ctrl": 15}, {"page": "30", "ctrl": 15}, {"page": "31", "ctrl": 15}, {
        "page": "32",
        "ctrl": 15
    }, {"page": "33", "ctrl": 15}, {"page": "34", "ctrl": 15}, {"page": "35", "ctrl": 15}, {
        "page": "36",
        "ctrl": 15
    }, {"page": "37", "ctrl": 15}, {"page": "38", "ctrl": 15}, {"page": "39", "ctrl": 15}, {
        "page": "40",
        "ctrl": 15
    }, {"page": "41", "ctrl": 15}, {"page": "42", "ctrl": 15}, {"page": "43", "ctrl": 15}, {
        "page": "44",
        "ctrl": 15
    }, {"page": "45", "ctrl": 15}, {"page": "46", "ctrl": 15}, {"page": "47", "ctrl": 15}, {
        "page": "48",
        "ctrl": 15
    }, {"page": "49", "ctrl": 15}, {"page": "50", "ctrl": 15}, {"page": "51", "ctrl": 15}, {
        "page": "52",
        "ctrl": 15
    }, {"page": "53", "ctrl": 15}, {"page": "54", "ctrl": 15}, {"page": "55", "ctrl": 15}, {
        "page": "56",
        "ctrl": 15
    }, {"page": "57", "ctrl": 15}, {"page": "58", "ctrl": 15}, {"page": "59", "ctrl": 15}, {
        "page": "60",
        "ctrl": 15
    }, {"page": "61", "ctrl": 15}, {"page": "62", "ctrl": 15}, {"page": "63", "ctrl": 15}];
}

export function convertAccessMatList(actualLvlArr) {
    let newArr = {};
    for (let i = 0; i < actualLvlArr.length; i++) {
        let ctrlBinFunc = zeroFill(Array.from(changeBase(actualLvlArr[i]['ctrl'], 10, 2)).reverse().join(''), 4)

        newArr[actualLvlArr[i]['page']] = ctrlBinFunc
    }
    return newArr
}

export function convertSpecialPerm(actualLvlArr) {
    let newArr = {};
    for (let i = 0; i < actualLvlArr.length; i++) {
        //Only profile have special permission at the moment, avoid to save all to avoid cookies wastage
        if (actualLvlArr[i]['page'] == 4) newArr[actualLvlArr[i]['page']] = actualLvlArr[i]['am'];
    }
    return newArr
}

export function convertFuncPerm(binString) {
    if (binString) {
        if (binString.length == 4) {
            return {
                "view": binString[0] == 1 ? true : false,
                "add": binString[1] == 1 ? true : false,
                "edit": binString[2] == 1 ? true : false,
                "delete": binString[3] == 1 ? true : false
            }
        } else {
            return {
                "view": false,
                "add": false,
                "edit": false,
                "delete": false
            }
        }
    } else {
        return {
            "view": false,
            "add": false,
            "edit": false,
            "delete": false
        }
    }

}

export function disableAllEleInDiv(divName) {
    if (document.getElementById(divName)) {
        var fields = document.getElementById(divName).getElementsByTagName('*');
        for (var i = 0; i < fields.length; i++) {
            if (fields[i].type != 'submit') fields[i].disabled = true;
        }
    }

}

export function performAntdTimeCheck(oldDateStr, lateDateStr, toDateStr, popupManager, dateBoolSetter) {
    let date1 = dayjs(oldDateStr).valueOf()
    let date2 = dayjs(lateDateStr).valueOf()

    if (oldDateStr == 'Invalid Date' || lateDateStr == 'Invalid Date') {
        dateBoolSetter(true);
        return;
    }


    if (date1 > date2) {
        // toDateStr(dayjs(oldDateStr));
        dateBoolSetter(false);
        handlePopup(true, "Error", "Please ensure that the ending date is not earlier than the starting date.", popupManager);
    } else {
        dateBoolSetter(true);
    }
}


export function simpleTimeCheck(frTime, toTime, toTimeStr, popupManager) {
    if (!frTime || !toTime) return;
    let frTimeProc = frTime.replace(":", "");
    let toTimeProc = toTime.replace(":", "");
    if (!isNaN(parseInt(frTimeProc)) && !isNaN(parseInt(toTimeProc))) {
        if (parseInt(frTimeProc) > parseInt(toTimeProc)) {
            toTimeStr("");
            handlePopup(true, "Error", "Please ensure that the ending time is not earlier than the starting time.", popupManager);

        }
    }
}

export function dateFormats() {
    return "DD-MMM-YYYY"
}

export function datePlaceHolder() {
    return "DD-MMM-YYYY"
}

export function momentTimeFormat() {
    return "YYYY-MM-DD"
}

export function dateTimeFormat() {
    return "DD-MMM-YYYY HH:mm"
}

export function dateTimeFormatTaskSchedule() {
    return "DD-MMM-YYYY HH:mm:ss"
}

export function timeFormat() {
    return "HH:mm:ss"
}

export function convertErMsg(obj) {
    if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
        let sentence = '';

        for (const key in obj) {
            const content = obj[key];
            sentence += content;
        }

        return sentence.trim();
    }

    if (typeof obj === 'string' || obj instanceof String) {
        return "• " + obj;

    } else {
        return "• " + JSON.stringify(obj)
    }


}


export function inLineConvertErMsg(obj) {
    if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
        let sentence = '';

        for (const key in obj) {
            const content = obj[key];
            sentence += content;
        }

        return sentence.trim();
    }

    return JSON.stringify(obj).replace(/"/g, '');

}

export function specialCharCheck(inputString, setter, popupManager) {
    const specialCharacters = ['\\', '"', "'", '%', '[', ']', ';', '--'];

    for (const char of specialCharacters) {
        if (inputString.includes(char)) {
            popupManager.open(ModalPop, {
                title: "Error",
                content: "Special characters are not allowed.",
            });
            let tmpStr = removeSpecialCharacters(inputString);
            setter(tmpStr)

            return false;
        }
    }

}

function removeSpecialCharacters(inputString) {
    const specialCharacters = ['\\', '"', "'", '%', '[', ']', ';', '--', '-'];

    let result = "";
    for (const char of inputString) {
        if (!specialCharacters.includes(char)) {
            result += char;
        }
    }

    return result;
}


export function IsJsonString(str) {
    try {
        var json = JSON.parse(str);
        return (typeof json === 'object');
    } catch (e) {
        return false;
    }
}


export function checkPercentageRange(value,popUpManager,elementId) {
    try {
        var valueInt = parseInt(value);
        console.log(valueInt)
        if(valueInt < 0 ) {
            document.getElementById(elementId).value = 0;
        }else if (valueInt > 100) {
            document.getElementById(elementId).value = 100;
        }else{
            document.getElementById(elementId).value = valueInt;
        }
        return false;

    } catch (e) {
        return false;
    }
}

export function validateDatesById(startDateId, endDateId,popupManager) {
    const startDate = document.getElementById(startDateId).value;
    const endDate = document.getElementById(endDateId).value;


    if (startDate && endDate) {
        if (new Date(startDate) > new Date(endDate)) {
            if (popupManager) handlePopup(true, "Error", "The start date cannot be later than the end date.", popupManager);
        }
    }
}

