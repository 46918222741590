import {handleRequestGet, handleRequestPost} from '../axios';

export const getParameterList = async () => {
    let url = '/sysrule';
    let result = await handleRequestGet(url, []);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
export const updateParameters = async (param) => {
    let url = '/sysrule/update';
    let result = await handleRequestPost(url, param);
    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
