import './index.css';

const Index = () => {
    return (
        <div className="loader-container">
            <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Index;
