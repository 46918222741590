export default {
    baseURL: 'https://admin.finext.com.my/admin/api',
    // baseURL: 'http://127.0.0.1:91',
    // axiosDebuggingLog: false,
    axiosDebuggingLog: true,
    debuggingMode: false,
    // debuggingMode: true,
    isU3Version: false,
    // isU3Version: false,
    Crypto: {
        ECDH: {
            PublicKeyB:
                'BAD1Fc9PG29SdYLnwP1qb3Tl8J+NAB2+XcofH3sUKqL/amZ7c1mXZINJkVJY+ifm93N+FV9N/mPmzTUacOXlXvn1NgHYRq3s00mrD6mNToJOM5MXSDdHdSwFeoIsOfA7FGU1ICce1lYQ4MeHoCiv0TJHm4iCYVLahOe8jXtrm+5NAaFfqQ=='
        }
    }
};

