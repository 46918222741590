import { handleRequestPost, handleRequestGet } from '../axios';
import { setLogin, getUserDetail,setAccessLevel } from '../../hooks/handleCookie';
import { parseResult } from './GeneralFunc';
import {
    changeBase, convertAccessMatList, convertSpecialPerm,
    getCookie,
    getDefaultAccessMat,
    handlePopup,
    zeroFill
} from "../../components/general/general-tools";
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import {usePopupManager} from "react-popup-manager/dist/src";



export const userLogin = async ({ username, password }) => {


    // var data = {'token':"123456"};
    // await setLogin(data);


    let url = '/sess/login';
    let result = await handleRequestPost(url, {
        username: username,
        password: password
    });

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult.status == 0) {
            let data = paresedResult.data;
            data.username = username;
            data.login_token = paresedResult.token;
            data.is_kick =  paresedResult.is_kick;
            let actualLvlArr = data.access ?? getDefaultAccessMat();
            // let actualLvlArr =  getDefaultAccessMat();
            // data.access =  getDefaultAccessMat();
            let temp = data;
            data.access = {};
            // console.log(convertSpecialPerm(actualLvlArr))
            await setLogin(temp);
            await setAccessLevel(convertAccessMatList(actualLvlArr),convertSpecialPerm(actualLvlArr));
            // await setSpecialPerm(convertSpecialPerm(actualLvlArr));
        }
        return paresedResult;
    } catch (error) {}

    return false;
};

export const userLogout = async () => {

    let url = '/sess/logout';
    let result = await handleRequestPost(url, {} );

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult.status == 0) {
            return true;
        }
    } catch (error) {

    }

    return false;
};

export const getHeartbeat = async () => {
    let url = '/sess/heartbeat';

    let result = await handleRequestGet(url, []);


    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};

export const resetHeartbeat = async () => {
    let url = '/sess/extend';

    let result = await handleRequestGet(url, []);

    try {
        let paresedResult = JSON.parse(result);
        if (paresedResult) {
            return paresedResult;
        }
    } catch (error) {
    }

    return false;
};
